* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Afacad", system-ui !important;
}

/* ====truckLogo==== */
@media (min-width: 341px) and (max-width: 991px) {
  .truckLogo {
    max-width: 60% !important;
  }
}

@media (max-width: 340px) {
  .truckLogo {
    max-width: 9rem !important;
  }
}

/* common */
.errmsg {
  color: red;
}

/* About css  */
.what {
  background-color: rgba(194, 194, 194, 0.07);
}

.what h4 {
  font-family: "raleway";
}

.what h2 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 47px;
  color: rgba(16, 110, 234, 1);
}

.go img {
  width: 547px;
  height: 410px;
}

.go h4 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;

  color: #000000;
}

.go h2 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;

  color: #000000;
}

.commit #one {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: 0.01em;

  color: #000000;
}

.commit #two {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  letter-spacing: 0.01em;

  color: #000000;
}

.commit li {
  list-style-type: none;
}

.commit li {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 3;
  letter-spacing: 0.01em;

  color: #000000;
}

/* Accordian  */

#accordion #AccoMos {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 47px;
  color: #106eea;
}

#accordion {
  background-color: #f9f9f9;
}

#accordion #break {
  margin-top: 4%;
}

#accordion #brk {
  margin-top: 4%;
}

#accordion hr {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1.5px;
  border-color: black;
}

#accordion #one {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  color: #656fe0;
}

#accordion #two {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #000000;
}

#accordion #three {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.53);
  background: rgba(217, 217, 217, 0.24);
}

#accordion button {
  box-shadow: 1px 1px 6px #d3d2d2;
}

#accordion #land {
  margin-bottom: 20px;
}

/* BacktoTop  */

.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #acff3b !important;
  color: #1e1e1e !important;
  border: none !important;
  border-radius: 50%;
  padding: 0;
  width: 50px;
  height: 50px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.back-to-top-button:hover {
  background-color: #0a0abf;
}

.back-to-top-button.visible {
  opacity: 1;
}

@media (min-width: 320px) and (max-width: 577px) {
  .back-to-top-button {
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    font-size: 12px;
  }
}

/* Cancel  */

.Cancel {
  background-color: #ffffff;
}

.Cancel .checkmark {
  font-size: 50px;
  color: #ff6d6d;
}

.Cancel #button {
  background: linear-gradient(180deg, #6c54ff 0%, #ad00ff 100%);
  border-radius: 30px;
}

.Cancel .circle {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #d6cbcb;
}

.Cancel .text {
  margin: auto;
}

/* Check Details  */

.summary #Ace {
  background-color: #ffffff;
}

.summary #address {
  background-color: #fff;
}

.summary #NextMove {
  background-color: linear-gradient(180deg, #6c54ff 0%, #ad00ff 100%);
}

.summary #PickUp {
  border: none;
  border-right: 1px solid #e4e2e2;
}

.summary .lineh {
  border-top: 1px solid #e4e2e2;
}

.summary .line {
  border-top: 1px solid #e4e2e2;
}

@media (max-width: 767px) {
  .summary .line {
    border-top: 0px solid #e4e2e2;
  }

  .lineh {
    margin-bottom: 0 !important;
  }

  .line {
    margin-bottom: 1rem !important;
    margin-top: 0rem !important;
  }
}

.summary .row > * {
  padding-left: 0;
  padding-right: 0;
}

.minHeight {
  min-height: 80vh !important;
}

@media screen and (min-width: 767px) {
  .summary #drop {
    border: none;
    border-left: 1px solid #e4e2e2;
  }
}

/* Contact Us  */

#ContactForm form {
  box-shadow: none;
  border: none;
  background-color: #fff;
  border-radius: 0;
  border-top: 15px solid #8423ca9a;
  border-bottom: 15px solid #80bdffb7;
}

#ContactForm .form-group {
  margin-bottom: 1rem;
}

#ContactForm .form-control {
  border: none;
  border-bottom: 2px solid #5d5c5c;
  border-radius: 0;
  padding: 0.5rem 0;
  background-color: transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#ContactForm .form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#ContactForm label {
  display: block;
  margin-bottom: 0.25rem;
  font-weight: bold;
}

#Contactbtt {
  background: #34517e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 60%;
}

/* Coupons  */

.rewards .head h2 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 47px;

  color: #106eea;
}

.rewards .head button {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 35px;
  color: #494949;
}

.rewards .but {
  border-bottom: 3px solid transparent;
}

.rewards #coupenBtn {
  border: none;
  background-color: transparent;
}

.rewards #coupenBtn:focus-within {
  border-bottom: 4px solid rgba(49, 49, 49, 1);
}

.rewards #coupenBtn:active {
  border-bottom: 4px solid rgba(49, 49, 49, 1);
}

.rewards .head {
  background-color: #ffffff;
}

.rewards .body {
  background-color: rgba(246, 222, 222, 0.58);
}

.rewards .HideSection {
  min-height: 80vh;
}

.rewards .coupon {
  background: linear-gradient(
    180deg,
    #9189c0 0%,
    rgba(156, 84, 170, 0.447917) 99.99%,
    rgba(165, 41, 152, 0) 100%
  );
  border: 0.1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  min-height: 150px;
}

.rewards #content p {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
}

.rewards #content span {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;

  color: #000000;
}

.rewards #content span b {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.rewards #first {
  color: #ffffff;
}

.rewards .coupon .dis {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
  width: 152.12px;
  height: 37.07px;
  transform: rotate(-90deg);
}

@media screen and (max-width: 600px) {
  .rewards .coupon .dis {
    transform: rotate(-360deg);
    color: #ffffff;
  }
}

/* Footer  */

footer {
  background: linear-gradient(
    89.87deg,
    rgba(116, 0, 255, 0.211156) 0.11%,
    rgba(97, 0, 255, 0.211156) 49.6%,
    rgba(0, 41, 255, 0.211156) 99.89%
  );
}

footer p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #131313;
}

footer span {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #131313;
}

footer hr {
  color: #ff2e2e;

  border: px solid #ff2e2e;
}

.center-address {
  color: #acff3b;
}

@media (max-width: 756px) {
  .center-address {
    text-align: center !important;
  }
}

.help a {
  text-decoration: none;
}

.help .h {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #131313;
}

.help .b {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #131313;
}

.a img {
  width: 168px;
}

.f hr {
  border: 1px solid #131313;
  width: 100%;
}

@media only screen and(max-width:768px) {
  .mb-4 h5 {
    align-content: center;
    justify-content: center;
  }
}

@media only screen and(max-width:414px) {
  .mb-4 h5 {
    align-content: center;
    justify-content: center;
  }
}

/* Header  */

html,
body {
  scroll-behavior: smooth;
  scroll-padding-top: 20%;
}

/* .navbar {
  display: flex;
  color: rgba(255, 255, 255, 0.72);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar img {
  width: 110px;
} */

/* .navbar-light .navbar-nav .nav-link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  padding-left: 30px;
  line-height: 18px;
  color: #161515;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
  font-weight: 500;
} */

/* .navbar-light .navbar-nav .nav-link i:hover {
  color: #fff;
  font-weight: 500;
} */

/* .nav-link:hover {
  background-color: #02046b;
  border-radius: 5px;
}

.nav-link.active {
  background-color: #02046b;
} */

#pro-btn {
  font-size: 25px;
  padding-left: 10px;
}

.btn-outline-success {
  width: 140px;
  height: 44px;
  color: #fff !important;
  background: linear-gradient(180deg, #6c54ff 0%, #ad00ff 100%);
  border-radius: 28px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  color: #ffffff;
}

@media only screen and(max-width: 414px) {
  .navbar-collapse {
    padding-left: 50px;
  }
}

@media only screen and(max-width:768px) {
  .navbar-collapse {
    padding-left: 30px;
  }
}

.NavBar {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
}

.main-logo {
  width: 150% !important;
}

.Links {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.logoutbtn:hover {
  color: #0077ff !important;
  /* background-color: #00387a !important; */
}

/* 
.Links {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  padding-left: 5px;
}

.navbar-light .navbar-nav .Links:hover {
  color: #fff;
  font-weight: 500;
}

.Links:hover {
  color: #fff;
  background-color: #02046b;
  border-radius: 5px;
}

.Links:active {
  background-color: #02046b;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .Links {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
} */

/* Hero  */

#HeroHed {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  color: rgba(0, 0, 0, 0.62);
}

#HeroTex {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
}

#HeroBtn {
  background: #3e4d6a;
  border-radius: 47px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
}

/* House Shift  */

#MapContainer {
  width: 100%;
  height: 60vh;
}

.lab Label {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  color: #808080;
}

.lab img {
  height: 20px;
  width: 20px;
  float: left;
}

#AddStop {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #b9b9b9;
}

#NextMove {
  background: #acff3b;
  /* border-radius: 28px; */
  font-weight: bold;
  border: none;
  color: black;
  /* Optional: to ensure text is readable */
  cursor: pointer;
  /* Optional: to change the cursor to a pointer on hover */
  transition: 0.5s ease;
  /* Optional: to animate the background change */
}

#NextMove:hover {
  /* background: linear-gradient(180deg, #ad00ff 0%, #6c54ff 100%); */
  /* background: linear-gradient(180deg, #00c6ff 0%, #0072ff 100%); */
  /* background: linear-gradient(180deg, #ff54c3 0%, #ff00a1 100%); */
  /* border-radius: 28px; */
  /* color: #000; */
}

/* Invoice  */

.invoice {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #ffffff;
}

.invoice h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.invoice strong {
  font-weight: bold;
}

.invoice table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice th,
.invoice td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.invoice tfoot td {
  font-weight: bold;
  border-top: 2px solid #ccc;
}

.invoice button {
  margin-top: 20px;
}

#BackToHome {
  text-decoration: none;
}

.invoice .pick {
  float: left;
}

.invoice .drop {
  float: right;
}

/* Invoices  */

#closeWhat {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;

  color: #31547e;
}

#closeBefore {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;

  color: #747373;
}

/* Lead  */

.we {
  background-color: rgba(194, 194, 194, 0.07);
}

.we h4 {
  font-family: "raleway";
}

.we h2 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 47px;
  color: rgba(16, 110, 234, 1);
}

.we li {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;

  color: rgba(0, 0, 0, 0.79);
}

.we ul {
  margin-top: 100px;
}

.we #point {
  background: rgba(194, 194, 194, 0.07);
  border-style: groove;
  border: 8px solid #c3bff0;
  border-top: none;
  border-right: none;
}

.we #icon {
  width: 555px;
  height: 420px;
  left: 15px;
  border-radius: 14px;
}

/* Login  */

.LoginHeader {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 65px;
  text-align: center;
  color: #333;
}

.LoginTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 49px;
  color: #333 !important;
}

.LoginSubHead {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 33px;
  color: #333 !important;
}

.LoginContent {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: #4e4e54;
}

.LoginOR {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 35px;

  color: #0e2063;
}

.or-line-container {
  display: flex;
  align-items: center;
}

.or-line {
  flex-grow: 1;
  height: 1px;
  background-color: #000;
}

.or-line-text {
  padding: 0 10px;
}

/* Map  */

#MapContainer {
  width: 100%;
  height: 60vh;
}

.lab Label {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  color: #808080;
}

.lab img {
  height: 20px;
  width: 20px;
  float: left;
}

#AddStop {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #b9b9b9;
}

/* Mobile  */

.me {
  background-color: rgba(194, 194, 194, 0.07);
}

.me #one {
  align-items: center;
  width: 475.99px;
  height: 612.25px;
  left: 104px;
}

.me h4 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 47px;

  color: #106eea;
}

.me #two {
  font-family: "Enriqueta";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
  /* or 36px */

  letter-spacing: 1px;

  color: #282d6c;
}

.me #three {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  /* or 23px */

  color: #656d9f;
}

/* Payment  */

#bun {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 45px;
  text-align: center;

  color: #106eea;
}

.cardpay {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 31px;

  color: #00387a;
}

.accordion-button::after {
  content: none;
}

.tab tr {
  border: none;
}

.table > :not(caption) > * > * {
  padding: 0;
}

.amount #rupees {
  background: linear-gradient(180deg, #6c54ff 0%, #ad00ff 100%);
  border-radius: 47px;
  color: #fff;
}

/* Profile  */

#pic {
  background-color: rgba(154, 184, 221, 1);
}

#pic p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;

  color: #ffffff;
}

#menu tr {
  background-color: #f1f8ff;
}

.table span {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;

  color: #000000;
}

.table img {
  height: 38px;
  width: 38px;
}

.container-fluid #menu {
  margin: 0;
  padding: 0;
}

/* Register  */

.LoginHeader {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 65px;
  text-align: center;
  color: #000000;
}

.LoginTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 49px;
  color: #0e2063;
}

.LoginSubHead {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 33px;
  /* identical to box height */
  color: #0e2063;
}

.LoginContent {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: #4e4e54;
}

.LoginOR {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 35px;

  color: #0e2063;
}

.or-line-container {
  display: flex;
  align-items: center;
}

.or-line {
  flex-grow: 1;
  height: 1px;
  background-color: #000;
}

.or-line-text {
  padding: 0 10px;
}

/* Rewards  */

.my-custom-popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* ===Croper image=== */
.cropper-container {
  max-width: 100%;
  margin: 0 auto;
}

/* ===tabs==== */

.nav-tabs .nav-link.active {
  background-color: #007bff !important;
  color: white !important;
}

.nav-tabs .nav-link {
  color: #000 !important;
}

/* .nav-tabs .nav-link.active:hover {
  background-color: #0056b3; 
  border: none !important;
} */

/* Rides  */

.order #shift-btn {
  border: none;
  background-color: transparent;
  border-bottom: 3px solid transparent;
}

.order #on {
  background-color: #fff;
}

.order #shift {
  background-color: rgba(246, 222, 222, 0.58);
}

.order #shift-bg {
  background-color: #e6ffe4;
  min-height: 80vh;
}

.order .dot1 {
  height: 10px;
  width: 10px;
  background-color: #3c54c0;
  border-radius: 50%;
  display: inline-block;
}

.order .dot2 {
  height: 10px;
  width: 10px;
  /* background-color: #048c4c !important; */
  border-radius: 50%;
  display: inline-block;
}

.order .line {
  height: 30px;
  width: 1px;
  background-color: #838282;
  border-radius: 50%;
  display: inline-block;
  margin-left: 4px;
  padding-top: 15px;
  margin-top: 0px;
}

/* Shift  */

#heros .btn-outline-secondary {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  text-align: left;
  margin-left: 6px;
  color: #808080;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}

#heros button img {
  height: 40px;
  width: 40px;
  float: left;
}

#heros #ShiftBtn:hover {
  background-color: #808080;
  color: #ffffff;
}

/* Shift Pack Deliver  */

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");

.fullbody {
  background-color: white;
}

.bottom {
  background-color: #ece1f2;
}

#cardimg {
  width: 70px;
}

.psd {
  /* position: sticky; */
  margin-bottom: -30px;
}

.psd h1 {
  color: rgb(28, 80, 139);
}

.firstcard {
  padding-top: 50px;
}

.initalcard {
  width: 85%;

  background-color: white;
  box-shadow: 1px 1px 1px 1px rgb(205, 207, 207);
  border: 0.6px solid rgb(252, 250, 250);
}

.initalcard .container p {
  align-content: start;
  font-size: 16px;
  color: #918f8f;
  padding: 10px;
}

.servicecardcontainer {
  padding-bottom: 30px;
}

.initalcard .container .row h6 span {
  background-color: #e33955;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 10px;
  color: white;
  border-radius: 2px;
}

.container .row .shiftingcard {
  border-radius: 2px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
}

.dividerdiv h2 {
  color: rgb(28, 80, 139);
}

.dividerdiv {
  margin: 60px 0px;
}

.dividerdiv h6 {
  font-size: 22px;
  font-weight: bold;
  font-family: "Cormorant Garamond", serif;
}

.shiftingcard div,
.imgul {
  align-items: end;
  width: 50px;
  height: 2px;
  background-color: #e33955;
}

.shiftingcard p {
  padding: 0px 10px;
}

.servicecardcontainer .shiftingcard {
  box-shadow: 1px 1px 1px 1px rgb(205, 207, 207);
  border: 0.6px solid rgb(252, 250, 250);
}

.servicecardcontainer .shiftingcard hr {
  color: #9e9d9d;
}

@-webkit-keyframes pop-on-hover {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes pop-on-hover {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.pop-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.pop-on-hover:hover,
.pop-on-hover:focus,
.pop-on-hover:active {
  -webkit-animation-name: pop-on-hover;
  animation-name: pop-on-hover;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Success Full  */

.Success {
  background-color: #faf5f6;
}

.Success .checkmark {
  font-size: 50px;
  color: #4d96ed;
}

.Success #button {
  background-color: #1bc24a;
  border-radius: 30px;
}

.Success .circle {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #d6cbcb;
}

.Success .text {
  margin: auto;
}

/* Trucklah Vechicle  */
@media (max-width: 576px) {
  #ServicePage {
    padding-top: 1.5rem !important;
  }
}

#AvaHeading {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 47px;
  color: #106eea;
}

#Vechicle .btn-primary {
  position: relative;
  background-color: transparent;
  color: #000;
  border: 2px solid #00000051;
  padding: 10px 20px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1em;
}

#Vechicle .btn-primary:before {
  /* content: '\2192'; */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-size: 1em;
}

#Vechicle .btn-primary:hover {
  background-color: #000;
  color: #fff;
}

#VehCaro {
  background-color: rgba(0, 0, 0, 0.1);
}

#VehCaroMain {
  background-color: rgba(188, 169, 169, 0.1);
  padding-left: 0%;
  padding-right: 0%;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-menu {
  background-color: white;
  /* padding: 20px; */
  /* width: 400px; */
  max-width: 70%;
  border-radius: 5px;
}

.close-button {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

#vehicleForm {
  padding-left: 5%;
  padding-right: 5%;
  background: linear-gradient(
    180deg,
    #8f6cf9 0%,
    rgba(194, 49, 240, 0.211156) 99.42%,
    rgba(200, 33, 234, 0.0414754) 99.74%,
    rgba(179, 62, 198, 0) 100%
  );
}

#vehicleForm form {
  box-shadow: none;
  border: none;
}

#vehicleForm #VehicleButton {
  background: linear-gradient(180deg, #6c54ff 0%, #ad00ff 100%);
  border-radius: 28px;
  color: #fff;
  border: none;
  width: 70%;
}

@media screen and (max-width: 767px) {
  .popup-overlay {
    position: relative;
    justify-content: center;
    align-items: first baseline;
    margin-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    bottom: auto;
  }

  .popup-menu {
    max-width: 90%;
  }
}

.data {
  width: 100%;
  background-color: #fff;
}

.data-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.data-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
}

.got {
  width: 24px;
  height: 24px;
}

.view {
  position: relative;
}

.closed {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}

.centered {
  text-align: center;
}

#shift-btn.active {
  background-color: #007bff;
  /* Change this to your desired active color */
  color: white;
}

.password-wrapper {
  position: relative;
}

.password-wrapper input {
  padding-right: 2.5rem;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
}

#shift-btn {
  border: none;
  background: none;
  padding: 10px 3px 3px 3px;
  cursor: pointer;
}

#shift-btn.underline {
  border-bottom: 2px solid #000000;
}

#order-btn.underline {
  border-bottom: 2px solid #000000;
}

@media (max-width: 576px) {
  #shift-btn {
    font-size: 10px;
    /* Smaller font size for mobile */
    padding: 0.2em 0.5em;
    /* Adjust padding for mobile */
  }

  .badge {
    font-size: 8px;
    /* Smaller badge size */
  }

  .order .col-12.d-flex.justify-content-center.mt-5.py-3.flex-wrap {
    flex-wrap: nowrap;
    /* Disable wrapping */
    overflow-x: auto;
    /* Allow horizontal scroll if buttons overflow */
  }
}

#coupenBtn {
  border: none;
  background: none;
  padding: 10px;
  cursor: pointer;
}

#coupenBtn.underline {
  border-bottom: 2px solid #000000;
}

.pac-container {
  border-color: transparent;
  box-shadow: none;
  border-top: 0px;
}

.distance-time {
  font-size: 1.2rem;
  color: #333;
  margin-top: 20px;
  text-align: center;
}

.distance-time p {
  margin: 0;
}

.logoutButton {
  font-size: 24px !important;
  position: absolute !important;
  top: 0;
  color: #545454 !important;
}

/* Vehicle Modal  */
.modal-backdrop.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
}

.carousel-container {
  margin-top: 20px;
}

.price-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #343a40;
  padding-left: 50px;
}

.price-text {
  font-size: 1rem;
  color: #6c757d;
}

.image-container {
  border-bottom: 1px solid #dee2e6;
  padding: 10px;
}

.addtionalservice {
  margin: 5px 0;
}

.loader-container {
  min-height: 60vh;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid #1e1e1e;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

@keyframes spinner-ub {
  100% {
    transform: rotate(1turn);
  }
}

/* ===success=== */

/* General Card Styling */
.cardStyle {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  background-color: #fff;
  max-width: 350px;
  border: none !important;
  margin: 0 auto;
  /* Center the card */
}

.successBtn {
  width: 50% !important;
  background-color: #1bc24a !important;
  border: none !important;
  padding: 10px !important;
  border-radius: 50px !important;
  color: #fff;
  font-size: 16px !important;
}

/* Adjustments for smaller devices */
@media (max-width: 768px) {
  .cardStyle {
    max-width: 100%;
    /* Ensure card is responsive */
    padding: 1.5rem;
  }

  .success-img,
  .error-img {
    max-width: 100%;
    /* Ensure the image scales on mobile */
    height: auto;
  }

  .titleStyle {
    font-size: 18px;
    /* Adjust font size for mobile */
  }

  .smallTextStyle {
    font-size: 14px;
    /* Adjust smaller text */
  }

  .Success {
    padding: 20px;
    /* Add padding for smaller screens */
  }
}

/* Larger devices */
@media (min-width: 769px) {
  .success-img,
  .error-img {
    max-width: 110%;
    /* Adjust the scaling of images on larger screens */
  }
}

/* 
.cardStyle {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  background-color: #fff;
  max-width: 350px;
  border: none !important;
}
.successBtn {
  width: 100% !important;
  background-color: #1bc24a !important;
  border: none !important;
  padding: 10px !important;
  border-radius: 8px !important;
  color: #fff;
  font-size: 16px !important;
}
.titleStyle {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
.smallTextStyle {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
} */

.icon-container {
  position: absolute;
  top: -6px;
  z-index: -999px;
  right: 140px;
  width: 70px;
  /* Adjust based on the size you want */
  height: 70px;
  background-color: white;
  border-radius: 50%;
  /* Makes it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
  /* Adjust this value if needed to move the icon upwards */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  */
}

.google-button {
  width: 100%;
  background-color: #db4437;
  /* Google button color */
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
}

/* .nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  -webkit-transition: background-color .218s;
  transition: background-color .218s;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100% !important;
  background: red !important;
}

.googleLogin{
  background: red !important;
  color: #ffffff !important;
} */

.googleLogin {
  background-color: red !important;
  /* Replace with your custom background color */
  border-radius: 5px !important;
  /* Example for rounded corners */
  color: white !important;
  /* Text color */
  padding: 10px 20px !important;
  /* Padding */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.badge.inactive {
  background-color: rgb(226, 57, 57);
  color: white;
  padding: 4px 6px;
  font-size: 0.8em;
  letter-spacing: 1.5px;
  vertical-align: super;
}

.rag {
  background-color: rgb(226, 57, 57);
  color: white;
  border-radius: 50%;
  padding: 0.3em 0.6em;
  font-size: 0.75em;
  vertical-align: super;
  margin-left: 1em;
}

.btn-custom {
  /* min-width: 9vw; */
  background-color: #1bc24a;
  font-size: 13px;
  border-radius: 15px;
  color: white;
  border: none;
}

/* Shift Type  */
.card-hover1 {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card-hover1:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card-hover2 {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card-hover2:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Default styles for the image */
.responsive-image {
  width: auto;
  height: auto;
  max-width: 100%;
}

/* Media query for screen widths between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .responsive-image {
    width: 100%;
    height: 100vh;
    /* Full height of the viewport */
    object-fit: cover;
    /* Cover the container while preserving aspect ratio */
  }
}

.btn-button {
  color: #fff !important;
  padding: 0.5rem 0.9rem;
  font-weight: 600;
  background-color: #4066d5 !important;
  border-color: #4066d5 !important;
}

.dt-length label {
  padding-left: 10px;
}

.badge.active {
  color: #fff;
  background-color: #5cb85c;
  display: inline-block;
  padding: 0.3em 0.9em;
  font-size: 0.8em;
  vertical-align: super;
  line-height: 1;
  letter-spacing: 1.5px;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  vertical-align: baseline;
  border-radius: 0.3rem;
}

/* Styles for the login button */
.login-btn {
  background: #333333;
  color: #acff3b;
  font-size: 18px;
  font-family: "Afacad", system-ui;
  border-radius: 5px;
}

.login-btn:hover {
  background: #333333;
  color: #acff3b;
  font-size: 18px;
  font-family: "Afacad", system-ui;
  border-radius: 5px;
}

/* Styles for the header menu */
.menus {
  font-weight: 500;
  /* font-size: 18px; */
  font-family: "Afacad", system-ui;
  color: #000000 !important;
  margin-right: 20px;
  text-decoration: none;
}

.menus :hover {
  font-weight: 700;
  color: #000 !important;
}

.menus .Links {
  position: relative;
  padding-bottom: 5px;
}

.menus .Links::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #000;
  transition: width 0.3s ease;
}

.menus .Links:hover::after {
  width: 100%;
  color: #000000 !important;
}

/* Flexbox alignment for login button on larger screens */
.login-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 30px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .login-btn {
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin-top: 1rem;
  }

  .header-menu {
    text-align: center;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .logo_ats {
    position: sticky !important;
    top: 0;
    z-index: 20;
    background: "#fff" !important;
  }
}

@media (max-width: 756px) {
  .login-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-left: 0px;
  }
}

/* data table search end position */
.dt-search {
  text-align: end;
}

.dt-paging {
  text-align: end;
}

[aria-label="ACTION: Activate to sort: Activate to sort"] .dt-column-order {
  display: none;
}

/* select-location */
.select-location {
  width: 500px !important;
  height: 50px !important;
  border-radius: 8px;
}

@media (max-width: 550px) {
  .select-location {
    width: 270px !important;
    height: 50px !important;
    border-radius: 8px;
  }
}

/* Style the carousel item */
.vechicle-label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
}

/* cash-pay */
.cash-pay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cash-pay button {
  margin-right: 10px !important;
}

@media (max-width: 425px) {
  .cash-pay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cash-pay button {
    margin-right: 0;
    margin-top: 10px;
  }
}

/* orderMargin */
.orderMargin {
  /* margin-top: 2rem !important; */
}

@media (max-width: 991px) {
  .orderMargin {
    /* margin-top: 1rem !important; */
  }
}

/* vehicleImages-card */

.vehicleImages-card {
  width: 25%;
}

.vehicleImages-card img {
  border: 1px solid #a4a4a4;
}

@media (max-width: 800px) {
  .vehicleImages-card {
    width: 100% !important;
  }
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #acff3b !important;
}

.css-117w1su-MuiStepIcon-text {
  fill: #fff !important;
  font-size: 0.95rem !important;
}

.date-field::placeholder {
  color: #00000040 !important;
}

.custom-checkbox {
  accent-color: #acff3b !important;
  /* Set the checkbox color */
}

.custom-checkbox:checked {
  background-color: #acff3b !important;
  /* Set the checked background color */
  border-color: #acff3b !important;
  /* Set the border color when checked */
}

.form-check-input:focus {
  border-color: #acff3b !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(172 255 83 / 60%) !important;
}

/* .hover-card:hover {
  border: 3px solid rgb(172, 255, 59);
}

.card-hover {
  transition: border-color 0.3s ease, transform 0.3s ease;
  border: 2px solid transparent;
} */

.hover-card:hover {
  border: 2px solid #e0e0e0a8 !important;
  transform: scale(1.05) !important;
}

.hover-card.active,
.hover-card.active img {
  border: 2px solid #adff3ba8;
  transform: scale(1.05);
}

.hover-card-img.active {
  border: 2px solid #adff3ba8;
  border-radius: 20px;
}

.count-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid;
  border-radius: 50px;
  margin-top: 10px;
  width: fit-content !important;
}

/* .card-hover:hover .count-controls {
  display: flex;
} */

.count-controls button {
  background-color: transparent !important;
  color: black;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
}

.count-controls span {
  font-size: 16px;
}

.count_content {
  text-align: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #acff3b !important;
}

.custom-arrow {
  background-color: #acff3b !important;
  border: none !important;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #1e1e1e !important;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

.custom-arrow:hover {
  background-color: #93fc00 !important;
}

.react-multi-carousel-arrow--left {
  left: 10px;
}

.react-multi-carousel-arrow--right {
  right: 10px;
}

.card-hover:active {
  border: 2px solid #adff3ba8;
}

.choose-vehicle {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #acff3b;
  color: #333333;
  font-size: 18px;
  font-family: "Afacad", system-ui;
  border-radius: 5px;
  background-color: transparent;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.choose-vehicle:hover {
  color: #333333;
  background: #acff3b;
  font-size: 18px;
  font-family: "Afacad", system-ui;
  border-radius: 5px;
}

.choose-vehicle.active {
  color: #333333;
  background: #acff3b;
  font-size: 18px;
  font-family: "Afacad", system-ui;
  border-radius: 5px;
}

/* .card-hover:hover {
  border: 2px solid #adff3ba8;
} */

/* Disable hover effect on the button */
.btn-outline-dark,
.btn-outline-dark:hover {
  background-color: transparent !important;
  color: black !important;
}

.data-link1 {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
  border-bottom: 2px solid transparent;
}

.data-link1:hover {
  border-bottom: 2px solid transparent;
  color: inherit;
}

#PayNow {
  background: #0cd72b;
  /* border-radius: 28px; */
  font-weight: bold;
  border: none;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s ease;
}

#password:focus-within {
  box-shadow: none;
  outline: none;
}

#noofworks:focus-within {
  box-shadow: none;
  outline: none;
  cursor: none;
}

.backsticky {
  position: sticky !important;
  top: 66px;
  z-index: 1000;
  background-color: white;
  padding: 10px;
}
.ordersback {
  position: sticky !important;
  top: 66px;
  z-index: 1000;
  background-color: white;
  padding: 5px;
}

/* ==== Summary calander === */
.react-calendar {
  width: 400px;
  border: none !important;
  line-height: inherit !important;
}
.react-calendar__tile--now {
  border-radius: 5px !important;
  border: 1px solid #007bff !important;
  background: #ffffff !important;
}
.react-calendar__tile {
  margin: 2px 2px !important;
  flex: 0 0 13% !important;
}
.react-calendar__tile--active {
  background: #ffffff !important;
  color: #000000 !important;
}
.react-calendar__tile--active.react-calendar__month-view__days__day--weekend {
  background: #ffffff !important;
  color: #d10000 !important;
}
abbr[title] {
  text-decoration: none !important;
}
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__label:disabled {
  background-color: #ffffff !important;
}
.react-calendar__tile--active.react-calendar__month-view__days__day--weekend.selected-date{
  background: #acff3b !important;
  /* border: 2px solid #acff3b !important; */
  color: #000000 !important;
  border-radius: 5px;
}
.selected-date {
  background: #acff3b !important;
  /* border: 2px solid #acff3b !important; */
  color: #000000 !important;
  border-radius: 5px;
}
.react-calendar__tile:disabled {
  background-color: #ffffff !important;
}
.react-calendar__navigation__label__labelText--from {
  font-size: large !important;
  font-weight: 500;
}

/* For WebKit Browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c9c9c9; 
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #c9c9c9 #f1f1f1;
}



.card-hover3 {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card-hover3:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.login-btn-2 {
  background-color: #acff3b;
  color: #333;
  padding: 4px 25px !important;
  border-radius: 3px;
  font-weight: 400;
  font-size: 1.2rem;
  border: 1px solid #ffffff !important;
}
.login-btn-2:hover {
  background-color: #95f70d;
  color: #333;
  border-radius: 3px;
  border: 1px solid #ffffff !important;
}
.login-btn-2:active {
  background-color: #95f70d !important;
  color: #333 !important;
  border: 1px solid #333 !important;
}

.btn-outline-black {
  background-color: #ffffff !important;
  color: #333 !important;
  border: 1px solid #333 !important;
}
.btn-outline-black:hover {
  background-color: #dc3545 !important;
  color: #ffffff !important;
  border: 1px solid #dc3545 !important;
}