/*
 * Webpixels CSS v1.1.5 (https://webpixels.io/)
 * Copyright 2021 Webpixels
 * Licensed under MIT (https://github.com/webpixels/css/blob/main/LICENSE)
 */
 :root {
    --x-gray-50: #fafafa;
    --x-gray-100: #f5f9fc;
    --x-gray-200: #e7eaf0;
    --x-gray-300: #cfd6df;
    --x-gray-400: #abb6c5;
    --x-gray-500: #8898a9;
    --x-gray-600: #6b7b93;
    --x-gray-700: #525f7f;
    --x-gray-800: #2d3748;
    --x-gray-900: #16192c;
    --x-blue-100: #ccebff;
    --x-blue-200: #99d6ff;
    --x-blue-300: #66c2ff;
    --x-blue-400: #33adff;
    --x-blue-500: #0099ff;
    --x-blue-600: #007acc;
    --x-blue-700: #005c99;
    --x-blue-800: #003d66;
    --x-blue-900: #001f33;
    --x-indigo-100: #dedffd;
    --x-indigo-200: #bebffb;
    --x-indigo-300: #9da0f9;
    --x-indigo-400: #7d80f7;
    --x-indigo-500: #4066D5;
    --x-indigo-600: #4a4dc4;
    --x-indigo-700: #373a93;
    --x-indigo-800: #252662;
    --x-indigo-900: #121331;
    --x-purple-100: #e7ddff;
    --x-purple-200: #d0bcff;
    --x-purple-300: #b89aff;
    --x-purple-400: #a179ff;
    --x-purple-500: #8957ff;
    --x-purple-600: #6e46cc;
    --x-purple-700: #523499;
    --x-purple-800: #372366;
    --x-purple-900: #1b1133;
    --x-pink-100: #ffddeb;
    --x-pink-200: #ffbcd7;
    --x-pink-300: #ff9ac2;
    --x-pink-400: #ff79ae;
    --x-pink-500: #ff579a;
    --x-pink-600: #cc467b;
    --x-pink-700: #99345c;
    --x-pink-800: #66233e;
    --x-pink-900: #33111f;
    --x-magenta-100: #fcd6ff;
    --x-magenta-200: #f8adff;
    --x-magenta-300: #f585ff;
    --x-magenta-400: #f15cff;
    --x-magenta-500: #ee33ff;
    --x-magenta-600: #be29cc;
    --x-magenta-700: #8f1f99;
    --x-magenta-800: #5f1466;
    --x-magenta-900: #300a33;
    --x-red-100: #ffd6e0;
    --x-red-200: #ffadc2;
    --x-red-300: #ff85a3;
    --x-red-400: #ff5c85;
    --x-red-500: #ff3366;
    --x-red-600: #cc2952;
    --x-red-700: #991f3d;
    --x-red-800: #661429;
    --x-red-900: #330a14;
    --x-orange-100: #ffe8cc;
    --x-orange-200: #ffd199;
    --x-orange-300: #ffba66;
    --x-orange-400: #ffa333;
    --x-orange-500: #ff8c00;
    --x-orange-600: #cc7000;
    --x-orange-700: #995400;
    --x-orange-800: #663800;
    --x-orange-900: #331c00;
    --x-yellow-100: #fff1cc;
    --x-yellow-200: #ffe499;
    --x-yellow-300: #ffd666;
    --x-yellow-400: #ffc933;
    --x-yellow-500: #ffbb00;
    --x-yellow-600: #cc9600;
    --x-yellow-700: #997000;
    --x-yellow-800: #664b00;
    --x-yellow-900: #332500;
    --x-green-100: #ccf5e7;
    --x-green-200: #99ebcf;
    --x-green-300: #66e0b8;
    --x-green-400: #33d6a0;
    --x-green-500: #00cc88;
    --x-green-600: #00a36d;
    --x-green-700: #007a52;
    --x-green-800: #005236;
    --x-green-900: #00291b;
    --x-teal-100: #d3f8f8;
    --x-teal-200: #a7f1f1;
    --x-teal-300: #7aebeb;
    --x-teal-400: #4ee4e4;
    --x-teal-500: #22dddd;
    --x-teal-600: #1bb1b1;
    --x-teal-700: #148585;
    --x-teal-800: #0e5858;
    --x-teal-900: #072c2c;
    --x-cyan-100: #ccf6ff;
    --x-cyan-200: #99eeff;
    --x-cyan-300: #66e5ff;
    --x-cyan-400: #33ddff;
    --x-cyan-500: #00d4ff;
    --x-cyan-600: #00aacc;
    --x-cyan-700: #007f99;
    --x-cyan-800: #005566;
    --x-cyan-900: #002a33;
    --x-primary: #4066D5;
    --x-shade-primary: #5356dd;
    --x-tint-primary: #6c70f6;
    --x-secondary: #cfd6df;
    --x-shade-secondary: #bac1c9;
    --x-tint-secondary: #d4dae2;
    --x-tertiary: #ff579a;
    --x-shade-tertiary: #e64e8b;
    --x-tint-tertiary: #ff68a4;
    --x-success: #00cc88;
    --x-shade-success: #00b87a;
    --x-tint-success: #1ad194;
    --x-info: #00d4ff;
    --x-shade-info: #00bfe6;
    --x-tint-info: #1ad8ff;
    --x-warning: #ff8c00;
    --x-shade-warning: #e67e00;
    --x-tint-warning: #ff981a;
    --x-danger: #ff3366;
    --x-shade-danger: #e62e5c;
    --x-tint-danger: #ff4775;
    --x-white: #fff;
    --x-shade-white: #e6e6e6;
    --x-tint-white: white;
    --x-light: #e7eaf0;
    --x-shade-light: #d0d3d8;
    --x-tint-light: #e9ecf2;
    --x-dark: #16192c;
    --x-shade-dark: #141728;
    --x-tint-dark: #2d3041;
    --x-primary-rgb: 92, 96, 245;
    --x-secondary-rgb: 207, 214, 223;
    --x-tertiary-rgb: 255, 87, 154;
    --x-success-rgb: 0, 204, 136;
    --x-info-rgb: 0, 212, 255;
    --x-warning-rgb: 255, 140, 0;
    --x-danger-rgb: 255, 51, 102;
    --x-white-rgb: 255, 255, 255;
    --x-light-rgb: 231, 234, 240;
    --x-dark-rgb: 22, 25, 44;
    --x-surface-primary: #fff;
    --x-surface-secondary: #f5f9fc;
    --x-surface-tertiary: #fafafa;
    --x-surface-light: #e7eaf0;
    --x-surface-dark: #141729;
    --x-white-rgb: 255, 255, 255;
    --x-black-rgb: 0, 0, 0;
    --x-muted-rgb: 107, 123, 147;
    --x-body-rgb: 82, 95, 127;
    --x-heading-rgb: 22, 25, 44;
    --x-card-rgb: 255, 255, 255;
    --x-font-sans-serif: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI,
      Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif,
      Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --x-font-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
    --x-font-display: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI,
      Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif,
      Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --x-font-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    --x-gradient: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
    );
    --x-body-font-family: var(--x-font-sans-serif);
    --x-body-font-size: 1rem;
    --x-body-font-weight: 400;
    --x-body-line-height: 1.625;
    --x-body-color: #525f7f;
    --x-body-bg: #fff;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }
  body {
    margin: 0;
    font-family: var(--x-body-font-family);
    font-size: var(--x-body-font-size);
    font-weight: var(--x-body-font-weight);
    line-height: var(--x-body-line-height);
    color: var(--x-body-color);
    text-align: var(--x-body-text-align);
    background-color: var(--x-body-bg);
    /* -webkit-text-size-adjust: 100%; */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  hr {
    margin: 1rem 0;
    color: #e7eaf0;
    background-color: currentColor;
    border: 0;
    opacity: 0.9;
  }
  hr:not([size]) {
    height: 1px;
  }
  h6,
  .h6,
  h5,
  .h5,
  h4,
  .h4,
  h3,
  .h3,
  h2,
  .h2,
  h1,
  .h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1.3;
    color: #16192c;
  }
  h1,
  .h1 {
    font-size: calc(1.35rem + 1.2vw);
  }
  @media (min-width: 1200px) {
    h1,
    .h1 {
      font-size: 2.25rem;
    }
  }
  h2,
  .h2 {
    font-size: calc(1.3rem + 0.6vw);
  }
  @media (min-width: 1200px) {
    h2,
    .h2 {
      font-size: 1.75rem;
    }
  }
  h3,
  .h3 {
    font-size: calc(1.2625rem + 0.15vw);
  }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      font-size: 1.375rem;
    }
  }
  h4,
  .h4 {
    font-size: 1.125rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
  }
  h6,
  .h6 {
    font-size: 0.875rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  abbr[title],
  abbr[data-bs-original-title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  ol,
  ul {
    padding-left: 1rem;
  }
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  dt {
    font-weight: 600;
  }
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1rem;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small,
  .small {
    font-size: 0.875em;
  }
  mark,
  .mark {
    padding: 0;
    background-color: #fcf8e3;
  }
  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: #4066D5;
    text-decoration: none;
  }
  a:hover {
    color: #4e52d0;
    text-decoration: none;
  }
  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  pre,
  code,
  kbd,
  samp {
    font-family: var(--x-font-monospace);
    font-size: 1em;
    direction: ltr /* rtl:ignore */;
    unicode-bidi: bidi-override;
  }
  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  code {
    font-size: 0.875em;
    color: #ff579a;
    word-wrap: break-word;
  }
  a > code {
    color: inherit;
  }
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.875em;
    color: #fff;
    background-color: #16192c;
    border-radius: 0.25rem;
  }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 600;
  }
  figure {
    margin: 0 0 1rem;
  }
  img,
  svg {
    vertical-align: middle;
  }
  table {
    caption-side: bottom;
    border-collapse: collapse;
  }
  caption {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #6b7b93;
    text-align: left;
  }
  th {
    font-weight: 500;
    text-align: inherit;
    /* text-align: -webkit-match-parent; */
  }
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  label {
    display: inline-block;
  }
  button {
    border-radius: 0;
  }
  button:focus:not(:focus-visible) {
    outline: 0;
  }
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  select {
    text-transform: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  select {
    word-wrap: normal;
  }
  select:disabled {
    opacity: 1;
  }
  [list]::-webkit-calendar-picker-indicator {
    display: none;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer;
  }
  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  textarea {
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
  }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem;
    }
  }
  legend + * {
    clear: left;
  }
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }
  ::-webkit-inner-spin-button {
    height: auto;
  }
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  ::-webkit-file-upload-button {
    font: inherit;
  }
  ::file-selector-button {
    font: inherit;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  iframe {
    border: 0;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  progress {
    vertical-align: baseline;
  }
  [hidden] {
    display: none !important;
  }
  *,
  *::before,
  *::after {
    border: 0 solid #e7eaf0;
  }
  iframe {
    display: block;
    vertical-align: middle;
  }
  figcaption,
  figure,
  main {
    display: block;
    margin: 0;
  }
  figure {
    max-width: 100%;
    min-height: 1px;
  }
  img,
  video {
    max-width: 100%;
    height: auto;
  }
  svg {
    vertical-align: middle;
    overflow: hidden;
  }
  a {
    outline: none;
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #cfd6df;
    border-radius: 0.375rem;
    max-width: 100%;
    height: auto;
  }
  .figure {
    display: inline-block;
  }
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  .figure-caption {
    font-size: 0.875em;
    color: #6b7b93;
  }
  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    width: 100%;
    padding-right: var(--x-gutter-x, 1.5rem);
    padding-left: var(--x-gutter-x, 1.5rem);
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container-sm,
    .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container-md,
    .container-sm,
    .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1360px;
    }
  }
  .row {
    --x-gutter-x: 1.5rem;
    --x-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--x-gutter-y) * -1);
    margin-right: calc(var(--x-gutter-x) * -0.5);
    margin-left: calc(var(--x-gutter-x) * -0.5);
  }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--x-gutter-x) * 0.5);
    padding-left: calc(var(--x-gutter-x) * 0.5);
    margin-top: var(--x-gutter-y);
  }
  .grid {
    display: grid;
    grid-template-rows: repeat(var(--x-rows, 1), 1fr);
    grid-template-columns: repeat(var(--x-columns, 12), 1fr);
    gap: var(--x-gap, 1.5rem);
  }
  .grid .g-col-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-1 {
    grid-column-start: 1;
  }
  .grid .g-start-2 {
    grid-column-start: 2;
  }
  .grid .g-start-3 {
    grid-column-start: 3;
  }
  .grid .g-start-4 {
    grid-column-start: 4;
  }
  .grid .g-start-5 {
    grid-column-start: 5;
  }
  .grid .g-start-6 {
    grid-column-start: 6;
  }
  .grid .g-start-7 {
    grid-column-start: 7;
  }
  .grid .g-start-8 {
    grid-column-start: 8;
  }
  .grid .g-start-9 {
    grid-column-start: 9;
  }
  .grid .g-start-10 {
    grid-column-start: 10;
  }
  .grid .g-start-11 {
    grid-column-start: 11;
  }
  @media (min-width: 576px) {
    .grid .g-col-sm-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-sm-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-sm-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-sm-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-sm-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-sm-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-sm-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-sm-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-sm-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-sm-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-sm-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-sm-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-sm-1 {
      grid-column-start: 1;
    }
    .grid .g-start-sm-2 {
      grid-column-start: 2;
    }
    .grid .g-start-sm-3 {
      grid-column-start: 3;
    }
    .grid .g-start-sm-4 {
      grid-column-start: 4;
    }
    .grid .g-start-sm-5 {
      grid-column-start: 5;
    }
    .grid .g-start-sm-6 {
      grid-column-start: 6;
    }
    .grid .g-start-sm-7 {
      grid-column-start: 7;
    }
    .grid .g-start-sm-8 {
      grid-column-start: 8;
    }
    .grid .g-start-sm-9 {
      grid-column-start: 9;
    }
    .grid .g-start-sm-10 {
      grid-column-start: 10;
    }
    .grid .g-start-sm-11 {
      grid-column-start: 11;
    }
  }
  @media (min-width: 768px) {
    .grid .g-col-md-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-md-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-md-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-md-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-md-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-md-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-md-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-md-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-md-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-md-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-md-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-md-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-md-1 {
      grid-column-start: 1;
    }
    .grid .g-start-md-2 {
      grid-column-start: 2;
    }
    .grid .g-start-md-3 {
      grid-column-start: 3;
    }
    .grid .g-start-md-4 {
      grid-column-start: 4;
    }
    .grid .g-start-md-5 {
      grid-column-start: 5;
    }
    .grid .g-start-md-6 {
      grid-column-start: 6;
    }
    .grid .g-start-md-7 {
      grid-column-start: 7;
    }
    .grid .g-start-md-8 {
      grid-column-start: 8;
    }
    .grid .g-start-md-9 {
      grid-column-start: 9;
    }
    .grid .g-start-md-10 {
      grid-column-start: 10;
    }
    .grid .g-start-md-11 {
      grid-column-start: 11;
    }
  }
  @media (min-width: 992px) {
    .grid .g-col-lg-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-lg-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-lg-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-lg-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-lg-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-lg-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-lg-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-lg-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-lg-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-lg-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-lg-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-lg-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-lg-1 {
      grid-column-start: 1;
    }
    .grid .g-start-lg-2 {
      grid-column-start: 2;
    }
    .grid .g-start-lg-3 {
      grid-column-start: 3;
    }
    .grid .g-start-lg-4 {
      grid-column-start: 4;
    }
    .grid .g-start-lg-5 {
      grid-column-start: 5;
    }
    .grid .g-start-lg-6 {
      grid-column-start: 6;
    }
    .grid .g-start-lg-7 {
      grid-column-start: 7;
    }
    .grid .g-start-lg-8 {
      grid-column-start: 8;
    }
    .grid .g-start-lg-9 {
      grid-column-start: 9;
    }
    .grid .g-start-lg-10 {
      grid-column-start: 10;
    }
    .grid .g-start-lg-11 {
      grid-column-start: 11;
    }
  }
  @media (min-width: 1200px) {
    .grid .g-col-xl-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-xl-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-xl-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-xl-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-xl-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-xl-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-xl-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-xl-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-xl-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-xl-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-xl-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-xl-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-xl-1 {
      grid-column-start: 1;
    }
    .grid .g-start-xl-2 {
      grid-column-start: 2;
    }
    .grid .g-start-xl-3 {
      grid-column-start: 3;
    }
    .grid .g-start-xl-4 {
      grid-column-start: 4;
    }
    .grid .g-start-xl-5 {
      grid-column-start: 5;
    }
    .grid .g-start-xl-6 {
      grid-column-start: 6;
    }
    .grid .g-start-xl-7 {
      grid-column-start: 7;
    }
    .grid .g-start-xl-8 {
      grid-column-start: 8;
    }
    .grid .g-start-xl-9 {
      grid-column-start: 9;
    }
    .grid .g-start-xl-10 {
      grid-column-start: 10;
    }
    .grid .g-start-xl-11 {
      grid-column-start: 11;
    }
  }
  @media (min-width: 1400px) {
    .grid .g-col-xxl-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-xxl-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-xxl-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-xxl-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-xxl-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-xxl-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-xxl-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-xxl-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-xxl-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-xxl-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-xxl-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-xxl-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-xxl-1 {
      grid-column-start: 1;
    }
    .grid .g-start-xxl-2 {
      grid-column-start: 2;
    }
    .grid .g-start-xxl-3 {
      grid-column-start: 3;
    }
    .grid .g-start-xxl-4 {
      grid-column-start: 4;
    }
    .grid .g-start-xxl-5 {
      grid-column-start: 5;
    }
    .grid .g-start-xxl-6 {
      grid-column-start: 6;
    }
    .grid .g-start-xxl-7 {
      grid-column-start: 7;
    }
    .grid .g-start-xxl-8 {
      grid-column-start: 8;
    }
    .grid .g-start-xxl-9 {
      grid-column-start: 9;
    }
    .grid .g-start-xxl-10 {
      grid-column-start: 10;
    }
    .grid .g-start-xxl-11 {
      grid-column-start: 11;
    }
  }
  .col {
    flex: 1 0 0%;
  }
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-1 {
    margin-left: 8.33333333%;
  }
  .offset-2 {
    margin-left: 16.66666667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.33333333%;
  }
  .offset-5 {
    margin-left: 41.66666667%;
  }
  .offset-6 {
    margin-left: 50%;
  }
  .offset-7 {
    margin-left: 58.33333333%;
  }
  .offset-8 {
    margin-left: 66.66666667%;
  }
  .offset-9 {
    margin-left: 75%;
  }
  .offset-10 {
    margin-left: 83.33333333%;
  }
  .offset-11 {
    margin-left: 91.66666667%;
  }
  .g-0,
  .gx-0 {
    --x-gutter-x: 0;
  }
  .g-0,
  .gy-0 {
    --x-gutter-y: 0;
  }
  .g-1,
  .gx-1 {
    --x-gutter-x: 0.25rem;
  }
  .g-1,
  .gy-1 {
    --x-gutter-y: 0.25rem;
  }
  .g-2,
  .gx-2 {
    --x-gutter-x: 0.5rem;
  }
  .g-2,
  .gy-2 {
    --x-gutter-y: 0.5rem;
  }
  .g-3,
  .gx-3 {
    --x-gutter-x: 0.75rem;
  }
  .g-3,
  .gy-3 {
    --x-gutter-y: 0.75rem;
  }
  .g-4,
  .gx-4 {
    --x-gutter-x: 1rem;
  }
  .g-4,
  .gy-4 {
    --x-gutter-y: 1rem;
  }
  .g-5,
  .gx-5 {
    --x-gutter-x: 1.25rem;
  }
  .g-5,
  .gy-5 {
    --x-gutter-y: 1.25rem;
  }
  .g-6,
  .gx-6 {
    --x-gutter-x: 1.5rem;
  }
  .g-6,
  .gy-6 {
    --x-gutter-y: 1.5rem;
  }
  .g-7,
  .gx-7 {
    --x-gutter-x: 1.75rem;
  }
  .g-7,
  .gy-7 {
    --x-gutter-y: 1.75rem;
  }
  .g-8,
  .gx-8 {
    --x-gutter-x: 2rem;
  }
  .g-8,
  .gy-8 {
    --x-gutter-y: 2rem;
  }
  .g-10,
  .gx-10 {
    --x-gutter-x: 2.5rem;
  }
  .g-10,
  .gy-10 {
    --x-gutter-y: 2.5rem;
  }
  .g-12,
  .gx-12 {
    --x-gutter-x: 3rem;
  }
  .g-12,
  .gy-12 {
    --x-gutter-y: 3rem;
  }
  .g-14,
  .gx-14 {
    --x-gutter-x: 3.5rem;
  }
  .g-14,
  .gy-14 {
    --x-gutter-y: 3.5rem;
  }
  .g-16,
  .gx-16 {
    --x-gutter-x: 4rem;
  }
  .g-16,
  .gy-16 {
    --x-gutter-y: 4rem;
  }
  .g-18,
  .gx-18 {
    --x-gutter-x: 4.5rem;
  }
  .g-18,
  .gy-18 {
    --x-gutter-y: 4.5rem;
  }
  .g-20,
  .gx-20 {
    --x-gutter-x: 5rem;
  }
  .g-20,
  .gy-20 {
    --x-gutter-y: 5rem;
  }
  .g-24,
  .gx-24 {
    --x-gutter-x: 6rem;
  }
  .g-24,
  .gy-24 {
    --x-gutter-y: 6rem;
  }
  .g-32,
  .gx-32 {
    --x-gutter-x: 8rem;
  }
  .g-32,
  .gy-32 {
    --x-gutter-y: 8rem;
  }
  .g-40,
  .gx-40 {
    --x-gutter-x: 10rem;
  }
  .g-40,
  .gy-40 {
    --x-gutter-y: 10rem;
  }
  .g-48,
  .gx-48 {
    --x-gutter-x: 12rem;
  }
  .g-48,
  .gy-48 {
    --x-gutter-y: 12rem;
  }
  .g-56,
  .gx-56 {
    --x-gutter-x: 14rem;
  }
  .g-56,
  .gy-56 {
    --x-gutter-y: 14rem;
  }
  .g-64,
  .gx-64 {
    --x-gutter-x: 16rem;
  }
  .g-64,
  .gy-64 {
    --x-gutter-y: 16rem;
  }
  .g-72,
  .gx-72 {
    --x-gutter-x: 18rem;
  }
  .g-72,
  .gy-72 {
    --x-gutter-y: 18rem;
  }
  .g-80,
  .gx-80 {
    --x-gutter-x: 20rem;
  }
  .g-80,
  .gy-80 {
    --x-gutter-y: 20rem;
  }
  .g-88,
  .gx-88 {
    --x-gutter-x: 22rem;
  }
  .g-88,
  .gy-88 {
    --x-gutter-y: 22rem;
  }
  .g-96,
  .gx-96 {
    --x-gutter-x: 24rem;
  }
  .g-96,
  .gy-96 {
    --x-gutter-y: 24rem;
  }
  .g-px,
  .gx-px {
    --x-gutter-x: 1px;
  }
  .g-px,
  .gy-px {
    --x-gutter-y: 1px;
  }
  @media (min-width: 576px) {
    .col-sm {
      flex: 1 0 0%;
    }
    .row-cols-sm-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-sm-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-sm-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-sm-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-sm-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-sm-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-sm-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-sm-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-sm-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-sm-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333333%;
    }
    .offset-sm-2 {
      margin-left: 16.66666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333333%;
    }
    .offset-sm-5 {
      margin-left: 41.66666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333333%;
    }
    .offset-sm-8 {
      margin-left: 66.66666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333333%;
    }
    .offset-sm-11 {
      margin-left: 91.66666667%;
    }
    .g-sm-0,
    .gx-sm-0 {
      --x-gutter-x: 0;
    }
    .g-sm-0,
    .gy-sm-0 {
      --x-gutter-y: 0;
    }
    .g-sm-1,
    .gx-sm-1 {
      --x-gutter-x: 0.25rem;
    }
    .g-sm-1,
    .gy-sm-1 {
      --x-gutter-y: 0.25rem;
    }
    .g-sm-2,
    .gx-sm-2 {
      --x-gutter-x: 0.5rem;
    }
    .g-sm-2,
    .gy-sm-2 {
      --x-gutter-y: 0.5rem;
    }
    .g-sm-3,
    .gx-sm-3 {
      --x-gutter-x: 0.75rem;
    }
    .g-sm-3,
    .gy-sm-3 {
      --x-gutter-y: 0.75rem;
    }
    .g-sm-4,
    .gx-sm-4 {
      --x-gutter-x: 1rem;
    }
    .g-sm-4,
    .gy-sm-4 {
      --x-gutter-y: 1rem;
    }
    .g-sm-5,
    .gx-sm-5 {
      --x-gutter-x: 1.25rem;
    }
    .g-sm-5,
    .gy-sm-5 {
      --x-gutter-y: 1.25rem;
    }
    .g-sm-6,
    .gx-sm-6 {
      --x-gutter-x: 1.5rem;
    }
    .g-sm-6,
    .gy-sm-6 {
      --x-gutter-y: 1.5rem;
    }
    .g-sm-7,
    .gx-sm-7 {
      --x-gutter-x: 1.75rem;
    }
    .g-sm-7,
    .gy-sm-7 {
      --x-gutter-y: 1.75rem;
    }
    .g-sm-8,
    .gx-sm-8 {
      --x-gutter-x: 2rem;
    }
    .g-sm-8,
    .gy-sm-8 {
      --x-gutter-y: 2rem;
    }
    .g-sm-10,
    .gx-sm-10 {
      --x-gutter-x: 2.5rem;
    }
    .g-sm-10,
    .gy-sm-10 {
      --x-gutter-y: 2.5rem;
    }
    .g-sm-12,
    .gx-sm-12 {
      --x-gutter-x: 3rem;
    }
    .g-sm-12,
    .gy-sm-12 {
      --x-gutter-y: 3rem;
    }
    .g-sm-14,
    .gx-sm-14 {
      --x-gutter-x: 3.5rem;
    }
    .g-sm-14,
    .gy-sm-14 {
      --x-gutter-y: 3.5rem;
    }
    .g-sm-16,
    .gx-sm-16 {
      --x-gutter-x: 4rem;
    }
    .g-sm-16,
    .gy-sm-16 {
      --x-gutter-y: 4rem;
    }
    .g-sm-18,
    .gx-sm-18 {
      --x-gutter-x: 4.5rem;
    }
    .g-sm-18,
    .gy-sm-18 {
      --x-gutter-y: 4.5rem;
    }
    .g-sm-20,
    .gx-sm-20 {
      --x-gutter-x: 5rem;
    }
    .g-sm-20,
    .gy-sm-20 {
      --x-gutter-y: 5rem;
    }
    .g-sm-24,
    .gx-sm-24 {
      --x-gutter-x: 6rem;
    }
    .g-sm-24,
    .gy-sm-24 {
      --x-gutter-y: 6rem;
    }
    .g-sm-32,
    .gx-sm-32 {
      --x-gutter-x: 8rem;
    }
    .g-sm-32,
    .gy-sm-32 {
      --x-gutter-y: 8rem;
    }
    .g-sm-40,
    .gx-sm-40 {
      --x-gutter-x: 10rem;
    }
    .g-sm-40,
    .gy-sm-40 {
      --x-gutter-y: 10rem;
    }
    .g-sm-48,
    .gx-sm-48 {
      --x-gutter-x: 12rem;
    }
    .g-sm-48,
    .gy-sm-48 {
      --x-gutter-y: 12rem;
    }
    .g-sm-56,
    .gx-sm-56 {
      --x-gutter-x: 14rem;
    }
    .g-sm-56,
    .gy-sm-56 {
      --x-gutter-y: 14rem;
    }
    .g-sm-64,
    .gx-sm-64 {
      --x-gutter-x: 16rem;
    }
    .g-sm-64,
    .gy-sm-64 {
      --x-gutter-y: 16rem;
    }
    .g-sm-72,
    .gx-sm-72 {
      --x-gutter-x: 18rem;
    }
    .g-sm-72,
    .gy-sm-72 {
      --x-gutter-y: 18rem;
    }
    .g-sm-80,
    .gx-sm-80 {
      --x-gutter-x: 20rem;
    }
    .g-sm-80,
    .gy-sm-80 {
      --x-gutter-y: 20rem;
    }
    .g-sm-88,
    .gx-sm-88 {
      --x-gutter-x: 22rem;
    }
    .g-sm-88,
    .gy-sm-88 {
      --x-gutter-y: 22rem;
    }
    .g-sm-96,
    .gx-sm-96 {
      --x-gutter-x: 24rem;
    }
    .g-sm-96,
    .gy-sm-96 {
      --x-gutter-y: 24rem;
    }
    .g-sm-px,
    .gx-sm-px {
      --x-gutter-x: 1px;
    }
    .g-sm-px,
    .gy-sm-px {
      --x-gutter-y: 1px;
    }
  }
  @media (min-width: 768px) {
    .col-md {
      flex: 1 0 0%;
    }
    .row-cols-md-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-md-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-md-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-md-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-md-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-md-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-md-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-md-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333333%;
    }
    .offset-md-2 {
      margin-left: 16.66666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333333%;
    }
    .offset-md-5 {
      margin-left: 41.66666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333333%;
    }
    .offset-md-8 {
      margin-left: 66.66666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333333%;
    }
    .offset-md-11 {
      margin-left: 91.66666667%;
    }
    .g-md-0,
    .gx-md-0 {
      --x-gutter-x: 0;
    }
    .g-md-0,
    .gy-md-0 {
      --x-gutter-y: 0;
    }
    .g-md-1,
    .gx-md-1 {
      --x-gutter-x: 0.25rem;
    }
    .g-md-1,
    .gy-md-1 {
      --x-gutter-y: 0.25rem;
    }
    .g-md-2,
    .gx-md-2 {
      --x-gutter-x: 0.5rem;
    }
    .g-md-2,
    .gy-md-2 {
      --x-gutter-y: 0.5rem;
    }
    .g-md-3,
    .gx-md-3 {
      --x-gutter-x: 0.75rem;
    }
    .g-md-3,
    .gy-md-3 {
      --x-gutter-y: 0.75rem;
    }
    .g-md-4,
    .gx-md-4 {
      --x-gutter-x: 1rem;
    }
    .g-md-4,
    .gy-md-4 {
      --x-gutter-y: 1rem;
    }
    .g-md-5,
    .gx-md-5 {
      --x-gutter-x: 1.25rem;
    }
    .g-md-5,
    .gy-md-5 {
      --x-gutter-y: 1.25rem;
    }
    .g-md-6,
    .gx-md-6 {
      --x-gutter-x: 1.5rem;
    }
    .g-md-6,
    .gy-md-6 {
      --x-gutter-y: 1.5rem;
    }
    .g-md-7,
    .gx-md-7 {
      --x-gutter-x: 1.75rem;
    }
    .g-md-7,
    .gy-md-7 {
      --x-gutter-y: 1.75rem;
    }
    .g-md-8,
    .gx-md-8 {
      --x-gutter-x: 2rem;
    }
    .g-md-8,
    .gy-md-8 {
      --x-gutter-y: 2rem;
    }
    .g-md-10,
    .gx-md-10 {
      --x-gutter-x: 2.5rem;
    }
    .g-md-10,
    .gy-md-10 {
      --x-gutter-y: 2.5rem;
    }
    .g-md-12,
    .gx-md-12 {
      --x-gutter-x: 3rem;
    }
    .g-md-12,
    .gy-md-12 {
      --x-gutter-y: 3rem;
    }
    .g-md-14,
    .gx-md-14 {
      --x-gutter-x: 3.5rem;
    }
    .g-md-14,
    .gy-md-14 {
      --x-gutter-y: 3.5rem;
    }
    .g-md-16,
    .gx-md-16 {
      --x-gutter-x: 4rem;
    }
    .g-md-16,
    .gy-md-16 {
      --x-gutter-y: 4rem;
    }
    .g-md-18,
    .gx-md-18 {
      --x-gutter-x: 4.5rem;
    }
    .g-md-18,
    .gy-md-18 {
      --x-gutter-y: 4.5rem;
    }
    .g-md-20,
    .gx-md-20 {
      --x-gutter-x: 5rem;
    }
    .g-md-20,
    .gy-md-20 {
      --x-gutter-y: 5rem;
    }
    .g-md-24,
    .gx-md-24 {
      --x-gutter-x: 6rem;
    }
    .g-md-24,
    .gy-md-24 {
      --x-gutter-y: 6rem;
    }
    .g-md-32,
    .gx-md-32 {
      --x-gutter-x: 8rem;
    }
    .g-md-32,
    .gy-md-32 {
      --x-gutter-y: 8rem;
    }
    .g-md-40,
    .gx-md-40 {
      --x-gutter-x: 10rem;
    }
    .g-md-40,
    .gy-md-40 {
      --x-gutter-y: 10rem;
    }
    .g-md-48,
    .gx-md-48 {
      --x-gutter-x: 12rem;
    }
    .g-md-48,
    .gy-md-48 {
      --x-gutter-y: 12rem;
    }
    .g-md-56,
    .gx-md-56 {
      --x-gutter-x: 14rem;
    }
    .g-md-56,
    .gy-md-56 {
      --x-gutter-y: 14rem;
    }
    .g-md-64,
    .gx-md-64 {
      --x-gutter-x: 16rem;
    }
    .g-md-64,
    .gy-md-64 {
      --x-gutter-y: 16rem;
    }
    .g-md-72,
    .gx-md-72 {
      --x-gutter-x: 18rem;
    }
    .g-md-72,
    .gy-md-72 {
      --x-gutter-y: 18rem;
    }
    .g-md-80,
    .gx-md-80 {
      --x-gutter-x: 20rem;
    }
    .g-md-80,
    .gy-md-80 {
      --x-gutter-y: 20rem;
    }
    .g-md-88,
    .gx-md-88 {
      --x-gutter-x: 22rem;
    }
    .g-md-88,
    .gy-md-88 {
      --x-gutter-y: 22rem;
    }
    .g-md-96,
    .gx-md-96 {
      --x-gutter-x: 24rem;
    }
    .g-md-96,
    .gy-md-96 {
      --x-gutter-y: 24rem;
    }
    .g-md-px,
    .gx-md-px {
      --x-gutter-x: 1px;
    }
    .g-md-px,
    .gy-md-px {
      --x-gutter-y: 1px;
    }
  }
  @media (min-width: 992px) {
    .col-lg {
      flex: 1 0 0%;
    }
    .row-cols-lg-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-lg-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-lg-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-lg-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-lg-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-lg-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-lg-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-lg-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333333%;
    }
    .offset-lg-2 {
      margin-left: 16.66666667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333333%;
    }
    .offset-lg-5 {
      margin-left: 41.66666667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333333%;
    }
    .offset-lg-8 {
      margin-left: 66.66666667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333333%;
    }
    .offset-lg-11 {
      margin-left: 91.66666667%;
    }
    .g-lg-0,
    .gx-lg-0 {
      --x-gutter-x: 0;
    }
    .g-lg-0,
    .gy-lg-0 {
      --x-gutter-y: 0;
    }
    .g-lg-1,
    .gx-lg-1 {
      --x-gutter-x: 0.25rem;
    }
    .g-lg-1,
    .gy-lg-1 {
      --x-gutter-y: 0.25rem;
    }
    .g-lg-2,
    .gx-lg-2 {
      --x-gutter-x: 0.5rem;
    }
    .g-lg-2,
    .gy-lg-2 {
      --x-gutter-y: 0.5rem;
    }
    .g-lg-3,
    .gx-lg-3 {
      --x-gutter-x: 0.75rem;
    }
    .g-lg-3,
    .gy-lg-3 {
      --x-gutter-y: 0.75rem;
    }
    .g-lg-4,
    .gx-lg-4 {
      --x-gutter-x: 1rem;
    }
    .g-lg-4,
    .gy-lg-4 {
      --x-gutter-y: 1rem;
    }
    .g-lg-5,
    .gx-lg-5 {
      --x-gutter-x: 1.25rem;
    }
    .g-lg-5,
    .gy-lg-5 {
      --x-gutter-y: 1.25rem;
    }
    .g-lg-6,
    .gx-lg-6 {
      --x-gutter-x: 1.5rem;
    }
    .g-lg-6,
    .gy-lg-6 {
      --x-gutter-y: 1.5rem;
    }
    .g-lg-7,
    .gx-lg-7 {
      --x-gutter-x: 1.75rem;
    }
    .g-lg-7,
    .gy-lg-7 {
      --x-gutter-y: 1.75rem;
    }
    .g-lg-8,
    .gx-lg-8 {
      --x-gutter-x: 2rem;
    }
    .g-lg-8,
    .gy-lg-8 {
      --x-gutter-y: 2rem;
    }
    .g-lg-10,
    .gx-lg-10 {
      --x-gutter-x: 2.5rem;
    }
    .g-lg-10,
    .gy-lg-10 {
      --x-gutter-y: 2.5rem;
    }
    .g-lg-12,
    .gx-lg-12 {
      --x-gutter-x: 3rem;
    }
    .g-lg-12,
    .gy-lg-12 {
      --x-gutter-y: 3rem;
    }
    .g-lg-14,
    .gx-lg-14 {
      --x-gutter-x: 3.5rem;
    }
    .g-lg-14,
    .gy-lg-14 {
      --x-gutter-y: 3.5rem;
    }
    .g-lg-16,
    .gx-lg-16 {
      --x-gutter-x: 4rem;
    }
    .g-lg-16,
    .gy-lg-16 {
      --x-gutter-y: 4rem;
    }
    .g-lg-18,
    .gx-lg-18 {
      --x-gutter-x: 4.5rem;
    }
    .g-lg-18,
    .gy-lg-18 {
      --x-gutter-y: 4.5rem;
    }
    .g-lg-20,
    .gx-lg-20 {
      --x-gutter-x: 5rem;
    }
    .g-lg-20,
    .gy-lg-20 {
      --x-gutter-y: 5rem;
    }
    .g-lg-24,
    .gx-lg-24 {
      --x-gutter-x: 6rem;
    }
    .g-lg-24,
    .gy-lg-24 {
      --x-gutter-y: 6rem;
    }
    .g-lg-32,
    .gx-lg-32 {
      --x-gutter-x: 8rem;
    }
    .g-lg-32,
    .gy-lg-32 {
      --x-gutter-y: 8rem;
    }
    .g-lg-40,
    .gx-lg-40 {
      --x-gutter-x: 10rem;
    }
    .g-lg-40,
    .gy-lg-40 {
      --x-gutter-y: 10rem;
    }
    .g-lg-48,
    .gx-lg-48 {
      --x-gutter-x: 12rem;
    }
    .g-lg-48,
    .gy-lg-48 {
      --x-gutter-y: 12rem;
    }
    .g-lg-56,
    .gx-lg-56 {
      --x-gutter-x: 14rem;
    }
    .g-lg-56,
    .gy-lg-56 {
      --x-gutter-y: 14rem;
    }
    .g-lg-64,
    .gx-lg-64 {
      --x-gutter-x: 16rem;
    }
    .g-lg-64,
    .gy-lg-64 {
      --x-gutter-y: 16rem;
    }
    .g-lg-72,
    .gx-lg-72 {
      --x-gutter-x: 18rem;
    }
    .g-lg-72,
    .gy-lg-72 {
      --x-gutter-y: 18rem;
    }
    .g-lg-80,
    .gx-lg-80 {
      --x-gutter-x: 20rem;
    }
    .g-lg-80,
    .gy-lg-80 {
      --x-gutter-y: 20rem;
    }
    .g-lg-88,
    .gx-lg-88 {
      --x-gutter-x: 22rem;
    }
    .g-lg-88,
    .gy-lg-88 {
      --x-gutter-y: 22rem;
    }
    .g-lg-96,
    .gx-lg-96 {
      --x-gutter-x: 24rem;
    }
    .g-lg-96,
    .gy-lg-96 {
      --x-gutter-y: 24rem;
    }
    .g-lg-px,
    .gx-lg-px {
      --x-gutter-x: 1px;
    }
    .g-lg-px,
    .gy-lg-px {
      --x-gutter-y: 1px;
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      flex: 1 0 0%;
    }
    .row-cols-xl-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-xl-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-xl-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-xl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-xl-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-xl-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-xl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-xl-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-xl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333333%;
    }
    .offset-xl-2 {
      margin-left: 16.66666667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333333%;
    }
    .offset-xl-5 {
      margin-left: 41.66666667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333333%;
    }
    .offset-xl-8 {
      margin-left: 66.66666667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333333%;
    }
    .offset-xl-11 {
      margin-left: 91.66666667%;
    }
    .g-xl-0,
    .gx-xl-0 {
      --x-gutter-x: 0;
    }
    .g-xl-0,
    .gy-xl-0 {
      --x-gutter-y: 0;
    }
    .g-xl-1,
    .gx-xl-1 {
      --x-gutter-x: 0.25rem;
    }
    .g-xl-1,
    .gy-xl-1 {
      --x-gutter-y: 0.25rem;
    }
    .g-xl-2,
    .gx-xl-2 {
      --x-gutter-x: 0.5rem;
    }
    .g-xl-2,
    .gy-xl-2 {
      --x-gutter-y: 0.5rem;
    }
    .g-xl-3,
    .gx-xl-3 {
      --x-gutter-x: 0.75rem;
    }
    .g-xl-3,
    .gy-xl-3 {
      --x-gutter-y: 0.75rem;
    }
    .g-xl-4,
    .gx-xl-4 {
      --x-gutter-x: 1rem;
    }
    .g-xl-4,
    .gy-xl-4 {
      --x-gutter-y: 1rem;
    }
    .g-xl-5,
    .gx-xl-5 {
      --x-gutter-x: 1.25rem;
    }
    .g-xl-5,
    .gy-xl-5 {
      --x-gutter-y: 1.25rem;
    }
    .g-xl-6,
    .gx-xl-6 {
      --x-gutter-x: 1.5rem;
    }
    .g-xl-6,
    .gy-xl-6 {
      --x-gutter-y: 1.5rem;
    }
    .g-xl-7,
    .gx-xl-7 {
      --x-gutter-x: 1.75rem;
    }
    .g-xl-7,
    .gy-xl-7 {
      --x-gutter-y: 1.75rem;
    }
    .g-xl-8,
    .gx-xl-8 {
      --x-gutter-x: 2rem;
    }
    .g-xl-8,
    .gy-xl-8 {
      --x-gutter-y: 2rem;
    }
    .g-xl-10,
    .gx-xl-10 {
      --x-gutter-x: 2.5rem;
    }
    .g-xl-10,
    .gy-xl-10 {
      --x-gutter-y: 2.5rem;
    }
    .g-xl-12,
    .gx-xl-12 {
      --x-gutter-x: 3rem;
    }
    .g-xl-12,
    .gy-xl-12 {
      --x-gutter-y: 3rem;
    }
    .g-xl-14,
    .gx-xl-14 {
      --x-gutter-x: 3.5rem;
    }
    .g-xl-14,
    .gy-xl-14 {
      --x-gutter-y: 3.5rem;
    }
    .g-xl-16,
    .gx-xl-16 {
      --x-gutter-x: 4rem;
    }
    .g-xl-16,
    .gy-xl-16 {
      --x-gutter-y: 4rem;
    }
    .g-xl-18,
    .gx-xl-18 {
      --x-gutter-x: 4.5rem;
    }
    .g-xl-18,
    .gy-xl-18 {
      --x-gutter-y: 4.5rem;
    }
    .g-xl-20,
    .gx-xl-20 {
      --x-gutter-x: 5rem;
    }
    .g-xl-20,
    .gy-xl-20 {
      --x-gutter-y: 5rem;
    }
    .g-xl-24,
    .gx-xl-24 {
      --x-gutter-x: 6rem;
    }
    .g-xl-24,
    .gy-xl-24 {
      --x-gutter-y: 6rem;
    }
    .g-xl-32,
    .gx-xl-32 {
      --x-gutter-x: 8rem;
    }
    .g-xl-32,
    .gy-xl-32 {
      --x-gutter-y: 8rem;
    }
    .g-xl-40,
    .gx-xl-40 {
      --x-gutter-x: 10rem;
    }
    .g-xl-40,
    .gy-xl-40 {
      --x-gutter-y: 10rem;
    }
    .g-xl-48,
    .gx-xl-48 {
      --x-gutter-x: 12rem;
    }
    .g-xl-48,
    .gy-xl-48 {
      --x-gutter-y: 12rem;
    }
    .g-xl-56,
    .gx-xl-56 {
      --x-gutter-x: 14rem;
    }
    .g-xl-56,
    .gy-xl-56 {
      --x-gutter-y: 14rem;
    }
    .g-xl-64,
    .gx-xl-64 {
      --x-gutter-x: 16rem;
    }
    .g-xl-64,
    .gy-xl-64 {
      --x-gutter-y: 16rem;
    }
    .g-xl-72,
    .gx-xl-72 {
      --x-gutter-x: 18rem;
    }
    .g-xl-72,
    .gy-xl-72 {
      --x-gutter-y: 18rem;
    }
    .g-xl-80,
    .gx-xl-80 {
      --x-gutter-x: 20rem;
    }
    .g-xl-80,
    .gy-xl-80 {
      --x-gutter-y: 20rem;
    }
    .g-xl-88,
    .gx-xl-88 {
      --x-gutter-x: 22rem;
    }
    .g-xl-88,
    .gy-xl-88 {
      --x-gutter-y: 22rem;
    }
    .g-xl-96,
    .gx-xl-96 {
      --x-gutter-x: 24rem;
    }
    .g-xl-96,
    .gy-xl-96 {
      --x-gutter-y: 24rem;
    }
    .g-xl-px,
    .gx-xl-px {
      --x-gutter-x: 1px;
    }
    .g-xl-px,
    .gy-xl-px {
      --x-gutter-y: 1px;
    }
  }
  @media (min-width: 1400px) {
    .col-xxl {
      flex: 1 0 0%;
    }
    .row-cols-xxl-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-xxl-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-xxl-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-xxl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-xxl-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-xxl-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-xxl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-xxl-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-xxl-0 {
      margin-left: 0;
    }
    .offset-xxl-1 {
      margin-left: 8.33333333%;
    }
    .offset-xxl-2 {
      margin-left: 16.66666667%;
    }
    .offset-xxl-3 {
      margin-left: 25%;
    }
    .offset-xxl-4 {
      margin-left: 33.33333333%;
    }
    .offset-xxl-5 {
      margin-left: 41.66666667%;
    }
    .offset-xxl-6 {
      margin-left: 50%;
    }
    .offset-xxl-7 {
      margin-left: 58.33333333%;
    }
    .offset-xxl-8 {
      margin-left: 66.66666667%;
    }
    .offset-xxl-9 {
      margin-left: 75%;
    }
    .offset-xxl-10 {
      margin-left: 83.33333333%;
    }
    .offset-xxl-11 {
      margin-left: 91.66666667%;
    }
    .g-xxl-0,
    .gx-xxl-0 {
      --x-gutter-x: 0;
    }
    .g-xxl-0,
    .gy-xxl-0 {
      --x-gutter-y: 0;
    }
    .g-xxl-1,
    .gx-xxl-1 {
      --x-gutter-x: 0.25rem;
    }
    .g-xxl-1,
    .gy-xxl-1 {
      --x-gutter-y: 0.25rem;
    }
    .g-xxl-2,
    .gx-xxl-2 {
      --x-gutter-x: 0.5rem;
    }
    .g-xxl-2,
    .gy-xxl-2 {
      --x-gutter-y: 0.5rem;
    }
    .g-xxl-3,
    .gx-xxl-3 {
      --x-gutter-x: 0.75rem;
    }
    .g-xxl-3,
    .gy-xxl-3 {
      --x-gutter-y: 0.75rem;
    }
    .g-xxl-4,
    .gx-xxl-4 {
      --x-gutter-x: 1rem;
    }
    .g-xxl-4,
    .gy-xxl-4 {
      --x-gutter-y: 1rem;
    }
    .g-xxl-5,
    .gx-xxl-5 {
      --x-gutter-x: 1.25rem;
    }
    .g-xxl-5,
    .gy-xxl-5 {
      --x-gutter-y: 1.25rem;
    }
    .g-xxl-6,
    .gx-xxl-6 {
      --x-gutter-x: 1.5rem;
    }
    .g-xxl-6,
    .gy-xxl-6 {
      --x-gutter-y: 1.5rem;
    }
    .g-xxl-7,
    .gx-xxl-7 {
      --x-gutter-x: 1.75rem;
    }
    .g-xxl-7,
    .gy-xxl-7 {
      --x-gutter-y: 1.75rem;
    }
    .g-xxl-8,
    .gx-xxl-8 {
      --x-gutter-x: 2rem;
    }
    .g-xxl-8,
    .gy-xxl-8 {
      --x-gutter-y: 2rem;
    }
    .g-xxl-10,
    .gx-xxl-10 {
      --x-gutter-x: 2.5rem;
    }
    .g-xxl-10,
    .gy-xxl-10 {
      --x-gutter-y: 2.5rem;
    }
    .g-xxl-12,
    .gx-xxl-12 {
      --x-gutter-x: 3rem;
    }
    .g-xxl-12,
    .gy-xxl-12 {
      --x-gutter-y: 3rem;
    }
    .g-xxl-14,
    .gx-xxl-14 {
      --x-gutter-x: 3.5rem;
    }
    .g-xxl-14,
    .gy-xxl-14 {
      --x-gutter-y: 3.5rem;
    }
    .g-xxl-16,
    .gx-xxl-16 {
      --x-gutter-x: 4rem;
    }
    .g-xxl-16,
    .gy-xxl-16 {
      --x-gutter-y: 4rem;
    }
    .g-xxl-18,
    .gx-xxl-18 {
      --x-gutter-x: 4.5rem;
    }
    .g-xxl-18,
    .gy-xxl-18 {
      --x-gutter-y: 4.5rem;
    }
    .g-xxl-20,
    .gx-xxl-20 {
      --x-gutter-x: 5rem;
    }
    .g-xxl-20,
    .gy-xxl-20 {
      --x-gutter-y: 5rem;
    }
    .g-xxl-24,
    .gx-xxl-24 {
      --x-gutter-x: 6rem;
    }
    .g-xxl-24,
    .gy-xxl-24 {
      --x-gutter-y: 6rem;
    }
    .g-xxl-32,
    .gx-xxl-32 {
      --x-gutter-x: 8rem;
    }
    .g-xxl-32,
    .gy-xxl-32 {
      --x-gutter-y: 8rem;
    }
    .g-xxl-40,
    .gx-xxl-40 {
      --x-gutter-x: 10rem;
    }
    .g-xxl-40,
    .gy-xxl-40 {
      --x-gutter-y: 10rem;
    }
    .g-xxl-48,
    .gx-xxl-48 {
      --x-gutter-x: 12rem;
    }
    .g-xxl-48,
    .gy-xxl-48 {
      --x-gutter-y: 12rem;
    }
    .g-xxl-56,
    .gx-xxl-56 {
      --x-gutter-x: 14rem;
    }
    .g-xxl-56,
    .gy-xxl-56 {
      --x-gutter-y: 14rem;
    }
    .g-xxl-64,
    .gx-xxl-64 {
      --x-gutter-x: 16rem;
    }
    .g-xxl-64,
    .gy-xxl-64 {
      --x-gutter-y: 16rem;
    }
    .g-xxl-72,
    .gx-xxl-72 {
      --x-gutter-x: 18rem;
    }
    .g-xxl-72,
    .gy-xxl-72 {
      --x-gutter-y: 18rem;
    }
    .g-xxl-80,
    .gx-xxl-80 {
      --x-gutter-x: 20rem;
    }
    .g-xxl-80,
    .gy-xxl-80 {
      --x-gutter-y: 20rem;
    }
    .g-xxl-88,
    .gx-xxl-88 {
      --x-gutter-x: 22rem;
    }
    .g-xxl-88,
    .gy-xxl-88 {
      --x-gutter-y: 22rem;
    }
    .g-xxl-96,
    .gx-xxl-96 {
      --x-gutter-x: 24rem;
    }
    .g-xxl-96,
    .gy-xxl-96 {
      --x-gutter-y: 24rem;
    }
    .g-xxl-px,
    .gx-xxl-px {
      --x-gutter-x: 1px;
    }
    .g-xxl-px,
    .gy-xxl-px {
      --x-gutter-y: 1px;
    }
  }
  .lead {
    font-size: 1.125rem;
    font-weight: 400;
  }
  .display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: bolder;
    line-height: 1.3;
  }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem;
    }
  }
  .display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: bolder;
    line-height: 1.3;
  }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem;
    }
  }
  .display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: bolder;
    line-height: 1.3;
  }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem;
    }
  }
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: bolder;
    line-height: 1.3;
  }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem;
    }
  }
  .display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: bolder;
    line-height: 1.3;
  }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem;
    }
  }
  .display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: bolder;
    line-height: 1.3;
  }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem;
    }
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  .list-inline-item {
    display: inline-block;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .initialism {
    font-size: 0.875em;
    text-transform: uppercase;
  }
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  .blockquote > :last-child {
    margin-bottom: 0;
  }
  .blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 0.875em;
    color: #6b7b93;
  }
  .blockquote-footer::before {
    content: "— ";
  }
  .surtitle {
    color: #525f7f;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .text-highlight-primary {
    background-image: linear-gradient(
      transparent 66%,
      rgba(92, 96, 245, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-secondary {
    background-image: linear-gradient(
      transparent 66%,
      rgba(207, 214, 223, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-tertiary {
    background-image: linear-gradient(
      transparent 66%,
      rgba(255, 87, 154, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-success {
    background-image: linear-gradient(
      transparent 66%,
      rgba(0, 204, 136, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-info {
    background-image: linear-gradient(
      transparent 66%,
      rgba(0, 212, 255, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-warning {
    background-image: linear-gradient(
      transparent 66%,
      rgba(255, 140, 0, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-danger {
    background-image: linear-gradient(
      transparent 66%,
      rgba(255, 51, 102, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-white {
    background-image: linear-gradient(
      transparent 66%,
      rgba(255, 255, 255, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-light {
    background-image: linear-gradient(
      transparent 66%,
      rgba(231, 234, 240, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-dark {
    background-image: linear-gradient(
      transparent 66%,
      rgba(22, 25, 44, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .dropcaps > p {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .dropcaps > p:first-child:first-letter {
    font-size: 3.5em;
    font-weight: 700;
    float: left;
    line-height: 1;
    margin-top: 0.05em;
    margin-right: 0.15em;
  }
  .form-label {
    margin-bottom: 0.5rem;
    /* font-size: 0.875rem; */
    /* font-weight: 500; */
    color: #16192c;
  }
  .col-form-label {
    padding-top: calc(0.75rem + 1px);
    padding-bottom: calc(0.75rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    font-weight: 500;
    line-height: 1.3;
    color: #16192c;
  }
  .col-form-label-lg {
    padding-top: calc(1rem + 1px);
    padding-bottom: calc(1rem + 1px);
    font-size: 1rem;
  }
  .col-form-label-sm {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 0.875rem;
  }
  .form-text {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #6b7b93;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3;
    color: #16192c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e7eaf0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  .form-control[type="file"] {
    overflow: hidden;
  }
  .form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer;
  }
  .form-control:focus {
    color: #16192c;
    background-color: #fff;
    border-color: #4066D5;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #4066D540;
  }
  .form-control::-webkit-date-and-time-value {
    height: 1.3em;
  }
  .form-control::-moz-placeholder {
    color: #8898a9;
    opacity: 1;
  }
  .form-control::placeholder {
    color: #8898a9;
    opacity: 1;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e7eaf0;
    opacity: 1;
  }
  .form-control::-webkit-file-upload-button {
    padding: 0.75rem 1.25rem;
    margin: -0.75rem -1.25rem;
    -webkit-margin-end: 1.25rem;
    margin-inline-end: 1.25rem;
    color: #16192c;
    background-color: #f5f9fc;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-control::file-selector-button {
    padding: 0.75rem 1.25rem;
    margin: -0.75rem -1.25rem;
    -webkit-margin-end: 1.25rem;
    margin-inline-end: 1.25rem;
    color: #16192c;
    background-color: #f5f9fc;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none;
    }
    .form-control::file-selector-button {
      transition: none;
    }
  }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #e9edef;
  }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #e9edef;
  }
  .form-control::-webkit-file-upload-button {
    padding: 0.75rem 1.25rem;
    margin: -0.75rem -1.25rem;
    -webkit-margin-end: 1.25rem;
    margin-inline-end: 1.25rem;
    color: #16192c;
    background-color: #f5f9fc;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #e9edef;
  }
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.75rem 0;
    margin-bottom: 0;
    line-height: 1.3;
    color: #525f7f;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }
  .form-control-plaintext.form-control-sm,
  .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
  .form-control-sm {
    min-height: calc(1.3em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
  }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
  }
  .form-control-sm::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
  }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
  }
  .form-control-lg {
    min-height: calc(1.3em + 2rem + 2px);
    padding: 1rem 1.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
  .form-control-lg::-webkit-file-upload-button {
    padding: 1rem 1.5rem;
    margin: -1rem -1.5rem;
    -webkit-margin-end: 1.5rem;
    margin-inline-end: 1.5rem;
  }
  .form-control-lg::file-selector-button {
    padding: 1rem 1.5rem;
    margin: -1rem -1.5rem;
    -webkit-margin-end: 1.5rem;
    margin-inline-end: 1.5rem;
  }
  .form-control-lg::-webkit-file-upload-button {
    padding: 1rem 1.5rem;
    margin: -1rem -1.5rem;
    -webkit-margin-end: 1.5rem;
    margin-inline-end: 1.5rem;
  }
  textarea.form-control {
    min-height: calc(1.3em + 1.5rem + 2px);
  }
  textarea.form-control-sm {
    min-height: calc(1.3em + 1rem + 2px);
  }
  textarea.form-control-lg {
    min-height: calc(1.3em + 2rem + 2px);
  }
  .form-control-color {
    width: 3rem;
    height: auto;
    padding: 0.75rem;
  }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer;
  }
  .form-control-color::-moz-color-swatch {
    height: 1.3em;
    border-radius: 0.375rem;
  }
  .form-control-color::-webkit-color-swatch {
    height: 1.3em;
    border-radius: 0.375rem;
  }
  .form-select {
    display: block;
    width: 100%;
    padding: 0.75rem 3.75rem 0.75rem 1.25rem;
    -moz-padding-start: calc(1.25rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3;
    color: #16192c;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1.25rem center;
    background-size: 16px 12px;
    border: 1px solid #e7eaf0;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none;
    }
  }
  .form-select:focus {
    border-color: #4066D5;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #e9faff7e;
  }
  .form-select[multiple],
  .form-select[size]:not([size="1"]) {
    padding-right: 1.25rem;
    background-image: none;
  }
  .form-select:disabled {
    color: #8898a9;
    background-color: #e7eaf0;
  }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #4066D5;
  }
  .form-select-sm {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 0.875rem;
  }
  .form-select-lg {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    font-size: 1rem;
  }
  .form-check {
    display: block;
    min-height: 1.625rem;
    padding-left: 1.5em;
    margin-bottom: 0;
  }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
  }
  .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.3125em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em;
  }
  .form-check-input[type="radio"] {
    border-radius: 50%;
  }
  .form-check-input:active {
    filter: brightness(90%);
  }
  .form-check-input:focus {
    border-color: #4066D5;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(92, 96, 245, 0.25);
  }
  .form-check-input:checked {
    background-color: #4066D5;
    border-color: #4066D5;
  }
  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFF'/%3e%3c/svg%3e");
  }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #4066D5;
    border-color: #4066D5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
  }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5;
  }
  .form-switch {
    padding-left: 3.375em;
  }
  .form-switch .form-check-input {
    width: 2.875em;
    margin-left: -3.375em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2.875em;
    transition: background-position 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
      transition: none;
    }
  }
  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235C60F5'/%3e%3c/svg%3e");
  }
  .form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
  }
  .form-check-inline {
    display: inline-block;
    margin-right: 1rem;
  }
  .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  .btn-check[disabled] + .btn,
  .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65;
  }
  .form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .form-range:focus {
    outline: 0;
  }
  .form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(92, 96, 245, 0.25);
  }
  .form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(92, 96, 245, 0.25);
  }
  .form-range::-moz-focus-outer {
    border: 0;
  }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #4066D5;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-range::-webkit-slider-thumb:active {
    background-color: #cecffc;
  }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #cfd6df;
    border-color: transparent;
    border-radius: 1rem;
  }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #4066D5;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
    }
  }
  .form-range::-moz-range-thumb:active {
    background-color: #cecffc;
  }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #cfd6df;
    border-color: transparent;
    border-radius: 1rem;
  }
  .form-range:disabled {
    pointer-events: none;
  }
  .form-range:disabled::-webkit-slider-thumb {
    background-color: #8898a9;
  }
  .form-range:disabled::-moz-range-thumb {
    background-color: #8898a9;
  }
  .form-floating {
    position: relative;
  }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
  }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 1.25rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-floating > label {
      transition: none;
    }
  }
  .form-floating > .form-control {
    padding: 1rem 1.25rem;
  }
  .form-floating > .form-control::-moz-placeholder {
    color: transparent;
  }
  .form-floating > .form-control::placeholder {
    color: transparent;
  }
  .form-floating > .form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:focus,
  .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3;
  }
  .input-group .btn {
    position: relative;
    z-index: 2;
  }
  .input-group .btn:focus {
    z-index: 3;
  }
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3;
    color: #6b7b93;
    text-align: center;
    white-space: nowrap;
    background-color: #f5f9fc;
    border: 1px solid #e7eaf0;
    border-radius: 0.375rem;
  }
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    padding: 1rem 1.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
  .input-group-sm > .form-control,
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text,
  .input-group-sm > .btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
  }
  .input-group-lg > .form-select,
  .input-group-sm > .form-select {
    padding-right: 5rem;
  }
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group.has-validation
    > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #0c8;
  }
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(0, 204, 136, 0.9);
    border-radius: 0.375rem;
  }
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip,
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip {
    display: block;
  }
  .was-validated .form-control:valid,
  .form-control.is-valid {
    border-color: #0c8;
    padding-right: calc(1.3em + 1.5rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CC88' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.325em + 0.375rem) center;
    background-size: calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
  }
  .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus {
    border-color: #0c8;
    box-shadow: 0 0 0 0.25rem rgba(0, 204, 136, 0.25);
  }
  .was-validated textarea.form-control:valid,
  textarea.form-control.is-valid {
    padding-right: calc(1.3em + 1.5rem);
    background-position: top calc(0.325em + 0.375rem) right
      calc(0.325em + 0.375rem);
  }
  .was-validated .form-select:valid,
  .form-select.is-valid {
    border-color: #0c8;
  }
  .was-validated .form-select:valid:not([multiple]):not([size]),
  .was-validated .form-select:valid:not([multiple])[size="1"],
  .form-select.is-valid:not([multiple]):not([size]),
  .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 6.875rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CC88' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 1.25rem center, center right 3.75rem;
    background-size: 16px 12px, calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
  }
  .was-validated .form-select:valid:focus,
  .form-select.is-valid:focus {
    border-color: #0c8;
    box-shadow: 0 0 0 0.25rem rgba(0, 204, 136, 0.25);
  }
  .was-validated .form-check-input:valid,
  .form-check-input.is-valid {
    border-color: #0c8;
  }
  .was-validated .form-check-input:valid:checked,
  .form-check-input.is-valid:checked {
    background-color: #0c8;
  }
  .was-validated .form-check-input:valid:focus,
  .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 204, 136, 0.25);
  }
  .was-validated .form-check-input:valid ~ .form-check-label,
  .form-check-input.is-valid ~ .form-check-label {
    color: #0c8;
  }
  .form-check-inline .form-check-input ~ .valid-feedback {
    margin-left: 0.5em;
  }
  .was-validated .input-group .form-control:valid,
  .input-group .form-control.is-valid,
  .was-validated .input-group .form-select:valid,
  .input-group .form-select.is-valid {
    z-index: 1;
  }
  .was-validated .input-group .form-control:valid:focus,
  .input-group .form-control.is-valid:focus,
  .was-validated .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3;
  }
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #f36;
  }
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(255, 51, 102, 0.9);
    border-radius: 0.375rem;
  }
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip,
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip {
    display: block;
  }
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    border-color: #f36;
    padding-right: calc(1.3em + 1.5rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF3366'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF3366' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.325em + 0.375rem) center;
    background-size: calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
  }
  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus {
    border-color: #f36;
    box-shadow: 0 0 0 0.25rem rgba(255, 51, 102, 0.25);
  }
  .was-validated textarea.form-control:invalid,
  textarea.form-control.is-invalid {
    padding-right: calc(1.3em + 1.5rem);
    background-position: top calc(0.325em + 0.375rem) right
      calc(0.325em + 0.375rem);
  }
  .was-validated .form-select:invalid,
  .form-select.is-invalid {
    border-color: #f36;
  }
  .was-validated .form-select:invalid:not([multiple]):not([size]),
  .was-validated .form-select:invalid:not([multiple])[size="1"],
  .form-select.is-invalid:not([multiple]):not([size]),
  .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 6.875rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF3366'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF3366' stroke='none'/%3e%3c/svg%3e");
    background-position: right 1.25rem center, center right 3.75rem;
    background-size: 16px 12px, calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
  }
  .was-validated .form-select:invalid:focus,
  .form-select.is-invalid:focus {
    border-color: #f36;
    box-shadow: 0 0 0 0.25rem rgba(255, 51, 102, 0.25);
  }
  .was-validated .form-check-input:invalid,
  .form-check-input.is-invalid {
    border-color: #f36;
  }
  .was-validated .form-check-input:invalid:checked,
  .form-check-input.is-invalid:checked {
    background-color: #f36;
  }
  .was-validated .form-check-input:invalid:focus,
  .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 51, 102, 0.25);
  }
  .was-validated .form-check-input:invalid ~ .form-check-label,
  .form-check-input.is-invalid ~ .form-check-label {
    color: #f36;
  }
  .form-check-inline .form-check-input ~ .invalid-feedback {
    margin-left: 0.5em;
  }
  .was-validated .input-group .form-control:invalid,
  .input-group .form-control.is-invalid,
  .was-validated .input-group .form-select:invalid,
  .input-group .form-select.is-invalid {
    z-index: 2;
  }
  .was-validated .input-group .form-control:invalid:focus,
  .input-group .form-control.is-invalid:focus,
  .was-validated .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3;
  }
  .form-control:focus::-moz-placeholder {
    color: #abb6c5;
  }
  .form-control:focus::placeholder {
    color: #abb6c5;
  }
  .form-control-muted {
    background-color: #eef0f5;
    border-color: #eef0f5;
  }
  .form-control-muted:focus {
    background-color: #f1f2f7;
  }
  .form-control-alt {
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .form-control-alt:focus {
    background-color: #fff;
    border-color: transparent;
    box-shadow: 0 0 0 2px #4066D5;
  }
  .form-control-flush {
    padding: 0;
    border-width: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .form-control-flush:focus {
    background-color: transparent;
    box-shadow: none;
    border-width: 0;
  }
  .form-check-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #16192c;
  }
  textarea[resize="none"] {
    resize: none !important;
  }
  textarea[resize="both"] {
    resize: both !important;
  }
  textarea[resize="vertical"] {
    resize: vertical !important;
  }
  textarea[resize="horizontal"] {
    resize: horizontal !important;
  }
  .textarea-autosize {
    display: grid;
  }
  .textarea-autosize:after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }
  .textarea-autosize > textarea {
    resize: none;
    overflow: hidden;
  }
  .textarea-autosize > textarea,
  .textarea-autosize:after {
    border: 1px solid #000;
    padding: 0.5rem;
    font: inherit;
    grid-area: 1/1/2/2;
  }
  .form-group-stacked .form-control,
  .form-group-stacked .form-select {
    position: relative;
    box-sizing: border-box;
    height: auto;
  }
  .form-group-stacked .form-control:focus,
  .form-group-stacked .form-select:focus {
    z-index: 2;
  }
  .form-group-stacked > :first-child .form-control,
  .form-group-stacked > :first-child .form-select {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-group-stacked > :last-child .form-control,
  .form-group-stacked > :last-child .form-select {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .form-group-stacked > .row {
    margin: 0;
  }
  .form-group-stacked > .row > :first-child,
  .form-group-stacked > .row > :last-child {
    padding: 0;
  }
  .form-group-stacked > .row > :first-child .form-control,
  .form-group-stacked > .row > :first-child .form-select {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-group-stacked > .row > :last-child .form-control,
  .form-group-stacked > .row > :last-child .form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-dark .form-control {
    color: rgba(255, 255, 255, 0.9);
    background-color: #2d3041;
    border: 1px solid #2d3041;
  }
  .form-dark .form-control::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-dark .form-control::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-dark .form-control:focus {
    background-color: #222537;
    border: 1px solid #222537;
  }
  .form-dark .form-control:focus::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .form-dark .form-control:focus::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .form-dark .input-group .input-group-text {
    background-color: #2d3041;
    border-color: #2d3041;
    color: rgba(255, 255, 255, 0.9);
  }
  .form-dark .input-group:focus-within .input-group-text {
    background-color: #222537;
    border-color: #222537;
  }
  .form-stacked .form-control {
    position: relative;
    box-sizing: border-box;
  }
  .form-stacked .form-control:first-of-type {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-stacked .form-control:not(:first-of-type):not(:last-of-type) {
    margin-bottom: -1px;
    border-radius: 0;
  }
  .form-stacked .form-control:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .form-stacked .form-control:focus {
    z-index: 2;
  }
  .input-group {
    border-radius: 0.375rem;
  }
  .input-group .form-control {
    box-shadow: none;
  }
  .input-group:focus-within {
    box-shadow: 0 0 0 0.25rem rgba(92, 96, 245, 0.25);
  }
  .input-group:focus-within .input-group-text {
    border-color: #4066D5;
  }
  .input-group:focus-within .form-control {
    box-shadow: none;
    border-color: #4066D5;
  }
  .input-group-text {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .input-group-flush .form-control,
  .input-group-flush .input-group-text {
    border: 0 !important;
  }
  .input-group-inline {
    position: relative;
    border-radius: 0.375rem;
  }
  .input-group-inline.input-group-sm {
    border-radius: 0.375rem;
  }
  .input-group-inline.input-group-lg {
    border-radius: 0.5rem;
  }
  .input-group-inline .input-group-text {
    background: #fff;
  }
  .input-group-inline
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0 !important;
    border-left: 0;
    padding-left: 0.25rem;
  }
  .input-group-inline
    > :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-right: 0 !important;
    border-right: 0;
    padding-right: 0.25rem;
  }
  .form-switch {
    min-height: 1.5rem;
  }
  .form-switch > .form-check-input {
    height: 1.5rem;
    margin-top: 0;
  }
  .form-switch > .form-check-label {
    margin-top: calc((1.5rem - 1.625 * 1em) / 2);
  }
  .was-validated .input-group:valid .form-control,
  .was-validated .input-group:valid .form-select,
  .was-validated .input-group:valid .input-group-text,
  .input-group.is-valid .form-control,
  .input-group.is-valid .form-select,
  .input-group.is-valid .input-group-text {
    border-color: #0c8;
  }
  .was-validated .input-group:valid:focus-within,
  .input-group.is-valid:focus-within {
    border-color: #0c8;
    box-shadow: 0 0 0 0.25rem rgba(0, 204, 136, 0.25);
  }
  .was-validated .input-group:invalid .form-control,
  .was-validated .input-group:invalid .form-select,
  .was-validated .input-group:invalid .input-group-text,
  .input-group.is-invalid .form-control,
  .input-group.is-invalid .form-select,
  .input-group.is-invalid .input-group-text {
    border-color: #f36;
  }
  .was-validated .input-group:invalid:focus-within,
  .input-group.is-invalid:focus-within {
    border-color: #f36;
    box-shadow: 0 0 0 0.25rem rgba(255, 51, 102, 0.25);
  }
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.5rem 1.25rem;
    font-size: 1rem;
    color: #525f7f;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      border-radius 0.15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none;
    }
  }
  .accordion-button:not(.collapsed) {
    color: #16192c;
    background-color: transparent;
    box-shadow: inset 0 -1px 0 #e7eaf0;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2316192C'%3e%3cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3e%3c/svg%3e");
    transform: rotate(180deg);
  }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23525F7F'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
      transition: none;
    }
  }
  .accordion-button:hover {
    z-index: 2;
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: #e7eaf0;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(92, 96, 245, 0.25);
  }
  .accordion-header {
    margin-bottom: 0;
  }
  .accordion-item {
    background-color: transparent;
    border: 1px solid #e7eaf0;
  }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
  }
  .accordion-item:not(:first-of-type) {
    border-top: 0;
  }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(0.375rem - 1px);
    border-bottom-left-radius: calc(0.375rem - 1px);
  }
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .accordion-body {
    padding: 1.5rem 1.25rem;
  }
  .accordion-flush .accordion-collapse {
    border-width: 0;
  }
  .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  .accordion-flush .accordion-item:first-child {
    border-top: 0;
  }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0;
  }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
  }
  .accordion-card .card-header {
    position: relative;
    cursor: pointer;
  }
  .accordion-card .card-header:after {
    content: "+";
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-family: var(--x-font-sans-serif);
    font-weight: 700;
  }
  .accordion-card .card-header[aria-expanded="false"]:after {
    content: "+";
  }
  .accordion-card .card-header[aria-expanded="true"]:after {
    content: "-";
  }
  .accordion-card .card-header[aria-expanded="true"] .heading {
    color: theme-color("primary");
  }
  .accordion-spaced > .card {
    border-radius: 0.75rem !important;
  }
  .accordion-spaced > .card:not(:last-of-type) {
    margin-bottom: 1.5rem;
    border: 1px solid #eceef3;
  }
  .alert {
    position: relative;
    padding: 0.875rem 1rem;
    margin-bottom: 0;
    border: 1px solid transparent;
    border-radius: 0.375rem;
  }
  .alert-heading {
    color: inherit;
  }
  .alert-link {
    font-weight: 600;
  }
  .alert-dismissible {
    padding-right: 3rem;
  }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.09375rem 1rem;
  }
  .alert-primary {
    color: #5356dd;
    background-color: #efeffe;
    border-color: #dedffd;
  }
  .alert-primary .alert-link {
    color: #4245b1;
  }
  .alert-secondary {
    color: #151516;
    background-color: #fafbfc;
    border-color: #f5f7f9;
  }
  .alert-secondary .alert-link {
    color: #111112;
  }
  .alert-tertiary {
    color: #e64e8b;
    background-color: #ffeef5;
    border-color: #ffddeb;
  }
  .alert-tertiary .alert-link {
    color: #b83e6f;
  }
  .alert-success {
    color: #00b87a;
    background-color: #e6faf3;
    border-color: #ccf5e7;
  }
  .alert-success .alert-link {
    color: #009362;
  }
  .alert-info {
    color: #00bfe6;
    background-color: #e6fbff;
    border-color: #ccf6ff;
  }
  .alert-info .alert-link {
    color: #0099b8;
  }
  .alert-warning {
    color: #e67e00;
    background-color: #fff4e6;
    border-color: #ffe8cc;
  }
  .alert-warning .alert-link {
    color: #b86500;
  }
  .alert-danger {
    color: #e62e5c;
    background-color: #ffebf0;
    border-color: #ffd6e0;
  }
  .alert-danger .alert-link {
    color: #b8254a;
  }
  .alert-white {
    color: #1a1a1a;
    background-color: #fff;
    border-color: #fff;
  }
  .alert-white .alert-link {
    color: #151515;
  }
  .alert-light {
    color: #171718;
    background-color: #fdfdfe;
    border-color: #fafbfc;
  }
  .alert-light .alert-link {
    color: #121213;
  }
  .alert-dark {
    color: #141728;
    background-color: #e8e8ea;
    border-color: #d0d1d5;
  }
  .alert-dark .alert-link {
    color: #101220;
  }
  .avatar {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 600;
    height: 2.875rem;
    width: 2.875rem;
    border-radius: 0.375rem;
  }
  .avatar img {
    width: 100%;
    border-radius: inherit;
  }
  .avatar + .avatar {
    margin-left: 0.25rem;
  }
  .avatar + .avatar-content {
    display: inline-block;
    margin-left: 0.75rem;
  }
  .avatar-2xl {
    width: 7.5rem;
    height: 7.5rem;
    font-size: calc(1.375rem + 1.5vw);
  }
  @media (min-width: 1200px) {
    .avatar-2xl {
      font-size: 2.5rem;
    }
  }
  .avatar-xl {
    width: 6rem;
    height: 6rem;
    font-size: calc(1.2625rem + 0.15vw);
  }
  @media (min-width: 1200px) {
    .avatar-xl {
      font-size: 1.375rem;
    }
  }
  .avatar-lg {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.25rem;
  }
  .avatar-sm {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
  }
  .avatar-xs {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.675rem;
    border-radius: 0.25rem;
  }
  .avatar-group {
    display: inline-block;
    line-height: 1;
  }
  .avatar-group .avatar {
    z-index: 1;
    transition: margin 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .avatar-group .avatar {
      transition: none;
    }
  }
  .avatar-group .avatar:hover {
    z-index: 2;
  }
  .avatar-group .avatar + .avatar {
    margin-left: -1.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .avatar-group .avatar-sm + .avatar-sm,
  .avatar-group .avatar-xs + .avatar-xs {
    margin-left: -1rem;
  }
  .avatar-group:hover .avatar {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .avatar-group:hover .avatar-sm {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .avatar-ungroup-hover:hover .avatar:not(:first-child) {
    margin-left: 0;
  }
  .badge {
    display: inline-block;
    padding: 0.2rem 0.6rem;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
  }
  .badge:empty {
    display: none;
  }
  .btn .badge {
    position: relative;
    top: -1px;
  }
  .badge {
    line-height: 1rem;
  }
  .badge-xs {
    padding: 0.1rem 0.5rem;
    font-size: 60%;
  }
  .badge-md {
    padding: 0.4rem 1rem;
  }
  .badge-lg {
    padding: 0.6rem 1rem;
    font-size: 1em;
  }
  .badge-count {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: middle;
    font-size: 0.75rem;
    font-weight: 600;
  }
  .badge-count.badge-xs {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;
  }
  .badge-count.badge-md {
    width: 1.5rem;
    height: 1.5rem;
  }
  .badge-count.badge-lg {
    width: 2rem;
    height: 2rem;
  }
  .badge-count svg {
    margin: 0;
  }
  .badge-dot {
    display: inline-flex;
    align-items: center;
    padding: 0;
    background: transparent;
    font-weight: 400;
    color: #525f7f;
  }
  .badge-dot i {
    display: inline-block;
    vertical-align: middle;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  .badge-dot.badge-md i {
    width: 0.5rem;
    height: 0.5rem;
  }
  .badge-dot.badge-lg i {
    width: 0.625rem;
    height: 0.625rem;
  }
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 0;
    font-size: 0.875rem;
    list-style: none;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.375rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.375rem;
    color: #abb6c5;
    content: var(
      --x-breadcrumb-divider,
      "/"
    ); /* rtl: var(--x-breadcrumb-divider, "/") */
  }
  .breadcrumb-item.active {
    color: #16192c;
  }
  .btn {
    display: inline-block;
    font-weight: 500;
    line-height: 1.3;
    color: #525f7f;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  .btn:hover {
    color: #525f7f;
  }
  .btn-check:focus + .btn,
  .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(92, 96, 245, 0.25);
  }
  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
  }
  .btn-primary {
    color: #fff;
    background-color: #4066D5;
    border-color: #4066D5;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #4066D5;
    border-color: #4066D5;
  }
  .btn-check:focus + .btn-primary,
  .btn-primary:focus {
    color: #fff;
    background-color: #4066D5;
    border-color: #4066D5;
    box-shadow: 0 0 0 0.25rem #4066D588;
  }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary,
  .btn-primary:active,
  .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4066D5;
    border-color: #4066D5;
  }
  .btn-check:checked + .btn-primary:focus,
  .btn-check:active + .btn-primary:focus,
  .btn-primary:active:focus,
  .btn-primary.active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem #4066D582;
  }
  .btn-primary:disabled,
  .btn-primary.disabled {
    color: #fff;
    background-color: #4066D5;
    border-color: #4066D5;
  }
  .btn-secondary {
    color: #000;
    background-color: #cfd6df;
    border-color: #cfd6df;
  }
  .btn-secondary:hover {
    color: #000;
    background-color: #d6dce4;
    border-color: #d4dae2;
  }
  .btn-check:focus + .btn-secondary,
  .btn-secondary:focus {
    color: #000;
    background-color: #d6dce4;
    border-color: #d4dae2;
    box-shadow: 0 0 0 0.25rem rgba(176, 182, 190, 0.5);
  }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary,
  .btn-secondary:active,
  .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #000;
    background-color: #d9dee5;
    border-color: #d4dae2;
  }
  .btn-check:checked + .btn-secondary:focus,
  .btn-check:active + .btn-secondary:focus,
  .btn-secondary:active:focus,
  .btn-secondary.active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(176, 182, 190, 0.5);
  }
  .btn-secondary:disabled,
  .btn-secondary.disabled {
    color: #000;
    background-color: #cfd6df;
    border-color: #cfd6df;
  }
  .btn-tertiary {
    color: #fff;
    background-color: #ff579a;
    border-color: #ff579a;
  }
  .btn-tertiary:hover {
    color: #fff;
    background-color: #d94a83;
    border-color: #cc467b;
  }
  .btn-check:focus + .btn-tertiary,
  .btn-tertiary:focus {
    color: #fff;
    background-color: #d94a83;
    border-color: #cc467b;
    box-shadow: 0 0 0 0.25rem rgba(255, 112, 169, 0.5);
  }
  .btn-check:checked + .btn-tertiary,
  .btn-check:active + .btn-tertiary,
  .btn-tertiary:active,
  .btn-tertiary.active,
  .show > .btn-tertiary.dropdown-toggle {
    color: #fff;
    background-color: #cc467b;
    border-color: #bf4174;
  }
  .btn-check:checked + .btn-tertiary:focus,
  .btn-check:active + .btn-tertiary:focus,
  .btn-tertiary:active:focus,
  .btn-tertiary.active:focus,
  .show > .btn-tertiary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 112, 169, 0.5);
  }
  .btn-tertiary:disabled,
  .btn-tertiary.disabled {
    color: #fff;
    background-color: #ff579a;
    border-color: #ff579a;
  }
  .btn-success {
    color: #fff;
    background-color: #0c8;
    border-color: #0c8;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #00ad74;
    border-color: #00a36d;
  }
  .btn-check:focus + .btn-success,
  .btn-success:focus {
    color: #fff;
    background-color: #00ad74;
    border-color: #00a36d;
    box-shadow: 0 0 0 0.25rem rgba(38, 212, 154, 0.5);
  }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success,
  .btn-success:active,
  .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #00a36d;
    border-color: #096;
  }
  .btn-check:checked + .btn-success:focus,
  .btn-check:active + .btn-success:focus,
  .btn-success:active:focus,
  .btn-success.active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(38, 212, 154, 0.5);
  }
  .btn-success:disabled,
  .btn-success.disabled {
    color: #fff;
    background-color: #0c8;
    border-color: #0c8;
  }
  .btn-info {
    color: #000;
    background-color: #00d4ff;
    border-color: #00d4ff;
  }
  .btn-info:hover {
    color: #000;
    background-color: #26daff;
    border-color: #1ad8ff;
  }
  .btn-check:focus + .btn-info,
  .btn-info:focus {
    color: #000;
    background-color: #26daff;
    border-color: #1ad8ff;
    box-shadow: 0 0 0 0.25rem rgba(0, 180, 217, 0.5);
  }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info,
  .btn-info:active,
  .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #3df;
    border-color: #1ad8ff;
  }
  .btn-check:checked + .btn-info:focus,
  .btn-check:active + .btn-info:focus,
  .btn-info:active:focus,
  .btn-info.active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 180, 217, 0.5);
  }
  .btn-info:disabled,
  .btn-info.disabled {
    color: #000;
    background-color: #00d4ff;
    border-color: #00d4ff;
  }
  .btn-warning {
    color: #fff;
    background-color: #ff8c00;
    border-color: #ff8c00;
  }
  .btn-warning:hover {
    color: #fff;
    background-color: #d97700;
    border-color: #cc7000;
  }
  .btn-check:focus + .btn-warning,
  .btn-warning:focus {
    color: #fff;
    background-color: #d97700;
    border-color: #cc7000;
    box-shadow: 0 0 0 0.25rem rgba(255, 157, 38, 0.5);
  }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning,
  .btn-warning:active,
  .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7000;
    border-color: #bf6900;
  }
  .btn-check:checked + .btn-warning:focus,
  .btn-check:active + .btn-warning:focus,
  .btn-warning:active:focus,
  .btn-warning.active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 157, 38, 0.5);
  }
  .btn-warning:disabled,
  .btn-warning.disabled {
    color: #fff;
    background-color: #ff8c00;
    border-color: #ff8c00;
  }
  .btn-danger {
    color: #fff;
    background-color: #f36;
    border-color: #f36;
  }
  .btn-danger:hover {
    color: #fff;
    background-color: #d92b57;
    border-color: #cc2952;
  }
  .btn-check:focus + .btn-danger,
  .btn-danger:focus {
    color: #fff;
    background-color: #d92b57;
    border-color: #cc2952;
    box-shadow: 0 0 0 0.25rem rgba(255, 82, 125, 0.5);
  }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger,
  .btn-danger:active,
  .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #cc2952;
    border-color: #bf264d;
  }
  .btn-check:checked + .btn-danger:focus,
  .btn-check:active + .btn-danger:focus,
  .btn-danger:active:focus,
  .btn-danger.active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 82, 125, 0.5);
  }
  .btn-danger:disabled,
  .btn-danger.disabled {
    color: #fff;
    background-color: #f36;
    border-color: #f36;
  }
  .btn-white {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-white:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-check:focus + .btn-white,
  .btn-white:focus {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
  }
  .btn-check:checked + .btn-white,
  .btn-check:active + .btn-white,
  .btn-white:active,
  .btn-white.active,
  .show > .btn-white.dropdown-toggle {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-check:checked + .btn-white:focus,
  .btn-check:active + .btn-white:focus,
  .btn-white:active:focus,
  .btn-white.active:focus,
  .show > .btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
  }
  .btn-white:disabled,
  .btn-white.disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-light {
    color: #000;
    background-color: #e7eaf0;
    border-color: #e7eaf0;
  }
  .btn-light:hover {
    color: #000;
    background-color: #ebedf2;
    border-color: #e9ecf2;
  }
  .btn-check:focus + .btn-light,
  .btn-light:focus {
    color: #000;
    background-color: #ebedf2;
    border-color: #e9ecf2;
    box-shadow: 0 0 0 0.25rem rgba(196, 199, 204, 0.5);
  }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light,
  .btn-light:active,
  .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #eceef3;
    border-color: #e9ecf2;
  }
  .btn-check:checked + .btn-light:focus,
  .btn-check:active + .btn-light:focus,
  .btn-light:active:focus,
  .btn-light.active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(196, 199, 204, 0.5);
  }
  .btn-light:disabled,
  .btn-light.disabled {
    color: #000;
    background-color: #e7eaf0;
    border-color: #e7eaf0;
  }
  .btn-dark {
    color: #fff;
    background-color: #16192c;
    border-color: #16192c;
  }
  .btn-dark:hover {
    color: #fff;
    background-color: #131525;
    border-color: #121423;
  }
  .btn-check:focus + .btn-dark,
  .btn-dark:focus {
    color: #fff;
    background-color: #131525;
    border-color: #121423;
    box-shadow: 0 0 0 0.25rem rgba(57, 60, 76, 0.5);
  }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark,
  .btn-dark:active,
  .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #121423;
    border-color: #111321;
  }
  .btn-check:checked + .btn-dark:focus,
  .btn-check:active + .btn-dark:focus,
  .btn-dark:active:focus,
  .btn-dark.active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(57, 60, 76, 0.5);
  }
  .btn-dark:disabled,
  .btn-dark.disabled {
    color: #fff;
    background-color: #16192c;
    border-color: #16192c;
  }
  .btn-outline-primary {
    color: #4066D5;
    border-color: #4066D5;
  }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #4066D5;
    border-color: #4066D5;
  }
  .btn-check:focus + .btn-outline-primary,
  .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(92, 96, 245, 0.5);
  }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary,
  .btn-outline-primary:active,
  .btn-outline-primary.active,
  .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #4066D5;
    border-color: #4066D5;
  }
  .btn-check:checked + .btn-outline-primary:focus,
  .btn-check:active + .btn-outline-primary:focus,
  .btn-outline-primary:active:focus,
  .btn-outline-primary.active:focus,
  .btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(92, 96, 245, 0.5);
  }
  .btn-outline-primary:disabled,
  .btn-outline-primary.disabled {
    color: #4066D5;
    background-color: transparent;
  }
  .btn-outline-secondary {
    color: #cfd6df;
    border-color: #cfd6df;
  }
  .btn-outline-secondary:hover {
    color: #000;
    background-color: #cfd6df;
    border-color: #cfd6df;
  }
  .btn-check:focus + .btn-outline-secondary,
  .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(207, 214, 223, 0.5);
  }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary,
  .btn-outline-secondary:active,
  .btn-outline-secondary.active,
  .btn-outline-secondary.dropdown-toggle.show {
    color: #000;
    background-color: #cfd6df;
    border-color: #cfd6df;
  }
  .btn-check:checked + .btn-outline-secondary:focus,
  .btn-check:active + .btn-outline-secondary:focus,
  .btn-outline-secondary:active:focus,
  .btn-outline-secondary.active:focus,
  .btn-outline-secondary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(207, 214, 223, 0.5);
  }
  .btn-outline-secondary:disabled,
  .btn-outline-secondary.disabled {
    color: #cfd6df;
    background-color: transparent;
  }
  .btn-outline-tertiary {
    color: #ff579a;
    border-color: #ff579a;
  }
  .btn-outline-tertiary:hover {
    color: #fff;
    background-color: #ff579a;
    border-color: #ff579a;
  }
  .btn-check:focus + .btn-outline-tertiary,
  .btn-outline-tertiary:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 87, 154, 0.5);
  }
  .btn-check:checked + .btn-outline-tertiary,
  .btn-check:active + .btn-outline-tertiary,
  .btn-outline-tertiary:active,
  .btn-outline-tertiary.active,
  .btn-outline-tertiary.dropdown-toggle.show {
    color: #fff;
    background-color: #ff579a;
    border-color: #ff579a;
  }
  .btn-check:checked + .btn-outline-tertiary:focus,
  .btn-check:active + .btn-outline-tertiary:focus,
  .btn-outline-tertiary:active:focus,
  .btn-outline-tertiary.active:focus,
  .btn-outline-tertiary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 87, 154, 0.5);
  }
  .btn-outline-tertiary:disabled,
  .btn-outline-tertiary.disabled {
    color: #ff579a;
    background-color: transparent;
  }
  .btn-outline-success {
    color: #0c8;
    border-color: #0c8;
  }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #0c8;
    border-color: #0c8;
  }
  .btn-check:focus + .btn-outline-success,
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 204, 136, 0.5);
  }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success,
  .btn-outline-success:active,
  .btn-outline-success.active,
  .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #0c8;
    border-color: #0c8;
  }
  .btn-check:checked + .btn-outline-success:focus,
  .btn-check:active + .btn-outline-success:focus,
  .btn-outline-success:active:focus,
  .btn-outline-success.active:focus,
  .btn-outline-success.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 204, 136, 0.5);
  }
  .btn-outline-success:disabled,
  .btn-outline-success.disabled {
    color: #0c8;
    background-color: transparent;
  }
  .btn-outline-info {
    color: #00d4ff;
    border-color: #00d4ff;
  }
  .btn-outline-info:hover {
    color: #000;
    background-color: #00d4ff;
    border-color: #00d4ff;
  }
  .btn-check:focus + .btn-outline-info,
  .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 212, 255, 0.5);
  }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info,
  .btn-outline-info:active,
  .btn-outline-info.active,
  .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #00d4ff;
    border-color: #00d4ff;
  }
  .btn-check:checked + .btn-outline-info:focus,
  .btn-check:active + .btn-outline-info:focus,
  .btn-outline-info:active:focus,
  .btn-outline-info.active:focus,
  .btn-outline-info.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 212, 255, 0.5);
  }
  .btn-outline-info:disabled,
  .btn-outline-info.disabled {
    color: #00d4ff;
    background-color: transparent;
  }
  .btn-outline-warning {
    color: #ff8c00;
    border-color: #ff8c00;
  }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff8c00;
    border-color: #ff8c00;
  }
  .btn-check:focus + .btn-outline-warning,
  .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 140, 0, 0.5);
  }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning,
  .btn-outline-warning:active,
  .btn-outline-warning.active,
  .btn-outline-warning.dropdown-toggle.show {
    color: #fff;
    background-color: #ff8c00;
    border-color: #ff8c00;
  }
  .btn-check:checked + .btn-outline-warning:focus,
  .btn-check:active + .btn-outline-warning:focus,
  .btn-outline-warning:active:focus,
  .btn-outline-warning.active:focus,
  .btn-outline-warning.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 140, 0, 0.5);
  }
  .btn-outline-warning:disabled,
  .btn-outline-warning.disabled {
    color: #ff8c00;
    background-color: transparent;
  }
  .btn-outline-danger {
    color: #f36;
    border-color: #f36;
  }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f36;
    border-color: #f36;
  }
  .btn-check:focus + .btn-outline-danger,
  .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 51, 102, 0.5);
  }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger,
  .btn-outline-danger:active,
  .btn-outline-danger.active,
  .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #f36;
    border-color: #f36;
  }
  .btn-check:checked + .btn-outline-danger:focus,
  .btn-check:active + .btn-outline-danger:focus,
  .btn-outline-danger:active:focus,
  .btn-outline-danger.active:focus,
  .btn-outline-danger.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 51, 102, 0.5);
  }
  .btn-outline-danger:disabled,
  .btn-outline-danger.disabled {
    color: #f36;
    background-color: transparent;
  }
  .btn-outline-white {
    color: #fff;
    border-color: #fff;
  }
  .btn-outline-white:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-check:focus + .btn-outline-white,
  .btn-outline-white:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
  }
  .btn-check:checked + .btn-outline-white,
  .btn-check:active + .btn-outline-white,
  .btn-outline-white:active,
  .btn-outline-white.active,
  .btn-outline-white.dropdown-toggle.show {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-check:checked + .btn-outline-white:focus,
  .btn-check:active + .btn-outline-white:focus,
  .btn-outline-white:active:focus,
  .btn-outline-white.active:focus,
  .btn-outline-white.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
  }
  .btn-outline-white:disabled,
  .btn-outline-white.disabled {
    color: #fff;
    background-color: transparent;
  }
  .btn-outline-light {
    color: #e7eaf0;
    border-color: #e7eaf0;
  }
  .btn-outline-light:hover {
    color: #000;
    background-color: #e7eaf0;
    border-color: #e7eaf0;
  }
  .btn-check:focus + .btn-outline-light,
  .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(231, 234, 240, 0.5);
  }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light,
  .btn-outline-light:active,
  .btn-outline-light.active,
  .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #e7eaf0;
    border-color: #e7eaf0;
  }
  .btn-check:checked + .btn-outline-light:focus,
  .btn-check:active + .btn-outline-light:focus,
  .btn-outline-light:active:focus,
  .btn-outline-light.active:focus,
  .btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(231, 234, 240, 0.5);
  }
  .btn-outline-light:disabled,
  .btn-outline-light.disabled {
    color: #e7eaf0;
    background-color: transparent;
  }
  .btn-outline-dark {
    color: #16192c;
    border-color: #16192c;
  }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #16192c;
    border-color: #16192c;
  }
  .btn-check:focus + .btn-outline-dark,
  .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(22, 25, 44, 0.5);
  }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark,
  .btn-outline-dark:active,
  .btn-outline-dark.active,
  .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #16192c;
    border-color: #16192c;
  }
  .btn-check:checked + .btn-outline-dark:focus,
  .btn-check:active + .btn-outline-dark:focus,
  .btn-outline-dark:active:focus,
  .btn-outline-dark.active:focus,
  .btn-outline-dark.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(22, 25, 44, 0.5);
  }
  .btn-outline-dark:disabled,
  .btn-outline-dark.disabled {
    color: #16192c;
    background-color: transparent;
  }
  .btn-link {
    font-weight: 400;
    color: #4066D5;
    text-decoration: none;
  }
  .btn-link:hover {
    color: #4e52d0;
    text-decoration: none;
  }
  .btn-link:focus {
    text-decoration: none;
  }
  .btn-link:disabled,
  .btn-link.disabled {
    color: #6b7b93;
  }
  .btn-lg,
  .btn-group-lg > .btn {
    padding: 1rem 1.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
  }
  .btn-soft-primary {
    color: #4066D5;
    background-color: #cecffc;
    border-color: #cecffc;
  }
  .btn-soft-primary:hover {
    color: #fff;
    background-color: #4066D5;
    border-color: #fff;
  }
  .btn-check:focus + .btn-soft-primary,
  .btn-soft-primary:focus {
    color: #fff;
    background-color: #4066D5;
    border-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(189, 190, 251, 0.5);
  }
  .btn-check:checked + .btn-soft-primary,
  .btn-check:active + .btn-soft-primary,
  .btn-soft-primary:active,
  .btn-soft-primary.active,
  .show > .btn-soft-primary.dropdown-toggle {
    color: #000;
    background-color: #d8d9fd;
    border-color: #d3d4fc;
  }
  .btn-check:checked + .btn-soft-primary:focus,
  .btn-check:active + .btn-soft-primary:focus,
  .btn-soft-primary:active:focus,
  .btn-soft-primary.active:focus,
  .show > .btn-soft-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(189, 190, 251, 0.5);
  }
  .btn-soft-primary:disabled,
  .btn-soft-primary.disabled {
    color: #000;
    background-color: #cecffc;
    border-color: #cecffc;
  }
  .btn-soft-secondary {
    color: #cfd6df;
    background-color: #f1f3f5;
    border-color: #f1f3f5;
  }
  .btn-soft-secondary:hover {
    color: #000;
    background-color: #cfd6df;
    border-color: #000;
  }
  .btn-check:focus + .btn-soft-secondary,
  .btn-soft-secondary:focus {
    color: #000;
    background-color: #cfd6df;
    border-color: #000;
    box-shadow: 0 0 0 0.25rem rgba(236, 239, 242, 0.5);
  }
  .btn-check:checked + .btn-soft-secondary,
  .btn-check:active + .btn-soft-secondary,
  .btn-soft-secondary:active,
  .btn-soft-secondary.active,
  .show > .btn-soft-secondary.dropdown-toggle {
    color: #000;
    background-color: #f4f5f7;
    border-color: #f2f4f6;
  }
  .btn-check:checked + .btn-soft-secondary:focus,
  .btn-check:active + .btn-soft-secondary:focus,
  .btn-soft-secondary:active:focus,
  .btn-soft-secondary.active:focus,
  .show > .btn-soft-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(236, 239, 242, 0.5);
  }
  .btn-soft-secondary:disabled,
  .btn-soft-secondary.disabled {
    color: #000;
    background-color: #f1f3f5;
    border-color: #f1f3f5;
  }
  .btn-soft-tertiary {
    color: #ff579a;
    background-color: #ffcde1;
    border-color: #ffcde1;
  }
  .btn-soft-tertiary:hover {
    color: #fff;
    background-color: #ff579a;
    border-color: #fff;
  }
  .btn-check:focus + .btn-soft-tertiary,
  .btn-soft-tertiary:focus {
    color: #fff;
    background-color: #ff579a;
    border-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(255, 187, 214, 0.5);
  }
  .btn-check:checked + .btn-soft-tertiary,
  .btn-check:active + .btn-soft-tertiary,
  .btn-soft-tertiary:active,
  .btn-soft-tertiary.active,
  .show > .btn-soft-tertiary.dropdown-toggle {
    color: #000;
    background-color: #ffd7e7;
    border-color: #ffd2e4;
  }
  .btn-check:checked + .btn-soft-tertiary:focus,
  .btn-check:active + .btn-soft-tertiary:focus,
  .btn-soft-tertiary:active:focus,
  .btn-soft-tertiary.active:focus,
  .show > .btn-soft-tertiary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 187, 214, 0.5);
  }
  .btn-soft-tertiary:disabled,
  .btn-soft-tertiary.disabled {
    color: #000;
    background-color: #ffcde1;
    border-color: #ffcde1;
  }
  .btn-soft-success {
    color: #0c8;
    background-color: #b3f0db;
    border-color: #b3f0db;
  }
  .btn-soft-success:hover {
    color: #fff;
    background-color: #0c8;
    border-color: #fff;
  }
  .btn-check:focus + .btn-soft-success,
  .btn-soft-success:focus {
    color: #fff;
    background-color: #0c8;
    border-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(152, 235, 207, 0.5);
  }
  .btn-check:checked + .btn-soft-success,
  .btn-check:active + .btn-soft-success,
  .btn-soft-success:active,
  .btn-soft-success.active,
  .show > .btn-soft-success.dropdown-toggle {
    color: #000;
    background-color: #c2f3e2;
    border-color: #bbf2df;
  }
  .btn-check:checked + .btn-soft-success:focus,
  .btn-check:active + .btn-soft-success:focus,
  .btn-soft-success:active:focus,
  .btn-soft-success.active:focus,
  .show > .btn-soft-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(152, 235, 207, 0.5);
  }
  .btn-soft-success:disabled,
  .btn-soft-success.disabled {
    color: #000;
    background-color: #b3f0db;
    border-color: #b3f0db;
  }
  .btn-soft-info {
    color: #00d4ff;
    background-color: #b3f2ff;
    border-color: #b3f2ff;
  }
  .btn-soft-info:hover {
    color: #000;
    background-color: #00d4ff;
    border-color: #000;
  }
  .btn-check:focus + .btn-soft-info,
  .btn-soft-info:focus {
    color: #000;
    background-color: #00d4ff;
    border-color: #000;
    box-shadow: 0 0 0 0.25rem rgba(152, 238, 255, 0.5);
  }
  .btn-check:checked + .btn-soft-info,
  .btn-check:active + .btn-soft-info,
  .btn-soft-info:active,
  .btn-soft-info.active,
  .show > .btn-soft-info.dropdown-toggle {
    color: #000;
    background-color: #c2f5ff;
    border-color: #bbf3ff;
  }
  .btn-check:checked + .btn-soft-info:focus,
  .btn-check:active + .btn-soft-info:focus,
  .btn-soft-info:active:focus,
  .btn-soft-info.active:focus,
  .show > .btn-soft-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(152, 238, 255, 0.5);
  }
  .btn-soft-info:disabled,
  .btn-soft-info.disabled {
    color: #000;
    background-color: #b3f2ff;
    border-color: #b3f2ff;
  }
  .btn-soft-warning {
    color: #ff8c00;
    background-color: #ffddb3;
    border-color: #ffddb3;
  }
  .btn-soft-warning:hover {
    color: #fff;
    background-color: #ff8c00;
    border-color: #fff;
  }
  .btn-check:focus + .btn-soft-warning,
  .btn-soft-warning:focus {
    color: #fff;
    background-color: #ff8c00;
    border-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(255, 209, 152, 0.5);
  }
  .btn-check:checked + .btn-soft-warning,
  .btn-check:active + .btn-soft-warning,
  .btn-soft-warning:active,
  .btn-soft-warning.active,
  .show > .btn-soft-warning.dropdown-toggle {
    color: #000;
    background-color: #ffe4c2;
    border-color: #ffe0bb;
  }
  .btn-check:checked + .btn-soft-warning:focus,
  .btn-check:active + .btn-soft-warning:focus,
  .btn-soft-warning:active:focus,
  .btn-soft-warning.active:focus,
  .show > .btn-soft-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 209, 152, 0.5);
  }
  .btn-soft-warning:disabled,
  .btn-soft-warning.disabled {
    color: #000;
    background-color: #ffddb3;
    border-color: #ffddb3;
  }
  .btn-soft-danger {
    color: #f36;
    background-color: #ffc2d1;
    border-color: #ffc2d1;
  }
  .btn-soft-danger:hover {
    color: #fff;
    background-color: #f36;
    border-color: #fff;
  }
  .btn-check:focus + .btn-soft-danger,
  .btn-soft-danger:focus {
    color: #fff;
    background-color: #f36;
    border-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(255, 173, 193, 0.5);
  }
  .btn-check:checked + .btn-soft-danger,
  .btn-check:active + .btn-soft-danger,
  .btn-soft-danger:active,
  .btn-soft-danger.active,
  .show > .btn-soft-danger.dropdown-toggle {
    color: #000;
    background-color: #ffceda;
    border-color: #ffc8d6;
  }
  .btn-check:checked + .btn-soft-danger:focus,
  .btn-check:active + .btn-soft-danger:focus,
  .btn-soft-danger:active:focus,
  .btn-soft-danger.active:focus,
  .show > .btn-soft-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 173, 193, 0.5);
  }
  .btn-soft-danger:disabled,
  .btn-soft-danger.disabled {
    color: #000;
    background-color: #ffc2d1;
    border-color: #ffc2d1;
  }
  .btn-soft-white {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-soft-white:hover {
    color: #000;
    background-color: #fff;
    border-color: #000;
  }
  .btn-check:focus + .btn-soft-white,
  .btn-soft-white:focus {
    color: #000;
    background-color: #fff;
    border-color: #000;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
  }
  .btn-check:checked + .btn-soft-white,
  .btn-check:active + .btn-soft-white,
  .btn-soft-white:active,
  .btn-soft-white.active,
  .show > .btn-soft-white.dropdown-toggle {
    color: #000;
    background-color: #ccc;
    border-color: #bfbfbf;
  }
  .btn-check:checked + .btn-soft-white:focus,
  .btn-check:active + .btn-soft-white:focus,
  .btn-soft-white:active:focus,
  .btn-soft-white.active:focus,
  .show > .btn-soft-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
  }
  .btn-soft-white:disabled,
  .btn-soft-white.disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-soft-light {
    color: #e7eaf0;
    background-color: #f8f9fb;
    border-color: #f8f9fb;
  }
  .btn-soft-light:hover {
    color: #000;
    background-color: #e7eaf0;
    border-color: #000;
  }
  .btn-check:focus + .btn-soft-light,
  .btn-soft-light:focus {
    color: #000;
    background-color: #e7eaf0;
    border-color: #000;
    box-shadow: 0 0 0 0.25rem rgba(245, 247, 249, 0.5);
  }
  .btn-check:checked + .btn-soft-light,
  .btn-check:active + .btn-soft-light,
  .btn-soft-light:active,
  .btn-soft-light.active,
  .show > .btn-soft-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafc;
    border-color: #f9fafb;
  }
  .btn-check:checked + .btn-soft-light:focus,
  .btn-check:active + .btn-soft-light:focus,
  .btn-soft-light:active:focus,
  .btn-soft-light.active:focus,
  .show > .btn-soft-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(245, 247, 249, 0.5);
  }
  .btn-soft-light:disabled,
  .btn-soft-light.disabled {
    color: #000;
    background-color: #f8f9fb;
    border-color: #f8f9fb;
  }
  .btn-soft-dark {
    color: #16192c;
    background-color: #b9bac0;
    border-color: #b9bac0;
  }
  .btn-soft-dark:hover {
    color: #fff;
    background-color: #16192c;
    border-color: #fff;
  }
  .btn-check:focus + .btn-soft-dark,
  .btn-soft-dark:focus {
    color: #fff;
    background-color: #16192c;
    border-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(161, 162, 170, 0.5);
  }
  .btn-check:checked + .btn-soft-dark,
  .btn-check:active + .btn-soft-dark,
  .btn-soft-dark:active,
  .btn-soft-dark.active,
  .show > .btn-soft-dark.dropdown-toggle {
    color: #000;
    background-color: #c7c8cd;
    border-color: #c0c1c6;
  }
  .btn-check:checked + .btn-soft-dark:focus,
  .btn-check:active + .btn-soft-dark:focus,
  .btn-soft-dark:active:focus,
  .btn-soft-dark.active:focus,
  .show > .btn-soft-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(161, 162, 170, 0.5);
  }
  .btn-soft-dark:disabled,
  .btn-soft-dark.disabled {
    color: #000;
    background-color: #b9bac0;
    border-color: #b9bac0;
  }
  .btn-neutral {
    color: #16192c;
    background-color: #fff;
    border-color: #e7eaf0;
  }
  .btn-neutral:hover {
    color: #16192c;
    background-color: #e7eaf0;
    border-color: #e7eaf0;
  }
  .btn-check:focus + .btn-neutral,
  .btn-neutral:focus {
    color: #16192c;
    background-color: #e7eaf0;
    border-color: #e7eaf0;
    box-shadow: 0 0 0 0.25rem rgba(200, 203, 211, 0.5);
  }
  .btn-check:checked + .btn-neutral,
  .btn-check:active + .btn-neutral,
  .btn-neutral:active,
  .btn-neutral.active,
  .show > .btn-neutral.dropdown-toggle {
    color: #000;
    background-color: #fff;
    border-color: #e9ecf2;
  }
  .btn-check:checked + .btn-neutral:focus,
  .btn-check:active + .btn-neutral:focus,
  .btn-neutral:active:focus,
  .btn-neutral.active:focus,
  .show > .btn-neutral.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(200, 203, 211, 0.5);
  }
  .btn-neutral:disabled,
  .btn-neutral.disabled {
    color: #000;
    background-color: #fff;
    border-color: #e7eaf0;
  }
  .btn-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.875rem;
    height: 2.875rem;
    padding: 0;
  }
  .btn-square.btn-xs {
    width: 1.75rem;
    height: 1.75rem;
  }
  .btn-square.btn-sm,
  .btn-group-sm > .btn-square.btn {
    width: 2.25rem;
    height: 2.25rem;
  }
  .btn-square.btn-lg,
  .btn-group-lg > .btn-square.btn {
    width: 3.5rem;
    height: 3.5rem;
  }
  .btn-square.btn-xl {
    width: 6rem;
    height: 6rem;
  }
  .btn-square.btn-2xl {
    width: 7.5rem;
    height: 7.5rem;
  }
  .btn-app {
    background: #000;
    color: #fff;
    position: relative;
    padding-left: 4.5rem;
    padding-right: 1.5rem;
    text-align: left;
  }
  .btn-app i,
  .btn-app svg {
    width: 1em;
    font-size: 2.5rem;
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-app .btn-text {
    display: block;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-bottom: 0.125rem;
  }
  .btn-app .btn-brand {
    display: block;
    text-align: left;
    font-size: 21px;
    line-height: 21px;
  }
  .btn-app:hover {
    background-color: #5659e4;
    color: #fff;
  }
  .btn-animated {
    position: relative;
    overflow: hidden;
  }
  .btn-animated .btn-inner-visible {
    position: relative;
  }
  .btn-animated .btn-inner-hidden {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-animated-x .btn-inner-visible {
    right: 0;
    transition: right 0.3s ease 0s;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn-animated-x .btn-inner-visible {
      transition: none;
    }
  }
  .btn-animated-x .btn-inner-hidden {
    right: -100%;
    transition: right 0.3s ease 0s;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn-animated-x .btn-inner-hidden {
      transition: none;
    }
  }
  .btn-animated-x:hover .btn-inner-hidden {
    right: 0;
  }
  .btn-animated-x:hover .btn-inner-visible {
    right: 150%;
  }
  .btn-animated-y .btn-inner-visible {
    top: 0;
    transition: top 0.3s ease 0s;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn-animated-y .btn-inner-visible {
      transition: none;
    }
  }
  .btn-animated-y .btn-inner-hidden {
    left: 0;
    top: -100%;
    transition: top 0.3s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn-animated-y .btn-inner-hidden {
      transition: none;
    }
  }
  .btn-animated-y:hover .btn-inner-hidden {
    top: 50%;
  }
  .btn-animated-y:hover .btn-inner-visible {
    top: 100px;
  }
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto;
  }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1;
  }
  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .btn-toolbar .input-group {
    width: auto;
  }
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px;
  }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .dropdown-toggle-split {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0;
  }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0;
  }
  .btn-sm + .dropdown-toggle-split,
  .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .btn-lg + .dropdown-toggle-split,
  .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
  }
  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%;
  }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px;
  }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .carousel {
    position: relative;
  }
  .carousel.pointer-event {
    touch-action: pan-y;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
  }
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none;
    }
  }
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }
  .carousel-item-next:not(.carousel-item-start),
  .active.carousel-item-end {
    transform: translateX(100%);
  }
  .carousel-item-prev:not(.carousel-item-end),
  .active.carousel-item-start {
    transform: translateX(-100%);
  }
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-start,
  .carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1;
  }
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none;
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none;
    }
  }
  .carousel-control-prev:hover,
  .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  .carousel-control-prev {
    left: 0;
  }
  .carousel-control-next {
    right: 0;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
      transition: none;
    }
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }
  .carousel-dark .carousel-control-prev-icon,
  .carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100);
  }
  .carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000;
  }
  .carousel-dark .carousel-caption {
    color: #000;
  }
  .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: currentColor;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: 0.5;
  }
  .btn-close:hover {
    color: currentColor;
    text-decoration: none;
    opacity: 0.75;
  }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(92, 96, 245, 0.25);
    opacity: 1;
  }
  .btn-close:disabled,
  .btn-close.disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: 0.25;
  }
  .btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
  .dropup,
  .dropend,
  .dropdown,
  .dropstart {
    position: relative;
  }
  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 12rem;
    padding: 0.5rem 0rem;
    margin: 0;
    font-size: 0.875rem;
    color: #525f7f;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f0f2f6;
    border-radius: 0.75rem;
  }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem;
  }
  .dropdown-menu-start {
    --bs-position: start;
  }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-end {
    --bs-position: end;
  }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
  @media (min-width: 576px) {
    .dropdown-menu-sm-start {
      --bs-position: start;
    }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-end {
      --bs-position: end;
    }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-start {
      --bs-position: start;
    }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-end {
      --bs-position: end;
    }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-start {
      --bs-position: start;
    }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-end {
      --bs-position: end;
    }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-start {
      --bs-position: start;
    }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-end {
      --bs-position: end;
    }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
      --bs-position: start;
    }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xxl-end {
      --bs-position: end;
    }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  .dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  .dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropend .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropend .dropdown-toggle::after {
    vertical-align: 0;
  }
  .dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  .dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  .dropstart .dropdown-toggle::after {
    display: none;
  }
  .dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  .dropstart .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropstart .dropdown-toggle::before {
    vertical-align: 0;
  }
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #f2f3f7;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    clear: both;
    font-weight: 400;
    color: #16192c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: theme-color("primary");
    background-color: transparent;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: theme-color("primary");
    text-decoration: none;
    background-color: transparent;
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #8898a9;
    pointer-events: none;
    background-color: transparent;
  }
  .dropdown-menu.show {
    display: block;
  }
  .dropdown-header {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6b7b93;
    white-space: nowrap;
  }
  .dropdown-item-text {
    display: block;
    padding: 0.5rem 1rem;
    color: #16192c;
  }
  .dropdown-menu-dark {
    color: #cfd6df;
    background-color: #2d3748;
    border-color: #f0f2f6;
  }
  .dropdown-menu-dark .dropdown-item {
    color: #cfd6df;
  }
  .dropdown-menu-dark .dropdown-item:hover,
  .dropdown-menu-dark .dropdown-item:focus {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15);
  }
  .dropdown-menu-dark .dropdown-item.active,
  .dropdown-menu-dark .dropdown-item:active {
    color: theme-color("primary");
    background-color: transparent;
  }
  .dropdown-menu-dark .dropdown-item.disabled,
  .dropdown-menu-dark .dropdown-item:disabled {
    color: #8898a9;
  }
  .dropdown-menu-dark .dropdown-divider {
    border-color: #f2f3f7;
  }
  .dropdown-menu-dark .dropdown-item-text {
    color: #cfd6df;
  }
  .dropdown-menu-dark .dropdown-header {
    color: #8898a9;
  }
  .dropdown,
  .dropup,
  .dropright,
  .dropleft {
    display: inline-block;
  }
  .dropdown-heading {
    color: #16192c;
    font-size: 1rem;
    font-weight: 500;
  }
  .dropdown-helper {
    color: #6b7b93;
  }
  .dropdown-group {
    display: block;
  }
  .dropdown-group:hover .dropdown-heading {
    color: #4066D5;
  }
  .dropdown-img-left {
    position: relative;
    display: flex;
    min-height: 200px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.625rem 1.75rem;
    background: no-repeat center center/cover;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .dropdown-img-left.dropdown-img-mask:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(92, 96, 245, 0.7);
    border-radius: inherit;
  }
  .dropdown-secondary {
    background-color: #f5f9fc;
  }
  .dropdown-body-left {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .dropdown-body-right {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .dropdown-menu-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
  @media (min-width: 576px) {
    .dropdown-menu-sm-fluid {
      width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
      left: var(--x-gutter-x, 1.5rem) !important;
    }
    .dropdown-menu-sm-fluid[data-bs-popper] {
      left: var(--x-gutter-x, 1.5rem);
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-fluid {
      width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
      left: var(--x-gutter-x, 1.5rem) !important;
    }
    .dropdown-menu-md-fluid[data-bs-popper] {
      left: var(--x-gutter-x, 1.5rem);
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-fluid {
      width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
      left: var(--x-gutter-x, 1.5rem) !important;
    }
    .dropdown-menu-lg-fluid[data-bs-popper] {
      left: var(--x-gutter-x, 1.5rem);
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-fluid {
      width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
      left: var(--x-gutter-x, 1.5rem) !important;
    }
    .dropdown-menu-xl-fluid[data-bs-popper] {
      left: var(--x-gutter-x, 1.5rem);
    }
  }
  @media (min-width: 1400px) {
    .dropdown-menu-xxl-fluid {
      width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
      left: var(--x-gutter-x, 1.5rem) !important;
    }
    .dropdown-menu-xxl-fluid[data-bs-popper] {
      left: var(--x-gutter-x, 1.5rem);
    }
  }
  .dropdown-submenu .dropdown-menu {
    min-width: 16rem;
    margin-left: 1rem;
  }
  .dropdown-submenu .dropdown-menu:before {
    content: "";
    border-left: 1.5rem solid transparent;
    position: absolute;
    left: -1rem;
    top: 0;
    height: 100%;
  }
  .dropdown-body {
    padding: 2rem 2.5rem;
  }
  .delimiter {
    border-right: 1px solid #e7eaf0;
  }
  @media (min-width: 768px) {
    .dropdown-menu-sm {
      min-width: 10rem !important;
    }
    .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before {
      right: 2rem;
      left: auto;
    }
    .dropdown-menu-md {
      min-width: 25rem !important;
    }
    .dropdown-menu-lg {
      min-width: 30rem !important;
    }
    .dropdown-menu-xl {
      min-width: 40rem !important;
    }
    .dropdown-menu-2xl {
      min-width: 50rem !important;
    }
  }
  .dropdown-menu-centered {
    left: 50% !important;
    transform: translateX(-50%) translateY(-3px) scale(0.96);
  }
  @media (min-width: 992px) {
    .dropdown-animate > .dropdown-menu {
      opacity: 0;
      position: absolute;
      display: block;
      pointer-events: none;
    }
    .dropdown-animate:not([data-toggle="hover"]) .dropdown-menu.show {
      pointer-events: auto;
      -webkit-animation: show-dropdown 0.3s ease forwards;
      animation: show-dropdown 0.3s ease forwards;
    }
    .dropdown-animate:not([data-toggle="hover"]) .dropdown-menu.hide {
      display: block;
      -webkit-animation: hide-dropdown 0.3s ease backwards;
      animation: hide-dropdown 0.3s ease backwards;
    }
    .dropdown-animate[data-toggle="hover"] > .dropdown-menu {
      display: block;
      margin: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .dropdown-animate[data-toggle="hover"] > .dropdown-menu {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    .dropdown-animate[data-toggle="hover"]:hover > .dropdown-menu {
      display: block;
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      transform: translateX(0) translateY(-6px) scale(1);
    }
  }
  @-webkit-keyframes show-dropdown {
    0% {
      opacity: 0;
      transform: translateX(0) translateY(-3px) scale(0.97);
      transition: all 0.3s ease;
    }
    100% {
      transform: translateX(0) translateY(-3px) scale(1);
      opacity: 1;
    }
  }
  @keyframes show-dropdown {
    0% {
      opacity: 0;
      transform: translateX(0) translateY(-3px) scale(0.97);
      transition: all 0.3s ease;
    }
    100% {
      transform: translateX(0) translateY(-3px) scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes hide-dropdown {
    from {
      opacity: 1;
      transform: translateX(0) translateY(-3px) scale(1);
      transition: all 0.3s ease;
    }
    to {
      opacity: 0;
      transform: translateX(0) translateY(-3px) scale(0.97);
    }
  }
  @keyframes hide-dropdown {
    from {
      opacity: 1;
      transform: translateX(0) translateY(-3px) scale(1);
      transition: all 0.3s ease;
    }
    to {
      opacity: 0;
      transform: translateX(0) translateY(-3px) scale(0.97);
    }
  }
  .dropdown-menu .dropdown-menu {
    left: 100%;
    margin: 0;
    right: auto;
    top: 0;
  }
  .dropdown-submenu {
    display: block;
    position: relative;
  }
  .dropdown-submenu > .dropdown-menu::after {
    border: 0 !important;
  }
  .dropdown-submenu > .dropdown-item::after,
  .dropdown-submenu > .list-group-item::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  .dropdown-submenu > .dropdown-item:empty::after,
  .dropdown-submenu > .list-group-item:empty::after {
    margin-left: 0;
  }
  .dropdown-submenu > .dropdown-item:after,
  .dropdown-submenu > .list-group-item:after {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-left: 0;
    font-size: 0.75rem;
    transform: translateY(-50%);
  }
  .dropdown-submenu[data-toggle="hover"] > .dropdown-menu {
    left: 100%;
    transform: translateX(0) translateY(-3px) scale(0.97);
  }
  .dropdown-submenu[data-toggle="hover"]:hover > .dropdown-menu {
    transform: translateX(0) translateY(-3px) scale(1);
  }
  @media (max-width: 767.98px) {
    .dropdown-submenu .dropdown-menu {
      padding: 0;
      box-shadow: none;
      top: 0;
    }
    .dropdown-submenu .dropdown-item {
      padding-left: 3rem;
    }
  }
  .frame-laptop {
    position: relative;
  }
  .frame-laptop .frame-inner {
    position: absolute;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 70%;
    height: 78%;
    margin: 2.5% 15% 0 15%;
  }
  .frame-iphone {
    position: relative;
    z-index: 100;
  }
  .frame-iphone .frame-inner {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 92%;
    height: 96%;
    margin: 4% 4% 0 4%;
    border-radius: 5%;
  }
  .frame-stack {
    position: relative;
  }
  .frame-stack .frame {
    position: absolute;
  }
  .frame-stack-iphone-iphone {
    padding-bottom: 130.250482%;
  }
  .frame-stack-iphone-iphone > .frame-iphone:first-child {
    bottom: 0;
    left: 0;
    width: 65.5260116%;
    z-index: 1;
  }
  .frame-stack-iphone-iphone > .frame-iphone:last-child {
    top: 0;
    right: 0;
    width: 72.8323699%;
  }
  .frame-stack-iphone-laptop,
  .frame-stack-laptop-iphone {
    padding-bottom: 62.4260355%;
  }
  .frame-stack-iphone-laptop > .frame-laptop,
  .frame-stack-laptop-iphone > .frame-laptop {
    width: 91.7159763%;
  }
  .frame-stack-iphone-laptop > .frame-iphone,
  .frame-stack-laptop-iphone > .frame-iphone {
    width: 27.9585799%;
    z-index: 1;
  }
  .frame-stack-laptop-iphone > .frame-laptop {
    top: 0;
    left: 0;
  }
  .frame-stack-iphone-laptop > .frame-laptop {
    top: 0;
    right: 0;
  }
  .frame-stack-laptop-iphone > .frame-iphone {
    bottom: 0;
    right: 0;
  }
  .frame-stack-iphone-laptop > .frame-iphone {
    bottom: 0;
    left: 0;
  }
  .icon {
    font-size: 1.7142857143rem;
    line-height: 1;
  }
  .icon svg {
    width: 1em;
    height: 1em;
  }
  .icon + .icon-text {
    padding-left: 1rem;
    width: calc(100% - 3rem - 1);
  }
  .icon-xl {
    font-size: 2.8571428571rem;
  }
  .icon-xl + .icon-text {
    width: calc(100% - 5rem - 1);
  }
  .icon-lg {
    font-size: 2.2857142857rem;
  }
  .icon-lg + .icon-text {
    width: calc(100% - 4rem - 1);
  }
  .icon-sm {
    font-size: 1.1428571429rem;
  }
  .icon-sm + .icon-text {
    width: calc(100% - 2rem - 1);
  }
  .icon-xs {
    font-size: 0.7142857143rem;
  }
  .icon-xs + .icon-text {
    width: calc(100% - 1.25rem - 1);
  }
  .icon-shape {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    border-radius: 0.375rem;
    width: 3rem;
    height: 3rem;
  }
  .icon-shape.icon-xl {
    width: 5rem;
    height: 5rem;
  }
  .icon-shape.icon-lg {
    width: 4rem;
    height: 4rem;
  }
  .icon-shape.icon-sm {
    width: 2rem;
    height: 2rem;
  }
  .icon-shape.icon-xs {
    width: 1.25rem;
    height: 1.25rem;
  }
  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.375rem;
  }
  .list-group-numbered {
    list-style-type: none;
    counter-reset: section;
  }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section;
  }
  .list-group-item-action {
    width: 100%;
    color: #525f7f;
    text-align: inherit;
  }
  .list-group-item-action:hover,
  .list-group-item-action:focus {
    z-index: 1;
    color: #525f7f;
    text-decoration: none;
    background-color: #f5f9fc;
  }
  .list-group-item-action:active {
    color: #525f7f;
    background-color: #e7eaf0;
  }
  .list-group-item {
    position: relative;
    display: block;
    padding: 1.125rem 1.5rem;
    color: #16192c;
    background-color: transparent;
    border: 1px solid #e7eaf0;
  }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  .list-group-item.disabled,
  .list-group-item:disabled {
    color: #6b7b93;
    pointer-events: none;
    background-color: transparent;
  }
  .list-group-item.active {
    z-index: 2;
    color: #525f7f;
    background-color: #f5f9fc;
    border-color: #e7eaf0;
  }
  .list-group-item + .list-group-item {
    border-top-width: 0;
  }
  .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
  }
  .list-group-horizontal {
    flex-direction: row;
  }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 768px) {
    .list-group-horizontal-md {
      flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 992px) {
    .list-group-horizontal-lg {
      flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 1400px) {
    .list-group-horizontal-xxl {
      flex-direction: row;
    }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  .list-group-flush {
    border-radius: 0;
  }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px;
  }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }
  .list-group-item-primary {
    color: #373a93;
    background-color: #dedffd;
  }
  .list-group-item-primary.list-group-item-action:hover,
  .list-group-item-primary.list-group-item-action:focus {
    color: #373a93;
    background-color: #c8c9e4;
  }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #373a93;
    border-color: #373a93;
  }
  .list-group-item-secondary {
    color: #7c8086;
    background-color: #f5f7f9;
  }
  .list-group-item-secondary.list-group-item-action:hover,
  .list-group-item-secondary.list-group-item-action:focus {
    color: #7c8086;
    background-color: #dddee0;
  }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7c8086;
    border-color: #7c8086;
  }
  .list-group-item-tertiary {
    color: #99345c;
    background-color: #ffddeb;
  }
  .list-group-item-tertiary.list-group-item-action:hover,
  .list-group-item-tertiary.list-group-item-action:focus {
    color: #99345c;
    background-color: #e6c7d4;
  }
  .list-group-item-tertiary.list-group-item-action.active {
    color: #fff;
    background-color: #99345c;
    border-color: #99345c;
  }
  .list-group-item-success {
    color: #007a52;
    background-color: #ccf5e7;
  }
  .list-group-item-success.list-group-item-action:hover,
  .list-group-item-success.list-group-item-action:focus {
    color: #007a52;
    background-color: #b8ddd0;
  }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #007a52;
    border-color: #007a52;
  }
  .list-group-item-info {
    color: #007f99;
    background-color: #ccf6ff;
  }
  .list-group-item-info.list-group-item-action:hover,
  .list-group-item-info.list-group-item-action:focus {
    color: #007f99;
    background-color: #b8dde6;
  }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #007f99;
    border-color: #007f99;
  }
  .list-group-item-warning {
    color: #995400;
    background-color: #ffe8cc;
  }
  .list-group-item-warning.list-group-item-action:hover,
  .list-group-item-warning.list-group-item-action:focus {
    color: #995400;
    background-color: #e6d1b8;
  }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #995400;
    border-color: #995400;
  }
  .list-group-item-danger {
    color: #991f3d;
    background-color: #ffd6e0;
  }
  .list-group-item-danger.list-group-item-action:hover,
  .list-group-item-danger.list-group-item-action:focus {
    color: #991f3d;
    background-color: #e6c1ca;
  }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #991f3d;
    border-color: #991f3d;
  }
  .list-group-item-white {
    color: #999;
    background-color: #fff;
  }
  .list-group-item-white.list-group-item-action:hover,
  .list-group-item-white.list-group-item-action:focus {
    color: #999;
    background-color: #e6e6e6;
  }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #999;
    border-color: #999;
  }
  .list-group-item-light {
    color: #8b8c90;
    background-color: #fafbfc;
  }
  .list-group-item-light.list-group-item-action:hover,
  .list-group-item-light.list-group-item-action:focus {
    color: #8b8c90;
    background-color: #e1e2e3;
  }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #8b8c90;
    border-color: #8b8c90;
  }
  .list-group-item-dark {
    color: #0d0f1a;
    background-color: #d0d1d5;
  }
  .list-group-item-dark.list-group-item-action:hover,
  .list-group-item-dark.list-group-item-action:focus {
    color: #0d0f1a;
    background-color: #bbbcc0;
  }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0d0f1a;
    border-color: #0d0f1a;
  }
  .list-group {
    list-style-type: none;
  }
  .list-group-space .list-group-item {
    margin-bottom: 1.5rem;
    border-radius: 0.375rem;
  }
  .list-group-item {
    font-size: 0.875rem;
  }
  .list-group-img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    vertical-align: top;
    margin: -0.1rem 1.2rem 0 -0.2rem;
  }
  .list-group-content {
    flex: 1;
    min-width: 0;
  }
  .list-group-content p {
    line-height: 1.5;
    margin: 0.2rem 0 0;
  }
  .list-group-heading {
    color: #2d3748;
  }
  .list-group-heading > small,
  .list-group-heading > .small {
    float: right;
    color: #8898a9;
    font-weight: 500;
  }
  .list-group-sm .list-group-item {
    padding: 0.675rem 1.25rem;
    font-size: 0.875rem;
  }
  .list-group-emphasized .list-group-item {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .list-group-emphasized .list-group-item {
      transition: none;
    }
  }
  .list-group-emphasized .list-group-item.active {
    z-index: 11;
    transform: scale(1.05);
    background-color: #f5f9fc;
    border-radius: 0.375rem;
  }
  .list-group-emphasized .list-group-item.active .media a {
    color: theme-color("primary");
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }
  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }
  .modal.show .modal-dialog {
    transform: none;
  }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02);
  }
  .modal-dialog-scrollable {
    height: calc(100% - 1rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
  }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e7eaf0;
    border-radius: 0.75rem;
    outline: 0;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .modal-backdrop.show {
    opacity: 0.4;
  }
  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem;
    border-bottom: 1px solid #e7eaf0;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
  }
  .modal-header .btn-close {
    padding: 0.75rem 0.75rem;
    margin: -0.75rem -0.75rem -0.75rem auto;
  }
  .modal-title {
    margin-bottom: 0;
    line-height: 1.625;
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.5rem;
  }
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e7eaf0;
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px);
  }
  .modal-footer > * {
    margin: 0.5rem;
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
      height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
  .modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen .modal-footer {
    border-radius: 0;
  }
  @media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0;
    }
  }
  @media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0;
    }
  }
  @media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0;
    }
  }
  @media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0;
    }
  }
  @media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0;
    }
  }
  .modal-content {
    border: 0;
    border-radius: 0.75rem;
  }
  .modal-fluid .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-fluid .modal-content {
    border-radius: 0;
  }
  .modal-open {
    max-height: 100vh;
  }
  .modal-dialog-aside {
    position: absolute;
    margin: 0;
  }
  .modal-primary .modal-title {
    color: #fff;
  }
  .modal-primary .modal-header,
  .modal-primary .modal-footer {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-primary .modal-content {
    background-color: #4066D5;
    color: #fff;
  }
  .modal-primary .modal-content .heading {
    color: #fff;
  }
  .modal-primary .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-secondary .modal-title {
    color: #000;
  }
  .modal-secondary .modal-header,
  .modal-secondary .modal-footer {
    border-color: rgba(0, 0, 0, 0.075);
  }
  .modal-secondary .modal-content {
    background-color: #cfd6df;
    color: #000;
  }
  .modal-secondary .modal-content .heading {
    color: #000;
  }
  .modal-secondary .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-tertiary .modal-title {
    color: #fff;
  }
  .modal-tertiary .modal-header,
  .modal-tertiary .modal-footer {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-tertiary .modal-content {
    background-color: #ff579a;
    color: #fff;
  }
  .modal-tertiary .modal-content .heading {
    color: #fff;
  }
  .modal-tertiary .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-success .modal-title {
    color: #fff;
  }
  .modal-success .modal-header,
  .modal-success .modal-footer {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-success .modal-content {
    background-color: #0c8;
    color: #fff;
  }
  .modal-success .modal-content .heading {
    color: #fff;
  }
  .modal-success .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-info .modal-title {
    color: #000;
  }
  .modal-info .modal-header,
  .modal-info .modal-footer {
    border-color: rgba(0, 0, 0, 0.075);
  }
  .modal-info .modal-content {
    background-color: #00d4ff;
    color: #000;
  }
  .modal-info .modal-content .heading {
    color: #000;
  }
  .modal-info .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-warning .modal-title {
    color: #fff;
  }
  .modal-warning .modal-header,
  .modal-warning .modal-footer {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-warning .modal-content {
    background-color: #ff8c00;
    color: #fff;
  }
  .modal-warning .modal-content .heading {
    color: #fff;
  }
  .modal-warning .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-danger .modal-title {
    color: #fff;
  }
  .modal-danger .modal-header,
  .modal-danger .modal-footer {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-danger .modal-content {
    background-color: #f36;
    color: #fff;
  }
  .modal-danger .modal-content .heading {
    color: #fff;
  }
  .modal-danger .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-white .modal-title {
    color: #000;
  }
  .modal-white .modal-header,
  .modal-white .modal-footer {
    border-color: rgba(0, 0, 0, 0.075);
  }
  .modal-white .modal-content {
    background-color: #fff;
    color: #000;
  }
  .modal-white .modal-content .heading {
    color: #000;
  }
  .modal-white .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-light .modal-title {
    color: #000;
  }
  .modal-light .modal-header,
  .modal-light .modal-footer {
    border-color: rgba(0, 0, 0, 0.075);
  }
  .modal-light .modal-content {
    background-color: #e7eaf0;
    color: #000;
  }
  .modal-light .modal-content .heading {
    color: #000;
  }
  .modal-light .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-dark .modal-title {
    color: #fff;
  }
  .modal-dark .modal-header,
  .modal-dark .modal-footer {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-dark .modal-content {
    background-color: #16192c;
    color: #fff;
  }
  .modal-dark .modal-content .heading {
    color: #fff;
  }
  .modal-dark .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-vertical {
    height: calc(100% - 0.5rem);
    max-width: 350px;
    margin: 0.5rem 0;
  }
  .modal-vertical.modal-lg {
    max-width: 450px;
  }
  .modal-vertical .modal-content {
    height: inherit;
    border-width: 0;
    border-radius: 0.75rem 0 0 0.75rem;
  }
  .modal-vertical .modal-body {
    height: inherit;
    overflow-y: auto;
  }
  .modal.fade .modal-dialog-vertical {
    transform: translateX(-100%);
  }
  .modal.show .modal-dialog-vertical {
    transform: translateX(0);
  }
  .modal.fixed-right {
    padding-right: 0 !important;
  }
  .modal.fixed-right .modal-vertical {
    margin-left: auto;
  }
  .modal.fixed-right.fade .modal-vertical {
    transform: translateX(100%);
  }
  .modal.fixed-right.show .modal-vertical {
    transform: translateX(0);
  }
  .offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none;
    }
  }
  .offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  .offcanvas-backdrop.fade {
    opacity: 0;
  }
  .offcanvas-backdrop.show {
    opacity: 0.4;
  }
  .offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem;
  }
  .offcanvas-header .btn-close {
    padding: 0.75rem 0.75rem;
    margin-top: -0.75rem;
    margin-right: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .offcanvas-title {
    margin-bottom: 0;
    line-height: 1.625;
  }
  .offcanvas-body {
    flex-grow: 1;
    padding: 1.5rem 1.5rem;
    overflow-y: auto;
  }
  .offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid #e7eaf0;
    transform: translateX(-100%);
  }
  .offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: 1px solid #e7eaf0;
    transform: translateX(100%);
  }
  .offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid #e7eaf0;
    transform: translateY(-100%);
  }
  .offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 1px solid #e7eaf0;
    transform: translateY(100%);
  }
  .offcanvas.show {
    transform: none;
  }
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
  }
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-sm,
  .navbar > .container-md,
  .navbar > .container-lg,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-brand {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-right: 0;
    font-size: 1rem;
    white-space: nowrap;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav .dropdown-menu {
    position: static;
  }
  .navbar-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  .navbar-toggler {
    padding: 0.25rem 0.375rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    transition: box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none;
    }
  }
  .navbar-toggler:hover {
    text-decoration: none;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .navbar-nav-scroll {
    max-height: var(--x-scroll-height, 75vh);
    overflow-y: auto;
  }
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
    .navbar-expand-sm .offcanvas-header {
      display: none;
    }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none;
    }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
    .navbar-expand-md .offcanvas-header {
      display: none;
    }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none;
    }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
    .navbar-expand-lg .offcanvas-header {
      display: none;
    }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none;
    }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
    .navbar-expand-xl .offcanvas-header {
      display: none;
    }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none;
    }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  @media (min-width: 1400px) {
    .navbar-expand-xxl {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xxl .navbar-toggler {
      display: none;
    }
    .navbar-expand-xxl .offcanvas-header {
      display: none;
    }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none;
    }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  .navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand .navbar-toggler {
    display: none;
  }
  .navbar-expand .offcanvas-header {
    display: none;
  }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
  .navbar-light .navbar-brand {
    color: #E99E5E;
  }
  .navbar-light .navbar-brand:hover,
  .navbar-light .navbar-brand:focus {
    color: #E99E5E;
  }
  .navbar-light .navbar-nav .nav-link {
    color: black;
  }
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link:focus {
    color: #4066D5;
  }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: white;
  }
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .nav-link.active {
    color: #4066D5 !important;
  }
  .navbar-light .navbar-toggler {
    color: white;
    border-color: transparent;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%236B7B93' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-light .navbar-text {
    color: #16192c;
  }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: #16192c;
  }
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  .navbar-dark .navbar-brand:hover,
  .navbar-dark .navbar-brand:focus {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.85);
  }
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.85);
    border-color: transparent;
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23E7EAF0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.85);
  }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff;
  }
  .navbar {
    position: relative;
    z-index: 100;
  }
  .navbar [class^="container"] {
    position: relative;
  }
  .navbar-nav .nav-link {
    font-size: 0.925rem;
    font-weight: 500;
    letter-spacing: 0;
    transition: all 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-nav .nav-link {
      transition: none;
    }
  }
  .navbar-text {
    font-size: 0.925rem;
  }
  .navbar-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .navbar-img-brand {
    font-size: initial;
  }
  .navbar-border.navbar-dark {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .navbar-border.navbar-dark .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .navbar-border.navbar-light {
    border-bottom: 1px solid rgba(0, 0, 0, 0.04) !important;
  }
  .navbar-border.navbar-light .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .navbar-dark .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .navbar-light .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .navbar-top {
    position: relative;
    display: flex;
    align-items: center;
  }
  .navbar-top .navbar-nav {
    flex-direction: row;
  }
  .navbar-top .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
  .navbar-top .navbar-nav .nav-link {
    padding: 0.5rem 0.5rem;
  }
  .navbar-collapse:before {
    content: "";
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    margin: 0.75rem -1rem;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-collapse:before {
      transition: none;
    }
  }
  .navbar-collapse.show:before,
  .navbar-collapse.collapsing:before {
    opacity: 1;
  }
  .navbar-light .navbar-collapse::before {
    border-top-color: rgba(22, 25, 44, 0.1);
  }
  .navbar-light .collapse .nav:before {
    border-color: rgba(22, 25, 44, 0.1);
  }
  .navbar-dark .navbar-collapse::before {
    border-top-color: rgba(255, 255, 255, 0.2);
  }
  .navbar-dark .collapse .nav:before {
    border-color: rgba(255, 255, 255, 0.2);
  }
  @media (min-width: 576px) {
    .navbar-expand-sm .navbar-brand img {
      height: 40px;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-sm .navbar-collapse .collapse-header {
      display: none;
    }
    .navbar-expand-sm .navbar-collapse:before {
      display: none;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md .navbar-brand img {
      height: 40px;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-md .navbar-collapse .collapse-header {
      display: none;
    }
    .navbar-expand-md .navbar-collapse:before {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-brand img {
      height: 40px;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-lg .navbar-collapse .collapse-header {
      display: none;
    }
    .navbar-expand-lg .navbar-collapse:before {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl .navbar-brand img {
      height: 40px;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-xl .navbar-collapse .collapse-header {
      display: none;
    }
    .navbar-expand-xl .navbar-collapse:before {
      display: none;
    }
  }
  @media (min-width: 1400px) {
    .navbar-expand-xxl .navbar-brand img {
      height: 40px;
    }
    .navbar-expand-xxl .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-xxl .navbar-collapse .collapse-header {
      display: none;
    }
    .navbar-expand-xxl .navbar-collapse:before {
      display: none;
    }
  }
  .navbar-expand .navbar-brand img {
    height: 40px;
  }
  .navbar-expand .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand .navbar-collapse:before {
    display: none;
  }
  .navbar-toggler-icon {
    width: 1.25em;
    height: 1.25em;
  }
  .navbar-search .input-group {
    border-radius: 0.5rem;
    border: 0 solid;
    transition: background-color 0.4s linear;
    transition-delay: 0.2s;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-search .input-group {
      transition: none;
    }
  }
  .navbar-search .input-group .input-group-text {
    background-color: transparent;
    padding-left: 1rem;
    border: 0;
  }
  .navbar-search .form-control {
    width: 250px;
    background-color: transparent;
    border: 0;
    transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 2.25);
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-search .form-control {
      transition: none;
    }
  }
  .navbar-search .focused .input-group .form-control {
    width: 380px;
  }
  .navbar-search .close {
    display: none;
  }
  .navbar-search-dark .input-group {
    background-color: #05060a;
    border-color: #05060a;
  }
  .navbar-search-dark .input-group-text {
    color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search-dark .form-control {
    color: rgba(255, 255, 255, 0.9);
  }
  .navbar-search-dark .form-control::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search-dark .form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search-dark .focused .input-group {
    background-color: #000;
    border-color: #000;
  }
  .navbar-search-light .input-group {
    background-color: #f2f2f2;
    border-color: rgba(0, 0, 0, 0.6);
  }
  .navbar-search-light .input-group-text {
    color: rgba(0, 0, 0, 0.6);
  }
  .navbar-search-light .form-control {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-search-light .form-control::-moz-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  .navbar-search-light .form-control::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  .navbar-search-light .focused .input-group {
    background-color: #e6e6e6;
    border-color: rgba(0, 0, 0, 0.9);
  }
  /* .navbar-vertical .navbar-nav {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  } */
  .navbar-vertical .navbar-nav .nav-link {
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .navbar-vertical .navbar-nav .nav-link.active {
    position: relative;
  }
  .navbar-vertical .navbar-nav .nav-link i,
  .navbar-vertical .navbar-nav .nav-link svg {
    min-width: 1.875rem;
  }
  .navbar-vertical .navbar-nav .nav-link .dropdown-menu {
    border: none;
  }
  .navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
    margin-left: 0.5rem;
  }
  .navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
    display: inline-block;
    content: "";
    background-repeat: no-repeat;
    background-size: 0.875rem 0.875rem;
    width: 0.875rem;
    height: 0.875rem;
    margin-left: auto;
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
      transition: none;
    }
  }
  .navbar-vertical
    .navbar-nav
    .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
    color: theme-color("primary");
    transform: rotate(90deg);
  }
  .navbar-vertical .navbar-nav > .nav-item {
    margin-top: 2px;
  }
  .navbar-vertical .navbar-nav .nav-sm .nav-link {
    font-size: 0.875rem;
  }
  .navbar-vertical .navbar-nav .nav .nav-link {
    padding-top: 0.4285714286rem;
    padding-bottom: 0.4285714286rem;
    padding-left: 3.375rem;
    font-weight: 400;
  }
  .navbar-vertical .navbar-nav .nav .nav .nav-link {
    padding-left: 2.8125rem;
  }
  .navbar-vertical.navbar-light {
    background-color: #fff;
    border-color: #e7eaf0;
  }
  .navbar-vertical.navbar-light .navbar-nav .nav-link i,
  .navbar-vertical.navbar-light .navbar-nav .nav-link svg {
    color: black;
  }
  .navbar-vertical.navbar-light
    .navbar-nav
    .nav-link[data-bs-toggle="collapse"]:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='%2316192C' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  }
  .navbar-vertical.navbar-light .navbar-nav > .nav-item > .nav-link:hover {
    background-color: #6182e6;
  }
  .navbar-vertical.navbar-light .navbar-nav > .nav-item > .nav-link.active {
    background-color: #d8e1ff;
    color: black !important;
    border-right: 2px solid blue;
  }
  .navbar-vertical.navbar-light .nav-link-text,
  .navbar-vertical.navbar-light .navbar-heading {
    color: #16192c;
  }
  .navbar-vertical.navbar-dark {
    background-color: #16192c;
    border-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-vertical.navbar-dark .navbar-nav .nav-link i,
  .navbar-vertical.navbar-dark .navbar-nav .nav-link svg {
    color: #f5f9fc;
  }
  .navbar-vertical.navbar-dark
    .navbar-nav
    .nav-link[data-bs-toggle="collapse"]:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='rgba(255, 255, 255, 0.85)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  }
  .navbar-vertical.navbar-dark .navbar-nav > .nav-item > .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-vertical.navbar-dark .navbar-nav > .nav-item > .nav-link.active {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-vertical.navbar-dark .nav-link-text,
  .navbar-vertical.navbar-dark .navbar-heading {
    color: rgba(255, 255, 255, 0.85);
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm {
      display: block;
      width: 100%;
      max-width: 270px;
      overflow-y: auto;
      z-index: 1000;
      border-radius: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 576px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-sm {
      transition: none;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm .nav-link-text,
    .navbar-vertical.navbar-expand-sm .navbar-heading {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 576px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-sm .nav-link-text,
    .navbar-vertical.navbar-expand-sm .navbar-heading {
      transition: none;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm.show {
      max-width: 270px;
      border-radius: 0;
    }
    .navbar-vertical.navbar-expand-sm.show.navbar-lg {
      max-width: 320px;
    }
    .navbar-vertical.navbar-expand-sm.show.navbar-xl {
      max-width: 370px;
    }
    .navbar-vertical.navbar-expand-sm.show .nav-link-text,
    .navbar-vertical.navbar-expand-sm.show .navbar-heading {
      opacity: 1;
      pointer-events: auto;
    }
    .navbar-vertical.navbar-expand-sm.show + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm.hide {
      max-width: 270px;
    }
    .navbar-vertical.navbar-expand-sm.hide .nav-link-text {
      opacity: 0;
      pointer-events: none;
    }
    .navbar-vertical.navbar-expand-sm.hide + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm .navbar-brand {
      margin-right: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm > [class*="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm .navbar-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 1;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm .navbar-nav {
      margin-left: 0;
      margin-right: 0;
      flex-direction: column;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md {
      display: block;
      width: 100%;
      max-width: 270px;
      overflow-y: auto;
      z-index: 1000;
      border-radius: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-md {
      transition: none;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .nav-link-text,
    .navbar-vertical.navbar-expand-md .navbar-heading {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-md .nav-link-text,
    .navbar-vertical.navbar-expand-md .navbar-heading {
      transition: none;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md.show {
      max-width: 270px;
      border-radius: 0;
    }
    .navbar-vertical.navbar-expand-md.show.navbar-lg {
      max-width: 320px;
    }
    .navbar-vertical.navbar-expand-md.show.navbar-xl {
      max-width: 370px;
    }
    .navbar-vertical.navbar-expand-md.show .nav-link-text,
    .navbar-vertical.navbar-expand-md.show .navbar-heading {
      opacity: 1;
      pointer-events: auto;
    }
    .navbar-vertical.navbar-expand-md.show + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md.hide {
      max-width: 270px;
    }
    .navbar-vertical.navbar-expand-md.hide .nav-link-text {
      opacity: 0;
      pointer-events: none;
    }
    .navbar-vertical.navbar-expand-md.hide + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .navbar-brand {
      margin-right: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md > [class*="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .navbar-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 1;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .navbar-nav {
      margin-left: 0;
      margin-right: 0;
      flex-direction: column;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg {
      display: block;
      width: 100%;
      max-width: 230px;
      overflow-y: auto;
      z-index: 1000;
      border-radius: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  
  .navbar-vertical.navbar-expand-lg::-webkit-scrollbar {
    width: 0px;
  }
  
  .navbar-vertical.navbar-expand-lg::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .navbar-vertical.navbar-expand-lg::-webkit-scrollbar-thumb {
    background: #bdbaba;
  }
  
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-lg {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg .nav-link-text,
    .navbar-vertical.navbar-expand-lg .navbar-heading {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-lg .nav-link-text,
    .navbar-vertical.navbar-expand-lg .navbar-heading {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg.show {
      max-width: 230px;
      border-radius: 0;
    }
    .navbar-vertical.navbar-expand-lg.show.navbar-lg {
      max-width: 320px;
    }
    .navbar-vertical.navbar-expand-lg.show.navbar-xl {
      max-width: 370px;
    }
    .navbar-vertical.navbar-expand-lg.show .nav-link-text,
    .navbar-vertical.navbar-expand-lg.show .navbar-heading {
      opacity: 1;
      pointer-events: auto;
    }
    .navbar-vertical.navbar-expand-lg.show + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg.hide {
      max-width: 270px;
    }
    .navbar-vertical.navbar-expand-lg.hide .nav-link-text {
      opacity: 0;
      pointer-events: none;
    }
    .navbar-vertical.navbar-expand-lg.hide + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg .navbar-brand {
      margin-right: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg > [class*="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg .navbar-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 1;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg .navbar-nav {
      margin-left: 0;
      margin-right: 0;
      flex-direction: column;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl {
      display: block;
      width: 100%;
      max-width: 270px;
      overflow-y: auto;
      z-index: 1000;
      border-radius: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-xl {
      transition: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl .nav-link-text,
    .navbar-vertical.navbar-expand-xl .navbar-heading {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-xl .nav-link-text,
    .navbar-vertical.navbar-expand-xl .navbar-heading {
      transition: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl.show {
      max-width: 270px;
      border-radius: 0;
    }
    .navbar-vertical.navbar-expand-xl.show.navbar-lg {
      max-width: 320px;
    }
    .navbar-vertical.navbar-expand-xl.show.navbar-xl {
      max-width: 370px;
    }
    .navbar-vertical.navbar-expand-xl.show .nav-link-text,
    .navbar-vertical.navbar-expand-xl.show .navbar-heading {
      opacity: 1;
      pointer-events: auto;
    }
    .navbar-vertical.navbar-expand-xl.show + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl.hide {
      max-width: 270px;
    }
    .navbar-vertical.navbar-expand-xl.hide .nav-link-text {
      opacity: 0;
      pointer-events: none;
    }
    .navbar-vertical.navbar-expand-xl.hide + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl .navbar-brand {
      margin-right: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl > [class*="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl .navbar-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 1;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl .navbar-nav {
      margin-left: 0;
      margin-right: 0;
      flex-direction: column;
    }
  }
  @media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl {
      display: block;
      width: 100%;
      max-width: 270px;
      overflow-y: auto;
      z-index: 1000;
      border-radius: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-xxl {
      transition: none;
    }
  }
  @media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl .nav-link-text,
    .navbar-vertical.navbar-expand-xxl .navbar-heading {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-xxl .nav-link-text,
    .navbar-vertical.navbar-expand-xxl .navbar-heading {
      transition: none;
    }
  }
  @media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl.show {
      max-width: 270px;
      border-radius: 0;
    }
    .navbar-vertical.navbar-expand-xxl.show.navbar-lg {
      max-width: 320px;
    }
    .navbar-vertical.navbar-expand-xxl.show.navbar-xl {
      max-width: 370px;
    }
    .navbar-vertical.navbar-expand-xxl.show .nav-link-text,
    .navbar-vertical.navbar-expand-xxl.show .navbar-heading {
      opacity: 1;
      pointer-events: auto;
    }
    .navbar-vertical.navbar-expand-xxl.show + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl.hide {
      max-width: 270px;
    }
    .navbar-vertical.navbar-expand-xxl.hide .nav-link-text {
      opacity: 0;
      pointer-events: none;
    }
    .navbar-vertical.navbar-expand-xxl.hide + .main-content {
      margin-left: 270px;
    }
  }
  @media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl .navbar-brand {
      margin-right: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }
  @media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl > [class*="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl .navbar-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 1;
    }
  }
  @media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl .navbar-nav {
      margin-left: 0;
      margin-right: 0;
      flex-direction: column;
    }
  }
  .navbar-vertical.navbar-expand {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand {
      transition: none;
    }
  }
  .navbar-vertical.navbar-expand + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand .nav-link-text,
  .navbar-vertical.navbar-expand .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand .nav-link-text,
    .navbar-vertical.navbar-expand .navbar-heading {
      transition: none;
    }
  }
  .navbar-vertical.navbar-expand.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand.show .nav-link-text,
  .navbar-vertical.navbar-expand.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand.show + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand.hide + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-vertical.navbar-expand > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-vertical.navbar-expand .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
  .navbar-vertical.navbar-expand .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
  .navbar-toggler {
    padding: 0.25rem 0.375rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    transition: box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none;
    }
  }
  .navbar-toggler:hover {
    text-decoration: none;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem;
  }
  .navbar-light .navbar-toggler {
    color: black;
    background-color: white;
    border-color: #323232c8;
  }
  .navbar-light .navbar-toggler:focus {
    color: transparent;
    background-color: #f5f9fc;
  }
  .navbar-dark .navbar-toggler {
    color: #e7eaf0;
    background-color: transparent;
    border-color: transparent;
  }
  .navbar-dark .navbar-toggler:focus {
    color: transparent;
    background-color: #f5f9fc;
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #525f7f;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none;
    }
  }
  .nav-link:hover,
  .nav-link:focus {
    color: #4066D5;
  }
  .nav-link.disabled {
    color: #abb6c5;
    pointer-events: none;
    cursor: default;
  }
  .nav-tabs {
    border-bottom: 1px solid #e7eaf0;
  }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: transparent transparent transparent;
    isolation: isolate;
  }
  .nav-tabs .nav-link.disabled {
    color: #abb6c5;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #4066D5;
    background-color: transparent;
    border-color: transparent transparent #4066D5;
  }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.375rem;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #4066D5;
  }
  .nav-fill > .nav-link,
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
  .nav-justified > .nav-link,
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  .nav-fill .nav-item .nav-link,
  .nav-justified .nav-item .nav-link {
    width: 100%;
  }
  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
  }
  @media (min-width: 992px) {
    .nav-link-badge {
      position: relative;
    }
    .nav-link-badge .badge {
      position: relative;
      top: -15px;
      left: -5px;
    }
    .nav-link-badge .nav-link-text {
      position: relative;
    }
    .nav-link-badge .nav-link-text:before {
      position: absolute;
      top: 0;
      left: -7px;
      display: block;
      width: 20px;
      height: 20px;
      margin: auto;
      content: "";
      transform: translate(-50%, -50%) rotate(100deg);
      border-left: 1px dashed #fff;
      border-radius: 10px;
    }
  }
  .nav.overflow-x {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1px;
  }
  .nav.overflow-x .nav-item {
    width: auto;
    max-width: 100%;
    flex: 0 0 auto;
  }
  .nav.overflow-x::-webkit-scrollbar {
    display: none;
  }
  .nav-header {
    border-bottom-width: 0;
  }
  .nav-application > .btn {
    width: 123px !important;
    height: 123px !important;
    float: left;
    background: transparent;
    color: #6b7b93;
    margin: 0;
    margin-bottom: 24px;
  }
  .nav-application > .btn:nth-child(odd) {
    margin-right: 24px;
  }
  .nav-application > .btn.active {
    background-color: theme-color("primary");
    color: color-yiq(theme-color("primary"));
  }
  .nav-application > .btn:hover:not(.active) {
    color: theme-color("primary");
    transform: scale(1.05);
  }
  .nav-dots {
    top: 50%;
    right: 2rem;
    position: fixed;
    z-index: 200;
    transform: translateY(-50%);
  }
  .nav-dots .nav-link {
    display: block;
    margin: 5px;
    position: relative;
  }
  .nav-dots .nav-link:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    height: 6px;
    width: 6px;
    border: 0;
    background: #fff;
    left: 50%;
    top: 50%;
    margin: -2px 0 0 -2px;
    transform: translate(-50%, -50%);
    transition: all 0.1s ease-in-out;
  }
  .nav-dots .nav-link:hover:before,
  .nav-dots .nav-link.active:before {
    height: 12px;
    width: 12px;
  }
  .nav-menu .nav-link {
    padding: 0.35rem 0;
  }
  .nav-menu .nav-link:not(.active):hover {
    color: #2d3748;
  }
  .nav-menu .nav-link:not(.active).active {
    font-weight: bolder;
  }
  .nav-tabs .nav-item {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .nav-tabs .nav-item:first-child {
    margin-left: 0;
  }
  .nav-tabs .nav-item:last-child {
    margin-right: 0;
  }
  .nav-tabs .nav-item.show .nav-link {
    border-color: transparent;
  }
  .nav-tabs .nav-link {
    padding: 1.25rem 0;
    border-bottom: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eceef3;
    border-radius: 0.75rem;
  }
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
  }
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px);
  }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
  }
  .card-body {
    flex: 1 1 auto;
    padding: 1.5rem 1.5rem;
    color: #525f7f;
  }
  .card-title {
    margin-bottom: 1.5rem;
  }
  .card-subtitle {
    margin-top: -0.75rem;
    margin-bottom: 0;
  }
  .card-text:last-child {
    margin-bottom: 0;
  }
  .card-link + .card-link {
    margin-left: 1.5rem;
  }
  .card-header {
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    color: #16192c;
    background-color: transparent;
    border-bottom: 1px solid #eceef3;
  }
  .card-header:first-child {
    border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
  }
  .card-footer {
    padding: 1.25rem 1.5rem;
    color: #16192c;
    background-color: transparent;
    border-top: 1px solid #eceef3;
  }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
  }
  .card-header-tabs {
    margin-right: -0.75rem;
    margin-bottom: -1.25rem;
    margin-left: -0.75rem;
    border-bottom: 0;
  }
  .card-header-tabs .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff;
  }
  .card-header-pills {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(0.75rem - 1px);
  }
  .card-img,
  .card-img-top,
  .card-img-bottom {
    width: 100%;
  }
  .card-img,
  .card-img-top {
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
  }
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px);
  }
  .card-group > .card {
    margin-bottom: 0.75rem;
  }
  @media (min-width: 576px) {
    .card-group {
      display: flex;
      flex-flow: row wrap;
    }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
      border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
      border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
      border-bottom-left-radius: 0;
    }
  }
  .card-header-tabs {
    margin-top: calc(-1.5rem - 1px);
    margin-bottom: calc(-1.5rem + 1px);
    margin-left: 0;
    margin-right: 0;
  }
  .card-header-tabs .nav-item {
    margin-right: 0.75rem;
  }
  .card-header-tabs .nav-link.active {
    background-color: transparent;
    border-bottom-color: #4066D5;
  }
  .card-comment-box {
    width: 100%;
  }
  .card-comment-box input,
  .card-comment-box textarea {
    border: 0;
    box-shadow: none;
    background: transparent;
    padding: 0.3125rem 0;
    margin: 0;
    color: #2d3748;
    outline: none;
    resize: none;
  }
  .card-comment-box input:focus,
  .card-comment-box textarea:focus {
    background: transparent;
    box-shadow: none;
  }
  .card-list {
    background-color: #fff;
    border: 1px solid #eceef3;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0.75rem;
  }
  .card-list .card {
    box-shadow: none !important;
  }
  .card-list-body .card:last-of-type {
    margin-bottom: 0 !important;
  }
  .empty-container:after {
    pointer-events: none;
    content: attr(data-placeholder);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500;
    color: #caced0;
    border: 1px dashed #caced0;
    height: 0;
    opacity: 0;
    border-radius: 0.75rem;
    transition: opacity 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .empty-container:after {
      transition: none;
    }
  }
  .empty-container:only-child:after {
    opacity: 1;
    height: 70px;
  }
  .card-list-flush {
    border: 0;
    border-radius: 0;
  }
  .card-placeholder {
    padding: 3rem 0;
    background-color: transparent;
    border: 1px dashed #cfd6df;
  }
  .card-placeholder:hover {
    border-color: #c1c7cf;
  }
  .card-placeholder .card-body {
    text-align: center;
  }
  .card-progress {
    margin-bottom: 0.75rem;
  }
  .card-progress .card-body {
    padding: 1rem;
  }
  .card-progress .progress {
    height: 2px;
    margin: 2px 4px;
  }
  .omnisearch {
    width: 100%;
    margin-top: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    opacity: 0;
    background-color: transparent;
    pointer-events: none;
    transform: translateY(0);
    transition: transform 0.3s, opacity 0.15s;
  }
  @media (prefers-reduced-motion: reduce) {
    .omnisearch {
      transition: none;
    }
  }
  .omnisearch-container {
    margin: auto;
  }
  @media (max-width: 767.98px) {
    .omnisearch-container {
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .omnisearch-container {
      width: 580px;
    }
  }
  .omnisearch.show {
    opacity: 1;
    transform: translateY(10%);
  }
  .omnisearch .container {
    position: relative;
    height: 100%;
  }
  .omnisearch .omnisearch-form {
    display: block;
    position: relative;
    z-index: 700;
    background: #fff;
    border-radius: 0.5rem;
  }
  .omnisearch .omnisearch-form .input-group-text {
    font-size: 1.25rem;
    background: transparent;
  }
  .omnisearch .omnisearch-form .form-control {
    display: block;
    height: 58px;
    font-size: 1.25rem;
    color: #6b7b93;
    background-color: transparent;
    background-image: none;
  }
  .omnisearch .omnisearch-form .form-control::-moz-placeholder {
    color: #8898a9;
  }
  .omnisearch .omnisearch-form .form-control::placeholder {
    color: #8898a9;
  }
  .omnisearch .omnisearch-suggestions {
    min-height: 150px;
    padding: 1.5rem;
    background: #fff;
    border-radius: 0.5rem;
    position: relative;
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0.21s;
  }
  @media (prefers-reduced-motion: reduce) {
    .omnisearch .omnisearch-suggestions {
      transition: none;
    }
  }
  .omnisearch .omnisearch-suggestions:before {
    background: #fff;
    box-shadow: none;
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    left: 20px;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(1rem);
    z-index: -5;
    border-radius: 0.2rem;
  }
  .omnisearch .omnisearch-suggestions .heading {
    color: #525f7f;
  }
  .omnisearch .omnisearch-suggestions .list-link span {
    font-weight: 600;
    color: #2d3748;
  }
  .omnisearch .omnisearch-suggestions .list-link:hover {
    color: theme-color("primary");
  }
  .omnisearch .omnisearch-suggestions .list-link:hover span {
    color: theme-color("primary");
  }
  .omnisearch.show .omnisearch-form,
  .omnisearch.show .omnisearch-suggestions {
    pointer-events: auto;
  }
  .omnisearch.show .omnisearch-suggestions {
    opacity: 1;
  }
  .omnisearch-open {
    overflow: hidden;
  }
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
  }
  .page-link {
    position: relative;
    display: block;
    color: #525f7f;
    background-color: #fff;
    border: 1px solid #cfd6df;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none;
    }
  }
  .page-link:hover {
    z-index: 2;
    color: #16192c;
    background-color: #fafafa;
    border-color: #cfd6df;
  }
  .page-link:focus {
    z-index: 3;
    color: #4e52d0;
    background-color: #f5f9fc;
    outline: 0;
    box-shadow: none;
  }
  .page-item:not(:first-child) .page-link {
    margin-left: -1px;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #fafafa;
    border-color: #fafafa;
  }
  .page-item.disabled .page-link {
    color: #6b7b93;
    pointer-events: none;
    background-color: #fff;
    border-color: #cfd6df;
  }
  .page-link {
    padding: 0.375rem 1rem;
  }
  .page-item:first-child .page-link {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
  }
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .pagination-sm .page-link {
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
  }
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .pagination {
    margin-bottom: 0;
  }
  .pagination .page-link {
    font-size: 0.875rem;
  }
  .pagination-lg .page-link {
    font-size: 1rem;
  }
  .pagination-sm .page-link {
    font-size: 0.875rem;
  }
  .pagination-tabs {
    border-radius: 0;
  }
  .pagination-tabs .page-link {
    margin-top: -1px;
    padding: 1.25rem 0.75rem;
    background-color: transparent;
    border-width: 1px 0 0 0;
    border-color: transparent;
    border-radius: 0 !important;
  }
  .pagination-tabs .page-link:hover {
    color: #4066D5;
  }
  .pagination-tabs .page-item.active .page-link {
    background-color: transparent;
    border-color: #4066D5;
    color: #4066D5;
  }
  .pagination-spaced {
    border-radius: 0;
  }
  .pagination-spaced .page-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    padding: 0;
    border-radius: 0.375rem;
    width: 36px;
    height: 36px;
  }
  .pagination-spaced.pagination-lg {
    width: 52px;
    height: 52px;
  }
  .placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: 0.5;
  }
  .placeholder.btn::before {
    display: inline-block;
    content: "";
  }
  .placeholder-xs {
    min-height: 0.6em;
  }
  .placeholder-sm {
    min-height: 0.8em;
  }
  .placeholder-lg {
    min-height: 1.2em;
  }
  .placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite;
  }
  @-webkit-keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }
  @keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }
  .placeholder-wave {
    -webkit-mask-image: linear-gradient(
      130deg,
      #000 55%,
      rgba(0, 0, 0, 0.8) 75%,
      #000 95%
    );
    mask-image: linear-gradient(
      130deg,
      #000 55%,
      rgba(0, 0, 0, 0.8) 75%,
      #000 95%
    );
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite;
  }
  @-webkit-keyframes placeholder-wave {
    100% {
      -webkit-mask-position: -200% 0%;
      mask-position: -200% 0%;
    }
  }
  @keyframes placeholder-wave {
    100% {
      -webkit-mask-position: -200% 0%;
      mask-position: -200% 0%;
    }
  }
  .popover {
    position: absolute;
    top: 0;
    left: 0 /* rtl:ignore */;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: var(--x-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.625;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
  }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
  }
  .popover .popover-arrow::before,
  .popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  .bs-popover-top > .popover-arrow,
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
    bottom: calc(-0.5rem - 1px);
  }
  .bs-popover-top > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: transparent;
  }
  .bs-popover-top > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }
  .bs-popover-end > .popover-arrow,
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
  }
  .bs-popover-end > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: transparent;
  }
  .bs-popover-end > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }
  .bs-popover-bottom > .popover-arrow,
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
    top: calc(-0.5rem - 1px);
  }
  .bs-popover-bottom > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: transparent;
  }
  .bs-popover-bottom > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }
  .bs-popover-bottom .popover-header::before,
  .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #fff;
  }
  .bs-popover-start > .popover-arrow,
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
  }
  .bs-popover-start > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: transparent;
  }
  .bs-popover-start > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
  }
  .popover-header {
    padding: 0.75rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #16192c;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
  }
  .popover-header:empty {
    display: none;
  }
  .popover-body {
    padding: 0.75rem 0.75rem;
    color: #525f7f;
  }
  .popover-header {
    font-weight: 600;
  }
  .popover-primary {
    background-color: #4066D5;
  }
  .popover-primary .popover-header {
    background-color: #4066D5;
    color: #fff;
  }
  .popover-primary .popover-body {
    color: #fff;
  }
  .popover-primary .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-primary.bs-popover-top .arrow::after,
  .popover-primary.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #4066D5;
  }
  .popover-primary.bs-popover-right .arrow::after {
    border-right-color: #4066D5;
  }
  .popover-primary.bs-popover-bottom .arrow::after,
  .popover-primary.bs-popover-auto[data-popper-placement^="bottom"]
    .arrow::after {
    border-bottom-color: #4066D5;
  }
  .popover-primary.bs-popover-left .arrow::after {
    border-left-color: #4066D5;
  }
  .popover-secondary {
    background-color: #cfd6df;
  }
  .popover-secondary .popover-header {
    background-color: #cfd6df;
    color: #000;
  }
  .popover-secondary .popover-body {
    color: #000;
  }
  .popover-secondary .popover-header {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .popover-secondary.bs-popover-top .arrow::after,
  .popover-secondary.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #cfd6df;
  }
  .popover-secondary.bs-popover-right .arrow::after {
    border-right-color: #cfd6df;
  }
  .popover-secondary.bs-popover-bottom .arrow::after,
  .popover-secondary.bs-popover-auto[data-popper-placement^="bottom"]
    .arrow::after {
    border-bottom-color: #cfd6df;
  }
  .popover-secondary.bs-popover-left .arrow::after {
    border-left-color: #cfd6df;
  }
  .popover-tertiary {
    background-color: #ff579a;
  }
  .popover-tertiary .popover-header {
    background-color: #ff579a;
    color: #fff;
  }
  .popover-tertiary .popover-body {
    color: #fff;
  }
  .popover-tertiary .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-tertiary.bs-popover-top .arrow::after,
  .popover-tertiary.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #ff579a;
  }
  .popover-tertiary.bs-popover-right .arrow::after {
    border-right-color: #ff579a;
  }
  .popover-tertiary.bs-popover-bottom .arrow::after,
  .popover-tertiary.bs-popover-auto[data-popper-placement^="bottom"]
    .arrow::after {
    border-bottom-color: #ff579a;
  }
  .popover-tertiary.bs-popover-left .arrow::after {
    border-left-color: #ff579a;
  }
  .popover-success {
    background-color: #0c8;
  }
  .popover-success .popover-header {
    background-color: #0c8;
    color: #fff;
  }
  .popover-success .popover-body {
    color: #fff;
  }
  .popover-success .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-success.bs-popover-top .arrow::after,
  .popover-success.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #0c8;
  }
  .popover-success.bs-popover-right .arrow::after {
    border-right-color: #0c8;
  }
  .popover-success.bs-popover-bottom .arrow::after,
  .popover-success.bs-popover-auto[data-popper-placement^="bottom"]
    .arrow::after {
    border-bottom-color: #0c8;
  }
  .popover-success.bs-popover-left .arrow::after {
    border-left-color: #0c8;
  }
  .popover-info {
    background-color: #00d4ff;
  }
  .popover-info .popover-header {
    background-color: #00d4ff;
    color: #000;
  }
  .popover-info .popover-body {
    color: #000;
  }
  .popover-info .popover-header {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .popover-info.bs-popover-top .arrow::after,
  .popover-info.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #00d4ff;
  }
  .popover-info.bs-popover-right .arrow::after {
    border-right-color: #00d4ff;
  }
  .popover-info.bs-popover-bottom .arrow::after,
  .popover-info.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after {
    border-bottom-color: #00d4ff;
  }
  .popover-info.bs-popover-left .arrow::after {
    border-left-color: #00d4ff;
  }
  .popover-warning {
    background-color: #ff8c00;
  }
  .popover-warning .popover-header {
    background-color: #ff8c00;
    color: #fff;
  }
  .popover-warning .popover-body {
    color: #fff;
  }
  .popover-warning .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-warning.bs-popover-top .arrow::after,
  .popover-warning.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #ff8c00;
  }
  .popover-warning.bs-popover-right .arrow::after {
    border-right-color: #ff8c00;
  }
  .popover-warning.bs-popover-bottom .arrow::after,
  .popover-warning.bs-popover-auto[data-popper-placement^="bottom"]
    .arrow::after {
    border-bottom-color: #ff8c00;
  }
  .popover-warning.bs-popover-left .arrow::after {
    border-left-color: #ff8c00;
  }
  .popover-danger {
    background-color: #f36;
  }
  .popover-danger .popover-header {
    background-color: #f36;
    color: #fff;
  }
  .popover-danger .popover-body {
    color: #fff;
  }
  .popover-danger .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-danger.bs-popover-top .arrow::after,
  .popover-danger.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #f36;
  }
  .popover-danger.bs-popover-right .arrow::after {
    border-right-color: #f36;
  }
  .popover-danger.bs-popover-bottom .arrow::after,
  .popover-danger.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after {
    border-bottom-color: #f36;
  }
  .popover-danger.bs-popover-left .arrow::after {
    border-left-color: #f36;
  }
  .popover-white {
    background-color: #fff;
  }
  .popover-white .popover-header {
    background-color: #fff;
    color: #000;
  }
  .popover-white .popover-body {
    color: #000;
  }
  .popover-white .popover-header {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .popover-white.bs-popover-top .arrow::after,
  .popover-white.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #fff;
  }
  .popover-white.bs-popover-right .arrow::after {
    border-right-color: #fff;
  }
  .popover-white.bs-popover-bottom .arrow::after,
  .popover-white.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after {
    border-bottom-color: #fff;
  }
  .popover-white.bs-popover-left .arrow::after {
    border-left-color: #fff;
  }
  .popover-light {
    background-color: #e7eaf0;
  }
  .popover-light .popover-header {
    background-color: #e7eaf0;
    color: #000;
  }
  .popover-light .popover-body {
    color: #000;
  }
  .popover-light .popover-header {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .popover-light.bs-popover-top .arrow::after,
  .popover-light.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #e7eaf0;
  }
  .popover-light.bs-popover-right .arrow::after {
    border-right-color: #e7eaf0;
  }
  .popover-light.bs-popover-bottom .arrow::after,
  .popover-light.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after {
    border-bottom-color: #e7eaf0;
  }
  .popover-light.bs-popover-left .arrow::after {
    border-left-color: #e7eaf0;
  }
  .popover-dark {
    background-color: #16192c;
  }
  .popover-dark .popover-header {
    background-color: #16192c;
    color: #fff;
  }
  .popover-dark .popover-body {
    color: #fff;
  }
  .popover-dark .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-dark.bs-popover-top .arrow::after,
  .popover-dark.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: #16192c;
  }
  .popover-dark.bs-popover-right .arrow::after {
    border-right-color: #16192c;
  }
  .popover-dark.bs-popover-bottom .arrow::after,
  .popover-dark.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after {
    border-bottom-color: #16192c;
  }
  .popover-dark.bs-popover-left .arrow::after {
    border-left-color: #16192c;
  }
  @-webkit-keyframes progress-bar-stripes {
    0% {
      background-position-x: 0.5rem;
    }
  }
  @keyframes progress-bar-stripes {
    0% {
      background-position-x: 0.5rem;
    }
  }
  .progress {
    display: flex;
    height: 0.5rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e7eaf0;
    border-radius: 50rem;
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: theme-color("primary");
    transition: width 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }
  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 0.5rem 0.5rem;
  }
  .progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none;
    }
  }
  .progress-wrapper {
    position: relative;
    padding-top: 1.5rem;
  }
  .progress-wrapper .progress {
    margin-bottom: 1rem;
  }
  .progress-inverse {
    background-color: inverse(#e7eaf0);
  }
  .progress-heading {
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0 0 2px;
    padding: 0;
  }
  .progress-text {
    margin-bottom: 0;
  }
  .progress-lg {
    height: 1rem;
  }
  .progress-md {
    height: 0.75rem;
  }
  .progress-sm {
    height: 0.375rem;
  }
  .progress-xs {
    height: 0.125rem;
  }
  .progress-group {
    position: relative;
  }
  .progress-prepend-icon {
    position: absolute;
    transform: translateY(-50%);
    font-size: 20px;
    top: 50%;
  }
  .progress-prepend-icon:not(:first-child) {
    right: -17px;
  }
  .progress-prepend-icon:not(:last-child) {
    left: -17px;
  }
  .progress-circle {
    width: 100px;
    height: 100px;
  }
  .progress-circle .progressbar-text {
    font-size: 1.5rem;
    font-weight: 600;
    color: #16192c;
  }
  .progress-circle [class^="display"],
  .progress-circle .h1,
  .progress-circle .h2,
  .progress-circle .h3 {
    color: #16192c;
  }
  .progress-circle svg path {
    stroke-linecap: round;
  }
  .progress-circle.progress-sm {
    width: 60px;
    height: 60px;
  }
  .progress-circle.progress-sm .progressbar-text {
    font-size: 0.875rem;
  }
  .progress-circle.progress-lg {
    width: 140px;
    height: 140px;
  }
  .progress-circle.progress-lg .progressbar-text {
    font-size: 1.25rem;
  }
  .progress-label {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    color: #16192c;
  }
  .progress-percentage {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
  }
  .progress-tooltip {
    display: inline-block;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 0.25rem 0.375rem;
    line-height: 1;
    font-size: 0.7rem;
    position: relative;
    bottom: 8px;
    border-radius: 3px;
    margin-left: -15px;
  }
  .progress-tooltip:after {
    top: 100%;
    left: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(51, 51, 51, 0);
    border-top-color: rgba(0, 0, 0, 0.8);
    border-width: 5px;
  }
  .shape-container {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    transform: translateZ(0);
    overflow: hidden;
    color: #fff;
  }
  .shape-container svg {
    display: block;
    pointer-events: none;
    vertical-align: baseline;
  }
  .shape-position-top {
    top: 0;
    margin-top: -1px;
  }
  .shape-position-bottom {
    bottom: 0;
    margin-bottom: -1px;
  }
  .shape-orientation-inverse {
    transform: rotate(180deg);
  }
  .shape-line {
    min-height: 19px;
  }
  @-webkit-keyframes spinner-border {
    to {
      transform: rotate(360deg); /* rtl:ignore */
    }
  }
  @keyframes spinner-border {
    to {
      transform: rotate(360deg); /* rtl:ignore */
    }
  }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
  }
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  @-webkit-keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: none;
    }
  }
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: 0.75s linear infinite spinner-grow;
    animation: 0.75s linear infinite spinner-grow;
  }
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  @media (prefers-reduced-motion: reduce) {
    .spinner-border,
    .spinner-grow {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
    }
  }
  .table {
    --x-table-bg: transparent;
    --x-table-accent-bg: transparent;
    --x-table-striped-color: #525f7f;
    --x-table-striped-bg: rgba(0, 0, 0, 0.05);
    --x-table-active-color: #525f7f;
    --x-table-active-bg: rgba(0, 0, 0, 0.1);
    --x-table-hover-color: #525f7f;
    --x-table-hover-bg: rgba(0, 0, 0, 0.02);
    width: 100%;
    margin-bottom: 1rem;
    color: #525f7f;
    vertical-align: middle;
    border-color: #e7eaf0;
  }
  .table > :not(caption) > * > * {
    padding: 0.5rem;
    background-color: var(--x-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--x-table-accent-bg);
  }
  .table > tbody {
    vertical-align: inherit;
  }
  .table > thead {
    vertical-align: bottom;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #e7eaf0;
  }
  .caption-top {
    caption-side: top;
  }
  .table-sm > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
  }
  .table-bordered > :not(caption) > * {
    border-width: 1px 0;
  }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px;
  }
  .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0;
  }
  .table-striped > tbody > tr:nth-of-type(odd) {
    --x-table-accent-bg: var(--x-table-striped-bg);
    color: var(--x-table-striped-color);
  }
  .table-active {
    --x-table-accent-bg: var(--x-table-active-bg);
    color: var(--x-table-active-color);
  }
  .table-hover > tbody > tr:hover {
    --x-table-accent-bg: var(--x-table-hover-bg);
    color: var(--x-table-hover-color);
  }
  .table-primary {
    --x-table-bg: #dedffd;
    --x-table-striped-bg: #d3d4f0;
    --x-table-striped-color: #000;
    --x-table-active-bg: #c8c9e4;
    --x-table-active-color: #000;
    --x-table-hover-bg: #dadbf8;
    --x-table-hover-color: #000;
    color: #000;
    border-color: #c8c9e4;
  }
  .table-secondary {
    --x-table-bg: #f5f7f9;
    --x-table-striped-bg: #e9ebed;
    --x-table-striped-color: #000;
    --x-table-active-bg: #dddee0;
    --x-table-active-color: #000;
    --x-table-hover-bg: #f0f2f4;
    --x-table-hover-color: #000;
    color: #000;
    border-color: #dddee0;
  }
  .table-success {
    --x-table-bg: #ccf5e7;
    --x-table-striped-bg: #c2e9db;
    --x-table-striped-color: #000;
    --x-table-active-bg: #b8ddd0;
    --x-table-active-color: #000;
    --x-table-hover-bg: #c8f0e2;
    --x-table-hover-color: #000;
    color: #000;
    border-color: #b8ddd0;
  }
  .table-info {
    --x-table-bg: #ccf6ff;
    --x-table-striped-bg: #c2eaf2;
    --x-table-striped-color: #000;
    --x-table-active-bg: #b8dde6;
    --x-table-active-color: #000;
    --x-table-hover-bg: #c8f1fa;
    --x-table-hover-color: #000;
    color: #000;
    border-color: #b8dde6;
  }
  .table-warning {
    --x-table-bg: #ffe8cc;
    --x-table-striped-bg: #f2dcc2;
    --x-table-striped-color: #000;
    --x-table-active-bg: #e6d1b8;
    --x-table-active-color: #000;
    --x-table-hover-bg: #fae3c8;
    --x-table-hover-color: #000;
    color: #000;
    border-color: #e6d1b8;
  }
  .table-danger {
    --x-table-bg: #ffd6e0;
    --x-table-striped-bg: #f2cbd5;
    --x-table-striped-color: #000;
    --x-table-active-bg: #e6c1ca;
    --x-table-active-color: #000;
    --x-table-hover-bg: #fad2dc;
    --x-table-hover-color: #000;
    color: #000;
    border-color: #e6c1ca;
  }
  .table-light {
    --x-table-bg: #e7eaf0;
    --x-table-striped-bg: #dbdee4;
    --x-table-striped-color: #000;
    --x-table-active-bg: #d0d3d8;
    --x-table-active-color: #000;
    --x-table-hover-bg: #e2e5eb;
    --x-table-hover-color: #000;
    color: #000;
    border-color: #d0d3d8;
  }
  .table-dark {
    --x-table-bg: #16192c;
    --x-table-striped-bg: #222537;
    --x-table-striped-color: #fff;
    --x-table-active-bg: #2d3041;
    --x-table-active-color: #fff;
    --x-table-hover-bg: #1b1e30;
    --x-table-hover-color: #fff;
    color: #fff;
    border-color: #2d3041;
  }
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1399.98px) {
    .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  .table {
    margin-bottom: 0;
  }
  .table thead th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0.675rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: transparent;
    border-bottom-width: 1px;
    color: #525f7f;
    white-space: nowrap;
    vertical-align: middle;
  }
  .table tbody th {
    font-size: 0.8125rem;
  }
  .table td {
    font-size: 0.8125rem;
    white-space: nowrap;
  }
  .table td .progress {
    height: 3px;
    width: 120px;
    margin: 0;
  }
  .table.table-dark thead th,
  .table .thead-dark th {
    background-color: #1f2234;
    color: #686a76;
  }
  .table.table-dark thead th a,
  .table .thead-dark th a {
    color: #686a76;
  }
  .table.table-light thead th,
  .table .thead-light th {
    background-color: #f5f9fc;
    color: #525f7f;
  }
  .table.table-light thead th a,
  .table .thead-light th a {
    color: #525f7f;
  }
  .table-spaced {
    border-collapse: separate;
    border-spacing: 0 1rem;
  }
  .table-spaced thead th {
    border: 0;
  }
  .table-spaced thead tr th,
  .table-spaced thead tr td,
  .table-spaced tbody tr th,
  .table-spaced tbody tr td {
    position: relative;
    background-color: transparent;
    border-top: 1px solid #e7eaf0 !important;
    border-bottom: 1px solid #e7eaf0 !important;
  }
  .table-spaced thead tr th:first-child,
  .table-spaced thead tr td:first-child,
  .table-spaced tbody tr th:first-child,
  .table-spaced tbody tr td:first-child {
    border-left: 1px solid #e7eaf0 !important;
    border-radius: 0.375rem 0 0 0.375rem;
  }
  .table-spaced thead tr th:first-child:after,
  .table-spaced thead tr td:first-child:after,
  .table-spaced tbody tr th:first-child:after,
  .table-spaced tbody tr td:first-child:after {
    border-left: 1px solid #e7eaf0;
    border-radius: 0.375rem 0 0 0.375rem;
  }
  .table-spaced thead tr th:last-child,
  .table-spaced thead tr td:last-child,
  .table-spaced tbody tr th:last-child,
  .table-spaced tbody tr td:last-child {
    border-right: 1px solid #e7eaf0 !important;
    border-radius: 0 0.375rem 0.375rem 0;
  }
  .table-flush th,
  .table-flush td {
    border-left: 0;
    border-right: 0;
  }
  .table-flush thead > tr th {
    border-top: 0;
  }
  .table-flush tbody > tr:last-child th,
  .table-flush tbody > tr:last-child td {
    border-bottom: 0;
  }
  .toast {
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06);
    border-radius: 0.375rem;
  }
  .toast.showing {
    opacity: 0;
  }
  .toast:not(.show) {
    display: none;
  }
  .toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none;
  }
  .toast-container > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
  .toast-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: #6b7b93;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
  }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem;
  }
  .toast-body {
    padding: 0.75rem;
    word-wrap: break-word;
  }
  .tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--x-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.625;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  .tooltip.show {
    opacity: 0.9;
  }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  .tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  .bs-tooltip-top,
  .bs-tooltip-auto[data-popper-placement^="top"] {
    padding: 0.4rem 0;
  }
  .bs-tooltip-top .tooltip-arrow,
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0;
  }
  .bs-tooltip-top .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
  }
  .bs-tooltip-end,
  .bs-tooltip-auto[data-popper-placement^="right"] {
    padding: 0 0.4rem;
  }
  .bs-tooltip-end .tooltip-arrow,
  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-end .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
  }
  .bs-tooltip-bottom,
  .bs-tooltip-auto[data-popper-placement^="bottom"] {
    padding: 0.4rem 0;
  }
  .bs-tooltip-bottom .tooltip-arrow,
  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0;
  }
  .bs-tooltip-bottom .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
  }
  .bs-tooltip-start,
  .bs-tooltip-auto[data-popper-placement^="left"] {
    padding: 0 0.4rem;
  }
  .bs-tooltip-start .tooltip-arrow,
  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-start .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
  }
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.375rem;
  }
  .article {
    font-size: 1rem;
    color: #525f7f;
    line-height: 1.9;
  }
  .article > p {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .article > h2,
  .article > .h2 {
    font-size: 1.5em;
    font-weight: bolder;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
    letter-spacing: -0.025em;
  }
  .article > h2:first-child,
  .article > .h2:first-child {
    margin-top: 1em;
  }
  .article > h3,
  .article > .h3 {
    font-size: 1.25em;
    font-weight: 600;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
  }
  .article > h4,
  .article > .h4 {
    font-size: 1em;
    font-weight: 500;
    margin-top: 2em;
  }
  .article > img {
    border-radius: 0.375rem;
  }
  .article > figure,
  .article > img,
  .article > video {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .article pre {
    font-size: 0.875em;
    line-height: 1.7;
    padding: 1rem 1rem;
    margin-top: 1.75em;
    margin-bottom: 1.75em;
    overflow-x: auto;
    background-color: #16192c;
    color: #fff;
    border-radius: 0.375rem;
  }
  .article > :first-child {
    margin-top: 0;
  }
  .article h2 + *,
  .article .h2 + *,
  .article h3 + *,
  .article .h3 + *,
  .article hr + * {
    margin-top: 0;
  }
  .article > hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .article > ul,
  .article > ol {
    padding-left: 1.5rem;
  }
  .article > ul li,
  .article > ol li {
    padding: 0.25rem 0;
  }
  .article > blockquote {
    font-weight: 500;
    font-size: 1.25rem;
    color: #16192c;
    letter-spacing: -0.012em;
    padding: 1.5rem 3.5rem;
    margin-top: 3em;
    margin-bottom: 3em;
    background-color: var(--x-surface-secondary);
    border-radius: 0.5rem;
  }
  .article > blockquote > p {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 991.98px) {
    .section-step-lg {
      padding-bottom: 3rem;
    }
    .section-step-lg + .section-step-lg {
      padding-top: 3rem;
    }
    .section-step-lg:last-child {
      padding-bottom: 0;
    }
  }
  @media (min-width: 992px) {
    .section-step-lg {
      position: relative;
      padding-bottom: 5rem;
    }
    .section-step-lg + .section-step-lg {
      padding-top: 5rem;
    }
    .section-step-lg:last-child {
      padding-bottom: 0;
    }
    .section-step-lg:not(:last-child):before {
      content: "";
      display: block;
      width: 360px;
      height: 100px;
      background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23E7EAF0' stroke-width='3' transform='translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)' d='M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E")
        no-repeat center;
      background-size: 360px 100px;
      transform: rotate(40deg);
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: -80px;
    }
  }
  @media (min-width: 992px) and (max-width: 767.98px) {
    .section-step-lg:not(:last-child):before {
      transform: rotate(90deg) scale(0.7);
      bottom: 20px;
    }
  }
  @media (min-width: 992px) {
    .section-step-lg:nth-child(odd) .row > div:first-child {
      order: 2;
    }
    .section-step-lg:nth-child(odd) .row > div:last-child {
      order: 1;
    }
  }
  @media (min-width: 992px) {
    .section-step-lg:nth-child(even):before {
      background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23E7EAF0' stroke-width='3' d='M2.24567891,1.54825684 C122.245679,110.326533 242.245679,130.359459 362.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E")
        no-repeat center;
      background-size: 360px 100px;
      transform: rotate(-40deg);
    }
  }
  @media (min-width: 992px) and (max-width: 767.98px) {
    .section-step-lg:nth-child(even):before {
      transform: rotate(-90deg) scale(0.7);
      bottom: 20px;
    }
  }
  .bg-gray-50 {
    background-color: #fafafa !important;
  }
  .bg-gray-100 {
    background-color: #f5f9fc !important;
  }
  .bg-gray-200 {
    background-color: #e7eaf0 !important;
  }
  .bg-gray-300 {
    background-color: #cfd6df !important;
  }
  .bg-gray-400 {
    background-color: #abb6c5 !important;
  }
  .bg-gray-500 {
    background-color: #8898a9 !important;
  }
  .bg-gray-600 {
    background-color: #6b7b93 !important;
  }
  .bg-gray-700 {
    background-color: #525f7f !important;
  }
  .bg-gray-800 {
    background-color: #2d3748 !important;
  }
  .bg-gray-900 {
    background-color: #16192c !important;
  }
  .bg-blue-100 {
    background-color: #ccebff !important;
  }
  .bg-blue-200 {
    background-color: #99d6ff !important;
  }
  .bg-blue-300 {
    background-color: #66c2ff !important;
  }
  .bg-blue-400 {
    background-color: #33adff !important;
  }
  .bg-blue-500 {
    background-color: #09f !important;
  }
  .bg-blue-600 {
    background-color: #007acc !important;
  }
  .bg-blue-700 {
    background-color: #005c99 !important;
  }
  .bg-blue-800 {
    background-color: #003d66 !important;
  }
  .bg-blue-900 {
    background-color: #001f33 !important;
  }
  .bg-indigo-100 {
    background-color: #dedffd !important;
  }
  .bg-indigo-200 {
    background-color: #bebffb !important;
  }
  .bg-indigo-300 {
    background-color: #9da0f9 !important;
  }
  .bg-indigo-400 {
    background-color: #7d80f7 !important;
  }
  .bg-indigo-500 {
    background-color: #4066D5 !important;
  }
  .bg-indigo-600 {
    background-color: #4a4dc4 !important;
  }
  .bg-indigo-700 {
    background-color: #373a93 !important;
  }
  .bg-indigo-800 {
    background-color: #252662 !important;
  }
  .bg-indigo-900 {
    background-color: #121331 !important;
  }
  .bg-purple-100 {
    background-color: #e7ddff !important;
  }
  .bg-purple-200 {
    background-color: #d0bcff !important;
  }
  .bg-purple-300 {
    background-color: #b89aff !important;
  }
  .bg-purple-400 {
    background-color: #a179ff !important;
  }
  .bg-purple-500 {
    background-color: #8957ff !important;
  }
  .bg-purple-600 {
    background-color: #6e46cc !important;
  }
  .bg-purple-700 {
    background-color: #523499 !important;
  }
  .bg-purple-800 {
    background-color: #372366 !important;
  }
  .bg-purple-900 {
    background-color: #1b1133 !important;
  }
  .bg-pink-100 {
    background-color: #ffddeb !important;
  }
  .bg-pink-200 {
    background-color: #ffbcd7 !important;
  }
  .bg-pink-300 {
    background-color: #ff9ac2 !important;
  }
  .bg-pink-400 {
    background-color: #ff79ae !important;
  }
  .bg-pink-500 {
    background-color: #ff579a !important;
  }
  .bg-pink-600 {
    background-color: #cc467b !important;
  }
  .bg-pink-700 {
    background-color: #99345c !important;
  }
  .bg-pink-800 {
    background-color: #66233e !important;
  }
  .bg-pink-900 {
    background-color: #33111f !important;
  }
  .bg-magenta-100 {
    background-color: #fcd6ff !important;
  }
  .bg-magenta-200 {
    background-color: #f8adff !important;
  }
  .bg-magenta-300 {
    background-color: #f585ff !important;
  }
  .bg-magenta-400 {
    background-color: #f15cff !important;
  }
  .bg-magenta-500 {
    background-color: #e3f !important;
  }
  .bg-magenta-600 {
    background-color: #be29cc !important;
  }
  .bg-magenta-700 {
    background-color: #8f1f99 !important;
  }
  .bg-magenta-800 {
    background-color: #5f1466 !important;
  }
  .bg-magenta-900 {
    background-color: #300a33 !important;
  }
  .bg-red-100 {
    background-color: #ffd6e0 !important;
  }
  .bg-red-200 {
    background-color: #ffadc2 !important;
  }
  .bg-red-300 {
    background-color: #ff85a3 !important;
  }
  .bg-red-400 {
    background-color: #ff5c85 !important;
  }
  .bg-red-500 {
    background-color: #f36 !important;
  }
  .bg-red-600 {
    background-color: #cc2952 !important;
  }
  .bg-red-700 {
    background-color: #991f3d !important;
  }
  .bg-red-800 {
    background-color: #661429 !important;
  }
  .bg-red-900 {
    background-color: #330a14 !important;
  }
  .bg-orange-100 {
    background-color: #ffe8cc !important;
  }
  .bg-orange-200 {
    background-color: #ffd199 !important;
  }
  .bg-orange-300 {
    background-color: #ffba66 !important;
  }
  .bg-orange-400 {
    background-color: #ffa333 !important;
  }
  .bg-orange-500 {
    background-color: #ff8c00 !important;
  }
  .bg-orange-600 {
    background-color: #cc7000 !important;
  }
  .bg-orange-700 {
    background-color: #995400 !important;
  }
  .bg-orange-800 {
    background-color: #663800 !important;
  }
  .bg-orange-900 {
    background-color: #331c00 !important;
  }
  .bg-yellow-100 {
    background-color: #fff1cc !important;
  }
  .bg-yellow-200 {
    background-color: #ffe499 !important;
  }
  .bg-yellow-300 {
    background-color: #ffd666 !important;
  }
  .bg-yellow-400 {
    background-color: #ffc933 !important;
  }
  .bg-yellow-500 {
    background-color: #fb0 !important;
  }
  .bg-yellow-600 {
    background-color: #cc9600 !important;
  }
  .bg-yellow-700 {
    background-color: #997000 !important;
  }
  .bg-yellow-800 {
    background-color: #664b00 !important;
  }
  .bg-yellow-900 {
    background-color: #332500 !important;
  }
  .bg-green-100 {
    background-color: #ccf5e7 !important;
  }
  .bg-green-200 {
    background-color: #99ebcf !important;
  }
  .bg-green-300 {
    background-color: #66e0b8 !important;
  }
  .bg-green-400 {
    background-color: #33d6a0 !important;
  }
  .bg-green-500 {
    background-color: #0c8 !important;
  }
  .bg-green-600 {
    background-color: #00a36d !important;
  }
  .bg-green-700 {
    background-color: #007a52 !important;
  }
  .bg-green-800 {
    background-color: #005236 !important;
  }
  .bg-green-900 {
    background-color: #00291b !important;
  }
  .bg-teal-100 {
    background-color: #d3f8f8 !important;
  }
  .bg-teal-200 {
    background-color: #a7f1f1 !important;
  }
  .bg-teal-300 {
    background-color: #7aebeb !important;
  }
  .bg-teal-400 {
    background-color: #4ee4e4 !important;
  }
  .bg-teal-500 {
    background-color: #2dd !important;
  }
  .bg-teal-600 {
    background-color: #1bb1b1 !important;
  }
  .bg-teal-700 {
    background-color: #148585 !important;
  }
  .bg-teal-800 {
    background-color: #0e5858 !important;
  }
  .bg-teal-900 {
    background-color: #072c2c !important;
  }
  .bg-cyan-100 {
    background-color: #ccf6ff !important;
  }
  .bg-cyan-200 {
    background-color: #9ef !important;
  }
  .bg-cyan-300 {
    background-color: #66e5ff !important;
  }
  .bg-cyan-400 {
    background-color: #3df !important;
  }
  .bg-cyan-500 {
    background-color: #00d4ff !important;
  }
  .bg-cyan-600 {
    background-color: #0ac !important;
  }
  .bg-cyan-700 {
    background-color: #007f99 !important;
  }
  .bg-cyan-800 {
    background-color: #056 !important;
  }
  .bg-cyan-900 {
    background-color: #002a33 !important;
  }
  .bg-tint-primary {
    background-color: #7478f7 !important;
  }
  .bg-shade-primary {
    background-color: #4e52d0 !important;
  }
  .bg-soft-primary {
    background-color: #dedffd !important;
  }
  .bg-tint-secondary {
    background-color: #d6dce4 !important;
  }
  .bg-shade-secondary {
    background-color: #b0b6be !important;
  }
  .bg-soft-secondary {
    background-color: #f5f7f9 !important;
  }
  .bg-tint-tertiary {
    background-color: #ff70a9 !important;
  }
  .bg-shade-tertiary {
    background-color: #d94a83 !important;
  }
  .bg-soft-tertiary {
    background-color: #ffddeb !important;
  }
  .bg-tint-success {
    background-color: #26d49a !important;
  }
  .bg-shade-success {
    background-color: #00ad74 !important;
  }
  .bg-soft-success {
    background-color: #ccf5e7 !important;
  }
  .bg-tint-info {
    background-color: #26daff !important;
  }
  .bg-shade-info {
    background-color: #00b4d9 !important;
  }
  .bg-soft-info {
    background-color: #ccf6ff !important;
  }
  .bg-tint-warning {
    background-color: #ff9d26 !important;
  }
  .bg-shade-warning {
    background-color: #d97700 !important;
  }
  .bg-soft-warning {
    background-color: #ffe8cc !important;
  }
  .bg-tint-danger {
    background-color: #ff527d !important;
  }
  .bg-shade-danger {
    background-color: #d92b57 !important;
  }
  .bg-soft-danger {
    background-color: #ffd6e0 !important;
  }
  .bg-tint-white {
    background-color: #fff !important;
  }
  .bg-shade-white {
    background-color: #d9d9d9 !important;
  }
  .bg-soft-white {
    background-color: #fff !important;
  }
  .bg-tint-light {
    background-color: #ebedf2 !important;
  }
  .bg-shade-light {
    background-color: #c4c7cc !important;
  }
  .bg-soft-light {
    background-color: #fafbfc !important;
  }
  .bg-tint-dark {
    background-color: #393c4c !important;
  }
  .bg-shade-dark {
    background-color: #131525 !important;
  }
  .bg-soft-dark {
    background-color: #d0d1d5 !important;
  }
  .bg-surface-primary {
    background-color: #fff !important;
  }
  .bg-surface-secondary {
    background-color: #ECECEC !important;
  }
  .bg-surface-tertiary {
    background-color: #fafafa !important;
  }
  .bg-surface-light {
    background-color: #e7eaf0 !important;
  }
  .bg-surface-dark {
    background-color: #141729 !important;
  }
  .gradient-top {
    background-image: linear-gradient(to top, var(--x-gradient)) !important;
  }
  .gradient-top-right {
    background-image: linear-gradient(to top right, var(--x-gradient)) !important;
  }
  .gradient-right {
    background-image: linear-gradient(to right, var(--x-gradient)) !important;
  }
  .gradient-bottom-right {
    background-image: linear-gradient(
      to bottom right,
      var(--x-gradient)
    ) !important;
  }
  .gradient-bottom {
    background-image: linear-gradient(to bottom, var(--x-gradient)) !important;
  }
  .gradient-bottom-left {
    background-image: linear-gradient(
      to bottom left,
      var(--x-gradient)
    ) !important;
  }
  .gradient-left {
    background-image: linear-gradient(to left, var(--x-gradient)) !important;
  }
  .gradient-top-left {
    background-image: linear-gradient(to top left, var(--x-gradient)) !important;
  }
  .start-gray-50,
  .start-gray-50-hover:hover {
    --x-start-color: #fafafa;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(250, 250, 250, 0));
  }
  .start-gray-100,
  .start-gray-100-hover:hover {
    --x-start-color: #f5f9fc;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(245, 249, 252, 0));
  }
  .start-gray-200,
  .start-gray-200-hover:hover {
    --x-start-color: #e7eaf0;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(231, 234, 240, 0));
  }
  .start-gray-300,
  .start-gray-300-hover:hover {
    --x-start-color: #cfd6df;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(207, 214, 223, 0));
  }
  .start-gray-400,
  .start-gray-400-hover:hover {
    --x-start-color: #abb6c5;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(171, 182, 197, 0));
  }
  .start-gray-500,
  .start-gray-500-hover:hover {
    --x-start-color: #8898a9;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(136, 152, 169, 0));
  }
  .start-gray-600,
  .start-gray-600-hover:hover {
    --x-start-color: #6b7b93;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(107, 123, 147, 0));
  }
  .start-gray-700,
  .start-gray-700-hover:hover {
    --x-start-color: #525f7f;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(82, 95, 127, 0));
  }
  .start-gray-800,
  .start-gray-800-hover:hover {
    --x-start-color: #2d3748;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(45, 55, 72, 0));
  }
  .start-gray-900,
  .start-gray-900-hover:hover {
    --x-start-color: #16192c;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(22, 25, 44, 0));
  }
  .start-blue-100,
  .start-blue-100-hover:hover {
    --x-start-color: #ccebff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(204, 235, 255, 0));
  }
  .start-blue-200,
  .start-blue-200-hover:hover {
    --x-start-color: #99d6ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(153, 214, 255, 0));
  }
  .start-blue-300,
  .start-blue-300-hover:hover {
    --x-start-color: #66c2ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(102, 194, 255, 0));
  }
  .start-blue-400,
  .start-blue-400-hover:hover {
    --x-start-color: #33adff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(51, 173, 255, 0));
  }
  .start-blue-500,
  .start-blue-500-hover:hover {
    --x-start-color: #0099ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 153, 255, 0));
  }
  .start-blue-600,
  .start-blue-600-hover:hover {
    --x-start-color: #007acc;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 122, 204, 0));
  }
  .start-blue-700,
  .start-blue-700-hover:hover {
    --x-start-color: #005c99;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 92, 153, 0));
  }
  .start-blue-800,
  .start-blue-800-hover:hover {
    --x-start-color: #003d66;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 61, 102, 0));
  }
  .start-blue-900,
  .start-blue-900-hover:hover {
    --x-start-color: #001f33;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 31, 51, 0));
  }
  .start-indigo-100,
  .start-indigo-100-hover:hover {
    --x-start-color: #dedffd;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(222, 223, 253, 0));
  }
  .start-indigo-200,
  .start-indigo-200-hover:hover {
    --x-start-color: #bebffb;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(190, 191, 251, 0));
  }
  .start-indigo-300,
  .start-indigo-300-hover:hover {
    --x-start-color: #9da0f9;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(157, 160, 249, 0));
  }
  .start-indigo-400,
  .start-indigo-400-hover:hover {
    --x-start-color: #7d80f7;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(125, 128, 247, 0));
  }
  .start-indigo-500,
  .start-indigo-500-hover:hover {
    --x-start-color: #4066D5;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(92, 96, 245, 0));
  }
  .start-indigo-600,
  .start-indigo-600-hover:hover {
    --x-start-color: #4a4dc4;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(74, 77, 196, 0));
  }
  .start-indigo-700,
  .start-indigo-700-hover:hover {
    --x-start-color: #373a93;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(55, 58, 147, 0));
  }
  .start-indigo-800,
  .start-indigo-800-hover:hover {
    --x-start-color: #252662;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(37, 38, 98, 0));
  }
  .start-indigo-900,
  .start-indigo-900-hover:hover {
    --x-start-color: #121331;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(18, 19, 49, 0));
  }
  .start-purple-100,
  .start-purple-100-hover:hover {
    --x-start-color: #e7ddff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(231, 221, 255, 0));
  }
  .start-purple-200,
  .start-purple-200-hover:hover {
    --x-start-color: #d0bcff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(208, 188, 255, 0));
  }
  .start-purple-300,
  .start-purple-300-hover:hover {
    --x-start-color: #b89aff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(184, 154, 255, 0));
  }
  .start-purple-400,
  .start-purple-400-hover:hover {
    --x-start-color: #a179ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(161, 121, 255, 0));
  }
  .start-purple-500,
  .start-purple-500-hover:hover {
    --x-start-color: #8957ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(137, 87, 255, 0));
  }
  .start-purple-600,
  .start-purple-600-hover:hover {
    --x-start-color: #6e46cc;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(110, 70, 204, 0));
  }
  .start-purple-700,
  .start-purple-700-hover:hover {
    --x-start-color: #523499;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(82, 52, 153, 0));
  }
  .start-purple-800,
  .start-purple-800-hover:hover {
    --x-start-color: #372366;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(55, 35, 102, 0));
  }
  .start-purple-900,
  .start-purple-900-hover:hover {
    --x-start-color: #1b1133;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(27, 17, 51, 0));
  }
  .start-pink-100,
  .start-pink-100-hover:hover {
    --x-start-color: #ffddeb;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 221, 235, 0));
  }
  .start-pink-200,
  .start-pink-200-hover:hover {
    --x-start-color: #ffbcd7;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 188, 215, 0));
  }
  .start-pink-300,
  .start-pink-300-hover:hover {
    --x-start-color: #ff9ac2;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 154, 194, 0));
  }
  .start-pink-400,
  .start-pink-400-hover:hover {
    --x-start-color: #ff79ae;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 121, 174, 0));
  }
  .start-pink-500,
  .start-pink-500-hover:hover {
    --x-start-color: #ff579a;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 87, 154, 0));
  }
  .start-pink-600,
  .start-pink-600-hover:hover {
    --x-start-color: #cc467b;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(204, 70, 123, 0));
  }
  .start-pink-700,
  .start-pink-700-hover:hover {
    --x-start-color: #99345c;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(153, 52, 92, 0));
  }
  .start-pink-800,
  .start-pink-800-hover:hover {
    --x-start-color: #66233e;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(102, 35, 62, 0));
  }
  .start-pink-900,
  .start-pink-900-hover:hover {
    --x-start-color: #33111f;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(51, 17, 31, 0));
  }
  .start-magenta-100,
  .start-magenta-100-hover:hover {
    --x-start-color: #fcd6ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(252, 214, 255, 0));
  }
  .start-magenta-200,
  .start-magenta-200-hover:hover {
    --x-start-color: #f8adff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(248, 173, 255, 0));
  }
  .start-magenta-300,
  .start-magenta-300-hover:hover {
    --x-start-color: #f585ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(245, 133, 255, 0));
  }
  .start-magenta-400,
  .start-magenta-400-hover:hover {
    --x-start-color: #f15cff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(241, 92, 255, 0));
  }
  .start-magenta-500,
  .start-magenta-500-hover:hover {
    --x-start-color: #ee33ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(238, 51, 255, 0));
  }
  .start-magenta-600,
  .start-magenta-600-hover:hover {
    --x-start-color: #be29cc;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(190, 41, 204, 0));
  }
  .start-magenta-700,
  .start-magenta-700-hover:hover {
    --x-start-color: #8f1f99;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(143, 31, 153, 0));
  }
  .start-magenta-800,
  .start-magenta-800-hover:hover {
    --x-start-color: #5f1466;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(95, 20, 102, 0));
  }
  .start-magenta-900,
  .start-magenta-900-hover:hover {
    --x-start-color: #300a33;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(48, 10, 51, 0));
  }
  .start-red-100,
  .start-red-100-hover:hover {
    --x-start-color: #ffd6e0;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 214, 224, 0));
  }
  .start-red-200,
  .start-red-200-hover:hover {
    --x-start-color: #ffadc2;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 173, 194, 0));
  }
  .start-red-300,
  .start-red-300-hover:hover {
    --x-start-color: #ff85a3;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 133, 163, 0));
  }
  .start-red-400,
  .start-red-400-hover:hover {
    --x-start-color: #ff5c85;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 92, 133, 0));
  }
  .start-red-500,
  .start-red-500-hover:hover {
    --x-start-color: #ff3366;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 51, 102, 0));
  }
  .start-red-600,
  .start-red-600-hover:hover {
    --x-start-color: #cc2952;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(204, 41, 82, 0));
  }
  .start-red-700,
  .start-red-700-hover:hover {
    --x-start-color: #991f3d;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(153, 31, 61, 0));
  }
  .start-red-800,
  .start-red-800-hover:hover {
    --x-start-color: #661429;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(102, 20, 41, 0));
  }
  .start-red-900,
  .start-red-900-hover:hover {
    --x-start-color: #330a14;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(51, 10, 20, 0));
  }
  .start-orange-100,
  .start-orange-100-hover:hover {
    --x-start-color: #ffe8cc;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 232, 204, 0));
  }
  .start-orange-200,
  .start-orange-200-hover:hover {
    --x-start-color: #ffd199;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 209, 153, 0));
  }
  .start-orange-300,
  .start-orange-300-hover:hover {
    --x-start-color: #ffba66;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 186, 102, 0));
  }
  .start-orange-400,
  .start-orange-400-hover:hover {
    --x-start-color: #ffa333;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 163, 51, 0));
  }
  .start-orange-500,
  .start-orange-500-hover:hover {
    --x-start-color: #ff8c00;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 140, 0, 0));
  }
  .start-orange-600,
  .start-orange-600-hover:hover {
    --x-start-color: #cc7000;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(204, 112, 0, 0));
  }
  .start-orange-700,
  .start-orange-700-hover:hover {
    --x-start-color: #995400;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(153, 84, 0, 0));
  }
  .start-orange-800,
  .start-orange-800-hover:hover {
    --x-start-color: #663800;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(102, 56, 0, 0));
  }
  .start-orange-900,
  .start-orange-900-hover:hover {
    --x-start-color: #331c00;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(51, 28, 0, 0));
  }
  .start-yellow-100,
  .start-yellow-100-hover:hover {
    --x-start-color: #fff1cc;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 241, 204, 0));
  }
  .start-yellow-200,
  .start-yellow-200-hover:hover {
    --x-start-color: #ffe499;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 228, 153, 0));
  }
  .start-yellow-300,
  .start-yellow-300-hover:hover {
    --x-start-color: #ffd666;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 214, 102, 0));
  }
  .start-yellow-400,
  .start-yellow-400-hover:hover {
    --x-start-color: #ffc933;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 201, 51, 0));
  }
  .start-yellow-500,
  .start-yellow-500-hover:hover {
    --x-start-color: #ffbb00;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(255, 187, 0, 0));
  }
  .start-yellow-600,
  .start-yellow-600-hover:hover {
    --x-start-color: #cc9600;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(204, 150, 0, 0));
  }
  .start-yellow-700,
  .start-yellow-700-hover:hover {
    --x-start-color: #997000;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(153, 112, 0, 0));
  }
  .start-yellow-800,
  .start-yellow-800-hover:hover {
    --x-start-color: #664b00;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(102, 75, 0, 0));
  }
  .start-yellow-900,
  .start-yellow-900-hover:hover {
    --x-start-color: #332500;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(51, 37, 0, 0));
  }
  .start-green-100,
  .start-green-100-hover:hover {
    --x-start-color: #ccf5e7;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(204, 245, 231, 0));
  }
  .start-green-200,
  .start-green-200-hover:hover {
    --x-start-color: #99ebcf;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(153, 235, 207, 0));
  }
  .start-green-300,
  .start-green-300-hover:hover {
    --x-start-color: #66e0b8;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(102, 224, 184, 0));
  }
  .start-green-400,
  .start-green-400-hover:hover {
    --x-start-color: #33d6a0;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(51, 214, 160, 0));
  }
  .start-green-500,
  .start-green-500-hover:hover {
    --x-start-color: #00cc88;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 204, 136, 0));
  }
  .start-green-600,
  .start-green-600-hover:hover {
    --x-start-color: #00a36d;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 163, 109, 0));
  }
  .start-green-700,
  .start-green-700-hover:hover {
    --x-start-color: #007a52;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 122, 82, 0));
  }
  .start-green-800,
  .start-green-800-hover:hover {
    --x-start-color: #005236;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 82, 54, 0));
  }
  .start-green-900,
  .start-green-900-hover:hover {
    --x-start-color: #00291b;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 41, 27, 0));
  }
  .start-teal-100,
  .start-teal-100-hover:hover {
    --x-start-color: #d3f8f8;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(211, 248, 248, 0));
  }
  .start-teal-200,
  .start-teal-200-hover:hover {
    --x-start-color: #a7f1f1;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(167, 241, 241, 0));
  }
  .start-teal-300,
  .start-teal-300-hover:hover {
    --x-start-color: #7aebeb;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(122, 235, 235, 0));
  }
  .start-teal-400,
  .start-teal-400-hover:hover {
    --x-start-color: #4ee4e4;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(78, 228, 228, 0));
  }
  .start-teal-500,
  .start-teal-500-hover:hover {
    --x-start-color: #22dddd;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(34, 221, 221, 0));
  }
  .start-teal-600,
  .start-teal-600-hover:hover {
    --x-start-color: #1bb1b1;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(27, 177, 177, 0));
  }
  .start-teal-700,
  .start-teal-700-hover:hover {
    --x-start-color: #148585;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(20, 133, 133, 0));
  }
  .start-teal-800,
  .start-teal-800-hover:hover {
    --x-start-color: #0e5858;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(14, 88, 88, 0));
  }
  .start-teal-900,
  .start-teal-900-hover:hover {
    --x-start-color: #072c2c;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(7, 44, 44, 0));
  }
  .start-cyan-100,
  .start-cyan-100-hover:hover {
    --x-start-color: #ccf6ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(204, 246, 255, 0));
  }
  .start-cyan-200,
  .start-cyan-200-hover:hover {
    --x-start-color: #99eeff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(153, 238, 255, 0));
  }
  .start-cyan-300,
  .start-cyan-300-hover:hover {
    --x-start-color: #66e5ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(102, 229, 255, 0));
  }
  .start-cyan-400,
  .start-cyan-400-hover:hover {
    --x-start-color: #33ddff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(51, 221, 255, 0));
  }
  .start-cyan-500,
  .start-cyan-500-hover:hover {
    --x-start-color: #00d4ff;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 212, 255, 0));
  }
  .start-cyan-600,
  .start-cyan-600-hover:hover {
    --x-start-color: #00aacc;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 170, 204, 0));
  }
  .start-cyan-700,
  .start-cyan-700-hover:hover {
    --x-start-color: #007f99;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 127, 153, 0));
  }
  .start-cyan-800,
  .start-cyan-800-hover:hover {
    --x-start-color: #005566;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 85, 102, 0));
  }
  .start-cyan-900,
  .start-cyan-900-hover:hover {
    --x-start-color: #002a33;
    --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 42, 51, 0));
  }
  .middle-gray-50,
  .middle-gray-50-hover:hover {
    --x-gradient: var(--x-start-color), #fafafa,
      var(--x-end-color, rgba(250, 250, 250, 0));
  }
  .middle-gray-100,
  .middle-gray-100-hover:hover {
    --x-gradient: var(--x-start-color), #f5f9fc,
      var(--x-end-color, rgba(245, 249, 252, 0));
  }
  .middle-gray-200,
  .middle-gray-200-hover:hover {
    --x-gradient: var(--x-start-color), #e7eaf0,
      var(--x-end-color, rgba(231, 234, 240, 0));
  }
  .middle-gray-300,
  .middle-gray-300-hover:hover {
    --x-gradient: var(--x-start-color), #cfd6df,
      var(--x-end-color, rgba(207, 214, 223, 0));
  }
  .middle-gray-400,
  .middle-gray-400-hover:hover {
    --x-gradient: var(--x-start-color), #abb6c5,
      var(--x-end-color, rgba(171, 182, 197, 0));
  }
  .middle-gray-500,
  .middle-gray-500-hover:hover {
    --x-gradient: var(--x-start-color), #8898a9,
      var(--x-end-color, rgba(136, 152, 169, 0));
  }
  .middle-gray-600,
  .middle-gray-600-hover:hover {
    --x-gradient: var(--x-start-color), #6b7b93,
      var(--x-end-color, rgba(107, 123, 147, 0));
  }
  .middle-gray-700,
  .middle-gray-700-hover:hover {
    --x-gradient: var(--x-start-color), #525f7f,
      var(--x-end-color, rgba(82, 95, 127, 0));
  }
  .middle-gray-800,
  .middle-gray-800-hover:hover {
    --x-gradient: var(--x-start-color), #2d3748,
      var(--x-end-color, rgba(45, 55, 72, 0));
  }
  .middle-gray-900,
  .middle-gray-900-hover:hover {
    --x-gradient: var(--x-start-color), #16192c,
      var(--x-end-color, rgba(22, 25, 44, 0));
  }
  .middle-blue-100,
  .middle-blue-100-hover:hover {
    --x-gradient: var(--x-start-color), #ccebff,
      var(--x-end-color, rgba(204, 235, 255, 0));
  }
  .middle-blue-200,
  .middle-blue-200-hover:hover {
    --x-gradient: var(--x-start-color), #99d6ff,
      var(--x-end-color, rgba(153, 214, 255, 0));
  }
  .middle-blue-300,
  .middle-blue-300-hover:hover {
    --x-gradient: var(--x-start-color), #66c2ff,
      var(--x-end-color, rgba(102, 194, 255, 0));
  }
  .middle-blue-400,
  .middle-blue-400-hover:hover {
    --x-gradient: var(--x-start-color), #33adff,
      var(--x-end-color, rgba(51, 173, 255, 0));
  }
  .middle-blue-500,
  .middle-blue-500-hover:hover {
    --x-gradient: var(--x-start-color), #0099ff,
      var(--x-end-color, rgba(0, 153, 255, 0));
  }
  .middle-blue-600,
  .middle-blue-600-hover:hover {
    --x-gradient: var(--x-start-color), #007acc,
      var(--x-end-color, rgba(0, 122, 204, 0));
  }
  .middle-blue-700,
  .middle-blue-700-hover:hover {
    --x-gradient: var(--x-start-color), #005c99,
      var(--x-end-color, rgba(0, 92, 153, 0));
  }
  .middle-blue-800,
  .middle-blue-800-hover:hover {
    --x-gradient: var(--x-start-color), #003d66,
      var(--x-end-color, rgba(0, 61, 102, 0));
  }
  .middle-blue-900,
  .middle-blue-900-hover:hover {
    --x-gradient: var(--x-start-color), #001f33,
      var(--x-end-color, rgba(0, 31, 51, 0));
  }
  .middle-indigo-100,
  .middle-indigo-100-hover:hover {
    --x-gradient: var(--x-start-color), #dedffd,
      var(--x-end-color, rgba(222, 223, 253, 0));
  }
  .middle-indigo-200,
  .middle-indigo-200-hover:hover {
    --x-gradient: var(--x-start-color), #bebffb,
      var(--x-end-color, rgba(190, 191, 251, 0));
  }
  .middle-indigo-300,
  .middle-indigo-300-hover:hover {
    --x-gradient: var(--x-start-color), #9da0f9,
      var(--x-end-color, rgba(157, 160, 249, 0));
  }
  .middle-indigo-400,
  .middle-indigo-400-hover:hover {
    --x-gradient: var(--x-start-color), #7d80f7,
      var(--x-end-color, rgba(125, 128, 247, 0));
  }
  .middle-indigo-500,
  .middle-indigo-500-hover:hover {
    --x-gradient: var(--x-start-color), #4066D5,
      var(--x-end-color, rgba(92, 96, 245, 0));
  }
  .middle-indigo-600,
  .middle-indigo-600-hover:hover {
    --x-gradient: var(--x-start-color), #4a4dc4,
      var(--x-end-color, rgba(74, 77, 196, 0));
  }
  .middle-indigo-700,
  .middle-indigo-700-hover:hover {
    --x-gradient: var(--x-start-color), #373a93,
      var(--x-end-color, rgba(55, 58, 147, 0));
  }
  .middle-indigo-800,
  .middle-indigo-800-hover:hover {
    --x-gradient: var(--x-start-color), #252662,
      var(--x-end-color, rgba(37, 38, 98, 0));
  }
  .middle-indigo-900,
  .middle-indigo-900-hover:hover {
    --x-gradient: var(--x-start-color), #121331,
      var(--x-end-color, rgba(18, 19, 49, 0));
  }
  .middle-purple-100,
  .middle-purple-100-hover:hover {
    --x-gradient: var(--x-start-color), #e7ddff,
      var(--x-end-color, rgba(231, 221, 255, 0));
  }
  .middle-purple-200,
  .middle-purple-200-hover:hover {
    --x-gradient: var(--x-start-color), #d0bcff,
      var(--x-end-color, rgba(208, 188, 255, 0));
  }
  .middle-purple-300,
  .middle-purple-300-hover:hover {
    --x-gradient: var(--x-start-color), #b89aff,
      var(--x-end-color, rgba(184, 154, 255, 0));
  }
  .middle-purple-400,
  .middle-purple-400-hover:hover {
    --x-gradient: var(--x-start-color), #a179ff,
      var(--x-end-color, rgba(161, 121, 255, 0));
  }
  .middle-purple-500,
  .middle-purple-500-hover:hover {
    --x-gradient: var(--x-start-color), #8957ff,
      var(--x-end-color, rgba(137, 87, 255, 0));
  }
  .middle-purple-600,
  .middle-purple-600-hover:hover {
    --x-gradient: var(--x-start-color), #6e46cc,
      var(--x-end-color, rgba(110, 70, 204, 0));
  }
  .middle-purple-700,
  .middle-purple-700-hover:hover {
    --x-gradient: var(--x-start-color), #523499,
      var(--x-end-color, rgba(82, 52, 153, 0));
  }
  .middle-purple-800,
  .middle-purple-800-hover:hover {
    --x-gradient: var(--x-start-color), #372366,
      var(--x-end-color, rgba(55, 35, 102, 0));
  }
  .middle-purple-900,
  .middle-purple-900-hover:hover {
    --x-gradient: var(--x-start-color), #1b1133,
      var(--x-end-color, rgba(27, 17, 51, 0));
  }
  .middle-pink-100,
  .middle-pink-100-hover:hover {
    --x-gradient: var(--x-start-color), #ffddeb,
      var(--x-end-color, rgba(255, 221, 235, 0));
  }
  .middle-pink-200,
  .middle-pink-200-hover:hover {
    --x-gradient: var(--x-start-color), #ffbcd7,
      var(--x-end-color, rgba(255, 188, 215, 0));
  }
  .middle-pink-300,
  .middle-pink-300-hover:hover {
    --x-gradient: var(--x-start-color), #ff9ac2,
      var(--x-end-color, rgba(255, 154, 194, 0));
  }
  .middle-pink-400,
  .middle-pink-400-hover:hover {
    --x-gradient: var(--x-start-color), #ff79ae,
      var(--x-end-color, rgba(255, 121, 174, 0));
  }
  .middle-pink-500,
  .middle-pink-500-hover:hover {
    --x-gradient: var(--x-start-color), #ff579a,
      var(--x-end-color, rgba(255, 87, 154, 0));
  }
  .middle-pink-600,
  .middle-pink-600-hover:hover {
    --x-gradient: var(--x-start-color), #cc467b,
      var(--x-end-color, rgba(204, 70, 123, 0));
  }
  .middle-pink-700,
  .middle-pink-700-hover:hover {
    --x-gradient: var(--x-start-color), #99345c,
      var(--x-end-color, rgba(153, 52, 92, 0));
  }
  .middle-pink-800,
  .middle-pink-800-hover:hover {
    --x-gradient: var(--x-start-color), #66233e,
      var(--x-end-color, rgba(102, 35, 62, 0));
  }
  .middle-pink-900,
  .middle-pink-900-hover:hover {
    --x-gradient: var(--x-start-color), #33111f,
      var(--x-end-color, rgba(51, 17, 31, 0));
  }
  .middle-magenta-100,
  .middle-magenta-100-hover:hover {
    --x-gradient: var(--x-start-color), #fcd6ff,
      var(--x-end-color, rgba(252, 214, 255, 0));
  }
  .middle-magenta-200,
  .middle-magenta-200-hover:hover {
    --x-gradient: var(--x-start-color), #f8adff,
      var(--x-end-color, rgba(248, 173, 255, 0));
  }
  .middle-magenta-300,
  .middle-magenta-300-hover:hover {
    --x-gradient: var(--x-start-color), #f585ff,
      var(--x-end-color, rgba(245, 133, 255, 0));
  }
  .middle-magenta-400,
  .middle-magenta-400-hover:hover {
    --x-gradient: var(--x-start-color), #f15cff,
      var(--x-end-color, rgba(241, 92, 255, 0));
  }
  .middle-magenta-500,
  .middle-magenta-500-hover:hover {
    --x-gradient: var(--x-start-color), #ee33ff,
      var(--x-end-color, rgba(238, 51, 255, 0));
  }
  .middle-magenta-600,
  .middle-magenta-600-hover:hover {
    --x-gradient: var(--x-start-color), #be29cc,
      var(--x-end-color, rgba(190, 41, 204, 0));
  }
  .middle-magenta-700,
  .middle-magenta-700-hover:hover {
    --x-gradient: var(--x-start-color), #8f1f99,
      var(--x-end-color, rgba(143, 31, 153, 0));
  }
  .middle-magenta-800,
  .middle-magenta-800-hover:hover {
    --x-gradient: var(--x-start-color), #5f1466,
      var(--x-end-color, rgba(95, 20, 102, 0));
  }
  .middle-magenta-900,
  .middle-magenta-900-hover:hover {
    --x-gradient: var(--x-start-color), #300a33,
      var(--x-end-color, rgba(48, 10, 51, 0));
  }
  .middle-red-100,
  .middle-red-100-hover:hover {
    --x-gradient: var(--x-start-color), #ffd6e0,
      var(--x-end-color, rgba(255, 214, 224, 0));
  }
  .middle-red-200,
  .middle-red-200-hover:hover {
    --x-gradient: var(--x-start-color), #ffadc2,
      var(--x-end-color, rgba(255, 173, 194, 0));
  }
  .middle-red-300,
  .middle-red-300-hover:hover {
    --x-gradient: var(--x-start-color), #ff85a3,
      var(--x-end-color, rgba(255, 133, 163, 0));
  }
  .middle-red-400,
  .middle-red-400-hover:hover {
    --x-gradient: var(--x-start-color), #ff5c85,
      var(--x-end-color, rgba(255, 92, 133, 0));
  }
  .middle-red-500,
  .middle-red-500-hover:hover {
    --x-gradient: var(--x-start-color), #ff3366,
      var(--x-end-color, rgba(255, 51, 102, 0));
  }
  .middle-red-600,
  .middle-red-600-hover:hover {
    --x-gradient: var(--x-start-color), #cc2952,
      var(--x-end-color, rgba(204, 41, 82, 0));
  }
  .middle-red-700,
  .middle-red-700-hover:hover {
    --x-gradient: var(--x-start-color), #991f3d,
      var(--x-end-color, rgba(153, 31, 61, 0));
  }
  .middle-red-800,
  .middle-red-800-hover:hover {
    --x-gradient: var(--x-start-color), #661429,
      var(--x-end-color, rgba(102, 20, 41, 0));
  }
  .middle-red-900,
  .middle-red-900-hover:hover {
    --x-gradient: var(--x-start-color), #330a14,
      var(--x-end-color, rgba(51, 10, 20, 0));
  }
  .middle-orange-100,
  .middle-orange-100-hover:hover {
    --x-gradient: var(--x-start-color), #ffe8cc,
      var(--x-end-color, rgba(255, 232, 204, 0));
  }
  .middle-orange-200,
  .middle-orange-200-hover:hover {
    --x-gradient: var(--x-start-color), #ffd199,
      var(--x-end-color, rgba(255, 209, 153, 0));
  }
  .middle-orange-300,
  .middle-orange-300-hover:hover {
    --x-gradient: var(--x-start-color), #ffba66,
      var(--x-end-color, rgba(255, 186, 102, 0));
  }
  .middle-orange-400,
  .middle-orange-400-hover:hover {
    --x-gradient: var(--x-start-color), #ffa333,
      var(--x-end-color, rgba(255, 163, 51, 0));
  }
  .middle-orange-500,
  .middle-orange-500-hover:hover {
    --x-gradient: var(--x-start-color), #ff8c00,
      var(--x-end-color, rgba(255, 140, 0, 0));
  }
  .middle-orange-600,
  .middle-orange-600-hover:hover {
    --x-gradient: var(--x-start-color), #cc7000,
      var(--x-end-color, rgba(204, 112, 0, 0));
  }
  .middle-orange-700,
  .middle-orange-700-hover:hover {
    --x-gradient: var(--x-start-color), #995400,
      var(--x-end-color, rgba(153, 84, 0, 0));
  }
  .middle-orange-800,
  .middle-orange-800-hover:hover {
    --x-gradient: var(--x-start-color), #663800,
      var(--x-end-color, rgba(102, 56, 0, 0));
  }
  .middle-orange-900,
  .middle-orange-900-hover:hover {
    --x-gradient: var(--x-start-color), #331c00,
      var(--x-end-color, rgba(51, 28, 0, 0));
  }
  .middle-yellow-100,
  .middle-yellow-100-hover:hover {
    --x-gradient: var(--x-start-color), #fff1cc,
      var(--x-end-color, rgba(255, 241, 204, 0));
  }
  .middle-yellow-200,
  .middle-yellow-200-hover:hover {
    --x-gradient: var(--x-start-color), #ffe499,
      var(--x-end-color, rgba(255, 228, 153, 0));
  }
  .middle-yellow-300,
  .middle-yellow-300-hover:hover {
    --x-gradient: var(--x-start-color), #ffd666,
      var(--x-end-color, rgba(255, 214, 102, 0));
  }
  .middle-yellow-400,
  .middle-yellow-400-hover:hover {
    --x-gradient: var(--x-start-color), #ffc933,
      var(--x-end-color, rgba(255, 201, 51, 0));
  }
  .middle-yellow-500,
  .middle-yellow-500-hover:hover {
    --x-gradient: var(--x-start-color), #ffbb00,
      var(--x-end-color, rgba(255, 187, 0, 0));
  }
  .middle-yellow-600,
  .middle-yellow-600-hover:hover {
    --x-gradient: var(--x-start-color), #cc9600,
      var(--x-end-color, rgba(204, 150, 0, 0));
  }
  .middle-yellow-700,
  .middle-yellow-700-hover:hover {
    --x-gradient: var(--x-start-color), #997000,
      var(--x-end-color, rgba(153, 112, 0, 0));
  }
  .middle-yellow-800,
  .middle-yellow-800-hover:hover {
    --x-gradient: var(--x-start-color), #664b00,
      var(--x-end-color, rgba(102, 75, 0, 0));
  }
  .middle-yellow-900,
  .middle-yellow-900-hover:hover {
    --x-gradient: var(--x-start-color), #332500,
      var(--x-end-color, rgba(51, 37, 0, 0));
  }
  .middle-green-100,
  .middle-green-100-hover:hover {
    --x-gradient: var(--x-start-color), #ccf5e7,
      var(--x-end-color, rgba(204, 245, 231, 0));
  }
  .middle-green-200,
  .middle-green-200-hover:hover {
    --x-gradient: var(--x-start-color), #99ebcf,
      var(--x-end-color, rgba(153, 235, 207, 0));
  }
  .middle-green-300,
  .middle-green-300-hover:hover {
    --x-gradient: var(--x-start-color), #66e0b8,
      var(--x-end-color, rgba(102, 224, 184, 0));
  }
  .middle-green-400,
  .middle-green-400-hover:hover {
    --x-gradient: var(--x-start-color), #33d6a0,
      var(--x-end-color, rgba(51, 214, 160, 0));
  }
  .middle-green-500,
  .middle-green-500-hover:hover {
    --x-gradient: var(--x-start-color), #00cc88,
      var(--x-end-color, rgba(0, 204, 136, 0));
  }
  .middle-green-600,
  .middle-green-600-hover:hover {
    --x-gradient: var(--x-start-color), #00a36d,
      var(--x-end-color, rgba(0, 163, 109, 0));
  }
  .middle-green-700,
  .middle-green-700-hover:hover {
    --x-gradient: var(--x-start-color), #007a52,
      var(--x-end-color, rgba(0, 122, 82, 0));
  }
  .middle-green-800,
  .middle-green-800-hover:hover {
    --x-gradient: var(--x-start-color), #005236,
      var(--x-end-color, rgba(0, 82, 54, 0));
  }
  .middle-green-900,
  .middle-green-900-hover:hover {
    --x-gradient: var(--x-start-color), #00291b,
      var(--x-end-color, rgba(0, 41, 27, 0));
  }
  .middle-teal-100,
  .middle-teal-100-hover:hover {
    --x-gradient: var(--x-start-color), #d3f8f8,
      var(--x-end-color, rgba(211, 248, 248, 0));
  }
  .middle-teal-200,
  .middle-teal-200-hover:hover {
    --x-gradient: var(--x-start-color), #a7f1f1,
      var(--x-end-color, rgba(167, 241, 241, 0));
  }
  .middle-teal-300,
  .middle-teal-300-hover:hover {
    --x-gradient: var(--x-start-color), #7aebeb,
      var(--x-end-color, rgba(122, 235, 235, 0));
  }
  .middle-teal-400,
  .middle-teal-400-hover:hover {
    --x-gradient: var(--x-start-color), #4ee4e4,
      var(--x-end-color, rgba(78, 228, 228, 0));
  }
  .middle-teal-500,
  .middle-teal-500-hover:hover {
    --x-gradient: var(--x-start-color), #22dddd,
      var(--x-end-color, rgba(34, 221, 221, 0));
  }
  .middle-teal-600,
  .middle-teal-600-hover:hover {
    --x-gradient: var(--x-start-color), #1bb1b1,
      var(--x-end-color, rgba(27, 177, 177, 0));
  }
  .middle-teal-700,
  .middle-teal-700-hover:hover {
    --x-gradient: var(--x-start-color), #148585,
      var(--x-end-color, rgba(20, 133, 133, 0));
  }
  .middle-teal-800,
  .middle-teal-800-hover:hover {
    --x-gradient: var(--x-start-color), #0e5858,
      var(--x-end-color, rgba(14, 88, 88, 0));
  }
  .middle-teal-900,
  .middle-teal-900-hover:hover {
    --x-gradient: var(--x-start-color), #072c2c,
      var(--x-end-color, rgba(7, 44, 44, 0));
  }
  .middle-cyan-100,
  .middle-cyan-100-hover:hover {
    --x-gradient: var(--x-start-color), #ccf6ff,
      var(--x-end-color, rgba(204, 246, 255, 0));
  }
  .middle-cyan-200,
  .middle-cyan-200-hover:hover {
    --x-gradient: var(--x-start-color), #99eeff,
      var(--x-end-color, rgba(153, 238, 255, 0));
  }
  .middle-cyan-300,
  .middle-cyan-300-hover:hover {
    --x-gradient: var(--x-start-color), #66e5ff,
      var(--x-end-color, rgba(102, 229, 255, 0));
  }
  .middle-cyan-400,
  .middle-cyan-400-hover:hover {
    --x-gradient: var(--x-start-color), #33ddff,
      var(--x-end-color, rgba(51, 221, 255, 0));
  }
  .middle-cyan-500,
  .middle-cyan-500-hover:hover {
    --x-gradient: var(--x-start-color), #00d4ff,
      var(--x-end-color, rgba(0, 212, 255, 0));
  }
  .middle-cyan-600,
  .middle-cyan-600-hover:hover {
    --x-gradient: var(--x-start-color), #00aacc,
      var(--x-end-color, rgba(0, 170, 204, 0));
  }
  .middle-cyan-700,
  .middle-cyan-700-hover:hover {
    --x-gradient: var(--x-start-color), #007f99,
      var(--x-end-color, rgba(0, 127, 153, 0));
  }
  .middle-cyan-800,
  .middle-cyan-800-hover:hover {
    --x-gradient: var(--x-start-color), #005566,
      var(--x-end-color, rgba(0, 85, 102, 0));
  }
  .middle-cyan-900,
  .middle-cyan-900-hover:hover {
    --x-gradient: var(--x-start-color), #002a33,
      var(--x-end-color, rgba(0, 42, 51, 0));
  }
  .end-gray-50,
  .end-gray-50-hover:hover {
    --x-end-color: #fafafa;
  }
  .end-gray-100,
  .end-gray-100-hover:hover {
    --x-end-color: #f5f9fc;
  }
  .end-gray-200,
  .end-gray-200-hover:hover {
    --x-end-color: #e7eaf0;
  }
  .end-gray-300,
  .end-gray-300-hover:hover {
    --x-end-color: #cfd6df;
  }
  .end-gray-400,
  .end-gray-400-hover:hover {
    --x-end-color: #abb6c5;
  }
  .end-gray-500,
  .end-gray-500-hover:hover {
    --x-end-color: #8898a9;
  }
  .end-gray-600,
  .end-gray-600-hover:hover {
    --x-end-color: #6b7b93;
  }
  .end-gray-700,
  .end-gray-700-hover:hover {
    --x-end-color: #525f7f;
  }
  .end-gray-800,
  .end-gray-800-hover:hover {
    --x-end-color: #2d3748;
  }
  .end-gray-900,
  .end-gray-900-hover:hover {
    --x-end-color: #16192c;
  }
  .end-blue-100,
  .end-blue-100-hover:hover {
    --x-end-color: #ccebff;
  }
  .end-blue-200,
  .end-blue-200-hover:hover {
    --x-end-color: #99d6ff;
  }
  .end-blue-300,
  .end-blue-300-hover:hover {
    --x-end-color: #66c2ff;
  }
  .end-blue-400,
  .end-blue-400-hover:hover {
    --x-end-color: #33adff;
  }
  .end-blue-500,
  .end-blue-500-hover:hover {
    --x-end-color: #0099ff;
  }
  .end-blue-600,
  .end-blue-600-hover:hover {
    --x-end-color: #007acc;
  }
  .end-blue-700,
  .end-blue-700-hover:hover {
    --x-end-color: #005c99;
  }
  .end-blue-800,
  .end-blue-800-hover:hover {
    --x-end-color: #003d66;
  }
  .end-blue-900,
  .end-blue-900-hover:hover {
    --x-end-color: #001f33;
  }
  .end-indigo-100,
  .end-indigo-100-hover:hover {
    --x-end-color: #dedffd;
  }
  .end-indigo-200,
  .end-indigo-200-hover:hover {
    --x-end-color: #bebffb;
  }
  .end-indigo-300,
  .end-indigo-300-hover:hover {
    --x-end-color: #9da0f9;
  }
  .end-indigo-400,
  .end-indigo-400-hover:hover {
    --x-end-color: #7d80f7;
  }
  .end-indigo-500,
  .end-indigo-500-hover:hover {
    --x-end-color: #4066D5;
  }
  .end-indigo-600,
  .end-indigo-600-hover:hover {
    --x-end-color: #4a4dc4;
  }
  .end-indigo-700,
  .end-indigo-700-hover:hover {
    --x-end-color: #373a93;
  }
  .end-indigo-800,
  .end-indigo-800-hover:hover {
    --x-end-color: #252662;
  }
  .end-indigo-900,
  .end-indigo-900-hover:hover {
    --x-end-color: #121331;
  }
  .end-purple-100,
  .end-purple-100-hover:hover {
    --x-end-color: #e7ddff;
  }
  .end-purple-200,
  .end-purple-200-hover:hover {
    --x-end-color: #d0bcff;
  }
  .end-purple-300,
  .end-purple-300-hover:hover {
    --x-end-color: #b89aff;
  }
  .end-purple-400,
  .end-purple-400-hover:hover {
    --x-end-color: #a179ff;
  }
  .end-purple-500,
  .end-purple-500-hover:hover {
    --x-end-color: #8957ff;
  }
  .end-purple-600,
  .end-purple-600-hover:hover {
    --x-end-color: #6e46cc;
  }
  .end-purple-700,
  .end-purple-700-hover:hover {
    --x-end-color: #523499;
  }
  .end-purple-800,
  .end-purple-800-hover:hover {
    --x-end-color: #372366;
  }
  .end-purple-900,
  .end-purple-900-hover:hover {
    --x-end-color: #1b1133;
  }
  .end-pink-100,
  .end-pink-100-hover:hover {
    --x-end-color: #ffddeb;
  }
  .end-pink-200,
  .end-pink-200-hover:hover {
    --x-end-color: #ffbcd7;
  }
  .end-pink-300,
  .end-pink-300-hover:hover {
    --x-end-color: #ff9ac2;
  }
  .end-pink-400,
  .end-pink-400-hover:hover {
    --x-end-color: #ff79ae;
  }
  .end-pink-500,
  .end-pink-500-hover:hover {
    --x-end-color: #ff579a;
  }
  .end-pink-600,
  .end-pink-600-hover:hover {
    --x-end-color: #cc467b;
  }
  .end-pink-700,
  .end-pink-700-hover:hover {
    --x-end-color: #99345c;
  }
  .end-pink-800,
  .end-pink-800-hover:hover {
    --x-end-color: #66233e;
  }
  .end-pink-900,
  .end-pink-900-hover:hover {
    --x-end-color: #33111f;
  }
  .end-magenta-100,
  .end-magenta-100-hover:hover {
    --x-end-color: #fcd6ff;
  }
  .end-magenta-200,
  .end-magenta-200-hover:hover {
    --x-end-color: #f8adff;
  }
  .end-magenta-300,
  .end-magenta-300-hover:hover {
    --x-end-color: #f585ff;
  }
  .end-magenta-400,
  .end-magenta-400-hover:hover {
    --x-end-color: #f15cff;
  }
  .end-magenta-500,
  .end-magenta-500-hover:hover {
    --x-end-color: #ee33ff;
  }
  .end-magenta-600,
  .end-magenta-600-hover:hover {
    --x-end-color: #be29cc;
  }
  .end-magenta-700,
  .end-magenta-700-hover:hover {
    --x-end-color: #8f1f99;
  }
  .end-magenta-800,
  .end-magenta-800-hover:hover {
    --x-end-color: #5f1466;
  }
  .end-magenta-900,
  .end-magenta-900-hover:hover {
    --x-end-color: #300a33;
  }
  .end-red-100,
  .end-red-100-hover:hover {
    --x-end-color: #ffd6e0;
  }
  .end-red-200,
  .end-red-200-hover:hover {
    --x-end-color: #ffadc2;
  }
  .end-red-300,
  .end-red-300-hover:hover {
    --x-end-color: #ff85a3;
  }
  .end-red-400,
  .end-red-400-hover:hover {
    --x-end-color: #ff5c85;
  }
  .end-red-500,
  .end-red-500-hover:hover {
    --x-end-color: #ff3366;
  }
  .end-red-600,
  .end-red-600-hover:hover {
    --x-end-color: #cc2952;
  }
  .end-red-700,
  .end-red-700-hover:hover {
    --x-end-color: #991f3d;
  }
  .end-red-800,
  .end-red-800-hover:hover {
    --x-end-color: #661429;
  }
  .end-red-900,
  .end-red-900-hover:hover {
    --x-end-color: #330a14;
  }
  .end-orange-100,
  .end-orange-100-hover:hover {
    --x-end-color: #ffe8cc;
  }
  .end-orange-200,
  .end-orange-200-hover:hover {
    --x-end-color: #ffd199;
  }
  .end-orange-300,
  .end-orange-300-hover:hover {
    --x-end-color: #ffba66;
  }
  .end-orange-400,
  .end-orange-400-hover:hover {
    --x-end-color: #ffa333;
  }
  .end-orange-500,
  .end-orange-500-hover:hover {
    --x-end-color: #ff8c00;
  }
  .end-orange-600,
  .end-orange-600-hover:hover {
    --x-end-color: #cc7000;
  }
  .end-orange-700,
  .end-orange-700-hover:hover {
    --x-end-color: #995400;
  }
  .end-orange-800,
  .end-orange-800-hover:hover {
    --x-end-color: #663800;
  }
  .end-orange-900,
  .end-orange-900-hover:hover {
    --x-end-color: #331c00;
  }
  .end-yellow-100,
  .end-yellow-100-hover:hover {
    --x-end-color: #fff1cc;
  }
  .end-yellow-200,
  .end-yellow-200-hover:hover {
    --x-end-color: #ffe499;
  }
  .end-yellow-300,
  .end-yellow-300-hover:hover {
    --x-end-color: #ffd666;
  }
  .end-yellow-400,
  .end-yellow-400-hover:hover {
    --x-end-color: #ffc933;
  }
  .end-yellow-500,
  .end-yellow-500-hover:hover {
    --x-end-color: #ffbb00;
  }
  .end-yellow-600,
  .end-yellow-600-hover:hover {
    --x-end-color: #cc9600;
  }
  .end-yellow-700,
  .end-yellow-700-hover:hover {
    --x-end-color: #997000;
  }
  .end-yellow-800,
  .end-yellow-800-hover:hover {
    --x-end-color: #664b00;
  }
  .end-yellow-900,
  .end-yellow-900-hover:hover {
    --x-end-color: #332500;
  }
  .end-green-100,
  .end-green-100-hover:hover {
    --x-end-color: #ccf5e7;
  }
  .end-green-200,
  .end-green-200-hover:hover {
    --x-end-color: #99ebcf;
  }
  .end-green-300,
  .end-green-300-hover:hover {
    --x-end-color: #66e0b8;
  }
  .end-green-400,
  .end-green-400-hover:hover {
    --x-end-color: #33d6a0;
  }
  .end-green-500,
  .end-green-500-hover:hover {
    --x-end-color: #00cc88;
  }
  .end-green-600,
  .end-green-600-hover:hover {
    --x-end-color: #00a36d;
  }
  .end-green-700,
  .end-green-700-hover:hover {
    --x-end-color: #007a52;
  }
  .end-green-800,
  .end-green-800-hover:hover {
    --x-end-color: #005236;
  }
  .end-green-900,
  .end-green-900-hover:hover {
    --x-end-color: #00291b;
  }
  .end-teal-100,
  .end-teal-100-hover:hover {
    --x-end-color: #d3f8f8;
  }
  .end-teal-200,
  .end-teal-200-hover:hover {
    --x-end-color: #a7f1f1;
  }
  .end-teal-300,
  .end-teal-300-hover:hover {
    --x-end-color: #7aebeb;
  }
  .end-teal-400,
  .end-teal-400-hover:hover {
    --x-end-color: #4ee4e4;
  }
  .end-teal-500,
  .end-teal-500-hover:hover {
    --x-end-color: #22dddd;
  }
  .end-teal-600,
  .end-teal-600-hover:hover {
    --x-end-color: #1bb1b1;
  }
  .end-teal-700,
  .end-teal-700-hover:hover {
    --x-end-color: #148585;
  }
  .end-teal-800,
  .end-teal-800-hover:hover {
    --x-end-color: #0e5858;
  }
  .end-teal-900,
  .end-teal-900-hover:hover {
    --x-end-color: #072c2c;
  }
  .end-cyan-100,
  .end-cyan-100-hover:hover {
    --x-end-color: #ccf6ff;
  }
  .end-cyan-200,
  .end-cyan-200-hover:hover {
    --x-end-color: #99eeff;
  }
  .end-cyan-300,
  .end-cyan-300-hover:hover {
    --x-end-color: #66e5ff;
  }
  .end-cyan-400,
  .end-cyan-400-hover:hover {
    --x-end-color: #33ddff;
  }
  .end-cyan-500,
  .end-cyan-500-hover:hover {
    --x-end-color: #00d4ff;
  }
  .end-cyan-600,
  .end-cyan-600-hover:hover {
    --x-end-color: #00aacc;
  }
  .end-cyan-700,
  .end-cyan-700-hover:hover {
    --x-end-color: #007f99;
  }
  .end-cyan-800,
  .end-cyan-800-hover:hover {
    --x-end-color: #005566;
  }
  .end-cyan-900,
  .end-cyan-900-hover:hover {
    --x-end-color: #002a33;
  }
  .text-tint-primary {
    color: #7478f7 !important;
  }
  .text-shade-primary {
    color: #4e52d0 !important;
  }
  .text-tint-secondary {
    color: #d6dce4 !important;
  }
  .text-shade-secondary {
    color: #b0b6be !important;
  }
  .text-tint-tertiary {
    color: #ff70a9 !important;
  }
  .text-shade-tertiary {
    color: #d94a83 !important;
  }
  .text-tint-success {
    color: #26d49a !important;
  }
  .text-shade-success {
    color: #00ad74 !important;
  }
  .text-tint-info {
    color: #26daff !important;
  }
  .text-shade-info {
    color: #00b4d9 !important;
  }
  .text-tint-warning {
    color: #ff9d26 !important;
  }
  .text-shade-warning {
    color: #d97700 !important;
  }
  .text-tint-danger {
    color: #ff527d !important;
  }
  .text-shade-danger {
    color: #d92b57 !important;
  }
  .text-tint-white {
    color: #fff !important;
  }
  .text-shade-white {
    color: #d9d9d9 !important;
  }
  .text-tint-light {
    color: #ebedf2 !important;
  }
  .text-shade-light {
    color: #c4c7cc !important;
  }
  .text-tint-dark {
    color: #393c4c !important;
  }
  .text-shade-dark {
    color: #131525 !important;
  }
  .h-calc {
    --x-h: 0px;
    height: calc(100vh - var(--x-h));
  }
  .w-calc {
    --x-w: 0px;
    width: calc(100vw - var(--x-w));
  }
  @media (min-width: 576px) {
    .h-sm-calc {
      --x-h-sm: 0px;
      height: calc(100vh - var(--x-h-sm));
    }
    .w-sm-calc {
      --x-w-sm: 0px;
      width: calc(100vw - var(--x-w-sm));
    }
  }
  @media (min-width: 768px) {
    .h-md-calc {
      --x-h-md: 0px;
      height: calc(100vh - var(--x-h-md));
    }
    .w-md-calc {
      --x-w-md: 0px;
      width: calc(100vw - var(--x-w-md));
    }
  }
  @media (min-width: 992px) {
    .h-lg-calc {
      --x-h-lg: 0px;
      height: calc(100vh - var(--x-h-lg));
    }
    .w-lg-calc {
      --x-w-lg: 0px;
      width: calc(100vw - var(--x-w-lg));
    }
  }
  @media (min-width: 1200px) {
    .h-xl-calc {
      --x-h-xl: 0px;
      height: calc(100vh - var(--x-h-xl));
    }
    .w-xl-calc {
      --x-w-xl: 0px;
      width: calc(100vw - var(--x-w-xl));
    }
  }
  @media (min-width: 1400px) {
    .h-xxl-calc {
      --x-h-xxl: 0px;
      height: calc(100vh - var(--x-h-xxl));
    }
    .w-xxl-calc {
      --x-w-xxl: 0px;
      width: calc(100vw - var(--x-w-xxl));
    }
  }
  .filter {
    --x-blur: 0;
    --x-contrast: 0;
    --x-grayscale: 0;
    --x-hue-rotate: 0;
    --x-drop-shadow: 0;
    filter: blur(var(--x-blur));
  }
  .fade {
    transition: opacity 0.2s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  .collapse:not(.show) {
    display: none;
  }
  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
      transition: none;
    }
  }
  @-webkit-keyframes ping {
    75%,
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  @keyframes ping {
    75%,
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  @-webkit-keyframes pulse {
    50% {
      opacity: 0.5;
    }
  }
  @keyframes pulse {
    50% {
      opacity: 0.5;
    }
  }
  @-webkit-keyframes bounce {
    0%,
    100% {
      transform: translateY(-25%);
      -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: none;
      -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-25%);
      -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: none;
      -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  @-webkit-keyframes slideLeft {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  @keyframes slideLeft {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  @-webkit-keyframes slideRight {
    0% {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes slideRight {
    0% {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  .transform {
    --x-translate-x: 0;
    --x-translate-y: 0;
    --x-translate-z: 0;
    --x-perspective: 0;
    --x-rotate: 0;
    --x-rotate-x: 0;
    --x-rotate-y: 0;
    --x-skew-x: 0;
    --x-skew-y: 0;
    --x-scale-x: 1;
    --x-scale-y: 1;
    transform: translateX(var(--x-translate-x)) translateY(var(--x-translate-y))
      perspective(var(--x-perspective)) rotate(var(--x-rotate))
      rotateX(var(--x-rotate-x)) rotateY(var(--x-rotate-y)) skewX(var(--x-skew-x))
      skewY(var(--x-skew-y)) scaleX(var(--x-scale-x)) scaleY(var(--x-scale-y));
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  .link-primary {
    color: #4066D5;
  }
  .link-primary:hover,
  .link-primary:focus {
    color: #4a4dc4;
  }
  .link-secondary {
    color: #cfd6df;
  }
  .link-secondary:hover,
  .link-secondary:focus {
    color: #d9dee5;
  }
  .link-tertiary {
    color: #ff579a;
  }
  .link-tertiary:hover,
  .link-tertiary:focus {
    color: #cc467b;
  }
  .link-success {
    color: #0c8;
  }
  .link-success:hover,
  .link-success:focus {
    color: #00a36d;
  }
  .link-info {
    color: #00d4ff;
  }
  .link-info:hover,
  .link-info:focus {
    color: #3df;
  }
  .link-warning {
    color: #ff8c00;
  }
  .link-warning:hover,
  .link-warning:focus {
    color: #cc7000;
  }
  .link-danger {
    color: #f36;
  }
  .link-danger:hover,
  .link-danger:focus {
    color: #cc2952;
  }
  .link-white {
    color: #fff;
  }
  .link-white:hover,
  .link-white:focus {
    color: #fff;
  }
  .link-light {
    color: #e7eaf0;
  }
  .link-light:hover,
  .link-light:focus {
    color: #eceef3;
  }
  .link-dark {
    color: #16192c;
  }
  .link-dark:hover,
  .link-dark:focus {
    color: #121423;
  }
  .ratio {
    position: relative;
    width: 100%;
  }
  .ratio::before {
    display: block;
    padding-top: var(--x-aspect-ratio);
    content: "";
  }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .ratio-1x1 {
    --x-aspect-ratio: 100%;
  }
  .ratio-4x3 {
    --x-aspect-ratio: 75%;
  }
  .ratio-16x9 {
    --x-aspect-ratio: 56.25%;
  }
  .ratio-21x9 {
    --x-aspect-ratio: 42.8571428571%;
  }
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  @media (min-width: 576px) {
    .sticky-sm-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 768px) {
    .sticky-md-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 992px) {
    .sticky-lg-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 1200px) {
    .sticky-xl-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 1400px) {
    .sticky-xxl-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  .hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
  }
  @media (width <= 991px) {
    .hstack {
      flex-direction: column !important;
  }
}
  .vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch;
  }
  .visually-hidden,
  .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
  }
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: 0.9;
  }
  .floatfix:before,
  .floatfix:after {
    content: "";
    display: table;
  }
  .floatfix:after {
    clear: both;
  }
  .scrollable-x {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .scrollable-x::-webkit-scrollbar {
    display: none;
  }
  .scrollable-y {
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .scrollable-y::-webkit-scrollbar {
    display: none;
  }
  [class*="faded"],
  [class*="blured"] {
    position: relative;
  }
  [class*="faded"]:after,
  [class*="blured"]:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 3.9375rem;
  }
  .faded-top:after {
    top: 0;
    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= "#00000000", endColorstr="#a6000000",GradientType=0 );
  }
  .faded-bottom:after {
    bottom: 0;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= "#00000000", endColorstr="#a6000000",GradientType=0 );
  }
  .blured-top:after {
    top: 0;
    background: rgba(255, 255, 255, 0.75);
    -webkit-backdrop-filter: blur(10px) saturate(200%);
    backdrop-filter: blur(10px) saturate(200%);
  }
  .blured-bottom:after {
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
    -webkit-backdrop-filter: blur(10px) saturate(200%);
    backdrop-filter: blur(10px) saturate(200%);
  }
  [class*="hover-"] {
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    [class*="hover-"] {
      transition: none;
    }
  }
  [class*="shadow"] {
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    [class*="shadow"] {
      transition: none;
    }
  }
  a.text-muted:hover,
  a.text-heading:hover,
  a.text-current:hover {
    color: #4e52d0 !important;
  }
  .text-limit {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .svg-fluid svg {
    max-width: 100%;
    height: 100%;
  }
  .svg-current svg [fill]:not([fill="none"]) {
    fill: currentColor !important;
  }
  .img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img-grayscale {
    filter: grayscale(100%);
  }
  .img-saturate {
    filter: saturate(150%);
  }
  .svg-icon {
    display: inline-flex;
    align-self: center;
  }
  .svg-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
  }
  .svg-icon[class*="text-"] > svg [fill]:not([fill="none"]) {
    fill: currentColor !important;
  }
  .svg-align-baseline svg {
    top: 0.125em;
  }
  .antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .subpixel-antialised {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .bg-fade-light {
    background-image: linear-gradient(to bottom, rgba(243, 248, 255, 0), #f3f8ff);
  }
  .bg-light-boxed-right {
    background: no-repeat left center -webkit-gradient(linear, left top, right top, from(#f3f8ff));
  }
  @media (min-width: 1200px) {
    .bg-light-boxed-right {
      background-size: calc(1140px + (100vw - 1140px) / 2) 100%;
    }
  }
  .bg-checkered {
    background-repeat: no-repeat, repeat, repeat;
    background-image: radial-gradient(transparent, transparent 50%, #fff),
      linear-gradient(
        to right,
        transparent,
        transparent 32px,
        #eaf2fe 32px,
        #eaf2fe
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent 32px,
        #eaf2fe 32px,
        #eaf2fe
      );
    background-size: 100% 100%, 33px 33px, 33px 33px;
  }
  .bg-hero-gradient:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(
      180deg,
      #fff,
      rgba(255, 255, 255, 0.966) 13.11%,
      rgba(255, 255, 255, 0.874) 24.72%,
      rgba(255, 255, 255, 0.741) 35.26%,
      rgba(255, 255, 255, 0.583) 45.16%,
      rgba(255, 255, 255, 0.417) 54.84%,
      rgba(255, 255, 255, 0.259) 64.74%,
      rgba(255, 255, 255, 0.126) 75.28%,
      rgba(255, 255, 255, 0.034) 86.89%,
      rgba(255, 255, 255, 0)
    );
  }
  .bg-translucent-white {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
  .bg-translucent-dark {
    background-color: rgba(22, 25, 44, 0.05) !important;
  }
  .bg-none {
    background-image: none !important;
  }
  .bg-between {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 90%;
  }
  @media (min-width: 576px) {
    .bg-sm-none {
      background-image: none !important;
    }
    .bg-sm-between {
      background-repeat: no-repeat;
      background-position: left center, right center;
      background-size: auto 90%;
    }
  }
  @media (min-width: 768px) {
    .bg-md-none {
      background-image: none !important;
    }
    .bg-md-between {
      background-repeat: no-repeat;
      background-position: left center, right center;
      background-size: auto 90%;
    }
  }
  @media (min-width: 992px) {
    .bg-lg-none {
      background-image: none !important;
    }
    .bg-lg-between {
      background-repeat: no-repeat;
      background-position: left center, right center;
      background-size: auto 90%;
    }
  }
  @media (min-width: 1200px) {
    .bg-xl-none {
      background-image: none !important;
    }
    .bg-xl-between {
      background-repeat: no-repeat;
      background-position: left center, right center;
      background-size: auto 90%;
    }
  }
  @media (min-width: 1400px) {
    .bg-xxl-none {
      background-image: none !important;
    }
    .bg-xxl-between {
      background-repeat: no-repeat;
      background-position: left center, right center;
      background-size: auto 90%;
    }
  }
  .align-baseline {
    vertical-align: baseline !important;
  }
  .align-top {
    vertical-align: top !important;
  }
  .align-middle {
    vertical-align: middle !important;
  }
  .align-bottom {
    vertical-align: bottom !important;
  }
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .align-text-top {
    vertical-align: text-top !important;
  }
  .float-start {
    float: left !important;
  }
  .float-end {
    float: right !important;
  }
  .float-none {
    float: none !important;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
  .opacity-0-hover:hover {
    opacity: 0 !important;
  }
  .opacity-10 {
    opacity: 0.1 !important;
  }
  .opacity-10-hover:hover {
    opacity: 0.1 !important;
  }
  .opacity-20 {
    opacity: 0.2 !important;
  }
  .opacity-20-hover:hover {
    opacity: 0.2 !important;
  }
  .opacity-30 {
    opacity: 0.3 !important;
  }
  .opacity-30-hover:hover {
    opacity: 0.3 !important;
  }
  .opacity-40 {
    opacity: 0.4 !important;
  }
  .opacity-40-hover:hover {
    opacity: 0.4 !important;
  }
  .opacity-50 {
    opacity: 0.5 !important;
  }
  .opacity-50-hover:hover {
    opacity: 0.5 !important;
  }
  .opacity-60 {
    opacity: 0.6 !important;
  }
  .opacity-60-hover:hover {
    opacity: 0.6 !important;
  }
  .opacity-70 {
    opacity: 0.7 !important;
  }
  .opacity-70-hover:hover {
    opacity: 0.7 !important;
  }
  .opacity-80 {
    opacity: 0.8 !important;
  }
  .opacity-80-hover:hover {
    opacity: 0.8 !important;
  }
  .opacity-90 {
    opacity: 0.9 !important;
  }
  .opacity-90-hover:hover {
    opacity: 0.9 !important;
  }
  .opacity-100 {
    opacity: 1 !important;
  }
  .opacity-100-hover:hover {
    opacity: 1 !important;
  }
  .overflow-auto {
    overflow: auto !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .overflow-visible {
    overflow: visible !important;
  }
  .overflow-scroll {
    overflow: scroll !important;
  }
  .d-inline {
    display: inline !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .d-block {
    display: block !important;
  }
  .d-grid {
    display: grid !important;
  }
  .d-table {
    display: table !important;
  }
  .d-table-row {
    display: table-row !important;
  }
  .d-table-cell {
    display: table-cell !important;
  }
  .d-flex {
    display: flex !important;
  }
  .d-inline-flex {
    display: inline-flex !important;
  }
  .d-none {
    display: none !important;
  }
  .shadow {
    box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-hover:hover {
    box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-1 {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-1-hover:hover {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-2 {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-2-hover:hover {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
      0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-inset {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-outline-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-none {
    box-shadow: none !important;
  }
  .shadow-none-hover:hover {
    box-shadow: none !important;
  }
  .position-static {
    position: static !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-absolute {
    position: absolute !important;
  }
  .position-fixed {
    position: fixed !important;
  }
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-0 {
    top: 0 !important;
  }
  .top-1 {
    top: 0.25rem !important;
  }
  .top-2 {
    top: 0.5rem !important;
  }
  .top-3 {
    top: 0.75rem !important;
  }
  .top-4 {
    top: 1rem !important;
  }
  .top-5 {
    top: 1.25rem !important;
  }
  .top-6 {
    top: 1.5rem !important;
  }
  .top-7 {
    top: 1.75rem !important;
  }
  .top-8 {
    top: 2rem !important;
  }
  .top-10 {
    top: 2.5rem !important;
  }
  .top-12 {
    top: 3rem !important;
  }
  .top-14 {
    top: 3.5rem !important;
  }
  .top-16 {
    top: 4rem !important;
  }
  .top-18 {
    top: 4.5rem !important;
  }
  .top-20 {
    top: 5rem !important;
  }
  .top-24 {
    top: 6rem !important;
  }
  .top-32 {
    top: 8rem !important;
  }
  .top-40 {
    top: 10rem !important;
  }
  .top-48 {
    top: 12rem !important;
  }
  .top-56 {
    top: 14rem !important;
  }
  .top-64 {
    top: 16rem !important;
  }
  .top-72 {
    top: 18rem !important;
  }
  .top-80 {
    top: 20rem !important;
  }
  .top-88 {
    top: 22rem !important;
  }
  .top-96 {
    top: 24rem !important;
  }
  .top-px {
    top: 1px !important;
  }
  .top-1\/2 {
    top: 50% !important;
  }
  .top-full {
    top: 100% !important;
  }
  .top-auto {
    top: auto !important;
  }
  .bottom-0 {
    bottom: 0 !important;
  }
  .bottom-1 {
    bottom: 0.25rem !important;
  }
  .bottom-2 {
    bottom: 0.5rem !important;
  }
  .bottom-3 {
    bottom: 0.75rem !important;
  }
  .bottom-4 {
    bottom: 1rem !important;
  }
  .bottom-5 {
    bottom: 1.25rem !important;
  }
  .bottom-6 {
    bottom: 1.5rem !important;
  }
  .bottom-7 {
    bottom: 1.75rem !important;
  }
  .bottom-8 {
    bottom: 2rem !important;
  }
  .bottom-10 {
    bottom: 2.5rem !important;
  }
  .bottom-12 {
    bottom: 3rem !important;
  }
  .bottom-14 {
    bottom: 3.5rem !important;
  }
  .bottom-16 {
    bottom: 4rem !important;
  }
  .bottom-18 {
    bottom: 4.5rem !important;
  }
  .bottom-20 {
    bottom: 5rem !important;
  }
  .bottom-24 {
    bottom: 6rem !important;
  }
  .bottom-32 {
    bottom: 8rem !important;
  }
  .bottom-40 {
    bottom: 10rem !important;
  }
  .bottom-48 {
    bottom: 12rem !important;
  }
  .bottom-56 {
    bottom: 14rem !important;
  }
  .bottom-64 {
    bottom: 16rem !important;
  }
  .bottom-72 {
    bottom: 18rem !important;
  }
  .bottom-80 {
    bottom: 20rem !important;
  }
  .bottom-88 {
    bottom: 22rem !important;
  }
  .bottom-96 {
    bottom: 24rem !important;
  }
  .bottom-px {
    bottom: 1px !important;
  }
  .bottom-1\/2 {
    bottom: 50% !important;
  }
  .bottom-full {
    bottom: 100% !important;
  }
  .bottom-auto {
    bottom: auto !important;
  }
  .start-0 {
    left: 0 !important;
  }
  .start-1 {
    left: 0.25rem !important;
  }
  .start-2 {
    left: 0.5rem !important;
  }
  .start-3 {
    left: 0.75rem !important;
  }
  .start-4 {
    left: 1rem !important;
  }
  .start-5 {
    left: 1.25rem !important;
  }
  .start-6 {
    left: 1.5rem !important;
  }
  .start-7 {
    left: 1.75rem !important;
  }
  .start-8 {
    left: 2rem !important;
  }
  .start-10 {
    left: 2.5rem !important;
  }
  .start-12 {
    left: 3rem !important;
  }
  .start-14 {
    left: 3.5rem !important;
  }
  .start-16 {
    left: 4rem !important;
  }
  .start-18 {
    left: 4.5rem !important;
  }
  .start-20 {
    left: 5rem !important;
  }
  .start-24 {
    left: 6rem !important;
  }
  .start-32 {
    left: 8rem !important;
  }
  .start-40 {
    left: 10rem !important;
  }
  .start-48 {
    left: 12rem !important;
  }
  .start-56 {
    left: 14rem !important;
  }
  .start-64 {
    left: 16rem !important;
  }
  .start-72 {
    left: 18rem !important;
  }
  .start-80 {
    left: 20rem !important;
  }
  .start-88 {
    left: 22rem !important;
  }
  .start-96 {
    left: 24rem !important;
  }
  .start-px {
    left: 1px !important;
  }
  .start-1\/2 {
    left: 50% !important;
  }
  .start-full {
    left: 100% !important;
  }
  .start-auto {
    left: auto !important;
  }
  .end-0 {
    right: 0 !important;
  }
  .end-1 {
    right: 0.25rem !important;
  }
  .end-2 {
    right: 0.5rem !important;
  }
  .end-3 {
    right: 0.75rem !important;
  }
  .end-4 {
    right: 1rem !important;
  }
  .end-5 {
    right: 1.25rem !important;
  }
  .end-6 {
    right: 1.5rem !important;
  }
  .end-7 {
    right: 1.75rem !important;
  }
  .end-8 {
    right: 2rem !important;
  }
  .end-10 {
    right: 2.5rem !important;
  }
  .end-12 {
    right: 3rem !important;
  }
  .end-14 {
    right: 3.5rem !important;
  }
  .end-16 {
    right: 4rem !important;
  }
  .end-18 {
    right: 4.5rem !important;
  }
  .end-20 {
    right: 5rem !important;
  }
  .end-24 {
    right: 6rem !important;
  }
  .end-32 {
    right: 8rem !important;
  }
  .end-40 {
    right: 10rem !important;
  }
  .end-48 {
    right: 12rem !important;
  }
  .end-56 {
    right: 14rem !important;
  }
  .end-64 {
    right: 16rem !important;
  }
  .end-72 {
    right: 18rem !important;
  }
  .end-80 {
    right: 20rem !important;
  }
  .end-88 {
    right: 22rem !important;
  }
  .end-96 {
    right: 24rem !important;
  }
  .end-px {
    right: 1px !important;
  }
  .end-1\/2 {
    right: 50% !important;
  }
  .end-full {
    right: 100% !important;
  }
  .end-auto {
    right: auto !important;
  }
  .translate-middle {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-top-0 {
    border-top-width: 0 !important;
  }
  .border-top-0-hover:hover {
    border-top-width: 0 !important;
  }
  .border-top-0-focus:focus {
    border-top-width: 0 !important;
  }
  .border-top {
    border-top-width: 1px !important;
  }
  .border-top-hover:hover {
    border-top-width: 1px !important;
  }
  .border-top-focus:focus {
    border-top-width: 1px !important;
  }
  .border-top-2 {
    border-top-width: 2px !important;
  }
  .border-top-2-hover:hover {
    border-top-width: 2px !important;
  }
  .border-top-2-focus:focus {
    border-top-width: 2px !important;
  }
  .border-top-3 {
    border-top-width: 3px !important;
  }
  .border-top-3-hover:hover {
    border-top-width: 3px !important;
  }
  .border-top-3-focus:focus {
    border-top-width: 3px !important;
  }
  .border-top-4 {
    border-top-width: 4px !important;
  }
  .border-top-4-hover:hover {
    border-top-width: 4px !important;
  }
  .border-top-4-focus:focus {
    border-top-width: 4px !important;
  }
  .border-top-5 {
    border-top-width: 5px !important;
  }
  .border-top-5-hover:hover {
    border-top-width: 5px !important;
  }
  .border-top-5-focus:focus {
    border-top-width: 5px !important;
  }
  .border-top-6 {
    border-top-width: 6px !important;
  }
  .border-top-6-hover:hover {
    border-top-width: 6px !important;
  }
  .border-top-6-focus:focus {
    border-top-width: 6px !important;
  }
  .border-top-7 {
    border-top-width: 7px !important;
  }
  .border-top-7-hover:hover {
    border-top-width: 7px !important;
  }
  .border-top-7-focus:focus {
    border-top-width: 7px !important;
  }
  .border-top-8 {
    border-top-width: 8px !important;
  }
  .border-top-8-hover:hover {
    border-top-width: 8px !important;
  }
  .border-top-8-focus:focus {
    border-top-width: 8px !important;
  }
  .border-end-0 {
    border-right-width: 0 !important;
  }
  .border-end-0-hover:hover {
    border-right-width: 0 !important;
  }
  .border-end-0-focus:focus {
    border-right-width: 0 !important;
  }
  .border-end {
    border-right-width: 1px !important;
  }
  .border-end-hover:hover {
    border-right-width: 1px !important;
  }
  .border-end-focus:focus {
    border-right-width: 1px !important;
  }
  .border-end-2 {
    border-right-width: 2px !important;
  }
  .border-end-2-hover:hover {
    border-right-width: 2px !important;
  }
  .border-end-2-focus:focus {
    border-right-width: 2px !important;
  }
  .border-end-3 {
    border-right-width: 3px !important;
  }
  .border-end-3-hover:hover {
    border-right-width: 3px !important;
  }
  .border-end-3-focus:focus {
    border-right-width: 3px !important;
  }
  .border-end-4 {
    border-right-width: 4px !important;
  }
  .border-end-4-hover:hover {
    border-right-width: 4px !important;
  }
  .border-end-4-focus:focus {
    border-right-width: 4px !important;
  }
  .border-end-5 {
    border-right-width: 5px !important;
  }
  .border-end-5-hover:hover {
    border-right-width: 5px !important;
  }
  .border-end-5-focus:focus {
    border-right-width: 5px !important;
  }
  .border-end-6 {
    border-right-width: 6px !important;
  }
  .border-end-6-hover:hover {
    border-right-width: 6px !important;
  }
  .border-end-6-focus:focus {
    border-right-width: 6px !important;
  }
  .border-end-7 {
    border-right-width: 7px !important;
  }
  .border-end-7-hover:hover {
    border-right-width: 7px !important;
  }
  .border-end-7-focus:focus {
    border-right-width: 7px !important;
  }
  .border-end-8 {
    border-right-width: 8px !important;
  }
  .border-end-8-hover:hover {
    border-right-width: 8px !important;
  }
  .border-end-8-focus:focus {
    border-right-width: 8px !important;
  }
  .border-bottom-0 {
    border-bottom-width: 0 !important;
  }
  .border-bottom-0-hover:hover {
    border-bottom-width: 0 !important;
  }
  .border-bottom-0-focus:focus {
    border-bottom-width: 0 !important;
  }
  .border-bottom {
    border-bottom-width: 1px !important;
  }
  .border-bottom-hover:hover {
    border-bottom-width: 1px !important;
  }
  .border-bottom-focus:focus {
    border-bottom-width: 1px !important;
  }
  .border-bottom-2 {
    border-bottom-width: 2px !important;
  }
  .border-bottom-2-hover:hover {
    border-bottom-width: 2px !important;
  }
  .border-bottom-2-focus:focus {
    border-bottom-width: 2px !important;
  }
  .border-bottom-3 {
    border-bottom-width: 3px !important;
  }
  .border-bottom-3-hover:hover {
    border-bottom-width: 3px !important;
  }
  .border-bottom-3-focus:focus {
    border-bottom-width: 3px !important;
  }
  .border-bottom-4 {
    border-bottom-width: 4px !important;
  }
  .border-bottom-4-hover:hover {
    border-bottom-width: 4px !important;
  }
  .border-bottom-4-focus:focus {
    border-bottom-width: 4px !important;
  }
  .border-bottom-5 {
    border-bottom-width: 5px !important;
  }
  .border-bottom-5-hover:hover {
    border-bottom-width: 5px !important;
  }
  .border-bottom-5-focus:focus {
    border-bottom-width: 5px !important;
  }
  .border-bottom-6 {
    border-bottom-width: 6px !important;
  }
  .border-bottom-6-hover:hover {
    border-bottom-width: 6px !important;
  }
  .border-bottom-6-focus:focus {
    border-bottom-width: 6px !important;
  }
  .border-bottom-7 {
    border-bottom-width: 7px !important;
  }
  .border-bottom-7-hover:hover {
    border-bottom-width: 7px !important;
  }
  .border-bottom-7-focus:focus {
    border-bottom-width: 7px !important;
  }
  .border-bottom-8 {
    border-bottom-width: 8px !important;
  }
  .border-bottom-8-hover:hover {
    border-bottom-width: 8px !important;
  }
  .border-bottom-8-focus:focus {
    border-bottom-width: 8px !important;
  }
  .border-start-0 {
    border-left-width: 0 !important;
  }
  .border-start-0-hover:hover {
    border-left-width: 0 !important;
  }
  .border-start-0-focus:focus {
    border-left-width: 0 !important;
  }
  .border-start {
    border-left-width: 1px !important;
  }
  .border-start-hover:hover {
    border-left-width: 1px !important;
  }
  .border-start-focus:focus {
    border-left-width: 1px !important;
  }
  .border-start-2 {
    border-left-width: 2px !important;
  }
  .border-start-2-hover:hover {
    border-left-width: 2px !important;
  }
  .border-start-2-focus:focus {
    border-left-width: 2px !important;
  }
  .border-start-3 {
    border-left-width: 3px !important;
  }
  .border-start-3-hover:hover {
    border-left-width: 3px !important;
  }
  .border-start-3-focus:focus {
    border-left-width: 3px !important;
  }
  .border-start-4 {
    border-left-width: 4px !important;
  }
  .border-start-4-hover:hover {
    border-left-width: 4px !important;
  }
  .border-start-4-focus:focus {
    border-left-width: 4px !important;
  }
  .border-start-5 {
    border-left-width: 5px !important;
  }
  .border-start-5-hover:hover {
    border-left-width: 5px !important;
  }
  .border-start-5-focus:focus {
    border-left-width: 5px !important;
  }
  .border-start-6 {
    border-left-width: 6px !important;
  }
  .border-start-6-hover:hover {
    border-left-width: 6px !important;
  }
  .border-start-6-focus:focus {
    border-left-width: 6px !important;
  }
  .border-start-7 {
    border-left-width: 7px !important;
  }
  .border-start-7-hover:hover {
    border-left-width: 7px !important;
  }
  .border-start-7-focus:focus {
    border-left-width: 7px !important;
  }
  .border-start-8 {
    border-left-width: 8px !important;
  }
  .border-start-8-hover:hover {
    border-left-width: 8px !important;
  }
  .border-start-8-focus:focus {
    border-left-width: 8px !important;
  }
  .border-primary {
    border-color: #4066D5 !important;
  }
  .border-primary-hover:hover {
    border-color: #4066D5 !important;
  }
  .border-primary-focus:focus {
    border-color: #4066D5 !important;
  }
  .border-primary-focus-within:focus-within {
    border-color: #4066D5 !important;
  }
  .border-secondary {
    border-color: #cfd6df !important;
  }
  .border-secondary-hover:hover {
    border-color: #cfd6df !important;
  }
  .border-secondary-focus:focus {
    border-color: #cfd6df !important;
  }
  .border-secondary-focus-within:focus-within {
    border-color: #cfd6df !important;
  }
  .border-tertiary {
    border-color: #ff579a !important;
  }
  .border-tertiary-hover:hover {
    border-color: #ff579a !important;
  }
  .border-tertiary-focus:focus {
    border-color: #ff579a !important;
  }
  .border-tertiary-focus-within:focus-within {
    border-color: #ff579a !important;
  }
  .border-success {
    border-color: #0c8 !important;
  }
  .border-success-hover:hover {
    border-color: #0c8 !important;
  }
  .border-success-focus:focus {
    border-color: #0c8 !important;
  }
  .border-success-focus-within:focus-within {
    border-color: #0c8 !important;
  }
  .border-info {
    border-color: #00d4ff !important;
  }
  .border-info-hover:hover {
    border-color: #00d4ff !important;
  }
  .border-info-focus:focus {
    border-color: #00d4ff !important;
  }
  .border-info-focus-within:focus-within {
    border-color: #00d4ff !important;
  }
  .border-warning {
    border-color: #ff8c00 !important;
  }
  .border-warning-hover:hover {
    border-color: #ff8c00 !important;
  }
  .border-warning-focus:focus {
    border-color: #ff8c00 !important;
  }
  .border-warning-focus-within:focus-within {
    border-color: #ff8c00 !important;
  }
  .border-danger {
    border-color: #f36 !important;
  }
  .border-danger-hover:hover {
    border-color: #f36 !important;
  }
  .border-danger-focus:focus {
    border-color: #f36 !important;
  }
  .border-danger-focus-within:focus-within {
    border-color: #f36 !important;
  }
  .border-white {
    border-color: #fff !important;
  }
  .border-white-hover:hover {
    border-color: #fff !important;
  }
  .border-white-focus:focus {
    border-color: #fff !important;
  }
  .border-white-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-light {
    border-color: #e7eaf0 !important;
  }
  .border-light-hover:hover {
    border-color: #e7eaf0 !important;
  }
  .border-light-focus:focus {
    border-color: #e7eaf0 !important;
  }
  .border-light-focus-within:focus-within {
    border-color: #e7eaf0 !important;
  }
  .border-dark {
    border-color: #16192c !important;
  }
  .border-dark-hover:hover {
    border-color: #16192c !important;
  }
  .border-dark-focus:focus {
    border-color: #16192c !important;
  }
  .border-dark-focus-within:focus-within {
    border-color: #16192c !important;
  }
  .border-transparent {
    border-color: transparent !important;
  }
  .border-transparent-hover:hover {
    border-color: transparent !important;
  }
  .border-transparent-focus:focus {
    border-color: transparent !important;
  }
  .border-transparent-focus-within:focus-within {
    border-color: transparent !important;
  }
  .border-current {
    border-color: currentColor !important;
  }
  .border-current-hover:hover {
    border-color: currentColor !important;
  }
  .border-current-focus:focus {
    border-color: currentColor !important;
  }
  .border-current-focus-within:focus-within {
    border-color: currentColor !important;
  }
  .border-base {
    border-color: #e7eaf0 !important;
  }
  .border-base-hover:hover {
    border-color: #e7eaf0 !important;
  }
  .border-base-focus:focus {
    border-color: #e7eaf0 !important;
  }
  .border-base-focus-within:focus-within {
    border-color: #e7eaf0 !important;
  }
  .border-body {
    border-color: #fff !important;
  }
  .border-body-hover:hover {
    border-color: #fff !important;
  }
  .border-body-focus:focus {
    border-color: #fff !important;
  }
  .border-body-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-card {
    border-color: #fff !important;
  }
  .border-card-hover:hover {
    border-color: #fff !important;
  }
  .border-card-focus:focus {
    border-color: #fff !important;
  }
  .border-card-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-black {
    border-color: #000 !important;
  }
  .border-black-hover:hover {
    border-color: #000 !important;
  }
  .border-black-focus:focus {
    border-color: #000 !important;
  }
  .border-black-focus-within:focus-within {
    border-color: #000 !important;
  }
  .border-0 {
    border-width: 0 !important;
  }
  .border-0-hover:hover {
    border-width: 0 !important;
  }
  .border-0-focus:focus {
    border-width: 0 !important;
  }
  .border {
    border-width: 1px !important;
  }
  .border-hover:hover {
    border-width: 1px !important;
  }
  .border-focus:focus {
    border-width: 1px !important;
  }
  .border-2 {
    border-width: 2px !important;
  }
  .border-2-hover:hover {
    border-width: 2px !important;
  }
  .border-2-focus:focus {
    border-width: 2px !important;
  }
  .border-3 {
    border-width: 3px !important;
  }
  .border-3-hover:hover {
    border-width: 3px !important;
  }
  .border-3-focus:focus {
    border-width: 3px !important;
  }
  .border-4 {
    border-width: 4px !important;
  }
  .border-4-hover:hover {
    border-width: 4px !important;
  }
  .border-4-focus:focus {
    border-width: 4px !important;
  }
  .border-5 {
    border-width: 5px !important;
  }
  .border-5-hover:hover {
    border-width: 5px !important;
  }
  .border-5-focus:focus {
    border-width: 5px !important;
  }
  .border-6 {
    border-width: 6px !important;
  }
  .border-6-hover:hover {
    border-width: 6px !important;
  }
  .border-6-focus:focus {
    border-width: 6px !important;
  }
  .border-7 {
    border-width: 7px !important;
  }
  .border-7-hover:hover {
    border-width: 7px !important;
  }
  .border-7-focus:focus {
    border-width: 7px !important;
  }
  .border-8 {
    border-width: 8px !important;
  }
  .border-8-hover:hover {
    border-width: 8px !important;
  }
  .border-8-focus:focus {
    border-width: 8px !important;
  }
  .w-0 {
    width: 0 !important;
  }
  .w-1 {
    width: 0.25rem !important;
  }
  .w-2 {
    width: 0.5rem !important;
  }
  .w-3 {
    width: 0.75rem !important;
  }
  .w-4 {
    width: 1rem !important;
  }
  .w-5 {
    width: 1.25rem !important;
  }
  .w-6 {
    width: 1.5rem !important;
  }
  .w-7 {
    width: 1.75rem !important;
  }
  .w-8 {
    width: 2rem !important;
  }
  .w-10 {
    width: 2.5rem !important;
  }
  .w-12 {
    width: 3rem !important;
  }
  .w-14 {
    width: 3.5rem !important;
  }
  .w-16 {
    width: 4rem !important;
  }
  .w-18 {
    width: 4.5rem !important;
  }
  .w-20 {
    width: 5rem !important;
  }
  .w-24 {
    width: 6rem !important;
  }
  .w-32 {
    width: 8rem !important;
  }
  .w-40 {
    width: 10rem !important;
  }
  .w-48 {
    width: 12rem !important;
  }
  .w-56 {
    width: 14rem !important;
  }
  .w-64 {
    width: 16rem !important;
  }
  .w-72 {
    width: 18rem !important;
  }
  .w-80 {
    width: 20rem !important;
  }
  .w-88 {
    width: 22rem !important;
  }
  .w-96 {
    width: 24rem !important;
  }
  .w-px {
    width: 1px !important;
  }
  .w-1\/2 {
    width: 50% !important;
  }
  .w-1\/3 {
    width: 33.3333333333% !important;
  }
  .w-2\/3 {
    width: 66.6666666667% !important;
  }
  .w-1\/4 {
    width: 25% !important;
  }
  .w-2\/4 {
    width: 50% !important;
  }
  .w-3\/4 {
    width: 75% !important;
  }
  .w-1\/5 {
    width: 20% !important;
  }
  .w-2\/5 {
    width: 40% !important;
  }
  .w-3\/5 {
    width: 60% !important;
  }
  .w-4\/5 {
    width: 80% !important;
  }
  .w-1\/6 {
    width: 16.6666666667% !important;
  }
  .w-2\/6 {
    width: 33.3333333333% !important;
  }
  .w-3\/6 {
    width: 50% !important;
  }
  .w-4\/6 {
    width: 66.6666666667% !important;
  }
  .w-5\/6 {
    width: 83.3333333333% !important;
  }
  .w-11\/10 {
    width: 110% !important;
  }
  .w-12\/10 {
    width: 120% !important;
  }
  .w-13\/10 {
    width: 130% !important;
  }
  .w-14\/10 {
    width: 140% !important;
  }
  .w-15\/10 {
    width: 150% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .w-full {
    width: 100% !important;
  }
  .w-screen {
    width: 100vw !important;
  }
  .w-min {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }
  .w-max {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }
  .max-w-screen-sm {
    max-width: 640px !important;
  }
  .max-w-screen-md {
    max-width: 768px !important;
  }
  .max-w-screen-lg {
    max-width: 1024px !important;
  }
  .max-w-screen-xl {
    max-width: 1280px !important;
  }
  .max-w-screen-xxl {
    max-width: 1536px !important;
  }
  .max-w-0 {
    max-width: 0 !important;
  }
  .max-w-full {
    max-width: 100% !important;
  }
  .max-w-read {
    max-width: 65ch !important;
  }
  .max-w-min {
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }
  .max-w-max {
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }
  .h-0 {
    height: 0 !important;
  }
  .h-1 {
    height: 0.25rem !important;
  }
  .h-2 {
    height: 0.5rem !important;
  }
  .h-3 {
    height: 0.75rem !important;
  }
  .h-4 {
    height: 1rem !important;
  }
  .h-5 {
    height: 1.25rem !important;
  }
  .h-6 {
    height: 1.5rem !important;
  }
  .h-7 {
    height: 1.75rem !important;
  }
  .h-8 {
    height: 2rem !important;
  }
  .h-10 {
    height: 2.5rem !important;
  }
  .h-12 {
    height: 3rem !important;
  }
  .h-14 {
    height: 3.5rem !important;
  }
  .h-16 {
    height: 4rem !important;
  }
  .h-18 {
    height: 4.5rem !important;
  }
  .h-20 {
    height: 5rem !important;
  }
  .h-24 {
    height: 6rem !important;
  }
  .h-32 {
    height: 8rem !important;
  }
  .h-40 {
    height: 10rem !important;
  }
  .h-48 {
    height: 12rem !important;
  }
  .h-56 {
    height: 14rem !important;
  }
  .h-64 {
    height: 16rem !important;
  }
  .h-72 {
    height: 18rem !important;
  }
  .h-80 {
    height: 20rem !important;
  }
  .h-88 {
    height: 22rem !important;
  }
  .h-96 {
    height: 24rem !important;
  }
  .h-px {
    height: 1px !important;
  }
  .h-1\/2 {
    height: 50% !important;
  }
  .h-1\/3 {
    height: 33.3333333333% !important;
  }
  .h-2\/3 {
    height: 66.6666666667% !important;
  }
  .h-1\/4 {
    height: 25% !important;
  }
  .h-2\/4 {
    height: 50% !important;
  }
  .h-3\/4 {
    height: 75% !important;
  }
  .h-1\/5 {
    height: 20% !important;
  }
  .h-2\/5 {
    height: 40% !important;
  }
  .h-3\/5 {
    height: 60% !important;
  }
  .h-4\/5 {
    height: 80% !important;
  }
  .h-1\/6 {
    height: 16.6666666667% !important;
  }
  .h-2\/6 {
    height: 33.3333333333% !important;
  }
  .h-3\/6 {
    height: 50% !important;
  }
  .h-4\/6 {
    height: 66.6666666667% !important;
  }
  .h-5\/6 {
    height: 83.3333333333% !important;
  }
  .h-11\/10 {
    height: 110% !important;
  }
  .h-12\/10 {
    height: 120% !important;
  }
  .h-13\/10 {
    height: 130% !important;
  }
  .h-14\/10 {
    height: 140% !important;
  }
  .h-15\/10 {
    height: 150% !important;
  }
  .h-auto {
    height: auto !important;
  }
  .h-full {
    height: 100% !important;
  }
  .h-screen {
    height: 100vh !important;
  }
  .max-h-0 {
    max-height: 0 !important;
  }
  .max-h-1 {
    max-height: 0.25rem !important;
  }
  .max-h-2 {
    max-height: 0.5rem !important;
  }
  .max-h-3 {
    max-height: 0.75rem !important;
  }
  .max-h-4 {
    max-height: 1rem !important;
  }
  .max-h-5 {
    max-height: 1.25rem !important;
  }
  .max-h-6 {
    max-height: 1.5rem !important;
  }
  .max-h-7 {
    max-height: 1.75rem !important;
  }
  .max-h-8 {
    max-height: 2rem !important;
  }
  .max-h-10 {
    max-height: 2.5rem !important;
  }
  .max-h-12 {
    max-height: 3rem !important;
  }
  .max-h-14 {
    max-height: 3.5rem !important;
  }
  .max-h-16 {
    max-height: 4rem !important;
  }
  .max-h-18 {
    max-height: 4.5rem !important;
  }
  .max-h-20 {
    max-height: 5rem !important;
  }
  .max-h-24 {
    max-height: 6rem !important;
  }
  .max-h-32 {
    max-height: 8rem !important;
  }
  .max-h-40 {
    max-height: 10rem !important;
  }
  .max-h-48 {
    max-height: 12rem !important;
  }
  .max-h-56 {
    max-height: 14rem !important;
  }
  .max-h-64 {
    max-height: 16rem !important;
  }
  .max-h-72 {
    max-height: 18rem !important;
  }
  .max-h-80 {
    max-height: 20rem !important;
  }
  .max-h-88 {
    max-height: 22rem !important;
  }
  .max-h-96 {
    max-height: 24rem !important;
  }
  .max-h-px {
    max-height: 1px !important;
  }
  .max-h-full {
    max-height: 100% !important;
  }
  .max-h-screen {
    max-height: 100vh !important;
  }
  .flex-1 {
    flex: 1 1 0% !important;
  }
  .flex-fill {
    flex: 1 1 auto !important;
  }
  .flex-initial {
    flex: 0 1 auto !important;
  }
  .flex-none {
    flex: none !important;
  }
  .flex-row {
    flex-direction: row !important;
  }
  .flex-column {
    flex-direction: column !important;
  }
  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-0 {
    gap: 0 !important;
  }
  .gap-1 {
    gap: 0.25rem !important;
  }
  .gap-2 {
    gap: 0.5rem !important;
  }
  .gap-3 {
    gap: 0.75rem !important;
  }
  .gap-4 {
    gap: 1rem !important;
  }
  .gap-5 {
    gap: 1.25rem !important;
  }
  .gap-6 {
    gap: 1.5rem !important;
  }
  .gap-7 {
    gap: 1.75rem !important;
  }
  .gap-8 {
    gap: 2rem !important;
  }
  .gap-10 {
    gap: 2.5rem !important;
  }
  .gap-12 {
    gap: 3rem !important;
  }
  .gap-14 {
    gap: 3.5rem !important;
  }
  .gap-16 {
    gap: 4rem !important;
  }
  .gap-18 {
    gap: 4.5rem !important;
  }
  .gap-20 {
    gap: 5rem !important;
  }
  .gap-24 {
    gap: 6rem !important;
  }
  .gap-32 {
    gap: 8rem !important;
  }
  .gap-40 {
    gap: 10rem !important;
  }
  .gap-48 {
    gap: 12rem !important;
  }
  .gap-56 {
    gap: 14rem !important;
  }
  .gap-64 {
    gap: 16rem !important;
  }
  .gap-72 {
    gap: 18rem !important;
  }
  .gap-80 {
    gap: 20rem !important;
  }
  .gap-88 {
    gap: 22rem !important;
  }
  .gap-96 {
    gap: 24rem !important;
  }
  .gap-px {
    gap: 1px !important;
  }
  .justify-content-start {
    justify-content: flex-start !important;
  }
  .justify-content-end {
    justify-content: flex-end !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .justify-content-around {
    justify-content: space-around !important;
  }
  .justify-content-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-start {
    align-items: flex-start !important;
  }
  .align-items-end {
    align-items: flex-end !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .align-items-baseline {
    align-items: baseline !important;
  }
  .align-items-stretch {
    align-items: stretch !important;
  }
  .align-content-start {
    align-content: flex-start !important;
  }
  .align-content-end {
    align-content: flex-end !important;
  }
  .align-content-center {
    align-content: center !important;
  }
  .align-content-between {
    align-content: space-between !important;
  }
  .align-content-around {
    align-content: space-around !important;
  }
  .align-content-evenly {
    align-content: space-evenly !important;
  }
  .align-content-stretch {
    align-content: stretch !important;
  }
  .align-self-auto {
    align-self: auto !important;
  }
  .align-self-start {
    align-self: flex-start !important;
  }
  .align-self-end {
    align-self: flex-end !important;
  }
  .align-self-center {
    align-self: center !important;
  }
  .align-self-baseline {
    align-self: baseline !important;
  }
  .align-self-stretch {
    align-self: stretch !important;
  }
  .order-first {
    order: -1 !important;
  }
  .order-0 {
    order: 0 !important;
  }
  .order-1 {
    order: 1 !important;
  }
  .order-2 {
    order: 2 !important;
  }
  .order-3 {
    order: 3 !important;
  }
  .order-4 {
    order: 4 !important;
  }
  .order-5 {
    order: 5 !important;
  }
  .order-last {
    order: 6 !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .m-1 {
    margin: 0.25rem !important;
  }
  .m-2 {
    margin: 0.5rem !important;
  }
  .m-3 {
    margin: 0.75rem !important;
  }
  .m-4 {
    margin: 1rem !important;
  }
  .m-5 {
    margin: 1.25rem !important;
  }
  .m-6 {
    margin: 1.5rem !important;
  }
  .m-7 {
    margin: 1.75rem !important;
  }
  .m-8 {
    margin: 2rem !important;
  }
  .m-10 {
    margin: 2.5rem !important;
  }
  .m-12 {
    margin: 3rem !important;
  }
  .m-14 {
    margin: 3.5rem !important;
  }
  .m-16 {
    margin: 4rem !important;
  }
  .m-18 {
    margin: 4.5rem !important;
  }
  .m-20 {
    margin: 5rem !important;
  }
  .m-24 {
    margin: 6rem !important;
  }
  .m-32 {
    margin: 8rem !important;
  }
  .m-40 {
    margin: 10rem !important;
  }
  .m-48 {
    margin: 12rem !important;
  }
  .m-56 {
    margin: 14rem !important;
  }
  .m-64 {
    margin: 16rem !important;
  }
  .m-72 {
    margin: 18rem !important;
  }
  .m-80 {
    margin: 20rem !important;
  }
  .m-88 {
    margin: 22rem !important;
  }
  .m-96 {
    margin: 24rem !important;
  }
  .m-px {
    margin: 1px !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-32 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-40 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-48 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-56 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-64 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-72 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-80 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-88 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-96 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-px {
    margin-right: 1px !important;
    margin-left: 1px !important;
  }
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-88 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-96 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .mt-1 {
    margin-top: 0.25rem !important;
  }
  .mt-2 {
    margin-top: 0.5rem !important;
  }
  .mt-3 {
    margin-top: 0.75rem !important;
  }
  .mt-4 {
    margin-top: 1rem !important;
  }
  .mt-5 {
    margin-top: 1.25rem !important;
  }
  .mt-6 {
    margin-top: 1.5rem !important;
  }
  .mt-7 {
    margin-top: 1.75rem !important;
  }
  .mt-8 {
    margin-top: 2rem !important;
  }
  .mt-10 {
    margin-top: 2.5rem !important;
  }
  .mt-12 {
    margin-top: 3rem !important;
  }
  .mt-14 {
    margin-top: 3.5rem !important;
  }
  .mt-16 {
    margin-top: 4rem !important;
  }
  .mt-18 {
    margin-top: 4.5rem !important;
  }
  .mt-20 {
    margin-top: 5rem !important;
  }
  .mt-24 {
    margin-top: 6rem !important;
  }
  .mt-32 {
    margin-top: 8rem !important;
  }
  .mt-40 {
    margin-top: 10rem !important;
  }
  .mt-48 {
    margin-top: 12rem !important;
  }
  .mt-56 {
    margin-top: 14rem !important;
  }
  .mt-64 {
    margin-top: 16rem !important;
  }
  .mt-72 {
    margin-top: 18rem !important;
  }
  .mt-80 {
    margin-top: 20rem !important;
  }
  .mt-88 {
    margin-top: 22rem !important;
  }
  .mt-96 {
    margin-top: 24rem !important;
  }
  .mt-px {
    margin-top: 1px !important;
  }
  .mt-auto {
    margin-top: auto !important;
  }
  .me-0 {
    margin-right: 0 !important;
  }
  .me-1 {
    margin-right: 0.25rem !important;
  }
  .me-2 {
    margin-right: 0.5rem !important;
  }
  .me-3 {
    margin-right: 0.75rem !important;
  }
  .me-4 {
    margin-right: 1rem !important;
  }
  .me-5 {
    margin-right: 1.25rem !important;
  }
  .me-6 {
    margin-right: 1.5rem !important;
  }
  .me-7 {
    margin-right: 1.75rem !important;
  }
  .me-8 {
    margin-right: 2rem !important;
  }
  .me-10 {
    margin-right: 2.5rem !important;
  }
  .me-12 {
    margin-right: 3rem !important;
  }
  .me-14 {
    margin-right: 3.5rem !important;
  }
  .me-16 {
    margin-right: 4rem !important;
  }
  .me-18 {
    margin-right: 4.5rem !important;
  }
  .me-20 {
    margin-right: 5rem !important;
  }
  .me-24 {
    margin-right: 6rem !important;
  }
  .me-32 {
    margin-right: 8rem !important;
  }
  .me-40 {
    margin-right: 10rem !important;
  }
  .me-48 {
    margin-right: 12rem !important;
  }
  .me-56 {
    margin-right: 14rem !important;
  }
  .me-64 {
    margin-right: 16rem !important;
  }
  .me-72 {
    margin-right: 18rem !important;
  }
  .me-80 {
    margin-right: 20rem !important;
  }
  .me-88 {
    margin-right: 22rem !important;
  }
  .me-96 {
    margin-right: 24rem !important;
  }
  .me-px {
    margin-right: 1px !important;
  }
  .me-auto {
    margin-right: auto !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-4 {
    margin-bottom: 1rem !important;
  }
  .mb-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-8 {
    margin-bottom: 2rem !important;
  }
  .mb-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-12 {
    margin-bottom: 3rem !important;
  }
  .mb-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-16 {
    margin-bottom: 4rem !important;
  }
  .mb-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-20 {
    margin-bottom: 5rem !important;
  }
  .mb-24 {
    margin-bottom: 6rem !important;
  }
  .mb-32 {
    margin-bottom: 8rem !important;
  }
  .mb-40 {
    margin-bottom: 10rem !important;
  }
  .mb-48 {
    margin-bottom: 12rem !important;
  }
  .mb-56 {
    margin-bottom: 14rem !important;
  }
  .mb-64 {
    margin-bottom: 16rem !important;
  }
  .mb-72 {
    margin-bottom: 18rem !important;
  }
  .mb-80 {
    margin-bottom: 20rem !important;
  }
  .mb-88 {
    margin-bottom: 22rem !important;
  }
  .mb-96 {
    margin-bottom: 24rem !important;
  }
  .mb-px {
    margin-bottom: 1px !important;
  }
  .mb-auto {
    margin-bottom: auto !important;
  }
  .ms-0 {
    margin-left: 0 !important;
  }
  .ms-1 {
    margin-left: 0.25rem !important;
  }
  .ms-2 {
    margin-left: 0.5rem !important;
  }
  .ms-3 {
    margin-left: 0.75rem !important;
  }
  .ms-4 {
    margin-left: 1rem !important;
  }
  .ms-5 {
    margin-left: 1.25rem !important;
  }
  .ms-6 {
    margin-left: 1.5rem !important;
  }
  .ms-7 {
    margin-left: 1.75rem !important;
  }
  .ms-8 {
    margin-left: 2rem !important;
  }
  .ms-10 {
    margin-left: 2.5rem !important;
  }
  .ms-12 {
    margin-left: 3rem !important;
  }
  .ms-14 {
    margin-left: 3.5rem !important;
  }
  .ms-16 {
    margin-left: 4rem !important;
  }
  .ms-18 {
    margin-left: 4.5rem !important;
  }
  .ms-20 {
    margin-left: 5rem !important;
  }
  .ms-24 {
    margin-left: 6rem !important;
  }
  .ms-32 {
    margin-left: 8rem !important;
  }
  .ms-40 {
    margin-left: 10rem !important;
  }
  .ms-48 {
    margin-left: 12rem !important;
  }
  .ms-56 {
    margin-left: 14rem !important;
  }
  .ms-64 {
    margin-left: 16rem !important;
  }
  .ms-72 {
    margin-left: 18rem !important;
  }
  .ms-80 {
    margin-left: 20rem !important;
  }
  .ms-88 {
    margin-left: 22rem !important;
  }
  .ms-96 {
    margin-left: 24rem !important;
  }
  .ms-px {
    margin-left: 1px !important;
  }
  .ms-auto {
    margin-left: auto !important;
  }
  .m-n1 {
    margin: -0.25rem !important;
  }
  .m-n2 {
    margin: -0.5rem !important;
  }
  .m-n3 {
    margin: -0.75rem !important;
  }
  .m-n4 {
    margin: -1rem !important;
  }
  .m-n5 {
    margin: -1.25rem !important;
  }
  .m-n6 {
    margin: -1.5rem !important;
  }
  .m-n7 {
    margin: -1.75rem !important;
  }
  .m-n8 {
    margin: -2rem !important;
  }
  .m-n10 {
    margin: -2.5rem !important;
  }
  .m-n12 {
    margin: -3rem !important;
  }
  .m-n14 {
    margin: -3.5rem !important;
  }
  .m-n16 {
    margin: -4rem !important;
  }
  .m-n18 {
    margin: -4.5rem !important;
  }
  .m-n20 {
    margin: -5rem !important;
  }
  .m-n24 {
    margin: -6rem !important;
  }
  .m-n32 {
    margin: -8rem !important;
  }
  .m-n40 {
    margin: -10rem !important;
  }
  .m-n48 {
    margin: -12rem !important;
  }
  .m-n56 {
    margin: -14rem !important;
  }
  .m-n64 {
    margin: -16rem !important;
  }
  .m-n72 {
    margin: -18rem !important;
  }
  .m-n80 {
    margin: -20rem !important;
  }
  .m-n88 {
    margin: -22rem !important;
  }
  .m-n96 {
    margin: -24rem !important;
  }
  .m-npx {
    margin: -1px !important;
  }
  .mx-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .mx-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-n24 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-n32 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-n40 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-n48 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-n56 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .mx-n64 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-n72 {
    margin-right: -18rem !important;
    margin-left: -18rem !important;
  }
  .mx-n80 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-n88 {
    margin-right: -22rem !important;
    margin-left: -22rem !important;
  }
  .mx-n96 {
    margin-right: -24rem !important;
    margin-left: -24rem !important;
  }
  .mx-npx {
    margin-right: -1px !important;
    margin-left: -1px !important;
  }
  .my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .my-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .my-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }
  .my-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-n88 {
    margin-top: -22rem !important;
    margin-bottom: -22rem !important;
  }
  .my-n96 {
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }
  .my-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .mt-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-n4 {
    margin-top: -1rem !important;
  }
  .mt-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-n8 {
    margin-top: -2rem !important;
  }
  .mt-n10 {
    margin-top: -2.5rem !important;
  }
  .mt-n12 {
    margin-top: -3rem !important;
  }
  .mt-n14 {
    margin-top: -3.5rem !important;
  }
  .mt-n16 {
    margin-top: -4rem !important;
  }
  .mt-n18 {
    margin-top: -4.5rem !important;
  }
  .mt-n20 {
    margin-top: -5rem !important;
  }
  .mt-n24 {
    margin-top: -6rem !important;
  }
  .mt-n32 {
    margin-top: -8rem !important;
  }
  .mt-n40 {
    margin-top: -10rem !important;
  }
  .mt-n48 {
    margin-top: -12rem !important;
  }
  .mt-n56 {
    margin-top: -14rem !important;
  }
  .mt-n64 {
    margin-top: -16rem !important;
  }
  .mt-n72 {
    margin-top: -18rem !important;
  }
  .mt-n80 {
    margin-top: -20rem !important;
  }
  .mt-n88 {
    margin-top: -22rem !important;
  }
  .mt-n96 {
    margin-top: -24rem !important;
  }
  .mt-npx {
    margin-top: -1px !important;
  }
  .me-n1 {
    margin-right: -0.25rem !important;
  }
  .me-n2 {
    margin-right: -0.5rem !important;
  }
  .me-n3 {
    margin-right: -0.75rem !important;
  }
  .me-n4 {
    margin-right: -1rem !important;
  }
  .me-n5 {
    margin-right: -1.25rem !important;
  }
  .me-n6 {
    margin-right: -1.5rem !important;
  }
  .me-n7 {
    margin-right: -1.75rem !important;
  }
  .me-n8 {
    margin-right: -2rem !important;
  }
  .me-n10 {
    margin-right: -2.5rem !important;
  }
  .me-n12 {
    margin-right: -3rem !important;
  }
  .me-n14 {
    margin-right: -3.5rem !important;
  }
  .me-n16 {
    margin-right: -4rem !important;
  }
  .me-n18 {
    margin-right: -4.5rem !important;
  }
  .me-n20 {
    margin-right: -5rem !important;
  }
  .me-n24 {
    margin-right: -6rem !important;
  }
  .me-n32 {
    margin-right: -8rem !important;
  }
  .me-n40 {
    margin-right: -10rem !important;
  }
  .me-n48 {
    margin-right: -12rem !important;
  }
  .me-n56 {
    margin-right: -14rem !important;
  }
  .me-n64 {
    margin-right: -16rem !important;
  }
  .me-n72 {
    margin-right: -18rem !important;
  }
  .me-n80 {
    margin-right: -20rem !important;
  }
  .me-n88 {
    margin-right: -22rem !important;
  }
  .me-n96 {
    margin-right: -24rem !important;
  }
  .me-npx {
    margin-right: -1px !important;
  }
  .mb-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-n10 {
    margin-bottom: -2.5rem !important;
  }
  .mb-n12 {
    margin-bottom: -3rem !important;
  }
  .mb-n14 {
    margin-bottom: -3.5rem !important;
  }
  .mb-n16 {
    margin-bottom: -4rem !important;
  }
  .mb-n18 {
    margin-bottom: -4.5rem !important;
  }
  .mb-n20 {
    margin-bottom: -5rem !important;
  }
  .mb-n24 {
    margin-bottom: -6rem !important;
  }
  .mb-n32 {
    margin-bottom: -8rem !important;
  }
  .mb-n40 {
    margin-bottom: -10rem !important;
  }
  .mb-n48 {
    margin-bottom: -12rem !important;
  }
  .mb-n56 {
    margin-bottom: -14rem !important;
  }
  .mb-n64 {
    margin-bottom: -16rem !important;
  }
  .mb-n72 {
    margin-bottom: -18rem !important;
  }
  .mb-n80 {
    margin-bottom: -20rem !important;
  }
  .mb-n88 {
    margin-bottom: -22rem !important;
  }
  .mb-n96 {
    margin-bottom: -24rem !important;
  }
  .mb-npx {
    margin-bottom: -1px !important;
  }
  .ms-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-n4 {
    margin-left: -1rem !important;
  }
  .ms-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-n8 {
    margin-left: -2rem !important;
  }
  .ms-n10 {
    margin-left: -2.5rem !important;
  }
  .ms-n12 {
    margin-left: -3rem !important;
  }
  .ms-n14 {
    margin-left: -3.5rem !important;
  }
  .ms-n16 {
    margin-left: -4rem !important;
  }
  .ms-n18 {
    margin-left: -4.5rem !important;
  }
  .ms-n20 {
    margin-left: -5rem !important;
  }
  .ms-n24 {
    margin-left: -6rem !important;
  }
  .ms-n32 {
    margin-left: -8rem !important;
  }
  .ms-n40 {
    margin-left: -10rem !important;
  }
  .ms-n48 {
    margin-left: -12rem !important;
  }
  .ms-n56 {
    margin-left: -14rem !important;
  }
  .ms-n64 {
    margin-left: -16rem !important;
  }
  .ms-n72 {
    margin-left: -18rem !important;
  }
  .ms-n80 {
    margin-left: -20rem !important;
  }
  .ms-n88 {
    margin-left: -22rem !important;
  }
  .ms-n96 {
    margin-left: -24rem !important;
  }
  .ms-npx {
    margin-left: -1px !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .p-3 {
    padding: 0.75rem !important;
  }
  .p-4 {
    padding: 1rem !important;
  }
  .p-5 {
    padding: 1.25rem !important;
  }
  .p-6 {
    padding: 1.5rem !important;
  }
  .p-7 {
    padding: 1.75rem !important;
  }
  .p-8 {
    padding: 2rem !important;
  }
  .p-10 {
    padding: 2.5rem !important;
  }
  .p-12 {
    padding: 3rem !important;
  }
  .p-14 {
    padding: 3.5rem !important;
  }
  .p-16 {
    padding: 4rem !important;
  }
  .p-18 {
    padding: 4.5rem !important;
  }
  .p-20 {
    padding: 5rem !important;
  }
  .p-24 {
    padding: 6rem !important;
  }
  .p-32 {
    padding: 8rem !important;
  }
  .p-40 {
    padding: 10rem !important;
  }
  .p-48 {
    padding: 12rem !important;
  }
  .p-56 {
    padding: 14rem !important;
  }
  .p-64 {
    padding: 16rem !important;
  }
  .p-72 {
    padding: 18rem !important;
  }
  .p-80 {
    padding: 20rem !important;
  }
  .p-88 {
    padding: 22rem !important;
  }
  .p-96 {
    padding: 24rem !important;
  }
  .p-px {
    padding: 1px !important;
  }
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-32 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-40 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-48 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-56 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-64 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-72 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-80 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-88 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-96 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-px {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-88 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-96 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pt-0 {
    padding-top: 0 !important;
  }
  .pt-1 {
    padding-top: 0.25rem !important;
  }
  .pt-2 {
    padding-top: 0.5rem !important;
  }
  .pt-3 {
    padding-top: 0.75rem !important;
  }
  .pt-4 {
    padding-top: 1rem !important;
  }
  .pt-5 {
    padding-top: 1.25rem !important;
  }
  .pt-6 {
    padding-top: 1.5rem !important;
  }
  .pt-7 {
    padding-top: 1.75rem !important;
  }
  .pt-8 {
    padding-top: 2rem !important;
  }
  .pt-10 {
    padding-top: 2.5rem !important;
  }
  .pt-12 {
    padding-top: 3rem !important;
  }
  .pt-14 {
    padding-top: 3.5rem !important;
  }
  .pt-16 {
    padding-top: 4rem !important;
  }
  .pt-18 {
    padding-top: 4.5rem !important;
  }
  .pt-20 {
    padding-top: 5rem !important;
  }
  .pt-24 {
    padding-top: 6rem !important;
  }
  .pt-32 {
    padding-top: 8rem !important;
  }
  .pt-40 {
    padding-top: 10rem !important;
  }
  .pt-48 {
    padding-top: 12rem !important;
  }
  .pt-56 {
    padding-top: 14rem !important;
  }
  .pt-64 {
    padding-top: 16rem !important;
  }
  .pt-72 {
    padding-top: 18rem !important;
  }
  .pt-80 {
    padding-top: 20rem !important;
  }
  .pt-88 {
    padding-top: 22rem !important;
  }
  .pt-96 {
    padding-top: 24rem !important;
  }
  .pt-px {
    padding-top: 1px !important;
  }
  .pe-0 {
    padding-right: 0 !important;
  }
  .pe-1 {
    padding-right: 0.25rem !important;
  }
  .pe-2 {
    padding-right: 0.5rem !important;
  }
  .pe-3 {
    padding-right: 0.75rem !important;
  }
  .pe-4 {
    padding-right: 1rem !important;
  }
  .pe-5 {
    padding-right: 1.25rem !important;
  }
  .pe-6 {
    padding-right: 1.5rem !important;
  }
  .pe-7 {
    padding-right: 1.75rem !important;
  }
  .pe-8 {
    padding-right: 2rem !important;
  }
  .pe-10 {
    padding-right: 2.5rem !important;
  }
  .pe-12 {
    padding-right: 3rem !important;
  }
  .pe-14 {
    padding-right: 3.5rem !important;
  }
  .pe-16 {
    padding-right: 4rem !important;
  }
  .pe-18 {
    padding-right: 4.5rem !important;
  }
  .pe-20 {
    padding-right: 5rem !important;
  }
  .pe-24 {
    padding-right: 6rem !important;
  }
  .pe-32 {
    padding-right: 8rem !important;
  }
  .pe-40 {
    padding-right: 10rem !important;
  }
  .pe-48 {
    padding-right: 12rem !important;
  }
  .pe-56 {
    padding-right: 14rem !important;
  }
  .pe-64 {
    padding-right: 16rem !important;
  }
  .pe-72 {
    padding-right: 18rem !important;
  }
  .pe-80 {
    padding-right: 20rem !important;
  }
  .pe-88 {
    padding-right: 22rem !important;
  }
  .pe-96 {
    padding-right: 24rem !important;
  }
  .pe-px {
    padding-right: 1px !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-4 {
    padding-bottom: 1rem !important;
  }
  .pb-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-8 {
    padding-bottom: 2rem !important;
  }
  .pb-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-12 {
    padding-bottom: 3rem !important;
  }
  .pb-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-16 {
    padding-bottom: 4rem !important;
  }
  .pb-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-20 {
    padding-bottom: 5rem !important;
  }
  .pb-24 {
    padding-bottom: 6rem !important;
  }
  .pb-32 {
    padding-bottom: 8rem !important;
  }
  .pb-40 {
    padding-bottom: 10rem !important;
  }
  .pb-48 {
    padding-bottom: 12rem !important;
  }
  .pb-56 {
    padding-bottom: 14rem !important;
  }
  .pb-64 {
    padding-bottom: 16rem !important;
  }
  .pb-72 {
    padding-bottom: 18rem !important;
  }
  .pb-80 {
    padding-bottom: 20rem !important;
  }
  .pb-88 {
    padding-bottom: 22rem !important;
  }
  .pb-96 {
    padding-bottom: 24rem !important;
  }
  .pb-px {
    padding-bottom: 1px !important;
  }
  .ps-0 {
    padding-left: 0 !important;
  }
  .ps-1 {
    padding-left: 0.25rem !important;
  }
  .ps-2 {
    padding-left: 0.5rem !important;
  }
  .ps-3 {
    padding-left: 0.75rem !important;
  }
  .ps-4 {
    padding-left: 1rem !important;
  }
  .ps-5 {
    padding-left: 1.25rem !important;
  }
  .ps-6 {
    padding-left: 1.5rem !important;
  }
  .ps-7 {
    padding-left: 1.75rem !important;
  }
  .ps-8 {
    padding-left: 2rem !important;
  }
  .ps-10 {
    padding-left: 2.5rem !important;
  }
  .ps-12 {
    padding-left: 3rem !important;
  }
  .ps-14 {
    padding-left: 3.5rem !important;
  }
  .ps-16 {
    padding-left: 4rem !important;
  }
  .ps-18 {
    padding-left: 4.5rem !important;
  }
  .ps-20 {
    padding-left: 5rem !important;
  }
  .ps-24 {
    padding-left: 6rem !important;
  }
  .ps-32 {
    padding-left: 8rem !important;
  }
  .ps-40 {
    padding-left: 10rem !important;
  }
  .ps-48 {
    padding-left: 12rem !important;
  }
  .ps-56 {
    padding-left: 14rem !important;
  }
  .ps-64 {
    padding-left: 16rem !important;
  }
  .ps-72 {
    padding-left: 18rem !important;
  }
  .ps-80 {
    padding-left: 20rem !important;
  }
  .ps-88 {
    padding-left: 22rem !important;
  }
  .ps-96 {
    padding-left: 24rem !important;
  }
  .ps-px {
    padding-left: 1px !important;
  }
  .font-base {
    font-family: var(--x-font-sans-serif) !important;
  }
  .font-display {
    font-family: var(--x-font-display) !important;
  }
  .font-serif {
    font-family: var(--x-font-serif) !important;
  }
  .font-code {
    font-family: var(--x-font-monospace) !important;
  }
  .text-xs {
    font-size: 0.75rem !important;
  }
  .text-sm {
    font-size: 0.875rem !important;
  }
  .text-base {
    font-size: 1rem !important;
  }
  .text-md {
    font-size: 1.125rem !important;
  }
  .text-lg {
    font-size: 1.25rem !important;
  }
  .text-xl {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .text-2xl {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .font-italic {
    font-style: italic !important;
  }
  .font-normal {
    font-style: normal !important;
  }
  .font-light {
    font-weight: 300 !important;
  }
  .font-lighter {
    font-weight: lighter !important;
  }
  .font-regular {
    font-weight: 400 !important;
  }
  .font-semibold {
    font-weight: 500 !important;
  }
  .font-bold {
    font-weight: 600 !important;
  }
  .font-bolder {
    font-weight: bolder !important;
  }
  .lh-none {
    line-height: 1 !important;
  }
  .lh-tighter {
    line-height: 1.125 !important;
  }
  .lh-tight {
    line-height: 1.2 !important;
  }
  .lh-snug {
    line-height: 1.375 !important;
  }
  .lh-normal {
    line-height: 1.5 !important;
  }
  .lh-relaxed {
    line-height: 1.625 !important;
  }
  .lh-loose {
    line-height: 2 !important;
  }
  .text-start {
    text-align: left !important;
  }
  .text-end {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-no-decoration {
    text-decoration: none !important;
  }
  .text-no-decoration-hover:hover {
    text-decoration: none !important;
  }
  .text-underline {
    text-decoration: underline !important;
  }
  .text-underline-hover:hover {
    text-decoration: underline !important;
  }
  .text-line-through {
    text-decoration: line-through !important;
  }
  .text-line-through-hover:hover {
    text-decoration: line-through !important;
  }
  .text-lowercase {
    text-transform: lowercase !important;
  }
  .text-lowercase-hover:hover {
    text-transform: lowercase !important;
  }
  .text-uppercase {
    text-transform: uppercase !important;
  }
  .text-uppercase-hover:hover {
    text-transform: uppercase !important;
  }
  .text-capitalize {
    text-transform: capitalize !important;
  }
  .text-capitalize-hover:hover {
    text-transform: capitalize !important;
  }
  .text-wrap {
    white-space: normal !important;
  }
  .text-nowrap {
    white-space: nowrap !important;
  }
  .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }
  .text-primary {
    --x-text-opacity: 1;
    color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
  }
  .text-primary-hover:hover {
    color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
  }
  .text-secondary {
    --x-text-opacity: 1;
    color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
  }
  .text-secondary-hover:hover {
    color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
  }
  .text-tertiary {
    --x-text-opacity: 1;
    color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
  }
  .text-tertiary-hover:hover {
    color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
  }
  .text-success {
    --x-text-opacity: 1;
    color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
  }
  .text-success-hover:hover {
    color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
  }
  .text-info {
    --x-text-opacity: 1;
    color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
  }
  .text-info-hover:hover {
    color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
  }
  .text-warning {
    --x-text-opacity: 1;
    color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
  }
  .text-warning-hover:hover {
    color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
  }
  .text-danger {
    --x-text-opacity: 1;
    color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
  }
  .text-danger-hover:hover {
    color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
  }
  .text-white {
    --x-text-opacity: 1;
    color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
  }
  .text-white-hover:hover {
    color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
  }
  .text-light {
    --x-text-opacity: 1;
    color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
  }
  .text-light-hover:hover {
    color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
  }
  .text-dark {
    --x-text-opacity: 1;
    color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
  }
  .text-dark-hover:hover {
    color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
  }
  .text-black {
    --x-text-opacity: 1;
    color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
  }
  .text-black-hover:hover {
    color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
  }
  .text-body {
    --x-text-opacity: 1;
    color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
  }
  .text-body-hover:hover {
    color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
  }
  .text-muted {
    --x-text-opacity: 1;
    color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
  }
  .text-muted-hover:hover {
    color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
  }
  .text-heading {
    --x-text-opacity: 1;
    color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
  }
  .text-heading-hover:hover {
    color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
  }
  .text-current {
    --x-text-opacity: 1;
    color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
  }
  .text-current-hover:hover {
    color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
  }
  .text-reset {
    --x-text-opacity: 1;
    color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
  }
  .text-reset-hover:hover {
    color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
  }
  .text-opacity-25 {
    --x-text-opacity: 0.25;
  }
  .text-opacity-50 {
    --x-text-opacity: 0.5;
  }
  .text-opacity-75 {
    --x-text-opacity: 0.75;
  }
  .text-opacity-100 {
    --x-text-opacity: 1;
  }
  .bg-primary {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-primary-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-primary-hover:hover {
    background-color: rgba(var(--x-primary-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-primary-focus:focus {
    background-color: rgba(var(--x-primary-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-primary-focus-within:focus-within {
    background-color: rgba(var(--x-primary-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-secondary {
    --x-bg-opacity: 1;
    background-color: rgba(
      var(--x-secondary-rgb),
      var(--x-bg-opacity)
    ) !important;
  }
  .bg-secondary-hover:hover {
    background-color: rgba(
      var(--x-secondary-rgb),
      var(--x-bg-opacity)
    ) !important;
  }
  .bg-secondary-focus:focus {
    background-color: rgba(
      var(--x-secondary-rgb),
      var(--x-bg-opacity)
    ) !important;
  }
  .bg-secondary-focus-within:focus-within {
    background-color: rgba(
      var(--x-secondary-rgb),
      var(--x-bg-opacity)
    ) !important;
  }
  .bg-tertiary {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-tertiary-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-tertiary-hover:hover {
    background-color: rgba(var(--x-tertiary-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-tertiary-focus:focus {
    background-color: rgba(var(--x-tertiary-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-tertiary-focus-within:focus-within {
    background-color: rgba(var(--x-tertiary-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-success {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-success-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-success-hover:hover {
    background-color: rgba(var(--x-success-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-success-focus:focus {
    background-color: rgba(var(--x-success-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-success-focus-within:focus-within {
    background-color: rgba(var(--x-success-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-info {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-info-hover:hover {
    background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-info-focus:focus {
    background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-info-focus-within:focus-within {
    background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-warning {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-warning-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-warning-hover:hover {
    background-color: rgba(var(--x-warning-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-warning-focus:focus {
    background-color: rgba(var(--x-warning-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-warning-focus-within:focus-within {
    background-color: rgba(var(--x-warning-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-danger {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-danger-hover:hover {
    background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-danger-focus:focus {
    background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-danger-focus-within:focus-within {
    background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-white {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-white-hover:hover {
    background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-white-focus:focus {
    background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-white-focus-within:focus-within {
    background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-light {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-light-hover:hover {
    background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-light-focus:focus {
    background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-light-focus-within:focus-within {
    background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-dark {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-dark-hover:hover {
    background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-dark-focus:focus {
    background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-dark-focus-within:focus-within {
    background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-body {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-body-bg-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-body-hover:hover {
    background-color: rgba(var(--x-body-bg-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-body-focus:focus {
    background-color: rgba(var(--x-body-bg-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-body-focus-within:focus-within {
    background-color: rgba(var(--x-body-bg-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-black {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-black-hover:hover {
    background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-black-focus:focus {
    background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-black-focus-within:focus-within {
    background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-card {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-card-hover:hover {
    background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-card-focus:focus {
    background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-card-focus-within:focus-within {
    background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-transparent {
    --x-bg-opacity: 1;
    background-color: rgba(
      var(--x-transparent-rgb),
      var(--x-bg-opacity)
    ) !important;
  }
  .bg-transparent-hover:hover {
    background-color: rgba(
      var(--x-transparent-rgb),
      var(--x-bg-opacity)
    ) !important;
  }
  .bg-transparent-focus:focus {
    background-color: rgba(
      var(--x-transparent-rgb),
      var(--x-bg-opacity)
    ) !important;
  }
  .bg-transparent-focus-within:focus-within {
    background-color: rgba(
      var(--x-transparent-rgb),
      var(--x-bg-opacity)
    ) !important;
  }
  .bg-current {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-current-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-current-hover:hover {
    background-color: rgba(var(--x-current-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-current-focus:focus {
    background-color: rgba(var(--x-current-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-current-focus-within:focus-within {
    background-color: rgba(var(--x-current-rgb), var(--x-bg-opacity)) !important;
  }
  .bg-opacity-10 {
    --x-bg-opacity: 0.1;
  }
  .bg-opacity-25 {
    --x-bg-opacity: 0.25;
  }
  .bg-opacity-50 {
    --x-bg-opacity: 0.5;
  }
  .bg-opacity-75 {
    --x-bg-opacity: 0.75;
  }
  .bg-opacity-100 {
    --x-bg-opacity: 1;
  }
  .bg-gradient {
    background-image: var(--x-gradient) !important;
  }
  .user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important;
  }
  .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important;
  }
  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
  }
  .pe-none {
    pointer-events: none !important;
  }
  .pe-auto {
    pointer-events: auto !important;
  }
  .rounded {
    border-radius: 0.375rem !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
  }
  .rounded-1 {
    border-radius: 0.25rem !important;
  }
  .rounded-2 {
    border-radius: 0.5rem !important;
  }
  .rounded-3 {
    border-radius: 0.75rem !important;
  }
  .rounded-4 {
    border-radius: 1rem !important;
  }
  .rounded-5 {
    border-radius: 1.5rem !important;
  }
  .rounded-6 {
    border-radius: 3rem !important;
  }
  .rounded-7 {
    border-radius: 4rem !important;
  }
  .rounded-8 {
    border-radius: 5rem !important;
  }
  .rounded-9 {
    border-radius: 6rem !important;
  }
  .rounded-10 {
    border-radius: 7rem !important;
  }
  .rounded-pill {
    border-radius: 50rem !important;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
  .rounded-card {
    border-radius: 0.75rem !important;
  }
  .rounded-top {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-top-1 {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-2 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .rounded-top-3 {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-7 {
    border-top-left-radius: 4rem !important;
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-8 {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-9 {
    border-top-left-radius: 6rem !important;
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-10 {
    border-top-left-radius: 7rem !important;
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .rounded-top-card {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-end {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-end-1 {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-end-2 {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .rounded-end-3 {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-end-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-end-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-end-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-end-7 {
    border-top-right-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-end-8 {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-end-9 {
    border-top-right-radius: 6rem !important;
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-end-10 {
    border-top-right-radius: 7rem !important;
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-end-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-end-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .rounded-end-card {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-bottom {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-1 {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-2 {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .rounded-bottom-3 {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-7 {
    border-bottom-right-radius: 4rem !important;
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-8 {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-9 {
    border-bottom-right-radius: 6rem !important;
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-10 {
    border-bottom-right-radius: 7rem !important;
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-card {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-start {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-start-1 {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-start-2 {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
  .rounded-start-3 {
    border-bottom-left-radius: 0.75rem !important;
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-start-4 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
  }
  .rounded-start-5 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-start-6 {
    border-bottom-left-radius: 3rem !important;
    border-top-left-radius: 3rem !important;
  }
  .rounded-start-7 {
    border-bottom-left-radius: 4rem !important;
    border-top-left-radius: 4rem !important;
  }
  .rounded-start-8 {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important;
  }
  .rounded-start-9 {
    border-bottom-left-radius: 6rem !important;
    border-top-left-radius: 6rem !important;
  }
  .rounded-start-10 {
    border-bottom-left-radius: 7rem !important;
    border-top-left-radius: 7rem !important;
  }
  .rounded-start-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important;
  }
  .rounded-start-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
  }
  .rounded-start-card {
    border-bottom-left-radius: 0.75rem !important;
    border-top-left-radius: 0.75rem !important;
  }
  .visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden !important;
  }
  .overlap-10 {
    z-index: 10 !important;
  }
  .overlap-20 {
    z-index: 20 !important;
  }
  .overlap-30 {
    z-index: 30 !important;
  }
  .overlap-40 {
    z-index: 40 !important;
  }
  .overlap-50 {
    z-index: 50 !important;
  }
  .overlap-100 {
    z-index: 100 !important;
  }
  .overlap-200 {
    z-index: 200 !important;
  }
  .overlap-300 {
    z-index: 300 !important;
  }
  .overlap-400 {
    z-index: 400 !important;
  }
  .overlap-500 {
    z-index: 500 !important;
  }
  .overlap-1000 {
    z-index: 1000 !important;
  }
  .overlap-auto {
    z-index: auto !important;
  }
  .rounded-top-start {
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-top-start-0 {
    border-top-left-radius: 0 !important;
  }
  .rounded-top-start-1 {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-2 {
    border-top-left-radius: 0.5rem !important;
  }
  .rounded-top-start-3 {
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-top-start-4 {
    border-top-left-radius: 1rem !important;
  }
  .rounded-top-start-5 {
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-top-start-6 {
    border-top-left-radius: 3rem !important;
  }
  .rounded-top-start-7 {
    border-top-left-radius: 4rem !important;
  }
  .rounded-top-start-8 {
    border-top-left-radius: 5rem !important;
  }
  .rounded-top-start-9 {
    border-top-left-radius: 6rem !important;
  }
  .rounded-top-start-10 {
    border-top-left-radius: 7rem !important;
  }
  .rounded-top-start-pill {
    border-top-left-radius: 50rem !important;
  }
  .rounded-top-start-circle {
    border-top-left-radius: 50% !important;
  }
  .rounded-top-start-card {
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-top-end {
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-top-end-0 {
    border-top-right-radius: 0 !important;
  }
  .rounded-top-end-1 {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-end-2 {
    border-top-right-radius: 0.5rem !important;
  }
  .rounded-top-end-3 {
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-end-4 {
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-end-5 {
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-end-6 {
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-end-7 {
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-end-8 {
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-end-9 {
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-end-10 {
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-end-pill {
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-end-circle {
    border-top-right-radius: 50% !important;
  }
  .rounded-top-end-card {
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-bottom-end {
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-bottom-end-0 {
    border-bottom-right-radius: 0 !important;
  }
  .rounded-bottom-end-1 {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-2 {
    border-bottom-right-radius: 0.5rem !important;
  }
  .rounded-bottom-end-3 {
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-bottom-end-4 {
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-bottom-end-5 {
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-bottom-end-6 {
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-bottom-end-7 {
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-bottom-end-8 {
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-bottom-end-9 {
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-bottom-end-10 {
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-bottom-end-pill {
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-bottom-end-circle {
    border-bottom-right-radius: 50% !important;
  }
  .rounded-bottom-end-card {
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-bottom-start {
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-bottom-start-0 {
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-start-1 {
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-start-2 {
    border-bottom-left-radius: 0.5rem !important;
  }
  .rounded-bottom-start-3 {
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-start-4 {
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-start-5 {
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-start-6 {
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-start-7 {
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-start-8 {
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-start-9 {
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-start-10 {
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-start-pill {
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-start-circle {
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-start-card {
    border-bottom-left-radius: 0.75rem !important;
  }
  .border-solid {
    border-style: solid !important;
  }
  .border-dashed {
    border-style: dashed !important;
  }
  .border-dotted {
    border-style: dotted !important;
  }
  .border-double {
    border-style: double !important;
  }
  .border-groove {
    border-style: groove !important;
  }
  .border-none {
    border-style: none !important;
  }
  .cursor-auto {
    cursor: auto !important;
  }
  .cursor-auto-hover:hover {
    cursor: auto !important;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
  .cursor-pointer-hover:hover {
    cursor: pointer !important;
  }
  .cursor-wait {
    cursor: wait !important;
  }
  .cursor-wait-hover:hover {
    cursor: wait !important;
  }
  .cursor-text {
    cursor: text !important;
  }
  .cursor-text-hover:hover {
    cursor: text !important;
  }
  .ls-tighter {
    letter-spacing: -0.05em !important;
  }
  .ls-tight {
    letter-spacing: -0.025em !important;
  }
  .ls-normal {
    letter-spacing: 0 !important;
  }
  .ls-wide {
    letter-spacing: 0.025em !important;
  }
  .ls-wider {
    letter-spacing: 0.05em !important;
  }
  .ls-widest {
    letter-spacing: 0.1em !important;
  }
  .bg-auto {
    background-size: auto !important;
  }
  .bg-cover {
    background-size: cover !important;
  }
  .bg-contain {
    background-size: contain !important;
  }
  .min-w-0 {
    min-width: 0 !important;
  }
  .min-w-full {
    min-width: 100% !important;
  }
  .min-w-min {
    min-width: -webkit-min-content !important;
    min-width: -moz-min-content !important;
    min-width: min-content !important;
  }
  .min-w-max {
    min-width: -webkit-max-content !important;
    min-width: -moz-max-content !important;
    min-width: max-content !important;
  }
  .min-h-0 {
    min-height: 0 !important;
  }
  .min-h-full {
    min-height: 100% !important;
  }
  .min-h-screen {
    min-height: 100vh !important;
  }
  .inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }
  .inset-1 {
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }
  .inset-2 {
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }
  .inset-3 {
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }
  .inset-4 {
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }
  .inset-5 {
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }
  .inset-6 {
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }
  .inset-7 {
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }
  .inset-8 {
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }
  .inset-10 {
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }
  .inset-12 {
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }
  .inset-14 {
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }
  .inset-16 {
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }
  .inset-18 {
    top: 4.5rem !important;
    right: 4.5rem !important;
    bottom: 4.5rem !important;
    left: 4.5rem !important;
  }
  .inset-20 {
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }
  .inset-24 {
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }
  .inset-32 {
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }
  .inset-40 {
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }
  .inset-48 {
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }
  .inset-56 {
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }
  .inset-64 {
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }
  .inset-72 {
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }
  .inset-80 {
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }
  .inset-88 {
    top: 22rem !important;
    right: 22rem !important;
    bottom: 22rem !important;
    left: 22rem !important;
  }
  .inset-96 {
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }
  .inset-px {
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }
  .inset-1\/2 {
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }
  .inset-full {
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }
  .inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }
  .inset-x-0 {
    left: 0 !important;
    right: 0 !important;
  }
  .inset-x-1 {
    left: 0.25rem !important;
    right: 0.25rem !important;
  }
  .inset-x-2 {
    left: 0.5rem !important;
    right: 0.5rem !important;
  }
  .inset-x-3 {
    left: 0.75rem !important;
    right: 0.75rem !important;
  }
  .inset-x-4 {
    left: 1rem !important;
    right: 1rem !important;
  }
  .inset-x-5 {
    left: 1.25rem !important;
    right: 1.25rem !important;
  }
  .inset-x-6 {
    left: 1.5rem !important;
    right: 1.5rem !important;
  }
  .inset-x-7 {
    left: 1.75rem !important;
    right: 1.75rem !important;
  }
  .inset-x-8 {
    left: 2rem !important;
    right: 2rem !important;
  }
  .inset-x-10 {
    left: 2.5rem !important;
    right: 2.5rem !important;
  }
  .inset-x-12 {
    left: 3rem !important;
    right: 3rem !important;
  }
  .inset-x-14 {
    left: 3.5rem !important;
    right: 3.5rem !important;
  }
  .inset-x-16 {
    left: 4rem !important;
    right: 4rem !important;
  }
  .inset-x-18 {
    left: 4.5rem !important;
    right: 4.5rem !important;
  }
  .inset-x-20 {
    left: 5rem !important;
    right: 5rem !important;
  }
  .inset-x-24 {
    left: 6rem !important;
    right: 6rem !important;
  }
  .inset-x-32 {
    left: 8rem !important;
    right: 8rem !important;
  }
  .inset-x-40 {
    left: 10rem !important;
    right: 10rem !important;
  }
  .inset-x-48 {
    left: 12rem !important;
    right: 12rem !important;
  }
  .inset-x-56 {
    left: 14rem !important;
    right: 14rem !important;
  }
  .inset-x-64 {
    left: 16rem !important;
    right: 16rem !important;
  }
  .inset-x-72 {
    left: 18rem !important;
    right: 18rem !important;
  }
  .inset-x-80 {
    left: 20rem !important;
    right: 20rem !important;
  }
  .inset-x-88 {
    left: 22rem !important;
    right: 22rem !important;
  }
  .inset-x-96 {
    left: 24rem !important;
    right: 24rem !important;
  }
  .inset-x-px {
    left: 1px !important;
    right: 1px !important;
  }
  .inset-x-1\/2 {
    left: 50% !important;
    right: 50% !important;
  }
  .inset-x-full {
    left: 100% !important;
    right: 100% !important;
  }
  .inset-x-auto {
    left: auto !important;
    right: auto !important;
  }
  .inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }
  .inset-y-1 {
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }
  .inset-y-2 {
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }
  .inset-y-3 {
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }
  .inset-y-4 {
    top: 1rem !important;
    bottom: 1rem !important;
  }
  .inset-y-5 {
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }
  .inset-y-6 {
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }
  .inset-y-7 {
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }
  .inset-y-8 {
    top: 2rem !important;
    bottom: 2rem !important;
  }
  .inset-y-10 {
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }
  .inset-y-12 {
    top: 3rem !important;
    bottom: 3rem !important;
  }
  .inset-y-14 {
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }
  .inset-y-16 {
    top: 4rem !important;
    bottom: 4rem !important;
  }
  .inset-y-18 {
    top: 4.5rem !important;
    bottom: 4.5rem !important;
  }
  .inset-y-20 {
    top: 5rem !important;
    bottom: 5rem !important;
  }
  .inset-y-24 {
    top: 6rem !important;
    bottom: 6rem !important;
  }
  .inset-y-32 {
    top: 8rem !important;
    bottom: 8rem !important;
  }
  .inset-y-40 {
    top: 10rem !important;
    bottom: 10rem !important;
  }
  .inset-y-48 {
    top: 12rem !important;
    bottom: 12rem !important;
  }
  .inset-y-56 {
    top: 14rem !important;
    bottom: 14rem !important;
  }
  .inset-y-64 {
    top: 16rem !important;
    bottom: 16rem !important;
  }
  .inset-y-72 {
    top: 18rem !important;
    bottom: 18rem !important;
  }
  .inset-y-80 {
    top: 20rem !important;
    bottom: 20rem !important;
  }
  .inset-y-88 {
    top: 22rem !important;
    bottom: 22rem !important;
  }
  .inset-y-96 {
    top: 24rem !important;
    bottom: 24rem !important;
  }
  .inset-y-px {
    top: 1px !important;
    bottom: 1px !important;
  }
  .inset-y-1\/2 {
    top: 50% !important;
    bottom: 50% !important;
  }
  .inset-y-full {
    top: 100% !important;
    bottom: 100% !important;
  }
  .inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }
  .place-content-start {
    place-content: flex-start !important;
  }
  .place-content-end {
    place-content: flex-end !important;
  }
  .place-content-center {
    place-content: center !important;
  }
  .place-content-between {
    place-content: space-between !important;
  }
  .place-content-around {
    place-content: space-around !important;
  }
  .place-content-evenly {
    place-content: space-evenly !important;
  }
  .place-content-stretch {
    place-content: stretch !important;
  }
  .overflow-x-auto {
    overflow-x: auto !important;
  }
  .overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-visible {
    overflow-x: visible !important;
  }
  .overflow-x-scroll {
    overflow-x: scroll !important;
  }
  .overflow-y-auto {
    overflow-y: auto !important;
  }
  .overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-visible {
    overflow-y: visible !important;
  }
  .overflow-y-scroll {
    overflow-y: scroll !important;
  }
  .transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform !important;
  }
  .transition-none {
    transition-property: none !important;
  }
  .transition-all {
    transition-property: all !important;
  }
  .transition-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }
  .transition-opacity {
    transition-property: opacity !important;
  }
  .transition-shadow {
    transition-property: box-shadow !important;
  }
  .transition-transform {
    transition-property: transform !important;
  }
  .ease-base {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .ease-linear {
    transition-timing-function: linear !important;
  }
  .ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }
  .ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }
  .ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .duration-base {
    transition-duration: 150ms !important;
  }
  .duration-75 {
    transition-duration: 75ms !important;
  }
  .duration-100 {
    transition-duration: 100ms !important;
  }
  .duration-150 {
    transition-duration: 150ms !important;
  }
  .duration-200 {
    transition-duration: 200ms !important;
  }
  .duration-300 {
    transition-duration: 300ms !important;
  }
  .duration-500 {
    transition-duration: 500ms !important;
  }
  .duration-700 {
    transition-duration: 700ms !important;
  }
  .duration-1000 {
    transition-duration: 1000ms !important;
  }
  .delay-75 {
    transition-delay: 75ms !important;
  }
  .delay-100 {
    transition-delay: 100ms !important;
  }
  .delay-150 {
    transition-delay: 150ms !important;
  }
  .delay-200 {
    transition-delay: 200ms !important;
  }
  .delay-300 {
    transition-delay: 300ms !important;
  }
  .delay-500 {
    transition-delay: 500ms !important;
  }
  .delay-700 {
    transition-delay: 700ms !important;
  }
  .delay-1000 {
    transition-delay: 1000ms !important;
  }
  .animation-none {
    -webkit-animation: none !important;
    animation: none !important;
  }
  .animation-spin {
    -webkit-animation: spin 1s linear infinite !important;
    animation: spin 1s linear infinite !important;
  }
  .animation-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }
  .animation-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }
  .animation-bounce {
    -webkit-animation: bounce 1s infinite !important;
    animation: bounce 1s infinite !important;
  }
  .animation-slide-left {
    -webkit-animation: slideLeft 3s infinite !important;
    animation: slideLeft 3s infinite !important;
  }
  .animation-slide-right {
    -webkit-animation: slideRight 3s infinite !important;
    animation: slideRight 3s infinite !important;
  }
  .animation-move-slow-left {
    -webkit-animation: slideLeft 15s linear infinite !important;
    animation: slideLeft 15s linear infinite !important;
  }
  .animation-move-slow-right {
    -webkit-animation: slideRight 15s linear infinite !important;
    animation: slideRight 15s linear infinite !important;
  }
  .blur-5 {
    --x-blur: 5px !important;
  }
  .blur-10 {
    --x-blur: 10px !important;
  }
  .blur-25 {
    --x-blur: 25px !important;
  }
  .blur-50 {
    --x-blur: 50px !important;
  }
  .blur-75 {
    --x-blur: 75px !important;
  }
  .blur-100 {
    --x-blur: 100px !important;
  }
  .blur-125 {
    --x-blur: 125px !important;
  }
  .blur-150 {
    --x-blur: 150px !important;
  }
  .blur-200 {
    --x-blur: 200px !important;
  }
  .transform-none {
    transform: none !important;
  }
  .origin-center {
    transform-origin: center !important;
  }
  .origin-center-hover:hover {
    transform-origin: center !important;
  }
  .origin-top {
    transform-origin: top !important;
  }
  .origin-top-hover:hover {
    transform-origin: top !important;
  }
  .origin-top-right {
    transform-origin: top right !important;
  }
  .origin-top-right-hover:hover {
    transform-origin: top right !important;
  }
  .origin-right {
    transform-origin: right !important;
  }
  .origin-right-hover:hover {
    transform-origin: right !important;
  }
  .origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .origin-bottom-right-hover:hover {
    transform-origin: bottom right !important;
  }
  .origin-bottom {
    transform-origin: bottom !important;
  }
  .origin-bottom-hover:hover {
    transform-origin: bottom !important;
  }
  .origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .origin-bottom-left-hover:hover {
    transform-origin: bottom left !important;
  }
  .origin-left {
    transform-origin: left !important;
  }
  .origin-left-hover:hover {
    transform-origin: left !important;
  }
  .origin-top-left {
    transform-origin: top left !important;
  }
  .origin-top-left-hover:hover {
    transform-origin: top left !important;
  }
  .scale-0 {
    --x-scale-x: 0 !important;
    --x-scale-y: 0 !important;
  }
  .scale-0-hover:hover {
    --x-scale-x: 0 !important;
    --x-scale-y: 0 !important;
  }
  .scale-25 {
    --x-scale-x: 0.25 !important;
    --x-scale-y: 0.25 !important;
  }
  .scale-25-hover:hover {
    --x-scale-x: 0.25 !important;
    --x-scale-y: 0.25 !important;
  }
  .scale-50 {
    --x-scale-x: 0.5 !important;
    --x-scale-y: 0.5 !important;
  }
  .scale-50-hover:hover {
    --x-scale-x: 0.5 !important;
    --x-scale-y: 0.5 !important;
  }
  .scale-75 {
    --x-scale-x: 0.75 !important;
    --x-scale-y: 0.75 !important;
  }
  .scale-75-hover:hover {
    --x-scale-x: 0.75 !important;
    --x-scale-y: 0.75 !important;
  }
  .scale-90 {
    --x-scale-x: 0.9 !important;
    --x-scale-y: 0.9 !important;
  }
  .scale-90-hover:hover {
    --x-scale-x: 0.9 !important;
    --x-scale-y: 0.9 !important;
  }
  .scale-95 {
    --x-scale-x: 0.95 !important;
    --x-scale-y: 0.95 !important;
  }
  .scale-95-hover:hover {
    --x-scale-x: 0.95 !important;
    --x-scale-y: 0.95 !important;
  }
  .scale-100 {
    --x-scale-x: 1 !important;
    --x-scale-y: 1 !important;
  }
  .scale-100-hover:hover {
    --x-scale-x: 1 !important;
    --x-scale-y: 1 !important;
  }
  .scale-105 {
    --x-scale-x: 1.05 !important;
    --x-scale-y: 1.05 !important;
  }
  .scale-105-hover:hover {
    --x-scale-x: 1.05 !important;
    --x-scale-y: 1.05 !important;
  }
  .scale-110 {
    --x-scale-x: 1.1 !important;
    --x-scale-y: 1.1 !important;
  }
  .scale-110-hover:hover {
    --x-scale-x: 1.1 !important;
    --x-scale-y: 1.1 !important;
  }
  .scale-125 {
    --x-scale-x: 1.25 !important;
    --x-scale-y: 1.25 !important;
  }
  .scale-125-hover:hover {
    --x-scale-x: 1.25 !important;
    --x-scale-y: 1.25 !important;
  }
  .scale-150 {
    --x-scale-x: 1.5 !important;
    --x-scale-y: 1.5 !important;
  }
  .scale-150-hover:hover {
    --x-scale-x: 1.5 !important;
    --x-scale-y: 1.5 !important;
  }
  .scale-200 {
    --x-scale-x: 2 !important;
    --x-scale-y: 2 !important;
  }
  .scale-200-hover:hover {
    --x-scale-x: 2 !important;
    --x-scale-y: 2 !important;
  }
  .scale-y-0 {
    --x--scale-y: 0 !important;
  }
  .scale-y-0-hover:hover {
    --x--scale-y: 0 !important;
  }
  .scale-y-25 {
    --x--scale-y: 0.25 !important;
  }
  .scale-y-25-hover:hover {
    --x--scale-y: 0.25 !important;
  }
  .scale-y-50 {
    --x--scale-y: 0.5 !important;
  }
  .scale-y-50-hover:hover {
    --x--scale-y: 0.5 !important;
  }
  .scale-y-75 {
    --x--scale-y: 0.75 !important;
  }
  .scale-y-75-hover:hover {
    --x--scale-y: 0.75 !important;
  }
  .scale-y-90 {
    --x--scale-y: 0.9 !important;
  }
  .scale-y-90-hover:hover {
    --x--scale-y: 0.9 !important;
  }
  .scale-y-95 {
    --x--scale-y: 0.95 !important;
  }
  .scale-y-95-hover:hover {
    --x--scale-y: 0.95 !important;
  }
  .scale-y-100 {
    --x--scale-y: 1 !important;
  }
  .scale-y-100-hover:hover {
    --x--scale-y: 1 !important;
  }
  .scale-y-105 {
    --x--scale-y: 1.05 !important;
  }
  .scale-y-105-hover:hover {
    --x--scale-y: 1.05 !important;
  }
  .scale-y-110 {
    --x--scale-y: 1.1 !important;
  }
  .scale-y-110-hover:hover {
    --x--scale-y: 1.1 !important;
  }
  .scale-y-125 {
    --x--scale-y: 1.25 !important;
  }
  .scale-y-125-hover:hover {
    --x--scale-y: 1.25 !important;
  }
  .scale-y-150 {
    --x--scale-y: 1.5 !important;
  }
  .scale-y-150-hover:hover {
    --x--scale-y: 1.5 !important;
  }
  .scale-y-200 {
    --x--scale-y: 2 !important;
  }
  .scale-y-200-hover:hover {
    --x--scale-y: 2 !important;
  }
  .scale-x-0 {
    --x--scale-x: 0 !important;
  }
  .scale-x-0-hover:hover {
    --x--scale-x: 0 !important;
  }
  .scale-x-25 {
    --x--scale-x: 0.25 !important;
  }
  .scale-x-25-hover:hover {
    --x--scale-x: 0.25 !important;
  }
  .scale-x-50 {
    --x--scale-x: 0.5 !important;
  }
  .scale-x-50-hover:hover {
    --x--scale-x: 0.5 !important;
  }
  .scale-x-75 {
    --x--scale-x: 0.75 !important;
  }
  .scale-x-75-hover:hover {
    --x--scale-x: 0.75 !important;
  }
  .scale-x-90 {
    --x--scale-x: 0.9 !important;
  }
  .scale-x-90-hover:hover {
    --x--scale-x: 0.9 !important;
  }
  .scale-x-95 {
    --x--scale-x: 0.95 !important;
  }
  .scale-x-95-hover:hover {
    --x--scale-x: 0.95 !important;
  }
  .scale-x-100 {
    --x--scale-x: 1 !important;
  }
  .scale-x-100-hover:hover {
    --x--scale-x: 1 !important;
  }
  .scale-x-105 {
    --x--scale-x: 1.05 !important;
  }
  .scale-x-105-hover:hover {
    --x--scale-x: 1.05 !important;
  }
  .scale-x-110 {
    --x--scale-x: 1.1 !important;
  }
  .scale-x-110-hover:hover {
    --x--scale-x: 1.1 !important;
  }
  .scale-x-125 {
    --x--scale-x: 1.25 !important;
  }
  .scale-x-125-hover:hover {
    --x--scale-x: 1.25 !important;
  }
  .scale-x-150 {
    --x--scale-x: 1.5 !important;
  }
  .scale-x-150-hover:hover {
    --x--scale-x: 1.5 !important;
  }
  .scale-x-200 {
    --x--scale-x: 2 !important;
  }
  .scale-x-200-hover:hover {
    --x--scale-x: 2 !important;
  }
  .rotate-0 {
    --x-rotate: 0deg !important;
  }
  .rotate-0-hover:hover {
    --x-rotate: 0deg !important;
  }
  .rotate-1 {
    --x-rotate: 1deg !important;
  }
  .rotate-1-hover:hover {
    --x-rotate: 1deg !important;
  }
  .rotate-2 {
    --x-rotate: 2deg !important;
  }
  .rotate-2-hover:hover {
    --x-rotate: 2deg !important;
  }
  .rotate-3 {
    --x-rotate: 3deg !important;
  }
  .rotate-3-hover:hover {
    --x-rotate: 3deg !important;
  }
  .rotate-6 {
    --x-rotate: 6deg !important;
  }
  .rotate-6-hover:hover {
    --x-rotate: 6deg !important;
  }
  .rotate-12 {
    --x-rotate: 12deg !important;
  }
  .rotate-12-hover:hover {
    --x-rotate: 12deg !important;
  }
  .rotate-30 {
    --x-rotate: 30deg !important;
  }
  .rotate-30-hover:hover {
    --x-rotate: 30deg !important;
  }
  .rotate-45 {
    --x-rotate: 45deg !important;
  }
  .rotate-45-hover:hover {
    --x-rotate: 45deg !important;
  }
  .rotate-90 {
    --x-rotate: 90deg !important;
  }
  .rotate-90-hover:hover {
    --x-rotate: 90deg !important;
  }
  .rotate-180 {
    --x-rotate: 180deg !important;
  }
  .rotate-180-hover:hover {
    --x-rotate: 180deg !important;
  }
  .rotate-n1 {
    --x-rotate: -1deg !important;
  }
  .rotate-n1-hover:hover {
    --x-rotate: -1deg !important;
  }
  .rotate-n2 {
    --x-rotate: -2deg !important;
  }
  .rotate-n2-hover:hover {
    --x-rotate: -2deg !important;
  }
  .rotate-n3 {
    --x-rotate: -3deg !important;
  }
  .rotate-n3-hover:hover {
    --x-rotate: -3deg !important;
  }
  .rotate-n6 {
    --x-rotate: -6deg !important;
  }
  .rotate-n6-hover:hover {
    --x-rotate: -6deg !important;
  }
  .rotate-n12 {
    --x-rotate: -12deg !important;
  }
  .rotate-n12-hover:hover {
    --x-rotate: -12deg !important;
  }
  .rotate-n30 {
    --x-rotate: -30deg !important;
  }
  .rotate-n30-hover:hover {
    --x-rotate: -30deg !important;
  }
  .rotate-n45 {
    --x-rotate: -45deg !important;
  }
  .rotate-n45-hover:hover {
    --x-rotate: -45deg !important;
  }
  .rotate-n90 {
    --x-rotate: -90deg !important;
  }
  .rotate-n90-hover:hover {
    --x-rotate: -90deg !important;
  }
  .rotate-n180 {
    --x-rotate: -180deg !important;
  }
  .rotate-n180-hover:hover {
    --x-rotate: -180deg !important;
  }
  .rotate-y-0 {
    --x-rotate-y: 0deg !important;
  }
  .rotate-y-0-hover:hover {
    --x-rotate-y: 0deg !important;
  }
  .rotate-y-1 {
    --x-rotate-y: 1deg !important;
  }
  .rotate-y-1-hover:hover {
    --x-rotate-y: 1deg !important;
  }
  .rotate-y-2 {
    --x-rotate-y: 2deg !important;
  }
  .rotate-y-2-hover:hover {
    --x-rotate-y: 2deg !important;
  }
  .rotate-y-3 {
    --x-rotate-y: 3deg !important;
  }
  .rotate-y-3-hover:hover {
    --x-rotate-y: 3deg !important;
  }
  .rotate-y-6 {
    --x-rotate-y: 6deg !important;
  }
  .rotate-y-6-hover:hover {
    --x-rotate-y: 6deg !important;
  }
  .rotate-y-12 {
    --x-rotate-y: 12deg !important;
  }
  .rotate-y-12-hover:hover {
    --x-rotate-y: 12deg !important;
  }
  .rotate-y-30 {
    --x-rotate-y: 30deg !important;
  }
  .rotate-y-30-hover:hover {
    --x-rotate-y: 30deg !important;
  }
  .rotate-y-45 {
    --x-rotate-y: 45deg !important;
  }
  .rotate-y-45-hover:hover {
    --x-rotate-y: 45deg !important;
  }
  .rotate-y-90 {
    --x-rotate-y: 90deg !important;
  }
  .rotate-y-90-hover:hover {
    --x-rotate-y: 90deg !important;
  }
  .rotate-y-180 {
    --x-rotate-y: 180deg !important;
  }
  .rotate-y-180-hover:hover {
    --x-rotate-y: 180deg !important;
  }
  .rotate-y-n1 {
    --x-rotate-y: -1deg !important;
  }
  .rotate-y-n1-hover:hover {
    --x-rotate-y: -1deg !important;
  }
  .rotate-y-n2 {
    --x-rotate-y: -2deg !important;
  }
  .rotate-y-n2-hover:hover {
    --x-rotate-y: -2deg !important;
  }
  .rotate-y-n3 {
    --x-rotate-y: -3deg !important;
  }
  .rotate-y-n3-hover:hover {
    --x-rotate-y: -3deg !important;
  }
  .rotate-y-n6 {
    --x-rotate-y: -6deg !important;
  }
  .rotate-y-n6-hover:hover {
    --x-rotate-y: -6deg !important;
  }
  .rotate-y-n12 {
    --x-rotate-y: -12deg !important;
  }
  .rotate-y-n12-hover:hover {
    --x-rotate-y: -12deg !important;
  }
  .rotate-y-n30 {
    --x-rotate-y: -30deg !important;
  }
  .rotate-y-n30-hover:hover {
    --x-rotate-y: -30deg !important;
  }
  .rotate-y-n45 {
    --x-rotate-y: -45deg !important;
  }
  .rotate-y-n45-hover:hover {
    --x-rotate-y: -45deg !important;
  }
  .rotate-y-n90 {
    --x-rotate-y: -90deg !important;
  }
  .rotate-y-n90-hover:hover {
    --x-rotate-y: -90deg !important;
  }
  .rotate-y-n180 {
    --x-rotate-y: -180deg !important;
  }
  .rotate-y-n180-hover:hover {
    --x-rotate-y: -180deg !important;
  }
  .rotate-x-0 {
    --x-rotate-x: 0deg !important;
  }
  .rotate-x-0-hover:hover {
    --x-rotate-x: 0deg !important;
  }
  .rotate-x-1 {
    --x-rotate-x: 1deg !important;
  }
  .rotate-x-1-hover:hover {
    --x-rotate-x: 1deg !important;
  }
  .rotate-x-2 {
    --x-rotate-x: 2deg !important;
  }
  .rotate-x-2-hover:hover {
    --x-rotate-x: 2deg !important;
  }
  .rotate-x-3 {
    --x-rotate-x: 3deg !important;
  }
  .rotate-x-3-hover:hover {
    --x-rotate-x: 3deg !important;
  }
  .rotate-x-6 {
    --x-rotate-x: 6deg !important;
  }
  .rotate-x-6-hover:hover {
    --x-rotate-x: 6deg !important;
  }
  .rotate-x-12 {
    --x-rotate-x: 12deg !important;
  }
  .rotate-x-12-hover:hover {
    --x-rotate-x: 12deg !important;
  }
  .rotate-x-30 {
    --x-rotate-x: 30deg !important;
  }
  .rotate-x-30-hover:hover {
    --x-rotate-x: 30deg !important;
  }
  .rotate-x-45 {
    --x-rotate-x: 45deg !important;
  }
  .rotate-x-45-hover:hover {
    --x-rotate-x: 45deg !important;
  }
  .rotate-x-90 {
    --x-rotate-x: 90deg !important;
  }
  .rotate-x-90-hover:hover {
    --x-rotate-x: 90deg !important;
  }
  .rotate-x-180 {
    --x-rotate-x: 180deg !important;
  }
  .rotate-x-180-hover:hover {
    --x-rotate-x: 180deg !important;
  }
  .rotate-x-n1 {
    --x-rotate-x: -1deg !important;
  }
  .rotate-x-n1-hover:hover {
    --x-rotate-x: -1deg !important;
  }
  .rotate-x-n2 {
    --x-rotate-x: -2deg !important;
  }
  .rotate-x-n2-hover:hover {
    --x-rotate-x: -2deg !important;
  }
  .rotate-x-n3 {
    --x-rotate-x: -3deg !important;
  }
  .rotate-x-n3-hover:hover {
    --x-rotate-x: -3deg !important;
  }
  .rotate-x-n6 {
    --x-rotate-x: -6deg !important;
  }
  .rotate-x-n6-hover:hover {
    --x-rotate-x: -6deg !important;
  }
  .rotate-x-n12 {
    --x-rotate-x: -12deg !important;
  }
  .rotate-x-n12-hover:hover {
    --x-rotate-x: -12deg !important;
  }
  .rotate-x-n30 {
    --x-rotate-x: -30deg !important;
  }
  .rotate-x-n30-hover:hover {
    --x-rotate-x: -30deg !important;
  }
  .rotate-x-n45 {
    --x-rotate-x: -45deg !important;
  }
  .rotate-x-n45-hover:hover {
    --x-rotate-x: -45deg !important;
  }
  .rotate-x-n90 {
    --x-rotate-x: -90deg !important;
  }
  .rotate-x-n90-hover:hover {
    --x-rotate-x: -90deg !important;
  }
  .rotate-x-n180 {
    --x-rotate-x: -180deg !important;
  }
  .rotate-x-n180-hover:hover {
    --x-rotate-x: -180deg !important;
  }
  .perspective-100 {
    --x-perspective: 100px !important;
  }
  .perspective-100-hover:hover {
    --x-perspective: 100px !important;
  }
  .perspective-200 {
    --x-perspective: 200px !important;
  }
  .perspective-200-hover:hover {
    --x-perspective: 200px !important;
  }
  .perspective-300 {
    --x-perspective: 300px !important;
  }
  .perspective-300-hover:hover {
    --x-perspective: 300px !important;
  }
  .perspective-400 {
    --x-perspective: 400px !important;
  }
  .perspective-400-hover:hover {
    --x-perspective: 400px !important;
  }
  .perspective-500 {
    --x-perspective: 500px !important;
  }
  .perspective-500-hover:hover {
    --x-perspective: 500px !important;
  }
  .perspective-600 {
    --x-perspective: 600px !important;
  }
  .perspective-600-hover:hover {
    --x-perspective: 600px !important;
  }
  .perspective-700 {
    --x-perspective: 700px !important;
  }
  .perspective-700-hover:hover {
    --x-perspective: 700px !important;
  }
  .perspective-800 {
    --x-perspective: 800px !important;
  }
  .perspective-800-hover:hover {
    --x-perspective: 800px !important;
  }
  .perspective-1000 {
    --x-perspective: 1000px !important;
  }
  .perspective-1000-hover:hover {
    --x-perspective: 1000px !important;
  }
  .perspective-1250 {
    --x-perspective: 1250px !important;
  }
  .perspective-1250-hover:hover {
    --x-perspective: 1250px !important;
  }
  .perspective-1500 {
    --x-perspective: 1500px !important;
  }
  .perspective-1500-hover:hover {
    --x-perspective: 1500px !important;
  }
  .translate-y-0 {
    --x-translate-y: 0 !important;
  }
  .translate-y-0-hover:hover {
    --x-translate-y: 0 !important;
  }
  .translate-y-1 {
    --x-translate-y: 0.25rem !important;
  }
  .translate-y-1-hover:hover {
    --x-translate-y: 0.25rem !important;
  }
  .translate-y-2 {
    --x-translate-y: 0.5rem !important;
  }
  .translate-y-2-hover:hover {
    --x-translate-y: 0.5rem !important;
  }
  .translate-y-3 {
    --x-translate-y: 0.75rem !important;
  }
  .translate-y-3-hover:hover {
    --x-translate-y: 0.75rem !important;
  }
  .translate-y-4 {
    --x-translate-y: 1rem !important;
  }
  .translate-y-4-hover:hover {
    --x-translate-y: 1rem !important;
  }
  .translate-y-5 {
    --x-translate-y: 1.25rem !important;
  }
  .translate-y-5-hover:hover {
    --x-translate-y: 1.25rem !important;
  }
  .translate-y-6 {
    --x-translate-y: 1.5rem !important;
  }
  .translate-y-6-hover:hover {
    --x-translate-y: 1.5rem !important;
  }
  .translate-y-7 {
    --x-translate-y: 1.75rem !important;
  }
  .translate-y-7-hover:hover {
    --x-translate-y: 1.75rem !important;
  }
  .translate-y-8 {
    --x-translate-y: 2rem !important;
  }
  .translate-y-8-hover:hover {
    --x-translate-y: 2rem !important;
  }
  .translate-y-10 {
    --x-translate-y: 2.5rem !important;
  }
  .translate-y-10-hover:hover {
    --x-translate-y: 2.5rem !important;
  }
  .translate-y-12 {
    --x-translate-y: 3rem !important;
  }
  .translate-y-12-hover:hover {
    --x-translate-y: 3rem !important;
  }
  .translate-y-14 {
    --x-translate-y: 3.5rem !important;
  }
  .translate-y-14-hover:hover {
    --x-translate-y: 3.5rem !important;
  }
  .translate-y-16 {
    --x-translate-y: 4rem !important;
  }
  .translate-y-16-hover:hover {
    --x-translate-y: 4rem !important;
  }
  .translate-y-18 {
    --x-translate-y: 4.5rem !important;
  }
  .translate-y-18-hover:hover {
    --x-translate-y: 4.5rem !important;
  }
  .translate-y-20 {
    --x-translate-y: 5rem !important;
  }
  .translate-y-20-hover:hover {
    --x-translate-y: 5rem !important;
  }
  .translate-y-24 {
    --x-translate-y: 6rem !important;
  }
  .translate-y-24-hover:hover {
    --x-translate-y: 6rem !important;
  }
  .translate-y-32 {
    --x-translate-y: 8rem !important;
  }
  .translate-y-32-hover:hover {
    --x-translate-y: 8rem !important;
  }
  .translate-y-40 {
    --x-translate-y: 10rem !important;
  }
  .translate-y-40-hover:hover {
    --x-translate-y: 10rem !important;
  }
  .translate-y-48 {
    --x-translate-y: 12rem !important;
  }
  .translate-y-48-hover:hover {
    --x-translate-y: 12rem !important;
  }
  .translate-y-56 {
    --x-translate-y: 14rem !important;
  }
  .translate-y-56-hover:hover {
    --x-translate-y: 14rem !important;
  }
  .translate-y-64 {
    --x-translate-y: 16rem !important;
  }
  .translate-y-64-hover:hover {
    --x-translate-y: 16rem !important;
  }
  .translate-y-72 {
    --x-translate-y: 18rem !important;
  }
  .translate-y-72-hover:hover {
    --x-translate-y: 18rem !important;
  }
  .translate-y-80 {
    --x-translate-y: 20rem !important;
  }
  .translate-y-80-hover:hover {
    --x-translate-y: 20rem !important;
  }
  .translate-y-88 {
    --x-translate-y: 22rem !important;
  }
  .translate-y-88-hover:hover {
    --x-translate-y: 22rem !important;
  }
  .translate-y-96 {
    --x-translate-y: 24rem !important;
  }
  .translate-y-96-hover:hover {
    --x-translate-y: 24rem !important;
  }
  .translate-y-px {
    --x-translate-y: 1px !important;
  }
  .translate-y-px-hover:hover {
    --x-translate-y: 1px !important;
  }
  .translate-y-1\/2 {
    --x-translate-y: 50% !important;
  }
  .translate-y-1\/2-hover:hover {
    --x-translate-y: 50% !important;
  }
  .translate-y-1\/3 {
    --x-translate-y: 33.333333% !important;
  }
  .translate-y-1\/3-hover:hover {
    --x-translate-y: 33.333333% !important;
  }
  .translate-y-1\/4 {
    --x-translate-y: 25% !important;
  }
  .translate-y-1\/4-hover:hover {
    --x-translate-y: 25% !important;
  }
  .translate-y-1\/5 {
    --x-translate-y: 20% !important;
  }
  .translate-y-1\/5-hover:hover {
    --x-translate-y: 20% !important;
  }
  .translate-y-2\/3 {
    --x-translate-y: 66.666667% !important;
  }
  .translate-y-2\/3-hover:hover {
    --x-translate-y: 66.666667% !important;
  }
  .translate-y-2\/5 {
    --x-translate-y: 50% !important;
  }
  .translate-y-2\/5-hover:hover {
    --x-translate-y: 50% !important;
  }
  .translate-y-3\/4 {
    --x-translate-y: 75% !important;
  }
  .translate-y-3\/4-hover:hover {
    --x-translate-y: 75% !important;
  }
  .translate-y-full {
    --x-translate-y: 100% !important;
  }
  .translate-y-full-hover:hover {
    --x-translate-y: 100% !important;
  }
  .translate-y-n1 {
    --x-translate-y: -0.25rem !important;
  }
  .translate-y-n1-hover:hover {
    --x-translate-y: -0.25rem !important;
  }
  .translate-y-n2 {
    --x-translate-y: -0.5rem !important;
  }
  .translate-y-n2-hover:hover {
    --x-translate-y: -0.5rem !important;
  }
  .translate-y-n3 {
    --x-translate-y: -0.75rem !important;
  }
  .translate-y-n3-hover:hover {
    --x-translate-y: -0.75rem !important;
  }
  .translate-y-n4 {
    --x-translate-y: -1rem !important;
  }
  .translate-y-n4-hover:hover {
    --x-translate-y: -1rem !important;
  }
  .translate-y-n5 {
    --x-translate-y: -1.25rem !important;
  }
  .translate-y-n5-hover:hover {
    --x-translate-y: -1.25rem !important;
  }
  .translate-y-n6 {
    --x-translate-y: -1.5rem !important;
  }
  .translate-y-n6-hover:hover {
    --x-translate-y: -1.5rem !important;
  }
  .translate-y-n7 {
    --x-translate-y: -1.75rem !important;
  }
  .translate-y-n7-hover:hover {
    --x-translate-y: -1.75rem !important;
  }
  .translate-y-n8 {
    --x-translate-y: -2rem !important;
  }
  .translate-y-n8-hover:hover {
    --x-translate-y: -2rem !important;
  }
  .translate-y-n10 {
    --x-translate-y: -2.5rem !important;
  }
  .translate-y-n10-hover:hover {
    --x-translate-y: -2.5rem !important;
  }
  .translate-y-n12 {
    --x-translate-y: -3rem !important;
  }
  .translate-y-n12-hover:hover {
    --x-translate-y: -3rem !important;
  }
  .translate-y-n14 {
    --x-translate-y: -3.5rem !important;
  }
  .translate-y-n14-hover:hover {
    --x-translate-y: -3.5rem !important;
  }
  .translate-y-n16 {
    --x-translate-y: -4rem !important;
  }
  .translate-y-n16-hover:hover {
    --x-translate-y: -4rem !important;
  }
  .translate-y-n18 {
    --x-translate-y: -4.5rem !important;
  }
  .translate-y-n18-hover:hover {
    --x-translate-y: -4.5rem !important;
  }
  .translate-y-n20 {
    --x-translate-y: -5rem !important;
  }
  .translate-y-n20-hover:hover {
    --x-translate-y: -5rem !important;
  }
  .translate-y-n24 {
    --x-translate-y: -6rem !important;
  }
  .translate-y-n24-hover:hover {
    --x-translate-y: -6rem !important;
  }
  .translate-y-n32 {
    --x-translate-y: -8rem !important;
  }
  .translate-y-n32-hover:hover {
    --x-translate-y: -8rem !important;
  }
  .translate-y-n40 {
    --x-translate-y: -10rem !important;
  }
  .translate-y-n40-hover:hover {
    --x-translate-y: -10rem !important;
  }
  .translate-y-n48 {
    --x-translate-y: -12rem !important;
  }
  .translate-y-n48-hover:hover {
    --x-translate-y: -12rem !important;
  }
  .translate-y-n56 {
    --x-translate-y: -14rem !important;
  }
  .translate-y-n56-hover:hover {
    --x-translate-y: -14rem !important;
  }
  .translate-y-n64 {
    --x-translate-y: -16rem !important;
  }
  .translate-y-n64-hover:hover {
    --x-translate-y: -16rem !important;
  }
  .translate-y-n72 {
    --x-translate-y: -18rem !important;
  }
  .translate-y-n72-hover:hover {
    --x-translate-y: -18rem !important;
  }
  .translate-y-n80 {
    --x-translate-y: -20rem !important;
  }
  .translate-y-n80-hover:hover {
    --x-translate-y: -20rem !important;
  }
  .translate-y-n88 {
    --x-translate-y: -22rem !important;
  }
  .translate-y-n88-hover:hover {
    --x-translate-y: -22rem !important;
  }
  .translate-y-n96 {
    --x-translate-y: -24rem !important;
  }
  .translate-y-n96-hover:hover {
    --x-translate-y: -24rem !important;
  }
  .translate-y-npx {
    --x-translate-y: -1px !important;
  }
  .translate-y-npx-hover:hover {
    --x-translate-y: -1px !important;
  }
  .translate-y-n1\/2 {
    --x-translate-y: -50% !important;
  }
  .translate-y-n1\/2-hover:hover {
    --x-translate-y: -50% !important;
  }
  .translate-y-n1\/3 {
    --x-translate-y: -33.333333% !important;
  }
  .translate-y-n1\/3-hover:hover {
    --x-translate-y: -33.333333% !important;
  }
  .translate-y-n1\/4 {
    --x-translate-y: -25% !important;
  }
  .translate-y-n1\/4-hover:hover {
    --x-translate-y: -25% !important;
  }
  .translate-y-n1\/5 {
    --x-translate-y: -20% !important;
  }
  .translate-y-n1\/5-hover:hover {
    --x-translate-y: -20% !important;
  }
  .translate-y-n2\/3 {
    --x-translate-y: -66.666667% !important;
  }
  .translate-y-n2\/3-hover:hover {
    --x-translate-y: -66.666667% !important;
  }
  .translate-y-n2\/5 {
    --x-translate-y: -50% !important;
  }
  .translate-y-n2\/5-hover:hover {
    --x-translate-y: -50% !important;
  }
  .translate-y-n3\/4 {
    --x-translate-y: -75% !important;
  }
  .translate-y-n3\/4-hover:hover {
    --x-translate-y: -75% !important;
  }
  .translate-y-nfull {
    --x-translate-y: -100% !important;
  }
  .translate-y-nfull-hover:hover {
    --x-translate-y: -100% !important;
  }
  .translate-x-0 {
    --x-translate-x: 0 !important;
  }
  .translate-x-0-hover:hover {
    --x-translate-x: 0 !important;
  }
  .translate-x-1 {
    --x-translate-x: 0.25rem !important;
  }
  .translate-x-1-hover:hover {
    --x-translate-x: 0.25rem !important;
  }
  .translate-x-2 {
    --x-translate-x: 0.5rem !important;
  }
  .translate-x-2-hover:hover {
    --x-translate-x: 0.5rem !important;
  }
  .translate-x-3 {
    --x-translate-x: 0.75rem !important;
  }
  .translate-x-3-hover:hover {
    --x-translate-x: 0.75rem !important;
  }
  .translate-x-4 {
    --x-translate-x: 1rem !important;
  }
  .translate-x-4-hover:hover {
    --x-translate-x: 1rem !important;
  }
  .translate-x-5 {
    --x-translate-x: 1.25rem !important;
  }
  .translate-x-5-hover:hover {
    --x-translate-x: 1.25rem !important;
  }
  .translate-x-6 {
    --x-translate-x: 1.5rem !important;
  }
  .translate-x-6-hover:hover {
    --x-translate-x: 1.5rem !important;
  }
  .translate-x-7 {
    --x-translate-x: 1.75rem !important;
  }
  .translate-x-7-hover:hover {
    --x-translate-x: 1.75rem !important;
  }
  .translate-x-8 {
    --x-translate-x: 2rem !important;
  }
  .translate-x-8-hover:hover {
    --x-translate-x: 2rem !important;
  }
  .translate-x-10 {
    --x-translate-x: 2.5rem !important;
  }
  .translate-x-10-hover:hover {
    --x-translate-x: 2.5rem !important;
  }
  .translate-x-12 {
    --x-translate-x: 3rem !important;
  }
  .translate-x-12-hover:hover {
    --x-translate-x: 3rem !important;
  }
  .translate-x-14 {
    --x-translate-x: 3.5rem !important;
  }
  .translate-x-14-hover:hover {
    --x-translate-x: 3.5rem !important;
  }
  .translate-x-16 {
    --x-translate-x: 4rem !important;
  }
  .translate-x-16-hover:hover {
    --x-translate-x: 4rem !important;
  }
  .translate-x-18 {
    --x-translate-x: 4.5rem !important;
  }
  .translate-x-18-hover:hover {
    --x-translate-x: 4.5rem !important;
  }
  .translate-x-20 {
    --x-translate-x: 5rem !important;
  }
  .translate-x-20-hover:hover {
    --x-translate-x: 5rem !important;
  }
  .translate-x-24 {
    --x-translate-x: 6rem !important;
  }
  .translate-x-24-hover:hover {
    --x-translate-x: 6rem !important;
  }
  .translate-x-32 {
    --x-translate-x: 8rem !important;
  }
  .translate-x-32-hover:hover {
    --x-translate-x: 8rem !important;
  }
  .translate-x-40 {
    --x-translate-x: 10rem !important;
  }
  .translate-x-40-hover:hover {
    --x-translate-x: 10rem !important;
  }
  .translate-x-48 {
    --x-translate-x: 12rem !important;
  }
  .translate-x-48-hover:hover {
    --x-translate-x: 12rem !important;
  }
  .translate-x-56 {
    --x-translate-x: 14rem !important;
  }
  .translate-x-56-hover:hover {
    --x-translate-x: 14rem !important;
  }
  .translate-x-64 {
    --x-translate-x: 16rem !important;
  }
  .translate-x-64-hover:hover {
    --x-translate-x: 16rem !important;
  }
  .translate-x-72 {
    --x-translate-x: 18rem !important;
  }
  .translate-x-72-hover:hover {
    --x-translate-x: 18rem !important;
  }
  .translate-x-80 {
    --x-translate-x: 20rem !important;
  }
  .translate-x-80-hover:hover {
    --x-translate-x: 20rem !important;
  }
  .translate-x-88 {
    --x-translate-x: 22rem !important;
  }
  .translate-x-88-hover:hover {
    --x-translate-x: 22rem !important;
  }
  .translate-x-96 {
    --x-translate-x: 24rem !important;
  }
  .translate-x-96-hover:hover {
    --x-translate-x: 24rem !important;
  }
  .translate-x-px {
    --x-translate-x: 1px !important;
  }
  .translate-x-px-hover:hover {
    --x-translate-x: 1px !important;
  }
  .translate-x-1\/2 {
    --x-translate-x: 50% !important;
  }
  .translate-x-1\/2-hover:hover {
    --x-translate-x: 50% !important;
  }
  .translate-x-1\/3 {
    --x-translate-x: 33.333333% !important;
  }
  .translate-x-1\/3-hover:hover {
    --x-translate-x: 33.333333% !important;
  }
  .translate-x-1\/4 {
    --x-translate-x: 25% !important;
  }
  .translate-x-1\/4-hover:hover {
    --x-translate-x: 25% !important;
  }
  .translate-x-1\/5 {
    --x-translate-x: 20% !important;
  }
  .translate-x-1\/5-hover:hover {
    --x-translate-x: 20% !important;
  }
  .translate-x-2\/3 {
    --x-translate-x: 66.666667% !important;
  }
  .translate-x-2\/3-hover:hover {
    --x-translate-x: 66.666667% !important;
  }
  .translate-x-2\/5 {
    --x-translate-x: 50% !important;
  }
  .translate-x-2\/5-hover:hover {
    --x-translate-x: 50% !important;
  }
  .translate-x-3\/4 {
    --x-translate-x: 75% !important;
  }
  .translate-x-3\/4-hover:hover {
    --x-translate-x: 75% !important;
  }
  .translate-x-full {
    --x-translate-x: 100% !important;
  }
  .translate-x-full-hover:hover {
    --x-translate-x: 100% !important;
  }
  .translate-x-n1 {
    --x-translate-x: -0.25rem !important;
  }
  .translate-x-n1-hover:hover {
    --x-translate-x: -0.25rem !important;
  }
  .translate-x-n2 {
    --x-translate-x: -0.5rem !important;
  }
  .translate-x-n2-hover:hover {
    --x-translate-x: -0.5rem !important;
  }
  .translate-x-n3 {
    --x-translate-x: -0.75rem !important;
  }
  .translate-x-n3-hover:hover {
    --x-translate-x: -0.75rem !important;
  }
  .translate-x-n4 {
    --x-translate-x: -1rem !important;
  }
  .translate-x-n4-hover:hover {
    --x-translate-x: -1rem !important;
  }
  .translate-x-n5 {
    --x-translate-x: -1.25rem !important;
  }
  .translate-x-n5-hover:hover {
    --x-translate-x: -1.25rem !important;
  }
  .translate-x-n6 {
    --x-translate-x: -1.5rem !important;
  }
  .translate-x-n6-hover:hover {
    --x-translate-x: -1.5rem !important;
  }
  .translate-x-n7 {
    --x-translate-x: -1.75rem !important;
  }
  .translate-x-n7-hover:hover {
    --x-translate-x: -1.75rem !important;
  }
  .translate-x-n8 {
    --x-translate-x: -2rem !important;
  }
  .translate-x-n8-hover:hover {
    --x-translate-x: -2rem !important;
  }
  .translate-x-n10 {
    --x-translate-x: -2.5rem !important;
  }
  .translate-x-n10-hover:hover {
    --x-translate-x: -2.5rem !important;
  }
  .translate-x-n12 {
    --x-translate-x: -3rem !important;
  }
  .translate-x-n12-hover:hover {
    --x-translate-x: -3rem !important;
  }
  .translate-x-n14 {
    --x-translate-x: -3.5rem !important;
  }
  .translate-x-n14-hover:hover {
    --x-translate-x: -3.5rem !important;
  }
  .translate-x-n16 {
    --x-translate-x: -4rem !important;
  }
  .translate-x-n16-hover:hover {
    --x-translate-x: -4rem !important;
  }
  .translate-x-n18 {
    --x-translate-x: -4.5rem !important;
  }
  .translate-x-n18-hover:hover {
    --x-translate-x: -4.5rem !important;
  }
  .translate-x-n20 {
    --x-translate-x: -5rem !important;
  }
  .translate-x-n20-hover:hover {
    --x-translate-x: -5rem !important;
  }
  .translate-x-n24 {
    --x-translate-x: -6rem !important;
  }
  .translate-x-n24-hover:hover {
    --x-translate-x: -6rem !important;
  }
  .translate-x-n32 {
    --x-translate-x: -8rem !important;
  }
  .translate-x-n32-hover:hover {
    --x-translate-x: -8rem !important;
  }
  .translate-x-n40 {
    --x-translate-x: -10rem !important;
  }
  .translate-x-n40-hover:hover {
    --x-translate-x: -10rem !important;
  }
  .translate-x-n48 {
    --x-translate-x: -12rem !important;
  }
  .translate-x-n48-hover:hover {
    --x-translate-x: -12rem !important;
  }
  .translate-x-n56 {
    --x-translate-x: -14rem !important;
  }
  .translate-x-n56-hover:hover {
    --x-translate-x: -14rem !important;
  }
  .translate-x-n64 {
    --x-translate-x: -16rem !important;
  }
  .translate-x-n64-hover:hover {
    --x-translate-x: -16rem !important;
  }
  .translate-x-n72 {
    --x-translate-x: -18rem !important;
  }
  .translate-x-n72-hover:hover {
    --x-translate-x: -18rem !important;
  }
  .translate-x-n80 {
    --x-translate-x: -20rem !important;
  }
  .translate-x-n80-hover:hover {
    --x-translate-x: -20rem !important;
  }
  .translate-x-n88 {
    --x-translate-x: -22rem !important;
  }
  .translate-x-n88-hover:hover {
    --x-translate-x: -22rem !important;
  }
  .translate-x-n96 {
    --x-translate-x: -24rem !important;
  }
  .translate-x-n96-hover:hover {
    --x-translate-x: -24rem !important;
  }
  .translate-x-npx {
    --x-translate-x: -1px !important;
  }
  .translate-x-npx-hover:hover {
    --x-translate-x: -1px !important;
  }
  .translate-x-n1\/2 {
    --x-translate-x: -50% !important;
  }
  .translate-x-n1\/2-hover:hover {
    --x-translate-x: -50% !important;
  }
  .translate-x-n1\/3 {
    --x-translate-x: -33.333333% !important;
  }
  .translate-x-n1\/3-hover:hover {
    --x-translate-x: -33.333333% !important;
  }
  .translate-x-n1\/4 {
    --x-translate-x: -25% !important;
  }
  .translate-x-n1\/4-hover:hover {
    --x-translate-x: -25% !important;
  }
  .translate-x-n1\/5 {
    --x-translate-x: -20% !important;
  }
  .translate-x-n1\/5-hover:hover {
    --x-translate-x: -20% !important;
  }
  .translate-x-n2\/3 {
    --x-translate-x: -66.666667% !important;
  }
  .translate-x-n2\/3-hover:hover {
    --x-translate-x: -66.666667% !important;
  }
  .translate-x-n2\/5 {
    --x-translate-x: -50% !important;
  }
  .translate-x-n2\/5-hover:hover {
    --x-translate-x: -50% !important;
  }
  .translate-x-n3\/4 {
    --x-translate-x: -75% !important;
  }
  .translate-x-n3\/4-hover:hover {
    --x-translate-x: -75% !important;
  }
  .translate-x-nfull {
    --x-translate-x: -100% !important;
  }
  .translate-x-nfull-hover:hover {
    --x-translate-x: -100% !important;
  }
  .skew-y-0 {
    --x-skew-y: 0deg !important;
  }
  .skew-y-0-hover:hover {
    --x-skew-y: 0deg !important;
  }
  .skew-y-1 {
    --x-skew-y: 1deg !important;
  }
  .skew-y-1-hover:hover {
    --x-skew-y: 1deg !important;
  }
  .skew-y-2 {
    --x-skew-y: 2deg !important;
  }
  .skew-y-2-hover:hover {
    --x-skew-y: 2deg !important;
  }
  .skew-y-3 {
    --x-skew-y: 3deg !important;
  }
  .skew-y-3-hover:hover {
    --x-skew-y: 3deg !important;
  }
  .skew-y-6 {
    --x-skew-y: 6deg !important;
  }
  .skew-y-6-hover:hover {
    --x-skew-y: 6deg !important;
  }
  .skew-y-12 {
    --x-skew-y: 12deg !important;
  }
  .skew-y-12-hover:hover {
    --x-skew-y: 12deg !important;
  }
  .skew-y-n1 {
    --x-skew-y: -1deg !important;
  }
  .skew-y-n1-hover:hover {
    --x-skew-y: -1deg !important;
  }
  .skew-y-n2 {
    --x-skew-y: -2deg !important;
  }
  .skew-y-n2-hover:hover {
    --x-skew-y: -2deg !important;
  }
  .skew-y-n3 {
    --x-skew-y: -3deg !important;
  }
  .skew-y-n3-hover:hover {
    --x-skew-y: -3deg !important;
  }
  .skew-y-n6 {
    --x-skew-y: -6deg !important;
  }
  .skew-y-n6-hover:hover {
    --x-skew-y: -6deg !important;
  }
  .skew-y-n12 {
    --x-skew-y: -12deg !important;
  }
  .skew-y-n12-hover:hover {
    --x-skew-y: -12deg !important;
  }
  .skew-x-0 {
    --x-skew-x: 0deg !important;
  }
  .skew-x-0-hover:hover {
    --x-skew-x: 0deg !important;
  }
  .skew-x-1 {
    --x-skew-x: 1deg !important;
  }
  .skew-x-1-hover:hover {
    --x-skew-x: 1deg !important;
  }
  .skew-x-2 {
    --x-skew-x: 2deg !important;
  }
  .skew-x-2-hover:hover {
    --x-skew-x: 2deg !important;
  }
  .skew-x-3 {
    --x-skew-x: 3deg !important;
  }
  .skew-x-3-hover:hover {
    --x-skew-x: 3deg !important;
  }
  .skew-x-6 {
    --x-skew-x: 6deg !important;
  }
  .skew-x-6-hover:hover {
    --x-skew-x: 6deg !important;
  }
  .skew-x-12 {
    --x-skew-x: 12deg !important;
  }
  .skew-x-12-hover:hover {
    --x-skew-x: 12deg !important;
  }
  .skew-x-n1 {
    --x-skew-x: -1deg !important;
  }
  .skew-x-n1-hover:hover {
    --x-skew-x: -1deg !important;
  }
  .skew-x-n2 {
    --x-skew-x: -2deg !important;
  }
  .skew-x-n2-hover:hover {
    --x-skew-x: -2deg !important;
  }
  .skew-x-n3 {
    --x-skew-x: -3deg !important;
  }
  .skew-x-n3-hover:hover {
    --x-skew-x: -3deg !important;
  }
  .skew-x-n6 {
    --x-skew-x: -6deg !important;
  }
  .skew-x-n6-hover:hover {
    --x-skew-x: -6deg !important;
  }
  .skew-x-n12 {
    --x-skew-x: -12deg !important;
  }
  .skew-x-n12-hover:hover {
    --x-skew-x: -12deg !important;
  }
  @media (min-width: 576px) {
    .float-sm-start {
      float: left !important;
    }
    .float-sm-end {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
    .opacity-sm-0 {
      opacity: 0 !important;
    }
    .opacity-sm-0-hover:hover {
      opacity: 0 !important;
    }
    .opacity-sm-10 {
      opacity: 0.1 !important;
    }
    .opacity-sm-10-hover:hover {
      opacity: 0.1 !important;
    }
    .opacity-sm-20 {
      opacity: 0.2 !important;
    }
    .opacity-sm-20-hover:hover {
      opacity: 0.2 !important;
    }
    .opacity-sm-30 {
      opacity: 0.3 !important;
    }
    .opacity-sm-30-hover:hover {
      opacity: 0.3 !important;
    }
    .opacity-sm-40 {
      opacity: 0.4 !important;
    }
    .opacity-sm-40-hover:hover {
      opacity: 0.4 !important;
    }
    .opacity-sm-50 {
      opacity: 0.5 !important;
    }
    .opacity-sm-50-hover:hover {
      opacity: 0.5 !important;
    }
    .opacity-sm-60 {
      opacity: 0.6 !important;
    }
    .opacity-sm-60-hover:hover {
      opacity: 0.6 !important;
    }
    .opacity-sm-70 {
      opacity: 0.7 !important;
    }
    .opacity-sm-70-hover:hover {
      opacity: 0.7 !important;
    }
    .opacity-sm-80 {
      opacity: 0.8 !important;
    }
    .opacity-sm-80-hover:hover {
      opacity: 0.8 !important;
    }
    .opacity-sm-90 {
      opacity: 0.9 !important;
    }
    .opacity-sm-90-hover:hover {
      opacity: 0.9 !important;
    }
    .opacity-sm-100 {
      opacity: 1 !important;
    }
    .opacity-sm-100-hover:hover {
      opacity: 1 !important;
    }
    .overflow-sm-auto {
      overflow: auto !important;
    }
    .overflow-sm-hidden {
      overflow: hidden !important;
    }
    .overflow-sm-visible {
      overflow: visible !important;
    }
    .overflow-sm-scroll {
      overflow: scroll !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-grid {
      display: grid !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
    .d-sm-none {
      display: none !important;
    }
    .shadow-sm {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-hover:hover {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-1 {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-1-hover:hover {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-2 {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-2-hover:hover {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-3 {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-3-hover:hover {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-4 {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-4-hover:hover {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-5 {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-5-hover:hover {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-6 {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-6-hover:hover {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-sm-inset {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-sm-inset-hover:hover {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-sm-outline {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-sm-outline-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-sm-focus {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-sm-focus-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-sm-none {
      box-shadow: none !important;
    }
    .shadow-sm-none-hover:hover {
      box-shadow: none !important;
    }
    .position-sm-static {
      position: static !important;
    }
    .position-sm-relative {
      position: relative !important;
    }
    .position-sm-absolute {
      position: absolute !important;
    }
    .position-sm-fixed {
      position: fixed !important;
    }
    .position-sm-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .top-sm-0 {
      top: 0 !important;
    }
    .top-sm-1 {
      top: 0.25rem !important;
    }
    .top-sm-2 {
      top: 0.5rem !important;
    }
    .top-sm-3 {
      top: 0.75rem !important;
    }
    .top-sm-4 {
      top: 1rem !important;
    }
    .top-sm-5 {
      top: 1.25rem !important;
    }
    .top-sm-6 {
      top: 1.5rem !important;
    }
    .top-sm-7 {
      top: 1.75rem !important;
    }
    .top-sm-8 {
      top: 2rem !important;
    }
    .top-sm-10 {
      top: 2.5rem !important;
    }
    .top-sm-12 {
      top: 3rem !important;
    }
    .top-sm-14 {
      top: 3.5rem !important;
    }
    .top-sm-16 {
      top: 4rem !important;
    }
    .top-sm-18 {
      top: 4.5rem !important;
    }
    .top-sm-20 {
      top: 5rem !important;
    }
    .top-sm-24 {
      top: 6rem !important;
    }
    .top-sm-32 {
      top: 8rem !important;
    }
    .top-sm-40 {
      top: 10rem !important;
    }
    .top-sm-48 {
      top: 12rem !important;
    }
    .top-sm-56 {
      top: 14rem !important;
    }
    .top-sm-64 {
      top: 16rem !important;
    }
    .top-sm-72 {
      top: 18rem !important;
    }
    .top-sm-80 {
      top: 20rem !important;
    }
    .top-sm-88 {
      top: 22rem !important;
    }
    .top-sm-96 {
      top: 24rem !important;
    }
    .top-sm-px {
      top: 1px !important;
    }
    .top-sm-1\/2 {
      top: 50% !important;
    }
    .top-sm-full {
      top: 100% !important;
    }
    .top-sm-auto {
      top: auto !important;
    }
    .bottom-sm-0 {
      bottom: 0 !important;
    }
    .bottom-sm-1 {
      bottom: 0.25rem !important;
    }
    .bottom-sm-2 {
      bottom: 0.5rem !important;
    }
    .bottom-sm-3 {
      bottom: 0.75rem !important;
    }
    .bottom-sm-4 {
      bottom: 1rem !important;
    }
    .bottom-sm-5 {
      bottom: 1.25rem !important;
    }
    .bottom-sm-6 {
      bottom: 1.5rem !important;
    }
    .bottom-sm-7 {
      bottom: 1.75rem !important;
    }
    .bottom-sm-8 {
      bottom: 2rem !important;
    }
    .bottom-sm-10 {
      bottom: 2.5rem !important;
    }
    .bottom-sm-12 {
      bottom: 3rem !important;
    }
    .bottom-sm-14 {
      bottom: 3.5rem !important;
    }
    .bottom-sm-16 {
      bottom: 4rem !important;
    }
    .bottom-sm-18 {
      bottom: 4.5rem !important;
    }
    .bottom-sm-20 {
      bottom: 5rem !important;
    }
    .bottom-sm-24 {
      bottom: 6rem !important;
    }
    .bottom-sm-32 {
      bottom: 8rem !important;
    }
    .bottom-sm-40 {
      bottom: 10rem !important;
    }
    .bottom-sm-48 {
      bottom: 12rem !important;
    }
    .bottom-sm-56 {
      bottom: 14rem !important;
    }
    .bottom-sm-64 {
      bottom: 16rem !important;
    }
    .bottom-sm-72 {
      bottom: 18rem !important;
    }
    .bottom-sm-80 {
      bottom: 20rem !important;
    }
    .bottom-sm-88 {
      bottom: 22rem !important;
    }
    .bottom-sm-96 {
      bottom: 24rem !important;
    }
    .bottom-sm-px {
      bottom: 1px !important;
    }
    .bottom-sm-1\/2 {
      bottom: 50% !important;
    }
    .bottom-sm-full {
      bottom: 100% !important;
    }
    .bottom-sm-auto {
      bottom: auto !important;
    }
    .start-sm-0 {
      left: 0 !important;
    }
    .start-sm-1 {
      left: 0.25rem !important;
    }
    .start-sm-2 {
      left: 0.5rem !important;
    }
    .start-sm-3 {
      left: 0.75rem !important;
    }
    .start-sm-4 {
      left: 1rem !important;
    }
    .start-sm-5 {
      left: 1.25rem !important;
    }
    .start-sm-6 {
      left: 1.5rem !important;
    }
    .start-sm-7 {
      left: 1.75rem !important;
    }
    .start-sm-8 {
      left: 2rem !important;
    }
    .start-sm-10 {
      left: 2.5rem !important;
    }
    .start-sm-12 {
      left: 3rem !important;
    }
    .start-sm-14 {
      left: 3.5rem !important;
    }
    .start-sm-16 {
      left: 4rem !important;
    }
    .start-sm-18 {
      left: 4.5rem !important;
    }
    .start-sm-20 {
      left: 5rem !important;
    }
    .start-sm-24 {
      left: 6rem !important;
    }
    .start-sm-32 {
      left: 8rem !important;
    }
    .start-sm-40 {
      left: 10rem !important;
    }
    .start-sm-48 {
      left: 12rem !important;
    }
    .start-sm-56 {
      left: 14rem !important;
    }
    .start-sm-64 {
      left: 16rem !important;
    }
    .start-sm-72 {
      left: 18rem !important;
    }
    .start-sm-80 {
      left: 20rem !important;
    }
    .start-sm-88 {
      left: 22rem !important;
    }
    .start-sm-96 {
      left: 24rem !important;
    }
    .start-sm-px {
      left: 1px !important;
    }
    .start-sm-1\/2 {
      left: 50% !important;
    }
    .start-sm-full {
      left: 100% !important;
    }
    .start-sm-auto {
      left: auto !important;
    }
    .end-sm-0 {
      right: 0 !important;
    }
    .end-sm-1 {
      right: 0.25rem !important;
    }
    .end-sm-2 {
      right: 0.5rem !important;
    }
    .end-sm-3 {
      right: 0.75rem !important;
    }
    .end-sm-4 {
      right: 1rem !important;
    }
    .end-sm-5 {
      right: 1.25rem !important;
    }
    .end-sm-6 {
      right: 1.5rem !important;
    }
    .end-sm-7 {
      right: 1.75rem !important;
    }
    .end-sm-8 {
      right: 2rem !important;
    }
    .end-sm-10 {
      right: 2.5rem !important;
    }
    .end-sm-12 {
      right: 3rem !important;
    }
    .end-sm-14 {
      right: 3.5rem !important;
    }
    .end-sm-16 {
      right: 4rem !important;
    }
    .end-sm-18 {
      right: 4.5rem !important;
    }
    .end-sm-20 {
      right: 5rem !important;
    }
    .end-sm-24 {
      right: 6rem !important;
    }
    .end-sm-32 {
      right: 8rem !important;
    }
    .end-sm-40 {
      right: 10rem !important;
    }
    .end-sm-48 {
      right: 12rem !important;
    }
    .end-sm-56 {
      right: 14rem !important;
    }
    .end-sm-64 {
      right: 16rem !important;
    }
    .end-sm-72 {
      right: 18rem !important;
    }
    .end-sm-80 {
      right: 20rem !important;
    }
    .end-sm-88 {
      right: 22rem !important;
    }
    .end-sm-96 {
      right: 24rem !important;
    }
    .end-sm-px {
      right: 1px !important;
    }
    .end-sm-1\/2 {
      right: 50% !important;
    }
    .end-sm-full {
      right: 100% !important;
    }
    .end-sm-auto {
      right: auto !important;
    }
    .border-top-sm-0 {
      border-top-width: 0 !important;
    }
    .border-top-sm-0-hover:hover {
      border-top-width: 0 !important;
    }
    .border-top-sm-0-focus:focus {
      border-top-width: 0 !important;
    }
    .border-top-sm {
      border-top-width: 1px !important;
    }
    .border-top-sm-hover:hover {
      border-top-width: 1px !important;
    }
    .border-top-sm-focus:focus {
      border-top-width: 1px !important;
    }
    .border-top-sm-2 {
      border-top-width: 2px !important;
    }
    .border-top-sm-2-hover:hover {
      border-top-width: 2px !important;
    }
    .border-top-sm-2-focus:focus {
      border-top-width: 2px !important;
    }
    .border-top-sm-3 {
      border-top-width: 3px !important;
    }
    .border-top-sm-3-hover:hover {
      border-top-width: 3px !important;
    }
    .border-top-sm-3-focus:focus {
      border-top-width: 3px !important;
    }
    .border-top-sm-4 {
      border-top-width: 4px !important;
    }
    .border-top-sm-4-hover:hover {
      border-top-width: 4px !important;
    }
    .border-top-sm-4-focus:focus {
      border-top-width: 4px !important;
    }
    .border-top-sm-5 {
      border-top-width: 5px !important;
    }
    .border-top-sm-5-hover:hover {
      border-top-width: 5px !important;
    }
    .border-top-sm-5-focus:focus {
      border-top-width: 5px !important;
    }
    .border-top-sm-6 {
      border-top-width: 6px !important;
    }
    .border-top-sm-6-hover:hover {
      border-top-width: 6px !important;
    }
    .border-top-sm-6-focus:focus {
      border-top-width: 6px !important;
    }
    .border-top-sm-7 {
      border-top-width: 7px !important;
    }
    .border-top-sm-7-hover:hover {
      border-top-width: 7px !important;
    }
    .border-top-sm-7-focus:focus {
      border-top-width: 7px !important;
    }
    .border-top-sm-8 {
      border-top-width: 8px !important;
    }
    .border-top-sm-8-hover:hover {
      border-top-width: 8px !important;
    }
    .border-top-sm-8-focus:focus {
      border-top-width: 8px !important;
    }
    .border-end-sm-0 {
      border-right-width: 0 !important;
    }
    .border-end-sm-0-hover:hover {
      border-right-width: 0 !important;
    }
    .border-end-sm-0-focus:focus {
      border-right-width: 0 !important;
    }
    .border-end-sm {
      border-right-width: 1px !important;
    }
    .border-end-sm-hover:hover {
      border-right-width: 1px !important;
    }
    .border-end-sm-focus:focus {
      border-right-width: 1px !important;
    }
    .border-end-sm-2 {
      border-right-width: 2px !important;
    }
    .border-end-sm-2-hover:hover {
      border-right-width: 2px !important;
    }
    .border-end-sm-2-focus:focus {
      border-right-width: 2px !important;
    }
    .border-end-sm-3 {
      border-right-width: 3px !important;
    }
    .border-end-sm-3-hover:hover {
      border-right-width: 3px !important;
    }
    .border-end-sm-3-focus:focus {
      border-right-width: 3px !important;
    }
    .border-end-sm-4 {
      border-right-width: 4px !important;
    }
    .border-end-sm-4-hover:hover {
      border-right-width: 4px !important;
    }
    .border-end-sm-4-focus:focus {
      border-right-width: 4px !important;
    }
    .border-end-sm-5 {
      border-right-width: 5px !important;
    }
    .border-end-sm-5-hover:hover {
      border-right-width: 5px !important;
    }
    .border-end-sm-5-focus:focus {
      border-right-width: 5px !important;
    }
    .border-end-sm-6 {
      border-right-width: 6px !important;
    }
    .border-end-sm-6-hover:hover {
      border-right-width: 6px !important;
    }
    .border-end-sm-6-focus:focus {
      border-right-width: 6px !important;
    }
    .border-end-sm-7 {
      border-right-width: 7px !important;
    }
    .border-end-sm-7-hover:hover {
      border-right-width: 7px !important;
    }
    .border-end-sm-7-focus:focus {
      border-right-width: 7px !important;
    }
    .border-end-sm-8 {
      border-right-width: 8px !important;
    }
    .border-end-sm-8-hover:hover {
      border-right-width: 8px !important;
    }
    .border-end-sm-8-focus:focus {
      border-right-width: 8px !important;
    }
    .border-bottom-sm-0 {
      border-bottom-width: 0 !important;
    }
    .border-bottom-sm-0-hover:hover {
      border-bottom-width: 0 !important;
    }
    .border-bottom-sm-0-focus:focus {
      border-bottom-width: 0 !important;
    }
    .border-bottom-sm {
      border-bottom-width: 1px !important;
    }
    .border-bottom-sm-hover:hover {
      border-bottom-width: 1px !important;
    }
    .border-bottom-sm-focus:focus {
      border-bottom-width: 1px !important;
    }
    .border-bottom-sm-2 {
      border-bottom-width: 2px !important;
    }
    .border-bottom-sm-2-hover:hover {
      border-bottom-width: 2px !important;
    }
    .border-bottom-sm-2-focus:focus {
      border-bottom-width: 2px !important;
    }
    .border-bottom-sm-3 {
      border-bottom-width: 3px !important;
    }
    .border-bottom-sm-3-hover:hover {
      border-bottom-width: 3px !important;
    }
    .border-bottom-sm-3-focus:focus {
      border-bottom-width: 3px !important;
    }
    .border-bottom-sm-4 {
      border-bottom-width: 4px !important;
    }
    .border-bottom-sm-4-hover:hover {
      border-bottom-width: 4px !important;
    }
    .border-bottom-sm-4-focus:focus {
      border-bottom-width: 4px !important;
    }
    .border-bottom-sm-5 {
      border-bottom-width: 5px !important;
    }
    .border-bottom-sm-5-hover:hover {
      border-bottom-width: 5px !important;
    }
    .border-bottom-sm-5-focus:focus {
      border-bottom-width: 5px !important;
    }
    .border-bottom-sm-6 {
      border-bottom-width: 6px !important;
    }
    .border-bottom-sm-6-hover:hover {
      border-bottom-width: 6px !important;
    }
    .border-bottom-sm-6-focus:focus {
      border-bottom-width: 6px !important;
    }
    .border-bottom-sm-7 {
      border-bottom-width: 7px !important;
    }
    .border-bottom-sm-7-hover:hover {
      border-bottom-width: 7px !important;
    }
    .border-bottom-sm-7-focus:focus {
      border-bottom-width: 7px !important;
    }
    .border-bottom-sm-8 {
      border-bottom-width: 8px !important;
    }
    .border-bottom-sm-8-hover:hover {
      border-bottom-width: 8px !important;
    }
    .border-bottom-sm-8-focus:focus {
      border-bottom-width: 8px !important;
    }
    .border-start-sm-0 {
      border-left-width: 0 !important;
    }
    .border-start-sm-0-hover:hover {
      border-left-width: 0 !important;
    }
    .border-start-sm-0-focus:focus {
      border-left-width: 0 !important;
    }
    .border-start-sm {
      border-left-width: 1px !important;
    }
    .border-start-sm-hover:hover {
      border-left-width: 1px !important;
    }
    .border-start-sm-focus:focus {
      border-left-width: 1px !important;
    }
    .border-start-sm-2 {
      border-left-width: 2px !important;
    }
    .border-start-sm-2-hover:hover {
      border-left-width: 2px !important;
    }
    .border-start-sm-2-focus:focus {
      border-left-width: 2px !important;
    }
    .border-start-sm-3 {
      border-left-width: 3px !important;
    }
    .border-start-sm-3-hover:hover {
      border-left-width: 3px !important;
    }
    .border-start-sm-3-focus:focus {
      border-left-width: 3px !important;
    }
    .border-start-sm-4 {
      border-left-width: 4px !important;
    }
    .border-start-sm-4-hover:hover {
      border-left-width: 4px !important;
    }
    .border-start-sm-4-focus:focus {
      border-left-width: 4px !important;
    }
    .border-start-sm-5 {
      border-left-width: 5px !important;
    }
    .border-start-sm-5-hover:hover {
      border-left-width: 5px !important;
    }
    .border-start-sm-5-focus:focus {
      border-left-width: 5px !important;
    }
    .border-start-sm-6 {
      border-left-width: 6px !important;
    }
    .border-start-sm-6-hover:hover {
      border-left-width: 6px !important;
    }
    .border-start-sm-6-focus:focus {
      border-left-width: 6px !important;
    }
    .border-start-sm-7 {
      border-left-width: 7px !important;
    }
    .border-start-sm-7-hover:hover {
      border-left-width: 7px !important;
    }
    .border-start-sm-7-focus:focus {
      border-left-width: 7px !important;
    }
    .border-start-sm-8 {
      border-left-width: 8px !important;
    }
    .border-start-sm-8-hover:hover {
      border-left-width: 8px !important;
    }
    .border-start-sm-8-focus:focus {
      border-left-width: 8px !important;
    }
    .border-sm-primary {
      border-color: #4066D5 !important;
    }
    .border-sm-primary-hover:hover {
      border-color: #4066D5 !important;
    }
    .border-sm-primary-focus:focus {
      border-color: #4066D5 !important;
    }
    .border-sm-primary-focus-within:focus-within {
      border-color: #4066D5 !important;
    }
    .border-sm-secondary {
      border-color: #cfd6df !important;
    }
    .border-sm-secondary-hover:hover {
      border-color: #cfd6df !important;
    }
    .border-sm-secondary-focus:focus {
      border-color: #cfd6df !important;
    }
    .border-sm-secondary-focus-within:focus-within {
      border-color: #cfd6df !important;
    }
    .border-sm-tertiary {
      border-color: #ff579a !important;
    }
    .border-sm-tertiary-hover:hover {
      border-color: #ff579a !important;
    }
    .border-sm-tertiary-focus:focus {
      border-color: #ff579a !important;
    }
    .border-sm-tertiary-focus-within:focus-within {
      border-color: #ff579a !important;
    }
    .border-sm-success {
      border-color: #0c8 !important;
    }
    .border-sm-success-hover:hover {
      border-color: #0c8 !important;
    }
    .border-sm-success-focus:focus {
      border-color: #0c8 !important;
    }
    .border-sm-success-focus-within:focus-within {
      border-color: #0c8 !important;
    }
    .border-sm-info {
      border-color: #00d4ff !important;
    }
    .border-sm-info-hover:hover {
      border-color: #00d4ff !important;
    }
    .border-sm-info-focus:focus {
      border-color: #00d4ff !important;
    }
    .border-sm-info-focus-within:focus-within {
      border-color: #00d4ff !important;
    }
    .border-sm-warning {
      border-color: #ff8c00 !important;
    }
    .border-sm-warning-hover:hover {
      border-color: #ff8c00 !important;
    }
    .border-sm-warning-focus:focus {
      border-color: #ff8c00 !important;
    }
    .border-sm-warning-focus-within:focus-within {
      border-color: #ff8c00 !important;
    }
    .border-sm-danger {
      border-color: #f36 !important;
    }
    .border-sm-danger-hover:hover {
      border-color: #f36 !important;
    }
    .border-sm-danger-focus:focus {
      border-color: #f36 !important;
    }
    .border-sm-danger-focus-within:focus-within {
      border-color: #f36 !important;
    }
    .border-sm-white {
      border-color: #fff !important;
    }
    .border-sm-white-hover:hover {
      border-color: #fff !important;
    }
    .border-sm-white-focus:focus {
      border-color: #fff !important;
    }
    .border-sm-white-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-sm-light {
      border-color: #e7eaf0 !important;
    }
    .border-sm-light-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-sm-light-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-sm-light-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-sm-dark {
      border-color: #16192c !important;
    }
    .border-sm-dark-hover:hover {
      border-color: #16192c !important;
    }
    .border-sm-dark-focus:focus {
      border-color: #16192c !important;
    }
    .border-sm-dark-focus-within:focus-within {
      border-color: #16192c !important;
    }
    .border-sm-transparent {
      border-color: transparent !important;
    }
    .border-sm-transparent-hover:hover {
      border-color: transparent !important;
    }
    .border-sm-transparent-focus:focus {
      border-color: transparent !important;
    }
    .border-sm-transparent-focus-within:focus-within {
      border-color: transparent !important;
    }
    .border-sm-current {
      border-color: currentColor !important;
    }
    .border-sm-current-hover:hover {
      border-color: currentColor !important;
    }
    .border-sm-current-focus:focus {
      border-color: currentColor !important;
    }
    .border-sm-current-focus-within:focus-within {
      border-color: currentColor !important;
    }
    .border-sm-base {
      border-color: #e7eaf0 !important;
    }
    .border-sm-base-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-sm-base-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-sm-base-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-sm-body {
      border-color: #fff !important;
    }
    .border-sm-body-hover:hover {
      border-color: #fff !important;
    }
    .border-sm-body-focus:focus {
      border-color: #fff !important;
    }
    .border-sm-body-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-sm-card {
      border-color: #fff !important;
    }
    .border-sm-card-hover:hover {
      border-color: #fff !important;
    }
    .border-sm-card-focus:focus {
      border-color: #fff !important;
    }
    .border-sm-card-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-sm-black {
      border-color: #000 !important;
    }
    .border-sm-black-hover:hover {
      border-color: #000 !important;
    }
    .border-sm-black-focus:focus {
      border-color: #000 !important;
    }
    .border-sm-black-focus-within:focus-within {
      border-color: #000 !important;
    }
    .border-sm-0 {
      border-width: 0 !important;
    }
    .border-sm-0-hover:hover {
      border-width: 0 !important;
    }
    .border-sm-0-focus:focus {
      border-width: 0 !important;
    }
    .border-sm {
      border-width: 1px !important;
    }
    .border-sm-hover:hover {
      border-width: 1px !important;
    }
    .border-sm-focus:focus {
      border-width: 1px !important;
    }
    .border-sm-2 {
      border-width: 2px !important;
    }
    .border-sm-2-hover:hover {
      border-width: 2px !important;
    }
    .border-sm-2-focus:focus {
      border-width: 2px !important;
    }
    .border-sm-3 {
      border-width: 3px !important;
    }
    .border-sm-3-hover:hover {
      border-width: 3px !important;
    }
    .border-sm-3-focus:focus {
      border-width: 3px !important;
    }
    .border-sm-4 {
      border-width: 4px !important;
    }
    .border-sm-4-hover:hover {
      border-width: 4px !important;
    }
    .border-sm-4-focus:focus {
      border-width: 4px !important;
    }
    .border-sm-5 {
      border-width: 5px !important;
    }
    .border-sm-5-hover:hover {
      border-width: 5px !important;
    }
    .border-sm-5-focus:focus {
      border-width: 5px !important;
    }
    .border-sm-6 {
      border-width: 6px !important;
    }
    .border-sm-6-hover:hover {
      border-width: 6px !important;
    }
    .border-sm-6-focus:focus {
      border-width: 6px !important;
    }
    .border-sm-7 {
      border-width: 7px !important;
    }
    .border-sm-7-hover:hover {
      border-width: 7px !important;
    }
    .border-sm-7-focus:focus {
      border-width: 7px !important;
    }
    .border-sm-8 {
      border-width: 8px !important;
    }
    .border-sm-8-hover:hover {
      border-width: 8px !important;
    }
    .border-sm-8-focus:focus {
      border-width: 8px !important;
    }
    .w-sm-0 {
      width: 0 !important;
    }
    .w-sm-1 {
      width: 0.25rem !important;
    }
    .w-sm-2 {
      width: 0.5rem !important;
    }
    .w-sm-3 {
      width: 0.75rem !important;
    }
    .w-sm-4 {
      width: 1rem !important;
    }
    .w-sm-5 {
      width: 1.25rem !important;
    }
    .w-sm-6 {
      width: 1.5rem !important;
    }
    .w-sm-7 {
      width: 1.75rem !important;
    }
    .w-sm-8 {
      width: 2rem !important;
    }
    .w-sm-10 {
      width: 2.5rem !important;
    }
    .w-sm-12 {
      width: 3rem !important;
    }
    .w-sm-14 {
      width: 3.5rem !important;
    }
    .w-sm-16 {
      width: 4rem !important;
    }
    .w-sm-18 {
      width: 4.5rem !important;
    }
    .w-sm-20 {
      width: 5rem !important;
    }
    .w-sm-24 {
      width: 6rem !important;
    }
    .w-sm-32 {
      width: 8rem !important;
    }
    .w-sm-40 {
      width: 10rem !important;
    }
    .w-sm-48 {
      width: 12rem !important;
    }
    .w-sm-56 {
      width: 14rem !important;
    }
    .w-sm-64 {
      width: 16rem !important;
    }
    .w-sm-72 {
      width: 18rem !important;
    }
    .w-sm-80 {
      width: 20rem !important;
    }
    .w-sm-88 {
      width: 22rem !important;
    }
    .w-sm-96 {
      width: 24rem !important;
    }
    .w-sm-px {
      width: 1px !important;
    }
    .w-sm-1\/2 {
      width: 50% !important;
    }
    .w-sm-1\/3 {
      width: 33.3333333333% !important;
    }
    .w-sm-2\/3 {
      width: 66.6666666667% !important;
    }
    .w-sm-1\/4 {
      width: 25% !important;
    }
    .w-sm-2\/4 {
      width: 50% !important;
    }
    .w-sm-3\/4 {
      width: 75% !important;
    }
    .w-sm-1\/5 {
      width: 20% !important;
    }
    .w-sm-2\/5 {
      width: 40% !important;
    }
    .w-sm-3\/5 {
      width: 60% !important;
    }
    .w-sm-4\/5 {
      width: 80% !important;
    }
    .w-sm-1\/6 {
      width: 16.6666666667% !important;
    }
    .w-sm-2\/6 {
      width: 33.3333333333% !important;
    }
    .w-sm-3\/6 {
      width: 50% !important;
    }
    .w-sm-4\/6 {
      width: 66.6666666667% !important;
    }
    .w-sm-5\/6 {
      width: 83.3333333333% !important;
    }
    .w-sm-11\/10 {
      width: 110% !important;
    }
    .w-sm-12\/10 {
      width: 120% !important;
    }
    .w-sm-13\/10 {
      width: 130% !important;
    }
    .w-sm-14\/10 {
      width: 140% !important;
    }
    .w-sm-15\/10 {
      width: 150% !important;
    }
    .w-sm-auto {
      width: auto !important;
    }
    .w-sm-full {
      width: 100% !important;
    }
    .w-sm-screen {
      width: 100vw !important;
    }
    .w-sm-min {
      width: -webkit-min-content !important;
      width: -moz-min-content !important;
      width: min-content !important;
    }
    .w-sm-max {
      width: -webkit-max-content !important;
      width: -moz-max-content !important;
      width: max-content !important;
    }
    .max-w-sm-screen-sm {
      max-width: 640px !important;
    }
    .max-w-sm-screen-md {
      max-width: 768px !important;
    }
    .max-w-sm-screen-lg {
      max-width: 1024px !important;
    }
    .max-w-sm-screen-xl {
      max-width: 1280px !important;
    }
    .max-w-sm-screen-xxl {
      max-width: 1536px !important;
    }
    .max-w-sm-0 {
      max-width: 0 !important;
    }
    .max-w-sm-full {
      max-width: 100% !important;
    }
    .max-w-sm-read {
      max-width: 65ch !important;
    }
    .max-w-sm-min {
      max-width: -webkit-min-content !important;
      max-width: -moz-min-content !important;
      max-width: min-content !important;
    }
    .max-w-sm-max {
      max-width: -webkit-max-content !important;
      max-width: -moz-max-content !important;
      max-width: max-content !important;
    }
    .h-sm-0 {
      height: 0 !important;
    }
    .h-sm-1 {
      height: 0.25rem !important;
    }
    .h-sm-2 {
      height: 0.5rem !important;
    }
    .h-sm-3 {
      height: 0.75rem !important;
    }
    .h-sm-4 {
      height: 1rem !important;
    }
    .h-sm-5 {
      height: 1.25rem !important;
    }
    .h-sm-6 {
      height: 1.5rem !important;
    }
    .h-sm-7 {
      height: 1.75rem !important;
    }
    .h-sm-8 {
      height: 2rem !important;
    }
    .h-sm-10 {
      height: 2.5rem !important;
    }
    .h-sm-12 {
      height: 3rem !important;
    }
    .h-sm-14 {
      height: 3.5rem !important;
    }
    .h-sm-16 {
      height: 4rem !important;
    }
    .h-sm-18 {
      height: 4.5rem !important;
    }
    .h-sm-20 {
      height: 5rem !important;
    }
    .h-sm-24 {
      height: 6rem !important;
    }
    .h-sm-32 {
      height: 8rem !important;
    }
    .h-sm-40 {
      height: 10rem !important;
    }
    .h-sm-48 {
      height: 12rem !important;
    }
    .h-sm-56 {
      height: 14rem !important;
    }
    .h-sm-64 {
      height: 16rem !important;
    }
    .h-sm-72 {
      height: 18rem !important;
    }
    .h-sm-80 {
      height: 20rem !important;
    }
    .h-sm-88 {
      height: 22rem !important;
    }
    .h-sm-96 {
      height: 24rem !important;
    }
    .h-sm-px {
      height: 1px !important;
    }
    .h-sm-1\/2 {
      height: 50% !important;
    }
    .h-sm-1\/3 {
      height: 33.3333333333% !important;
    }
    .h-sm-2\/3 {
      height: 66.6666666667% !important;
    }
    .h-sm-1\/4 {
      height: 25% !important;
    }
    .h-sm-2\/4 {
      height: 50% !important;
    }
    .h-sm-3\/4 {
      height: 75% !important;
    }
    .h-sm-1\/5 {
      height: 20% !important;
    }
    .h-sm-2\/5 {
      height: 40% !important;
    }
    .h-sm-3\/5 {
      height: 60% !important;
    }
    .h-sm-4\/5 {
      height: 80% !important;
    }
    .h-sm-1\/6 {
      height: 16.6666666667% !important;
    }
    .h-sm-2\/6 {
      height: 33.3333333333% !important;
    }
    .h-sm-3\/6 {
      height: 50% !important;
    }
    .h-sm-4\/6 {
      height: 66.6666666667% !important;
    }
    .h-sm-5\/6 {
      height: 83.3333333333% !important;
    }
    .h-sm-11\/10 {
      height: 110% !important;
    }
    .h-sm-12\/10 {
      height: 120% !important;
    }
    .h-sm-13\/10 {
      height: 130% !important;
    }
    .h-sm-14\/10 {
      height: 140% !important;
    }
    .h-sm-15\/10 {
      height: 150% !important;
    }
    .h-sm-auto {
      height: auto !important;
    }
    .h-sm-full {
      height: 100% !important;
    }
    .h-sm-screen {
      height: 100vh !important;
    }
    .max-h-sm-0 {
      max-height: 0 !important;
    }
    .max-h-sm-1 {
      max-height: 0.25rem !important;
    }
    .max-h-sm-2 {
      max-height: 0.5rem !important;
    }
    .max-h-sm-3 {
      max-height: 0.75rem !important;
    }
    .max-h-sm-4 {
      max-height: 1rem !important;
    }
    .max-h-sm-5 {
      max-height: 1.25rem !important;
    }
    .max-h-sm-6 {
      max-height: 1.5rem !important;
    }
    .max-h-sm-7 {
      max-height: 1.75rem !important;
    }
    .max-h-sm-8 {
      max-height: 2rem !important;
    }
    .max-h-sm-10 {
      max-height: 2.5rem !important;
    }
    .max-h-sm-12 {
      max-height: 3rem !important;
    }
    .max-h-sm-14 {
      max-height: 3.5rem !important;
    }
    .max-h-sm-16 {
      max-height: 4rem !important;
    }
    .max-h-sm-18 {
      max-height: 4.5rem !important;
    }
    .max-h-sm-20 {
      max-height: 5rem !important;
    }
    .max-h-sm-24 {
      max-height: 6rem !important;
    }
    .max-h-sm-32 {
      max-height: 8rem !important;
    }
    .max-h-sm-40 {
      max-height: 10rem !important;
    }
    .max-h-sm-48 {
      max-height: 12rem !important;
    }
    .max-h-sm-56 {
      max-height: 14rem !important;
    }
    .max-h-sm-64 {
      max-height: 16rem !important;
    }
    .max-h-sm-72 {
      max-height: 18rem !important;
    }
    .max-h-sm-80 {
      max-height: 20rem !important;
    }
    .max-h-sm-88 {
      max-height: 22rem !important;
    }
    .max-h-sm-96 {
      max-height: 24rem !important;
    }
    .max-h-sm-px {
      max-height: 1px !important;
    }
    .max-h-sm-full {
      max-height: 100% !important;
    }
    .max-h-sm-screen {
      max-height: 100vh !important;
    }
    .flex-sm-1 {
      flex: 1 1 0% !important;
    }
    .flex-sm-fill {
      flex: 1 1 auto !important;
    }
    .flex-sm-initial {
      flex: 0 1 auto !important;
    }
    .flex-sm-none {
      flex: none !important;
    }
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .gap-sm-0 {
      gap: 0 !important;
    }
    .gap-sm-1 {
      gap: 0.25rem !important;
    }
    .gap-sm-2 {
      gap: 0.5rem !important;
    }
    .gap-sm-3 {
      gap: 0.75rem !important;
    }
    .gap-sm-4 {
      gap: 1rem !important;
    }
    .gap-sm-5 {
      gap: 1.25rem !important;
    }
    .gap-sm-6 {
      gap: 1.5rem !important;
    }
    .gap-sm-7 {
      gap: 1.75rem !important;
    }
    .gap-sm-8 {
      gap: 2rem !important;
    }
    .gap-sm-10 {
      gap: 2.5rem !important;
    }
    .gap-sm-12 {
      gap: 3rem !important;
    }
    .gap-sm-14 {
      gap: 3.5rem !important;
    }
    .gap-sm-16 {
      gap: 4rem !important;
    }
    .gap-sm-18 {
      gap: 4.5rem !important;
    }
    .gap-sm-20 {
      gap: 5rem !important;
    }
    .gap-sm-24 {
      gap: 6rem !important;
    }
    .gap-sm-32 {
      gap: 8rem !important;
    }
    .gap-sm-40 {
      gap: 10rem !important;
    }
    .gap-sm-48 {
      gap: 12rem !important;
    }
    .gap-sm-56 {
      gap: 14rem !important;
    }
    .gap-sm-64 {
      gap: 16rem !important;
    }
    .gap-sm-72 {
      gap: 18rem !important;
    }
    .gap-sm-80 {
      gap: 20rem !important;
    }
    .gap-sm-88 {
      gap: 22rem !important;
    }
    .gap-sm-96 {
      gap: 24rem !important;
    }
    .gap-sm-px {
      gap: 1px !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .justify-content-sm-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-evenly {
      align-content: space-evenly !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      align-self: stretch !important;
    }
    .order-sm-first {
      order: -1 !important;
    }
    .order-sm-0 {
      order: 0 !important;
    }
    .order-sm-1 {
      order: 1 !important;
    }
    .order-sm-2 {
      order: 2 !important;
    }
    .order-sm-3 {
      order: 3 !important;
    }
    .order-sm-4 {
      order: 4 !important;
    }
    .order-sm-5 {
      order: 5 !important;
    }
    .order-sm-last {
      order: 6 !important;
    }
    .m-sm-0 {
      margin: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 0.75rem !important;
    }
    .m-sm-4 {
      margin: 1rem !important;
    }
    .m-sm-5 {
      margin: 1.25rem !important;
    }
    .m-sm-6 {
      margin: 1.5rem !important;
    }
    .m-sm-7 {
      margin: 1.75rem !important;
    }
    .m-sm-8 {
      margin: 2rem !important;
    }
    .m-sm-10 {
      margin: 2.5rem !important;
    }
    .m-sm-12 {
      margin: 3rem !important;
    }
    .m-sm-14 {
      margin: 3.5rem !important;
    }
    .m-sm-16 {
      margin: 4rem !important;
    }
    .m-sm-18 {
      margin: 4.5rem !important;
    }
    .m-sm-20 {
      margin: 5rem !important;
    }
    .m-sm-24 {
      margin: 6rem !important;
    }
    .m-sm-32 {
      margin: 8rem !important;
    }
    .m-sm-40 {
      margin: 10rem !important;
    }
    .m-sm-48 {
      margin: 12rem !important;
    }
    .m-sm-56 {
      margin: 14rem !important;
    }
    .m-sm-64 {
      margin: 16rem !important;
    }
    .m-sm-72 {
      margin: 18rem !important;
    }
    .m-sm-80 {
      margin: 20rem !important;
    }
    .m-sm-88 {
      margin: 22rem !important;
    }
    .m-sm-96 {
      margin: 24rem !important;
    }
    .m-sm-px {
      margin: 1px !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-sm-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-sm-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-sm-3 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-sm-4 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-sm-5 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-sm-6 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-sm-7 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-sm-8 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-sm-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-sm-12 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-sm-14 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-sm-16 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-sm-18 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-sm-20 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-sm-24 {
      margin-right: 6rem !important;
      margin-left: 6rem !important;
    }
    .mx-sm-32 {
      margin-right: 8rem !important;
      margin-left: 8rem !important;
    }
    .mx-sm-40 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-sm-48 {
      margin-right: 12rem !important;
      margin-left: 12rem !important;
    }
    .mx-sm-56 {
      margin-right: 14rem !important;
      margin-left: 14rem !important;
    }
    .mx-sm-64 {
      margin-right: 16rem !important;
      margin-left: 16rem !important;
    }
    .mx-sm-72 {
      margin-right: 18rem !important;
      margin-left: 18rem !important;
    }
    .mx-sm-80 {
      margin-right: 20rem !important;
      margin-left: 20rem !important;
    }
    .mx-sm-88 {
      margin-right: 22rem !important;
      margin-left: 22rem !important;
    }
    .mx-sm-96 {
      margin-right: 24rem !important;
      margin-left: 24rem !important;
    }
    .mx-sm-px {
      margin-right: 1px !important;
      margin-left: 1px !important;
    }
    .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-sm-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-sm-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-sm-3 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-sm-4 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-sm-5 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-sm-6 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-sm-7 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-sm-8 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-sm-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-sm-12 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-sm-14 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-sm-16 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-sm-18 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-sm-20 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-sm-24 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important;
    }
    .my-sm-32 {
      margin-top: 8rem !important;
      margin-bottom: 8rem !important;
    }
    .my-sm-40 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-sm-48 {
      margin-top: 12rem !important;
      margin-bottom: 12rem !important;
    }
    .my-sm-56 {
      margin-top: 14rem !important;
      margin-bottom: 14rem !important;
    }
    .my-sm-64 {
      margin-top: 16rem !important;
      margin-bottom: 16rem !important;
    }
    .my-sm-72 {
      margin-top: 18rem !important;
      margin-bottom: 18rem !important;
    }
    .my-sm-80 {
      margin-top: 20rem !important;
      margin-bottom: 20rem !important;
    }
    .my-sm-88 {
      margin-top: 22rem !important;
      margin-bottom: 22rem !important;
    }
    .my-sm-96 {
      margin-top: 24rem !important;
      margin-bottom: 24rem !important;
    }
    .my-sm-px {
      margin-top: 1px !important;
      margin-bottom: 1px !important;
    }
    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-sm-0 {
      margin-top: 0 !important;
    }
    .mt-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mt-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mt-sm-3 {
      margin-top: 0.75rem !important;
    }
    .mt-sm-4 {
      margin-top: 1rem !important;
    }
    .mt-sm-5 {
      margin-top: 1.25rem !important;
    }
    .mt-sm-6 {
      margin-top: 1.5rem !important;
    }
    .mt-sm-7 {
      margin-top: 1.75rem !important;
    }
    .mt-sm-8 {
      margin-top: 2rem !important;
    }
    .mt-sm-10 {
      margin-top: 2.5rem !important;
    }
    .mt-sm-12 {
      margin-top: 3rem !important;
    }
    .mt-sm-14 {
      margin-top: 3.5rem !important;
    }
    .mt-sm-16 {
      margin-top: 4rem !important;
    }
    .mt-sm-18 {
      margin-top: 4.5rem !important;
    }
    .mt-sm-20 {
      margin-top: 5rem !important;
    }
    .mt-sm-24 {
      margin-top: 6rem !important;
    }
    .mt-sm-32 {
      margin-top: 8rem !important;
    }
    .mt-sm-40 {
      margin-top: 10rem !important;
    }
    .mt-sm-48 {
      margin-top: 12rem !important;
    }
    .mt-sm-56 {
      margin-top: 14rem !important;
    }
    .mt-sm-64 {
      margin-top: 16rem !important;
    }
    .mt-sm-72 {
      margin-top: 18rem !important;
    }
    .mt-sm-80 {
      margin-top: 20rem !important;
    }
    .mt-sm-88 {
      margin-top: 22rem !important;
    }
    .mt-sm-96 {
      margin-top: 24rem !important;
    }
    .mt-sm-px {
      margin-top: 1px !important;
    }
    .mt-sm-auto {
      margin-top: auto !important;
    }
    .me-sm-0 {
      margin-right: 0 !important;
    }
    .me-sm-1 {
      margin-right: 0.25rem !important;
    }
    .me-sm-2 {
      margin-right: 0.5rem !important;
    }
    .me-sm-3 {
      margin-right: 0.75rem !important;
    }
    .me-sm-4 {
      margin-right: 1rem !important;
    }
    .me-sm-5 {
      margin-right: 1.25rem !important;
    }
    .me-sm-6 {
      margin-right: 1.5rem !important;
    }
    .me-sm-7 {
      margin-right: 1.75rem !important;
    }
    .me-sm-8 {
      margin-right: 2rem !important;
    }
    .me-sm-10 {
      margin-right: 2.5rem !important;
    }
    .me-sm-12 {
      margin-right: 3rem !important;
    }
    .me-sm-14 {
      margin-right: 3.5rem !important;
    }
    .me-sm-16 {
      margin-right: 4rem !important;
    }
    .me-sm-18 {
      margin-right: 4.5rem !important;
    }
    .me-sm-20 {
      margin-right: 5rem !important;
    }
    .me-sm-24 {
      margin-right: 6rem !important;
    }
    .me-sm-32 {
      margin-right: 8rem !important;
    }
    .me-sm-40 {
      margin-right: 10rem !important;
    }
    .me-sm-48 {
      margin-right: 12rem !important;
    }
    .me-sm-56 {
      margin-right: 14rem !important;
    }
    .me-sm-64 {
      margin-right: 16rem !important;
    }
    .me-sm-72 {
      margin-right: 18rem !important;
    }
    .me-sm-80 {
      margin-right: 20rem !important;
    }
    .me-sm-88 {
      margin-right: 22rem !important;
    }
    .me-sm-96 {
      margin-right: 24rem !important;
    }
    .me-sm-px {
      margin-right: 1px !important;
    }
    .me-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-0 {
      margin-bottom: 0 !important;
    }
    .mb-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-sm-3 {
      margin-bottom: 0.75rem !important;
    }
    .mb-sm-4 {
      margin-bottom: 1rem !important;
    }
    .mb-sm-5 {
      margin-bottom: 1.25rem !important;
    }
    .mb-sm-6 {
      margin-bottom: 1.5rem !important;
    }
    .mb-sm-7 {
      margin-bottom: 1.75rem !important;
    }
    .mb-sm-8 {
      margin-bottom: 2rem !important;
    }
    .mb-sm-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-sm-12 {
      margin-bottom: 3rem !important;
    }
    .mb-sm-14 {
      margin-bottom: 3.5rem !important;
    }
    .mb-sm-16 {
      margin-bottom: 4rem !important;
    }
    .mb-sm-18 {
      margin-bottom: 4.5rem !important;
    }
    .mb-sm-20 {
      margin-bottom: 5rem !important;
    }
    .mb-sm-24 {
      margin-bottom: 6rem !important;
    }
    .mb-sm-32 {
      margin-bottom: 8rem !important;
    }
    .mb-sm-40 {
      margin-bottom: 10rem !important;
    }
    .mb-sm-48 {
      margin-bottom: 12rem !important;
    }
    .mb-sm-56 {
      margin-bottom: 14rem !important;
    }
    .mb-sm-64 {
      margin-bottom: 16rem !important;
    }
    .mb-sm-72 {
      margin-bottom: 18rem !important;
    }
    .mb-sm-80 {
      margin-bottom: 20rem !important;
    }
    .mb-sm-88 {
      margin-bottom: 22rem !important;
    }
    .mb-sm-96 {
      margin-bottom: 24rem !important;
    }
    .mb-sm-px {
      margin-bottom: 1px !important;
    }
    .mb-sm-auto {
      margin-bottom: auto !important;
    }
    .ms-sm-0 {
      margin-left: 0 !important;
    }
    .ms-sm-1 {
      margin-left: 0.25rem !important;
    }
    .ms-sm-2 {
      margin-left: 0.5rem !important;
    }
    .ms-sm-3 {
      margin-left: 0.75rem !important;
    }
    .ms-sm-4 {
      margin-left: 1rem !important;
    }
    .ms-sm-5 {
      margin-left: 1.25rem !important;
    }
    .ms-sm-6 {
      margin-left: 1.5rem !important;
    }
    .ms-sm-7 {
      margin-left: 1.75rem !important;
    }
    .ms-sm-8 {
      margin-left: 2rem !important;
    }
    .ms-sm-10 {
      margin-left: 2.5rem !important;
    }
    .ms-sm-12 {
      margin-left: 3rem !important;
    }
    .ms-sm-14 {
      margin-left: 3.5rem !important;
    }
    .ms-sm-16 {
      margin-left: 4rem !important;
    }
    .ms-sm-18 {
      margin-left: 4.5rem !important;
    }
    .ms-sm-20 {
      margin-left: 5rem !important;
    }
    .ms-sm-24 {
      margin-left: 6rem !important;
    }
    .ms-sm-32 {
      margin-left: 8rem !important;
    }
    .ms-sm-40 {
      margin-left: 10rem !important;
    }
    .ms-sm-48 {
      margin-left: 12rem !important;
    }
    .ms-sm-56 {
      margin-left: 14rem !important;
    }
    .ms-sm-64 {
      margin-left: 16rem !important;
    }
    .ms-sm-72 {
      margin-left: 18rem !important;
    }
    .ms-sm-80 {
      margin-left: 20rem !important;
    }
    .ms-sm-88 {
      margin-left: 22rem !important;
    }
    .ms-sm-96 {
      margin-left: 24rem !important;
    }
    .ms-sm-px {
      margin-left: 1px !important;
    }
    .ms-sm-auto {
      margin-left: auto !important;
    }
    .m-sm-n1 {
      margin: -0.25rem !important;
    }
    .m-sm-n2 {
      margin: -0.5rem !important;
    }
    .m-sm-n3 {
      margin: -0.75rem !important;
    }
    .m-sm-n4 {
      margin: -1rem !important;
    }
    .m-sm-n5 {
      margin: -1.25rem !important;
    }
    .m-sm-n6 {
      margin: -1.5rem !important;
    }
    .m-sm-n7 {
      margin: -1.75rem !important;
    }
    .m-sm-n8 {
      margin: -2rem !important;
    }
    .m-sm-n10 {
      margin: -2.5rem !important;
    }
    .m-sm-n12 {
      margin: -3rem !important;
    }
    .m-sm-n14 {
      margin: -3.5rem !important;
    }
    .m-sm-n16 {
      margin: -4rem !important;
    }
    .m-sm-n18 {
      margin: -4.5rem !important;
    }
    .m-sm-n20 {
      margin: -5rem !important;
    }
    .m-sm-n24 {
      margin: -6rem !important;
    }
    .m-sm-n32 {
      margin: -8rem !important;
    }
    .m-sm-n40 {
      margin: -10rem !important;
    }
    .m-sm-n48 {
      margin: -12rem !important;
    }
    .m-sm-n56 {
      margin: -14rem !important;
    }
    .m-sm-n64 {
      margin: -16rem !important;
    }
    .m-sm-n72 {
      margin: -18rem !important;
    }
    .m-sm-n80 {
      margin: -20rem !important;
    }
    .m-sm-n88 {
      margin: -22rem !important;
    }
    .m-sm-n96 {
      margin: -24rem !important;
    }
    .m-sm-npx {
      margin: -1px !important;
    }
    .mx-sm-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-sm-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-sm-n3 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-sm-n4 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-sm-n5 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-sm-n6 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-sm-n7 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-sm-n8 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-sm-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-sm-n12 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-sm-n14 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-sm-n16 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-sm-n18 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-sm-n20 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-sm-n24 {
      margin-right: -6rem !important;
      margin-left: -6rem !important;
    }
    .mx-sm-n32 {
      margin-right: -8rem !important;
      margin-left: -8rem !important;
    }
    .mx-sm-n40 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-sm-n48 {
      margin-right: -12rem !important;
      margin-left: -12rem !important;
    }
    .mx-sm-n56 {
      margin-right: -14rem !important;
      margin-left: -14rem !important;
    }
    .mx-sm-n64 {
      margin-right: -16rem !important;
      margin-left: -16rem !important;
    }
    .mx-sm-n72 {
      margin-right: -18rem !important;
      margin-left: -18rem !important;
    }
    .mx-sm-n80 {
      margin-right: -20rem !important;
      margin-left: -20rem !important;
    }
    .mx-sm-n88 {
      margin-right: -22rem !important;
      margin-left: -22rem !important;
    }
    .mx-sm-n96 {
      margin-right: -24rem !important;
      margin-left: -24rem !important;
    }
    .mx-sm-npx {
      margin-right: -1px !important;
      margin-left: -1px !important;
    }
    .my-sm-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-sm-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-sm-n3 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-sm-n4 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-sm-n5 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-sm-n6 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-sm-n7 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-sm-n8 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-sm-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-sm-n12 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-sm-n14 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-sm-n16 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-sm-n18 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-sm-n20 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-sm-n24 {
      margin-top: -6rem !important;
      margin-bottom: -6rem !important;
    }
    .my-sm-n32 {
      margin-top: -8rem !important;
      margin-bottom: -8rem !important;
    }
    .my-sm-n40 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-sm-n48 {
      margin-top: -12rem !important;
      margin-bottom: -12rem !important;
    }
    .my-sm-n56 {
      margin-top: -14rem !important;
      margin-bottom: -14rem !important;
    }
    .my-sm-n64 {
      margin-top: -16rem !important;
      margin-bottom: -16rem !important;
    }
    .my-sm-n72 {
      margin-top: -18rem !important;
      margin-bottom: -18rem !important;
    }
    .my-sm-n80 {
      margin-top: -20rem !important;
      margin-bottom: -20rem !important;
    }
    .my-sm-n88 {
      margin-top: -22rem !important;
      margin-bottom: -22rem !important;
    }
    .my-sm-n96 {
      margin-top: -24rem !important;
      margin-bottom: -24rem !important;
    }
    .my-sm-npx {
      margin-top: -1px !important;
      margin-bottom: -1px !important;
    }
    .mt-sm-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-sm-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-sm-n3 {
      margin-top: -0.75rem !important;
    }
    .mt-sm-n4 {
      margin-top: -1rem !important;
    }
    .mt-sm-n5 {
      margin-top: -1.25rem !important;
    }
    .mt-sm-n6 {
      margin-top: -1.5rem !important;
    }
    .mt-sm-n7 {
      margin-top: -1.75rem !important;
    }
    .mt-sm-n8 {
      margin-top: -2rem !important;
    }
    .mt-sm-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-sm-n12 {
      margin-top: -3rem !important;
    }
    .mt-sm-n14 {
      margin-top: -3.5rem !important;
    }
    .mt-sm-n16 {
      margin-top: -4rem !important;
    }
    .mt-sm-n18 {
      margin-top: -4.5rem !important;
    }
    .mt-sm-n20 {
      margin-top: -5rem !important;
    }
    .mt-sm-n24 {
      margin-top: -6rem !important;
    }
    .mt-sm-n32 {
      margin-top: -8rem !important;
    }
    .mt-sm-n40 {
      margin-top: -10rem !important;
    }
    .mt-sm-n48 {
      margin-top: -12rem !important;
    }
    .mt-sm-n56 {
      margin-top: -14rem !important;
    }
    .mt-sm-n64 {
      margin-top: -16rem !important;
    }
    .mt-sm-n72 {
      margin-top: -18rem !important;
    }
    .mt-sm-n80 {
      margin-top: -20rem !important;
    }
    .mt-sm-n88 {
      margin-top: -22rem !important;
    }
    .mt-sm-n96 {
      margin-top: -24rem !important;
    }
    .mt-sm-npx {
      margin-top: -1px !important;
    }
    .me-sm-n1 {
      margin-right: -0.25rem !important;
    }
    .me-sm-n2 {
      margin-right: -0.5rem !important;
    }
    .me-sm-n3 {
      margin-right: -0.75rem !important;
    }
    .me-sm-n4 {
      margin-right: -1rem !important;
    }
    .me-sm-n5 {
      margin-right: -1.25rem !important;
    }
    .me-sm-n6 {
      margin-right: -1.5rem !important;
    }
    .me-sm-n7 {
      margin-right: -1.75rem !important;
    }
    .me-sm-n8 {
      margin-right: -2rem !important;
    }
    .me-sm-n10 {
      margin-right: -2.5rem !important;
    }
    .me-sm-n12 {
      margin-right: -3rem !important;
    }
    .me-sm-n14 {
      margin-right: -3.5rem !important;
    }
    .me-sm-n16 {
      margin-right: -4rem !important;
    }
    .me-sm-n18 {
      margin-right: -4.5rem !important;
    }
    .me-sm-n20 {
      margin-right: -5rem !important;
    }
    .me-sm-n24 {
      margin-right: -6rem !important;
    }
    .me-sm-n32 {
      margin-right: -8rem !important;
    }
    .me-sm-n40 {
      margin-right: -10rem !important;
    }
    .me-sm-n48 {
      margin-right: -12rem !important;
    }
    .me-sm-n56 {
      margin-right: -14rem !important;
    }
    .me-sm-n64 {
      margin-right: -16rem !important;
    }
    .me-sm-n72 {
      margin-right: -18rem !important;
    }
    .me-sm-n80 {
      margin-right: -20rem !important;
    }
    .me-sm-n88 {
      margin-right: -22rem !important;
    }
    .me-sm-n96 {
      margin-right: -24rem !important;
    }
    .me-sm-npx {
      margin-right: -1px !important;
    }
    .mb-sm-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-sm-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-sm-n3 {
      margin-bottom: -0.75rem !important;
    }
    .mb-sm-n4 {
      margin-bottom: -1rem !important;
    }
    .mb-sm-n5 {
      margin-bottom: -1.25rem !important;
    }
    .mb-sm-n6 {
      margin-bottom: -1.5rem !important;
    }
    .mb-sm-n7 {
      margin-bottom: -1.75rem !important;
    }
    .mb-sm-n8 {
      margin-bottom: -2rem !important;
    }
    .mb-sm-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-sm-n12 {
      margin-bottom: -3rem !important;
    }
    .mb-sm-n14 {
      margin-bottom: -3.5rem !important;
    }
    .mb-sm-n16 {
      margin-bottom: -4rem !important;
    }
    .mb-sm-n18 {
      margin-bottom: -4.5rem !important;
    }
    .mb-sm-n20 {
      margin-bottom: -5rem !important;
    }
    .mb-sm-n24 {
      margin-bottom: -6rem !important;
    }
    .mb-sm-n32 {
      margin-bottom: -8rem !important;
    }
    .mb-sm-n40 {
      margin-bottom: -10rem !important;
    }
    .mb-sm-n48 {
      margin-bottom: -12rem !important;
    }
    .mb-sm-n56 {
      margin-bottom: -14rem !important;
    }
    .mb-sm-n64 {
      margin-bottom: -16rem !important;
    }
    .mb-sm-n72 {
      margin-bottom: -18rem !important;
    }
    .mb-sm-n80 {
      margin-bottom: -20rem !important;
    }
    .mb-sm-n88 {
      margin-bottom: -22rem !important;
    }
    .mb-sm-n96 {
      margin-bottom: -24rem !important;
    }
    .mb-sm-npx {
      margin-bottom: -1px !important;
    }
    .ms-sm-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-sm-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-sm-n3 {
      margin-left: -0.75rem !important;
    }
    .ms-sm-n4 {
      margin-left: -1rem !important;
    }
    .ms-sm-n5 {
      margin-left: -1.25rem !important;
    }
    .ms-sm-n6 {
      margin-left: -1.5rem !important;
    }
    .ms-sm-n7 {
      margin-left: -1.75rem !important;
    }
    .ms-sm-n8 {
      margin-left: -2rem !important;
    }
    .ms-sm-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-sm-n12 {
      margin-left: -3rem !important;
    }
    .ms-sm-n14 {
      margin-left: -3.5rem !important;
    }
    .ms-sm-n16 {
      margin-left: -4rem !important;
    }
    .ms-sm-n18 {
      margin-left: -4.5rem !important;
    }
    .ms-sm-n20 {
      margin-left: -5rem !important;
    }
    .ms-sm-n24 {
      margin-left: -6rem !important;
    }
    .ms-sm-n32 {
      margin-left: -8rem !important;
    }
    .ms-sm-n40 {
      margin-left: -10rem !important;
    }
    .ms-sm-n48 {
      margin-left: -12rem !important;
    }
    .ms-sm-n56 {
      margin-left: -14rem !important;
    }
    .ms-sm-n64 {
      margin-left: -16rem !important;
    }
    .ms-sm-n72 {
      margin-left: -18rem !important;
    }
    .ms-sm-n80 {
      margin-left: -20rem !important;
    }
    .ms-sm-n88 {
      margin-left: -22rem !important;
    }
    .ms-sm-n96 {
      margin-left: -24rem !important;
    }
    .ms-sm-npx {
      margin-left: -1px !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 0.75rem !important;
    }
    .p-sm-4 {
      padding: 1rem !important;
    }
    .p-sm-5 {
      padding: 1.25rem !important;
    }
    .p-sm-6 {
      padding: 1.5rem !important;
    }
    .p-sm-7 {
      padding: 1.75rem !important;
    }
    .p-sm-8 {
      padding: 2rem !important;
    }
    .p-sm-10 {
      padding: 2.5rem !important;
    }
    .p-sm-12 {
      padding: 3rem !important;
    }
    .p-sm-14 {
      padding: 3.5rem !important;
    }
    .p-sm-16 {
      padding: 4rem !important;
    }
    .p-sm-18 {
      padding: 4.5rem !important;
    }
    .p-sm-20 {
      padding: 5rem !important;
    }
    .p-sm-24 {
      padding: 6rem !important;
    }
    .p-sm-32 {
      padding: 8rem !important;
    }
    .p-sm-40 {
      padding: 10rem !important;
    }
    .p-sm-48 {
      padding: 12rem !important;
    }
    .p-sm-56 {
      padding: 14rem !important;
    }
    .p-sm-64 {
      padding: 16rem !important;
    }
    .p-sm-72 {
      padding: 18rem !important;
    }
    .p-sm-80 {
      padding: 20rem !important;
    }
    .p-sm-88 {
      padding: 22rem !important;
    }
    .p-sm-96 {
      padding: 24rem !important;
    }
    .p-sm-px {
      padding: 1px !important;
    }
    .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-sm-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-sm-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-sm-3 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-sm-4 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-sm-5 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-sm-6 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-sm-7 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-sm-8 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-sm-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-sm-12 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-sm-14 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-sm-16 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-sm-18 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-sm-20 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-sm-24 {
      padding-right: 6rem !important;
      padding-left: 6rem !important;
    }
    .px-sm-32 {
      padding-right: 8rem !important;
      padding-left: 8rem !important;
    }
    .px-sm-40 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-sm-48 {
      padding-right: 12rem !important;
      padding-left: 12rem !important;
    }
    .px-sm-56 {
      padding-right: 14rem !important;
      padding-left: 14rem !important;
    }
    .px-sm-64 {
      padding-right: 16rem !important;
      padding-left: 16rem !important;
    }
    .px-sm-72 {
      padding-right: 18rem !important;
      padding-left: 18rem !important;
    }
    .px-sm-80 {
      padding-right: 20rem !important;
      padding-left: 20rem !important;
    }
    .px-sm-88 {
      padding-right: 22rem !important;
      padding-left: 22rem !important;
    }
    .px-sm-96 {
      padding-right: 24rem !important;
      padding-left: 24rem !important;
    }
    .px-sm-px {
      padding-right: 1px !important;
      padding-left: 1px !important;
    }
    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-sm-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-sm-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-sm-3 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-sm-4 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-sm-5 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-sm-6 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-sm-7 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-sm-8 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-sm-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-sm-12 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-sm-14 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-sm-16 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-sm-18 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-sm-20 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-sm-24 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important;
    }
    .py-sm-32 {
      padding-top: 8rem !important;
      padding-bottom: 8rem !important;
    }
    .py-sm-40 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-sm-48 {
      padding-top: 12rem !important;
      padding-bottom: 12rem !important;
    }
    .py-sm-56 {
      padding-top: 14rem !important;
      padding-bottom: 14rem !important;
    }
    .py-sm-64 {
      padding-top: 16rem !important;
      padding-bottom: 16rem !important;
    }
    .py-sm-72 {
      padding-top: 18rem !important;
      padding-bottom: 18rem !important;
    }
    .py-sm-80 {
      padding-top: 20rem !important;
      padding-bottom: 20rem !important;
    }
    .py-sm-88 {
      padding-top: 22rem !important;
      padding-bottom: 22rem !important;
    }
    .py-sm-96 {
      padding-top: 24rem !important;
      padding-bottom: 24rem !important;
    }
    .py-sm-px {
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }
    .pt-sm-0 {
      padding-top: 0 !important;
    }
    .pt-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pt-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pt-sm-3 {
      padding-top: 0.75rem !important;
    }
    .pt-sm-4 {
      padding-top: 1rem !important;
    }
    .pt-sm-5 {
      padding-top: 1.25rem !important;
    }
    .pt-sm-6 {
      padding-top: 1.5rem !important;
    }
    .pt-sm-7 {
      padding-top: 1.75rem !important;
    }
    .pt-sm-8 {
      padding-top: 2rem !important;
    }
    .pt-sm-10 {
      padding-top: 2.5rem !important;
    }
    .pt-sm-12 {
      padding-top: 3rem !important;
    }
    .pt-sm-14 {
      padding-top: 3.5rem !important;
    }
    .pt-sm-16 {
      padding-top: 4rem !important;
    }
    .pt-sm-18 {
      padding-top: 4.5rem !important;
    }
    .pt-sm-20 {
      padding-top: 5rem !important;
    }
    .pt-sm-24 {
      padding-top: 6rem !important;
    }
    .pt-sm-32 {
      padding-top: 8rem !important;
    }
    .pt-sm-40 {
      padding-top: 10rem !important;
    }
    .pt-sm-48 {
      padding-top: 12rem !important;
    }
    .pt-sm-56 {
      padding-top: 14rem !important;
    }
    .pt-sm-64 {
      padding-top: 16rem !important;
    }
    .pt-sm-72 {
      padding-top: 18rem !important;
    }
    .pt-sm-80 {
      padding-top: 20rem !important;
    }
    .pt-sm-88 {
      padding-top: 22rem !important;
    }
    .pt-sm-96 {
      padding-top: 24rem !important;
    }
    .pt-sm-px {
      padding-top: 1px !important;
    }
    .pe-sm-0 {
      padding-right: 0 !important;
    }
    .pe-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pe-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pe-sm-3 {
      padding-right: 0.75rem !important;
    }
    .pe-sm-4 {
      padding-right: 1rem !important;
    }
    .pe-sm-5 {
      padding-right: 1.25rem !important;
    }
    .pe-sm-6 {
      padding-right: 1.5rem !important;
    }
    .pe-sm-7 {
      padding-right: 1.75rem !important;
    }
    .pe-sm-8 {
      padding-right: 2rem !important;
    }
    .pe-sm-10 {
      padding-right: 2.5rem !important;
    }
    .pe-sm-12 {
      padding-right: 3rem !important;
    }
    .pe-sm-14 {
      padding-right: 3.5rem !important;
    }
    .pe-sm-16 {
      padding-right: 4rem !important;
    }
    .pe-sm-18 {
      padding-right: 4.5rem !important;
    }
    .pe-sm-20 {
      padding-right: 5rem !important;
    }
    .pe-sm-24 {
      padding-right: 6rem !important;
    }
    .pe-sm-32 {
      padding-right: 8rem !important;
    }
    .pe-sm-40 {
      padding-right: 10rem !important;
    }
    .pe-sm-48 {
      padding-right: 12rem !important;
    }
    .pe-sm-56 {
      padding-right: 14rem !important;
    }
    .pe-sm-64 {
      padding-right: 16rem !important;
    }
    .pe-sm-72 {
      padding-right: 18rem !important;
    }
    .pe-sm-80 {
      padding-right: 20rem !important;
    }
    .pe-sm-88 {
      padding-right: 22rem !important;
    }
    .pe-sm-96 {
      padding-right: 24rem !important;
    }
    .pe-sm-px {
      padding-right: 1px !important;
    }
    .pb-sm-0 {
      padding-bottom: 0 !important;
    }
    .pb-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-sm-3 {
      padding-bottom: 0.75rem !important;
    }
    .pb-sm-4 {
      padding-bottom: 1rem !important;
    }
    .pb-sm-5 {
      padding-bottom: 1.25rem !important;
    }
    .pb-sm-6 {
      padding-bottom: 1.5rem !important;
    }
    .pb-sm-7 {
      padding-bottom: 1.75rem !important;
    }
    .pb-sm-8 {
      padding-bottom: 2rem !important;
    }
    .pb-sm-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-sm-12 {
      padding-bottom: 3rem !important;
    }
    .pb-sm-14 {
      padding-bottom: 3.5rem !important;
    }
    .pb-sm-16 {
      padding-bottom: 4rem !important;
    }
    .pb-sm-18 {
      padding-bottom: 4.5rem !important;
    }
    .pb-sm-20 {
      padding-bottom: 5rem !important;
    }
    .pb-sm-24 {
      padding-bottom: 6rem !important;
    }
    .pb-sm-32 {
      padding-bottom: 8rem !important;
    }
    .pb-sm-40 {
      padding-bottom: 10rem !important;
    }
    .pb-sm-48 {
      padding-bottom: 12rem !important;
    }
    .pb-sm-56 {
      padding-bottom: 14rem !important;
    }
    .pb-sm-64 {
      padding-bottom: 16rem !important;
    }
    .pb-sm-72 {
      padding-bottom: 18rem !important;
    }
    .pb-sm-80 {
      padding-bottom: 20rem !important;
    }
    .pb-sm-88 {
      padding-bottom: 22rem !important;
    }
    .pb-sm-96 {
      padding-bottom: 24rem !important;
    }
    .pb-sm-px {
      padding-bottom: 1px !important;
    }
    .ps-sm-0 {
      padding-left: 0 !important;
    }
    .ps-sm-1 {
      padding-left: 0.25rem !important;
    }
    .ps-sm-2 {
      padding-left: 0.5rem !important;
    }
    .ps-sm-3 {
      padding-left: 0.75rem !important;
    }
    .ps-sm-4 {
      padding-left: 1rem !important;
    }
    .ps-sm-5 {
      padding-left: 1.25rem !important;
    }
    .ps-sm-6 {
      padding-left: 1.5rem !important;
    }
    .ps-sm-7 {
      padding-left: 1.75rem !important;
    }
    .ps-sm-8 {
      padding-left: 2rem !important;
    }
    .ps-sm-10 {
      padding-left: 2.5rem !important;
    }
    .ps-sm-12 {
      padding-left: 3rem !important;
    }
    .ps-sm-14 {
      padding-left: 3.5rem !important;
    }
    .ps-sm-16 {
      padding-left: 4rem !important;
    }
    .ps-sm-18 {
      padding-left: 4.5rem !important;
    }
    .ps-sm-20 {
      padding-left: 5rem !important;
    }
    .ps-sm-24 {
      padding-left: 6rem !important;
    }
    .ps-sm-32 {
      padding-left: 8rem !important;
    }
    .ps-sm-40 {
      padding-left: 10rem !important;
    }
    .ps-sm-48 {
      padding-left: 12rem !important;
    }
    .ps-sm-56 {
      padding-left: 14rem !important;
    }
    .ps-sm-64 {
      padding-left: 16rem !important;
    }
    .ps-sm-72 {
      padding-left: 18rem !important;
    }
    .ps-sm-80 {
      padding-left: 20rem !important;
    }
    .ps-sm-88 {
      padding-left: 22rem !important;
    }
    .ps-sm-96 {
      padding-left: 24rem !important;
    }
    .ps-sm-px {
      padding-left: 1px !important;
    }
    .font-sm-italic {
      font-style: italic !important;
    }
    .font-sm-normal {
      font-style: normal !important;
    }
    .font-sm-light {
      font-weight: 300 !important;
    }
    .font-sm-lighter {
      font-weight: lighter !important;
    }
    .font-sm-regular {
      font-weight: 400 !important;
    }
    .font-sm-semibold {
      font-weight: 500 !important;
    }
    .font-sm-bold {
      font-weight: 600 !important;
    }
    .font-sm-bolder {
      font-weight: bolder !important;
    }
    .lh-sm-none {
      line-height: 1 !important;
    }
    .lh-sm-tighter {
      line-height: 1.125 !important;
    }
    .lh-sm-tight {
      line-height: 1.2 !important;
    }
    .lh-sm-snug {
      line-height: 1.375 !important;
    }
    .lh-sm-normal {
      line-height: 1.5 !important;
    }
    .lh-sm-relaxed {
      line-height: 1.625 !important;
    }
    .lh-sm-loose {
      line-height: 2 !important;
    }
    .text-sm-start {
      text-align: left !important;
    }
    .text-sm-end {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
    .text-sm-no-decoration {
      text-decoration: none !important;
    }
    .text-sm-no-decoration-hover:hover {
      text-decoration: none !important;
    }
    .text-sm-underline {
      text-decoration: underline !important;
    }
    .text-sm-underline-hover:hover {
      text-decoration: underline !important;
    }
    .text-sm-line-through {
      text-decoration: line-through !important;
    }
    .text-sm-line-through-hover:hover {
      text-decoration: line-through !important;
    }
    .text-sm-lowercase {
      text-transform: lowercase !important;
    }
    .text-sm-lowercase-hover:hover {
      text-transform: lowercase !important;
    }
    .text-sm-uppercase {
      text-transform: uppercase !important;
    }
    .text-sm-uppercase-hover:hover {
      text-transform: uppercase !important;
    }
    .text-sm-capitalize {
      text-transform: capitalize !important;
    }
    .text-sm-capitalize-hover:hover {
      text-transform: capitalize !important;
    }
    .text-sm-primary {
      --x-text-opacity: 1;
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-primary-hover:hover {
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-secondary {
      --x-text-opacity: 1;
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-secondary-hover:hover {
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-tertiary {
      --x-text-opacity: 1;
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-tertiary-hover:hover {
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-success {
      --x-text-opacity: 1;
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-success-hover:hover {
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-info {
      --x-text-opacity: 1;
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-info-hover:hover {
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-warning {
      --x-text-opacity: 1;
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-warning-hover:hover {
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-danger {
      --x-text-opacity: 1;
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-danger-hover:hover {
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-white {
      --x-text-opacity: 1;
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-white-hover:hover {
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-light {
      --x-text-opacity: 1;
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-light-hover:hover {
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-dark {
      --x-text-opacity: 1;
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-dark-hover:hover {
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-black {
      --x-text-opacity: 1;
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-black-hover:hover {
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-body {
      --x-text-opacity: 1;
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-body-hover:hover {
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-muted {
      --x-text-opacity: 1;
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-muted-hover:hover {
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-heading {
      --x-text-opacity: 1;
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-heading-hover:hover {
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-current {
      --x-text-opacity: 1;
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-current-hover:hover {
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-reset {
      --x-text-opacity: 1;
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .text-sm-reset-hover:hover {
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .bg-sm-primary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-primary-hover:hover {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-primary-focus:focus {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-primary-focus-within:focus-within {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-secondary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-secondary-hover:hover {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-secondary-focus:focus {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-secondary-focus-within:focus-within {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-tertiary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-tertiary-hover:hover {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-tertiary-focus:focus {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-tertiary-focus-within:focus-within {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-success {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-success-hover:hover {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-success-focus:focus {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-success-focus-within:focus-within {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-info {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-info-hover:hover {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-info-focus:focus {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-info-focus-within:focus-within {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-warning {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-warning-hover:hover {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-warning-focus:focus {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-warning-focus-within:focus-within {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-danger {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-danger-hover:hover {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-danger-focus:focus {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-danger-focus-within:focus-within {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-white {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-white-hover:hover {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-white-focus:focus {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-white-focus-within:focus-within {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-light {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-light-hover:hover {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-light-focus:focus {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-light-focus-within:focus-within {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-dark {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-dark-hover:hover {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-dark-focus:focus {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-dark-focus-within:focus-within {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-body {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-body-hover:hover {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-body-focus:focus {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-body-focus-within:focus-within {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-black {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-black-hover:hover {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-black-focus:focus {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-black-focus-within:focus-within {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-card {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-card-hover:hover {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-card-focus:focus {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-card-focus-within:focus-within {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-sm-transparent {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-transparent-hover:hover {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-transparent-focus:focus {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-transparent-focus-within:focus-within {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-current {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-current-hover:hover {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-current-focus:focus {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-sm-current-focus-within:focus-within {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .rounded-sm {
      border-radius: 0.375rem !important;
    }
    .rounded-sm-0 {
      border-radius: 0 !important;
    }
    .rounded-sm-1 {
      border-radius: 0.25rem !important;
    }
    .rounded-sm-2 {
      border-radius: 0.5rem !important;
    }
    .rounded-sm-3 {
      border-radius: 0.75rem !important;
    }
    .rounded-sm-4 {
      border-radius: 1rem !important;
    }
    .rounded-sm-5 {
      border-radius: 1.5rem !important;
    }
    .rounded-sm-6 {
      border-radius: 3rem !important;
    }
    .rounded-sm-7 {
      border-radius: 4rem !important;
    }
    .rounded-sm-8 {
      border-radius: 5rem !important;
    }
    .rounded-sm-9 {
      border-radius: 6rem !important;
    }
    .rounded-sm-10 {
      border-radius: 7rem !important;
    }
    .rounded-sm-pill {
      border-radius: 50rem !important;
    }
    .rounded-sm-circle {
      border-radius: 50% !important;
    }
    .rounded-sm-card {
      border-radius: 0.75rem !important;
    }
    .rounded-top-sm {
      border-top-left-radius: 0.375rem !important;
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-sm-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-top-sm-1 {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-sm-2 {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-sm-3 {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-sm-4 {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-sm-5 {
      border-top-left-radius: 1.5rem !important;
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-sm-6 {
      border-top-left-radius: 3rem !important;
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-sm-7 {
      border-top-left-radius: 4rem !important;
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-sm-8 {
      border-top-left-radius: 5rem !important;
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-sm-9 {
      border-top-left-radius: 6rem !important;
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-sm-10 {
      border-top-left-radius: 7rem !important;
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-sm-pill {
      border-top-left-radius: 50rem !important;
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-sm-circle {
      border-top-left-radius: 50% !important;
      border-top-right-radius: 50% !important;
    }
    .rounded-top-sm-card {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-end-sm {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-end-sm-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-end-sm-1 {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-end-sm-2 {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-end-sm-3 {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-end-sm-4 {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-end-sm-5 {
      border-top-right-radius: 1.5rem !important;
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-end-sm-6 {
      border-top-right-radius: 3rem !important;
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-end-sm-7 {
      border-top-right-radius: 4rem !important;
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-end-sm-8 {
      border-top-right-radius: 5rem !important;
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-end-sm-9 {
      border-top-right-radius: 6rem !important;
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-end-sm-10 {
      border-top-right-radius: 7rem !important;
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-end-sm-pill {
      border-top-right-radius: 50rem !important;
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-end-sm-circle {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .rounded-end-sm-card {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-sm {
      border-bottom-right-radius: 0.375rem !important;
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-sm-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-sm-1 {
      border-bottom-right-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-sm-2 {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-sm-3 {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-sm-4 {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-sm-5 {
      border-bottom-right-radius: 1.5rem !important;
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-sm-6 {
      border-bottom-right-radius: 3rem !important;
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-sm-7 {
      border-bottom-right-radius: 4rem !important;
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-sm-8 {
      border-bottom-right-radius: 5rem !important;
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-sm-9 {
      border-bottom-right-radius: 6rem !important;
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-sm-10 {
      border-bottom-right-radius: 7rem !important;
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-sm-pill {
      border-bottom-right-radius: 50rem !important;
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-sm-circle {
      border-bottom-right-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-sm-card {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-start-sm {
      border-bottom-left-radius: 0.375rem !important;
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-start-sm-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .rounded-start-sm-1 {
      border-bottom-left-radius: 0.25rem !important;
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-start-sm-2 {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-start-sm-3 {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-start-sm-4 {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-start-sm-5 {
      border-bottom-left-radius: 1.5rem !important;
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-start-sm-6 {
      border-bottom-left-radius: 3rem !important;
      border-top-left-radius: 3rem !important;
    }
    .rounded-start-sm-7 {
      border-bottom-left-radius: 4rem !important;
      border-top-left-radius: 4rem !important;
    }
    .rounded-start-sm-8 {
      border-bottom-left-radius: 5rem !important;
      border-top-left-radius: 5rem !important;
    }
    .rounded-start-sm-9 {
      border-bottom-left-radius: 6rem !important;
      border-top-left-radius: 6rem !important;
    }
    .rounded-start-sm-10 {
      border-bottom-left-radius: 7rem !important;
      border-top-left-radius: 7rem !important;
    }
    .rounded-start-sm-pill {
      border-bottom-left-radius: 50rem !important;
      border-top-left-radius: 50rem !important;
    }
    .rounded-start-sm-circle {
      border-bottom-left-radius: 50% !important;
      border-top-left-radius: 50% !important;
    }
    .rounded-start-sm-card {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-sm {
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-top-start-sm-0 {
      border-top-left-radius: 0 !important;
    }
    .rounded-top-start-sm-1 {
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-top-start-sm-2 {
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-top-start-sm-3 {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-sm-4 {
      border-top-left-radius: 1rem !important;
    }
    .rounded-top-start-sm-5 {
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-top-start-sm-6 {
      border-top-left-radius: 3rem !important;
    }
    .rounded-top-start-sm-7 {
      border-top-left-radius: 4rem !important;
    }
    .rounded-top-start-sm-8 {
      border-top-left-radius: 5rem !important;
    }
    .rounded-top-start-sm-9 {
      border-top-left-radius: 6rem !important;
    }
    .rounded-top-start-sm-10 {
      border-top-left-radius: 7rem !important;
    }
    .rounded-top-start-sm-pill {
      border-top-left-radius: 50rem !important;
    }
    .rounded-top-start-sm-circle {
      border-top-left-radius: 50% !important;
    }
    .rounded-top-start-sm-card {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-end-sm {
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-end-sm-0 {
      border-top-right-radius: 0 !important;
    }
    .rounded-top-end-sm-1 {
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-end-sm-2 {
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-end-sm-3 {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-end-sm-4 {
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-end-sm-5 {
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-end-sm-6 {
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-end-sm-7 {
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-end-sm-8 {
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-end-sm-9 {
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-end-sm-10 {
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-end-sm-pill {
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-end-sm-circle {
      border-top-right-radius: 50% !important;
    }
    .rounded-top-end-sm-card {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-sm {
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-bottom-end-sm-0 {
      border-bottom-right-radius: 0 !important;
    }
    .rounded-bottom-end-sm-1 {
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-bottom-end-sm-2 {
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-bottom-end-sm-3 {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-sm-4 {
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-bottom-end-sm-5 {
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-bottom-end-sm-6 {
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-bottom-end-sm-7 {
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-bottom-end-sm-8 {
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-bottom-end-sm-9 {
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-bottom-end-sm-10 {
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-bottom-end-sm-pill {
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-bottom-end-sm-circle {
      border-bottom-right-radius: 50% !important;
    }
    .rounded-bottom-end-sm-card {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-start-sm {
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-start-sm-0 {
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-start-sm-1 {
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-start-sm-2 {
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-start-sm-3 {
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-start-sm-4 {
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-start-sm-5 {
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-start-sm-6 {
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-start-sm-7 {
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-start-sm-8 {
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-start-sm-9 {
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-start-sm-10 {
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-start-sm-pill {
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-start-sm-circle {
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-start-sm-card {
      border-bottom-left-radius: 0.75rem !important;
    }
    .border-sm-solid {
      border-style: solid !important;
    }
    .border-sm-dashed {
      border-style: dashed !important;
    }
    .border-sm-dotted {
      border-style: dotted !important;
    }
    .border-sm-double {
      border-style: double !important;
    }
    .border-sm-groove {
      border-style: groove !important;
    }
    .border-sm-none {
      border-style: none !important;
    }
    .cursor-sm-auto {
      cursor: auto !important;
    }
    .cursor-sm-auto-hover:hover {
      cursor: auto !important;
    }
    .cursor-sm-pointer {
      cursor: pointer !important;
    }
    .cursor-sm-pointer-hover:hover {
      cursor: pointer !important;
    }
    .cursor-sm-wait {
      cursor: wait !important;
    }
    .cursor-sm-wait-hover:hover {
      cursor: wait !important;
    }
    .cursor-sm-text {
      cursor: text !important;
    }
    .cursor-sm-text-hover:hover {
      cursor: text !important;
    }
    .ls-sm-tighter {
      letter-spacing: -0.05em !important;
    }
    .ls-sm-tight {
      letter-spacing: -0.025em !important;
    }
    .ls-sm-normal {
      letter-spacing: 0 !important;
    }
    .ls-sm-wide {
      letter-spacing: 0.025em !important;
    }
    .ls-sm-wider {
      letter-spacing: 0.05em !important;
    }
    .ls-sm-widest {
      letter-spacing: 0.1em !important;
    }
    .bg-sm-auto {
      background-size: auto !important;
    }
    .bg-sm-cover {
      background-size: cover !important;
    }
    .bg-sm-contain {
      background-size: contain !important;
    }
    .min-w-sm-0 {
      min-width: 0 !important;
    }
    .min-w-sm-full {
      min-width: 100% !important;
    }
    .min-w-sm-min {
      min-width: -webkit-min-content !important;
      min-width: -moz-min-content !important;
      min-width: min-content !important;
    }
    .min-w-sm-max {
      min-width: -webkit-max-content !important;
      min-width: -moz-max-content !important;
      min-width: max-content !important;
    }
    .min-h-sm-0 {
      min-height: 0 !important;
    }
    .min-h-sm-full {
      min-height: 100% !important;
    }
    .min-h-sm-screen {
      min-height: 100vh !important;
    }
    .inset-sm-0 {
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
    }
    .inset-sm-1 {
      top: 0.25rem !important;
      right: 0.25rem !important;
      bottom: 0.25rem !important;
      left: 0.25rem !important;
    }
    .inset-sm-2 {
      top: 0.5rem !important;
      right: 0.5rem !important;
      bottom: 0.5rem !important;
      left: 0.5rem !important;
    }
    .inset-sm-3 {
      top: 0.75rem !important;
      right: 0.75rem !important;
      bottom: 0.75rem !important;
      left: 0.75rem !important;
    }
    .inset-sm-4 {
      top: 1rem !important;
      right: 1rem !important;
      bottom: 1rem !important;
      left: 1rem !important;
    }
    .inset-sm-5 {
      top: 1.25rem !important;
      right: 1.25rem !important;
      bottom: 1.25rem !important;
      left: 1.25rem !important;
    }
    .inset-sm-6 {
      top: 1.5rem !important;
      right: 1.5rem !important;
      bottom: 1.5rem !important;
      left: 1.5rem !important;
    }
    .inset-sm-7 {
      top: 1.75rem !important;
      right: 1.75rem !important;
      bottom: 1.75rem !important;
      left: 1.75rem !important;
    }
    .inset-sm-8 {
      top: 2rem !important;
      right: 2rem !important;
      bottom: 2rem !important;
      left: 2rem !important;
    }
    .inset-sm-10 {
      top: 2.5rem !important;
      right: 2.5rem !important;
      bottom: 2.5rem !important;
      left: 2.5rem !important;
    }
    .inset-sm-12 {
      top: 3rem !important;
      right: 3rem !important;
      bottom: 3rem !important;
      left: 3rem !important;
    }
    .inset-sm-14 {
      top: 3.5rem !important;
      right: 3.5rem !important;
      bottom: 3.5rem !important;
      left: 3.5rem !important;
    }
    .inset-sm-16 {
      top: 4rem !important;
      right: 4rem !important;
      bottom: 4rem !important;
      left: 4rem !important;
    }
    .inset-sm-18 {
      top: 4.5rem !important;
      right: 4.5rem !important;
      bottom: 4.5rem !important;
      left: 4.5rem !important;
    }
    .inset-sm-20 {
      top: 5rem !important;
      right: 5rem !important;
      bottom: 5rem !important;
      left: 5rem !important;
    }
    .inset-sm-24 {
      top: 6rem !important;
      right: 6rem !important;
      bottom: 6rem !important;
      left: 6rem !important;
    }
    .inset-sm-32 {
      top: 8rem !important;
      right: 8rem !important;
      bottom: 8rem !important;
      left: 8rem !important;
    }
    .inset-sm-40 {
      top: 10rem !important;
      right: 10rem !important;
      bottom: 10rem !important;
      left: 10rem !important;
    }
    .inset-sm-48 {
      top: 12rem !important;
      right: 12rem !important;
      bottom: 12rem !important;
      left: 12rem !important;
    }
    .inset-sm-56 {
      top: 14rem !important;
      right: 14rem !important;
      bottom: 14rem !important;
      left: 14rem !important;
    }
    .inset-sm-64 {
      top: 16rem !important;
      right: 16rem !important;
      bottom: 16rem !important;
      left: 16rem !important;
    }
    .inset-sm-72 {
      top: 18rem !important;
      right: 18rem !important;
      bottom: 18rem !important;
      left: 18rem !important;
    }
    .inset-sm-80 {
      top: 20rem !important;
      right: 20rem !important;
      bottom: 20rem !important;
      left: 20rem !important;
    }
    .inset-sm-88 {
      top: 22rem !important;
      right: 22rem !important;
      bottom: 22rem !important;
      left: 22rem !important;
    }
    .inset-sm-96 {
      top: 24rem !important;
      right: 24rem !important;
      bottom: 24rem !important;
      left: 24rem !important;
    }
    .inset-sm-px {
      top: 1px !important;
      right: 1px !important;
      bottom: 1px !important;
      left: 1px !important;
    }
    .inset-sm-1\/2 {
      top: 50% !important;
      right: 50% !important;
      bottom: 50% !important;
      left: 50% !important;
    }
    .inset-sm-full {
      top: 100% !important;
      right: 100% !important;
      bottom: 100% !important;
      left: 100% !important;
    }
    .inset-sm-auto {
      top: auto !important;
      right: auto !important;
      bottom: auto !important;
      left: auto !important;
    }
    .inset-x-sm-0 {
      left: 0 !important;
      right: 0 !important;
    }
    .inset-x-sm-1 {
      left: 0.25rem !important;
      right: 0.25rem !important;
    }
    .inset-x-sm-2 {
      left: 0.5rem !important;
      right: 0.5rem !important;
    }
    .inset-x-sm-3 {
      left: 0.75rem !important;
      right: 0.75rem !important;
    }
    .inset-x-sm-4 {
      left: 1rem !important;
      right: 1rem !important;
    }
    .inset-x-sm-5 {
      left: 1.25rem !important;
      right: 1.25rem !important;
    }
    .inset-x-sm-6 {
      left: 1.5rem !important;
      right: 1.5rem !important;
    }
    .inset-x-sm-7 {
      left: 1.75rem !important;
      right: 1.75rem !important;
    }
    .inset-x-sm-8 {
      left: 2rem !important;
      right: 2rem !important;
    }
    .inset-x-sm-10 {
      left: 2.5rem !important;
      right: 2.5rem !important;
    }
    .inset-x-sm-12 {
      left: 3rem !important;
      right: 3rem !important;
    }
    .inset-x-sm-14 {
      left: 3.5rem !important;
      right: 3.5rem !important;
    }
    .inset-x-sm-16 {
      left: 4rem !important;
      right: 4rem !important;
    }
    .inset-x-sm-18 {
      left: 4.5rem !important;
      right: 4.5rem !important;
    }
    .inset-x-sm-20 {
      left: 5rem !important;
      right: 5rem !important;
    }
    .inset-x-sm-24 {
      left: 6rem !important;
      right: 6rem !important;
    }
    .inset-x-sm-32 {
      left: 8rem !important;
      right: 8rem !important;
    }
    .inset-x-sm-40 {
      left: 10rem !important;
      right: 10rem !important;
    }
    .inset-x-sm-48 {
      left: 12rem !important;
      right: 12rem !important;
    }
    .inset-x-sm-56 {
      left: 14rem !important;
      right: 14rem !important;
    }
    .inset-x-sm-64 {
      left: 16rem !important;
      right: 16rem !important;
    }
    .inset-x-sm-72 {
      left: 18rem !important;
      right: 18rem !important;
    }
    .inset-x-sm-80 {
      left: 20rem !important;
      right: 20rem !important;
    }
    .inset-x-sm-88 {
      left: 22rem !important;
      right: 22rem !important;
    }
    .inset-x-sm-96 {
      left: 24rem !important;
      right: 24rem !important;
    }
    .inset-x-sm-px {
      left: 1px !important;
      right: 1px !important;
    }
    .inset-x-sm-1\/2 {
      left: 50% !important;
      right: 50% !important;
    }
    .inset-x-sm-full {
      left: 100% !important;
      right: 100% !important;
    }
    .inset-x-sm-auto {
      left: auto !important;
      right: auto !important;
    }
    .inset-y-sm-0 {
      top: 0 !important;
      bottom: 0 !important;
    }
    .inset-y-sm-1 {
      top: 0.25rem !important;
      bottom: 0.25rem !important;
    }
    .inset-y-sm-2 {
      top: 0.5rem !important;
      bottom: 0.5rem !important;
    }
    .inset-y-sm-3 {
      top: 0.75rem !important;
      bottom: 0.75rem !important;
    }
    .inset-y-sm-4 {
      top: 1rem !important;
      bottom: 1rem !important;
    }
    .inset-y-sm-5 {
      top: 1.25rem !important;
      bottom: 1.25rem !important;
    }
    .inset-y-sm-6 {
      top: 1.5rem !important;
      bottom: 1.5rem !important;
    }
    .inset-y-sm-7 {
      top: 1.75rem !important;
      bottom: 1.75rem !important;
    }
    .inset-y-sm-8 {
      top: 2rem !important;
      bottom: 2rem !important;
    }
    .inset-y-sm-10 {
      top: 2.5rem !important;
      bottom: 2.5rem !important;
    }
    .inset-y-sm-12 {
      top: 3rem !important;
      bottom: 3rem !important;
    }
    .inset-y-sm-14 {
      top: 3.5rem !important;
      bottom: 3.5rem !important;
    }
    .inset-y-sm-16 {
      top: 4rem !important;
      bottom: 4rem !important;
    }
    .inset-y-sm-18 {
      top: 4.5rem !important;
      bottom: 4.5rem !important;
    }
    .inset-y-sm-20 {
      top: 5rem !important;
      bottom: 5rem !important;
    }
    .inset-y-sm-24 {
      top: 6rem !important;
      bottom: 6rem !important;
    }
    .inset-y-sm-32 {
      top: 8rem !important;
      bottom: 8rem !important;
    }
    .inset-y-sm-40 {
      top: 10rem !important;
      bottom: 10rem !important;
    }
    .inset-y-sm-48 {
      top: 12rem !important;
      bottom: 12rem !important;
    }
    .inset-y-sm-56 {
      top: 14rem !important;
      bottom: 14rem !important;
    }
    .inset-y-sm-64 {
      top: 16rem !important;
      bottom: 16rem !important;
    }
    .inset-y-sm-72 {
      top: 18rem !important;
      bottom: 18rem !important;
    }
    .inset-y-sm-80 {
      top: 20rem !important;
      bottom: 20rem !important;
    }
    .inset-y-sm-88 {
      top: 22rem !important;
      bottom: 22rem !important;
    }
    .inset-y-sm-96 {
      top: 24rem !important;
      bottom: 24rem !important;
    }
    .inset-y-sm-px {
      top: 1px !important;
      bottom: 1px !important;
    }
    .inset-y-sm-1\/2 {
      top: 50% !important;
      bottom: 50% !important;
    }
    .inset-y-sm-full {
      top: 100% !important;
      bottom: 100% !important;
    }
    .inset-y-sm-auto {
      top: auto !important;
      bottom: auto !important;
    }
    .place-content-sm-start {
      place-content: flex-start !important;
    }
    .place-content-sm-end {
      place-content: flex-end !important;
    }
    .place-content-sm-center {
      place-content: center !important;
    }
    .place-content-sm-between {
      place-content: space-between !important;
    }
    .place-content-sm-around {
      place-content: space-around !important;
    }
    .place-content-sm-evenly {
      place-content: space-evenly !important;
    }
    .place-content-sm-stretch {
      place-content: stretch !important;
    }
    .overflow-x-sm-auto {
      overflow-x: auto !important;
    }
    .overflow-x-sm-hidden {
      overflow-x: hidden !important;
    }
    .overflow-x-sm-visible {
      overflow-x: visible !important;
    }
    .overflow-x-sm-scroll {
      overflow-x: scroll !important;
    }
    .overflow-y-sm-auto {
      overflow-y: auto !important;
    }
    .overflow-y-sm-hidden {
      overflow-y: hidden !important;
    }
    .overflow-y-sm-visible {
      overflow-y: visible !important;
    }
    .overflow-y-sm-scroll {
      overflow-y: scroll !important;
    }
    .transition-sm {
      transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform !important;
    }
    .transition-sm-none {
      transition-property: none !important;
    }
    .transition-sm-all {
      transition-property: all !important;
    }
    .transition-sm-colors {
      transition-property: background-color, border-color, color, fill, stroke !important;
    }
    .transition-sm-opacity {
      transition-property: opacity !important;
    }
    .transition-sm-shadow {
      transition-property: box-shadow !important;
    }
    .transition-sm-transform {
      transition-property: transform !important;
    }
    .ease-sm-base {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .ease-sm-linear {
      transition-timing-function: linear !important;
    }
    .ease-sm-in {
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
    }
    .ease-sm-out {
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
    }
    .ease-sm-in-out {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .duration-sm-base {
      transition-duration: 150ms !important;
    }
    .duration-sm-75 {
      transition-duration: 75ms !important;
    }
    .duration-sm-100 {
      transition-duration: 100ms !important;
    }
    .duration-sm-150 {
      transition-duration: 150ms !important;
    }
    .duration-sm-200 {
      transition-duration: 200ms !important;
    }
    .duration-sm-300 {
      transition-duration: 300ms !important;
    }
    .duration-sm-500 {
      transition-duration: 500ms !important;
    }
    .duration-sm-700 {
      transition-duration: 700ms !important;
    }
    .duration-sm-1000 {
      transition-duration: 1000ms !important;
    }
    .delay-sm-75 {
      transition-delay: 75ms !important;
    }
    .delay-sm-100 {
      transition-delay: 100ms !important;
    }
    .delay-sm-150 {
      transition-delay: 150ms !important;
    }
    .delay-sm-200 {
      transition-delay: 200ms !important;
    }
    .delay-sm-300 {
      transition-delay: 300ms !important;
    }
    .delay-sm-500 {
      transition-delay: 500ms !important;
    }
    .delay-sm-700 {
      transition-delay: 700ms !important;
    }
    .delay-sm-1000 {
      transition-delay: 1000ms !important;
    }
    .animation-sm-none {
      -webkit-animation: none !important;
      animation: none !important;
    }
    .animation-sm-spin {
      -webkit-animation: spin 1s linear infinite !important;
      animation: spin 1s linear infinite !important;
    }
    .animation-sm-ping {
      -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
      animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
    }
    .animation-sm-pulse {
      -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
    }
    .animation-sm-bounce {
      -webkit-animation: bounce 1s infinite !important;
      animation: bounce 1s infinite !important;
    }
    .animation-sm-slide-left {
      -webkit-animation: slideLeft 3s infinite !important;
      animation: slideLeft 3s infinite !important;
    }
    .animation-sm-slide-right {
      -webkit-animation: slideRight 3s infinite !important;
      animation: slideRight 3s infinite !important;
    }
    .animation-sm-move-slow-left {
      -webkit-animation: slideLeft 15s linear infinite !important;
      animation: slideLeft 15s linear infinite !important;
    }
    .animation-sm-move-slow-right {
      -webkit-animation: slideRight 15s linear infinite !important;
      animation: slideRight 15s linear infinite !important;
    }
    .blur-sm-5 {
      --x-blur: 5px !important;
    }
    .blur-sm-10 {
      --x-blur: 10px !important;
    }
    .blur-sm-25 {
      --x-blur: 25px !important;
    }
    .blur-sm-50 {
      --x-blur: 50px !important;
    }
    .blur-sm-75 {
      --x-blur: 75px !important;
    }
    .blur-sm-100 {
      --x-blur: 100px !important;
    }
    .blur-sm-125 {
      --x-blur: 125px !important;
    }
    .blur-sm-150 {
      --x-blur: 150px !important;
    }
    .blur-sm-200 {
      --x-blur: 200px !important;
    }
    .transform-sm-none {
      transform: none !important;
    }
    .origin-sm-center {
      transform-origin: center !important;
    }
    .origin-sm-center-hover:hover {
      transform-origin: center !important;
    }
    .origin-sm-top {
      transform-origin: top !important;
    }
    .origin-sm-top-hover:hover {
      transform-origin: top !important;
    }
    .origin-sm-top-right {
      transform-origin: top right !important;
    }
    .origin-sm-top-right-hover:hover {
      transform-origin: top right !important;
    }
    .origin-sm-right {
      transform-origin: right !important;
    }
    .origin-sm-right-hover:hover {
      transform-origin: right !important;
    }
    .origin-sm-bottom-right {
      transform-origin: bottom right !important;
    }
    .origin-sm-bottom-right-hover:hover {
      transform-origin: bottom right !important;
    }
    .origin-sm-bottom {
      transform-origin: bottom !important;
    }
    .origin-sm-bottom-hover:hover {
      transform-origin: bottom !important;
    }
    .origin-sm-bottom-left {
      transform-origin: bottom left !important;
    }
    .origin-sm-bottom-left-hover:hover {
      transform-origin: bottom left !important;
    }
    .origin-sm-left {
      transform-origin: left !important;
    }
    .origin-sm-left-hover:hover {
      transform-origin: left !important;
    }
    .origin-sm-top-left {
      transform-origin: top left !important;
    }
    .origin-sm-top-left-hover:hover {
      transform-origin: top left !important;
    }
    .scale-sm-0 {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-sm-0-hover:hover {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-sm-25 {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-sm-25-hover:hover {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-sm-50 {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-sm-50-hover:hover {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-sm-75 {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-sm-75-hover:hover {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-sm-90 {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-sm-90-hover:hover {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-sm-95 {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-sm-95-hover:hover {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-sm-100 {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-sm-100-hover:hover {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-sm-105 {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-sm-105-hover:hover {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-sm-110 {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-sm-110-hover:hover {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-sm-125 {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-sm-125-hover:hover {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-sm-150 {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-sm-150-hover:hover {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-sm-200 {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-sm-200-hover:hover {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-y-sm-0 {
      --x--scale-y: 0 !important;
    }
    .scale-y-sm-0-hover:hover {
      --x--scale-y: 0 !important;
    }
    .scale-y-sm-25 {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-sm-25-hover:hover {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-sm-50 {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-sm-50-hover:hover {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-sm-75 {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-sm-75-hover:hover {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-sm-90 {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-sm-90-hover:hover {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-sm-95 {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-sm-95-hover:hover {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-sm-100 {
      --x--scale-y: 1 !important;
    }
    .scale-y-sm-100-hover:hover {
      --x--scale-y: 1 !important;
    }
    .scale-y-sm-105 {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-sm-105-hover:hover {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-sm-110 {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-sm-110-hover:hover {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-sm-125 {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-sm-125-hover:hover {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-sm-150 {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-sm-150-hover:hover {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-sm-200 {
      --x--scale-y: 2 !important;
    }
    .scale-y-sm-200-hover:hover {
      --x--scale-y: 2 !important;
    }
    .scale-x-sm-0 {
      --x--scale-x: 0 !important;
    }
    .scale-x-sm-0-hover:hover {
      --x--scale-x: 0 !important;
    }
    .scale-x-sm-25 {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-sm-25-hover:hover {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-sm-50 {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-sm-50-hover:hover {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-sm-75 {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-sm-75-hover:hover {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-sm-90 {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-sm-90-hover:hover {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-sm-95 {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-sm-95-hover:hover {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-sm-100 {
      --x--scale-x: 1 !important;
    }
    .scale-x-sm-100-hover:hover {
      --x--scale-x: 1 !important;
    }
    .scale-x-sm-105 {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-sm-105-hover:hover {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-sm-110 {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-sm-110-hover:hover {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-sm-125 {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-sm-125-hover:hover {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-sm-150 {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-sm-150-hover:hover {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-sm-200 {
      --x--scale-x: 2 !important;
    }
    .scale-x-sm-200-hover:hover {
      --x--scale-x: 2 !important;
    }
    .rotate-sm-0 {
      --x-rotate: 0deg !important;
    }
    .rotate-sm-0-hover:hover {
      --x-rotate: 0deg !important;
    }
    .rotate-sm-1 {
      --x-rotate: 1deg !important;
    }
    .rotate-sm-1-hover:hover {
      --x-rotate: 1deg !important;
    }
    .rotate-sm-2 {
      --x-rotate: 2deg !important;
    }
    .rotate-sm-2-hover:hover {
      --x-rotate: 2deg !important;
    }
    .rotate-sm-3 {
      --x-rotate: 3deg !important;
    }
    .rotate-sm-3-hover:hover {
      --x-rotate: 3deg !important;
    }
    .rotate-sm-6 {
      --x-rotate: 6deg !important;
    }
    .rotate-sm-6-hover:hover {
      --x-rotate: 6deg !important;
    }
    .rotate-sm-12 {
      --x-rotate: 12deg !important;
    }
    .rotate-sm-12-hover:hover {
      --x-rotate: 12deg !important;
    }
    .rotate-sm-30 {
      --x-rotate: 30deg !important;
    }
    .rotate-sm-30-hover:hover {
      --x-rotate: 30deg !important;
    }
    .rotate-sm-45 {
      --x-rotate: 45deg !important;
    }
    .rotate-sm-45-hover:hover {
      --x-rotate: 45deg !important;
    }
    .rotate-sm-90 {
      --x-rotate: 90deg !important;
    }
    .rotate-sm-90-hover:hover {
      --x-rotate: 90deg !important;
    }
    .rotate-sm-180 {
      --x-rotate: 180deg !important;
    }
    .rotate-sm-180-hover:hover {
      --x-rotate: 180deg !important;
    }
    .rotate-sm-n1 {
      --x-rotate: -1deg !important;
    }
    .rotate-sm-n1-hover:hover {
      --x-rotate: -1deg !important;
    }
    .rotate-sm-n2 {
      --x-rotate: -2deg !important;
    }
    .rotate-sm-n2-hover:hover {
      --x-rotate: -2deg !important;
    }
    .rotate-sm-n3 {
      --x-rotate: -3deg !important;
    }
    .rotate-sm-n3-hover:hover {
      --x-rotate: -3deg !important;
    }
    .rotate-sm-n6 {
      --x-rotate: -6deg !important;
    }
    .rotate-sm-n6-hover:hover {
      --x-rotate: -6deg !important;
    }
    .rotate-sm-n12 {
      --x-rotate: -12deg !important;
    }
    .rotate-sm-n12-hover:hover {
      --x-rotate: -12deg !important;
    }
    .rotate-sm-n30 {
      --x-rotate: -30deg !important;
    }
    .rotate-sm-n30-hover:hover {
      --x-rotate: -30deg !important;
    }
    .rotate-sm-n45 {
      --x-rotate: -45deg !important;
    }
    .rotate-sm-n45-hover:hover {
      --x-rotate: -45deg !important;
    }
    .rotate-sm-n90 {
      --x-rotate: -90deg !important;
    }
    .rotate-sm-n90-hover:hover {
      --x-rotate: -90deg !important;
    }
    .rotate-sm-n180 {
      --x-rotate: -180deg !important;
    }
    .rotate-sm-n180-hover:hover {
      --x-rotate: -180deg !important;
    }
    .rotate-y-sm-0 {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-sm-0-hover:hover {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-sm-1 {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-sm-1-hover:hover {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-sm-2 {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-sm-2-hover:hover {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-sm-3 {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-sm-3-hover:hover {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-sm-6 {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-sm-6-hover:hover {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-sm-12 {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-sm-12-hover:hover {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-sm-30 {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-sm-30-hover:hover {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-sm-45 {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-sm-45-hover:hover {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-sm-90 {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-sm-90-hover:hover {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-sm-180 {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-sm-180-hover:hover {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-sm-n1 {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-sm-n1-hover:hover {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-sm-n2 {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-sm-n2-hover:hover {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-sm-n3 {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-sm-n3-hover:hover {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-sm-n6 {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-sm-n6-hover:hover {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-sm-n12 {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-sm-n12-hover:hover {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-sm-n30 {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-sm-n30-hover:hover {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-sm-n45 {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-sm-n45-hover:hover {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-sm-n90 {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-sm-n90-hover:hover {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-sm-n180 {
      --x-rotate-y: -180deg !important;
    }
    .rotate-y-sm-n180-hover:hover {
      --x-rotate-y: -180deg !important;
    }
    .rotate-x-sm-0 {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-sm-0-hover:hover {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-sm-1 {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-sm-1-hover:hover {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-sm-2 {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-sm-2-hover:hover {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-sm-3 {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-sm-3-hover:hover {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-sm-6 {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-sm-6-hover:hover {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-sm-12 {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-sm-12-hover:hover {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-sm-30 {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-sm-30-hover:hover {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-sm-45 {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-sm-45-hover:hover {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-sm-90 {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-sm-90-hover:hover {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-sm-180 {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-sm-180-hover:hover {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-sm-n1 {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-sm-n1-hover:hover {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-sm-n2 {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-sm-n2-hover:hover {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-sm-n3 {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-sm-n3-hover:hover {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-sm-n6 {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-sm-n6-hover:hover {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-sm-n12 {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-sm-n12-hover:hover {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-sm-n30 {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-sm-n30-hover:hover {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-sm-n45 {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-sm-n45-hover:hover {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-sm-n90 {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-sm-n90-hover:hover {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-sm-n180 {
      --x-rotate-x: -180deg !important;
    }
    .rotate-x-sm-n180-hover:hover {
      --x-rotate-x: -180deg !important;
    }
    .perspective-sm-100 {
      --x-perspective: 100px !important;
    }
    .perspective-sm-100-hover:hover {
      --x-perspective: 100px !important;
    }
    .perspective-sm-200 {
      --x-perspective: 200px !important;
    }
    .perspective-sm-200-hover:hover {
      --x-perspective: 200px !important;
    }
    .perspective-sm-300 {
      --x-perspective: 300px !important;
    }
    .perspective-sm-300-hover:hover {
      --x-perspective: 300px !important;
    }
    .perspective-sm-400 {
      --x-perspective: 400px !important;
    }
    .perspective-sm-400-hover:hover {
      --x-perspective: 400px !important;
    }
    .perspective-sm-500 {
      --x-perspective: 500px !important;
    }
    .perspective-sm-500-hover:hover {
      --x-perspective: 500px !important;
    }
    .perspective-sm-600 {
      --x-perspective: 600px !important;
    }
    .perspective-sm-600-hover:hover {
      --x-perspective: 600px !important;
    }
    .perspective-sm-700 {
      --x-perspective: 700px !important;
    }
    .perspective-sm-700-hover:hover {
      --x-perspective: 700px !important;
    }
    .perspective-sm-800 {
      --x-perspective: 800px !important;
    }
    .perspective-sm-800-hover:hover {
      --x-perspective: 800px !important;
    }
    .perspective-sm-1000 {
      --x-perspective: 1000px !important;
    }
    .perspective-sm-1000-hover:hover {
      --x-perspective: 1000px !important;
    }
    .perspective-sm-1250 {
      --x-perspective: 1250px !important;
    }
    .perspective-sm-1250-hover:hover {
      --x-perspective: 1250px !important;
    }
    .perspective-sm-1500 {
      --x-perspective: 1500px !important;
    }
    .perspective-sm-1500-hover:hover {
      --x-perspective: 1500px !important;
    }
    .translate-y-sm-0 {
      --x-translate-y: 0 !important;
    }
    .translate-y-sm-0-hover:hover {
      --x-translate-y: 0 !important;
    }
    .translate-y-sm-1 {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-sm-1-hover:hover {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-sm-2 {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-sm-2-hover:hover {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-sm-3 {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-sm-3-hover:hover {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-sm-4 {
      --x-translate-y: 1rem !important;
    }
    .translate-y-sm-4-hover:hover {
      --x-translate-y: 1rem !important;
    }
    .translate-y-sm-5 {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-sm-5-hover:hover {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-sm-6 {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-sm-6-hover:hover {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-sm-7 {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-sm-7-hover:hover {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-sm-8 {
      --x-translate-y: 2rem !important;
    }
    .translate-y-sm-8-hover:hover {
      --x-translate-y: 2rem !important;
    }
    .translate-y-sm-10 {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-sm-10-hover:hover {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-sm-12 {
      --x-translate-y: 3rem !important;
    }
    .translate-y-sm-12-hover:hover {
      --x-translate-y: 3rem !important;
    }
    .translate-y-sm-14 {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-sm-14-hover:hover {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-sm-16 {
      --x-translate-y: 4rem !important;
    }
    .translate-y-sm-16-hover:hover {
      --x-translate-y: 4rem !important;
    }
    .translate-y-sm-18 {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-sm-18-hover:hover {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-sm-20 {
      --x-translate-y: 5rem !important;
    }
    .translate-y-sm-20-hover:hover {
      --x-translate-y: 5rem !important;
    }
    .translate-y-sm-24 {
      --x-translate-y: 6rem !important;
    }
    .translate-y-sm-24-hover:hover {
      --x-translate-y: 6rem !important;
    }
    .translate-y-sm-32 {
      --x-translate-y: 8rem !important;
    }
    .translate-y-sm-32-hover:hover {
      --x-translate-y: 8rem !important;
    }
    .translate-y-sm-40 {
      --x-translate-y: 10rem !important;
    }
    .translate-y-sm-40-hover:hover {
      --x-translate-y: 10rem !important;
    }
    .translate-y-sm-48 {
      --x-translate-y: 12rem !important;
    }
    .translate-y-sm-48-hover:hover {
      --x-translate-y: 12rem !important;
    }
    .translate-y-sm-56 {
      --x-translate-y: 14rem !important;
    }
    .translate-y-sm-56-hover:hover {
      --x-translate-y: 14rem !important;
    }
    .translate-y-sm-64 {
      --x-translate-y: 16rem !important;
    }
    .translate-y-sm-64-hover:hover {
      --x-translate-y: 16rem !important;
    }
    .translate-y-sm-72 {
      --x-translate-y: 18rem !important;
    }
    .translate-y-sm-72-hover:hover {
      --x-translate-y: 18rem !important;
    }
    .translate-y-sm-80 {
      --x-translate-y: 20rem !important;
    }
    .translate-y-sm-80-hover:hover {
      --x-translate-y: 20rem !important;
    }
    .translate-y-sm-88 {
      --x-translate-y: 22rem !important;
    }
    .translate-y-sm-88-hover:hover {
      --x-translate-y: 22rem !important;
    }
    .translate-y-sm-96 {
      --x-translate-y: 24rem !important;
    }
    .translate-y-sm-96-hover:hover {
      --x-translate-y: 24rem !important;
    }
    .translate-y-sm-px {
      --x-translate-y: 1px !important;
    }
    .translate-y-sm-px-hover:hover {
      --x-translate-y: 1px !important;
    }
    .translate-y-sm-1\/2 {
      --x-translate-y: 50% !important;
    }
    .translate-y-sm-1\/2-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-sm-1\/3 {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-sm-1\/3-hover:hover {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-sm-1\/4 {
      --x-translate-y: 25% !important;
    }
    .translate-y-sm-1\/4-hover:hover {
      --x-translate-y: 25% !important;
    }
    .translate-y-sm-1\/5 {
      --x-translate-y: 20% !important;
    }
    .translate-y-sm-1\/5-hover:hover {
      --x-translate-y: 20% !important;
    }
    .translate-y-sm-2\/3 {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-sm-2\/3-hover:hover {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-sm-2\/5 {
      --x-translate-y: 50% !important;
    }
    .translate-y-sm-2\/5-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-sm-3\/4 {
      --x-translate-y: 75% !important;
    }
    .translate-y-sm-3\/4-hover:hover {
      --x-translate-y: 75% !important;
    }
    .translate-y-sm-full {
      --x-translate-y: 100% !important;
    }
    .translate-y-sm-full-hover:hover {
      --x-translate-y: 100% !important;
    }
    .translate-y-sm-n1 {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-sm-n1-hover:hover {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-sm-n2 {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-sm-n2-hover:hover {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-sm-n3 {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-sm-n3-hover:hover {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-sm-n4 {
      --x-translate-y: -1rem !important;
    }
    .translate-y-sm-n4-hover:hover {
      --x-translate-y: -1rem !important;
    }
    .translate-y-sm-n5 {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-sm-n5-hover:hover {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-sm-n6 {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-sm-n6-hover:hover {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-sm-n7 {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-sm-n7-hover:hover {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-sm-n8 {
      --x-translate-y: -2rem !important;
    }
    .translate-y-sm-n8-hover:hover {
      --x-translate-y: -2rem !important;
    }
    .translate-y-sm-n10 {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-sm-n10-hover:hover {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-sm-n12 {
      --x-translate-y: -3rem !important;
    }
    .translate-y-sm-n12-hover:hover {
      --x-translate-y: -3rem !important;
    }
    .translate-y-sm-n14 {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-sm-n14-hover:hover {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-sm-n16 {
      --x-translate-y: -4rem !important;
    }
    .translate-y-sm-n16-hover:hover {
      --x-translate-y: -4rem !important;
    }
    .translate-y-sm-n18 {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-sm-n18-hover:hover {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-sm-n20 {
      --x-translate-y: -5rem !important;
    }
    .translate-y-sm-n20-hover:hover {
      --x-translate-y: -5rem !important;
    }
    .translate-y-sm-n24 {
      --x-translate-y: -6rem !important;
    }
    .translate-y-sm-n24-hover:hover {
      --x-translate-y: -6rem !important;
    }
    .translate-y-sm-n32 {
      --x-translate-y: -8rem !important;
    }
    .translate-y-sm-n32-hover:hover {
      --x-translate-y: -8rem !important;
    }
    .translate-y-sm-n40 {
      --x-translate-y: -10rem !important;
    }
    .translate-y-sm-n40-hover:hover {
      --x-translate-y: -10rem !important;
    }
    .translate-y-sm-n48 {
      --x-translate-y: -12rem !important;
    }
    .translate-y-sm-n48-hover:hover {
      --x-translate-y: -12rem !important;
    }
    .translate-y-sm-n56 {
      --x-translate-y: -14rem !important;
    }
    .translate-y-sm-n56-hover:hover {
      --x-translate-y: -14rem !important;
    }
    .translate-y-sm-n64 {
      --x-translate-y: -16rem !important;
    }
    .translate-y-sm-n64-hover:hover {
      --x-translate-y: -16rem !important;
    }
    .translate-y-sm-n72 {
      --x-translate-y: -18rem !important;
    }
    .translate-y-sm-n72-hover:hover {
      --x-translate-y: -18rem !important;
    }
    .translate-y-sm-n80 {
      --x-translate-y: -20rem !important;
    }
    .translate-y-sm-n80-hover:hover {
      --x-translate-y: -20rem !important;
    }
    .translate-y-sm-n88 {
      --x-translate-y: -22rem !important;
    }
    .translate-y-sm-n88-hover:hover {
      --x-translate-y: -22rem !important;
    }
    .translate-y-sm-n96 {
      --x-translate-y: -24rem !important;
    }
    .translate-y-sm-n96-hover:hover {
      --x-translate-y: -24rem !important;
    }
    .translate-y-sm-npx {
      --x-translate-y: -1px !important;
    }
    .translate-y-sm-npx-hover:hover {
      --x-translate-y: -1px !important;
    }
    .translate-y-sm-n1\/2 {
      --x-translate-y: -50% !important;
    }
    .translate-y-sm-n1\/2-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-sm-n1\/3 {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-sm-n1\/3-hover:hover {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-sm-n1\/4 {
      --x-translate-y: -25% !important;
    }
    .translate-y-sm-n1\/4-hover:hover {
      --x-translate-y: -25% !important;
    }
    .translate-y-sm-n1\/5 {
      --x-translate-y: -20% !important;
    }
    .translate-y-sm-n1\/5-hover:hover {
      --x-translate-y: -20% !important;
    }
    .translate-y-sm-n2\/3 {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-sm-n2\/3-hover:hover {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-sm-n2\/5 {
      --x-translate-y: -50% !important;
    }
    .translate-y-sm-n2\/5-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-sm-n3\/4 {
      --x-translate-y: -75% !important;
    }
    .translate-y-sm-n3\/4-hover:hover {
      --x-translate-y: -75% !important;
    }
    .translate-y-sm-nfull {
      --x-translate-y: -100% !important;
    }
    .translate-y-sm-nfull-hover:hover {
      --x-translate-y: -100% !important;
    }
    .translate-x-sm-0 {
      --x-translate-x: 0 !important;
    }
    .translate-x-sm-0-hover:hover {
      --x-translate-x: 0 !important;
    }
    .translate-x-sm-1 {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-sm-1-hover:hover {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-sm-2 {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-sm-2-hover:hover {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-sm-3 {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-sm-3-hover:hover {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-sm-4 {
      --x-translate-x: 1rem !important;
    }
    .translate-x-sm-4-hover:hover {
      --x-translate-x: 1rem !important;
    }
    .translate-x-sm-5 {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-sm-5-hover:hover {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-sm-6 {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-sm-6-hover:hover {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-sm-7 {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-sm-7-hover:hover {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-sm-8 {
      --x-translate-x: 2rem !important;
    }
    .translate-x-sm-8-hover:hover {
      --x-translate-x: 2rem !important;
    }
    .translate-x-sm-10 {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-sm-10-hover:hover {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-sm-12 {
      --x-translate-x: 3rem !important;
    }
    .translate-x-sm-12-hover:hover {
      --x-translate-x: 3rem !important;
    }
    .translate-x-sm-14 {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-sm-14-hover:hover {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-sm-16 {
      --x-translate-x: 4rem !important;
    }
    .translate-x-sm-16-hover:hover {
      --x-translate-x: 4rem !important;
    }
    .translate-x-sm-18 {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-sm-18-hover:hover {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-sm-20 {
      --x-translate-x: 5rem !important;
    }
    .translate-x-sm-20-hover:hover {
      --x-translate-x: 5rem !important;
    }
    .translate-x-sm-24 {
      --x-translate-x: 6rem !important;
    }
    .translate-x-sm-24-hover:hover {
      --x-translate-x: 6rem !important;
    }
    .translate-x-sm-32 {
      --x-translate-x: 8rem !important;
    }
    .translate-x-sm-32-hover:hover {
      --x-translate-x: 8rem !important;
    }
    .translate-x-sm-40 {
      --x-translate-x: 10rem !important;
    }
    .translate-x-sm-40-hover:hover {
      --x-translate-x: 10rem !important;
    }
    .translate-x-sm-48 {
      --x-translate-x: 12rem !important;
    }
    .translate-x-sm-48-hover:hover {
      --x-translate-x: 12rem !important;
    }
    .translate-x-sm-56 {
      --x-translate-x: 14rem !important;
    }
    .translate-x-sm-56-hover:hover {
      --x-translate-x: 14rem !important;
    }
    .translate-x-sm-64 {
      --x-translate-x: 16rem !important;
    }
    .translate-x-sm-64-hover:hover {
      --x-translate-x: 16rem !important;
    }
    .translate-x-sm-72 {
      --x-translate-x: 18rem !important;
    }
    .translate-x-sm-72-hover:hover {
      --x-translate-x: 18rem !important;
    }
    .translate-x-sm-80 {
      --x-translate-x: 20rem !important;
    }
    .translate-x-sm-80-hover:hover {
      --x-translate-x: 20rem !important;
    }
    .translate-x-sm-88 {
      --x-translate-x: 22rem !important;
    }
    .translate-x-sm-88-hover:hover {
      --x-translate-x: 22rem !important;
    }
    .translate-x-sm-96 {
      --x-translate-x: 24rem !important;
    }
    .translate-x-sm-96-hover:hover {
      --x-translate-x: 24rem !important;
    }
    .translate-x-sm-px {
      --x-translate-x: 1px !important;
    }
    .translate-x-sm-px-hover:hover {
      --x-translate-x: 1px !important;
    }
    .translate-x-sm-1\/2 {
      --x-translate-x: 50% !important;
    }
    .translate-x-sm-1\/2-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-sm-1\/3 {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-sm-1\/3-hover:hover {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-sm-1\/4 {
      --x-translate-x: 25% !important;
    }
    .translate-x-sm-1\/4-hover:hover {
      --x-translate-x: 25% !important;
    }
    .translate-x-sm-1\/5 {
      --x-translate-x: 20% !important;
    }
    .translate-x-sm-1\/5-hover:hover {
      --x-translate-x: 20% !important;
    }
    .translate-x-sm-2\/3 {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-sm-2\/3-hover:hover {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-sm-2\/5 {
      --x-translate-x: 50% !important;
    }
    .translate-x-sm-2\/5-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-sm-3\/4 {
      --x-translate-x: 75% !important;
    }
    .translate-x-sm-3\/4-hover:hover {
      --x-translate-x: 75% !important;
    }
    .translate-x-sm-full {
      --x-translate-x: 100% !important;
    }
    .translate-x-sm-full-hover:hover {
      --x-translate-x: 100% !important;
    }
    .translate-x-sm-n1 {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-sm-n1-hover:hover {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-sm-n2 {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-sm-n2-hover:hover {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-sm-n3 {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-sm-n3-hover:hover {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-sm-n4 {
      --x-translate-x: -1rem !important;
    }
    .translate-x-sm-n4-hover:hover {
      --x-translate-x: -1rem !important;
    }
    .translate-x-sm-n5 {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-sm-n5-hover:hover {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-sm-n6 {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-sm-n6-hover:hover {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-sm-n7 {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-sm-n7-hover:hover {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-sm-n8 {
      --x-translate-x: -2rem !important;
    }
    .translate-x-sm-n8-hover:hover {
      --x-translate-x: -2rem !important;
    }
    .translate-x-sm-n10 {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-sm-n10-hover:hover {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-sm-n12 {
      --x-translate-x: -3rem !important;
    }
    .translate-x-sm-n12-hover:hover {
      --x-translate-x: -3rem !important;
    }
    .translate-x-sm-n14 {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-sm-n14-hover:hover {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-sm-n16 {
      --x-translate-x: -4rem !important;
    }
    .translate-x-sm-n16-hover:hover {
      --x-translate-x: -4rem !important;
    }
    .translate-x-sm-n18 {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-sm-n18-hover:hover {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-sm-n20 {
      --x-translate-x: -5rem !important;
    }
    .translate-x-sm-n20-hover:hover {
      --x-translate-x: -5rem !important;
    }
    .translate-x-sm-n24 {
      --x-translate-x: -6rem !important;
    }
    .translate-x-sm-n24-hover:hover {
      --x-translate-x: -6rem !important;
    }
    .translate-x-sm-n32 {
      --x-translate-x: -8rem !important;
    }
    .translate-x-sm-n32-hover:hover {
      --x-translate-x: -8rem !important;
    }
    .translate-x-sm-n40 {
      --x-translate-x: -10rem !important;
    }
    .translate-x-sm-n40-hover:hover {
      --x-translate-x: -10rem !important;
    }
    .translate-x-sm-n48 {
      --x-translate-x: -12rem !important;
    }
    .translate-x-sm-n48-hover:hover {
      --x-translate-x: -12rem !important;
    }
    .translate-x-sm-n56 {
      --x-translate-x: -14rem !important;
    }
    .translate-x-sm-n56-hover:hover {
      --x-translate-x: -14rem !important;
    }
    .translate-x-sm-n64 {
      --x-translate-x: -16rem !important;
    }
    .translate-x-sm-n64-hover:hover {
      --x-translate-x: -16rem !important;
    }
    .translate-x-sm-n72 {
      --x-translate-x: -18rem !important;
    }
    .translate-x-sm-n72-hover:hover {
      --x-translate-x: -18rem !important;
    }
    .translate-x-sm-n80 {
      --x-translate-x: -20rem !important;
    }
    .translate-x-sm-n80-hover:hover {
      --x-translate-x: -20rem !important;
    }
    .translate-x-sm-n88 {
      --x-translate-x: -22rem !important;
    }
    .translate-x-sm-n88-hover:hover {
      --x-translate-x: -22rem !important;
    }
    .translate-x-sm-n96 {
      --x-translate-x: -24rem !important;
    }
    .translate-x-sm-n96-hover:hover {
      --x-translate-x: -24rem !important;
    }
    .translate-x-sm-npx {
      --x-translate-x: -1px !important;
    }
    .translate-x-sm-npx-hover:hover {
      --x-translate-x: -1px !important;
    }
    .translate-x-sm-n1\/2 {
      --x-translate-x: -50% !important;
    }
    .translate-x-sm-n1\/2-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-sm-n1\/3 {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-sm-n1\/3-hover:hover {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-sm-n1\/4 {
      --x-translate-x: -25% !important;
    }
    .translate-x-sm-n1\/4-hover:hover {
      --x-translate-x: -25% !important;
    }
    .translate-x-sm-n1\/5 {
      --x-translate-x: -20% !important;
    }
    .translate-x-sm-n1\/5-hover:hover {
      --x-translate-x: -20% !important;
    }
    .translate-x-sm-n2\/3 {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-sm-n2\/3-hover:hover {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-sm-n2\/5 {
      --x-translate-x: -50% !important;
    }
    .translate-x-sm-n2\/5-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-sm-n3\/4 {
      --x-translate-x: -75% !important;
    }
    .translate-x-sm-n3\/4-hover:hover {
      --x-translate-x: -75% !important;
    }
    .translate-x-sm-nfull {
      --x-translate-x: -100% !important;
    }
    .translate-x-sm-nfull-hover:hover {
      --x-translate-x: -100% !important;
    }
    .skew-y-sm-0 {
      --x-skew-y: 0deg !important;
    }
    .skew-y-sm-0-hover:hover {
      --x-skew-y: 0deg !important;
    }
    .skew-y-sm-1 {
      --x-skew-y: 1deg !important;
    }
    .skew-y-sm-1-hover:hover {
      --x-skew-y: 1deg !important;
    }
    .skew-y-sm-2 {
      --x-skew-y: 2deg !important;
    }
    .skew-y-sm-2-hover:hover {
      --x-skew-y: 2deg !important;
    }
    .skew-y-sm-3 {
      --x-skew-y: 3deg !important;
    }
    .skew-y-sm-3-hover:hover {
      --x-skew-y: 3deg !important;
    }
    .skew-y-sm-6 {
      --x-skew-y: 6deg !important;
    }
    .skew-y-sm-6-hover:hover {
      --x-skew-y: 6deg !important;
    }
    .skew-y-sm-12 {
      --x-skew-y: 12deg !important;
    }
    .skew-y-sm-12-hover:hover {
      --x-skew-y: 12deg !important;
    }
    .skew-y-sm-n1 {
      --x-skew-y: -1deg !important;
    }
    .skew-y-sm-n1-hover:hover {
      --x-skew-y: -1deg !important;
    }
    .skew-y-sm-n2 {
      --x-skew-y: -2deg !important;
    }
    .skew-y-sm-n2-hover:hover {
      --x-skew-y: -2deg !important;
    }
    .skew-y-sm-n3 {
      --x-skew-y: -3deg !important;
    }
    .skew-y-sm-n3-hover:hover {
      --x-skew-y: -3deg !important;
    }
    .skew-y-sm-n6 {
      --x-skew-y: -6deg !important;
    }
    .skew-y-sm-n6-hover:hover {
      --x-skew-y: -6deg !important;
    }
    .skew-y-sm-n12 {
      --x-skew-y: -12deg !important;
    }
    .skew-y-sm-n12-hover:hover {
      --x-skew-y: -12deg !important;
    }
    .skew-x-sm-0 {
      --x-skew-x: 0deg !important;
    }
    .skew-x-sm-0-hover:hover {
      --x-skew-x: 0deg !important;
    }
    .skew-x-sm-1 {
      --x-skew-x: 1deg !important;
    }
    .skew-x-sm-1-hover:hover {
      --x-skew-x: 1deg !important;
    }
    .skew-x-sm-2 {
      --x-skew-x: 2deg !important;
    }
    .skew-x-sm-2-hover:hover {
      --x-skew-x: 2deg !important;
    }
    .skew-x-sm-3 {
      --x-skew-x: 3deg !important;
    }
    .skew-x-sm-3-hover:hover {
      --x-skew-x: 3deg !important;
    }
    .skew-x-sm-6 {
      --x-skew-x: 6deg !important;
    }
    .skew-x-sm-6-hover:hover {
      --x-skew-x: 6deg !important;
    }
    .skew-x-sm-12 {
      --x-skew-x: 12deg !important;
    }
    .skew-x-sm-12-hover:hover {
      --x-skew-x: 12deg !important;
    }
    .skew-x-sm-n1 {
      --x-skew-x: -1deg !important;
    }
    .skew-x-sm-n1-hover:hover {
      --x-skew-x: -1deg !important;
    }
    .skew-x-sm-n2 {
      --x-skew-x: -2deg !important;
    }
    .skew-x-sm-n2-hover:hover {
      --x-skew-x: -2deg !important;
    }
    .skew-x-sm-n3 {
      --x-skew-x: -3deg !important;
    }
    .skew-x-sm-n3-hover:hover {
      --x-skew-x: -3deg !important;
    }
    .skew-x-sm-n6 {
      --x-skew-x: -6deg !important;
    }
    .skew-x-sm-n6-hover:hover {
      --x-skew-x: -6deg !important;
    }
    .skew-x-sm-n12 {
      --x-skew-x: -12deg !important;
    }
    .skew-x-sm-n12-hover:hover {
      --x-skew-x: -12deg !important;
    }
  }
  @media (min-width: 768px) {
    .float-md-start {
      float: left !important;
    }
    .float-md-end {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
    .opacity-md-0 {
      opacity: 0 !important;
    }
    .opacity-md-0-hover:hover {
      opacity: 0 !important;
    }
    .opacity-md-10 {
      opacity: 0.1 !important;
    }
    .opacity-md-10-hover:hover {
      opacity: 0.1 !important;
    }
    .opacity-md-20 {
      opacity: 0.2 !important;
    }
    .opacity-md-20-hover:hover {
      opacity: 0.2 !important;
    }
    .opacity-md-30 {
      opacity: 0.3 !important;
    }
    .opacity-md-30-hover:hover {
      opacity: 0.3 !important;
    }
    .opacity-md-40 {
      opacity: 0.4 !important;
    }
    .opacity-md-40-hover:hover {
      opacity: 0.4 !important;
    }
    .opacity-md-50 {
      opacity: 0.5 !important;
    }
    .opacity-md-50-hover:hover {
      opacity: 0.5 !important;
    }
    .opacity-md-60 {
      opacity: 0.6 !important;
    }
    .opacity-md-60-hover:hover {
      opacity: 0.6 !important;
    }
    .opacity-md-70 {
      opacity: 0.7 !important;
    }
    .opacity-md-70-hover:hover {
      opacity: 0.7 !important;
    }
    .opacity-md-80 {
      opacity: 0.8 !important;
    }
    .opacity-md-80-hover:hover {
      opacity: 0.8 !important;
    }
    .opacity-md-90 {
      opacity: 0.9 !important;
    }
    .opacity-md-90-hover:hover {
      opacity: 0.9 !important;
    }
    .opacity-md-100 {
      opacity: 1 !important;
    }
    .opacity-md-100-hover:hover {
      opacity: 1 !important;
    }
    .overflow-md-auto {
      overflow: auto !important;
    }
    .overflow-md-hidden {
      overflow: hidden !important;
    }
    .overflow-md-visible {
      overflow: visible !important;
    }
    .overflow-md-scroll {
      overflow: scroll !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-grid {
      display: grid !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
    .d-md-none {
      display: none !important;
    }
    .shadow-md {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-hover:hover {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-1 {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-1-hover:hover {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-2 {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-2-hover:hover {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-3 {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-3-hover:hover {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-4 {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-4-hover:hover {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-5 {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-5-hover:hover {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-6 {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-6-hover:hover {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-md-inset {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-md-inset-hover:hover {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-md-outline {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-md-outline-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-md-focus {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-md-focus-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-md-none {
      box-shadow: none !important;
    }
    .shadow-md-none-hover:hover {
      box-shadow: none !important;
    }
    .position-md-static {
      position: static !important;
    }
    .position-md-relative {
      position: relative !important;
    }
    .position-md-absolute {
      position: absolute !important;
    }
    .position-md-fixed {
      position: fixed !important;
    }
    .position-md-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .top-md-0 {
      top: 0 !important;
    }
    .top-md-1 {
      top: 0.25rem !important;
    }
    .top-md-2 {
      top: 0.5rem !important;
    }
    .top-md-3 {
      top: 0.75rem !important;
    }
    .top-md-4 {
      top: 1rem !important;
    }
    .top-md-5 {
      top: 1.25rem !important;
    }
    .top-md-6 {
      top: 1.5rem !important;
    }
    .top-md-7 {
      top: 1.75rem !important;
    }
    .top-md-8 {
      top: 2rem !important;
    }
    .top-md-10 {
      top: 2.5rem !important;
    }
    .top-md-12 {
      top: 3rem !important;
    }
    .top-md-14 {
      top: 3.5rem !important;
    }
    .top-md-16 {
      top: 4rem !important;
    }
    .top-md-18 {
      top: 4.5rem !important;
    }
    .top-md-20 {
      top: 5rem !important;
    }
    .top-md-24 {
      top: 6rem !important;
    }
    .top-md-32 {
      top: 8rem !important;
    }
    .top-md-40 {
      top: 10rem !important;
    }
    .top-md-48 {
      top: 12rem !important;
    }
    .top-md-56 {
      top: 14rem !important;
    }
    .top-md-64 {
      top: 16rem !important;
    }
    .top-md-72 {
      top: 18rem !important;
    }
    .top-md-80 {
      top: 20rem !important;
    }
    .top-md-88 {
      top: 22rem !important;
    }
    .top-md-96 {
      top: 24rem !important;
    }
    .top-md-px {
      top: 1px !important;
    }
    .top-md-1\/2 {
      top: 50% !important;
    }
    .top-md-full {
      top: 100% !important;
    }
    .top-md-auto {
      top: auto !important;
    }
    .bottom-md-0 {
      bottom: 0 !important;
    }
    .bottom-md-1 {
      bottom: 0.25rem !important;
    }
    .bottom-md-2 {
      bottom: 0.5rem !important;
    }
    .bottom-md-3 {
      bottom: 0.75rem !important;
    }
    .bottom-md-4 {
      bottom: 1rem !important;
    }
    .bottom-md-5 {
      bottom: 1.25rem !important;
    }
    .bottom-md-6 {
      bottom: 1.5rem !important;
    }
    .bottom-md-7 {
      bottom: 1.75rem !important;
    }
    .bottom-md-8 {
      bottom: 2rem !important;
    }
    .bottom-md-10 {
      bottom: 2.5rem !important;
    }
    .bottom-md-12 {
      bottom: 3rem !important;
    }
    .bottom-md-14 {
      bottom: 3.5rem !important;
    }
    .bottom-md-16 {
      bottom: 4rem !important;
    }
    .bottom-md-18 {
      bottom: 4.5rem !important;
    }
    .bottom-md-20 {
      bottom: 5rem !important;
    }
    .bottom-md-24 {
      bottom: 6rem !important;
    }
    .bottom-md-32 {
      bottom: 8rem !important;
    }
    .bottom-md-40 {
      bottom: 10rem !important;
    }
    .bottom-md-48 {
      bottom: 12rem !important;
    }
    .bottom-md-56 {
      bottom: 14rem !important;
    }
    .bottom-md-64 {
      bottom: 16rem !important;
    }
    .bottom-md-72 {
      bottom: 18rem !important;
    }
    .bottom-md-80 {
      bottom: 20rem !important;
    }
    .bottom-md-88 {
      bottom: 22rem !important;
    }
    .bottom-md-96 {
      bottom: 24rem !important;
    }
    .bottom-md-px {
      bottom: 1px !important;
    }
    .bottom-md-1\/2 {
      bottom: 50% !important;
    }
    .bottom-md-full {
      bottom: 100% !important;
    }
    .bottom-md-auto {
      bottom: auto !important;
    }
    .start-md-0 {
      left: 0 !important;
    }
    .start-md-1 {
      left: 0.25rem !important;
    }
    .start-md-2 {
      left: 0.5rem !important;
    }
    .start-md-3 {
      left: 0.75rem !important;
    }
    .start-md-4 {
      left: 1rem !important;
    }
    .start-md-5 {
      left: 1.25rem !important;
    }
    .start-md-6 {
      left: 1.5rem !important;
    }
    .start-md-7 {
      left: 1.75rem !important;
    }
    .start-md-8 {
      left: 2rem !important;
    }
    .start-md-10 {
      left: 2.5rem !important;
    }
    .start-md-12 {
      left: 3rem !important;
    }
    .start-md-14 {
      left: 3.5rem !important;
    }
    .start-md-16 {
      left: 4rem !important;
    }
    .start-md-18 {
      left: 4.5rem !important;
    }
    .start-md-20 {
      left: 5rem !important;
    }
    .start-md-24 {
      left: 6rem !important;
    }
    .start-md-32 {
      left: 8rem !important;
    }
    .start-md-40 {
      left: 10rem !important;
    }
    .start-md-48 {
      left: 12rem !important;
    }
    .start-md-56 {
      left: 14rem !important;
    }
    .start-md-64 {
      left: 16rem !important;
    }
    .start-md-72 {
      left: 18rem !important;
    }
    .start-md-80 {
      left: 20rem !important;
    }
    .start-md-88 {
      left: 22rem !important;
    }
    .start-md-96 {
      left: 24rem !important;
    }
    .start-md-px {
      left: 1px !important;
    }
    .start-md-1\/2 {
      left: 50% !important;
    }
    .start-md-full {
      left: 100% !important;
    }
    .start-md-auto {
      left: auto !important;
    }
    .end-md-0 {
      right: 0 !important;
    }
    .end-md-1 {
      right: 0.25rem !important;
    }
    .end-md-2 {
      right: 0.5rem !important;
    }
    .end-md-3 {
      right: 0.75rem !important;
    }
    .end-md-4 {
      right: 1rem !important;
    }
    .end-md-5 {
      right: 1.25rem !important;
    }
    .end-md-6 {
      right: 1.5rem !important;
    }
    .end-md-7 {
      right: 1.75rem !important;
    }
    .end-md-8 {
      right: 2rem !important;
    }
    .end-md-10 {
      right: 2.5rem !important;
    }
    .end-md-12 {
      right: 3rem !important;
    }
    .end-md-14 {
      right: 3.5rem !important;
    }
    .end-md-16 {
      right: 4rem !important;
    }
    .end-md-18 {
      right: 4.5rem !important;
    }
    .end-md-20 {
      right: 5rem !important;
    }
    .end-md-24 {
      right: 6rem !important;
    }
    .end-md-32 {
      right: 8rem !important;
    }
    .end-md-40 {
      right: 10rem !important;
    }
    .end-md-48 {
      right: 12rem !important;
    }
    .end-md-56 {
      right: 14rem !important;
    }
    .end-md-64 {
      right: 16rem !important;
    }
    .end-md-72 {
      right: 18rem !important;
    }
    .end-md-80 {
      right: 20rem !important;
    }
    .end-md-88 {
      right: 22rem !important;
    }
    .end-md-96 {
      right: 24rem !important;
    }
    .end-md-px {
      right: 1px !important;
    }
    .end-md-1\/2 {
      right: 50% !important;
    }
    .end-md-full {
      right: 100% !important;
    }
    .end-md-auto {
      right: auto !important;
    }
    .border-top-md-0 {
      border-top-width: 0 !important;
    }
    .border-top-md-0-hover:hover {
      border-top-width: 0 !important;
    }
    .border-top-md-0-focus:focus {
      border-top-width: 0 !important;
    }
    .border-top-md {
      border-top-width: 1px !important;
    }
    .border-top-md-hover:hover {
      border-top-width: 1px !important;
    }
    .border-top-md-focus:focus {
      border-top-width: 1px !important;
    }
    .border-top-md-2 {
      border-top-width: 2px !important;
    }
    .border-top-md-2-hover:hover {
      border-top-width: 2px !important;
    }
    .border-top-md-2-focus:focus {
      border-top-width: 2px !important;
    }
    .border-top-md-3 {
      border-top-width: 3px !important;
    }
    .border-top-md-3-hover:hover {
      border-top-width: 3px !important;
    }
    .border-top-md-3-focus:focus {
      border-top-width: 3px !important;
    }
    .border-top-md-4 {
      border-top-width: 4px !important;
    }
    .border-top-md-4-hover:hover {
      border-top-width: 4px !important;
    }
    .border-top-md-4-focus:focus {
      border-top-width: 4px !important;
    }
    .border-top-md-5 {
      border-top-width: 5px !important;
    }
    .border-top-md-5-hover:hover {
      border-top-width: 5px !important;
    }
    .border-top-md-5-focus:focus {
      border-top-width: 5px !important;
    }
    .border-top-md-6 {
      border-top-width: 6px !important;
    }
    .border-top-md-6-hover:hover {
      border-top-width: 6px !important;
    }
    .border-top-md-6-focus:focus {
      border-top-width: 6px !important;
    }
    .border-top-md-7 {
      border-top-width: 7px !important;
    }
    .border-top-md-7-hover:hover {
      border-top-width: 7px !important;
    }
    .border-top-md-7-focus:focus {
      border-top-width: 7px !important;
    }
    .border-top-md-8 {
      border-top-width: 8px !important;
    }
    .border-top-md-8-hover:hover {
      border-top-width: 8px !important;
    }
    .border-top-md-8-focus:focus {
      border-top-width: 8px !important;
    }
    .border-end-md-0 {
      border-right-width: 0 !important;
    }
    .border-end-md-0-hover:hover {
      border-right-width: 0 !important;
    }
    .border-end-md-0-focus:focus {
      border-right-width: 0 !important;
    }
    .border-end-md {
      border-right-width: 1px !important;
    }
    .border-end-md-hover:hover {
      border-right-width: 1px !important;
    }
    .border-end-md-focus:focus {
      border-right-width: 1px !important;
    }
    .border-end-md-2 {
      border-right-width: 2px !important;
    }
    .border-end-md-2-hover:hover {
      border-right-width: 2px !important;
    }
    .border-end-md-2-focus:focus {
      border-right-width: 2px !important;
    }
    .border-end-md-3 {
      border-right-width: 3px !important;
    }
    .border-end-md-3-hover:hover {
      border-right-width: 3px !important;
    }
    .border-end-md-3-focus:focus {
      border-right-width: 3px !important;
    }
    .border-end-md-4 {
      border-right-width: 4px !important;
    }
    .border-end-md-4-hover:hover {
      border-right-width: 4px !important;
    }
    .border-end-md-4-focus:focus {
      border-right-width: 4px !important;
    }
    .border-end-md-5 {
      border-right-width: 5px !important;
    }
    .border-end-md-5-hover:hover {
      border-right-width: 5px !important;
    }
    .border-end-md-5-focus:focus {
      border-right-width: 5px !important;
    }
    .border-end-md-6 {
      border-right-width: 6px !important;
    }
    .border-end-md-6-hover:hover {
      border-right-width: 6px !important;
    }
    .border-end-md-6-focus:focus {
      border-right-width: 6px !important;
    }
    .border-end-md-7 {
      border-right-width: 7px !important;
    }
    .border-end-md-7-hover:hover {
      border-right-width: 7px !important;
    }
    .border-end-md-7-focus:focus {
      border-right-width: 7px !important;
    }
    .border-end-md-8 {
      border-right-width: 8px !important;
    }
    .border-end-md-8-hover:hover {
      border-right-width: 8px !important;
    }
    .border-end-md-8-focus:focus {
      border-right-width: 8px !important;
    }
    .border-bottom-md-0 {
      border-bottom-width: 0 !important;
    }
    .border-bottom-md-0-hover:hover {
      border-bottom-width: 0 !important;
    }
    .border-bottom-md-0-focus:focus {
      border-bottom-width: 0 !important;
    }
    .border-bottom-md {
      border-bottom-width: 1px !important;
    }
    .border-bottom-md-hover:hover {
      border-bottom-width: 1px !important;
    }
    .border-bottom-md-focus:focus {
      border-bottom-width: 1px !important;
    }
    .border-bottom-md-2 {
      border-bottom-width: 2px !important;
    }
    .border-bottom-md-2-hover:hover {
      border-bottom-width: 2px !important;
    }
    .border-bottom-md-2-focus:focus {
      border-bottom-width: 2px !important;
    }
    .border-bottom-md-3 {
      border-bottom-width: 3px !important;
    }
    .border-bottom-md-3-hover:hover {
      border-bottom-width: 3px !important;
    }
    .border-bottom-md-3-focus:focus {
      border-bottom-width: 3px !important;
    }
    .border-bottom-md-4 {
      border-bottom-width: 4px !important;
    }
    .border-bottom-md-4-hover:hover {
      border-bottom-width: 4px !important;
    }
    .border-bottom-md-4-focus:focus {
      border-bottom-width: 4px !important;
    }
    .border-bottom-md-5 {
      border-bottom-width: 5px !important;
    }
    .border-bottom-md-5-hover:hover {
      border-bottom-width: 5px !important;
    }
    .border-bottom-md-5-focus:focus {
      border-bottom-width: 5px !important;
    }
    .border-bottom-md-6 {
      border-bottom-width: 6px !important;
    }
    .border-bottom-md-6-hover:hover {
      border-bottom-width: 6px !important;
    }
    .border-bottom-md-6-focus:focus {
      border-bottom-width: 6px !important;
    }
    .border-bottom-md-7 {
      border-bottom-width: 7px !important;
    }
    .border-bottom-md-7-hover:hover {
      border-bottom-width: 7px !important;
    }
    .border-bottom-md-7-focus:focus {
      border-bottom-width: 7px !important;
    }
    .border-bottom-md-8 {
      border-bottom-width: 8px !important;
    }
    .border-bottom-md-8-hover:hover {
      border-bottom-width: 8px !important;
    }
    .border-bottom-md-8-focus:focus {
      border-bottom-width: 8px !important;
    }
    .border-start-md-0 {
      border-left-width: 0 !important;
    }
    .border-start-md-0-hover:hover {
      border-left-width: 0 !important;
    }
    .border-start-md-0-focus:focus {
      border-left-width: 0 !important;
    }
    .border-start-md {
      border-left-width: 1px !important;
    }
    .border-start-md-hover:hover {
      border-left-width: 1px !important;
    }
    .border-start-md-focus:focus {
      border-left-width: 1px !important;
    }
    .border-start-md-2 {
      border-left-width: 2px !important;
    }
    .border-start-md-2-hover:hover {
      border-left-width: 2px !important;
    }
    .border-start-md-2-focus:focus {
      border-left-width: 2px !important;
    }
    .border-start-md-3 {
      border-left-width: 3px !important;
    }
    .border-start-md-3-hover:hover {
      border-left-width: 3px !important;
    }
    .border-start-md-3-focus:focus {
      border-left-width: 3px !important;
    }
    .border-start-md-4 {
      border-left-width: 4px !important;
    }
    .border-start-md-4-hover:hover {
      border-left-width: 4px !important;
    }
    .border-start-md-4-focus:focus {
      border-left-width: 4px !important;
    }
    .border-start-md-5 {
      border-left-width: 5px !important;
    }
    .border-start-md-5-hover:hover {
      border-left-width: 5px !important;
    }
    .border-start-md-5-focus:focus {
      border-left-width: 5px !important;
    }
    .border-start-md-6 {
      border-left-width: 6px !important;
    }
    .border-start-md-6-hover:hover {
      border-left-width: 6px !important;
    }
    .border-start-md-6-focus:focus {
      border-left-width: 6px !important;
    }
    .border-start-md-7 {
      border-left-width: 7px !important;
    }
    .border-start-md-7-hover:hover {
      border-left-width: 7px !important;
    }
    .border-start-md-7-focus:focus {
      border-left-width: 7px !important;
    }
    .border-start-md-8 {
      border-left-width: 8px !important;
    }
    .border-start-md-8-hover:hover {
      border-left-width: 8px !important;
    }
    .border-start-md-8-focus:focus {
      border-left-width: 8px !important;
    }
    .border-md-primary {
      border-color: #4066D5 !important;
    }
    .border-md-primary-hover:hover {
      border-color: #4066D5 !important;
    }
    .border-md-primary-focus:focus {
      border-color: #4066D5 !important;
    }
    .border-md-primary-focus-within:focus-within {
      border-color: #4066D5 !important;
    }
    .border-md-secondary {
      border-color: #cfd6df !important;
    }
    .border-md-secondary-hover:hover {
      border-color: #cfd6df !important;
    }
    .border-md-secondary-focus:focus {
      border-color: #cfd6df !important;
    }
    .border-md-secondary-focus-within:focus-within {
      border-color: #cfd6df !important;
    }
    .border-md-tertiary {
      border-color: #ff579a !important;
    }
    .border-md-tertiary-hover:hover {
      border-color: #ff579a !important;
    }
    .border-md-tertiary-focus:focus {
      border-color: #ff579a !important;
    }
    .border-md-tertiary-focus-within:focus-within {
      border-color: #ff579a !important;
    }
    .border-md-success {
      border-color: #0c8 !important;
    }
    .border-md-success-hover:hover {
      border-color: #0c8 !important;
    }
    .border-md-success-focus:focus {
      border-color: #0c8 !important;
    }
    .border-md-success-focus-within:focus-within {
      border-color: #0c8 !important;
    }
    .border-md-info {
      border-color: #00d4ff !important;
    }
    .border-md-info-hover:hover {
      border-color: #00d4ff !important;
    }
    .border-md-info-focus:focus {
      border-color: #00d4ff !important;
    }
    .border-md-info-focus-within:focus-within {
      border-color: #00d4ff !important;
    }
    .border-md-warning {
      border-color: #ff8c00 !important;
    }
    .border-md-warning-hover:hover {
      border-color: #ff8c00 !important;
    }
    .border-md-warning-focus:focus {
      border-color: #ff8c00 !important;
    }
    .border-md-warning-focus-within:focus-within {
      border-color: #ff8c00 !important;
    }
    .border-md-danger {
      border-color: #f36 !important;
    }
    .border-md-danger-hover:hover {
      border-color: #f36 !important;
    }
    .border-md-danger-focus:focus {
      border-color: #f36 !important;
    }
    .border-md-danger-focus-within:focus-within {
      border-color: #f36 !important;
    }
    .border-md-white {
      border-color: #fff !important;
    }
    .border-md-white-hover:hover {
      border-color: #fff !important;
    }
    .border-md-white-focus:focus {
      border-color: #fff !important;
    }
    .border-md-white-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-md-light {
      border-color: #e7eaf0 !important;
    }
    .border-md-light-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-md-light-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-md-light-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-md-dark {
      border-color: #16192c !important;
    }
    .border-md-dark-hover:hover {
      border-color: #16192c !important;
    }
    .border-md-dark-focus:focus {
      border-color: #16192c !important;
    }
    .border-md-dark-focus-within:focus-within {
      border-color: #16192c !important;
    }
    .border-md-transparent {
      border-color: transparent !important;
    }
    .border-md-transparent-hover:hover {
      border-color: transparent !important;
    }
    .border-md-transparent-focus:focus {
      border-color: transparent !important;
    }
    .border-md-transparent-focus-within:focus-within {
      border-color: transparent !important;
    }
    .border-md-current {
      border-color: currentColor !important;
    }
    .border-md-current-hover:hover {
      border-color: currentColor !important;
    }
    .border-md-current-focus:focus {
      border-color: currentColor !important;
    }
    .border-md-current-focus-within:focus-within {
      border-color: currentColor !important;
    }
    .border-md-base {
      border-color: #e7eaf0 !important;
    }
    .border-md-base-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-md-base-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-md-base-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-md-body {
      border-color: #fff !important;
    }
    .border-md-body-hover:hover {
      border-color: #fff !important;
    }
    .border-md-body-focus:focus {
      border-color: #fff !important;
    }
    .border-md-body-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-md-card {
      border-color: #fff !important;
    }
    .border-md-card-hover:hover {
      border-color: #fff !important;
    }
    .border-md-card-focus:focus {
      border-color: #fff !important;
    }
    .border-md-card-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-md-black {
      border-color: #000 !important;
    }
    .border-md-black-hover:hover {
      border-color: #000 !important;
    }
    .border-md-black-focus:focus {
      border-color: #000 !important;
    }
    .border-md-black-focus-within:focus-within {
      border-color: #000 !important;
    }
    .border-md-0 {
      border-width: 0 !important;
    }
    .border-md-0-hover:hover {
      border-width: 0 !important;
    }
    .border-md-0-focus:focus {
      border-width: 0 !important;
    }
    .border-md {
      border-width: 1px !important;
    }
    .border-md-hover:hover {
      border-width: 1px !important;
    }
    .border-md-focus:focus {
      border-width: 1px !important;
    }
    .border-md-2 {
      border-width: 2px !important;
    }
    .border-md-2-hover:hover {
      border-width: 2px !important;
    }
    .border-md-2-focus:focus {
      border-width: 2px !important;
    }
    .border-md-3 {
      border-width: 3px !important;
    }
    .border-md-3-hover:hover {
      border-width: 3px !important;
    }
    .border-md-3-focus:focus {
      border-width: 3px !important;
    }
    .border-md-4 {
      border-width: 4px !important;
    }
    .border-md-4-hover:hover {
      border-width: 4px !important;
    }
    .border-md-4-focus:focus {
      border-width: 4px !important;
    }
    .border-md-5 {
      border-width: 5px !important;
    }
    .border-md-5-hover:hover {
      border-width: 5px !important;
    }
    .border-md-5-focus:focus {
      border-width: 5px !important;
    }
    .border-md-6 {
      border-width: 6px !important;
    }
    .border-md-6-hover:hover {
      border-width: 6px !important;
    }
    .border-md-6-focus:focus {
      border-width: 6px !important;
    }
    .border-md-7 {
      border-width: 7px !important;
    }
    .border-md-7-hover:hover {
      border-width: 7px !important;
    }
    .border-md-7-focus:focus {
      border-width: 7px !important;
    }
    .border-md-8 {
      border-width: 8px !important;
    }
    .border-md-8-hover:hover {
      border-width: 8px !important;
    }
    .border-md-8-focus:focus {
      border-width: 8px !important;
    }
    .w-md-0 {
      width: 0 !important;
    }
    .w-md-1 {
      width: 0.25rem !important;
    }
    .w-md-2 {
      width: 0.5rem !important;
    }
    .w-md-3 {
      width: 0.75rem !important;
    }
    .w-md-4 {
      width: 1rem !important;
    }
    .w-md-5 {
      width: 1.25rem !important;
    }
    .w-md-6 {
      width: 1.5rem !important;
    }
    .w-md-7 {
      width: 1.75rem !important;
    }
    .w-md-8 {
      width: 2rem !important;
    }
    .w-md-10 {
      width: 2.5rem !important;
    }
    .w-md-12 {
      width: 3rem !important;
    }
    .w-md-14 {
      width: 3.5rem !important;
    }
    .w-md-16 {
      width: 4rem !important;
    }
    .w-md-18 {
      width: 4.5rem !important;
    }
    .w-md-20 {
      width: 5rem !important;
    }
    .w-md-24 {
      width: 6rem !important;
    }
    .w-md-32 {
      width: 8rem !important;
    }
    .w-md-40 {
      width: 10rem !important;
    }
    .w-md-48 {
      width: 12rem !important;
    }
    .w-md-56 {
      width: 14rem !important;
    }
    .w-md-64 {
      width: 16rem !important;
    }
    .w-md-72 {
      width: 18rem !important;
    }
    .w-md-80 {
      width: 20rem !important;
    }
    .w-md-88 {
      width: 22rem !important;
    }
    .w-md-96 {
      width: 24rem !important;
    }
    .w-md-px {
      width: 1px !important;
    }
    .w-md-1\/2 {
      width: 50% !important;
    }
    .w-md-1\/3 {
      width: 33.3333333333% !important;
    }
    .w-md-2\/3 {
      width: 66.6666666667% !important;
    }
    .w-md-1\/4 {
      width: 25% !important;
    }
    .w-md-2\/4 {
      width: 50% !important;
    }
    .w-md-3\/4 {
      width: 75% !important;
    }
    .w-md-1\/5 {
      width: 20% !important;
    }
    .w-md-2\/5 {
      width: 40% !important;
    }
    .w-md-3\/5 {
      width: 60% !important;
    }
    .w-md-4\/5 {
      width: 80% !important;
    }
    .w-md-1\/6 {
      width: 16.6666666667% !important;
    }
    .w-md-2\/6 {
      width: 33.3333333333% !important;
    }
    .w-md-3\/6 {
      width: 50% !important;
    }
    .w-md-4\/6 {
      width: 66.6666666667% !important;
    }
    .w-md-5\/6 {
      width: 83.3333333333% !important;
    }
    .w-md-11\/10 {
      width: 110% !important;
    }
    .w-md-12\/10 {
      width: 120% !important;
    }
    .w-md-13\/10 {
      width: 130% !important;
    }
    .w-md-14\/10 {
      width: 140% !important;
    }
    .w-md-15\/10 {
      width: 150% !important;
    }
    .w-md-auto {
      width: auto !important;
    }
    .w-md-full {
      width: 100% !important;
    }
    .w-md-screen {
      width: 100vw !important;
    }
    .w-md-min {
      width: -webkit-min-content !important;
      width: -moz-min-content !important;
      width: min-content !important;
    }
    .w-md-max {
      width: -webkit-max-content !important;
      width: -moz-max-content !important;
      width: max-content !important;
    }
    .max-w-md-screen-sm {
      max-width: 640px !important;
    }
    .max-w-md-screen-md {
      max-width: 768px !important;
    }
    .max-w-md-screen-lg {
      max-width: 1024px !important;
    }
    .max-w-md-screen-xl {
      max-width: 1280px !important;
    }
    .max-w-md-screen-xxl {
      max-width: 1536px !important;
    }
    .max-w-md-0 {
      max-width: 0 !important;
    }
    .max-w-md-full {
      max-width: 100% !important;
    }
    .max-w-md-read {
      max-width: 65ch !important;
    }
    .max-w-md-min {
      max-width: -webkit-min-content !important;
      max-width: -moz-min-content !important;
      max-width: min-content !important;
    }
    .max-w-md-max {
      max-width: -webkit-max-content !important;
      max-width: -moz-max-content !important;
      max-width: max-content !important;
    }
    .h-md-0 {
      height: 0 !important;
    }
    .h-md-1 {
      height: 0.25rem !important;
    }
    .h-md-2 {
      height: 0.5rem !important;
    }
    .h-md-3 {
      height: 0.75rem !important;
    }
    .h-md-4 {
      height: 1rem !important;
    }
    .h-md-5 {
      height: 1.25rem !important;
    }
    .h-md-6 {
      height: 1.5rem !important;
    }
    .h-md-7 {
      height: 1.75rem !important;
    }
    .h-md-8 {
      height: 2rem !important;
    }
    .h-md-10 {
      height: 2.5rem !important;
    }
    .h-md-12 {
      height: 3rem !important;
    }
    .h-md-14 {
      height: 3.5rem !important;
    }
    .h-md-16 {
      height: 4rem !important;
    }
    .h-md-18 {
      height: 4.5rem !important;
    }
    .h-md-20 {
      height: 5rem !important;
    }
    .h-md-24 {
      height: 6rem !important;
    }
    .h-md-32 {
      height: 8rem !important;
    }
    .h-md-40 {
      height: 10rem !important;
    }
    .h-md-48 {
      height: 12rem !important;
    }
    .h-md-56 {
      height: 14rem !important;
    }
    .h-md-64 {
      height: 16rem !important;
    }
    .h-md-72 {
      height: 18rem !important;
    }
    .h-md-80 {
      height: 20rem !important;
    }
    .h-md-88 {
      height: 22rem !important;
    }
    .h-md-96 {
      height: 24rem !important;
    }
    .h-md-px {
      height: 1px !important;
    }
    .h-md-1\/2 {
      height: 50% !important;
    }
    .h-md-1\/3 {
      height: 33.3333333333% !important;
    }
    .h-md-2\/3 {
      height: 66.6666666667% !important;
    }
    .h-md-1\/4 {
      height: 25% !important;
    }
    .h-md-2\/4 {
      height: 50% !important;
    }
    .h-md-3\/4 {
      height: 75% !important;
    }
    .h-md-1\/5 {
      height: 20% !important;
    }
    .h-md-2\/5 {
      height: 40% !important;
    }
    .h-md-3\/5 {
      height: 60% !important;
    }
    .h-md-4\/5 {
      height: 80% !important;
    }
    .h-md-1\/6 {
      height: 16.6666666667% !important;
    }
    .h-md-2\/6 {
      height: 33.3333333333% !important;
    }
    .h-md-3\/6 {
      height: 50% !important;
    }
    .h-md-4\/6 {
      height: 66.6666666667% !important;
    }
    .h-md-5\/6 {
      height: 83.3333333333% !important;
    }
    .h-md-11\/10 {
      height: 110% !important;
    }
    .h-md-12\/10 {
      height: 120% !important;
    }
    .h-md-13\/10 {
      height: 130% !important;
    }
    .h-md-14\/10 {
      height: 140% !important;
    }
    .h-md-15\/10 {
      height: 150% !important;
    }
    .h-md-auto {
      height: auto !important;
    }
    .h-md-full {
      height: 100% !important;
    }
    .h-md-screen {
      height: 100vh !important;
    }
    .max-h-md-0 {
      max-height: 0 !important;
    }
    .max-h-md-1 {
      max-height: 0.25rem !important;
    }
    .max-h-md-2 {
      max-height: 0.5rem !important;
    }
    .max-h-md-3 {
      max-height: 0.75rem !important;
    }
    .max-h-md-4 {
      max-height: 1rem !important;
    }
    .max-h-md-5 {
      max-height: 1.25rem !important;
    }
    .max-h-md-6 {
      max-height: 1.5rem !important;
    }
    .max-h-md-7 {
      max-height: 1.75rem !important;
    }
    .max-h-md-8 {
      max-height: 2rem !important;
    }
    .max-h-md-10 {
      max-height: 2.5rem !important;
    }
    .max-h-md-12 {
      max-height: 3rem !important;
    }
    .max-h-md-14 {
      max-height: 3.5rem !important;
    }
    .max-h-md-16 {
      max-height: 4rem !important;
    }
    .max-h-md-18 {
      max-height: 4.5rem !important;
    }
    .max-h-md-20 {
      max-height: 5rem !important;
    }
    .max-h-md-24 {
      max-height: 6rem !important;
    }
    .max-h-md-32 {
      max-height: 8rem !important;
    }
    .max-h-md-40 {
      max-height: 10rem !important;
    }
    .max-h-md-48 {
      max-height: 12rem !important;
    }
    .max-h-md-56 {
      max-height: 14rem !important;
    }
    .max-h-md-64 {
      max-height: 16rem !important;
    }
    .max-h-md-72 {
      max-height: 18rem !important;
    }
    .max-h-md-80 {
      max-height: 20rem !important;
    }
    .max-h-md-88 {
      max-height: 22rem !important;
    }
    .max-h-md-96 {
      max-height: 24rem !important;
    }
    .max-h-md-px {
      max-height: 1px !important;
    }
    .max-h-md-full {
      max-height: 100% !important;
    }
    .max-h-md-screen {
      max-height: 100vh !important;
    }
    .flex-md-1 {
      flex: 1 1 0% !important;
    }
    .flex-md-fill {
      flex: 1 1 auto !important;
    }
    .flex-md-initial {
      flex: 0 1 auto !important;
    }
    .flex-md-none {
      flex: none !important;
    }
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .gap-md-0 {
      gap: 0 !important;
    }
    .gap-md-1 {
      gap: 0.25rem !important;
    }
    .gap-md-2 {
      gap: 0.5rem !important;
    }
    .gap-md-3 {
      gap: 0.75rem !important;
    }
    .gap-md-4 {
      gap: 1rem !important;
    }
    .gap-md-5 {
      gap: 1.25rem !important;
    }
    .gap-md-6 {
      gap: 1.5rem !important;
    }
    .gap-md-7 {
      gap: 1.75rem !important;
    }
    .gap-md-8 {
      gap: 2rem !important;
    }
    .gap-md-10 {
      gap: 2.5rem !important;
    }
    .gap-md-12 {
      gap: 3rem !important;
    }
    .gap-md-14 {
      gap: 3.5rem !important;
    }
    .gap-md-16 {
      gap: 4rem !important;
    }
    .gap-md-18 {
      gap: 4.5rem !important;
    }
    .gap-md-20 {
      gap: 5rem !important;
    }
    .gap-md-24 {
      gap: 6rem !important;
    }
    .gap-md-32 {
      gap: 8rem !important;
    }
    .gap-md-40 {
      gap: 10rem !important;
    }
    .gap-md-48 {
      gap: 12rem !important;
    }
    .gap-md-56 {
      gap: 14rem !important;
    }
    .gap-md-64 {
      gap: 16rem !important;
    }
    .gap-md-72 {
      gap: 18rem !important;
    }
    .gap-md-80 {
      gap: 20rem !important;
    }
    .gap-md-88 {
      gap: 22rem !important;
    }
    .gap-md-96 {
      gap: 24rem !important;
    }
    .gap-md-px {
      gap: 1px !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .justify-content-md-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-evenly {
      align-content: space-evenly !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      align-self: stretch !important;
    }
    .order-md-first {
      order: -1 !important;
    }
    .order-md-0 {
      order: 0 !important;
    }
    .order-md-1 {
      order: 1 !important;
    }
    .order-md-2 {
      order: 2 !important;
    }
    .order-md-3 {
      order: 3 !important;
    }
    .order-md-4 {
      order: 4 !important;
    }
    .order-md-5 {
      order: 5 !important;
    }
    .order-md-last {
      order: 6 !important;
    }
    .m-md-0 {
      margin: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .m-md-3 {
      margin: 0.75rem !important;
    }
    .m-md-4 {
      margin: 1rem !important;
    }
    .m-md-5 {
      margin: 1.25rem !important;
    }
    .m-md-6 {
      margin: 1.5rem !important;
    }
    .m-md-7 {
      margin: 1.75rem !important;
    }
    .m-md-8 {
      margin: 2rem !important;
    }
    .m-md-10 {
      margin: 2.5rem !important;
    }
    .m-md-12 {
      margin: 3rem !important;
    }
    .m-md-14 {
      margin: 3.5rem !important;
    }
    .m-md-16 {
      margin: 4rem !important;
    }
    .m-md-18 {
      margin: 4.5rem !important;
    }
    .m-md-20 {
      margin: 5rem !important;
    }
    .m-md-24 {
      margin: 6rem !important;
    }
    .m-md-32 {
      margin: 8rem !important;
    }
    .m-md-40 {
      margin: 10rem !important;
    }
    .m-md-48 {
      margin: 12rem !important;
    }
    .m-md-56 {
      margin: 14rem !important;
    }
    .m-md-64 {
      margin: 16rem !important;
    }
    .m-md-72 {
      margin: 18rem !important;
    }
    .m-md-80 {
      margin: 20rem !important;
    }
    .m-md-88 {
      margin: 22rem !important;
    }
    .m-md-96 {
      margin: 24rem !important;
    }
    .m-md-px {
      margin: 1px !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-md-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-md-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-md-3 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-md-4 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-md-5 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-md-6 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-md-7 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-md-8 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-md-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-md-12 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-md-14 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-md-16 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-md-18 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-md-20 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-md-24 {
      margin-right: 6rem !important;
      margin-left: 6rem !important;
    }
    .mx-md-32 {
      margin-right: 8rem !important;
      margin-left: 8rem !important;
    }
    .mx-md-40 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-md-48 {
      margin-right: 12rem !important;
      margin-left: 12rem !important;
    }
    .mx-md-56 {
      margin-right: 14rem !important;
      margin-left: 14rem !important;
    }
    .mx-md-64 {
      margin-right: 16rem !important;
      margin-left: 16rem !important;
    }
    .mx-md-72 {
      margin-right: 18rem !important;
      margin-left: 18rem !important;
    }
    .mx-md-80 {
      margin-right: 20rem !important;
      margin-left: 20rem !important;
    }
    .mx-md-88 {
      margin-right: 22rem !important;
      margin-left: 22rem !important;
    }
    .mx-md-96 {
      margin-right: 24rem !important;
      margin-left: 24rem !important;
    }
    .mx-md-px {
      margin-right: 1px !important;
      margin-left: 1px !important;
    }
    .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-md-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-md-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-md-3 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-md-4 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-md-5 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-md-6 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-md-7 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-md-8 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-md-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-md-12 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-md-14 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-md-16 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-md-18 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-md-20 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-md-24 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important;
    }
    .my-md-32 {
      margin-top: 8rem !important;
      margin-bottom: 8rem !important;
    }
    .my-md-40 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-md-48 {
      margin-top: 12rem !important;
      margin-bottom: 12rem !important;
    }
    .my-md-56 {
      margin-top: 14rem !important;
      margin-bottom: 14rem !important;
    }
    .my-md-64 {
      margin-top: 16rem !important;
      margin-bottom: 16rem !important;
    }
    .my-md-72 {
      margin-top: 18rem !important;
      margin-bottom: 18rem !important;
    }
    .my-md-80 {
      margin-top: 20rem !important;
      margin-bottom: 20rem !important;
    }
    .my-md-88 {
      margin-top: 22rem !important;
      margin-bottom: 22rem !important;
    }
    .my-md-96 {
      margin-top: 24rem !important;
      margin-bottom: 24rem !important;
    }
    .my-md-px {
      margin-top: 1px !important;
      margin-bottom: 1px !important;
    }
    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-md-0 {
      margin-top: 0 !important;
    }
    .mt-md-1 {
      margin-top: 0.25rem !important;
    }
    .mt-md-2 {
      margin-top: 0.5rem !important;
    }
    .mt-md-3 {
      margin-top: 0.75rem !important;
    }
    .mt-md-4 {
      margin-top: 1rem !important;
    }
    .mt-md-5 {
      margin-top: 1.25rem !important;
    }
    .mt-md-6 {
      margin-top: 1.5rem !important;
    }
    .mt-md-7 {
      margin-top: 1.75rem !important;
    }
    .mt-md-8 {
      margin-top: 2rem !important;
    }
    .mt-md-10 {
      margin-top: 2.5rem !important;
    }
    .mt-md-12 {
      margin-top: 3rem !important;
    }
    .mt-md-14 {
      margin-top: 3.5rem !important;
    }
    .mt-md-16 {
      margin-top: 4rem !important;
    }
    .mt-md-18 {
      margin-top: 4.5rem !important;
    }
    .mt-md-20 {
      margin-top: 5rem !important;
    }
    .mt-md-24 {
      margin-top: 6rem !important;
    }
    .mt-md-32 {
      margin-top: 8rem !important;
    }
    .mt-md-40 {
      margin-top: 10rem !important;
    }
    .mt-md-48 {
      margin-top: 12rem !important;
    }
    .mt-md-56 {
      margin-top: 14rem !important;
    }
    .mt-md-64 {
      margin-top: 16rem !important;
    }
    .mt-md-72 {
      margin-top: 18rem !important;
    }
    .mt-md-80 {
      margin-top: 20rem !important;
    }
    .mt-md-88 {
      margin-top: 22rem !important;
    }
    .mt-md-96 {
      margin-top: 24rem !important;
    }
    .mt-md-px {
      margin-top: 1px !important;
    }
    .mt-md-auto {
      margin-top: auto !important;
    }
    .me-md-0 {
      margin-right: 0 !important;
    }
    .me-md-1 {
      margin-right: 0.25rem !important;
    }
    .me-md-2 {
      margin-right: 0.5rem !important;
    }
    .me-md-3 {
      margin-right: 0.75rem !important;
    }
    .me-md-4 {
      margin-right: 1rem !important;
    }
    .me-md-5 {
      margin-right: 1.25rem !important;
    }
    .me-md-6 {
      margin-right: 1.5rem !important;
    }
    .me-md-7 {
      margin-right: 1.75rem !important;
    }
    .me-md-8 {
      margin-right: 2rem !important;
    }
    .me-md-10 {
      margin-right: 2.5rem !important;
    }
    .me-md-12 {
      margin-right: 3rem !important;
    }
    .me-md-14 {
      margin-right: 3.5rem !important;
    }
    .me-md-16 {
      margin-right: 4rem !important;
    }
    .me-md-18 {
      margin-right: 4.5rem !important;
    }
    .me-md-20 {
      margin-right: 5rem !important;
    }
    .me-md-24 {
      margin-right: 6rem !important;
    }
    .me-md-32 {
      margin-right: 8rem !important;
    }
    .me-md-40 {
      margin-right: 10rem !important;
    }
    .me-md-48 {
      margin-right: 12rem !important;
    }
    .me-md-56 {
      margin-right: 14rem !important;
    }
    .me-md-64 {
      margin-right: 16rem !important;
    }
    .me-md-72 {
      margin-right: 18rem !important;
    }
    .me-md-80 {
      margin-right: 20rem !important;
    }
    .me-md-88 {
      margin-right: 22rem !important;
    }
    .me-md-96 {
      margin-right: 24rem !important;
    }
    .me-md-px {
      margin-right: 1px !important;
    }
    .me-md-auto {
      margin-right: auto !important;
    }
    .mb-md-0 {
      margin-bottom: 0 !important;
    }
    .mb-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-md-3 {
      margin-bottom: 0.75rem !important;
    }
    .mb-md-4 {
      margin-bottom: 1rem !important;
    }
    .mb-md-5 {
      margin-bottom: 1.25rem !important;
    }
    .mb-md-6 {
      margin-bottom: 1.5rem !important;
    }
    .mb-md-7 {
      margin-bottom: 1.75rem !important;
    }
    .mb-md-8 {
      margin-bottom: 2rem !important;
    }
    .mb-md-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-md-12 {
      margin-bottom: 3rem !important;
    }
    .mb-md-14 {
      margin-bottom: 3.5rem !important;
    }
    .mb-md-16 {
      margin-bottom: 4rem !important;
    }
    .mb-md-18 {
      margin-bottom: 4.5rem !important;
    }
    .mb-md-20 {
      margin-bottom: 5rem !important;
    }
    .mb-md-24 {
      margin-bottom: 6rem !important;
    }
    .mb-md-32 {
      margin-bottom: 8rem !important;
    }
    .mb-md-40 {
      margin-bottom: 10rem !important;
    }
    .mb-md-48 {
      margin-bottom: 12rem !important;
    }
    .mb-md-56 {
      margin-bottom: 14rem !important;
    }
    .mb-md-64 {
      margin-bottom: 16rem !important;
    }
    .mb-md-72 {
      margin-bottom: 18rem !important;
    }
    .mb-md-80 {
      margin-bottom: 20rem !important;
    }
    .mb-md-88 {
      margin-bottom: 22rem !important;
    }
    .mb-md-96 {
      margin-bottom: 24rem !important;
    }
    .mb-md-px {
      margin-bottom: 1px !important;
    }
    .mb-md-auto {
      margin-bottom: auto !important;
    }
    .ms-md-0 {
      margin-left: 0 !important;
    }
    .ms-md-1 {
      margin-left: 0.25rem !important;
    }
    .ms-md-2 {
      margin-left: 0.5rem !important;
    }
    .ms-md-3 {
      margin-left: 0.75rem !important;
    }
    .ms-md-4 {
      margin-left: 1rem !important;
    }
    .ms-md-5 {
      margin-left: 1.25rem !important;
    }
    .ms-md-6 {
      margin-left: 1.5rem !important;
    }
    .ms-md-7 {
      margin-left: 1.75rem !important;
    }
    .ms-md-8 {
      margin-left: 2rem !important;
    }
    .ms-md-10 {
      margin-left: 2.5rem !important;
    }
    .ms-md-12 {
      margin-left: 3rem !important;
    }
    .ms-md-14 {
      margin-left: 3.5rem !important;
    }
    .ms-md-16 {
      margin-left: 4rem !important;
    }
    .ms-md-18 {
      margin-left: 4.5rem !important;
    }
    .ms-md-20 {
      margin-left: 5rem !important;
    }
    .ms-md-24 {
      margin-left: 6rem !important;
    }
    .ms-md-32 {
      margin-left: 8rem !important;
    }
    .ms-md-40 {
      margin-left: 10rem !important;
    }
    .ms-md-48 {
      margin-left: 12rem !important;
    }
    .ms-md-56 {
      margin-left: 14rem !important;
    }
    .ms-md-64 {
      margin-left: 16rem !important;
    }
    .ms-md-72 {
      margin-left: 18rem !important;
    }
    .ms-md-80 {
      margin-left: 20rem !important;
    }
    .ms-md-88 {
      margin-left: 22rem !important;
    }
    .ms-md-96 {
      margin-left: 24rem !important;
    }
    .ms-md-px {
      margin-left: 1px !important;
    }
    .ms-md-auto {
      margin-left: auto !important;
    }
    .m-md-n1 {
      margin: -0.25rem !important;
    }
    .m-md-n2 {
      margin: -0.5rem !important;
    }
    .m-md-n3 {
      margin: -0.75rem !important;
    }
    .m-md-n4 {
      margin: -1rem !important;
    }
    .m-md-n5 {
      margin: -1.25rem !important;
    }
    .m-md-n6 {
      margin: -1.5rem !important;
    }
    .m-md-n7 {
      margin: -1.75rem !important;
    }
    .m-md-n8 {
      margin: -2rem !important;
    }
    .m-md-n10 {
      margin: -2.5rem !important;
    }
    .m-md-n12 {
      margin: -3rem !important;
    }
    .m-md-n14 {
      margin: -3.5rem !important;
    }
    .m-md-n16 {
      margin: -4rem !important;
    }
    .m-md-n18 {
      margin: -4.5rem !important;
    }
    .m-md-n20 {
      margin: -5rem !important;
    }
    .m-md-n24 {
      margin: -6rem !important;
    }
    .m-md-n32 {
      margin: -8rem !important;
    }
    .m-md-n40 {
      margin: -10rem !important;
    }
    .m-md-n48 {
      margin: -12rem !important;
    }
    .m-md-n56 {
      margin: -14rem !important;
    }
    .m-md-n64 {
      margin: -16rem !important;
    }
    .m-md-n72 {
      margin: -18rem !important;
    }
    .m-md-n80 {
      margin: -20rem !important;
    }
    .m-md-n88 {
      margin: -22rem !important;
    }
    .m-md-n96 {
      margin: -24rem !important;
    }
    .m-md-npx {
      margin: -1px !important;
    }
    .mx-md-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-md-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-md-n3 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-md-n4 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-md-n5 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-md-n6 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-md-n7 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-md-n8 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-md-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-md-n12 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-md-n14 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-md-n16 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-md-n18 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-md-n20 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-md-n24 {
      margin-right: -6rem !important;
      margin-left: -6rem !important;
    }
    .mx-md-n32 {
      margin-right: -8rem !important;
      margin-left: -8rem !important;
    }
    .mx-md-n40 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-md-n48 {
      margin-right: -12rem !important;
      margin-left: -12rem !important;
    }
    .mx-md-n56 {
      margin-right: -14rem !important;
      margin-left: -14rem !important;
    }
    .mx-md-n64 {
      margin-right: -16rem !important;
      margin-left: -16rem !important;
    }
    .mx-md-n72 {
      margin-right: -18rem !important;
      margin-left: -18rem !important;
    }
    .mx-md-n80 {
      margin-right: -20rem !important;
      margin-left: -20rem !important;
    }
    .mx-md-n88 {
      margin-right: -22rem !important;
      margin-left: -22rem !important;
    }
    .mx-md-n96 {
      margin-right: -24rem !important;
      margin-left: -24rem !important;
    }
    .mx-md-npx {
      margin-right: -1px !important;
      margin-left: -1px !important;
    }
    .my-md-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-md-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-md-n3 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-md-n4 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-md-n5 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-md-n6 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-md-n7 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-md-n8 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-md-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-md-n12 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-md-n14 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-md-n16 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-md-n18 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-md-n20 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-md-n24 {
      margin-top: -6rem !important;
      margin-bottom: -6rem !important;
    }
    .my-md-n32 {
      margin-top: -8rem !important;
      margin-bottom: -8rem !important;
    }
    .my-md-n40 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-md-n48 {
      margin-top: -12rem !important;
      margin-bottom: -12rem !important;
    }
    .my-md-n56 {
      margin-top: -14rem !important;
      margin-bottom: -14rem !important;
    }
    .my-md-n64 {
      margin-top: -16rem !important;
      margin-bottom: -16rem !important;
    }
    .my-md-n72 {
      margin-top: -18rem !important;
      margin-bottom: -18rem !important;
    }
    .my-md-n80 {
      margin-top: -20rem !important;
      margin-bottom: -20rem !important;
    }
    .my-md-n88 {
      margin-top: -22rem !important;
      margin-bottom: -22rem !important;
    }
    .my-md-n96 {
      margin-top: -24rem !important;
      margin-bottom: -24rem !important;
    }
    .my-md-npx {
      margin-top: -1px !important;
      margin-bottom: -1px !important;
    }
    .mt-md-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-md-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-md-n3 {
      margin-top: -0.75rem !important;
    }
    .mt-md-n4 {
      margin-top: -1rem !important;
    }
    .mt-md-n5 {
      margin-top: -1.25rem !important;
    }
    .mt-md-n6 {
      margin-top: -1.5rem !important;
    }
    .mt-md-n7 {
      margin-top: -1.75rem !important;
    }
    .mt-md-n8 {
      margin-top: -2rem !important;
    }
    .mt-md-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-md-n12 {
      margin-top: -3rem !important;
    }
    .mt-md-n14 {
      margin-top: -3.5rem !important;
    }
    .mt-md-n16 {
      margin-top: -4rem !important;
    }
    .mt-md-n18 {
      margin-top: -4.5rem !important;
    }
    .mt-md-n20 {
      margin-top: -5rem !important;
    }
    .mt-md-n24 {
      margin-top: -6rem !important;
    }
    .mt-md-n32 {
      margin-top: -8rem !important;
    }
    .mt-md-n40 {
      margin-top: -10rem !important;
    }
    .mt-md-n48 {
      margin-top: -12rem !important;
    }
    .mt-md-n56 {
      margin-top: -14rem !important;
    }
    .mt-md-n64 {
      margin-top: -16rem !important;
    }
    .mt-md-n72 {
      margin-top: -18rem !important;
    }
    .mt-md-n80 {
      margin-top: -20rem !important;
    }
    .mt-md-n88 {
      margin-top: -22rem !important;
    }
    .mt-md-n96 {
      margin-top: -24rem !important;
    }
    .mt-md-npx {
      margin-top: -1px !important;
    }
    .me-md-n1 {
      margin-right: -0.25rem !important;
    }
    .me-md-n2 {
      margin-right: -0.5rem !important;
    }
    .me-md-n3 {
      margin-right: -0.75rem !important;
    }
    .me-md-n4 {
      margin-right: -1rem !important;
    }
    .me-md-n5 {
      margin-right: -1.25rem !important;
    }
    .me-md-n6 {
      margin-right: -1.5rem !important;
    }
    .me-md-n7 {
      margin-right: -1.75rem !important;
    }
    .me-md-n8 {
      margin-right: -2rem !important;
    }
    .me-md-n10 {
      margin-right: -2.5rem !important;
    }
    .me-md-n12 {
      margin-right: -3rem !important;
    }
    .me-md-n14 {
      margin-right: -3.5rem !important;
    }
    .me-md-n16 {
      margin-right: -4rem !important;
    }
    .me-md-n18 {
      margin-right: -4.5rem !important;
    }
    .me-md-n20 {
      margin-right: -5rem !important;
    }
    .me-md-n24 {
      margin-right: -6rem !important;
    }
    .me-md-n32 {
      margin-right: -8rem !important;
    }
    .me-md-n40 {
      margin-right: -10rem !important;
    }
    .me-md-n48 {
      margin-right: -12rem !important;
    }
    .me-md-n56 {
      margin-right: -14rem !important;
    }
    .me-md-n64 {
      margin-right: -16rem !important;
    }
    .me-md-n72 {
      margin-right: -18rem !important;
    }
    .me-md-n80 {
      margin-right: -20rem !important;
    }
    .me-md-n88 {
      margin-right: -22rem !important;
    }
    .me-md-n96 {
      margin-right: -24rem !important;
    }
    .me-md-npx {
      margin-right: -1px !important;
    }
    .mb-md-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-md-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-md-n3 {
      margin-bottom: -0.75rem !important;
    }
    .mb-md-n4 {
      margin-bottom: -1rem !important;
    }
    .mb-md-n5 {
      margin-bottom: -1.25rem !important;
    }
    .mb-md-n6 {
      margin-bottom: -1.5rem !important;
    }
    .mb-md-n7 {
      margin-bottom: -1.75rem !important;
    }
    .mb-md-n8 {
      margin-bottom: -2rem !important;
    }
    .mb-md-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-md-n12 {
      margin-bottom: -3rem !important;
    }
    .mb-md-n14 {
      margin-bottom: -3.5rem !important;
    }
    .mb-md-n16 {
      margin-bottom: -4rem !important;
    }
    .mb-md-n18 {
      margin-bottom: -4.5rem !important;
    }
    .mb-md-n20 {
      margin-bottom: -5rem !important;
    }
    .mb-md-n24 {
      margin-bottom: -6rem !important;
    }
    .mb-md-n32 {
      margin-bottom: -8rem !important;
    }
    .mb-md-n40 {
      margin-bottom: -10rem !important;
    }
    .mb-md-n48 {
      margin-bottom: -12rem !important;
    }
    .mb-md-n56 {
      margin-bottom: -14rem !important;
    }
    .mb-md-n64 {
      margin-bottom: -16rem !important;
    }
    .mb-md-n72 {
      margin-bottom: -18rem !important;
    }
    .mb-md-n80 {
      margin-bottom: -20rem !important;
    }
    .mb-md-n88 {
      margin-bottom: -22rem !important;
    }
    .mb-md-n96 {
      margin-bottom: -24rem !important;
    }
    .mb-md-npx {
      margin-bottom: -1px !important;
    }
    .ms-md-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-md-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-md-n3 {
      margin-left: -0.75rem !important;
    }
    .ms-md-n4 {
      margin-left: -1rem !important;
    }
    .ms-md-n5 {
      margin-left: -1.25rem !important;
    }
    .ms-md-n6 {
      margin-left: -1.5rem !important;
    }
    .ms-md-n7 {
      margin-left: -1.75rem !important;
    }
    .ms-md-n8 {
      margin-left: -2rem !important;
    }
    .ms-md-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-md-n12 {
      margin-left: -3rem !important;
    }
    .ms-md-n14 {
      margin-left: -3.5rem !important;
    }
    .ms-md-n16 {
      margin-left: -4rem !important;
    }
    .ms-md-n18 {
      margin-left: -4.5rem !important;
    }
    .ms-md-n20 {
      margin-left: -5rem !important;
    }
    .ms-md-n24 {
      margin-left: -6rem !important;
    }
    .ms-md-n32 {
      margin-left: -8rem !important;
    }
    .ms-md-n40 {
      margin-left: -10rem !important;
    }
    .ms-md-n48 {
      margin-left: -12rem !important;
    }
    .ms-md-n56 {
      margin-left: -14rem !important;
    }
    .ms-md-n64 {
      margin-left: -16rem !important;
    }
    .ms-md-n72 {
      margin-left: -18rem !important;
    }
    .ms-md-n80 {
      margin-left: -20rem !important;
    }
    .ms-md-n88 {
      margin-left: -22rem !important;
    }
    .ms-md-n96 {
      margin-left: -24rem !important;
    }
    .ms-md-npx {
      margin-left: -1px !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .p-md-3 {
      padding: 0.75rem !important;
    }
    .p-md-4 {
      padding: 1rem !important;
    }
    .p-md-5 {
      padding: 1.25rem !important;
    }
    .p-md-6 {
      padding: 1.5rem !important;
    }
    .p-md-7 {
      padding: 1.75rem !important;
    }
    .p-md-8 {
      padding: 2rem !important;
    }
    .p-md-10 {
      padding: 2.5rem !important;
    }
    .p-md-12 {
      padding: 3rem !important;
    }
    .p-md-14 {
      padding: 3.5rem !important;
    }
    .p-md-16 {
      padding: 4rem !important;
    }
    .p-md-18 {
      padding: 4.5rem !important;
    }
    .p-md-20 {
      padding: 5rem !important;
    }
    .p-md-24 {
      padding: 6rem !important;
    }
    .p-md-32 {
      padding: 8rem !important;
    }
    .p-md-40 {
      padding: 10rem !important;
    }
    .p-md-48 {
      padding: 12rem !important;
    }
    .p-md-56 {
      padding: 14rem !important;
    }
    .p-md-64 {
      padding: 16rem !important;
    }
    .p-md-72 {
      padding: 18rem !important;
    }
    .p-md-80 {
      padding: 20rem !important;
    }
    .p-md-88 {
      padding: 22rem !important;
    }
    .p-md-96 {
      padding: 24rem !important;
    }
    .p-md-px {
      padding: 1px !important;
    }
    .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-md-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-md-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-md-3 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-md-4 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-md-5 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-md-6 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-md-7 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-md-8 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-md-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-md-12 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-md-14 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-md-16 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-md-18 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-md-20 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-md-24 {
      padding-right: 6rem !important;
      padding-left: 6rem !important;
    }
    .px-md-32 {
      padding-right: 8rem !important;
      padding-left: 8rem !important;
    }
    .px-md-40 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-md-48 {
      padding-right: 12rem !important;
      padding-left: 12rem !important;
    }
    .px-md-56 {
      padding-right: 14rem !important;
      padding-left: 14rem !important;
    }
    .px-md-64 {
      padding-right: 16rem !important;
      padding-left: 16rem !important;
    }
    .px-md-72 {
      padding-right: 18rem !important;
      padding-left: 18rem !important;
    }
    .px-md-80 {
      padding-right: 20rem !important;
      padding-left: 20rem !important;
    }
    .px-md-88 {
      padding-right: 22rem !important;
      padding-left: 22rem !important;
    }
    .px-md-96 {
      padding-right: 24rem !important;
      padding-left: 24rem !important;
    }
    .px-md-px {
      padding-right: 1px !important;
      padding-left: 1px !important;
    }
    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-md-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-md-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-md-3 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-md-4 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-md-5 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-md-6 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-md-7 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-md-8 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-md-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-md-12 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-md-14 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-md-16 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-md-18 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-md-20 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-md-24 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important;
    }
    .py-md-32 {
      padding-top: 8rem !important;
      padding-bottom: 8rem !important;
    }
    .py-md-40 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-md-48 {
      padding-top: 12rem !important;
      padding-bottom: 12rem !important;
    }
    .py-md-56 {
      padding-top: 14rem !important;
      padding-bottom: 14rem !important;
    }
    .py-md-64 {
      padding-top: 16rem !important;
      padding-bottom: 16rem !important;
    }
    .py-md-72 {
      padding-top: 18rem !important;
      padding-bottom: 18rem !important;
    }
    .py-md-80 {
      padding-top: 20rem !important;
      padding-bottom: 20rem !important;
    }
    .py-md-88 {
      padding-top: 22rem !important;
      padding-bottom: 22rem !important;
    }
    .py-md-96 {
      padding-top: 24rem !important;
      padding-bottom: 24rem !important;
    }
    .py-md-px {
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }
    .pt-md-0 {
      padding-top: 0 !important;
    }
    .pt-md-1 {
      padding-top: 0.25rem !important;
    }
    .pt-md-2 {
      padding-top: 0.5rem !important;
    }
    .pt-md-3 {
      padding-top: 0.75rem !important;
    }
    .pt-md-4 {
      padding-top: 1rem !important;
    }
    .pt-md-5 {
      padding-top: 1.25rem !important;
    }
    .pt-md-6 {
      padding-top: 1.5rem !important;
    }
    .pt-md-7 {
      padding-top: 1.75rem !important;
    }
    .pt-md-8 {
      padding-top: 2rem !important;
    }
    .pt-md-10 {
      padding-top: 2.5rem !important;
    }
    .pt-md-12 {
      padding-top: 3rem !important;
    }
    .pt-md-14 {
      padding-top: 3.5rem !important;
    }
    .pt-md-16 {
      padding-top: 4rem !important;
    }
    .pt-md-18 {
      padding-top: 4.5rem !important;
    }
    .pt-md-20 {
      padding-top: 5rem !important;
    }
    .pt-md-24 {
      padding-top: 6rem !important;
    }
    .pt-md-32 {
      padding-top: 8rem !important;
    }
    .pt-md-40 {
      padding-top: 10rem !important;
    }
    .pt-md-48 {
      padding-top: 12rem !important;
    }
    .pt-md-56 {
      padding-top: 14rem !important;
    }
    .pt-md-64 {
      padding-top: 16rem !important;
    }
    .pt-md-72 {
      padding-top: 18rem !important;
    }
    .pt-md-80 {
      padding-top: 20rem !important;
    }
    .pt-md-88 {
      padding-top: 22rem !important;
    }
    .pt-md-96 {
      padding-top: 24rem !important;
    }
    .pt-md-px {
      padding-top: 1px !important;
    }
    .pe-md-0 {
      padding-right: 0 !important;
    }
    .pe-md-1 {
      padding-right: 0.25rem !important;
    }
    .pe-md-2 {
      padding-right: 0.5rem !important;
    }
    .pe-md-3 {
      padding-right: 0.75rem !important;
    }
    .pe-md-4 {
      padding-right: 1rem !important;
    }
    .pe-md-5 {
      padding-right: 1.25rem !important;
    }
    .pe-md-6 {
      padding-right: 1.5rem !important;
    }
    .pe-md-7 {
      padding-right: 1.75rem !important;
    }
    .pe-md-8 {
      padding-right: 2rem !important;
    }
    .pe-md-10 {
      padding-right: 2.5rem !important;
    }
    .pe-md-12 {
      padding-right: 3rem !important;
    }
    .pe-md-14 {
      padding-right: 3.5rem !important;
    }
    .pe-md-16 {
      padding-right: 4rem !important;
    }
    .pe-md-18 {
      padding-right: 4.5rem !important;
    }
    .pe-md-20 {
      padding-right: 5rem !important;
    }
    .pe-md-24 {
      padding-right: 6rem !important;
    }
    .pe-md-32 {
      padding-right: 8rem !important;
    }
    .pe-md-40 {
      padding-right: 10rem !important;
    }
    .pe-md-48 {
      padding-right: 12rem !important;
    }
    .pe-md-56 {
      padding-right: 14rem !important;
    }
    .pe-md-64 {
      padding-right: 16rem !important;
    }
    .pe-md-72 {
      padding-right: 18rem !important;
    }
    .pe-md-80 {
      padding-right: 20rem !important;
    }
    .pe-md-88 {
      padding-right: 22rem !important;
    }
    .pe-md-96 {
      padding-right: 24rem !important;
    }
    .pe-md-px {
      padding-right: 1px !important;
    }
    .pb-md-0 {
      padding-bottom: 0 !important;
    }
    .pb-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-md-3 {
      padding-bottom: 0.75rem !important;
    }
    .pb-md-4 {
      padding-bottom: 1rem !important;
    }
    .pb-md-5 {
      padding-bottom: 1.25rem !important;
    }
    .pb-md-6 {
      padding-bottom: 1.5rem !important;
    }
    .pb-md-7 {
      padding-bottom: 1.75rem !important;
    }
    .pb-md-8 {
      padding-bottom: 2rem !important;
    }
    .pb-md-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-md-12 {
      padding-bottom: 3rem !important;
    }
    .pb-md-14 {
      padding-bottom: 3.5rem !important;
    }
    .pb-md-16 {
      padding-bottom: 4rem !important;
    }
    .pb-md-18 {
      padding-bottom: 4.5rem !important;
    }
    .pb-md-20 {
      padding-bottom: 5rem !important;
    }
    .pb-md-24 {
      padding-bottom: 6rem !important;
    }
    .pb-md-32 {
      padding-bottom: 8rem !important;
    }
    .pb-md-40 {
      padding-bottom: 10rem !important;
    }
    .pb-md-48 {
      padding-bottom: 12rem !important;
    }
    .pb-md-56 {
      padding-bottom: 14rem !important;
    }
    .pb-md-64 {
      padding-bottom: 16rem !important;
    }
    .pb-md-72 {
      padding-bottom: 18rem !important;
    }
    .pb-md-80 {
      padding-bottom: 20rem !important;
    }
    .pb-md-88 {
      padding-bottom: 22rem !important;
    }
    .pb-md-96 {
      padding-bottom: 24rem !important;
    }
    .pb-md-px {
      padding-bottom: 1px !important;
    }
    .ps-md-0 {
      padding-left: 0 !important;
    }
    .ps-md-1 {
      padding-left: 0.25rem !important;
    }
    .ps-md-2 {
      padding-left: 0.5rem !important;
    }
    .ps-md-3 {
      padding-left: 0.75rem !important;
    }
    .ps-md-4 {
      padding-left: 1rem !important;
    }
    .ps-md-5 {
      padding-left: 1.25rem !important;
    }
    .ps-md-6 {
      padding-left: 1.5rem !important;
    }
    .ps-md-7 {
      padding-left: 1.75rem !important;
    }
    .ps-md-8 {
      padding-left: 2rem !important;
    }
    .ps-md-10 {
      padding-left: 2.5rem !important;
    }
    .ps-md-12 {
      padding-left: 3rem !important;
    }
    .ps-md-14 {
      padding-left: 3.5rem !important;
    }
    .ps-md-16 {
      padding-left: 4rem !important;
    }
    .ps-md-18 {
      padding-left: 4.5rem !important;
    }
    .ps-md-20 {
      padding-left: 5rem !important;
    }
    .ps-md-24 {
      padding-left: 6rem !important;
    }
    .ps-md-32 {
      padding-left: 8rem !important;
    }
    .ps-md-40 {
      padding-left: 10rem !important;
    }
    .ps-md-48 {
      padding-left: 12rem !important;
    }
    .ps-md-56 {
      padding-left: 14rem !important;
    }
    .ps-md-64 {
      padding-left: 16rem !important;
    }
    .ps-md-72 {
      padding-left: 18rem !important;
    }
    .ps-md-80 {
      padding-left: 20rem !important;
    }
    .ps-md-88 {
      padding-left: 22rem !important;
    }
    .ps-md-96 {
      padding-left: 24rem !important;
    }
    .ps-md-px {
      padding-left: 1px !important;
    }
    .font-md-italic {
      font-style: italic !important;
    }
    .font-md-normal {
      font-style: normal !important;
    }
    .font-md-light {
      font-weight: 300 !important;
    }
    .font-md-lighter {
      font-weight: lighter !important;
    }
    .font-md-regular {
      font-weight: 400 !important;
    }
    .font-md-semibold {
      font-weight: 500 !important;
    }
    .font-md-bold {
      font-weight: 600 !important;
    }
    .font-md-bolder {
      font-weight: bolder !important;
    }
    .lh-md-none {
      line-height: 1 !important;
    }
    .lh-md-tighter {
      line-height: 1.125 !important;
    }
    .lh-md-tight {
      line-height: 1.2 !important;
    }
    .lh-md-snug {
      line-height: 1.375 !important;
    }
    .lh-md-normal {
      line-height: 1.5 !important;
    }
    .lh-md-relaxed {
      line-height: 1.625 !important;
    }
    .lh-md-loose {
      line-height: 2 !important;
    }
    .text-md-start {
      text-align: left !important;
    }
    .text-md-end {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
    .text-md-no-decoration {
      text-decoration: none !important;
    }
    .text-md-no-decoration-hover:hover {
      text-decoration: none !important;
    }
    .text-md-underline {
      text-decoration: underline !important;
    }
    .text-md-underline-hover:hover {
      text-decoration: underline !important;
    }
    .text-md-line-through {
      text-decoration: line-through !important;
    }
    .text-md-line-through-hover:hover {
      text-decoration: line-through !important;
    }
    .text-md-lowercase {
      text-transform: lowercase !important;
    }
    .text-md-lowercase-hover:hover {
      text-transform: lowercase !important;
    }
    .text-md-uppercase {
      text-transform: uppercase !important;
    }
    .text-md-uppercase-hover:hover {
      text-transform: uppercase !important;
    }
    .text-md-capitalize {
      text-transform: capitalize !important;
    }
    .text-md-capitalize-hover:hover {
      text-transform: capitalize !important;
    }
    .text-md-primary {
      --x-text-opacity: 1;
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-primary-hover:hover {
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-secondary {
      --x-text-opacity: 1;
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-secondary-hover:hover {
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-tertiary {
      --x-text-opacity: 1;
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-tertiary-hover:hover {
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-success {
      --x-text-opacity: 1;
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-success-hover:hover {
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-info {
      --x-text-opacity: 1;
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-info-hover:hover {
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-warning {
      --x-text-opacity: 1;
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-warning-hover:hover {
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-danger {
      --x-text-opacity: 1;
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-danger-hover:hover {
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-white {
      --x-text-opacity: 1;
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-white-hover:hover {
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-light {
      --x-text-opacity: 1;
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-light-hover:hover {
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-dark {
      --x-text-opacity: 1;
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-dark-hover:hover {
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-black {
      --x-text-opacity: 1;
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-black-hover:hover {
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-body {
      --x-text-opacity: 1;
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-body-hover:hover {
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-muted {
      --x-text-opacity: 1;
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-muted-hover:hover {
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-heading {
      --x-text-opacity: 1;
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-heading-hover:hover {
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-current {
      --x-text-opacity: 1;
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-current-hover:hover {
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-reset {
      --x-text-opacity: 1;
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .text-md-reset-hover:hover {
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .bg-md-primary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-primary-hover:hover {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-primary-focus:focus {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-primary-focus-within:focus-within {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-secondary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-secondary-hover:hover {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-secondary-focus:focus {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-secondary-focus-within:focus-within {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-tertiary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-tertiary-hover:hover {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-tertiary-focus:focus {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-tertiary-focus-within:focus-within {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-success {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-success-hover:hover {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-success-focus:focus {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-success-focus-within:focus-within {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-info {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-info-hover:hover {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-info-focus:focus {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-info-focus-within:focus-within {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-warning {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-warning-hover:hover {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-warning-focus:focus {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-warning-focus-within:focus-within {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-danger {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-danger-hover:hover {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-danger-focus:focus {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-danger-focus-within:focus-within {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-white {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-white-hover:hover {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-white-focus:focus {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-white-focus-within:focus-within {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-light {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-light-hover:hover {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-light-focus:focus {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-light-focus-within:focus-within {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-dark {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-dark-hover:hover {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-dark-focus:focus {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-dark-focus-within:focus-within {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-body {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-body-hover:hover {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-body-focus:focus {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-body-focus-within:focus-within {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-black {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-black-hover:hover {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-black-focus:focus {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-black-focus-within:focus-within {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-card {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-card-hover:hover {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-card-focus:focus {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-card-focus-within:focus-within {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-md-transparent {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-transparent-hover:hover {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-transparent-focus:focus {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-transparent-focus-within:focus-within {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-current {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-current-hover:hover {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-current-focus:focus {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-md-current-focus-within:focus-within {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .rounded-md {
      border-radius: 0.375rem !important;
    }
    .rounded-md-0 {
      border-radius: 0 !important;
    }
    .rounded-md-1 {
      border-radius: 0.25rem !important;
    }
    .rounded-md-2 {
      border-radius: 0.5rem !important;
    }
    .rounded-md-3 {
      border-radius: 0.75rem !important;
    }
    .rounded-md-4 {
      border-radius: 1rem !important;
    }
    .rounded-md-5 {
      border-radius: 1.5rem !important;
    }
    .rounded-md-6 {
      border-radius: 3rem !important;
    }
    .rounded-md-7 {
      border-radius: 4rem !important;
    }
    .rounded-md-8 {
      border-radius: 5rem !important;
    }
    .rounded-md-9 {
      border-radius: 6rem !important;
    }
    .rounded-md-10 {
      border-radius: 7rem !important;
    }
    .rounded-md-pill {
      border-radius: 50rem !important;
    }
    .rounded-md-circle {
      border-radius: 50% !important;
    }
    .rounded-md-card {
      border-radius: 0.75rem !important;
    }
    .rounded-top-md {
      border-top-left-radius: 0.375rem !important;
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-md-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-top-md-1 {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-md-2 {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-md-3 {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-md-4 {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-md-5 {
      border-top-left-radius: 1.5rem !important;
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-md-6 {
      border-top-left-radius: 3rem !important;
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-md-7 {
      border-top-left-radius: 4rem !important;
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-md-8 {
      border-top-left-radius: 5rem !important;
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-md-9 {
      border-top-left-radius: 6rem !important;
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-md-10 {
      border-top-left-radius: 7rem !important;
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-md-pill {
      border-top-left-radius: 50rem !important;
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-md-circle {
      border-top-left-radius: 50% !important;
      border-top-right-radius: 50% !important;
    }
    .rounded-top-md-card {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-end-md {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-end-md-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-end-md-1 {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-end-md-2 {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-end-md-3 {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-end-md-4 {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-end-md-5 {
      border-top-right-radius: 1.5rem !important;
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-end-md-6 {
      border-top-right-radius: 3rem !important;
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-end-md-7 {
      border-top-right-radius: 4rem !important;
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-end-md-8 {
      border-top-right-radius: 5rem !important;
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-end-md-9 {
      border-top-right-radius: 6rem !important;
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-end-md-10 {
      border-top-right-radius: 7rem !important;
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-end-md-pill {
      border-top-right-radius: 50rem !important;
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-end-md-circle {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .rounded-end-md-card {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-md {
      border-bottom-right-radius: 0.375rem !important;
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-md-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-md-1 {
      border-bottom-right-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-md-2 {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-md-3 {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-md-4 {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-md-5 {
      border-bottom-right-radius: 1.5rem !important;
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-md-6 {
      border-bottom-right-radius: 3rem !important;
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-md-7 {
      border-bottom-right-radius: 4rem !important;
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-md-8 {
      border-bottom-right-radius: 5rem !important;
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-md-9 {
      border-bottom-right-radius: 6rem !important;
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-md-10 {
      border-bottom-right-radius: 7rem !important;
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-md-pill {
      border-bottom-right-radius: 50rem !important;
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-md-circle {
      border-bottom-right-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-md-card {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-start-md {
      border-bottom-left-radius: 0.375rem !important;
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-start-md-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .rounded-start-md-1 {
      border-bottom-left-radius: 0.25rem !important;
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-start-md-2 {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-start-md-3 {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-start-md-4 {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-start-md-5 {
      border-bottom-left-radius: 1.5rem !important;
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-start-md-6 {
      border-bottom-left-radius: 3rem !important;
      border-top-left-radius: 3rem !important;
    }
    .rounded-start-md-7 {
      border-bottom-left-radius: 4rem !important;
      border-top-left-radius: 4rem !important;
    }
    .rounded-start-md-8 {
      border-bottom-left-radius: 5rem !important;
      border-top-left-radius: 5rem !important;
    }
    .rounded-start-md-9 {
      border-bottom-left-radius: 6rem !important;
      border-top-left-radius: 6rem !important;
    }
    .rounded-start-md-10 {
      border-bottom-left-radius: 7rem !important;
      border-top-left-radius: 7rem !important;
    }
    .rounded-start-md-pill {
      border-bottom-left-radius: 50rem !important;
      border-top-left-radius: 50rem !important;
    }
    .rounded-start-md-circle {
      border-bottom-left-radius: 50% !important;
      border-top-left-radius: 50% !important;
    }
    .rounded-start-md-card {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-md {
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-top-start-md-0 {
      border-top-left-radius: 0 !important;
    }
    .rounded-top-start-md-1 {
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-top-start-md-2 {
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-top-start-md-3 {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-md-4 {
      border-top-left-radius: 1rem !important;
    }
    .rounded-top-start-md-5 {
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-top-start-md-6 {
      border-top-left-radius: 3rem !important;
    }
    .rounded-top-start-md-7 {
      border-top-left-radius: 4rem !important;
    }
    .rounded-top-start-md-8 {
      border-top-left-radius: 5rem !important;
    }
    .rounded-top-start-md-9 {
      border-top-left-radius: 6rem !important;
    }
    .rounded-top-start-md-10 {
      border-top-left-radius: 7rem !important;
    }
    .rounded-top-start-md-pill {
      border-top-left-radius: 50rem !important;
    }
    .rounded-top-start-md-circle {
      border-top-left-radius: 50% !important;
    }
    .rounded-top-start-md-card {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-end-md {
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-end-md-0 {
      border-top-right-radius: 0 !important;
    }
    .rounded-top-end-md-1 {
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-end-md-2 {
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-end-md-3 {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-end-md-4 {
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-end-md-5 {
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-end-md-6 {
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-end-md-7 {
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-end-md-8 {
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-end-md-9 {
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-end-md-10 {
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-end-md-pill {
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-end-md-circle {
      border-top-right-radius: 50% !important;
    }
    .rounded-top-end-md-card {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-md {
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-bottom-end-md-0 {
      border-bottom-right-radius: 0 !important;
    }
    .rounded-bottom-end-md-1 {
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-bottom-end-md-2 {
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-bottom-end-md-3 {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-md-4 {
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-bottom-end-md-5 {
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-bottom-end-md-6 {
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-bottom-end-md-7 {
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-bottom-end-md-8 {
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-bottom-end-md-9 {
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-bottom-end-md-10 {
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-bottom-end-md-pill {
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-bottom-end-md-circle {
      border-bottom-right-radius: 50% !important;
    }
    .rounded-bottom-end-md-card {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-start-md {
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-start-md-0 {
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-start-md-1 {
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-start-md-2 {
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-start-md-3 {
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-start-md-4 {
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-start-md-5 {
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-start-md-6 {
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-start-md-7 {
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-start-md-8 {
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-start-md-9 {
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-start-md-10 {
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-start-md-pill {
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-start-md-circle {
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-start-md-card {
      border-bottom-left-radius: 0.75rem !important;
    }
    .border-md-solid {
      border-style: solid !important;
    }
    .border-md-dashed {
      border-style: dashed !important;
    }
    .border-md-dotted {
      border-style: dotted !important;
    }
    .border-md-double {
      border-style: double !important;
    }
    .border-md-groove {
      border-style: groove !important;
    }
    .border-md-none {
      border-style: none !important;
    }
    .cursor-md-auto {
      cursor: auto !important;
    }
    .cursor-md-auto-hover:hover {
      cursor: auto !important;
    }
    .cursor-md-pointer {
      cursor: pointer !important;
    }
    .cursor-md-pointer-hover:hover {
      cursor: pointer !important;
    }
    .cursor-md-wait {
      cursor: wait !important;
    }
    .cursor-md-wait-hover:hover {
      cursor: wait !important;
    }
    .cursor-md-text {
      cursor: text !important;
    }
    .cursor-md-text-hover:hover {
      cursor: text !important;
    }
    .ls-md-tighter {
      letter-spacing: -0.05em !important;
    }
    .ls-md-tight {
      letter-spacing: -0.025em !important;
    }
    .ls-md-normal {
      letter-spacing: 0 !important;
    }
    .ls-md-wide {
      letter-spacing: 0.025em !important;
    }
    .ls-md-wider {
      letter-spacing: 0.05em !important;
    }
    .ls-md-widest {
      letter-spacing: 0.1em !important;
    }
    .bg-md-auto {
      background-size: auto !important;
    }
    .bg-md-cover {
      background-size: cover !important;
    }
    .bg-md-contain {
      background-size: contain !important;
    }
    .min-w-md-0 {
      min-width: 0 !important;
    }
    .min-w-md-full {
      min-width: 100% !important;
    }
    .min-w-md-min {
      min-width: -webkit-min-content !important;
      min-width: -moz-min-content !important;
      min-width: min-content !important;
    }
    .min-w-md-max {
      min-width: -webkit-max-content !important;
      min-width: -moz-max-content !important;
      min-width: max-content !important;
    }
    .min-h-md-0 {
      min-height: 0 !important;
    }
    .min-h-md-full {
      min-height: 100% !important;
    }
    .min-h-md-screen {
      min-height: 100vh !important;
    }
    .inset-md-0 {
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
    }
    .inset-md-1 {
      top: 0.25rem !important;
      right: 0.25rem !important;
      bottom: 0.25rem !important;
      left: 0.25rem !important;
    }
    .inset-md-2 {
      top: 0.5rem !important;
      right: 0.5rem !important;
      bottom: 0.5rem !important;
      left: 0.5rem !important;
    }
    .inset-md-3 {
      top: 0.75rem !important;
      right: 0.75rem !important;
      bottom: 0.75rem !important;
      left: 0.75rem !important;
    }
    .inset-md-4 {
      top: 1rem !important;
      right: 1rem !important;
      bottom: 1rem !important;
      left: 1rem !important;
    }
    .inset-md-5 {
      top: 1.25rem !important;
      right: 1.25rem !important;
      bottom: 1.25rem !important;
      left: 1.25rem !important;
    }
    .inset-md-6 {
      top: 1.5rem !important;
      right: 1.5rem !important;
      bottom: 1.5rem !important;
      left: 1.5rem !important;
    }
    .inset-md-7 {
      top: 1.75rem !important;
      right: 1.75rem !important;
      bottom: 1.75rem !important;
      left: 1.75rem !important;
    }
    .inset-md-8 {
      top: 2rem !important;
      right: 2rem !important;
      bottom: 2rem !important;
      left: 2rem !important;
    }
    .inset-md-10 {
      top: 2.5rem !important;
      right: 2.5rem !important;
      bottom: 2.5rem !important;
      left: 2.5rem !important;
    }
    .inset-md-12 {
      top: 3rem !important;
      right: 3rem !important;
      bottom: 3rem !important;
      left: 3rem !important;
    }
    .inset-md-14 {
      top: 3.5rem !important;
      right: 3.5rem !important;
      bottom: 3.5rem !important;
      left: 3.5rem !important;
    }
    .inset-md-16 {
      top: 4rem !important;
      right: 4rem !important;
      bottom: 4rem !important;
      left: 4rem !important;
    }
    .inset-md-18 {
      top: 4.5rem !important;
      right: 4.5rem !important;
      bottom: 4.5rem !important;
      left: 4.5rem !important;
    }
    .inset-md-20 {
      top: 5rem !important;
      right: 5rem !important;
      bottom: 5rem !important;
      left: 5rem !important;
    }
    .inset-md-24 {
      top: 6rem !important;
      right: 6rem !important;
      bottom: 6rem !important;
      left: 6rem !important;
    }
    .inset-md-32 {
      top: 8rem !important;
      right: 8rem !important;
      bottom: 8rem !important;
      left: 8rem !important;
    }
    .inset-md-40 {
      top: 10rem !important;
      right: 10rem !important;
      bottom: 10rem !important;
      left: 10rem !important;
    }
    .inset-md-48 {
      top: 12rem !important;
      right: 12rem !important;
      bottom: 12rem !important;
      left: 12rem !important;
    }
    .inset-md-56 {
      top: 14rem !important;
      right: 14rem !important;
      bottom: 14rem !important;
      left: 14rem !important;
    }
    .inset-md-64 {
      top: 16rem !important;
      right: 16rem !important;
      bottom: 16rem !important;
      left: 16rem !important;
    }
    .inset-md-72 {
      top: 18rem !important;
      right: 18rem !important;
      bottom: 18rem !important;
      left: 18rem !important;
    }
    .inset-md-80 {
      top: 20rem !important;
      right: 20rem !important;
      bottom: 20rem !important;
      left: 20rem !important;
    }
    .inset-md-88 {
      top: 22rem !important;
      right: 22rem !important;
      bottom: 22rem !important;
      left: 22rem !important;
    }
    .inset-md-96 {
      top: 24rem !important;
      right: 24rem !important;
      bottom: 24rem !important;
      left: 24rem !important;
    }
    .inset-md-px {
      top: 1px !important;
      right: 1px !important;
      bottom: 1px !important;
      left: 1px !important;
    }
    .inset-md-1\/2 {
      top: 50% !important;
      right: 50% !important;
      bottom: 50% !important;
      left: 50% !important;
    }
    .inset-md-full {
      top: 100% !important;
      right: 100% !important;
      bottom: 100% !important;
      left: 100% !important;
    }
    .inset-md-auto {
      top: auto !important;
      right: auto !important;
      bottom: auto !important;
      left: auto !important;
    }
    .inset-x-md-0 {
      left: 0 !important;
      right: 0 !important;
    }
    .inset-x-md-1 {
      left: 0.25rem !important;
      right: 0.25rem !important;
    }
    .inset-x-md-2 {
      left: 0.5rem !important;
      right: 0.5rem !important;
    }
    .inset-x-md-3 {
      left: 0.75rem !important;
      right: 0.75rem !important;
    }
    .inset-x-md-4 {
      left: 1rem !important;
      right: 1rem !important;
    }
    .inset-x-md-5 {
      left: 1.25rem !important;
      right: 1.25rem !important;
    }
    .inset-x-md-6 {
      left: 1.5rem !important;
      right: 1.5rem !important;
    }
    .inset-x-md-7 {
      left: 1.75rem !important;
      right: 1.75rem !important;
    }
    .inset-x-md-8 {
      left: 2rem !important;
      right: 2rem !important;
    }
    .inset-x-md-10 {
      left: 2.5rem !important;
      right: 2.5rem !important;
    }
    .inset-x-md-12 {
      left: 3rem !important;
      right: 3rem !important;
    }
    .inset-x-md-14 {
      left: 3.5rem !important;
      right: 3.5rem !important;
    }
    .inset-x-md-16 {
      left: 4rem !important;
      right: 4rem !important;
    }
    .inset-x-md-18 {
      left: 4.5rem !important;
      right: 4.5rem !important;
    }
    .inset-x-md-20 {
      left: 5rem !important;
      right: 5rem !important;
    }
    .inset-x-md-24 {
      left: 6rem !important;
      right: 6rem !important;
    }
    .inset-x-md-32 {
      left: 8rem !important;
      right: 8rem !important;
    }
    .inset-x-md-40 {
      left: 10rem !important;
      right: 10rem !important;
    }
    .inset-x-md-48 {
      left: 12rem !important;
      right: 12rem !important;
    }
    .inset-x-md-56 {
      left: 14rem !important;
      right: 14rem !important;
    }
    .inset-x-md-64 {
      left: 16rem !important;
      right: 16rem !important;
    }
    .inset-x-md-72 {
      left: 18rem !important;
      right: 18rem !important;
    }
    .inset-x-md-80 {
      left: 20rem !important;
      right: 20rem !important;
    }
    .inset-x-md-88 {
      left: 22rem !important;
      right: 22rem !important;
    }
    .inset-x-md-96 {
      left: 24rem !important;
      right: 24rem !important;
    }
    .inset-x-md-px {
      left: 1px !important;
      right: 1px !important;
    }
    .inset-x-md-1\/2 {
      left: 50% !important;
      right: 50% !important;
    }
    .inset-x-md-full {
      left: 100% !important;
      right: 100% !important;
    }
    .inset-x-md-auto {
      left: auto !important;
      right: auto !important;
    }
    .inset-y-md-0 {
      top: 0 !important;
      bottom: 0 !important;
    }
    .inset-y-md-1 {
      top: 0.25rem !important;
      bottom: 0.25rem !important;
    }
    .inset-y-md-2 {
      top: 0.5rem !important;
      bottom: 0.5rem !important;
    }
    .inset-y-md-3 {
      top: 0.75rem !important;
      bottom: 0.75rem !important;
    }
    .inset-y-md-4 {
      top: 1rem !important;
      bottom: 1rem !important;
    }
    .inset-y-md-5 {
      top: 1.25rem !important;
      bottom: 1.25rem !important;
    }
    .inset-y-md-6 {
      top: 1.5rem !important;
      bottom: 1.5rem !important;
    }
    .inset-y-md-7 {
      top: 1.75rem !important;
      bottom: 1.75rem !important;
    }
    .inset-y-md-8 {
      top: 2rem !important;
      bottom: 2rem !important;
    }
    .inset-y-md-10 {
      top: 2.5rem !important;
      bottom: 2.5rem !important;
    }
    .inset-y-md-12 {
      top: 3rem !important;
      bottom: 3rem !important;
    }
    .inset-y-md-14 {
      top: 3.5rem !important;
      bottom: 3.5rem !important;
    }
    .inset-y-md-16 {
      top: 4rem !important;
      bottom: 4rem !important;
    }
    .inset-y-md-18 {
      top: 4.5rem !important;
      bottom: 4.5rem !important;
    }
    .inset-y-md-20 {
      top: 5rem !important;
      bottom: 5rem !important;
    }
    .inset-y-md-24 {
      top: 6rem !important;
      bottom: 6rem !important;
    }
    .inset-y-md-32 {
      top: 8rem !important;
      bottom: 8rem !important;
    }
    .inset-y-md-40 {
      top: 10rem !important;
      bottom: 10rem !important;
    }
    .inset-y-md-48 {
      top: 12rem !important;
      bottom: 12rem !important;
    }
    .inset-y-md-56 {
      top: 14rem !important;
      bottom: 14rem !important;
    }
    .inset-y-md-64 {
      top: 16rem !important;
      bottom: 16rem !important;
    }
    .inset-y-md-72 {
      top: 18rem !important;
      bottom: 18rem !important;
    }
    .inset-y-md-80 {
      top: 20rem !important;
      bottom: 20rem !important;
    }
    .inset-y-md-88 {
      top: 22rem !important;
      bottom: 22rem !important;
    }
    .inset-y-md-96 {
      top: 24rem !important;
      bottom: 24rem !important;
    }
    .inset-y-md-px {
      top: 1px !important;
      bottom: 1px !important;
    }
    .inset-y-md-1\/2 {
      top: 50% !important;
      bottom: 50% !important;
    }
    .inset-y-md-full {
      top: 100% !important;
      bottom: 100% !important;
    }
    .inset-y-md-auto {
      top: auto !important;
      bottom: auto !important;
    }
    .place-content-md-start {
      place-content: flex-start !important;
    }
    .place-content-md-end {
      place-content: flex-end !important;
    }
    .place-content-md-center {
      place-content: center !important;
    }
    .place-content-md-between {
      place-content: space-between !important;
    }
    .place-content-md-around {
      place-content: space-around !important;
    }
    .place-content-md-evenly {
      place-content: space-evenly !important;
    }
    .place-content-md-stretch {
      place-content: stretch !important;
    }
    .overflow-x-md-auto {
      overflow-x: auto !important;
    }
    .overflow-x-md-hidden {
      overflow-x: hidden !important;
    }
    .overflow-x-md-visible {
      overflow-x: visible !important;
    }
    .overflow-x-md-scroll {
      overflow-x: scroll !important;
    }
    .overflow-y-md-auto {
      overflow-y: auto !important;
    }
    .overflow-y-md-hidden {
      overflow-y: hidden !important;
    }
    .overflow-y-md-visible {
      overflow-y: visible !important;
    }
    .overflow-y-md-scroll {
      overflow-y: scroll !important;
    }
    .transition-md {
      transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform !important;
    }
    .transition-md-none {
      transition-property: none !important;
    }
    .transition-md-all {
      transition-property: all !important;
    }
    .transition-md-colors {
      transition-property: background-color, border-color, color, fill, stroke !important;
    }
    .transition-md-opacity {
      transition-property: opacity !important;
    }
    .transition-md-shadow {
      transition-property: box-shadow !important;
    }
    .transition-md-transform {
      transition-property: transform !important;
    }
    .ease-md-base {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .ease-md-linear {
      transition-timing-function: linear !important;
    }
    .ease-md-in {
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
    }
    .ease-md-out {
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
    }
    .ease-md-in-out {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .duration-md-base {
      transition-duration: 150ms !important;
    }
    .duration-md-75 {
      transition-duration: 75ms !important;
    }
    .duration-md-100 {
      transition-duration: 100ms !important;
    }
    .duration-md-150 {
      transition-duration: 150ms !important;
    }
    .duration-md-200 {
      transition-duration: 200ms !important;
    }
    .duration-md-300 {
      transition-duration: 300ms !important;
    }
    .duration-md-500 {
      transition-duration: 500ms !important;
    }
    .duration-md-700 {
      transition-duration: 700ms !important;
    }
    .duration-md-1000 {
      transition-duration: 1000ms !important;
    }
    .delay-md-75 {
      transition-delay: 75ms !important;
    }
    .delay-md-100 {
      transition-delay: 100ms !important;
    }
    .delay-md-150 {
      transition-delay: 150ms !important;
    }
    .delay-md-200 {
      transition-delay: 200ms !important;
    }
    .delay-md-300 {
      transition-delay: 300ms !important;
    }
    .delay-md-500 {
      transition-delay: 500ms !important;
    }
    .delay-md-700 {
      transition-delay: 700ms !important;
    }
    .delay-md-1000 {
      transition-delay: 1000ms !important;
    }
    .animation-md-none {
      -webkit-animation: none !important;
      animation: none !important;
    }
    .animation-md-spin {
      -webkit-animation: spin 1s linear infinite !important;
      animation: spin 1s linear infinite !important;
    }
    .animation-md-ping {
      -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
      animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
    }
    .animation-md-pulse {
      -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
    }
    .animation-md-bounce {
      -webkit-animation: bounce 1s infinite !important;
      animation: bounce 1s infinite !important;
    }
    .animation-md-slide-left {
      -webkit-animation: slideLeft 3s infinite !important;
      animation: slideLeft 3s infinite !important;
    }
    .animation-md-slide-right {
      -webkit-animation: slideRight 3s infinite !important;
      animation: slideRight 3s infinite !important;
    }
    .animation-md-move-slow-left {
      -webkit-animation: slideLeft 15s linear infinite !important;
      animation: slideLeft 15s linear infinite !important;
    }
    .animation-md-move-slow-right {
      -webkit-animation: slideRight 15s linear infinite !important;
      animation: slideRight 15s linear infinite !important;
    }
    .blur-md-5 {
      --x-blur: 5px !important;
    }
    .blur-md-10 {
      --x-blur: 10px !important;
    }
    .blur-md-25 {
      --x-blur: 25px !important;
    }
    .blur-md-50 {
      --x-blur: 50px !important;
    }
    .blur-md-75 {
      --x-blur: 75px !important;
    }
    .blur-md-100 {
      --x-blur: 100px !important;
    }
    .blur-md-125 {
      --x-blur: 125px !important;
    }
    .blur-md-150 {
      --x-blur: 150px !important;
    }
    .blur-md-200 {
      --x-blur: 200px !important;
    }
    .transform-md-none {
      transform: none !important;
    }
    .origin-md-center {
      transform-origin: center !important;
    }
    .origin-md-center-hover:hover {
      transform-origin: center !important;
    }
    .origin-md-top {
      transform-origin: top !important;
    }
    .origin-md-top-hover:hover {
      transform-origin: top !important;
    }
    .origin-md-top-right {
      transform-origin: top right !important;
    }
    .origin-md-top-right-hover:hover {
      transform-origin: top right !important;
    }
    .origin-md-right {
      transform-origin: right !important;
    }
    .origin-md-right-hover:hover {
      transform-origin: right !important;
    }
    .origin-md-bottom-right {
      transform-origin: bottom right !important;
    }
    .origin-md-bottom-right-hover:hover {
      transform-origin: bottom right !important;
    }
    .origin-md-bottom {
      transform-origin: bottom !important;
    }
    .origin-md-bottom-hover:hover {
      transform-origin: bottom !important;
    }
    .origin-md-bottom-left {
      transform-origin: bottom left !important;
    }
    .origin-md-bottom-left-hover:hover {
      transform-origin: bottom left !important;
    }
    .origin-md-left {
      transform-origin: left !important;
    }
    .origin-md-left-hover:hover {
      transform-origin: left !important;
    }
    .origin-md-top-left {
      transform-origin: top left !important;
    }
    .origin-md-top-left-hover:hover {
      transform-origin: top left !important;
    }
    .scale-md-0 {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-md-0-hover:hover {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-md-25 {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-md-25-hover:hover {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-md-50 {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-md-50-hover:hover {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-md-75 {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-md-75-hover:hover {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-md-90 {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-md-90-hover:hover {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-md-95 {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-md-95-hover:hover {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-md-100 {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-md-100-hover:hover {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-md-105 {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-md-105-hover:hover {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-md-110 {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-md-110-hover:hover {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-md-125 {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-md-125-hover:hover {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-md-150 {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-md-150-hover:hover {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-md-200 {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-md-200-hover:hover {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-y-md-0 {
      --x--scale-y: 0 !important;
    }
    .scale-y-md-0-hover:hover {
      --x--scale-y: 0 !important;
    }
    .scale-y-md-25 {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-md-25-hover:hover {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-md-50 {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-md-50-hover:hover {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-md-75 {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-md-75-hover:hover {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-md-90 {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-md-90-hover:hover {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-md-95 {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-md-95-hover:hover {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-md-100 {
      --x--scale-y: 1 !important;
    }
    .scale-y-md-100-hover:hover {
      --x--scale-y: 1 !important;
    }
    .scale-y-md-105 {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-md-105-hover:hover {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-md-110 {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-md-110-hover:hover {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-md-125 {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-md-125-hover:hover {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-md-150 {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-md-150-hover:hover {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-md-200 {
      --x--scale-y: 2 !important;
    }
    .scale-y-md-200-hover:hover {
      --x--scale-y: 2 !important;
    }
    .scale-x-md-0 {
      --x--scale-x: 0 !important;
    }
    .scale-x-md-0-hover:hover {
      --x--scale-x: 0 !important;
    }
    .scale-x-md-25 {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-md-25-hover:hover {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-md-50 {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-md-50-hover:hover {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-md-75 {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-md-75-hover:hover {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-md-90 {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-md-90-hover:hover {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-md-95 {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-md-95-hover:hover {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-md-100 {
      --x--scale-x: 1 !important;
    }
    .scale-x-md-100-hover:hover {
      --x--scale-x: 1 !important;
    }
    .scale-x-md-105 {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-md-105-hover:hover {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-md-110 {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-md-110-hover:hover {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-md-125 {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-md-125-hover:hover {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-md-150 {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-md-150-hover:hover {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-md-200 {
      --x--scale-x: 2 !important;
    }
    .scale-x-md-200-hover:hover {
      --x--scale-x: 2 !important;
    }
    .rotate-md-0 {
      --x-rotate: 0deg !important;
    }
    .rotate-md-0-hover:hover {
      --x-rotate: 0deg !important;
    }
    .rotate-md-1 {
      --x-rotate: 1deg !important;
    }
    .rotate-md-1-hover:hover {
      --x-rotate: 1deg !important;
    }
    .rotate-md-2 {
      --x-rotate: 2deg !important;
    }
    .rotate-md-2-hover:hover {
      --x-rotate: 2deg !important;
    }
    .rotate-md-3 {
      --x-rotate: 3deg !important;
    }
    .rotate-md-3-hover:hover {
      --x-rotate: 3deg !important;
    }
    .rotate-md-6 {
      --x-rotate: 6deg !important;
    }
    .rotate-md-6-hover:hover {
      --x-rotate: 6deg !important;
    }
    .rotate-md-12 {
      --x-rotate: 12deg !important;
    }
    .rotate-md-12-hover:hover {
      --x-rotate: 12deg !important;
    }
    .rotate-md-30 {
      --x-rotate: 30deg !important;
    }
    .rotate-md-30-hover:hover {
      --x-rotate: 30deg !important;
    }
    .rotate-md-45 {
      --x-rotate: 45deg !important;
    }
    .rotate-md-45-hover:hover {
      --x-rotate: 45deg !important;
    }
    .rotate-md-90 {
      --x-rotate: 90deg !important;
    }
    .rotate-md-90-hover:hover {
      --x-rotate: 90deg !important;
    }
    .rotate-md-180 {
      --x-rotate: 180deg !important;
    }
    .rotate-md-180-hover:hover {
      --x-rotate: 180deg !important;
    }
    .rotate-md-n1 {
      --x-rotate: -1deg !important;
    }
    .rotate-md-n1-hover:hover {
      --x-rotate: -1deg !important;
    }
    .rotate-md-n2 {
      --x-rotate: -2deg !important;
    }
    .rotate-md-n2-hover:hover {
      --x-rotate: -2deg !important;
    }
    .rotate-md-n3 {
      --x-rotate: -3deg !important;
    }
    .rotate-md-n3-hover:hover {
      --x-rotate: -3deg !important;
    }
    .rotate-md-n6 {
      --x-rotate: -6deg !important;
    }
    .rotate-md-n6-hover:hover {
      --x-rotate: -6deg !important;
    }
    .rotate-md-n12 {
      --x-rotate: -12deg !important;
    }
    .rotate-md-n12-hover:hover {
      --x-rotate: -12deg !important;
    }
    .rotate-md-n30 {
      --x-rotate: -30deg !important;
    }
    .rotate-md-n30-hover:hover {
      --x-rotate: -30deg !important;
    }
    .rotate-md-n45 {
      --x-rotate: -45deg !important;
    }
    .rotate-md-n45-hover:hover {
      --x-rotate: -45deg !important;
    }
    .rotate-md-n90 {
      --x-rotate: -90deg !important;
    }
    .rotate-md-n90-hover:hover {
      --x-rotate: -90deg !important;
    }
    .rotate-md-n180 {
      --x-rotate: -180deg !important;
    }
    .rotate-md-n180-hover:hover {
      --x-rotate: -180deg !important;
    }
    .rotate-y-md-0 {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-md-0-hover:hover {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-md-1 {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-md-1-hover:hover {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-md-2 {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-md-2-hover:hover {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-md-3 {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-md-3-hover:hover {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-md-6 {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-md-6-hover:hover {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-md-12 {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-md-12-hover:hover {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-md-30 {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-md-30-hover:hover {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-md-45 {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-md-45-hover:hover {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-md-90 {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-md-90-hover:hover {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-md-180 {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-md-180-hover:hover {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-md-n1 {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-md-n1-hover:hover {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-md-n2 {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-md-n2-hover:hover {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-md-n3 {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-md-n3-hover:hover {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-md-n6 {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-md-n6-hover:hover {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-md-n12 {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-md-n12-hover:hover {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-md-n30 {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-md-n30-hover:hover {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-md-n45 {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-md-n45-hover:hover {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-md-n90 {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-md-n90-hover:hover {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-md-n180 {
      --x-rotate-y: -180deg !important;
    }
    .rotate-y-md-n180-hover:hover {
      --x-rotate-y: -180deg !important;
    }
    .rotate-x-md-0 {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-md-0-hover:hover {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-md-1 {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-md-1-hover:hover {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-md-2 {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-md-2-hover:hover {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-md-3 {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-md-3-hover:hover {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-md-6 {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-md-6-hover:hover {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-md-12 {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-md-12-hover:hover {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-md-30 {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-md-30-hover:hover {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-md-45 {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-md-45-hover:hover {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-md-90 {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-md-90-hover:hover {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-md-180 {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-md-180-hover:hover {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-md-n1 {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-md-n1-hover:hover {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-md-n2 {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-md-n2-hover:hover {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-md-n3 {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-md-n3-hover:hover {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-md-n6 {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-md-n6-hover:hover {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-md-n12 {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-md-n12-hover:hover {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-md-n30 {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-md-n30-hover:hover {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-md-n45 {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-md-n45-hover:hover {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-md-n90 {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-md-n90-hover:hover {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-md-n180 {
      --x-rotate-x: -180deg !important;
    }
    .rotate-x-md-n180-hover:hover {
      --x-rotate-x: -180deg !important;
    }
    .perspective-md-100 {
      --x-perspective: 100px !important;
    }
    .perspective-md-100-hover:hover {
      --x-perspective: 100px !important;
    }
    .perspective-md-200 {
      --x-perspective: 200px !important;
    }
    .perspective-md-200-hover:hover {
      --x-perspective: 200px !important;
    }
    .perspective-md-300 {
      --x-perspective: 300px !important;
    }
    .perspective-md-300-hover:hover {
      --x-perspective: 300px !important;
    }
    .perspective-md-400 {
      --x-perspective: 400px !important;
    }
    .perspective-md-400-hover:hover {
      --x-perspective: 400px !important;
    }
    .perspective-md-500 {
      --x-perspective: 500px !important;
    }
    .perspective-md-500-hover:hover {
      --x-perspective: 500px !important;
    }
    .perspective-md-600 {
      --x-perspective: 600px !important;
    }
    .perspective-md-600-hover:hover {
      --x-perspective: 600px !important;
    }
    .perspective-md-700 {
      --x-perspective: 700px !important;
    }
    .perspective-md-700-hover:hover {
      --x-perspective: 700px !important;
    }
    .perspective-md-800 {
      --x-perspective: 800px !important;
    }
    .perspective-md-800-hover:hover {
      --x-perspective: 800px !important;
    }
    .perspective-md-1000 {
      --x-perspective: 1000px !important;
    }
    .perspective-md-1000-hover:hover {
      --x-perspective: 1000px !important;
    }
    .perspective-md-1250 {
      --x-perspective: 1250px !important;
    }
    .perspective-md-1250-hover:hover {
      --x-perspective: 1250px !important;
    }
    .perspective-md-1500 {
      --x-perspective: 1500px !important;
    }
    .perspective-md-1500-hover:hover {
      --x-perspective: 1500px !important;
    }
    .translate-y-md-0 {
      --x-translate-y: 0 !important;
    }
    .translate-y-md-0-hover:hover {
      --x-translate-y: 0 !important;
    }
    .translate-y-md-1 {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-md-1-hover:hover {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-md-2 {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-md-2-hover:hover {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-md-3 {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-md-3-hover:hover {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-md-4 {
      --x-translate-y: 1rem !important;
    }
    .translate-y-md-4-hover:hover {
      --x-translate-y: 1rem !important;
    }
    .translate-y-md-5 {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-md-5-hover:hover {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-md-6 {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-md-6-hover:hover {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-md-7 {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-md-7-hover:hover {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-md-8 {
      --x-translate-y: 2rem !important;
    }
    .translate-y-md-8-hover:hover {
      --x-translate-y: 2rem !important;
    }
    .translate-y-md-10 {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-md-10-hover:hover {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-md-12 {
      --x-translate-y: 3rem !important;
    }
    .translate-y-md-12-hover:hover {
      --x-translate-y: 3rem !important;
    }
    .translate-y-md-14 {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-md-14-hover:hover {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-md-16 {
      --x-translate-y: 4rem !important;
    }
    .translate-y-md-16-hover:hover {
      --x-translate-y: 4rem !important;
    }
    .translate-y-md-18 {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-md-18-hover:hover {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-md-20 {
      --x-translate-y: 5rem !important;
    }
    .translate-y-md-20-hover:hover {
      --x-translate-y: 5rem !important;
    }
    .translate-y-md-24 {
      --x-translate-y: 6rem !important;
    }
    .translate-y-md-24-hover:hover {
      --x-translate-y: 6rem !important;
    }
    .translate-y-md-32 {
      --x-translate-y: 8rem !important;
    }
    .translate-y-md-32-hover:hover {
      --x-translate-y: 8rem !important;
    }
    .translate-y-md-40 {
      --x-translate-y: 10rem !important;
    }
    .translate-y-md-40-hover:hover {
      --x-translate-y: 10rem !important;
    }
    .translate-y-md-48 {
      --x-translate-y: 12rem !important;
    }
    .translate-y-md-48-hover:hover {
      --x-translate-y: 12rem !important;
    }
    .translate-y-md-56 {
      --x-translate-y: 14rem !important;
    }
    .translate-y-md-56-hover:hover {
      --x-translate-y: 14rem !important;
    }
    .translate-y-md-64 {
      --x-translate-y: 16rem !important;
    }
    .translate-y-md-64-hover:hover {
      --x-translate-y: 16rem !important;
    }
    .translate-y-md-72 {
      --x-translate-y: 18rem !important;
    }
    .translate-y-md-72-hover:hover {
      --x-translate-y: 18rem !important;
    }
    .translate-y-md-80 {
      --x-translate-y: 20rem !important;
    }
    .translate-y-md-80-hover:hover {
      --x-translate-y: 20rem !important;
    }
    .translate-y-md-88 {
      --x-translate-y: 22rem !important;
    }
    .translate-y-md-88-hover:hover {
      --x-translate-y: 22rem !important;
    }
    .translate-y-md-96 {
      --x-translate-y: 24rem !important;
    }
    .translate-y-md-96-hover:hover {
      --x-translate-y: 24rem !important;
    }
    .translate-y-md-px {
      --x-translate-y: 1px !important;
    }
    .translate-y-md-px-hover:hover {
      --x-translate-y: 1px !important;
    }
    .translate-y-md-1\/2 {
      --x-translate-y: 50% !important;
    }
    .translate-y-md-1\/2-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-md-1\/3 {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-md-1\/3-hover:hover {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-md-1\/4 {
      --x-translate-y: 25% !important;
    }
    .translate-y-md-1\/4-hover:hover {
      --x-translate-y: 25% !important;
    }
    .translate-y-md-1\/5 {
      --x-translate-y: 20% !important;
    }
    .translate-y-md-1\/5-hover:hover {
      --x-translate-y: 20% !important;
    }
    .translate-y-md-2\/3 {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-md-2\/3-hover:hover {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-md-2\/5 {
      --x-translate-y: 50% !important;
    }
    .translate-y-md-2\/5-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-md-3\/4 {
      --x-translate-y: 75% !important;
    }
    .translate-y-md-3\/4-hover:hover {
      --x-translate-y: 75% !important;
    }
    .translate-y-md-full {
      --x-translate-y: 100% !important;
    }
    .translate-y-md-full-hover:hover {
      --x-translate-y: 100% !important;
    }
    .translate-y-md-n1 {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-md-n1-hover:hover {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-md-n2 {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-md-n2-hover:hover {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-md-n3 {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-md-n3-hover:hover {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-md-n4 {
      --x-translate-y: -1rem !important;
    }
    .translate-y-md-n4-hover:hover {
      --x-translate-y: -1rem !important;
    }
    .translate-y-md-n5 {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-md-n5-hover:hover {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-md-n6 {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-md-n6-hover:hover {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-md-n7 {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-md-n7-hover:hover {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-md-n8 {
      --x-translate-y: -2rem !important;
    }
    .translate-y-md-n8-hover:hover {
      --x-translate-y: -2rem !important;
    }
    .translate-y-md-n10 {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-md-n10-hover:hover {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-md-n12 {
      --x-translate-y: -3rem !important;
    }
    .translate-y-md-n12-hover:hover {
      --x-translate-y: -3rem !important;
    }
    .translate-y-md-n14 {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-md-n14-hover:hover {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-md-n16 {
      --x-translate-y: -4rem !important;
    }
    .translate-y-md-n16-hover:hover {
      --x-translate-y: -4rem !important;
    }
    .translate-y-md-n18 {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-md-n18-hover:hover {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-md-n20 {
      --x-translate-y: -5rem !important;
    }
    .translate-y-md-n20-hover:hover {
      --x-translate-y: -5rem !important;
    }
    .translate-y-md-n24 {
      --x-translate-y: -6rem !important;
    }
    .translate-y-md-n24-hover:hover {
      --x-translate-y: -6rem !important;
    }
    .translate-y-md-n32 {
      --x-translate-y: -8rem !important;
    }
    .translate-y-md-n32-hover:hover {
      --x-translate-y: -8rem !important;
    }
    .translate-y-md-n40 {
      --x-translate-y: -10rem !important;
    }
    .translate-y-md-n40-hover:hover {
      --x-translate-y: -10rem !important;
    }
    .translate-y-md-n48 {
      --x-translate-y: -12rem !important;
    }
    .translate-y-md-n48-hover:hover {
      --x-translate-y: -12rem !important;
    }
    .translate-y-md-n56 {
      --x-translate-y: -14rem !important;
    }
    .translate-y-md-n56-hover:hover {
      --x-translate-y: -14rem !important;
    }
    .translate-y-md-n64 {
      --x-translate-y: -16rem !important;
    }
    .translate-y-md-n64-hover:hover {
      --x-translate-y: -16rem !important;
    }
    .translate-y-md-n72 {
      --x-translate-y: -18rem !important;
    }
    .translate-y-md-n72-hover:hover {
      --x-translate-y: -18rem !important;
    }
    .translate-y-md-n80 {
      --x-translate-y: -20rem !important;
    }
    .translate-y-md-n80-hover:hover {
      --x-translate-y: -20rem !important;
    }
    .translate-y-md-n88 {
      --x-translate-y: -22rem !important;
    }
    .translate-y-md-n88-hover:hover {
      --x-translate-y: -22rem !important;
    }
    .translate-y-md-n96 {
      --x-translate-y: -24rem !important;
    }
    .translate-y-md-n96-hover:hover {
      --x-translate-y: -24rem !important;
    }
    .translate-y-md-npx {
      --x-translate-y: -1px !important;
    }
    .translate-y-md-npx-hover:hover {
      --x-translate-y: -1px !important;
    }
    .translate-y-md-n1\/2 {
      --x-translate-y: -50% !important;
    }
    .translate-y-md-n1\/2-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-md-n1\/3 {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-md-n1\/3-hover:hover {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-md-n1\/4 {
      --x-translate-y: -25% !important;
    }
    .translate-y-md-n1\/4-hover:hover {
      --x-translate-y: -25% !important;
    }
    .translate-y-md-n1\/5 {
      --x-translate-y: -20% !important;
    }
    .translate-y-md-n1\/5-hover:hover {
      --x-translate-y: -20% !important;
    }
    .translate-y-md-n2\/3 {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-md-n2\/3-hover:hover {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-md-n2\/5 {
      --x-translate-y: -50% !important;
    }
    .translate-y-md-n2\/5-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-md-n3\/4 {
      --x-translate-y: -75% !important;
    }
    .translate-y-md-n3\/4-hover:hover {
      --x-translate-y: -75% !important;
    }
    .translate-y-md-nfull {
      --x-translate-y: -100% !important;
    }
    .translate-y-md-nfull-hover:hover {
      --x-translate-y: -100% !important;
    }
    .translate-x-md-0 {
      --x-translate-x: 0 !important;
    }
    .translate-x-md-0-hover:hover {
      --x-translate-x: 0 !important;
    }
    .translate-x-md-1 {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-md-1-hover:hover {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-md-2 {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-md-2-hover:hover {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-md-3 {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-md-3-hover:hover {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-md-4 {
      --x-translate-x: 1rem !important;
    }
    .translate-x-md-4-hover:hover {
      --x-translate-x: 1rem !important;
    }
    .translate-x-md-5 {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-md-5-hover:hover {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-md-6 {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-md-6-hover:hover {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-md-7 {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-md-7-hover:hover {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-md-8 {
      --x-translate-x: 2rem !important;
    }
    .translate-x-md-8-hover:hover {
      --x-translate-x: 2rem !important;
    }
    .translate-x-md-10 {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-md-10-hover:hover {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-md-12 {
      --x-translate-x: 3rem !important;
    }
    .translate-x-md-12-hover:hover {
      --x-translate-x: 3rem !important;
    }
    .translate-x-md-14 {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-md-14-hover:hover {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-md-16 {
      --x-translate-x: 4rem !important;
    }
    .translate-x-md-16-hover:hover {
      --x-translate-x: 4rem !important;
    }
    .translate-x-md-18 {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-md-18-hover:hover {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-md-20 {
      --x-translate-x: 5rem !important;
    }
    .translate-x-md-20-hover:hover {
      --x-translate-x: 5rem !important;
    }
    .translate-x-md-24 {
      --x-translate-x: 6rem !important;
    }
    .translate-x-md-24-hover:hover {
      --x-translate-x: 6rem !important;
    }
    .translate-x-md-32 {
      --x-translate-x: 8rem !important;
    }
    .translate-x-md-32-hover:hover {
      --x-translate-x: 8rem !important;
    }
    .translate-x-md-40 {
      --x-translate-x: 10rem !important;
    }
    .translate-x-md-40-hover:hover {
      --x-translate-x: 10rem !important;
    }
    .translate-x-md-48 {
      --x-translate-x: 12rem !important;
    }
    .translate-x-md-48-hover:hover {
      --x-translate-x: 12rem !important;
    }
    .translate-x-md-56 {
      --x-translate-x: 14rem !important;
    }
    .translate-x-md-56-hover:hover {
      --x-translate-x: 14rem !important;
    }
    .translate-x-md-64 {
      --x-translate-x: 16rem !important;
    }
    .translate-x-md-64-hover:hover {
      --x-translate-x: 16rem !important;
    }
    .translate-x-md-72 {
      --x-translate-x: 18rem !important;
    }
    .translate-x-md-72-hover:hover {
      --x-translate-x: 18rem !important;
    }
    .translate-x-md-80 {
      --x-translate-x: 20rem !important;
    }
    .translate-x-md-80-hover:hover {
      --x-translate-x: 20rem !important;
    }
    .translate-x-md-88 {
      --x-translate-x: 22rem !important;
    }
    .translate-x-md-88-hover:hover {
      --x-translate-x: 22rem !important;
    }
    .translate-x-md-96 {
      --x-translate-x: 24rem !important;
    }
    .translate-x-md-96-hover:hover {
      --x-translate-x: 24rem !important;
    }
    .translate-x-md-px {
      --x-translate-x: 1px !important;
    }
    .translate-x-md-px-hover:hover {
      --x-translate-x: 1px !important;
    }
    .translate-x-md-1\/2 {
      --x-translate-x: 50% !important;
    }
    .translate-x-md-1\/2-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-md-1\/3 {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-md-1\/3-hover:hover {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-md-1\/4 {
      --x-translate-x: 25% !important;
    }
    .translate-x-md-1\/4-hover:hover {
      --x-translate-x: 25% !important;
    }
    .translate-x-md-1\/5 {
      --x-translate-x: 20% !important;
    }
    .translate-x-md-1\/5-hover:hover {
      --x-translate-x: 20% !important;
    }
    .translate-x-md-2\/3 {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-md-2\/3-hover:hover {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-md-2\/5 {
      --x-translate-x: 50% !important;
    }
    .translate-x-md-2\/5-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-md-3\/4 {
      --x-translate-x: 75% !important;
    }
    .translate-x-md-3\/4-hover:hover {
      --x-translate-x: 75% !important;
    }
    .translate-x-md-full {
      --x-translate-x: 100% !important;
    }
    .translate-x-md-full-hover:hover {
      --x-translate-x: 100% !important;
    }
    .translate-x-md-n1 {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-md-n1-hover:hover {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-md-n2 {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-md-n2-hover:hover {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-md-n3 {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-md-n3-hover:hover {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-md-n4 {
      --x-translate-x: -1rem !important;
    }
    .translate-x-md-n4-hover:hover {
      --x-translate-x: -1rem !important;
    }
    .translate-x-md-n5 {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-md-n5-hover:hover {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-md-n6 {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-md-n6-hover:hover {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-md-n7 {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-md-n7-hover:hover {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-md-n8 {
      --x-translate-x: -2rem !important;
    }
    .translate-x-md-n8-hover:hover {
      --x-translate-x: -2rem !important;
    }
    .translate-x-md-n10 {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-md-n10-hover:hover {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-md-n12 {
      --x-translate-x: -3rem !important;
    }
    .translate-x-md-n12-hover:hover {
      --x-translate-x: -3rem !important;
    }
    .translate-x-md-n14 {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-md-n14-hover:hover {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-md-n16 {
      --x-translate-x: -4rem !important;
    }
    .translate-x-md-n16-hover:hover {
      --x-translate-x: -4rem !important;
    }
    .translate-x-md-n18 {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-md-n18-hover:hover {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-md-n20 {
      --x-translate-x: -5rem !important;
    }
    .translate-x-md-n20-hover:hover {
      --x-translate-x: -5rem !important;
    }
    .translate-x-md-n24 {
      --x-translate-x: -6rem !important;
    }
    .translate-x-md-n24-hover:hover {
      --x-translate-x: -6rem !important;
    }
    .translate-x-md-n32 {
      --x-translate-x: -8rem !important;
    }
    .translate-x-md-n32-hover:hover {
      --x-translate-x: -8rem !important;
    }
    .translate-x-md-n40 {
      --x-translate-x: -10rem !important;
    }
    .translate-x-md-n40-hover:hover {
      --x-translate-x: -10rem !important;
    }
    .translate-x-md-n48 {
      --x-translate-x: -12rem !important;
    }
    .translate-x-md-n48-hover:hover {
      --x-translate-x: -12rem !important;
    }
    .translate-x-md-n56 {
      --x-translate-x: -14rem !important;
    }
    .translate-x-md-n56-hover:hover {
      --x-translate-x: -14rem !important;
    }
    .translate-x-md-n64 {
      --x-translate-x: -16rem !important;
    }
    .translate-x-md-n64-hover:hover {
      --x-translate-x: -16rem !important;
    }
    .translate-x-md-n72 {
      --x-translate-x: -18rem !important;
    }
    .translate-x-md-n72-hover:hover {
      --x-translate-x: -18rem !important;
    }
    .translate-x-md-n80 {
      --x-translate-x: -20rem !important;
    }
    .translate-x-md-n80-hover:hover {
      --x-translate-x: -20rem !important;
    }
    .translate-x-md-n88 {
      --x-translate-x: -22rem !important;
    }
    .translate-x-md-n88-hover:hover {
      --x-translate-x: -22rem !important;
    }
    .translate-x-md-n96 {
      --x-translate-x: -24rem !important;
    }
    .translate-x-md-n96-hover:hover {
      --x-translate-x: -24rem !important;
    }
    .translate-x-md-npx {
      --x-translate-x: -1px !important;
    }
    .translate-x-md-npx-hover:hover {
      --x-translate-x: -1px !important;
    }
    .translate-x-md-n1\/2 {
      --x-translate-x: -50% !important;
    }
    .translate-x-md-n1\/2-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-md-n1\/3 {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-md-n1\/3-hover:hover {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-md-n1\/4 {
      --x-translate-x: -25% !important;
    }
    .translate-x-md-n1\/4-hover:hover {
      --x-translate-x: -25% !important;
    }
    .translate-x-md-n1\/5 {
      --x-translate-x: -20% !important;
    }
    .translate-x-md-n1\/5-hover:hover {
      --x-translate-x: -20% !important;
    }
    .translate-x-md-n2\/3 {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-md-n2\/3-hover:hover {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-md-n2\/5 {
      --x-translate-x: -50% !important;
    }
    .translate-x-md-n2\/5-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-md-n3\/4 {
      --x-translate-x: -75% !important;
    }
    .translate-x-md-n3\/4-hover:hover {
      --x-translate-x: -75% !important;
    }
    .translate-x-md-nfull {
      --x-translate-x: -100% !important;
    }
    .translate-x-md-nfull-hover:hover {
      --x-translate-x: -100% !important;
    }
    .skew-y-md-0 {
      --x-skew-y: 0deg !important;
    }
    .skew-y-md-0-hover:hover {
      --x-skew-y: 0deg !important;
    }
    .skew-y-md-1 {
      --x-skew-y: 1deg !important;
    }
    .skew-y-md-1-hover:hover {
      --x-skew-y: 1deg !important;
    }
    .skew-y-md-2 {
      --x-skew-y: 2deg !important;
    }
    .skew-y-md-2-hover:hover {
      --x-skew-y: 2deg !important;
    }
    .skew-y-md-3 {
      --x-skew-y: 3deg !important;
    }
    .skew-y-md-3-hover:hover {
      --x-skew-y: 3deg !important;
    }
    .skew-y-md-6 {
      --x-skew-y: 6deg !important;
    }
    .skew-y-md-6-hover:hover {
      --x-skew-y: 6deg !important;
    }
    .skew-y-md-12 {
      --x-skew-y: 12deg !important;
    }
    .skew-y-md-12-hover:hover {
      --x-skew-y: 12deg !important;
    }
    .skew-y-md-n1 {
      --x-skew-y: -1deg !important;
    }
    .skew-y-md-n1-hover:hover {
      --x-skew-y: -1deg !important;
    }
    .skew-y-md-n2 {
      --x-skew-y: -2deg !important;
    }
    .skew-y-md-n2-hover:hover {
      --x-skew-y: -2deg !important;
    }
    .skew-y-md-n3 {
      --x-skew-y: -3deg !important;
    }
    .skew-y-md-n3-hover:hover {
      --x-skew-y: -3deg !important;
    }
    .skew-y-md-n6 {
      --x-skew-y: -6deg !important;
    }
    .skew-y-md-n6-hover:hover {
      --x-skew-y: -6deg !important;
    }
    .skew-y-md-n12 {
      --x-skew-y: -12deg !important;
    }
    .skew-y-md-n12-hover:hover {
      --x-skew-y: -12deg !important;
    }
    .skew-x-md-0 {
      --x-skew-x: 0deg !important;
    }
    .skew-x-md-0-hover:hover {
      --x-skew-x: 0deg !important;
    }
    .skew-x-md-1 {
      --x-skew-x: 1deg !important;
    }
    .skew-x-md-1-hover:hover {
      --x-skew-x: 1deg !important;
    }
    .skew-x-md-2 {
      --x-skew-x: 2deg !important;
    }
    .skew-x-md-2-hover:hover {
      --x-skew-x: 2deg !important;
    }
    .skew-x-md-3 {
      --x-skew-x: 3deg !important;
    }
    .skew-x-md-3-hover:hover {
      --x-skew-x: 3deg !important;
    }
    .skew-x-md-6 {
      --x-skew-x: 6deg !important;
    }
    .skew-x-md-6-hover:hover {
      --x-skew-x: 6deg !important;
    }
    .skew-x-md-12 {
      --x-skew-x: 12deg !important;
    }
    .skew-x-md-12-hover:hover {
      --x-skew-x: 12deg !important;
    }
    .skew-x-md-n1 {
      --x-skew-x: -1deg !important;
    }
    .skew-x-md-n1-hover:hover {
      --x-skew-x: -1deg !important;
    }
    .skew-x-md-n2 {
      --x-skew-x: -2deg !important;
    }
    .skew-x-md-n2-hover:hover {
      --x-skew-x: -2deg !important;
    }
    .skew-x-md-n3 {
      --x-skew-x: -3deg !important;
    }
    .skew-x-md-n3-hover:hover {
      --x-skew-x: -3deg !important;
    }
    .skew-x-md-n6 {
      --x-skew-x: -6deg !important;
    }
    .skew-x-md-n6-hover:hover {
      --x-skew-x: -6deg !important;
    }
    .skew-x-md-n12 {
      --x-skew-x: -12deg !important;
    }
    .skew-x-md-n12-hover:hover {
      --x-skew-x: -12deg !important;
    }
  }
  @media (min-width: 992px) {
    .float-lg-start {
      float: left !important;
    }
    .float-lg-end {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
    .opacity-lg-0 {
      opacity: 0 !important;
    }
    .opacity-lg-0-hover:hover {
      opacity: 0 !important;
    }
    .opacity-lg-10 {
      opacity: 0.1 !important;
    }
    .opacity-lg-10-hover:hover {
      opacity: 0.1 !important;
    }
    .opacity-lg-20 {
      opacity: 0.2 !important;
    }
    .opacity-lg-20-hover:hover {
      opacity: 0.2 !important;
    }
    .opacity-lg-30 {
      opacity: 0.3 !important;
    }
    .opacity-lg-30-hover:hover {
      opacity: 0.3 !important;
    }
    .opacity-lg-40 {
      opacity: 0.4 !important;
    }
    .opacity-lg-40-hover:hover {
      opacity: 0.4 !important;
    }
    .opacity-lg-50 {
      opacity: 0.5 !important;
    }
    .opacity-lg-50-hover:hover {
      opacity: 0.5 !important;
    }
    .opacity-lg-60 {
      opacity: 0.6 !important;
    }
    .opacity-lg-60-hover:hover {
      opacity: 0.6 !important;
    }
    .opacity-lg-70 {
      opacity: 0.7 !important;
    }
    .opacity-lg-70-hover:hover {
      opacity: 0.7 !important;
    }
    .opacity-lg-80 {
      opacity: 0.8 !important;
    }
    .opacity-lg-80-hover:hover {
      opacity: 0.8 !important;
    }
    .opacity-lg-90 {
      opacity: 0.9 !important;
    }
    .opacity-lg-90-hover:hover {
      opacity: 0.9 !important;
    }
    .opacity-lg-100 {
      opacity: 1 !important;
    }
    .opacity-lg-100-hover:hover {
      opacity: 1 !important;
    }
    .overflow-lg-auto {
      overflow: auto !important;
    }
    .overflow-lg-hidden {
      overflow: hidden !important;
    }
    .overflow-lg-visible {
      overflow: visible !important;
    }
    .overflow-lg-scroll {
      overflow: scroll !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-grid {
      display: grid !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
    .d-lg-none {
      display: none !important;
    }
    .shadow-lg {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-hover:hover {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-1 {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-1-hover:hover {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-2 {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-2-hover:hover {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-3 {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-3-hover:hover {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-4 {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-4-hover:hover {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-5 {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-5-hover:hover {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-6 {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-6-hover:hover {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-lg-inset {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-lg-inset-hover:hover {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-lg-outline {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-lg-outline-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-lg-focus {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-lg-focus-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-lg-none {
      box-shadow: none !important;
    }
    .shadow-lg-none-hover:hover {
      box-shadow: none !important;
    }
    .position-lg-static {
      position: static !important;
    }
    .position-lg-relative {
      position: relative !important;
    }
    .position-lg-absolute {
      position: absolute !important;
    }
    .position-lg-fixed {
      position: fixed !important;
    }
    .position-lg-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .top-lg-0 {
      top: 0 !important;
    }
    .top-lg-1 {
      top: 0.25rem !important;
    }
    .top-lg-2 {
      top: 0.5rem !important;
    }
    .top-lg-3 {
      top: 0.75rem !important;
    }
    .top-lg-4 {
      top: 1rem !important;
    }
    .top-lg-5 {
      top: 1.25rem !important;
    }
    .top-lg-6 {
      top: 1.5rem !important;
    }
    .top-lg-7 {
      top: 1.75rem !important;
    }
    .top-lg-8 {
      top: 2rem !important;
    }
    .top-lg-10 {
      top: 2.5rem !important;
    }
    .top-lg-12 {
      top: 3rem !important;
    }
    .top-lg-14 {
      top: 3.5rem !important;
    }
    .top-lg-16 {
      top: 4rem !important;
    }
    .top-lg-18 {
      top: 4.5rem !important;
    }
    .top-lg-20 {
      top: 5rem !important;
    }
    .top-lg-24 {
      top: 6rem !important;
    }
    .top-lg-32 {
      top: 8rem !important;
    }
    .top-lg-40 {
      top: 10rem !important;
    }
    .top-lg-48 {
      top: 12rem !important;
    }
    .top-lg-56 {
      top: 14rem !important;
    }
    .top-lg-64 {
      top: 16rem !important;
    }
    .top-lg-72 {
      top: 18rem !important;
    }
    .top-lg-80 {
      top: 20rem !important;
    }
    .top-lg-88 {
      top: 22rem !important;
    }
    .top-lg-96 {
      top: 24rem !important;
    }
    .top-lg-px {
      top: 1px !important;
    }
    .top-lg-1\/2 {
      top: 50% !important;
    }
    .top-lg-full {
      top: 100% !important;
    }
    .top-lg-auto {
      top: auto !important;
    }
    .bottom-lg-0 {
      bottom: 0 !important;
    }
    .bottom-lg-1 {
      bottom: 0.25rem !important;
    }
    .bottom-lg-2 {
      bottom: 0.5rem !important;
    }
    .bottom-lg-3 {
      bottom: 0.75rem !important;
    }
    .bottom-lg-4 {
      bottom: 1rem !important;
    }
    .bottom-lg-5 {
      bottom: 1.25rem !important;
    }
    .bottom-lg-6 {
      bottom: 1.5rem !important;
    }
    .bottom-lg-7 {
      bottom: 1.75rem !important;
    }
    .bottom-lg-8 {
      bottom: 2rem !important;
    }
    .bottom-lg-10 {
      bottom: 2.5rem !important;
    }
    .bottom-lg-12 {
      bottom: 3rem !important;
    }
    .bottom-lg-14 {
      bottom: 3.5rem !important;
    }
    .bottom-lg-16 {
      bottom: 4rem !important;
    }
    .bottom-lg-18 {
      bottom: 4.5rem !important;
    }
    .bottom-lg-20 {
      bottom: 5rem !important;
    }
    .bottom-lg-24 {
      bottom: 6rem !important;
    }
    .bottom-lg-32 {
      bottom: 8rem !important;
    }
    .bottom-lg-40 {
      bottom: 10rem !important;
    }
    .bottom-lg-48 {
      bottom: 12rem !important;
    }
    .bottom-lg-56 {
      bottom: 14rem !important;
    }
    .bottom-lg-64 {
      bottom: 16rem !important;
    }
    .bottom-lg-72 {
      bottom: 18rem !important;
    }
    .bottom-lg-80 {
      bottom: 20rem !important;
    }
    .bottom-lg-88 {
      bottom: 22rem !important;
    }
    .bottom-lg-96 {
      bottom: 24rem !important;
    }
    .bottom-lg-px {
      bottom: 1px !important;
    }
    .bottom-lg-1\/2 {
      bottom: 50% !important;
    }
    .bottom-lg-full {
      bottom: 100% !important;
    }
    .bottom-lg-auto {
      bottom: auto !important;
    }
    .start-lg-0 {
      left: 0 !important;
    }
    .start-lg-1 {
      left: 0.25rem !important;
    }
    .start-lg-2 {
      left: 0.5rem !important;
    }
    .start-lg-3 {
      left: 0.75rem !important;
    }
    .start-lg-4 {
      left: 1rem !important;
    }
    .start-lg-5 {
      left: 1.25rem !important;
    }
    .start-lg-6 {
      left: 1.5rem !important;
    }
    .start-lg-7 {
      left: 1.75rem !important;
    }
    .start-lg-8 {
      left: 2rem !important;
    }
    .start-lg-10 {
      left: 2.5rem !important;
    }
    .start-lg-12 {
      left: 3rem !important;
    }
    .start-lg-14 {
      left: 3.5rem !important;
    }
    .start-lg-16 {
      left: 4rem !important;
    }
    .start-lg-18 {
      left: 4.5rem !important;
    }
    .start-lg-20 {
      left: 5rem !important;
    }
    .start-lg-24 {
      left: 6rem !important;
    }
    .start-lg-32 {
      left: 8rem !important;
    }
    .start-lg-40 {
      left: 10rem !important;
    }
    .start-lg-48 {
      left: 12rem !important;
    }
    .start-lg-56 {
      left: 14rem !important;
    }
    .start-lg-64 {
      left: 16rem !important;
    }
    .start-lg-72 {
      left: 18rem !important;
    }
    .start-lg-80 {
      left: 20rem !important;
    }
    .start-lg-88 {
      left: 22rem !important;
    }
    .start-lg-96 {
      left: 24rem !important;
    }
    .start-lg-px {
      left: 1px !important;
    }
    .start-lg-1\/2 {
      left: 50% !important;
    }
    .start-lg-full {
      left: 100% !important;
    }
    .start-lg-auto {
      left: auto !important;
    }
    .end-lg-0 {
      right: 0 !important;
    }
    .end-lg-1 {
      right: 0.25rem !important;
    }
    .end-lg-2 {
      right: 0.5rem !important;
    }
    .end-lg-3 {
      right: 0.75rem !important;
    }
    .end-lg-4 {
      right: 1rem !important;
    }
    .end-lg-5 {
      right: 1.25rem !important;
    }
    .end-lg-6 {
      right: 1.5rem !important;
    }
    .end-lg-7 {
      right: 1.75rem !important;
    }
    .end-lg-8 {
      right: 2rem !important;
    }
    .end-lg-10 {
      right: 2.5rem !important;
    }
    .end-lg-12 {
      right: 3rem !important;
    }
    .end-lg-14 {
      right: 3.5rem !important;
    }
    .end-lg-16 {
      right: 4rem !important;
    }
    .end-lg-18 {
      right: 4.5rem !important;
    }
    .end-lg-20 {
      right: 5rem !important;
    }
    .end-lg-24 {
      right: 6rem !important;
    }
    .end-lg-32 {
      right: 8rem !important;
    }
    .end-lg-40 {
      right: 10rem !important;
    }
    .end-lg-48 {
      right: 12rem !important;
    }
    .end-lg-56 {
      right: 14rem !important;
    }
    .end-lg-64 {
      right: 16rem !important;
    }
    .end-lg-72 {
      right: 18rem !important;
    }
    .end-lg-80 {
      right: 20rem !important;
    }
    .end-lg-88 {
      right: 22rem !important;
    }
    .end-lg-96 {
      right: 24rem !important;
    }
    .end-lg-px {
      right: 1px !important;
    }
    .end-lg-1\/2 {
      right: 50% !important;
    }
    .end-lg-full {
      right: 100% !important;
    }
    .end-lg-auto {
      right: auto !important;
    }
    .border-top-lg-0 {
      border-top-width: 0 !important;
    }
    .border-top-lg-0-hover:hover {
      border-top-width: 0 !important;
    }
    .border-top-lg-0-focus:focus {
      border-top-width: 0 !important;
    }
    .border-top-lg {
      border-top-width: 1px !important;
    }
    .border-top-lg-hover:hover {
      border-top-width: 1px !important;
    }
    .border-top-lg-focus:focus {
      border-top-width: 1px !important;
    }
    .border-top-lg-2 {
      border-top-width: 2px !important;
    }
    .border-top-lg-2-hover:hover {
      border-top-width: 2px !important;
    }
    .border-top-lg-2-focus:focus {
      border-top-width: 2px !important;
    }
    .border-top-lg-3 {
      border-top-width: 3px !important;
    }
    .border-top-lg-3-hover:hover {
      border-top-width: 3px !important;
    }
    .border-top-lg-3-focus:focus {
      border-top-width: 3px !important;
    }
    .border-top-lg-4 {
      border-top-width: 4px !important;
    }
    .border-top-lg-4-hover:hover {
      border-top-width: 4px !important;
    }
    .border-top-lg-4-focus:focus {
      border-top-width: 4px !important;
    }
    .border-top-lg-5 {
      border-top-width: 5px !important;
    }
    .border-top-lg-5-hover:hover {
      border-top-width: 5px !important;
    }
    .border-top-lg-5-focus:focus {
      border-top-width: 5px !important;
    }
    .border-top-lg-6 {
      border-top-width: 6px !important;
    }
    .border-top-lg-6-hover:hover {
      border-top-width: 6px !important;
    }
    .border-top-lg-6-focus:focus {
      border-top-width: 6px !important;
    }
    .border-top-lg-7 {
      border-top-width: 7px !important;
    }
    .border-top-lg-7-hover:hover {
      border-top-width: 7px !important;
    }
    .border-top-lg-7-focus:focus {
      border-top-width: 7px !important;
    }
    .border-top-lg-8 {
      border-top-width: 8px !important;
    }
    .border-top-lg-8-hover:hover {
      border-top-width: 8px !important;
    }
    .border-top-lg-8-focus:focus {
      border-top-width: 8px !important;
    }
    .border-end-lg-0 {
      border-right-width: 0 !important;
    }
    .border-end-lg-0-hover:hover {
      border-right-width: 0 !important;
    }
    .border-end-lg-0-focus:focus {
      border-right-width: 0 !important;
    }
    .border-end-lg {
      border-right-width: 1px !important;
    }
    .border-end-lg-hover:hover {
      border-right-width: 1px !important;
    }
    .border-end-lg-focus:focus {
      border-right-width: 1px !important;
    }
    .border-end-lg-2 {
      border-right-width: 2px !important;
    }
    .border-end-lg-2-hover:hover {
      border-right-width: 2px !important;
    }
    .border-end-lg-2-focus:focus {
      border-right-width: 2px !important;
    }
    .border-end-lg-3 {
      border-right-width: 3px !important;
    }
    .border-end-lg-3-hover:hover {
      border-right-width: 3px !important;
    }
    .border-end-lg-3-focus:focus {
      border-right-width: 3px !important;
    }
    .border-end-lg-4 {
      border-right-width: 4px !important;
    }
    .border-end-lg-4-hover:hover {
      border-right-width: 4px !important;
    }
    .border-end-lg-4-focus:focus {
      border-right-width: 4px !important;
    }
    .border-end-lg-5 {
      border-right-width: 5px !important;
    }
    .border-end-lg-5-hover:hover {
      border-right-width: 5px !important;
    }
    .border-end-lg-5-focus:focus {
      border-right-width: 5px !important;
    }
    .border-end-lg-6 {
      border-right-width: 6px !important;
    }
    .border-end-lg-6-hover:hover {
      border-right-width: 6px !important;
    }
    .border-end-lg-6-focus:focus {
      border-right-width: 6px !important;
    }
    .border-end-lg-7 {
      border-right-width: 7px !important;
    }
    .border-end-lg-7-hover:hover {
      border-right-width: 7px !important;
    }
    .border-end-lg-7-focus:focus {
      border-right-width: 7px !important;
    }
    .border-end-lg-8 {
      border-right-width: 8px !important;
    }
    .border-end-lg-8-hover:hover {
      border-right-width: 8px !important;
    }
    .border-end-lg-8-focus:focus {
      border-right-width: 8px !important;
    }
    .border-bottom-lg-0 {
      border-bottom-width: 0 !important;
    }
    .border-bottom-lg-0-hover:hover {
      border-bottom-width: 0 !important;
    }
    .border-bottom-lg-0-focus:focus {
      border-bottom-width: 0 !important;
    }
    .border-bottom-lg {
      border-bottom-width: 1px !important;
    }
    .border-bottom-lg-hover:hover {
      border-bottom-width: 1px !important;
    }
    .border-bottom-lg-focus:focus {
      border-bottom-width: 1px !important;
    }
    .border-bottom-lg-2 {
      border-bottom-width: 2px !important;
    }
    .border-bottom-lg-2-hover:hover {
      border-bottom-width: 2px !important;
    }
    .border-bottom-lg-2-focus:focus {
      border-bottom-width: 2px !important;
    }
    .border-bottom-lg-3 {
      border-bottom-width: 3px !important;
    }
    .border-bottom-lg-3-hover:hover {
      border-bottom-width: 3px !important;
    }
    .border-bottom-lg-3-focus:focus {
      border-bottom-width: 3px !important;
    }
    .border-bottom-lg-4 {
      border-bottom-width: 4px !important;
    }
    .border-bottom-lg-4-hover:hover {
      border-bottom-width: 4px !important;
    }
    .border-bottom-lg-4-focus:focus {
      border-bottom-width: 4px !important;
    }
    .border-bottom-lg-5 {
      border-bottom-width: 5px !important;
    }
    .border-bottom-lg-5-hover:hover {
      border-bottom-width: 5px !important;
    }
    .border-bottom-lg-5-focus:focus {
      border-bottom-width: 5px !important;
    }
    .border-bottom-lg-6 {
      border-bottom-width: 6px !important;
    }
    .border-bottom-lg-6-hover:hover {
      border-bottom-width: 6px !important;
    }
    .border-bottom-lg-6-focus:focus {
      border-bottom-width: 6px !important;
    }
    .border-bottom-lg-7 {
      border-bottom-width: 7px !important;
    }
    .border-bottom-lg-7-hover:hover {
      border-bottom-width: 7px !important;
    }
    .border-bottom-lg-7-focus:focus {
      border-bottom-width: 7px !important;
    }
    .border-bottom-lg-8 {
      border-bottom-width: 8px !important;
    }
    .border-bottom-lg-8-hover:hover {
      border-bottom-width: 8px !important;
    }
    .border-bottom-lg-8-focus:focus {
      border-bottom-width: 8px !important;
    }
    .border-start-lg-0 {
      border-left-width: 0 !important;
    }
    .border-start-lg-0-hover:hover {
      border-left-width: 0 !important;
    }
    .border-start-lg-0-focus:focus {
      border-left-width: 0 !important;
    }
    .border-start-lg {
      border-left-width: 1px !important;
    }
    .border-start-lg-hover:hover {
      border-left-width: 1px !important;
    }
    .border-start-lg-focus:focus {
      border-left-width: 1px !important;
    }
    .border-start-lg-2 {
      border-left-width: 2px !important;
    }
    .border-start-lg-2-hover:hover {
      border-left-width: 2px !important;
    }
    .border-start-lg-2-focus:focus {
      border-left-width: 2px !important;
    }
    .border-start-lg-3 {
      border-left-width: 3px !important;
    }
    .border-start-lg-3-hover:hover {
      border-left-width: 3px !important;
    }
    .border-start-lg-3-focus:focus {
      border-left-width: 3px !important;
    }
    .border-start-lg-4 {
      border-left-width: 4px !important;
    }
    .border-start-lg-4-hover:hover {
      border-left-width: 4px !important;
    }
    .border-start-lg-4-focus:focus {
      border-left-width: 4px !important;
    }
    .border-start-lg-5 {
      border-left-width: 5px !important;
    }
    .border-start-lg-5-hover:hover {
      border-left-width: 5px !important;
    }
    .border-start-lg-5-focus:focus {
      border-left-width: 5px !important;
    }
    .border-start-lg-6 {
      border-left-width: 6px !important;
    }
    .border-start-lg-6-hover:hover {
      border-left-width: 6px !important;
    }
    .border-start-lg-6-focus:focus {
      border-left-width: 6px !important;
    }
    .border-start-lg-7 {
      border-left-width: 7px !important;
    }
    .border-start-lg-7-hover:hover {
      border-left-width: 7px !important;
    }
    .border-start-lg-7-focus:focus {
      border-left-width: 7px !important;
    }
    .border-start-lg-8 {
      border-left-width: 8px !important;
    }
    .border-start-lg-8-hover:hover {
      border-left-width: 8px !important;
    }
    .border-start-lg-8-focus:focus {
      border-left-width: 8px !important;
    }
    .border-lg-primary {
      border-color: #4066D5 !important;
    }
    .border-lg-primary-hover:hover {
      border-color: #4066D5 !important;
    }
    .border-lg-primary-focus:focus {
      border-color: #4066D5 !important;
    }
    .border-lg-primary-focus-within:focus-within {
      border-color: #4066D5 !important;
    }
    .border-lg-secondary {
      border-color: #cfd6df !important;
    }
    .border-lg-secondary-hover:hover {
      border-color: #cfd6df !important;
    }
    .border-lg-secondary-focus:focus {
      border-color: #cfd6df !important;
    }
    .border-lg-secondary-focus-within:focus-within {
      border-color: #cfd6df !important;
    }
    .border-lg-tertiary {
      border-color: #ff579a !important;
    }
    .border-lg-tertiary-hover:hover {
      border-color: #ff579a !important;
    }
    .border-lg-tertiary-focus:focus {
      border-color: #ff579a !important;
    }
    .border-lg-tertiary-focus-within:focus-within {
      border-color: #ff579a !important;
    }
    .border-lg-success {
      border-color: #0c8 !important;
    }
    .border-lg-success-hover:hover {
      border-color: #0c8 !important;
    }
    .border-lg-success-focus:focus {
      border-color: #0c8 !important;
    }
    .border-lg-success-focus-within:focus-within {
      border-color: #0c8 !important;
    }
    .border-lg-info {
      border-color: #00d4ff !important;
    }
    .border-lg-info-hover:hover {
      border-color: #00d4ff !important;
    }
    .border-lg-info-focus:focus {
      border-color: #00d4ff !important;
    }
    .border-lg-info-focus-within:focus-within {
      border-color: #00d4ff !important;
    }
    .border-lg-warning {
      border-color: #ff8c00 !important;
    }
    .border-lg-warning-hover:hover {
      border-color: #ff8c00 !important;
    }
    .border-lg-warning-focus:focus {
      border-color: #ff8c00 !important;
    }
    .border-lg-warning-focus-within:focus-within {
      border-color: #ff8c00 !important;
    }
    .border-lg-danger {
      border-color: #f36 !important;
    }
    .border-lg-danger-hover:hover {
      border-color: #f36 !important;
    }
    .border-lg-danger-focus:focus {
      border-color: #f36 !important;
    }
    .border-lg-danger-focus-within:focus-within {
      border-color: #f36 !important;
    }
    .border-lg-white {
      border-color: #fff !important;
    }
    .border-lg-white-hover:hover {
      border-color: #fff !important;
    }
    .border-lg-white-focus:focus {
      border-color: #fff !important;
    }
    .border-lg-white-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-lg-light {
      border-color: #e7eaf0 !important;
    }
    .border-lg-light-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-lg-light-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-lg-light-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-lg-dark {
      border-color: #16192c !important;
    }
    .border-lg-dark-hover:hover {
      border-color: #16192c !important;
    }
    .border-lg-dark-focus:focus {
      border-color: #16192c !important;
    }
    .border-lg-dark-focus-within:focus-within {
      border-color: #16192c !important;
    }
    .border-lg-transparent {
      border-color: transparent !important;
    }
    .border-lg-transparent-hover:hover {
      border-color: transparent !important;
    }
    .border-lg-transparent-focus:focus {
      border-color: transparent !important;
    }
    .border-lg-transparent-focus-within:focus-within {
      border-color: transparent !important;
    }
    .border-lg-current {
      border-color: currentColor !important;
    }
    .border-lg-current-hover:hover {
      border-color: currentColor !important;
    }
    .border-lg-current-focus:focus {
      border-color: currentColor !important;
    }
    .border-lg-current-focus-within:focus-within {
      border-color: currentColor !important;
    }
    .border-lg-base {
      border-color: #e7eaf0 !important;
    }
    .border-lg-base-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-lg-base-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-lg-base-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-lg-body {
      border-color: #fff !important;
    }
    .border-lg-body-hover:hover {
      border-color: #fff !important;
    }
    .border-lg-body-focus:focus {
      border-color: #fff !important;
    }
    .border-lg-body-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-lg-card {
      border-color: #fff !important;
    }
    .border-lg-card-hover:hover {
      border-color: #fff !important;
    }
    .border-lg-card-focus:focus {
      border-color: #fff !important;
    }
    .border-lg-card-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-lg-black {
      border-color: #000 !important;
    }
    .border-lg-black-hover:hover {
      border-color: #000 !important;
    }
    .border-lg-black-focus:focus {
      border-color: #000 !important;
    }
    .border-lg-black-focus-within:focus-within {
      border-color: #000 !important;
    }
    .border-lg-0 {
      border-width: 0 !important;
    }
    .border-lg-0-hover:hover {
      border-width: 0 !important;
    }
    .border-lg-0-focus:focus {
      border-width: 0 !important;
    }
    .border-lg {
      border-width: 1px !important;
    }
    .border-lg-hover:hover {
      border-width: 1px !important;
    }
    .border-lg-focus:focus {
      border-width: 1px !important;
    }
    .border-lg-2 {
      border-width: 2px !important;
    }
    .border-lg-2-hover:hover {
      border-width: 2px !important;
    }
    .border-lg-2-focus:focus {
      border-width: 2px !important;
    }
    .border-lg-3 {
      border-width: 3px !important;
    }
    .border-lg-3-hover:hover {
      border-width: 3px !important;
    }
    .border-lg-3-focus:focus {
      border-width: 3px !important;
    }
    .border-lg-4 {
      border-width: 4px !important;
    }
    .border-lg-4-hover:hover {
      border-width: 4px !important;
    }
    .border-lg-4-focus:focus {
      border-width: 4px !important;
    }
    .border-lg-5 {
      border-width: 5px !important;
    }
    .border-lg-5-hover:hover {
      border-width: 5px !important;
    }
    .border-lg-5-focus:focus {
      border-width: 5px !important;
    }
    .border-lg-6 {
      border-width: 6px !important;
    }
    .border-lg-6-hover:hover {
      border-width: 6px !important;
    }
    .border-lg-6-focus:focus {
      border-width: 6px !important;
    }
    .border-lg-7 {
      border-width: 7px !important;
    }
    .border-lg-7-hover:hover {
      border-width: 7px !important;
    }
    .border-lg-7-focus:focus {
      border-width: 7px !important;
    }
    .border-lg-8 {
      border-width: 8px !important;
    }
    .border-lg-8-hover:hover {
      border-width: 8px !important;
    }
    .border-lg-8-focus:focus {
      border-width: 8px !important;
    }
    .w-lg-0 {
      width: 0 !important;
    }
    .w-lg-1 {
      width: 0.25rem !important;
    }
    .w-lg-2 {
      width: 0.5rem !important;
    }
    .w-lg-3 {
      width: 0.75rem !important;
    }
    .w-lg-4 {
      width: 1rem !important;
    }
    .w-lg-5 {
      width: 1.25rem !important;
    }
    .w-lg-6 {
      width: 1.5rem !important;
    }
    .w-lg-7 {
      width: 1.75rem !important;
    }
    .w-lg-8 {
      width: 2rem !important;
    }
    .w-lg-10 {
      width: 2.5rem !important;
    }
    .w-lg-12 {
      width: 3rem !important;
    }
    .w-lg-14 {
      width: 3.5rem !important;
    }
    .w-lg-16 {
      width: 4rem !important;
    }
    .w-lg-18 {
      width: 4.5rem !important;
    }
    .w-lg-20 {
      width: 5rem !important;
    }
    .w-lg-24 {
      width: 6rem !important;
    }
    .w-lg-32 {
      width: 8rem !important;
    }
    .w-lg-40 {
      width: 10rem !important;
    }
    .w-lg-48 {
      width: 12rem !important;
    }
    .w-lg-56 {
      width: 14rem !important;
    }
    .w-lg-64 {
      width: 16rem !important;
    }
    .w-lg-72 {
      width: 18rem !important;
    }
    .w-lg-80 {
      width: 20rem !important;
    }
    .w-lg-88 {
      width: 22rem !important;
    }
    .w-lg-96 {
      width: 24rem !important;
    }
    .w-lg-px {
      width: 1px !important;
    }
    .w-lg-1\/2 {
      width: 50% !important;
    }
    .w-lg-1\/3 {
      width: 33.3333333333% !important;
    }
    .w-lg-2\/3 {
      width: 66.6666666667% !important;
    }
    .w-lg-1\/4 {
      width: 25% !important;
    }
    .w-lg-2\/4 {
      width: 50% !important;
    }
    .w-lg-3\/4 {
      width: 75% !important;
    }
    .w-lg-1\/5 {
      width: 20% !important;
    }
    .w-lg-2\/5 {
      width: 40% !important;
    }
    .w-lg-3\/5 {
      width: 60% !important;
    }
    .w-lg-4\/5 {
      width: 80% !important;
    }
    .w-lg-1\/6 {
      width: 16.6666666667% !important;
    }
    .w-lg-2\/6 {
      width: 33.3333333333% !important;
    }
    .w-lg-3\/6 {
      width: 50% !important;
    }
    .w-lg-4\/6 {
      width: 66.6666666667% !important;
    }
    .w-lg-5\/6 {
      width: 83.3333333333% !important;
    }
    .w-lg-11\/10 {
      width: 110% !important;
    }
    .w-lg-12\/10 {
      width: 120% !important;
    }
    .w-lg-13\/10 {
      width: 130% !important;
    }
    .w-lg-14\/10 {
      width: 140% !important;
    }
    .w-lg-15\/10 {
      width: 150% !important;
    }
    .w-lg-auto {
      width: auto !important;
    }
    .w-lg-full {
      width: 100% !important;
    }
    .w-lg-screen {
      width: 100vw !important;
    }
    .w-lg-min {
      width: -webkit-min-content !important;
      width: -moz-min-content !important;
      width: min-content !important;
    }
    .w-lg-max {
      width: -webkit-max-content !important;
      width: -moz-max-content !important;
      width: max-content !important;
    }
    .max-w-lg-screen-sm {
      max-width: 640px !important;
    }
    .max-w-lg-screen-md {
      max-width: 768px !important;
    }
    .max-w-lg-screen-lg {
      max-width: 1024px !important;
    }
    .max-w-lg-screen-xl {
      max-width: 1280px !important;
    }
    .max-w-lg-screen-xxl {
      max-width: 1536px !important;
    }
    .max-w-lg-0 {
      max-width: 0 !important;
    }
    .max-w-lg-full {
      max-width: 100% !important;
    }
    .max-w-lg-read {
      max-width: 65ch !important;
    }
    .max-w-lg-min {
      max-width: -webkit-min-content !important;
      max-width: -moz-min-content !important;
      max-width: min-content !important;
    }
    .max-w-lg-max {
      max-width: -webkit-max-content !important;
      max-width: -moz-max-content !important;
      max-width: max-content !important;
    }
    .h-lg-0 {
      height: 0 !important;
    }
    .h-lg-1 {
      height: 0.25rem !important;
    }
    .h-lg-2 {
      height: 0.5rem !important;
    }
    .h-lg-3 {
      height: 0.75rem !important;
    }
    .h-lg-4 {
      height: 1rem !important;
    }
    .h-lg-5 {
      height: 1.25rem !important;
    }
    .h-lg-6 {
      height: 1.5rem !important;
    }
    .h-lg-7 {
      height: 1.75rem !important;
    }
    .h-lg-8 {
      height: 2rem !important;
    }
    .h-lg-10 {
      height: 2.5rem !important;
    }
    .h-lg-12 {
      height: 3rem !important;
    }
    .h-lg-14 {
      height: 3.5rem !important;
    }
    .h-lg-16 {
      height: 4rem !important;
    }
    .h-lg-18 {
      height: 4.5rem !important;
    }
    .h-lg-20 {
      height: 5rem !important;
    }
    .h-lg-24 {
      height: 6rem !important;
    }
    .h-lg-32 {
      height: 8rem !important;
    }
    .h-lg-40 {
      height: 10rem !important;
    }
    .h-lg-48 {
      height: 12rem !important;
    }
    .h-lg-56 {
      height: 14rem !important;
    }
    .h-lg-64 {
      height: 16rem !important;
    }
    .h-lg-72 {
      height: 18rem !important;
    }
    .h-lg-80 {
      height: 20rem !important;
    }
    .h-lg-88 {
      height: 22rem !important;
    }
    .h-lg-96 {
      height: 24rem !important;
    }
    .h-lg-px {
      height: 1px !important;
    }
    .h-lg-1\/2 {
      height: 50% !important;
    }
    .h-lg-1\/3 {
      height: 33.3333333333% !important;
    }
    .h-lg-2\/3 {
      height: 66.6666666667% !important;
    }
    .h-lg-1\/4 {
      height: 25% !important;
    }
    .h-lg-2\/4 {
      height: 50% !important;
    }
    .h-lg-3\/4 {
      height: 75% !important;
    }
    .h-lg-1\/5 {
      height: 20% !important;
    }
    .h-lg-2\/5 {
      height: 40% !important;
    }
    .h-lg-3\/5 {
      height: 60% !important;
    }
    .h-lg-4\/5 {
      height: 80% !important;
    }
    .h-lg-1\/6 {
      height: 16.6666666667% !important;
    }
    .h-lg-2\/6 {
      height: 33.3333333333% !important;
    }
    .h-lg-3\/6 {
      height: 50% !important;
    }
    .h-lg-4\/6 {
      height: 66.6666666667% !important;
    }
    .h-lg-5\/6 {
      height: 83.3333333333% !important;
    }
    .h-lg-11\/10 {
      height: 110% !important;
    }
    .h-lg-12\/10 {
      height: 120% !important;
    }
    .h-lg-13\/10 {
      height: 130% !important;
    }
    .h-lg-14\/10 {
      height: 140% !important;
    }
    .h-lg-15\/10 {
      height: 150% !important;
    }
    .h-lg-auto {
      height: auto !important;
    }
    .h-lg-full {
      height: 100% !important;
    }
    .h-lg-screen {
      height: 100vh !important;
    }
    .max-h-lg-0 {
      max-height: 0 !important;
    }
    .max-h-lg-1 {
      max-height: 0.25rem !important;
    }
    .max-h-lg-2 {
      max-height: 0.5rem !important;
    }
    .max-h-lg-3 {
      max-height: 0.75rem !important;
    }
    .max-h-lg-4 {
      max-height: 1rem !important;
    }
    .max-h-lg-5 {
      max-height: 1.25rem !important;
    }
    .max-h-lg-6 {
      max-height: 1.5rem !important;
    }
    .max-h-lg-7 {
      max-height: 1.75rem !important;
    }
    .max-h-lg-8 {
      max-height: 2rem !important;
    }
    .max-h-lg-10 {
      max-height: 2.5rem !important;
    }
    .max-h-lg-12 {
      max-height: 3rem !important;
    }
    .max-h-lg-14 {
      max-height: 3.5rem !important;
    }
    .max-h-lg-16 {
      max-height: 4rem !important;
    }
    .max-h-lg-18 {
      max-height: 4.5rem !important;
    }
    .max-h-lg-20 {
      max-height: 5rem !important;
    }
    .max-h-lg-24 {
      max-height: 6rem !important;
    }
    .max-h-lg-32 {
      max-height: 8rem !important;
    }
    .max-h-lg-40 {
      max-height: 10rem !important;
    }
    .max-h-lg-48 {
      max-height: 12rem !important;
    }
    .max-h-lg-56 {
      max-height: 14rem !important;
    }
    .max-h-lg-64 {
      max-height: 16rem !important;
    }
    .max-h-lg-72 {
      max-height: 18rem !important;
    }
    .max-h-lg-80 {
      max-height: 20rem !important;
    }
    .max-h-lg-88 {
      max-height: 22rem !important;
    }
    .max-h-lg-96 {
      max-height: 24rem !important;
    }
    .max-h-lg-px {
      max-height: 1px !important;
    }
    .max-h-lg-full {
      max-height: 100% !important;
    }
    .max-h-lg-screen {
      max-height: 100vh !important;
    }
    .flex-lg-1 {
      flex: 1 1 0% !important;
    }
    .flex-lg-fill {
      flex: 1 1 auto !important;
    }
    .flex-lg-initial {
      flex: 0 1 auto !important;
    }
    .flex-lg-none {
      flex: none !important;
    }
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .gap-lg-0 {
      gap: 0 !important;
    }
    .gap-lg-1 {
      gap: 0.25rem !important;
    }
    .gap-lg-2 {
      gap: 0.5rem !important;
    }
    .gap-lg-3 {
      gap: 0.75rem !important;
    }
    .gap-lg-4 {
      gap: 1rem !important;
    }
    .gap-lg-5 {
      gap: 1.25rem !important;
    }
    .gap-lg-6 {
      gap: 1.5rem !important;
    }
    .gap-lg-7 {
      gap: 1.75rem !important;
    }
    .gap-lg-8 {
      gap: 2rem !important;
    }
    .gap-lg-10 {
      gap: 2.5rem !important;
    }
    .gap-lg-12 {
      gap: 3rem !important;
    }
    .gap-lg-14 {
      gap: 3.5rem !important;
    }
    .gap-lg-16 {
      gap: 4rem !important;
    }
    .gap-lg-18 {
      gap: 4.5rem !important;
    }
    .gap-lg-20 {
      gap: 5rem !important;
    }
    .gap-lg-24 {
      gap: 6rem !important;
    }
    .gap-lg-32 {
      gap: 8rem !important;
    }
    .gap-lg-40 {
      gap: 10rem !important;
    }
    .gap-lg-48 {
      gap: 12rem !important;
    }
    .gap-lg-56 {
      gap: 14rem !important;
    }
    .gap-lg-64 {
      gap: 16rem !important;
    }
    .gap-lg-72 {
      gap: 18rem !important;
    }
    .gap-lg-80 {
      gap: 20rem !important;
    }
    .gap-lg-88 {
      gap: 22rem !important;
    }
    .gap-lg-96 {
      gap: 24rem !important;
    }
    .gap-lg-px {
      gap: 1px !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .justify-content-lg-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-evenly {
      align-content: space-evenly !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      align-self: stretch !important;
    }
    .order-lg-first {
      order: -1 !important;
    }
    .order-lg-0 {
      order: 0 !important;
    }
    .order-lg-1 {
      order: 1 !important;
    }
    .order-lg-2 {
      order: 2 !important;
    }
    .order-lg-3 {
      order: 3 !important;
    }
    .order-lg-4 {
      order: 4 !important;
    }
    .order-lg-5 {
      order: 5 !important;
    }
    .order-lg-last {
      order: 6 !important;
    }
    .m-lg-0 {
      margin: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 0.75rem !important;
    }
    .m-lg-4 {
      margin: 1rem !important;
    }
    .m-lg-5 {
      margin: 1.25rem !important;
    }
    .m-lg-6 {
      margin: 1.5rem !important;
    }
    .m-lg-7 {
      margin: 1.75rem !important;
    }
    .m-lg-8 {
      margin: 2rem !important;
    }
    .m-lg-10 {
      margin: 2.5rem !important;
    }
    .m-lg-12 {
      margin: 3rem !important;
    }
    .m-lg-14 {
      margin: 3.5rem !important;
    }
    .m-lg-16 {
      margin: 4rem !important;
    }
    .m-lg-18 {
      margin: 4.5rem !important;
    }
    .m-lg-20 {
      margin: 5rem !important;
    }
    .m-lg-24 {
      margin: 6rem !important;
    }
    .m-lg-32 {
      margin: 8rem !important;
    }
    .m-lg-40 {
      margin: 10rem !important;
    }
    .m-lg-48 {
      margin: 12rem !important;
    }
    .m-lg-56 {
      margin: 14rem !important;
    }
    .m-lg-64 {
      margin: 16rem !important;
    }
    .m-lg-72 {
      margin: 18rem !important;
    }
    .m-lg-80 {
      margin: 20rem !important;
    }
    .m-lg-88 {
      margin: 22rem !important;
    }
    .m-lg-96 {
      margin: 24rem !important;
    }
    .m-lg-px {
      margin: 1px !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-lg-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-lg-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-lg-3 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-lg-4 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-lg-5 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-lg-6 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-lg-7 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-lg-8 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-lg-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-lg-12 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-lg-14 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-lg-16 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-lg-18 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-lg-20 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-lg-24 {
      margin-right: 6rem !important;
      margin-left: 6rem !important;
    }
    .mx-lg-32 {
      margin-right: 8rem !important;
      margin-left: 8rem !important;
    }
    .mx-lg-40 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-lg-48 {
      margin-right: 12rem !important;
      margin-left: 12rem !important;
    }
    .mx-lg-56 {
      margin-right: 14rem !important;
      margin-left: 14rem !important;
    }
    .mx-lg-64 {
      margin-right: 16rem !important;
      margin-left: 16rem !important;
    }
    .mx-lg-72 {
      margin-right: 18rem !important;
      margin-left: 18rem !important;
    }
    .mx-lg-80 {
      margin-right: 20rem !important;
      margin-left: 20rem !important;
    }
    .mx-lg-88 {
      margin-right: 22rem !important;
      margin-left: 22rem !important;
    }
    .mx-lg-96 {
      margin-right: 24rem !important;
      margin-left: 24rem !important;
    }
    .mx-lg-px {
      margin-right: 1px !important;
      margin-left: 1px !important;
    }
    .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-lg-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-lg-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-lg-3 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-lg-4 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-lg-5 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-lg-6 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-lg-7 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-lg-8 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-lg-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-lg-12 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-lg-14 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-lg-16 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-lg-18 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-lg-20 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-lg-24 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important;
    }
    .my-lg-32 {
      margin-top: 8rem !important;
      margin-bottom: 8rem !important;
    }
    .my-lg-40 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-lg-48 {
      margin-top: 12rem !important;
      margin-bottom: 12rem !important;
    }
    .my-lg-56 {
      margin-top: 14rem !important;
      margin-bottom: 14rem !important;
    }
    .my-lg-64 {
      margin-top: 16rem !important;
      margin-bottom: 16rem !important;
    }
    .my-lg-72 {
      margin-top: 18rem !important;
      margin-bottom: 18rem !important;
    }
    .my-lg-80 {
      margin-top: 20rem !important;
      margin-bottom: 20rem !important;
    }
    .my-lg-88 {
      margin-top: 22rem !important;
      margin-bottom: 22rem !important;
    }
    .my-lg-96 {
      margin-top: 24rem !important;
      margin-bottom: 24rem !important;
    }
    .my-lg-px {
      margin-top: 1px !important;
      margin-bottom: 1px !important;
    }
    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-lg-0 {
      margin-top: 0 !important;
    }
    .mt-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mt-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mt-lg-3 {
      margin-top: 0.75rem !important;
    }
    .mt-lg-4 {
      margin-top: 1rem !important;
    }
    .mt-lg-5 {
      margin-top: 1.25rem !important;
    }
    .mt-lg-6 {
      margin-top: 1.5rem !important;
    }
    .mt-lg-7 {
      margin-top: 1.75rem !important;
    }
    .mt-lg-8 {
      margin-top: 2rem !important;
    }
    .mt-lg-10 {
      margin-top: 2.5rem !important;
    }
    .mt-lg-12 {
      margin-top: 3rem !important;
    }
    .mt-lg-14 {
      margin-top: 3.5rem !important;
    }
    .mt-lg-16 {
      margin-top: 4rem !important;
    }
    .mt-lg-18 {
      margin-top: 4.5rem !important;
    }
    .mt-lg-20 {
      margin-top: 5rem !important;
    }
    .mt-lg-24 {
      margin-top: 6rem !important;
    }
    .mt-lg-32 {
      margin-top: 8rem !important;
    }
    .mt-lg-40 {
      margin-top: 10rem !important;
    }
    .mt-lg-48 {
      margin-top: 12rem !important;
    }
    .mt-lg-56 {
      margin-top: 14rem !important;
    }
    .mt-lg-64 {
      margin-top: 16rem !important;
    }
    .mt-lg-72 {
      margin-top: 18rem !important;
    }
    .mt-lg-80 {
      margin-top: 20rem !important;
    }
    .mt-lg-88 {
      margin-top: 22rem !important;
    }
    .mt-lg-96 {
      margin-top: 24rem !important;
    }
    .mt-lg-px {
      margin-top: 1px !important;
    }
    .mt-lg-auto {
      margin-top: auto !important;
    }
    .me-lg-0 {
      margin-right: 0 !important;
    }
    .me-lg-1 {
      margin-right: 0.25rem !important;
    }
    .me-lg-2 {
      margin-right: 0.5rem !important;
    }
    .me-lg-3 {
      margin-right: 0.75rem !important;
    }
    .me-lg-4 {
      margin-right: 1rem !important;
    }
    .me-lg-5 {
      margin-right: 1.25rem !important;
    }
    .me-lg-6 {
      margin-right: 1.5rem !important;
    }
    .me-lg-7 {
      margin-right: 1.75rem !important;
    }
    .me-lg-8 {
      margin-right: 2rem !important;
    }
    .me-lg-10 {
      margin-right: 2.5rem !important;
    }
    .me-lg-12 {
      margin-right: 3rem !important;
    }
    .me-lg-14 {
      margin-right: 3.5rem !important;
    }
    .me-lg-16 {
      margin-right: 4rem !important;
    }
    .me-lg-18 {
      margin-right: 4.5rem !important;
    }
    .me-lg-20 {
      margin-right: 5rem !important;
    }
    .me-lg-24 {
      margin-right: 6rem !important;
    }
    .me-lg-32 {
      margin-right: 8rem !important;
    }
    .me-lg-40 {
      margin-right: 10rem !important;
    }
    .me-lg-48 {
      margin-right: 12rem !important;
    }
    .me-lg-56 {
      margin-right: 14rem !important;
    }
    .me-lg-64 {
      margin-right: 16rem !important;
    }
    .me-lg-72 {
      margin-right: 18rem !important;
    }
    .me-lg-80 {
      margin-right: 20rem !important;
    }
    .me-lg-88 {
      margin-right: 22rem !important;
    }
    .me-lg-96 {
      margin-right: 24rem !important;
    }
    .me-lg-px {
      margin-right: 1px !important;
    }
    .me-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-0 {
      margin-bottom: 0 !important;
    }
    .mb-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-lg-3 {
      margin-bottom: 0.75rem !important;
    }
    .mb-lg-4 {
      margin-bottom: 1rem !important;
    }
    .mb-lg-5 {
      margin-bottom: 1.25rem !important;
    }
    .mb-lg-6 {
      margin-bottom: 1.5rem !important;
    }
    .mb-lg-7 {
      margin-bottom: 1.75rem !important;
    }
    .mb-lg-8 {
      margin-bottom: 2rem !important;
    }
    .mb-lg-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-lg-12 {
      margin-bottom: 3rem !important;
    }
    .mb-lg-14 {
      margin-bottom: 3.5rem !important;
    }
    .mb-lg-16 {
      margin-bottom: 4rem !important;
    }
    .mb-lg-18 {
      margin-bottom: 4.5rem !important;
    }
    .mb-lg-20 {
      margin-bottom: 5rem !important;
    }
    .mb-lg-24 {
      margin-bottom: 6rem !important;
    }
    .mb-lg-32 {
      margin-bottom: 8rem !important;
    }
    .mb-lg-40 {
      margin-bottom: 10rem !important;
    }
    .mb-lg-48 {
      margin-bottom: 12rem !important;
    }
    .mb-lg-56 {
      margin-bottom: 14rem !important;
    }
    .mb-lg-64 {
      margin-bottom: 16rem !important;
    }
    .mb-lg-72 {
      margin-bottom: 18rem !important;
    }
    .mb-lg-80 {
      margin-bottom: 20rem !important;
    }
    .mb-lg-88 {
      margin-bottom: 22rem !important;
    }
    .mb-lg-96 {
      margin-bottom: 24rem !important;
    }
    .mb-lg-px {
      margin-bottom: 1px !important;
    }
    .mb-lg-auto {
      margin-bottom: auto !important;
    }
    .ms-lg-0 {
      margin-left: 0 !important;
    }
    .ms-lg-1 {
      margin-left: 0.25rem !important;
    }
    .ms-lg-2 {
      margin-left: 0.5rem !important;
    }
    .ms-lg-3 {
      margin-left: 0.75rem !important;
    }
    .ms-lg-4 {
      margin-left: 1rem !important;
    }
    .ms-lg-5 {
      margin-left: 1.25rem !important;
    }
    .ms-lg-6 {
      margin-left: 1.5rem !important;
    }
    .ms-lg-7 {
      margin-left: 1.75rem !important;
    }
    .ms-lg-8 {
      margin-left: 2rem !important;
    }
    .ms-lg-10 {
      margin-left: 2.5rem !important;
    }
    .ms-lg-12 {
      margin-left: 3rem !important;
    }
    .ms-lg-14 {
      margin-left: 3.5rem !important;
    }
    .ms-lg-16 {
      margin-left: 4rem !important;
    }
    .ms-lg-18 {
      margin-left: 4.5rem !important;
    }
    .ms-lg-20 {
      margin-left: 5rem !important;
    }
    .ms-lg-24 {
      margin-left: 6rem !important;
    }
    .ms-lg-32 {
      margin-left: 8rem !important;
    }
    .ms-lg-40 {
      margin-left: 10rem !important;
    }
    .ms-lg-48 {
      margin-left: 12rem !important;
    }
    .ms-lg-56 {
      margin-left: 14rem !important;
    }
    .ms-lg-64 {
      margin-left: 16rem !important;
    }
    .ms-lg-72 {
      margin-left: 18rem !important;
    }
    .ms-lg-80 {
      margin-left: 20rem !important;
    }
    .ms-lg-88 {
      margin-left: 22rem !important;
    }
    .ms-lg-96 {
      margin-left: 24rem !important;
    }
    .ms-lg-px {
      margin-left: 1px !important;
    }
    .ms-lg-auto {
      margin-left: auto !important;
    }
    .m-lg-n1 {
      margin: -0.25rem !important;
    }
    .m-lg-n2 {
      margin: -0.5rem !important;
    }
    .m-lg-n3 {
      margin: -0.75rem !important;
    }
    .m-lg-n4 {
      margin: -1rem !important;
    }
    .m-lg-n5 {
      margin: -1.25rem !important;
    }
    .m-lg-n6 {
      margin: -1.5rem !important;
    }
    .m-lg-n7 {
      margin: -1.75rem !important;
    }
    .m-lg-n8 {
      margin: -2rem !important;
    }
    .m-lg-n10 {
      margin: -2.5rem !important;
    }
    .m-lg-n12 {
      margin: -3rem !important;
    }
    .m-lg-n14 {
      margin: -3.5rem !important;
    }
    .m-lg-n16 {
      margin: -4rem !important;
    }
    .m-lg-n18 {
      margin: -4.5rem !important;
    }
    .m-lg-n20 {
      margin: -5rem !important;
    }
    .m-lg-n24 {
      margin: -6rem !important;
    }
    .m-lg-n32 {
      margin: -8rem !important;
    }
    .m-lg-n40 {
      margin: -10rem !important;
    }
    .m-lg-n48 {
      margin: -12rem !important;
    }
    .m-lg-n56 {
      margin: -14rem !important;
    }
    .m-lg-n64 {
      margin: -16rem !important;
    }
    .m-lg-n72 {
      margin: -18rem !important;
    }
    .m-lg-n80 {
      margin: -20rem !important;
    }
    .m-lg-n88 {
      margin: -22rem !important;
    }
    .m-lg-n96 {
      margin: -24rem !important;
    }
    .m-lg-npx {
      margin: -1px !important;
    }
    .mx-lg-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-lg-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-lg-n3 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-lg-n4 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-lg-n5 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-lg-n6 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-lg-n7 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-lg-n8 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-lg-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-lg-n12 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-lg-n14 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-lg-n16 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-lg-n18 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-lg-n20 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-lg-n24 {
      margin-right: -6rem !important;
      margin-left: -6rem !important;
    }
    .mx-lg-n32 {
      margin-right: -8rem !important;
      margin-left: -8rem !important;
    }
    .mx-lg-n40 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-lg-n48 {
      margin-right: -12rem !important;
      margin-left: -12rem !important;
    }
    .mx-lg-n56 {
      margin-right: -14rem !important;
      margin-left: -14rem !important;
    }
    .mx-lg-n64 {
      margin-right: -16rem !important;
      margin-left: -16rem !important;
    }
    .mx-lg-n72 {
      margin-right: -18rem !important;
      margin-left: -18rem !important;
    }
    .mx-lg-n80 {
      margin-right: -20rem !important;
      margin-left: -20rem !important;
    }
    .mx-lg-n88 {
      margin-right: -22rem !important;
      margin-left: -22rem !important;
    }
    .mx-lg-n96 {
      margin-right: -24rem !important;
      margin-left: -24rem !important;
    }
    .mx-lg-npx {
      margin-right: -1px !important;
      margin-left: -1px !important;
    }
    .my-lg-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-lg-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-lg-n3 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-lg-n4 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-lg-n5 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-lg-n6 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-lg-n7 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-lg-n8 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-lg-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-lg-n12 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-lg-n14 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-lg-n16 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-lg-n18 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-lg-n20 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-lg-n24 {
      margin-top: -6rem !important;
      margin-bottom: -6rem !important;
    }
    .my-lg-n32 {
      margin-top: -8rem !important;
      margin-bottom: -8rem !important;
    }
    .my-lg-n40 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-lg-n48 {
      margin-top: -12rem !important;
      margin-bottom: -12rem !important;
    }
    .my-lg-n56 {
      margin-top: -14rem !important;
      margin-bottom: -14rem !important;
    }
    .my-lg-n64 {
      margin-top: -16rem !important;
      margin-bottom: -16rem !important;
    }
    .my-lg-n72 {
      margin-top: -18rem !important;
      margin-bottom: -18rem !important;
    }
    .my-lg-n80 {
      margin-top: -20rem !important;
      margin-bottom: -20rem !important;
    }
    .my-lg-n88 {
      margin-top: -22rem !important;
      margin-bottom: -22rem !important;
    }
    .my-lg-n96 {
      margin-top: -24rem !important;
      margin-bottom: -24rem !important;
    }
    .my-lg-npx {
      margin-top: -1px !important;
      margin-bottom: -1px !important;
    }
    .mt-lg-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-lg-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-lg-n3 {
      margin-top: -0.75rem !important;
    }
    .mt-lg-n4 {
      margin-top: -1rem !important;
    }
    .mt-lg-n5 {
      margin-top: -1.25rem !important;
    }
    .mt-lg-n6 {
      margin-top: -1.5rem !important;
    }
    .mt-lg-n7 {
      margin-top: -1.75rem !important;
    }
    .mt-lg-n8 {
      margin-top: -2rem !important;
    }
    .mt-lg-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-lg-n12 {
      margin-top: -3rem !important;
    }
    .mt-lg-n14 {
      margin-top: -3.5rem !important;
    }
    .mt-lg-n16 {
      margin-top: -4rem !important;
    }
    .mt-lg-n18 {
      margin-top: -4.5rem !important;
    }
    .mt-lg-n20 {
      margin-top: -5rem !important;
    }
    .mt-lg-n24 {
      margin-top: -6rem !important;
    }
    .mt-lg-n32 {
      margin-top: -8rem !important;
    }
    .mt-lg-n40 {
      margin-top: -10rem !important;
    }
    .mt-lg-n48 {
      margin-top: -12rem !important;
    }
    .mt-lg-n56 {
      margin-top: -14rem !important;
    }
    .mt-lg-n64 {
      margin-top: -16rem !important;
    }
    .mt-lg-n72 {
      margin-top: -18rem !important;
    }
    .mt-lg-n80 {
      margin-top: -20rem !important;
    }
    .mt-lg-n88 {
      margin-top: -22rem !important;
    }
    .mt-lg-n96 {
      margin-top: -24rem !important;
    }
    .mt-lg-npx {
      margin-top: -1px !important;
    }
    .me-lg-n1 {
      margin-right: -0.25rem !important;
    }
    .me-lg-n2 {
      margin-right: -0.5rem !important;
    }
    .me-lg-n3 {
      margin-right: -0.75rem !important;
    }
    .me-lg-n4 {
      margin-right: -1rem !important;
    }
    .me-lg-n5 {
      margin-right: -1.25rem !important;
    }
    .me-lg-n6 {
      margin-right: -1.5rem !important;
    }
    .me-lg-n7 {
      margin-right: -1.75rem !important;
    }
    .me-lg-n8 {
      margin-right: -2rem !important;
    }
    .me-lg-n10 {
      margin-right: -2.5rem !important;
    }
    .me-lg-n12 {
      margin-right: -3rem !important;
    }
    .me-lg-n14 {
      margin-right: -3.5rem !important;
    }
    .me-lg-n16 {
      margin-right: -4rem !important;
    }
    .me-lg-n18 {
      margin-right: -4.5rem !important;
    }
    .me-lg-n20 {
      margin-right: -5rem !important;
    }
    .me-lg-n24 {
      margin-right: -6rem !important;
    }
    .me-lg-n32 {
      margin-right: -8rem !important;
    }
    .me-lg-n40 {
      margin-right: -10rem !important;
    }
    .me-lg-n48 {
      margin-right: -12rem !important;
    }
    .me-lg-n56 {
      margin-right: -14rem !important;
    }
    .me-lg-n64 {
      margin-right: -16rem !important;
    }
    .me-lg-n72 {
      margin-right: -18rem !important;
    }
    .me-lg-n80 {
      margin-right: -20rem !important;
    }
    .me-lg-n88 {
      margin-right: -22rem !important;
    }
    .me-lg-n96 {
      margin-right: -24rem !important;
    }
    .me-lg-npx {
      margin-right: -1px !important;
    }
    .mb-lg-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-lg-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-lg-n3 {
      margin-bottom: -0.75rem !important;
    }
    .mb-lg-n4 {
      margin-bottom: -1rem !important;
    }
    .mb-lg-n5 {
      margin-bottom: -1.25rem !important;
    }
    .mb-lg-n6 {
      margin-bottom: -1.5rem !important;
    }
    .mb-lg-n7 {
      margin-bottom: -1.75rem !important;
    }
    .mb-lg-n8 {
      margin-bottom: -2rem !important;
    }
    .mb-lg-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-lg-n12 {
      margin-bottom: -3rem !important;
    }
    .mb-lg-n14 {
      margin-bottom: -3.5rem !important;
    }
    .mb-lg-n16 {
      margin-bottom: -4rem !important;
    }
    .mb-lg-n18 {
      margin-bottom: -4.5rem !important;
    }
    .mb-lg-n20 {
      margin-bottom: -5rem !important;
    }
    .mb-lg-n24 {
      margin-bottom: -6rem !important;
    }
    .mb-lg-n32 {
      margin-bottom: -8rem !important;
    }
    .mb-lg-n40 {
      margin-bottom: -10rem !important;
    }
    .mb-lg-n48 {
      margin-bottom: -12rem !important;
    }
    .mb-lg-n56 {
      margin-bottom: -14rem !important;
    }
    .mb-lg-n64 {
      margin-bottom: -16rem !important;
    }
    .mb-lg-n72 {
      margin-bottom: -18rem !important;
    }
    .mb-lg-n80 {
      margin-bottom: -20rem !important;
    }
    .mb-lg-n88 {
      margin-bottom: -22rem !important;
    }
    .mb-lg-n96 {
      margin-bottom: -24rem !important;
    }
    .mb-lg-npx {
      margin-bottom: -1px !important;
    }
    .ms-lg-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-lg-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-lg-n3 {
      margin-left: -0.75rem !important;
    }
    .ms-lg-n4 {
      margin-left: -1rem !important;
    }
    .ms-lg-n5 {
      margin-left: -1.25rem !important;
    }
    .ms-lg-n6 {
      margin-left: -1.5rem !important;
    }
    .ms-lg-n7 {
      margin-left: -1.75rem !important;
    }
    .ms-lg-n8 {
      margin-left: -2rem !important;
    }
    .ms-lg-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-lg-n12 {
      margin-left: -3rem !important;
    }
    .ms-lg-n14 {
      margin-left: -3.5rem !important;
    }
    .ms-lg-n16 {
      margin-left: -4rem !important;
    }
    .ms-lg-n18 {
      margin-left: -4.5rem !important;
    }
    .ms-lg-n20 {
      margin-left: -5rem !important;
    }
    .ms-lg-n24 {
      margin-left: -6rem !important;
    }
    .ms-lg-n32 {
      margin-left: -8rem !important;
    }
    .ms-lg-n40 {
      margin-left: -10rem !important;
    }
    .ms-lg-n48 {
      margin-left: -12rem !important;
    }
    .ms-lg-n56 {
      margin-left: -14rem !important;
    }
    .ms-lg-n64 {
      margin-left: -16rem !important;
    }
    .ms-lg-n72 {
      margin-left: -18rem !important;
    }
    .ms-lg-n80 {
      margin-left: -20rem !important;
    }
    .ms-lg-n88 {
      margin-left: -22rem !important;
    }
    .ms-lg-n96 {
      margin-left: -24rem !important;
    }
    .ms-lg-npx {
      margin-left: -1px !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 0.75rem !important;
    }
    .p-lg-4 {
      padding: 1rem !important;
    }
    .p-lg-5 {
      padding: 1.25rem !important;
    }
    .p-lg-6 {
      padding: 1.5rem !important;
    }
    .p-lg-7 {
      padding: 1.75rem !important;
    }
    .p-lg-8 {
      padding: 2rem !important;
    }
    .p-lg-10 {
      padding: 2.5rem !important;
    }
    .p-lg-12 {
      padding: 3rem !important;
    }
    .p-lg-14 {
      padding: 3.5rem !important;
    }
    .p-lg-16 {
      padding: 4rem !important;
    }
    .p-lg-18 {
      padding: 4.5rem !important;
    }
    .p-lg-20 {
      padding: 5rem !important;
    }
    .p-lg-24 {
      padding: 6rem !important;
    }
    .p-lg-32 {
      padding: 8rem !important;
    }
    .p-lg-40 {
      padding: 10rem !important;
    }
    .p-lg-48 {
      padding: 12rem !important;
    }
    .p-lg-56 {
      padding: 14rem !important;
    }
    .p-lg-64 {
      padding: 16rem !important;
    }
    .p-lg-72 {
      padding: 18rem !important;
    }
    .p-lg-80 {
      padding: 20rem !important;
    }
    .p-lg-88 {
      padding: 22rem !important;
    }
    .p-lg-96 {
      padding: 24rem !important;
    }
    .p-lg-px {
      padding: 1px !important;
    }
    .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-lg-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-lg-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-lg-3 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-lg-4 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-lg-5 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-lg-6 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-lg-7 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-lg-8 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-lg-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-lg-12 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-lg-14 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-lg-16 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-lg-18 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-lg-20 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-lg-24 {
      padding-right: 6rem !important;
      padding-left: 6rem !important;
    }
    .px-lg-32 {
      padding-right: 8rem !important;
      padding-left: 8rem !important;
    }
    .px-lg-40 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-lg-48 {
      padding-right: 12rem !important;
      padding-left: 12rem !important;
    }
    .px-lg-56 {
      padding-right: 14rem !important;
      padding-left: 14rem !important;
    }
    .px-lg-64 {
      padding-right: 16rem !important;
      padding-left: 16rem !important;
    }
    .px-lg-72 {
      padding-right: 18rem !important;
      padding-left: 18rem !important;
    }
    .px-lg-80 {
      padding-right: 20rem !important;
      padding-left: 20rem !important;
    }
    .px-lg-88 {
      padding-right: 22rem !important;
      padding-left: 22rem !important;
    }
    .px-lg-96 {
      padding-right: 24rem !important;
      padding-left: 24rem !important;
    }
    .px-lg-px {
      padding-right: 1px !important;
      padding-left: 1px !important;
    }
    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-lg-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-lg-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-lg-3 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-lg-4 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-lg-5 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-lg-6 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-lg-7 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-lg-8 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-lg-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-lg-12 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-lg-14 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-lg-16 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-lg-18 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-lg-20 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-lg-24 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important;
    }
    .py-lg-32 {
      padding-top: 8rem !important;
      padding-bottom: 8rem !important;
    }
    .py-lg-40 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-lg-48 {
      padding-top: 12rem !important;
      padding-bottom: 12rem !important;
    }
    .py-lg-56 {
      padding-top: 14rem !important;
      padding-bottom: 14rem !important;
    }
    .py-lg-64 {
      padding-top: 16rem !important;
      padding-bottom: 16rem !important;
    }
    .py-lg-72 {
      padding-top: 18rem !important;
      padding-bottom: 18rem !important;
    }
    .py-lg-80 {
      padding-top: 20rem !important;
      padding-bottom: 20rem !important;
    }
    .py-lg-88 {
      padding-top: 22rem !important;
      padding-bottom: 22rem !important;
    }
    .py-lg-96 {
      padding-top: 24rem !important;
      padding-bottom: 24rem !important;
    }
    .py-lg-px {
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }
    .pt-lg-0 {
      padding-top: 0 !important;
    }
    .pt-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pt-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pt-lg-3 {
      padding-top: 0.75rem !important;
    }
    .pt-lg-4 {
      padding-top: 1rem !important;
    }
    .pt-lg-5 {
      padding-top: 1.25rem !important;
    }
    .pt-lg-6 {
      padding-top: 1.5rem !important;
    }
    .pt-lg-7 {
      padding-top: 1.75rem !important;
    }
    .pt-lg-8 {
      padding-top: 2rem !important;
    }
    .pt-lg-10 {
      padding-top: 2.5rem !important;
    }
    .pt-lg-12 {
      padding-top: 3rem !important;
    }
    .pt-lg-14 {
      padding-top: 3.5rem !important;
    }
    .pt-lg-16 {
      padding-top: 4rem !important;
    }
    .pt-lg-18 {
      padding-top: 4.5rem !important;
    }
    .pt-lg-20 {
      padding-top: 5rem !important;
    }
    .pt-lg-24 {
      padding-top: 6rem !important;
    }
    .pt-lg-32 {
      padding-top: 8rem !important;
    }
    .pt-lg-40 {
      padding-top: 10rem !important;
    }
    .pt-lg-48 {
      padding-top: 12rem !important;
    }
    .pt-lg-56 {
      padding-top: 14rem !important;
    }
    .pt-lg-64 {
      padding-top: 16rem !important;
    }
    .pt-lg-72 {
      padding-top: 18rem !important;
    }
    .pt-lg-80 {
      padding-top: 20rem !important;
    }
    .pt-lg-88 {
      padding-top: 22rem !important;
    }
    .pt-lg-96 {
      padding-top: 24rem !important;
    }
    .pt-lg-px {
      padding-top: 1px !important;
    }
    .pe-lg-0 {
      padding-right: 0 !important;
    }
    .pe-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pe-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pe-lg-3 {
      padding-right: 0.75rem !important;
    }
    .pe-lg-4 {
      padding-right: 1rem !important;
    }
    .pe-lg-5 {
      padding-right: 1.25rem !important;
    }
    .pe-lg-6 {
      padding-right: 1.5rem !important;
    }
    .pe-lg-7 {
      padding-right: 1.75rem !important;
    }
    .pe-lg-8 {
      padding-right: 2rem !important;
    }
    .pe-lg-10 {
      padding-right: 2.5rem !important;
    }
    .pe-lg-12 {
      padding-right: 3rem !important;
    }
    .pe-lg-14 {
      padding-right: 3.5rem !important;
    }
    .pe-lg-16 {
      padding-right: 4rem !important;
    }
    .pe-lg-18 {
      padding-right: 4.5rem !important;
    }
    .pe-lg-20 {
      padding-right: 5rem !important;
    }
    .pe-lg-24 {
      padding-right: 6rem !important;
    }
    .pe-lg-32 {
      padding-right: 8rem !important;
    }
    .pe-lg-40 {
      padding-right: 10rem !important;
    }
    .pe-lg-48 {
      padding-right: 12rem !important;
    }
    .pe-lg-56 {
      padding-right: 14rem !important;
    }
    .pe-lg-64 {
      padding-right: 16rem !important;
    }
    .pe-lg-72 {
      padding-right: 18rem !important;
    }
    .pe-lg-80 {
      padding-right: 20rem !important;
    }
    .pe-lg-88 {
      padding-right: 22rem !important;
    }
    .pe-lg-96 {
      padding-right: 24rem !important;
    }
    .pe-lg-px {
      padding-right: 1px !important;
    }
    .pb-lg-0 {
      padding-bottom: 0 !important;
    }
    .pb-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-lg-3 {
      padding-bottom: 0.75rem !important;
    }
    .pb-lg-4 {
      padding-bottom: 1rem !important;
    }
    .pb-lg-5 {
      padding-bottom: 1.25rem !important;
    }
    .pb-lg-6 {
      padding-bottom: 1.5rem !important;
    }
    .pb-lg-7 {
      padding-bottom: 1.75rem !important;
    }
    .pb-lg-8 {
      padding-bottom: 2rem !important;
    }
    .pb-lg-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-lg-12 {
      padding-bottom: 3rem !important;
    }
    .pb-lg-14 {
      padding-bottom: 3.5rem !important;
    }
    .pb-lg-16 {
      padding-bottom: 4rem !important;
    }
    .pb-lg-18 {
      padding-bottom: 4.5rem !important;
    }
    .pb-lg-20 {
      padding-bottom: 5rem !important;
    }
    .pb-lg-24 {
      padding-bottom: 6rem !important;
    }
    .pb-lg-32 {
      padding-bottom: 8rem !important;
    }
    .pb-lg-40 {
      padding-bottom: 10rem !important;
    }
    .pb-lg-48 {
      padding-bottom: 12rem !important;
    }
    .pb-lg-56 {
      padding-bottom: 14rem !important;
    }
    .pb-lg-64 {
      padding-bottom: 16rem !important;
    }
    .pb-lg-72 {
      padding-bottom: 18rem !important;
    }
    .pb-lg-80 {
      padding-bottom: 20rem !important;
    }
    .pb-lg-88 {
      padding-bottom: 22rem !important;
    }
    .pb-lg-96 {
      padding-bottom: 24rem !important;
    }
    .pb-lg-px {
      padding-bottom: 1px !important;
    }
    .ps-lg-0 {
      padding-left: 0 !important;
    }
    .ps-lg-1 {
      padding-left: 0.25rem !important;
    }
    .ps-lg-2 {
      padding-left: 0.5rem !important;
    }
    .ps-lg-3 {
      padding-left: 0.75rem !important;
    }
    .ps-lg-4 {
      padding-left: 1rem !important;
    }
    .ps-lg-5 {
      padding-left: 1.25rem !important;
    }
    .ps-lg-6 {
      padding-left: 1.5rem !important;
    }
    .ps-lg-7 {
      padding-left: 1.75rem !important;
    }
    .ps-lg-8 {
      padding-left: 2rem !important;
    }
    .ps-lg-10 {
      padding-left: 2.5rem !important;
    }
    .ps-lg-12 {
      padding-left: 3rem !important;
    }
    .ps-lg-14 {
      padding-left: 3.5rem !important;
    }
    .ps-lg-16 {
      padding-left: 4rem !important;
    }
    .ps-lg-18 {
      padding-left: 4.5rem !important;
    }
    .ps-lg-20 {
      padding-left: 5rem !important;
    }
    .ps-lg-24 {
      padding-left: 6rem !important;
    }
    .ps-lg-32 {
      padding-left: 8rem !important;
    }
    .ps-lg-40 {
      padding-left: 10rem !important;
    }
    .ps-lg-48 {
      padding-left: 12rem !important;
    }
    .ps-lg-56 {
      padding-left: 14rem !important;
    }
    .ps-lg-64 {
      padding-left: 16rem !important;
    }
    .ps-lg-72 {
      padding-left: 18rem !important;
    }
    .ps-lg-80 {
      padding-left: 20rem !important;
    }
    .ps-lg-88 {
      padding-left: 22rem !important;
    }
    .ps-lg-96 {
      padding-left: 24rem !important;
    }
    .ps-lg-px {
      padding-left: 1px !important;
    }
    .font-lg-italic {
      font-style: italic !important;
    }
    .font-lg-normal {
      font-style: normal !important;
    }
    .font-lg-light {
      font-weight: 300 !important;
    }
    .font-lg-lighter {
      font-weight: lighter !important;
    }
    .font-lg-regular {
      font-weight: 400 !important;
    }
    .font-lg-semibold {
      font-weight: 500 !important;
    }
    .font-lg-bold {
      font-weight: 600 !important;
    }
    .font-lg-bolder {
      font-weight: bolder !important;
    }
    .lh-lg-none {
      line-height: 1 !important;
    }
    .lh-lg-tighter {
      line-height: 1.125 !important;
    }
    .lh-lg-tight {
      line-height: 1.2 !important;
    }
    .lh-lg-snug {
      line-height: 1.375 !important;
    }
    .lh-lg-normal {
      line-height: 1.5 !important;
    }
    .lh-lg-relaxed {
      line-height: 1.625 !important;
    }
    .lh-lg-loose {
      line-height: 2 !important;
    }
    .text-lg-start {
      text-align: left !important;
    }
    .text-lg-end {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
    .text-lg-no-decoration {
      text-decoration: none !important;
    }
    .text-lg-no-decoration-hover:hover {
      text-decoration: none !important;
    }
    .text-lg-underline {
      text-decoration: underline !important;
    }
    .text-lg-underline-hover:hover {
      text-decoration: underline !important;
    }
    .text-lg-line-through {
      text-decoration: line-through !important;
    }
    .text-lg-line-through-hover:hover {
      text-decoration: line-through !important;
    }
    .text-lg-lowercase {
      text-transform: lowercase !important;
    }
    .text-lg-lowercase-hover:hover {
      text-transform: lowercase !important;
    }
    .text-lg-uppercase {
      text-transform: uppercase !important;
    }
    .text-lg-uppercase-hover:hover {
      text-transform: uppercase !important;
    }
    .text-lg-capitalize {
      text-transform: capitalize !important;
    }
    .text-lg-capitalize-hover:hover {
      text-transform: capitalize !important;
    }
    .text-lg-primary {
      --x-text-opacity: 1;
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-primary-hover:hover {
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-secondary {
      --x-text-opacity: 1;
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-secondary-hover:hover {
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-tertiary {
      --x-text-opacity: 1;
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-tertiary-hover:hover {
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-success {
      --x-text-opacity: 1;
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-success-hover:hover {
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-info {
      --x-text-opacity: 1;
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-info-hover:hover {
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-warning {
      --x-text-opacity: 1;
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-warning-hover:hover {
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-danger {
      --x-text-opacity: 1;
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-danger-hover:hover {
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-white {
      --x-text-opacity: 1;
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-white-hover:hover {
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-light {
      --x-text-opacity: 1;
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-light-hover:hover {
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-dark {
      --x-text-opacity: 1;
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-dark-hover:hover {
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-black {
      --x-text-opacity: 1;
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-black-hover:hover {
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-body {
      --x-text-opacity: 1;
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-body-hover:hover {
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-muted {
      --x-text-opacity: 1;
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-muted-hover:hover {
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-heading {
      --x-text-opacity: 1;
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-heading-hover:hover {
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-current {
      --x-text-opacity: 1;
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-current-hover:hover {
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-reset {
      --x-text-opacity: 1;
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .text-lg-reset-hover:hover {
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .bg-lg-primary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-primary-hover:hover {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-primary-focus:focus {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-primary-focus-within:focus-within {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-secondary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-secondary-hover:hover {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-secondary-focus:focus {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-secondary-focus-within:focus-within {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-tertiary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-tertiary-hover:hover {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-tertiary-focus:focus {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-tertiary-focus-within:focus-within {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-success {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-success-hover:hover {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-success-focus:focus {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-success-focus-within:focus-within {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-info {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-info-hover:hover {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-info-focus:focus {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-info-focus-within:focus-within {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-warning {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-warning-hover:hover {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-warning-focus:focus {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-warning-focus-within:focus-within {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-danger {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-danger-hover:hover {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-danger-focus:focus {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-danger-focus-within:focus-within {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-white {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-white-hover:hover {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-white-focus:focus {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-white-focus-within:focus-within {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-light {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-light-hover:hover {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-light-focus:focus {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-light-focus-within:focus-within {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-dark {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-dark-hover:hover {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-dark-focus:focus {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-dark-focus-within:focus-within {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-body {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-body-hover:hover {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-body-focus:focus {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-body-focus-within:focus-within {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-black {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-black-hover:hover {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-black-focus:focus {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-black-focus-within:focus-within {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-card {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-card-hover:hover {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-card-focus:focus {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-card-focus-within:focus-within {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-lg-transparent {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-transparent-hover:hover {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-transparent-focus:focus {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-transparent-focus-within:focus-within {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-current {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-current-hover:hover {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-current-focus:focus {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-lg-current-focus-within:focus-within {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .rounded-lg {
      border-radius: 0.375rem !important;
    }
    .rounded-lg-0 {
      border-radius: 0 !important;
    }
    .rounded-lg-1 {
      border-radius: 0.25rem !important;
    }
    .rounded-lg-2 {
      border-radius: 0.5rem !important;
    }
    .rounded-lg-3 {
      border-radius: 0.75rem !important;
    }
    .rounded-lg-4 {
      border-radius: 1rem !important;
    }
    .rounded-lg-5 {
      border-radius: 1.5rem !important;
    }
    .rounded-lg-6 {
      border-radius: 3rem !important;
    }
    .rounded-lg-7 {
      border-radius: 4rem !important;
    }
    .rounded-lg-8 {
      border-radius: 5rem !important;
    }
    .rounded-lg-9 {
      border-radius: 6rem !important;
    }
    .rounded-lg-10 {
      border-radius: 7rem !important;
    }
    .rounded-lg-pill {
      border-radius: 50rem !important;
    }
    .rounded-lg-circle {
      border-radius: 50% !important;
    }
    .rounded-lg-card {
      border-radius: 0.75rem !important;
    }
    .rounded-top-lg {
      border-top-left-radius: 0.375rem !important;
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-lg-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-top-lg-1 {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-lg-2 {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-lg-3 {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-lg-4 {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-lg-5 {
      border-top-left-radius: 1.5rem !important;
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-lg-6 {
      border-top-left-radius: 3rem !important;
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-lg-7 {
      border-top-left-radius: 4rem !important;
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-lg-8 {
      border-top-left-radius: 5rem !important;
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-lg-9 {
      border-top-left-radius: 6rem !important;
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-lg-10 {
      border-top-left-radius: 7rem !important;
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-lg-pill {
      border-top-left-radius: 50rem !important;
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-lg-circle {
      border-top-left-radius: 50% !important;
      border-top-right-radius: 50% !important;
    }
    .rounded-top-lg-card {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-end-lg {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-end-lg-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-end-lg-1 {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-end-lg-2 {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-end-lg-3 {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-end-lg-4 {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-end-lg-5 {
      border-top-right-radius: 1.5rem !important;
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-end-lg-6 {
      border-top-right-radius: 3rem !important;
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-end-lg-7 {
      border-top-right-radius: 4rem !important;
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-end-lg-8 {
      border-top-right-radius: 5rem !important;
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-end-lg-9 {
      border-top-right-radius: 6rem !important;
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-end-lg-10 {
      border-top-right-radius: 7rem !important;
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-end-lg-pill {
      border-top-right-radius: 50rem !important;
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-end-lg-circle {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .rounded-end-lg-card {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-lg {
      border-bottom-right-radius: 0.375rem !important;
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-lg-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-lg-1 {
      border-bottom-right-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-lg-2 {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-lg-3 {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-lg-4 {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-lg-5 {
      border-bottom-right-radius: 1.5rem !important;
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-lg-6 {
      border-bottom-right-radius: 3rem !important;
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-lg-7 {
      border-bottom-right-radius: 4rem !important;
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-lg-8 {
      border-bottom-right-radius: 5rem !important;
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-lg-9 {
      border-bottom-right-radius: 6rem !important;
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-lg-10 {
      border-bottom-right-radius: 7rem !important;
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-lg-pill {
      border-bottom-right-radius: 50rem !important;
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-lg-circle {
      border-bottom-right-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-lg-card {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-start-lg {
      border-bottom-left-radius: 0.375rem !important;
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-start-lg-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .rounded-start-lg-1 {
      border-bottom-left-radius: 0.25rem !important;
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-start-lg-2 {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-start-lg-3 {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-start-lg-4 {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-start-lg-5 {
      border-bottom-left-radius: 1.5rem !important;
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-start-lg-6 {
      border-bottom-left-radius: 3rem !important;
      border-top-left-radius: 3rem !important;
    }
    .rounded-start-lg-7 {
      border-bottom-left-radius: 4rem !important;
      border-top-left-radius: 4rem !important;
    }
    .rounded-start-lg-8 {
      border-bottom-left-radius: 5rem !important;
      border-top-left-radius: 5rem !important;
    }
    .rounded-start-lg-9 {
      border-bottom-left-radius: 6rem !important;
      border-top-left-radius: 6rem !important;
    }
    .rounded-start-lg-10 {
      border-bottom-left-radius: 7rem !important;
      border-top-left-radius: 7rem !important;
    }
    .rounded-start-lg-pill {
      border-bottom-left-radius: 50rem !important;
      border-top-left-radius: 50rem !important;
    }
    .rounded-start-lg-circle {
      border-bottom-left-radius: 50% !important;
      border-top-left-radius: 50% !important;
    }
    .rounded-start-lg-card {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-lg {
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-top-start-lg-0 {
      border-top-left-radius: 0 !important;
    }
    .rounded-top-start-lg-1 {
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-top-start-lg-2 {
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-top-start-lg-3 {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-lg-4 {
      border-top-left-radius: 1rem !important;
    }
    .rounded-top-start-lg-5 {
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-top-start-lg-6 {
      border-top-left-radius: 3rem !important;
    }
    .rounded-top-start-lg-7 {
      border-top-left-radius: 4rem !important;
    }
    .rounded-top-start-lg-8 {
      border-top-left-radius: 5rem !important;
    }
    .rounded-top-start-lg-9 {
      border-top-left-radius: 6rem !important;
    }
    .rounded-top-start-lg-10 {
      border-top-left-radius: 7rem !important;
    }
    .rounded-top-start-lg-pill {
      border-top-left-radius: 50rem !important;
    }
    .rounded-top-start-lg-circle {
      border-top-left-radius: 50% !important;
    }
    .rounded-top-start-lg-card {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-end-lg {
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-end-lg-0 {
      border-top-right-radius: 0 !important;
    }
    .rounded-top-end-lg-1 {
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-end-lg-2 {
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-end-lg-3 {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-end-lg-4 {
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-end-lg-5 {
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-end-lg-6 {
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-end-lg-7 {
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-end-lg-8 {
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-end-lg-9 {
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-end-lg-10 {
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-end-lg-pill {
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-end-lg-circle {
      border-top-right-radius: 50% !important;
    }
    .rounded-top-end-lg-card {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-lg {
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-bottom-end-lg-0 {
      border-bottom-right-radius: 0 !important;
    }
    .rounded-bottom-end-lg-1 {
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-bottom-end-lg-2 {
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-bottom-end-lg-3 {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-lg-4 {
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-bottom-end-lg-5 {
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-bottom-end-lg-6 {
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-bottom-end-lg-7 {
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-bottom-end-lg-8 {
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-bottom-end-lg-9 {
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-bottom-end-lg-10 {
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-bottom-end-lg-pill {
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-bottom-end-lg-circle {
      border-bottom-right-radius: 50% !important;
    }
    .rounded-bottom-end-lg-card {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-start-lg {
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-start-lg-0 {
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-start-lg-1 {
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-start-lg-2 {
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-start-lg-3 {
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-start-lg-4 {
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-start-lg-5 {
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-start-lg-6 {
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-start-lg-7 {
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-start-lg-8 {
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-start-lg-9 {
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-start-lg-10 {
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-start-lg-pill {
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-start-lg-circle {
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-start-lg-card {
      border-bottom-left-radius: 0.75rem !important;
    }
    .border-lg-solid {
      border-style: solid !important;
    }
    .border-lg-dashed {
      border-style: dashed !important;
    }
    .border-lg-dotted {
      border-style: dotted !important;
    }
    .border-lg-double {
      border-style: double !important;
    }
    .border-lg-groove {
      border-style: groove !important;
    }
    .border-lg-none {
      border-style: none !important;
    }
    .cursor-lg-auto {
      cursor: auto !important;
    }
    .cursor-lg-auto-hover:hover {
      cursor: auto !important;
    }
    .cursor-lg-pointer {
      cursor: pointer !important;
    }
    .cursor-lg-pointer-hover:hover {
      cursor: pointer !important;
    }
    .cursor-lg-wait {
      cursor: wait !important;
    }
    .cursor-lg-wait-hover:hover {
      cursor: wait !important;
    }
    .cursor-lg-text {
      cursor: text !important;
    }
    .cursor-lg-text-hover:hover {
      cursor: text !important;
    }
    .ls-lg-tighter {
      letter-spacing: -0.05em !important;
    }
    .ls-lg-tight {
      letter-spacing: -0.025em !important;
    }
    .ls-lg-normal {
      letter-spacing: 0 !important;
    }
    .ls-lg-wide {
      letter-spacing: 0.025em !important;
    }
    .ls-lg-wider {
      letter-spacing: 0.05em !important;
    }
    .ls-lg-widest {
      letter-spacing: 0.1em !important;
    }
    .bg-lg-auto {
      background-size: auto !important;
    }
    .bg-lg-cover {
      background-size: cover !important;
    }
    .bg-lg-contain {
      background-size: contain !important;
    }
    .min-w-lg-0 {
      min-width: 0 !important;
    }
    .min-w-lg-full {
      min-width: 100% !important;
    }
    .min-w-lg-min {
      min-width: -webkit-min-content !important;
      min-width: -moz-min-content !important;
      min-width: min-content !important;
    }
    .min-w-lg-max {
      min-width: -webkit-max-content !important;
      min-width: -moz-max-content !important;
      min-width: max-content !important;
    }
    .min-h-lg-0 {
      min-height: 0 !important;
    }
    .min-h-lg-full {
      min-height: 100% !important;
    }
    .min-h-lg-screen {
      min-height: 100vh !important;
    }
    .inset-lg-0 {
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
    }
    .inset-lg-1 {
      top: 0.25rem !important;
      right: 0.25rem !important;
      bottom: 0.25rem !important;
      left: 0.25rem !important;
    }
    .inset-lg-2 {
      top: 0.5rem !important;
      right: 0.5rem !important;
      bottom: 0.5rem !important;
      left: 0.5rem !important;
    }
    .inset-lg-3 {
      top: 0.75rem !important;
      right: 0.75rem !important;
      bottom: 0.75rem !important;
      left: 0.75rem !important;
    }
    .inset-lg-4 {
      top: 1rem !important;
      right: 1rem !important;
      bottom: 1rem !important;
      left: 1rem !important;
    }
    .inset-lg-5 {
      top: 1.25rem !important;
      right: 1.25rem !important;
      bottom: 1.25rem !important;
      left: 1.25rem !important;
    }
    .inset-lg-6 {
      top: 1.5rem !important;
      right: 1.5rem !important;
      bottom: 1.5rem !important;
      left: 1.5rem !important;
    }
    .inset-lg-7 {
      top: 1.75rem !important;
      right: 1.75rem !important;
      bottom: 1.75rem !important;
      left: 1.75rem !important;
    }
    .inset-lg-8 {
      top: 2rem !important;
      right: 2rem !important;
      bottom: 2rem !important;
      left: 2rem !important;
    }
    .inset-lg-10 {
      top: 2.5rem !important;
      right: 2.5rem !important;
      bottom: 2.5rem !important;
      left: 2.5rem !important;
    }
    .inset-lg-12 {
      top: 3rem !important;
      right: 3rem !important;
      bottom: 3rem !important;
      left: 3rem !important;
    }
    .inset-lg-14 {
      top: 3.5rem !important;
      right: 3.5rem !important;
      bottom: 3.5rem !important;
      left: 3.5rem !important;
    }
    .inset-lg-16 {
      top: 4rem !important;
      right: 4rem !important;
      bottom: 4rem !important;
      left: 4rem !important;
    }
    .inset-lg-18 {
      top: 4.5rem !important;
      right: 4.5rem !important;
      bottom: 4.5rem !important;
      left: 4.5rem !important;
    }
    .inset-lg-20 {
      top: 5rem !important;
      right: 5rem !important;
      bottom: 5rem !important;
      left: 5rem !important;
    }
    .inset-lg-24 {
      top: 6rem !important;
      right: 6rem !important;
      bottom: 6rem !important;
      left: 6rem !important;
    }
    .inset-lg-32 {
      top: 8rem !important;
      right: 8rem !important;
      bottom: 8rem !important;
      left: 8rem !important;
    }
    .inset-lg-40 {
      top: 10rem !important;
      right: 10rem !important;
      bottom: 10rem !important;
      left: 10rem !important;
    }
    .inset-lg-48 {
      top: 12rem !important;
      right: 12rem !important;
      bottom: 12rem !important;
      left: 12rem !important;
    }
    .inset-lg-56 {
      top: 14rem !important;
      right: 14rem !important;
      bottom: 14rem !important;
      left: 14rem !important;
    }
    .inset-lg-64 {
      top: 16rem !important;
      right: 16rem !important;
      bottom: 16rem !important;
      left: 16rem !important;
    }
    .inset-lg-72 {
      top: 18rem !important;
      right: 18rem !important;
      bottom: 18rem !important;
      left: 18rem !important;
    }
    .inset-lg-80 {
      top: 20rem !important;
      right: 20rem !important;
      bottom: 20rem !important;
      left: 20rem !important;
    }
    .inset-lg-88 {
      top: 22rem !important;
      right: 22rem !important;
      bottom: 22rem !important;
      left: 22rem !important;
    }
    .inset-lg-96 {
      top: 24rem !important;
      right: 24rem !important;
      bottom: 24rem !important;
      left: 24rem !important;
    }
    .inset-lg-px {
      top: 1px !important;
      right: 1px !important;
      bottom: 1px !important;
      left: 1px !important;
    }
    .inset-lg-1\/2 {
      top: 50% !important;
      right: 50% !important;
      bottom: 50% !important;
      left: 50% !important;
    }
    .inset-lg-full {
      top: 100% !important;
      right: 100% !important;
      bottom: 100% !important;
      left: 100% !important;
    }
    .inset-lg-auto {
      top: auto !important;
      right: auto !important;
      bottom: auto !important;
      left: auto !important;
    }
    .inset-x-lg-0 {
      left: 0 !important;
      right: 0 !important;
    }
    .inset-x-lg-1 {
      left: 0.25rem !important;
      right: 0.25rem !important;
    }
    .inset-x-lg-2 {
      left: 0.5rem !important;
      right: 0.5rem !important;
    }
    .inset-x-lg-3 {
      left: 0.75rem !important;
      right: 0.75rem !important;
    }
    .inset-x-lg-4 {
      left: 1rem !important;
      right: 1rem !important;
    }
    .inset-x-lg-5 {
      left: 1.25rem !important;
      right: 1.25rem !important;
    }
    .inset-x-lg-6 {
      left: 1.5rem !important;
      right: 1.5rem !important;
    }
    .inset-x-lg-7 {
      left: 1.75rem !important;
      right: 1.75rem !important;
    }
    .inset-x-lg-8 {
      left: 2rem !important;
      right: 2rem !important;
    }
    .inset-x-lg-10 {
      left: 2.5rem !important;
      right: 2.5rem !important;
    }
    .inset-x-lg-12 {
      left: 3rem !important;
      right: 3rem !important;
    }
    .inset-x-lg-14 {
      left: 3.5rem !important;
      right: 3.5rem !important;
    }
    .inset-x-lg-16 {
      left: 4rem !important;
      right: 4rem !important;
    }
    .inset-x-lg-18 {
      left: 4.5rem !important;
      right: 4.5rem !important;
    }
    .inset-x-lg-20 {
      left: 5rem !important;
      right: 5rem !important;
    }
    .inset-x-lg-24 {
      left: 6rem !important;
      right: 6rem !important;
    }
    .inset-x-lg-32 {
      left: 8rem !important;
      right: 8rem !important;
    }
    .inset-x-lg-40 {
      left: 10rem !important;
      right: 10rem !important;
    }
    .inset-x-lg-48 {
      left: 12rem !important;
      right: 12rem !important;
    }
    .inset-x-lg-56 {
      left: 14rem !important;
      right: 14rem !important;
    }
    .inset-x-lg-64 {
      left: 16rem !important;
      right: 16rem !important;
    }
    .inset-x-lg-72 {
      left: 18rem !important;
      right: 18rem !important;
    }
    .inset-x-lg-80 {
      left: 20rem !important;
      right: 20rem !important;
    }
    .inset-x-lg-88 {
      left: 22rem !important;
      right: 22rem !important;
    }
    .inset-x-lg-96 {
      left: 24rem !important;
      right: 24rem !important;
    }
    .inset-x-lg-px {
      left: 1px !important;
      right: 1px !important;
    }
    .inset-x-lg-1\/2 {
      left: 50% !important;
      right: 50% !important;
    }
    .inset-x-lg-full {
      left: 100% !important;
      right: 100% !important;
    }
    .inset-x-lg-auto {
      left: auto !important;
      right: auto !important;
    }
    .inset-y-lg-0 {
      top: 0 !important;
      bottom: 0 !important;
    }
    .inset-y-lg-1 {
      top: 0.25rem !important;
      bottom: 0.25rem !important;
    }
    .inset-y-lg-2 {
      top: 0.5rem !important;
      bottom: 0.5rem !important;
    }
    .inset-y-lg-3 {
      top: 0.75rem !important;
      bottom: 0.75rem !important;
    }
    .inset-y-lg-4 {
      top: 1rem !important;
      bottom: 1rem !important;
    }
    .inset-y-lg-5 {
      top: 1.25rem !important;
      bottom: 1.25rem !important;
    }
    .inset-y-lg-6 {
      top: 1.5rem !important;
      bottom: 1.5rem !important;
    }
    .inset-y-lg-7 {
      top: 1.75rem !important;
      bottom: 1.75rem !important;
    }
    .inset-y-lg-8 {
      top: 2rem !important;
      bottom: 2rem !important;
    }
    .inset-y-lg-10 {
      top: 2.5rem !important;
      bottom: 2.5rem !important;
    }
    .inset-y-lg-12 {
      top: 3rem !important;
      bottom: 3rem !important;
    }
    .inset-y-lg-14 {
      top: 3.5rem !important;
      bottom: 3.5rem !important;
    }
    .inset-y-lg-16 {
      top: 4rem !important;
      bottom: 4rem !important;
    }
    .inset-y-lg-18 {
      top: 4.5rem !important;
      bottom: 4.5rem !important;
    }
    .inset-y-lg-20 {
      top: 5rem !important;
      bottom: 5rem !important;
    }
    .inset-y-lg-24 {
      top: 6rem !important;
      bottom: 6rem !important;
    }
    .inset-y-lg-32 {
      top: 8rem !important;
      bottom: 8rem !important;
    }
    .inset-y-lg-40 {
      top: 10rem !important;
      bottom: 10rem !important;
    }
    .inset-y-lg-48 {
      top: 12rem !important;
      bottom: 12rem !important;
    }
    .inset-y-lg-56 {
      top: 14rem !important;
      bottom: 14rem !important;
    }
    .inset-y-lg-64 {
      top: 16rem !important;
      bottom: 16rem !important;
    }
    .inset-y-lg-72 {
      top: 18rem !important;
      bottom: 18rem !important;
    }
    .inset-y-lg-80 {
      top: 20rem !important;
      bottom: 20rem !important;
    }
    .inset-y-lg-88 {
      top: 22rem !important;
      bottom: 22rem !important;
    }
    .inset-y-lg-96 {
      top: 24rem !important;
      bottom: 24rem !important;
    }
    .inset-y-lg-px {
      top: 1px !important;
      bottom: 1px !important;
    }
    .inset-y-lg-1\/2 {
      top: 50% !important;
      bottom: 50% !important;
    }
    .inset-y-lg-full {
      top: 100% !important;
      bottom: 100% !important;
    }
    .inset-y-lg-auto {
      top: auto !important;
      bottom: auto !important;
    }
    .place-content-lg-start {
      place-content: flex-start !important;
    }
    .place-content-lg-end {
      place-content: flex-end !important;
    }
    .place-content-lg-center {
      place-content: center !important;
    }
    .place-content-lg-between {
      place-content: space-between !important;
    }
    .place-content-lg-around {
      place-content: space-around !important;
    }
    .place-content-lg-evenly {
      place-content: space-evenly !important;
    }
    .place-content-lg-stretch {
      place-content: stretch !important;
    }
    .overflow-x-lg-auto {
      overflow-x: auto !important;
    }
    .overflow-x-lg-hidden {
      overflow-x: hidden !important;
    }
    .overflow-x-lg-visible {
      overflow-x: visible !important;
    }
    .overflow-x-lg-scroll {
      overflow-x: scroll !important;
    }
    .overflow-y-lg-auto {
      overflow-y: auto !important;
    }
    .overflow-y-lg-hidden {
      overflow-y: hidden !important;
    }
    .overflow-y-lg-visible {
      overflow-y: visible !important;
    }
    .overflow-y-lg-scroll {
      overflow-y: scroll !important;
    }
    .transition-lg {
      transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform !important;
    }
    .transition-lg-none {
      transition-property: none !important;
    }
    .transition-lg-all {
      transition-property: all !important;
    }
    .transition-lg-colors {
      transition-property: background-color, border-color, color, fill, stroke !important;
    }
    .transition-lg-opacity {
      transition-property: opacity !important;
    }
    .transition-lg-shadow {
      transition-property: box-shadow !important;
    }
    .transition-lg-transform {
      transition-property: transform !important;
    }
    .ease-lg-base {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .ease-lg-linear {
      transition-timing-function: linear !important;
    }
    .ease-lg-in {
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
    }
    .ease-lg-out {
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
    }
    .ease-lg-in-out {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .duration-lg-base {
      transition-duration: 150ms !important;
    }
    .duration-lg-75 {
      transition-duration: 75ms !important;
    }
    .duration-lg-100 {
      transition-duration: 100ms !important;
    }
    .duration-lg-150 {
      transition-duration: 150ms !important;
    }
    .duration-lg-200 {
      transition-duration: 200ms !important;
    }
    .duration-lg-300 {
      transition-duration: 300ms !important;
    }
    .duration-lg-500 {
      transition-duration: 500ms !important;
    }
    .duration-lg-700 {
      transition-duration: 700ms !important;
    }
    .duration-lg-1000 {
      transition-duration: 1000ms !important;
    }
    .delay-lg-75 {
      transition-delay: 75ms !important;
    }
    .delay-lg-100 {
      transition-delay: 100ms !important;
    }
    .delay-lg-150 {
      transition-delay: 150ms !important;
    }
    .delay-lg-200 {
      transition-delay: 200ms !important;
    }
    .delay-lg-300 {
      transition-delay: 300ms !important;
    }
    .delay-lg-500 {
      transition-delay: 500ms !important;
    }
    .delay-lg-700 {
      transition-delay: 700ms !important;
    }
    .delay-lg-1000 {
      transition-delay: 1000ms !important;
    }
    .animation-lg-none {
      -webkit-animation: none !important;
      animation: none !important;
    }
    .animation-lg-spin {
      -webkit-animation: spin 1s linear infinite !important;
      animation: spin 1s linear infinite !important;
    }
    .animation-lg-ping {
      -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
      animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
    }
    .animation-lg-pulse {
      -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
    }
    .animation-lg-bounce {
      -webkit-animation: bounce 1s infinite !important;
      animation: bounce 1s infinite !important;
    }
    .animation-lg-slide-left {
      -webkit-animation: slideLeft 3s infinite !important;
      animation: slideLeft 3s infinite !important;
    }
    .animation-lg-slide-right {
      -webkit-animation: slideRight 3s infinite !important;
      animation: slideRight 3s infinite !important;
    }
    .animation-lg-move-slow-left {
      -webkit-animation: slideLeft 15s linear infinite !important;
      animation: slideLeft 15s linear infinite !important;
    }
    .animation-lg-move-slow-right {
      -webkit-animation: slideRight 15s linear infinite !important;
      animation: slideRight 15s linear infinite !important;
    }
    .blur-lg-5 {
      --x-blur: 5px !important;
    }
    .blur-lg-10 {
      --x-blur: 10px !important;
    }
    .blur-lg-25 {
      --x-blur: 25px !important;
    }
    .blur-lg-50 {
      --x-blur: 50px !important;
    }
    .blur-lg-75 {
      --x-blur: 75px !important;
    }
    .blur-lg-100 {
      --x-blur: 100px !important;
    }
    .blur-lg-125 {
      --x-blur: 125px !important;
    }
    .blur-lg-150 {
      --x-blur: 150px !important;
    }
    .blur-lg-200 {
      --x-blur: 200px !important;
    }
    .transform-lg-none {
      transform: none !important;
    }
    .origin-lg-center {
      transform-origin: center !important;
    }
    .origin-lg-center-hover:hover {
      transform-origin: center !important;
    }
    .origin-lg-top {
      transform-origin: top !important;
    }
    .origin-lg-top-hover:hover {
      transform-origin: top !important;
    }
    .origin-lg-top-right {
      transform-origin: top right !important;
    }
    .origin-lg-top-right-hover:hover {
      transform-origin: top right !important;
    }
    .origin-lg-right {
      transform-origin: right !important;
    }
    .origin-lg-right-hover:hover {
      transform-origin: right !important;
    }
    .origin-lg-bottom-right {
      transform-origin: bottom right !important;
    }
    .origin-lg-bottom-right-hover:hover {
      transform-origin: bottom right !important;
    }
    .origin-lg-bottom {
      transform-origin: bottom !important;
    }
    .origin-lg-bottom-hover:hover {
      transform-origin: bottom !important;
    }
    .origin-lg-bottom-left {
      transform-origin: bottom left !important;
    }
    .origin-lg-bottom-left-hover:hover {
      transform-origin: bottom left !important;
    }
    .origin-lg-left {
      transform-origin: left !important;
    }
    .origin-lg-left-hover:hover {
      transform-origin: left !important;
    }
    .origin-lg-top-left {
      transform-origin: top left !important;
    }
    .origin-lg-top-left-hover:hover {
      transform-origin: top left !important;
    }
    .scale-lg-0 {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-lg-0-hover:hover {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-lg-25 {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-lg-25-hover:hover {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-lg-50 {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-lg-50-hover:hover {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-lg-75 {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-lg-75-hover:hover {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-lg-90 {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-lg-90-hover:hover {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-lg-95 {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-lg-95-hover:hover {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-lg-100 {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-lg-100-hover:hover {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-lg-105 {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-lg-105-hover:hover {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-lg-110 {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-lg-110-hover:hover {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-lg-125 {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-lg-125-hover:hover {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-lg-150 {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-lg-150-hover:hover {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-lg-200 {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-lg-200-hover:hover {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-y-lg-0 {
      --x--scale-y: 0 !important;
    }
    .scale-y-lg-0-hover:hover {
      --x--scale-y: 0 !important;
    }
    .scale-y-lg-25 {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-lg-25-hover:hover {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-lg-50 {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-lg-50-hover:hover {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-lg-75 {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-lg-75-hover:hover {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-lg-90 {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-lg-90-hover:hover {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-lg-95 {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-lg-95-hover:hover {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-lg-100 {
      --x--scale-y: 1 !important;
    }
    .scale-y-lg-100-hover:hover {
      --x--scale-y: 1 !important;
    }
    .scale-y-lg-105 {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-lg-105-hover:hover {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-lg-110 {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-lg-110-hover:hover {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-lg-125 {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-lg-125-hover:hover {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-lg-150 {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-lg-150-hover:hover {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-lg-200 {
      --x--scale-y: 2 !important;
    }
    .scale-y-lg-200-hover:hover {
      --x--scale-y: 2 !important;
    }
    .scale-x-lg-0 {
      --x--scale-x: 0 !important;
    }
    .scale-x-lg-0-hover:hover {
      --x--scale-x: 0 !important;
    }
    .scale-x-lg-25 {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-lg-25-hover:hover {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-lg-50 {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-lg-50-hover:hover {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-lg-75 {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-lg-75-hover:hover {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-lg-90 {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-lg-90-hover:hover {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-lg-95 {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-lg-95-hover:hover {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-lg-100 {
      --x--scale-x: 1 !important;
    }
    .scale-x-lg-100-hover:hover {
      --x--scale-x: 1 !important;
    }
    .scale-x-lg-105 {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-lg-105-hover:hover {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-lg-110 {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-lg-110-hover:hover {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-lg-125 {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-lg-125-hover:hover {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-lg-150 {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-lg-150-hover:hover {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-lg-200 {
      --x--scale-x: 2 !important;
    }
    .scale-x-lg-200-hover:hover {
      --x--scale-x: 2 !important;
    }
    .rotate-lg-0 {
      --x-rotate: 0deg !important;
    }
    .rotate-lg-0-hover:hover {
      --x-rotate: 0deg !important;
    }
    .rotate-lg-1 {
      --x-rotate: 1deg !important;
    }
    .rotate-lg-1-hover:hover {
      --x-rotate: 1deg !important;
    }
    .rotate-lg-2 {
      --x-rotate: 2deg !important;
    }
    .rotate-lg-2-hover:hover {
      --x-rotate: 2deg !important;
    }
    .rotate-lg-3 {
      --x-rotate: 3deg !important;
    }
    .rotate-lg-3-hover:hover {
      --x-rotate: 3deg !important;
    }
    .rotate-lg-6 {
      --x-rotate: 6deg !important;
    }
    .rotate-lg-6-hover:hover {
      --x-rotate: 6deg !important;
    }
    .rotate-lg-12 {
      --x-rotate: 12deg !important;
    }
    .rotate-lg-12-hover:hover {
      --x-rotate: 12deg !important;
    }
    .rotate-lg-30 {
      --x-rotate: 30deg !important;
    }
    .rotate-lg-30-hover:hover {
      --x-rotate: 30deg !important;
    }
    .rotate-lg-45 {
      --x-rotate: 45deg !important;
    }
    .rotate-lg-45-hover:hover {
      --x-rotate: 45deg !important;
    }
    .rotate-lg-90 {
      --x-rotate: 90deg !important;
    }
    .rotate-lg-90-hover:hover {
      --x-rotate: 90deg !important;
    }
    .rotate-lg-180 {
      --x-rotate: 180deg !important;
    }
    .rotate-lg-180-hover:hover {
      --x-rotate: 180deg !important;
    }
    .rotate-lg-n1 {
      --x-rotate: -1deg !important;
    }
    .rotate-lg-n1-hover:hover {
      --x-rotate: -1deg !important;
    }
    .rotate-lg-n2 {
      --x-rotate: -2deg !important;
    }
    .rotate-lg-n2-hover:hover {
      --x-rotate: -2deg !important;
    }
    .rotate-lg-n3 {
      --x-rotate: -3deg !important;
    }
    .rotate-lg-n3-hover:hover {
      --x-rotate: -3deg !important;
    }
    .rotate-lg-n6 {
      --x-rotate: -6deg !important;
    }
    .rotate-lg-n6-hover:hover {
      --x-rotate: -6deg !important;
    }
    .rotate-lg-n12 {
      --x-rotate: -12deg !important;
    }
    .rotate-lg-n12-hover:hover {
      --x-rotate: -12deg !important;
    }
    .rotate-lg-n30 {
      --x-rotate: -30deg !important;
    }
    .rotate-lg-n30-hover:hover {
      --x-rotate: -30deg !important;
    }
    .rotate-lg-n45 {
      --x-rotate: -45deg !important;
    }
    .rotate-lg-n45-hover:hover {
      --x-rotate: -45deg !important;
    }
    .rotate-lg-n90 {
      --x-rotate: -90deg !important;
    }
    .rotate-lg-n90-hover:hover {
      --x-rotate: -90deg !important;
    }
    .rotate-lg-n180 {
      --x-rotate: -180deg !important;
    }
    .rotate-lg-n180-hover:hover {
      --x-rotate: -180deg !important;
    }
    .rotate-y-lg-0 {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-lg-0-hover:hover {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-lg-1 {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-lg-1-hover:hover {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-lg-2 {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-lg-2-hover:hover {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-lg-3 {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-lg-3-hover:hover {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-lg-6 {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-lg-6-hover:hover {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-lg-12 {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-lg-12-hover:hover {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-lg-30 {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-lg-30-hover:hover {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-lg-45 {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-lg-45-hover:hover {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-lg-90 {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-lg-90-hover:hover {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-lg-180 {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-lg-180-hover:hover {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-lg-n1 {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-lg-n1-hover:hover {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-lg-n2 {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-lg-n2-hover:hover {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-lg-n3 {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-lg-n3-hover:hover {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-lg-n6 {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-lg-n6-hover:hover {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-lg-n12 {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-lg-n12-hover:hover {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-lg-n30 {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-lg-n30-hover:hover {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-lg-n45 {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-lg-n45-hover:hover {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-lg-n90 {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-lg-n90-hover:hover {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-lg-n180 {
      --x-rotate-y: -180deg !important;
    }
    .rotate-y-lg-n180-hover:hover {
      --x-rotate-y: -180deg !important;
    }
    .rotate-x-lg-0 {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-lg-0-hover:hover {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-lg-1 {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-lg-1-hover:hover {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-lg-2 {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-lg-2-hover:hover {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-lg-3 {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-lg-3-hover:hover {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-lg-6 {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-lg-6-hover:hover {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-lg-12 {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-lg-12-hover:hover {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-lg-30 {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-lg-30-hover:hover {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-lg-45 {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-lg-45-hover:hover {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-lg-90 {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-lg-90-hover:hover {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-lg-180 {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-lg-180-hover:hover {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-lg-n1 {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-lg-n1-hover:hover {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-lg-n2 {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-lg-n2-hover:hover {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-lg-n3 {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-lg-n3-hover:hover {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-lg-n6 {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-lg-n6-hover:hover {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-lg-n12 {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-lg-n12-hover:hover {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-lg-n30 {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-lg-n30-hover:hover {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-lg-n45 {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-lg-n45-hover:hover {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-lg-n90 {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-lg-n90-hover:hover {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-lg-n180 {
      --x-rotate-x: -180deg !important;
    }
    .rotate-x-lg-n180-hover:hover {
      --x-rotate-x: -180deg !important;
    }
    .perspective-lg-100 {
      --x-perspective: 100px !important;
    }
    .perspective-lg-100-hover:hover {
      --x-perspective: 100px !important;
    }
    .perspective-lg-200 {
      --x-perspective: 200px !important;
    }
    .perspective-lg-200-hover:hover {
      --x-perspective: 200px !important;
    }
    .perspective-lg-300 {
      --x-perspective: 300px !important;
    }
    .perspective-lg-300-hover:hover {
      --x-perspective: 300px !important;
    }
    .perspective-lg-400 {
      --x-perspective: 400px !important;
    }
    .perspective-lg-400-hover:hover {
      --x-perspective: 400px !important;
    }
    .perspective-lg-500 {
      --x-perspective: 500px !important;
    }
    .perspective-lg-500-hover:hover {
      --x-perspective: 500px !important;
    }
    .perspective-lg-600 {
      --x-perspective: 600px !important;
    }
    .perspective-lg-600-hover:hover {
      --x-perspective: 600px !important;
    }
    .perspective-lg-700 {
      --x-perspective: 700px !important;
    }
    .perspective-lg-700-hover:hover {
      --x-perspective: 700px !important;
    }
    .perspective-lg-800 {
      --x-perspective: 800px !important;
    }
    .perspective-lg-800-hover:hover {
      --x-perspective: 800px !important;
    }
    .perspective-lg-1000 {
      --x-perspective: 1000px !important;
    }
    .perspective-lg-1000-hover:hover {
      --x-perspective: 1000px !important;
    }
    .perspective-lg-1250 {
      --x-perspective: 1250px !important;
    }
    .perspective-lg-1250-hover:hover {
      --x-perspective: 1250px !important;
    }
    .perspective-lg-1500 {
      --x-perspective: 1500px !important;
    }
    .perspective-lg-1500-hover:hover {
      --x-perspective: 1500px !important;
    }
    .translate-y-lg-0 {
      --x-translate-y: 0 !important;
    }
    .translate-y-lg-0-hover:hover {
      --x-translate-y: 0 !important;
    }
    .translate-y-lg-1 {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-lg-1-hover:hover {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-lg-2 {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-lg-2-hover:hover {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-lg-3 {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-lg-3-hover:hover {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-lg-4 {
      --x-translate-y: 1rem !important;
    }
    .translate-y-lg-4-hover:hover {
      --x-translate-y: 1rem !important;
    }
    .translate-y-lg-5 {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-lg-5-hover:hover {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-lg-6 {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-lg-6-hover:hover {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-lg-7 {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-lg-7-hover:hover {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-lg-8 {
      --x-translate-y: 2rem !important;
    }
    .translate-y-lg-8-hover:hover {
      --x-translate-y: 2rem !important;
    }
    .translate-y-lg-10 {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-lg-10-hover:hover {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-lg-12 {
      --x-translate-y: 3rem !important;
    }
    .translate-y-lg-12-hover:hover {
      --x-translate-y: 3rem !important;
    }
    .translate-y-lg-14 {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-lg-14-hover:hover {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-lg-16 {
      --x-translate-y: 4rem !important;
    }
    .translate-y-lg-16-hover:hover {
      --x-translate-y: 4rem !important;
    }
    .translate-y-lg-18 {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-lg-18-hover:hover {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-lg-20 {
      --x-translate-y: 5rem !important;
    }
    .translate-y-lg-20-hover:hover {
      --x-translate-y: 5rem !important;
    }
    .translate-y-lg-24 {
      --x-translate-y: 6rem !important;
    }
    .translate-y-lg-24-hover:hover {
      --x-translate-y: 6rem !important;
    }
    .translate-y-lg-32 {
      --x-translate-y: 8rem !important;
    }
    .translate-y-lg-32-hover:hover {
      --x-translate-y: 8rem !important;
    }
    .translate-y-lg-40 {
      --x-translate-y: 10rem !important;
    }
    .translate-y-lg-40-hover:hover {
      --x-translate-y: 10rem !important;
    }
    .translate-y-lg-48 {
      --x-translate-y: 12rem !important;
    }
    .translate-y-lg-48-hover:hover {
      --x-translate-y: 12rem !important;
    }
    .translate-y-lg-56 {
      --x-translate-y: 14rem !important;
    }
    .translate-y-lg-56-hover:hover {
      --x-translate-y: 14rem !important;
    }
    .translate-y-lg-64 {
      --x-translate-y: 16rem !important;
    }
    .translate-y-lg-64-hover:hover {
      --x-translate-y: 16rem !important;
    }
    .translate-y-lg-72 {
      --x-translate-y: 18rem !important;
    }
    .translate-y-lg-72-hover:hover {
      --x-translate-y: 18rem !important;
    }
    .translate-y-lg-80 {
      --x-translate-y: 20rem !important;
    }
    .translate-y-lg-80-hover:hover {
      --x-translate-y: 20rem !important;
    }
    .translate-y-lg-88 {
      --x-translate-y: 22rem !important;
    }
    .translate-y-lg-88-hover:hover {
      --x-translate-y: 22rem !important;
    }
    .translate-y-lg-96 {
      --x-translate-y: 24rem !important;
    }
    .translate-y-lg-96-hover:hover {
      --x-translate-y: 24rem !important;
    }
    .translate-y-lg-px {
      --x-translate-y: 1px !important;
    }
    .translate-y-lg-px-hover:hover {
      --x-translate-y: 1px !important;
    }
    .translate-y-lg-1\/2 {
      --x-translate-y: 50% !important;
    }
    .translate-y-lg-1\/2-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-lg-1\/3 {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-lg-1\/3-hover:hover {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-lg-1\/4 {
      --x-translate-y: 25% !important;
    }
    .translate-y-lg-1\/4-hover:hover {
      --x-translate-y: 25% !important;
    }
    .translate-y-lg-1\/5 {
      --x-translate-y: 20% !important;
    }
    .translate-y-lg-1\/5-hover:hover {
      --x-translate-y: 20% !important;
    }
    .translate-y-lg-2\/3 {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-lg-2\/3-hover:hover {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-lg-2\/5 {
      --x-translate-y: 50% !important;
    }
    .translate-y-lg-2\/5-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-lg-3\/4 {
      --x-translate-y: 75% !important;
    }
    .translate-y-lg-3\/4-hover:hover {
      --x-translate-y: 75% !important;
    }
    .translate-y-lg-full {
      --x-translate-y: 100% !important;
    }
    .translate-y-lg-full-hover:hover {
      --x-translate-y: 100% !important;
    }
    .translate-y-lg-n1 {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-lg-n1-hover:hover {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-lg-n2 {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-lg-n2-hover:hover {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-lg-n3 {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-lg-n3-hover:hover {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-lg-n4 {
      --x-translate-y: -1rem !important;
    }
    .translate-y-lg-n4-hover:hover {
      --x-translate-y: -1rem !important;
    }
    .translate-y-lg-n5 {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-lg-n5-hover:hover {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-lg-n6 {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-lg-n6-hover:hover {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-lg-n7 {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-lg-n7-hover:hover {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-lg-n8 {
      --x-translate-y: -2rem !important;
    }
    .translate-y-lg-n8-hover:hover {
      --x-translate-y: -2rem !important;
    }
    .translate-y-lg-n10 {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-lg-n10-hover:hover {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-lg-n12 {
      --x-translate-y: -3rem !important;
    }
    .translate-y-lg-n12-hover:hover {
      --x-translate-y: -3rem !important;
    }
    .translate-y-lg-n14 {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-lg-n14-hover:hover {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-lg-n16 {
      --x-translate-y: -4rem !important;
    }
    .translate-y-lg-n16-hover:hover {
      --x-translate-y: -4rem !important;
    }
    .translate-y-lg-n18 {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-lg-n18-hover:hover {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-lg-n20 {
      --x-translate-y: -5rem !important;
    }
    .translate-y-lg-n20-hover:hover {
      --x-translate-y: -5rem !important;
    }
    .translate-y-lg-n24 {
      --x-translate-y: -6rem !important;
    }
    .translate-y-lg-n24-hover:hover {
      --x-translate-y: -6rem !important;
    }
    .translate-y-lg-n32 {
      --x-translate-y: -8rem !important;
    }
    .translate-y-lg-n32-hover:hover {
      --x-translate-y: -8rem !important;
    }
    .translate-y-lg-n40 {
      --x-translate-y: -10rem !important;
    }
    .translate-y-lg-n40-hover:hover {
      --x-translate-y: -10rem !important;
    }
    .translate-y-lg-n48 {
      --x-translate-y: -12rem !important;
    }
    .translate-y-lg-n48-hover:hover {
      --x-translate-y: -12rem !important;
    }
    .translate-y-lg-n56 {
      --x-translate-y: -14rem !important;
    }
    .translate-y-lg-n56-hover:hover {
      --x-translate-y: -14rem !important;
    }
    .translate-y-lg-n64 {
      --x-translate-y: -16rem !important;
    }
    .translate-y-lg-n64-hover:hover {
      --x-translate-y: -16rem !important;
    }
    .translate-y-lg-n72 {
      --x-translate-y: -18rem !important;
    }
    .translate-y-lg-n72-hover:hover {
      --x-translate-y: -18rem !important;
    }
    .translate-y-lg-n80 {
      --x-translate-y: -20rem !important;
    }
    .translate-y-lg-n80-hover:hover {
      --x-translate-y: -20rem !important;
    }
    .translate-y-lg-n88 {
      --x-translate-y: -22rem !important;
    }
    .translate-y-lg-n88-hover:hover {
      --x-translate-y: -22rem !important;
    }
    .translate-y-lg-n96 {
      --x-translate-y: -24rem !important;
    }
    .translate-y-lg-n96-hover:hover {
      --x-translate-y: -24rem !important;
    }
    .translate-y-lg-npx {
      --x-translate-y: -1px !important;
    }
    .translate-y-lg-npx-hover:hover {
      --x-translate-y: -1px !important;
    }
    .translate-y-lg-n1\/2 {
      --x-translate-y: -50% !important;
    }
    .translate-y-lg-n1\/2-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-lg-n1\/3 {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-lg-n1\/3-hover:hover {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-lg-n1\/4 {
      --x-translate-y: -25% !important;
    }
    .translate-y-lg-n1\/4-hover:hover {
      --x-translate-y: -25% !important;
    }
    .translate-y-lg-n1\/5 {
      --x-translate-y: -20% !important;
    }
    .translate-y-lg-n1\/5-hover:hover {
      --x-translate-y: -20% !important;
    }
    .translate-y-lg-n2\/3 {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-lg-n2\/3-hover:hover {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-lg-n2\/5 {
      --x-translate-y: -50% !important;
    }
    .translate-y-lg-n2\/5-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-lg-n3\/4 {
      --x-translate-y: -75% !important;
    }
    .translate-y-lg-n3\/4-hover:hover {
      --x-translate-y: -75% !important;
    }
    .translate-y-lg-nfull {
      --x-translate-y: -100% !important;
    }
    .translate-y-lg-nfull-hover:hover {
      --x-translate-y: -100% !important;
    }
    .translate-x-lg-0 {
      --x-translate-x: 0 !important;
    }
    .translate-x-lg-0-hover:hover {
      --x-translate-x: 0 !important;
    }
    .translate-x-lg-1 {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-lg-1-hover:hover {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-lg-2 {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-lg-2-hover:hover {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-lg-3 {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-lg-3-hover:hover {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-lg-4 {
      --x-translate-x: 1rem !important;
    }
    .translate-x-lg-4-hover:hover {
      --x-translate-x: 1rem !important;
    }
    .translate-x-lg-5 {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-lg-5-hover:hover {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-lg-6 {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-lg-6-hover:hover {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-lg-7 {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-lg-7-hover:hover {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-lg-8 {
      --x-translate-x: 2rem !important;
    }
    .translate-x-lg-8-hover:hover {
      --x-translate-x: 2rem !important;
    }
    .translate-x-lg-10 {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-lg-10-hover:hover {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-lg-12 {
      --x-translate-x: 3rem !important;
    }
    .translate-x-lg-12-hover:hover {
      --x-translate-x: 3rem !important;
    }
    .translate-x-lg-14 {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-lg-14-hover:hover {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-lg-16 {
      --x-translate-x: 4rem !important;
    }
    .translate-x-lg-16-hover:hover {
      --x-translate-x: 4rem !important;
    }
    .translate-x-lg-18 {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-lg-18-hover:hover {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-lg-20 {
      --x-translate-x: 5rem !important;
    }
    .translate-x-lg-20-hover:hover {
      --x-translate-x: 5rem !important;
    }
    .translate-x-lg-24 {
      --x-translate-x: 6rem !important;
    }
    .translate-x-lg-24-hover:hover {
      --x-translate-x: 6rem !important;
    }
    .translate-x-lg-32 {
      --x-translate-x: 8rem !important;
    }
    .translate-x-lg-32-hover:hover {
      --x-translate-x: 8rem !important;
    }
    .translate-x-lg-40 {
      --x-translate-x: 10rem !important;
    }
    .translate-x-lg-40-hover:hover {
      --x-translate-x: 10rem !important;
    }
    .translate-x-lg-48 {
      --x-translate-x: 12rem !important;
    }
    .translate-x-lg-48-hover:hover {
      --x-translate-x: 12rem !important;
    }
    .translate-x-lg-56 {
      --x-translate-x: 14rem !important;
    }
    .translate-x-lg-56-hover:hover {
      --x-translate-x: 14rem !important;
    }
    .translate-x-lg-64 {
      --x-translate-x: 16rem !important;
    }
    .translate-x-lg-64-hover:hover {
      --x-translate-x: 16rem !important;
    }
    .translate-x-lg-72 {
      --x-translate-x: 18rem !important;
    }
    .translate-x-lg-72-hover:hover {
      --x-translate-x: 18rem !important;
    }
    .translate-x-lg-80 {
      --x-translate-x: 20rem !important;
    }
    .translate-x-lg-80-hover:hover {
      --x-translate-x: 20rem !important;
    }
    .translate-x-lg-88 {
      --x-translate-x: 22rem !important;
    }
    .translate-x-lg-88-hover:hover {
      --x-translate-x: 22rem !important;
    }
    .translate-x-lg-96 {
      --x-translate-x: 24rem !important;
    }
    .translate-x-lg-96-hover:hover {
      --x-translate-x: 24rem !important;
    }
    .translate-x-lg-px {
      --x-translate-x: 1px !important;
    }
    .translate-x-lg-px-hover:hover {
      --x-translate-x: 1px !important;
    }
    .translate-x-lg-1\/2 {
      --x-translate-x: 50% !important;
    }
    .translate-x-lg-1\/2-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-lg-1\/3 {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-lg-1\/3-hover:hover {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-lg-1\/4 {
      --x-translate-x: 25% !important;
    }
    .translate-x-lg-1\/4-hover:hover {
      --x-translate-x: 25% !important;
    }
    .translate-x-lg-1\/5 {
      --x-translate-x: 20% !important;
    }
    .translate-x-lg-1\/5-hover:hover {
      --x-translate-x: 20% !important;
    }
    .translate-x-lg-2\/3 {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-lg-2\/3-hover:hover {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-lg-2\/5 {
      --x-translate-x: 50% !important;
    }
    .translate-x-lg-2\/5-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-lg-3\/4 {
      --x-translate-x: 75% !important;
    }
    .translate-x-lg-3\/4-hover:hover {
      --x-translate-x: 75% !important;
    }
    .translate-x-lg-full {
      --x-translate-x: 100% !important;
    }
    .translate-x-lg-full-hover:hover {
      --x-translate-x: 100% !important;
    }
    .translate-x-lg-n1 {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-lg-n1-hover:hover {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-lg-n2 {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-lg-n2-hover:hover {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-lg-n3 {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-lg-n3-hover:hover {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-lg-n4 {
      --x-translate-x: -1rem !important;
    }
    .translate-x-lg-n4-hover:hover {
      --x-translate-x: -1rem !important;
    }
    .translate-x-lg-n5 {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-lg-n5-hover:hover {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-lg-n6 {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-lg-n6-hover:hover {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-lg-n7 {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-lg-n7-hover:hover {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-lg-n8 {
      --x-translate-x: -2rem !important;
    }
    .translate-x-lg-n8-hover:hover {
      --x-translate-x: -2rem !important;
    }
    .translate-x-lg-n10 {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-lg-n10-hover:hover {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-lg-n12 {
      --x-translate-x: -3rem !important;
    }
    .translate-x-lg-n12-hover:hover {
      --x-translate-x: -3rem !important;
    }
    .translate-x-lg-n14 {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-lg-n14-hover:hover {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-lg-n16 {
      --x-translate-x: -4rem !important;
    }
    .translate-x-lg-n16-hover:hover {
      --x-translate-x: -4rem !important;
    }
    .translate-x-lg-n18 {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-lg-n18-hover:hover {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-lg-n20 {
      --x-translate-x: -5rem !important;
    }
    .translate-x-lg-n20-hover:hover {
      --x-translate-x: -5rem !important;
    }
    .translate-x-lg-n24 {
      --x-translate-x: -6rem !important;
    }
    .translate-x-lg-n24-hover:hover {
      --x-translate-x: -6rem !important;
    }
    .translate-x-lg-n32 {
      --x-translate-x: -8rem !important;
    }
    .translate-x-lg-n32-hover:hover {
      --x-translate-x: -8rem !important;
    }
    .translate-x-lg-n40 {
      --x-translate-x: -10rem !important;
    }
    .translate-x-lg-n40-hover:hover {
      --x-translate-x: -10rem !important;
    }
    .translate-x-lg-n48 {
      --x-translate-x: -12rem !important;
    }
    .translate-x-lg-n48-hover:hover {
      --x-translate-x: -12rem !important;
    }
    .translate-x-lg-n56 {
      --x-translate-x: -14rem !important;
    }
    .translate-x-lg-n56-hover:hover {
      --x-translate-x: -14rem !important;
    }
    .translate-x-lg-n64 {
      --x-translate-x: -16rem !important;
    }
    .translate-x-lg-n64-hover:hover {
      --x-translate-x: -16rem !important;
    }
    .translate-x-lg-n72 {
      --x-translate-x: -18rem !important;
    }
    .translate-x-lg-n72-hover:hover {
      --x-translate-x: -18rem !important;
    }
    .translate-x-lg-n80 {
      --x-translate-x: -20rem !important;
    }
    .translate-x-lg-n80-hover:hover {
      --x-translate-x: -20rem !important;
    }
    .translate-x-lg-n88 {
      --x-translate-x: -22rem !important;
    }
    .translate-x-lg-n88-hover:hover {
      --x-translate-x: -22rem !important;
    }
    .translate-x-lg-n96 {
      --x-translate-x: -24rem !important;
    }
    .translate-x-lg-n96-hover:hover {
      --x-translate-x: -24rem !important;
    }
    .translate-x-lg-npx {
      --x-translate-x: -1px !important;
    }
    .translate-x-lg-npx-hover:hover {
      --x-translate-x: -1px !important;
    }
    .translate-x-lg-n1\/2 {
      --x-translate-x: -50% !important;
    }
    .translate-x-lg-n1\/2-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-lg-n1\/3 {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-lg-n1\/3-hover:hover {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-lg-n1\/4 {
      --x-translate-x: -25% !important;
    }
    .translate-x-lg-n1\/4-hover:hover {
      --x-translate-x: -25% !important;
    }
    .translate-x-lg-n1\/5 {
      --x-translate-x: -20% !important;
    }
    .translate-x-lg-n1\/5-hover:hover {
      --x-translate-x: -20% !important;
    }
    .translate-x-lg-n2\/3 {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-lg-n2\/3-hover:hover {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-lg-n2\/5 {
      --x-translate-x: -50% !important;
    }
    .translate-x-lg-n2\/5-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-lg-n3\/4 {
      --x-translate-x: -75% !important;
    }
    .translate-x-lg-n3\/4-hover:hover {
      --x-translate-x: -75% !important;
    }
    .translate-x-lg-nfull {
      --x-translate-x: -100% !important;
    }
    .translate-x-lg-nfull-hover:hover {
      --x-translate-x: -100% !important;
    }
    .skew-y-lg-0 {
      --x-skew-y: 0deg !important;
    }
    .skew-y-lg-0-hover:hover {
      --x-skew-y: 0deg !important;
    }
    .skew-y-lg-1 {
      --x-skew-y: 1deg !important;
    }
    .skew-y-lg-1-hover:hover {
      --x-skew-y: 1deg !important;
    }
    .skew-y-lg-2 {
      --x-skew-y: 2deg !important;
    }
    .skew-y-lg-2-hover:hover {
      --x-skew-y: 2deg !important;
    }
    .skew-y-lg-3 {
      --x-skew-y: 3deg !important;
    }
    .skew-y-lg-3-hover:hover {
      --x-skew-y: 3deg !important;
    }
    .skew-y-lg-6 {
      --x-skew-y: 6deg !important;
    }
    .skew-y-lg-6-hover:hover {
      --x-skew-y: 6deg !important;
    }
    .skew-y-lg-12 {
      --x-skew-y: 12deg !important;
    }
    .skew-y-lg-12-hover:hover {
      --x-skew-y: 12deg !important;
    }
    .skew-y-lg-n1 {
      --x-skew-y: -1deg !important;
    }
    .skew-y-lg-n1-hover:hover {
      --x-skew-y: -1deg !important;
    }
    .skew-y-lg-n2 {
      --x-skew-y: -2deg !important;
    }
    .skew-y-lg-n2-hover:hover {
      --x-skew-y: -2deg !important;
    }
    .skew-y-lg-n3 {
      --x-skew-y: -3deg !important;
    }
    .skew-y-lg-n3-hover:hover {
      --x-skew-y: -3deg !important;
    }
    .skew-y-lg-n6 {
      --x-skew-y: -6deg !important;
    }
    .skew-y-lg-n6-hover:hover {
      --x-skew-y: -6deg !important;
    }
    .skew-y-lg-n12 {
      --x-skew-y: -12deg !important;
    }
    .skew-y-lg-n12-hover:hover {
      --x-skew-y: -12deg !important;
    }
    .skew-x-lg-0 {
      --x-skew-x: 0deg !important;
    }
    .skew-x-lg-0-hover:hover {
      --x-skew-x: 0deg !important;
    }
    .skew-x-lg-1 {
      --x-skew-x: 1deg !important;
    }
    .skew-x-lg-1-hover:hover {
      --x-skew-x: 1deg !important;
    }
    .skew-x-lg-2 {
      --x-skew-x: 2deg !important;
    }
    .skew-x-lg-2-hover:hover {
      --x-skew-x: 2deg !important;
    }
    .skew-x-lg-3 {
      --x-skew-x: 3deg !important;
    }
    .skew-x-lg-3-hover:hover {
      --x-skew-x: 3deg !important;
    }
    .skew-x-lg-6 {
      --x-skew-x: 6deg !important;
    }
    .skew-x-lg-6-hover:hover {
      --x-skew-x: 6deg !important;
    }
    .skew-x-lg-12 {
      --x-skew-x: 12deg !important;
    }
    .skew-x-lg-12-hover:hover {
      --x-skew-x: 12deg !important;
    }
    .skew-x-lg-n1 {
      --x-skew-x: -1deg !important;
    }
    .skew-x-lg-n1-hover:hover {
      --x-skew-x: -1deg !important;
    }
    .skew-x-lg-n2 {
      --x-skew-x: -2deg !important;
    }
    .skew-x-lg-n2-hover:hover {
      --x-skew-x: -2deg !important;
    }
    .skew-x-lg-n3 {
      --x-skew-x: -3deg !important;
    }
    .skew-x-lg-n3-hover:hover {
      --x-skew-x: -3deg !important;
    }
    .skew-x-lg-n6 {
      --x-skew-x: -6deg !important;
    }
    .skew-x-lg-n6-hover:hover {
      --x-skew-x: -6deg !important;
    }
    .skew-x-lg-n12 {
      --x-skew-x: -12deg !important;
    }
    .skew-x-lg-n12-hover:hover {
      --x-skew-x: -12deg !important;
    }
  }
  @media (min-width: 1200px) {
    .float-xl-start {
      float: left !important;
    }
    .float-xl-end {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
    .opacity-xl-0 {
      opacity: 0 !important;
    }
    .opacity-xl-0-hover:hover {
      opacity: 0 !important;
    }
    .opacity-xl-10 {
      opacity: 0.1 !important;
    }
    .opacity-xl-10-hover:hover {
      opacity: 0.1 !important;
    }
    .opacity-xl-20 {
      opacity: 0.2 !important;
    }
    .opacity-xl-20-hover:hover {
      opacity: 0.2 !important;
    }
    .opacity-xl-30 {
      opacity: 0.3 !important;
    }
    .opacity-xl-30-hover:hover {
      opacity: 0.3 !important;
    }
    .opacity-xl-40 {
      opacity: 0.4 !important;
    }
    .opacity-xl-40-hover:hover {
      opacity: 0.4 !important;
    }
    .opacity-xl-50 {
      opacity: 0.5 !important;
    }
    .opacity-xl-50-hover:hover {
      opacity: 0.5 !important;
    }
    .opacity-xl-60 {
      opacity: 0.6 !important;
    }
    .opacity-xl-60-hover:hover {
      opacity: 0.6 !important;
    }
    .opacity-xl-70 {
      opacity: 0.7 !important;
    }
    .opacity-xl-70-hover:hover {
      opacity: 0.7 !important;
    }
    .opacity-xl-80 {
      opacity: 0.8 !important;
    }
    .opacity-xl-80-hover:hover {
      opacity: 0.8 !important;
    }
    .opacity-xl-90 {
      opacity: 0.9 !important;
    }
    .opacity-xl-90-hover:hover {
      opacity: 0.9 !important;
    }
    .opacity-xl-100 {
      opacity: 1 !important;
    }
    .opacity-xl-100-hover:hover {
      opacity: 1 !important;
    }
    .overflow-xl-auto {
      overflow: auto !important;
    }
    .overflow-xl-hidden {
      overflow: hidden !important;
    }
    .overflow-xl-visible {
      overflow: visible !important;
    }
    .overflow-xl-scroll {
      overflow: scroll !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-grid {
      display: grid !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
    .d-xl-none {
      display: none !important;
    }
    .shadow-xl {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-hover:hover {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-1 {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-1-hover:hover {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-2 {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-2-hover:hover {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-3 {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-3-hover:hover {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-4 {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-4-hover:hover {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-5 {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-5-hover:hover {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-6 {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-6-hover:hover {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xl-inset {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-xl-inset-hover:hover {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-xl-outline {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-xl-outline-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-xl-focus {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-xl-focus-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-xl-none {
      box-shadow: none !important;
    }
    .shadow-xl-none-hover:hover {
      box-shadow: none !important;
    }
    .position-xl-static {
      position: static !important;
    }
    .position-xl-relative {
      position: relative !important;
    }
    .position-xl-absolute {
      position: absolute !important;
    }
    .position-xl-fixed {
      position: fixed !important;
    }
    .position-xl-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .top-xl-0 {
      top: 0 !important;
    }
    .top-xl-1 {
      top: 0.25rem !important;
    }
    .top-xl-2 {
      top: 0.5rem !important;
    }
    .top-xl-3 {
      top: 0.75rem !important;
    }
    .top-xl-4 {
      top: 1rem !important;
    }
    .top-xl-5 {
      top: 1.25rem !important;
    }
    .top-xl-6 {
      top: 1.5rem !important;
    }
    .top-xl-7 {
      top: 1.75rem !important;
    }
    .top-xl-8 {
      top: 2rem !important;
    }
    .top-xl-10 {
      top: 2.5rem !important;
    }
    .top-xl-12 {
      top: 3rem !important;
    }
    .top-xl-14 {
      top: 3.5rem !important;
    }
    .top-xl-16 {
      top: 4rem !important;
    }
    .top-xl-18 {
      top: 4.5rem !important;
    }
    .top-xl-20 {
      top: 5rem !important;
    }
    .top-xl-24 {
      top: 6rem !important;
    }
    .top-xl-32 {
      top: 8rem !important;
    }
    .top-xl-40 {
      top: 10rem !important;
    }
    .top-xl-48 {
      top: 12rem !important;
    }
    .top-xl-56 {
      top: 14rem !important;
    }
    .top-xl-64 {
      top: 16rem !important;
    }
    .top-xl-72 {
      top: 18rem !important;
    }
    .top-xl-80 {
      top: 20rem !important;
    }
    .top-xl-88 {
      top: 22rem !important;
    }
    .top-xl-96 {
      top: 24rem !important;
    }
    .top-xl-px {
      top: 1px !important;
    }
    .top-xl-1\/2 {
      top: 50% !important;
    }
    .top-xl-full {
      top: 100% !important;
    }
    .top-xl-auto {
      top: auto !important;
    }
    .bottom-xl-0 {
      bottom: 0 !important;
    }
    .bottom-xl-1 {
      bottom: 0.25rem !important;
    }
    .bottom-xl-2 {
      bottom: 0.5rem !important;
    }
    .bottom-xl-3 {
      bottom: 0.75rem !important;
    }
    .bottom-xl-4 {
      bottom: 1rem !important;
    }
    .bottom-xl-5 {
      bottom: 1.25rem !important;
    }
    .bottom-xl-6 {
      bottom: 1.5rem !important;
    }
    .bottom-xl-7 {
      bottom: 1.75rem !important;
    }
    .bottom-xl-8 {
      bottom: 2rem !important;
    }
    .bottom-xl-10 {
      bottom: 2.5rem !important;
    }
    .bottom-xl-12 {
      bottom: 3rem !important;
    }
    .bottom-xl-14 {
      bottom: 3.5rem !important;
    }
    .bottom-xl-16 {
      bottom: 4rem !important;
    }
    .bottom-xl-18 {
      bottom: 4.5rem !important;
    }
    .bottom-xl-20 {
      bottom: 5rem !important;
    }
    .bottom-xl-24 {
      bottom: 6rem !important;
    }
    .bottom-xl-32 {
      bottom: 8rem !important;
    }
    .bottom-xl-40 {
      bottom: 10rem !important;
    }
    .bottom-xl-48 {
      bottom: 12rem !important;
    }
    .bottom-xl-56 {
      bottom: 14rem !important;
    }
    .bottom-xl-64 {
      bottom: 16rem !important;
    }
    .bottom-xl-72 {
      bottom: 18rem !important;
    }
    .bottom-xl-80 {
      bottom: 20rem !important;
    }
    .bottom-xl-88 {
      bottom: 22rem !important;
    }
    .bottom-xl-96 {
      bottom: 24rem !important;
    }
    .bottom-xl-px {
      bottom: 1px !important;
    }
    .bottom-xl-1\/2 {
      bottom: 50% !important;
    }
    .bottom-xl-full {
      bottom: 100% !important;
    }
    .bottom-xl-auto {
      bottom: auto !important;
    }
    .start-xl-0 {
      left: 0 !important;
    }
    .start-xl-1 {
      left: 0.25rem !important;
    }
    .start-xl-2 {
      left: 0.5rem !important;
    }
    .start-xl-3 {
      left: 0.75rem !important;
    }
    .start-xl-4 {
      left: 1rem !important;
    }
    .start-xl-5 {
      left: 1.25rem !important;
    }
    .start-xl-6 {
      left: 1.5rem !important;
    }
    .start-xl-7 {
      left: 1.75rem !important;
    }
    .start-xl-8 {
      left: 2rem !important;
    }
    .start-xl-10 {
      left: 2.5rem !important;
    }
    .start-xl-12 {
      left: 3rem !important;
    }
    .start-xl-14 {
      left: 3.5rem !important;
    }
    .start-xl-16 {
      left: 4rem !important;
    }
    .start-xl-18 {
      left: 4.5rem !important;
    }
    .start-xl-20 {
      left: 5rem !important;
    }
    .start-xl-24 {
      left: 6rem !important;
    }
    .start-xl-32 {
      left: 8rem !important;
    }
    .start-xl-40 {
      left: 10rem !important;
    }
    .start-xl-48 {
      left: 12rem !important;
    }
    .start-xl-56 {
      left: 14rem !important;
    }
    .start-xl-64 {
      left: 16rem !important;
    }
    .start-xl-72 {
      left: 18rem !important;
    }
    .start-xl-80 {
      left: 20rem !important;
    }
    .start-xl-88 {
      left: 22rem !important;
    }
    .start-xl-96 {
      left: 24rem !important;
    }
    .start-xl-px {
      left: 1px !important;
    }
    .start-xl-1\/2 {
      left: 50% !important;
    }
    .start-xl-full {
      left: 100% !important;
    }
    .start-xl-auto {
      left: auto !important;
    }
    .end-xl-0 {
      right: 0 !important;
    }
    .end-xl-1 {
      right: 0.25rem !important;
    }
    .end-xl-2 {
      right: 0.5rem !important;
    }
    .end-xl-3 {
      right: 0.75rem !important;
    }
    .end-xl-4 {
      right: 1rem !important;
    }
    .end-xl-5 {
      right: 1.25rem !important;
    }
    .end-xl-6 {
      right: 1.5rem !important;
    }
    .end-xl-7 {
      right: 1.75rem !important;
    }
    .end-xl-8 {
      right: 2rem !important;
    }
    .end-xl-10 {
      right: 2.5rem !important;
    }
    .end-xl-12 {
      right: 3rem !important;
    }
    .end-xl-14 {
      right: 3.5rem !important;
    }
    .end-xl-16 {
      right: 4rem !important;
    }
    .end-xl-18 {
      right: 4.5rem !important;
    }
    .end-xl-20 {
      right: 5rem !important;
    }
    .end-xl-24 {
      right: 6rem !important;
    }
    .end-xl-32 {
      right: 8rem !important;
    }
    .end-xl-40 {
      right: 10rem !important;
    }
    .end-xl-48 {
      right: 12rem !important;
    }
    .end-xl-56 {
      right: 14rem !important;
    }
    .end-xl-64 {
      right: 16rem !important;
    }
    .end-xl-72 {
      right: 18rem !important;
    }
    .end-xl-80 {
      right: 20rem !important;
    }
    .end-xl-88 {
      right: 22rem !important;
    }
    .end-xl-96 {
      right: 24rem !important;
    }
    .end-xl-px {
      right: 1px !important;
    }
    .end-xl-1\/2 {
      right: 50% !important;
    }
    .end-xl-full {
      right: 100% !important;
    }
    .end-xl-auto {
      right: auto !important;
    }
    .border-top-xl-0 {
      border-top-width: 0 !important;
    }
    .border-top-xl-0-hover:hover {
      border-top-width: 0 !important;
    }
    .border-top-xl-0-focus:focus {
      border-top-width: 0 !important;
    }
    .border-top-xl {
      border-top-width: 1px !important;
    }
    .border-top-xl-hover:hover {
      border-top-width: 1px !important;
    }
    .border-top-xl-focus:focus {
      border-top-width: 1px !important;
    }
    .border-top-xl-2 {
      border-top-width: 2px !important;
    }
    .border-top-xl-2-hover:hover {
      border-top-width: 2px !important;
    }
    .border-top-xl-2-focus:focus {
      border-top-width: 2px !important;
    }
    .border-top-xl-3 {
      border-top-width: 3px !important;
    }
    .border-top-xl-3-hover:hover {
      border-top-width: 3px !important;
    }
    .border-top-xl-3-focus:focus {
      border-top-width: 3px !important;
    }
    .border-top-xl-4 {
      border-top-width: 4px !important;
    }
    .border-top-xl-4-hover:hover {
      border-top-width: 4px !important;
    }
    .border-top-xl-4-focus:focus {
      border-top-width: 4px !important;
    }
    .border-top-xl-5 {
      border-top-width: 5px !important;
    }
    .border-top-xl-5-hover:hover {
      border-top-width: 5px !important;
    }
    .border-top-xl-5-focus:focus {
      border-top-width: 5px !important;
    }
    .border-top-xl-6 {
      border-top-width: 6px !important;
    }
    .border-top-xl-6-hover:hover {
      border-top-width: 6px !important;
    }
    .border-top-xl-6-focus:focus {
      border-top-width: 6px !important;
    }
    .border-top-xl-7 {
      border-top-width: 7px !important;
    }
    .border-top-xl-7-hover:hover {
      border-top-width: 7px !important;
    }
    .border-top-xl-7-focus:focus {
      border-top-width: 7px !important;
    }
    .border-top-xl-8 {
      border-top-width: 8px !important;
    }
    .border-top-xl-8-hover:hover {
      border-top-width: 8px !important;
    }
    .border-top-xl-8-focus:focus {
      border-top-width: 8px !important;
    }
    .border-end-xl-0 {
      border-right-width: 0 !important;
    }
    .border-end-xl-0-hover:hover {
      border-right-width: 0 !important;
    }
    .border-end-xl-0-focus:focus {
      border-right-width: 0 !important;
    }
    .border-end-xl {
      border-right-width: 1px !important;
    }
    .border-end-xl-hover:hover {
      border-right-width: 1px !important;
    }
    .border-end-xl-focus:focus {
      border-right-width: 1px !important;
    }
    .border-end-xl-2 {
      border-right-width: 2px !important;
    }
    .border-end-xl-2-hover:hover {
      border-right-width: 2px !important;
    }
    .border-end-xl-2-focus:focus {
      border-right-width: 2px !important;
    }
    .border-end-xl-3 {
      border-right-width: 3px !important;
    }
    .border-end-xl-3-hover:hover {
      border-right-width: 3px !important;
    }
    .border-end-xl-3-focus:focus {
      border-right-width: 3px !important;
    }
    .border-end-xl-4 {
      border-right-width: 4px !important;
    }
    .border-end-xl-4-hover:hover {
      border-right-width: 4px !important;
    }
    .border-end-xl-4-focus:focus {
      border-right-width: 4px !important;
    }
    .border-end-xl-5 {
      border-right-width: 5px !important;
    }
    .border-end-xl-5-hover:hover {
      border-right-width: 5px !important;
    }
    .border-end-xl-5-focus:focus {
      border-right-width: 5px !important;
    }
    .border-end-xl-6 {
      border-right-width: 6px !important;
    }
    .border-end-xl-6-hover:hover {
      border-right-width: 6px !important;
    }
    .border-end-xl-6-focus:focus {
      border-right-width: 6px !important;
    }
    .border-end-xl-7 {
      border-right-width: 7px !important;
    }
    .border-end-xl-7-hover:hover {
      border-right-width: 7px !important;
    }
    .border-end-xl-7-focus:focus {
      border-right-width: 7px !important;
    }
    .border-end-xl-8 {
      border-right-width: 8px !important;
    }
    .border-end-xl-8-hover:hover {
      border-right-width: 8px !important;
    }
    .border-end-xl-8-focus:focus {
      border-right-width: 8px !important;
    }
    .border-bottom-xl-0 {
      border-bottom-width: 0 !important;
    }
    .border-bottom-xl-0-hover:hover {
      border-bottom-width: 0 !important;
    }
    .border-bottom-xl-0-focus:focus {
      border-bottom-width: 0 !important;
    }
    .border-bottom-xl {
      border-bottom-width: 1px !important;
    }
    .border-bottom-xl-hover:hover {
      border-bottom-width: 1px !important;
    }
    .border-bottom-xl-focus:focus {
      border-bottom-width: 1px !important;
    }
    .border-bottom-xl-2 {
      border-bottom-width: 2px !important;
    }
    .border-bottom-xl-2-hover:hover {
      border-bottom-width: 2px !important;
    }
    .border-bottom-xl-2-focus:focus {
      border-bottom-width: 2px !important;
    }
    .border-bottom-xl-3 {
      border-bottom-width: 3px !important;
    }
    .border-bottom-xl-3-hover:hover {
      border-bottom-width: 3px !important;
    }
    .border-bottom-xl-3-focus:focus {
      border-bottom-width: 3px !important;
    }
    .border-bottom-xl-4 {
      border-bottom-width: 4px !important;
    }
    .border-bottom-xl-4-hover:hover {
      border-bottom-width: 4px !important;
    }
    .border-bottom-xl-4-focus:focus {
      border-bottom-width: 4px !important;
    }
    .border-bottom-xl-5 {
      border-bottom-width: 5px !important;
    }
    .border-bottom-xl-5-hover:hover {
      border-bottom-width: 5px !important;
    }
    .border-bottom-xl-5-focus:focus {
      border-bottom-width: 5px !important;
    }
    .border-bottom-xl-6 {
      border-bottom-width: 6px !important;
    }
    .border-bottom-xl-6-hover:hover {
      border-bottom-width: 6px !important;
    }
    .border-bottom-xl-6-focus:focus {
      border-bottom-width: 6px !important;
    }
    .border-bottom-xl-7 {
      border-bottom-width: 7px !important;
    }
    .border-bottom-xl-7-hover:hover {
      border-bottom-width: 7px !important;
    }
    .border-bottom-xl-7-focus:focus {
      border-bottom-width: 7px !important;
    }
    .border-bottom-xl-8 {
      border-bottom-width: 8px !important;
    }
    .border-bottom-xl-8-hover:hover {
      border-bottom-width: 8px !important;
    }
    .border-bottom-xl-8-focus:focus {
      border-bottom-width: 8px !important;
    }
    .border-start-xl-0 {
      border-left-width: 0 !important;
    }
    .border-start-xl-0-hover:hover {
      border-left-width: 0 !important;
    }
    .border-start-xl-0-focus:focus {
      border-left-width: 0 !important;
    }
    .border-start-xl {
      border-left-width: 1px !important;
    }
    .border-start-xl-hover:hover {
      border-left-width: 1px !important;
    }
    .border-start-xl-focus:focus {
      border-left-width: 1px !important;
    }
    .border-start-xl-2 {
      border-left-width: 2px !important;
    }
    .border-start-xl-2-hover:hover {
      border-left-width: 2px !important;
    }
    .border-start-xl-2-focus:focus {
      border-left-width: 2px !important;
    }
    .border-start-xl-3 {
      border-left-width: 3px !important;
    }
    .border-start-xl-3-hover:hover {
      border-left-width: 3px !important;
    }
    .border-start-xl-3-focus:focus {
      border-left-width: 3px !important;
    }
    .border-start-xl-4 {
      border-left-width: 4px !important;
    }
    .border-start-xl-4-hover:hover {
      border-left-width: 4px !important;
    }
    .border-start-xl-4-focus:focus {
      border-left-width: 4px !important;
    }
    .border-start-xl-5 {
      border-left-width: 5px !important;
    }
    .border-start-xl-5-hover:hover {
      border-left-width: 5px !important;
    }
    .border-start-xl-5-focus:focus {
      border-left-width: 5px !important;
    }
    .border-start-xl-6 {
      border-left-width: 6px !important;
    }
    .border-start-xl-6-hover:hover {
      border-left-width: 6px !important;
    }
    .border-start-xl-6-focus:focus {
      border-left-width: 6px !important;
    }
    .border-start-xl-7 {
      border-left-width: 7px !important;
    }
    .border-start-xl-7-hover:hover {
      border-left-width: 7px !important;
    }
    .border-start-xl-7-focus:focus {
      border-left-width: 7px !important;
    }
    .border-start-xl-8 {
      border-left-width: 8px !important;
    }
    .border-start-xl-8-hover:hover {
      border-left-width: 8px !important;
    }
    .border-start-xl-8-focus:focus {
      border-left-width: 8px !important;
    }
    .border-xl-primary {
      border-color: #4066D5 !important;
    }
    .border-xl-primary-hover:hover {
      border-color: #4066D5 !important;
    }
    .border-xl-primary-focus:focus {
      border-color: #4066D5 !important;
    }
    .border-xl-primary-focus-within:focus-within {
      border-color: #4066D5 !important;
    }
    .border-xl-secondary {
      border-color: #cfd6df !important;
    }
    .border-xl-secondary-hover:hover {
      border-color: #cfd6df !important;
    }
    .border-xl-secondary-focus:focus {
      border-color: #cfd6df !important;
    }
    .border-xl-secondary-focus-within:focus-within {
      border-color: #cfd6df !important;
    }
    .border-xl-tertiary {
      border-color: #ff579a !important;
    }
    .border-xl-tertiary-hover:hover {
      border-color: #ff579a !important;
    }
    .border-xl-tertiary-focus:focus {
      border-color: #ff579a !important;
    }
    .border-xl-tertiary-focus-within:focus-within {
      border-color: #ff579a !important;
    }
    .border-xl-success {
      border-color: #0c8 !important;
    }
    .border-xl-success-hover:hover {
      border-color: #0c8 !important;
    }
    .border-xl-success-focus:focus {
      border-color: #0c8 !important;
    }
    .border-xl-success-focus-within:focus-within {
      border-color: #0c8 !important;
    }
    .border-xl-info {
      border-color: #00d4ff !important;
    }
    .border-xl-info-hover:hover {
      border-color: #00d4ff !important;
    }
    .border-xl-info-focus:focus {
      border-color: #00d4ff !important;
    }
    .border-xl-info-focus-within:focus-within {
      border-color: #00d4ff !important;
    }
    .border-xl-warning {
      border-color: #ff8c00 !important;
    }
    .border-xl-warning-hover:hover {
      border-color: #ff8c00 !important;
    }
    .border-xl-warning-focus:focus {
      border-color: #ff8c00 !important;
    }
    .border-xl-warning-focus-within:focus-within {
      border-color: #ff8c00 !important;
    }
    .border-xl-danger {
      border-color: #f36 !important;
    }
    .border-xl-danger-hover:hover {
      border-color: #f36 !important;
    }
    .border-xl-danger-focus:focus {
      border-color: #f36 !important;
    }
    .border-xl-danger-focus-within:focus-within {
      border-color: #f36 !important;
    }
    .border-xl-white {
      border-color: #fff !important;
    }
    .border-xl-white-hover:hover {
      border-color: #fff !important;
    }
    .border-xl-white-focus:focus {
      border-color: #fff !important;
    }
    .border-xl-white-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-xl-light {
      border-color: #e7eaf0 !important;
    }
    .border-xl-light-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-xl-light-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-xl-light-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-xl-dark {
      border-color: #16192c !important;
    }
    .border-xl-dark-hover:hover {
      border-color: #16192c !important;
    }
    .border-xl-dark-focus:focus {
      border-color: #16192c !important;
    }
    .border-xl-dark-focus-within:focus-within {
      border-color: #16192c !important;
    }
    .border-xl-transparent {
      border-color: transparent !important;
    }
    .border-xl-transparent-hover:hover {
      border-color: transparent !important;
    }
    .border-xl-transparent-focus:focus {
      border-color: transparent !important;
    }
    .border-xl-transparent-focus-within:focus-within {
      border-color: transparent !important;
    }
    .border-xl-current {
      border-color: currentColor !important;
    }
    .border-xl-current-hover:hover {
      border-color: currentColor !important;
    }
    .border-xl-current-focus:focus {
      border-color: currentColor !important;
    }
    .border-xl-current-focus-within:focus-within {
      border-color: currentColor !important;
    }
    .border-xl-base {
      border-color: #e7eaf0 !important;
    }
    .border-xl-base-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-xl-base-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-xl-base-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-xl-body {
      border-color: #fff !important;
    }
    .border-xl-body-hover:hover {
      border-color: #fff !important;
    }
    .border-xl-body-focus:focus {
      border-color: #fff !important;
    }
    .border-xl-body-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-xl-card {
      border-color: #fff !important;
    }
    .border-xl-card-hover:hover {
      border-color: #fff !important;
    }
    .border-xl-card-focus:focus {
      border-color: #fff !important;
    }
    .border-xl-card-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-xl-black {
      border-color: #000 !important;
    }
    .border-xl-black-hover:hover {
      border-color: #000 !important;
    }
    .border-xl-black-focus:focus {
      border-color: #000 !important;
    }
    .border-xl-black-focus-within:focus-within {
      border-color: #000 !important;
    }
    .border-xl-0 {
      border-width: 0 !important;
    }
    .border-xl-0-hover:hover {
      border-width: 0 !important;
    }
    .border-xl-0-focus:focus {
      border-width: 0 !important;
    }
    .border-xl {
      border-width: 1px !important;
    }
    .border-xl-hover:hover {
      border-width: 1px !important;
    }
    .border-xl-focus:focus {
      border-width: 1px !important;
    }
    .border-xl-2 {
      border-width: 2px !important;
    }
    .border-xl-2-hover:hover {
      border-width: 2px !important;
    }
    .border-xl-2-focus:focus {
      border-width: 2px !important;
    }
    .border-xl-3 {
      border-width: 3px !important;
    }
    .border-xl-3-hover:hover {
      border-width: 3px !important;
    }
    .border-xl-3-focus:focus {
      border-width: 3px !important;
    }
    .border-xl-4 {
      border-width: 4px !important;
    }
    .border-xl-4-hover:hover {
      border-width: 4px !important;
    }
    .border-xl-4-focus:focus {
      border-width: 4px !important;
    }
    .border-xl-5 {
      border-width: 5px !important;
    }
    .border-xl-5-hover:hover {
      border-width: 5px !important;
    }
    .border-xl-5-focus:focus {
      border-width: 5px !important;
    }
    .border-xl-6 {
      border-width: 6px !important;
    }
    .border-xl-6-hover:hover {
      border-width: 6px !important;
    }
    .border-xl-6-focus:focus {
      border-width: 6px !important;
    }
    .border-xl-7 {
      border-width: 7px !important;
    }
    .border-xl-7-hover:hover {
      border-width: 7px !important;
    }
    .border-xl-7-focus:focus {
      border-width: 7px !important;
    }
    .border-xl-8 {
      border-width: 8px !important;
    }
    .border-xl-8-hover:hover {
      border-width: 8px !important;
    }
    .border-xl-8-focus:focus {
      border-width: 8px !important;
    }
    .w-xl-0 {
      width: 0 !important;
    }
    .w-xl-1 {
      width: 0.25rem !important;
    }
    .w-xl-2 {
      width: 0.5rem !important;
    }
    .w-xl-3 {
      width: 0.75rem !important;
    }
    .w-xl-4 {
      width: 1rem !important;
    }
    .w-xl-5 {
      width: 1.25rem !important;
    }
    .w-xl-6 {
      width: 1.5rem !important;
    }
    .w-xl-7 {
      width: 1.75rem !important;
    }
    .w-xl-8 {
      width: 2rem !important;
    }
    .w-xl-10 {
      width: 2.5rem !important;
    }
    .w-xl-12 {
      width: 3rem !important;
    }
    .w-xl-14 {
      width: 3.5rem !important;
    }
    .w-xl-16 {
      width: 4rem !important;
    }
    .w-xl-18 {
      width: 4.5rem !important;
    }
    .w-xl-20 {
      width: 5rem !important;
    }
    .w-xl-24 {
      width: 6rem !important;
    }
    .w-xl-32 {
      width: 8rem !important;
    }
    .w-xl-40 {
      width: 10rem !important;
    }
    .w-xl-48 {
      width: 12rem !important;
    }
    .w-xl-56 {
      width: 14rem !important;
    }
    .w-xl-64 {
      width: 16rem !important;
    }
    .w-xl-72 {
      width: 18rem !important;
    }
    .w-xl-80 {
      width: 20rem !important;
    }
    .w-xl-88 {
      width: 22rem !important;
    }
    .w-xl-96 {
      width: 24rem !important;
    }
    .w-xl-px {
      width: 1px !important;
    }
    .w-xl-1\/2 {
      width: 50% !important;
    }
    .w-xl-1\/3 {
      width: 33.3333333333% !important;
    }
    .w-xl-2\/3 {
      width: 66.6666666667% !important;
    }
    .w-xl-1\/4 {
      width: 25% !important;
    }
    .w-xl-2\/4 {
      width: 50% !important;
    }
    .w-xl-3\/4 {
      width: 75% !important;
    }
    .w-xl-1\/5 {
      width: 20% !important;
    }
    .w-xl-2\/5 {
      width: 40% !important;
    }
    .w-xl-3\/5 {
      width: 60% !important;
    }
    .w-xl-4\/5 {
      width: 80% !important;
    }
    .w-xl-1\/6 {
      width: 16.6666666667% !important;
    }
    .w-xl-2\/6 {
      width: 33.3333333333% !important;
    }
    .w-xl-3\/6 {
      width: 50% !important;
    }
    .w-xl-4\/6 {
      width: 66.6666666667% !important;
    }
    .w-xl-5\/6 {
      width: 83.3333333333% !important;
    }
    .w-xl-11\/10 {
      width: 110% !important;
    }
    .w-xl-12\/10 {
      width: 120% !important;
    }
    .w-xl-13\/10 {
      width: 130% !important;
    }
    .w-xl-14\/10 {
      width: 140% !important;
    }
    .w-xl-15\/10 {
      width: 150% !important;
    }
    .w-xl-auto {
      width: auto !important;
    }
    .w-xl-full {
      width: 100% !important;
    }
    .w-xl-screen {
      width: 100vw !important;
    }
    .w-xl-min {
      width: -webkit-min-content !important;
      width: -moz-min-content !important;
      width: min-content !important;
    }
    .w-xl-max {
      width: -webkit-max-content !important;
      width: -moz-max-content !important;
      width: max-content !important;
    }
    .max-w-xl-screen-sm {
      max-width: 640px !important;
    }
    .max-w-xl-screen-md {
      max-width: 768px !important;
    }
    .max-w-xl-screen-lg {
      max-width: 1024px !important;
    }
    .max-w-xl-screen-xl {
      max-width: 1280px !important;
    }
    .max-w-xl-screen-xxl {
      max-width: 1536px !important;
    }
    .max-w-xl-0 {
      max-width: 0 !important;
    }
    .max-w-xl-full {
      max-width: 100% !important;
    }
    .max-w-xl-read {
      max-width: 65ch !important;
    }
    .max-w-xl-min {
      max-width: -webkit-min-content !important;
      max-width: -moz-min-content !important;
      max-width: min-content !important;
    }
    .max-w-xl-max {
      max-width: -webkit-max-content !important;
      max-width: -moz-max-content !important;
      max-width: max-content !important;
    }
    .h-xl-0 {
      height: 0 !important;
    }
    .h-xl-1 {
      height: 0.25rem !important;
    }
    .h-xl-2 {
      height: 0.5rem !important;
    }
    .h-xl-3 {
      height: 0.75rem !important;
    }
    .h-xl-4 {
      height: 1rem !important;
    }
    .h-xl-5 {
      height: 1.25rem !important;
    }
    .h-xl-6 {
      height: 1.5rem !important;
    }
    .h-xl-7 {
      height: 1.75rem !important;
    }
    .h-xl-8 {
      height: 2rem !important;
    }
    .h-xl-10 {
      height: 2.5rem !important;
    }
    .h-xl-12 {
      height: 3rem !important;
    }
    .h-xl-14 {
      height: 3.5rem !important;
    }
    .h-xl-16 {
      height: 4rem !important;
    }
    .h-xl-18 {
      height: 4.5rem !important;
    }
    .h-xl-20 {
      height: 5rem !important;
    }
    .h-xl-24 {
      height: 6rem !important;
    }
    .h-xl-32 {
      height: 8rem !important;
    }
    .h-xl-40 {
      height: 10rem !important;
    }
    .h-xl-48 {
      height: 12rem !important;
    }
    .h-xl-56 {
      height: 14rem !important;
    }
    .h-xl-64 {
      height: 16rem !important;
    }
    .h-xl-72 {
      height: 18rem !important;
    }
    .h-xl-80 {
      height: 20rem !important;
    }
    .h-xl-88 {
      height: 22rem !important;
    }
    .h-xl-96 {
      height: 24rem !important;
    }
    .h-xl-px {
      height: 1px !important;
    }
    .h-xl-1\/2 {
      height: 50% !important;
    }
    .h-xl-1\/3 {
      height: 33.3333333333% !important;
    }
    .h-xl-2\/3 {
      height: 66.6666666667% !important;
    }
    .h-xl-1\/4 {
      height: 25% !important;
    }
    .h-xl-2\/4 {
      height: 50% !important;
    }
    .h-xl-3\/4 {
      height: 75% !important;
    }
    .h-xl-1\/5 {
      height: 20% !important;
    }
    .h-xl-2\/5 {
      height: 40% !important;
    }
    .h-xl-3\/5 {
      height: 60% !important;
    }
    .h-xl-4\/5 {
      height: 80% !important;
    }
    .h-xl-1\/6 {
      height: 16.6666666667% !important;
    }
    .h-xl-2\/6 {
      height: 33.3333333333% !important;
    }
    .h-xl-3\/6 {
      height: 50% !important;
    }
    .h-xl-4\/6 {
      height: 66.6666666667% !important;
    }
    .h-xl-5\/6 {
      height: 83.3333333333% !important;
    }
    .h-xl-11\/10 {
      height: 110% !important;
    }
    .h-xl-12\/10 {
      height: 120% !important;
    }
    .h-xl-13\/10 {
      height: 130% !important;
    }
    .h-xl-14\/10 {
      height: 140% !important;
    }
    .h-xl-15\/10 {
      height: 150% !important;
    }
    .h-xl-auto {
      height: auto !important;
    }
    .h-xl-full {
      height: 100% !important;
    }
    .h-xl-screen {
      height: 100vh !important;
    }
    .max-h-xl-0 {
      max-height: 0 !important;
    }
    .max-h-xl-1 {
      max-height: 0.25rem !important;
    }
    .max-h-xl-2 {
      max-height: 0.5rem !important;
    }
    .max-h-xl-3 {
      max-height: 0.75rem !important;
    }
    .max-h-xl-4 {
      max-height: 1rem !important;
    }
    .max-h-xl-5 {
      max-height: 1.25rem !important;
    }
    .max-h-xl-6 {
      max-height: 1.5rem !important;
    }
    .max-h-xl-7 {
      max-height: 1.75rem !important;
    }
    .max-h-xl-8 {
      max-height: 2rem !important;
    }
    .max-h-xl-10 {
      max-height: 2.5rem !important;
    }
    .max-h-xl-12 {
      max-height: 3rem !important;
    }
    .max-h-xl-14 {
      max-height: 3.5rem !important;
    }
    .max-h-xl-16 {
      max-height: 4rem !important;
    }
    .max-h-xl-18 {
      max-height: 4.5rem !important;
    }
    .max-h-xl-20 {
      max-height: 5rem !important;
    }
    .max-h-xl-24 {
      max-height: 6rem !important;
    }
    .max-h-xl-32 {
      max-height: 8rem !important;
    }
    .max-h-xl-40 {
      max-height: 10rem !important;
    }
    .max-h-xl-48 {
      max-height: 12rem !important;
    }
    .max-h-xl-56 {
      max-height: 14rem !important;
    }
    .max-h-xl-64 {
      max-height: 16rem !important;
    }
    .max-h-xl-72 {
      max-height: 18rem !important;
    }
    .max-h-xl-80 {
      max-height: 20rem !important;
    }
    .max-h-xl-88 {
      max-height: 22rem !important;
    }
    .max-h-xl-96 {
      max-height: 24rem !important;
    }
    .max-h-xl-px {
      max-height: 1px !important;
    }
    .max-h-xl-full {
      max-height: 100% !important;
    }
    .max-h-xl-screen {
      max-height: 100vh !important;
    }
    .flex-xl-1 {
      flex: 1 1 0% !important;
    }
    .flex-xl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xl-initial {
      flex: 0 1 auto !important;
    }
    .flex-xl-none {
      flex: none !important;
    }
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .gap-xl-0 {
      gap: 0 !important;
    }
    .gap-xl-1 {
      gap: 0.25rem !important;
    }
    .gap-xl-2 {
      gap: 0.5rem !important;
    }
    .gap-xl-3 {
      gap: 0.75rem !important;
    }
    .gap-xl-4 {
      gap: 1rem !important;
    }
    .gap-xl-5 {
      gap: 1.25rem !important;
    }
    .gap-xl-6 {
      gap: 1.5rem !important;
    }
    .gap-xl-7 {
      gap: 1.75rem !important;
    }
    .gap-xl-8 {
      gap: 2rem !important;
    }
    .gap-xl-10 {
      gap: 2.5rem !important;
    }
    .gap-xl-12 {
      gap: 3rem !important;
    }
    .gap-xl-14 {
      gap: 3.5rem !important;
    }
    .gap-xl-16 {
      gap: 4rem !important;
    }
    .gap-xl-18 {
      gap: 4.5rem !important;
    }
    .gap-xl-20 {
      gap: 5rem !important;
    }
    .gap-xl-24 {
      gap: 6rem !important;
    }
    .gap-xl-32 {
      gap: 8rem !important;
    }
    .gap-xl-40 {
      gap: 10rem !important;
    }
    .gap-xl-48 {
      gap: 12rem !important;
    }
    .gap-xl-56 {
      gap: 14rem !important;
    }
    .gap-xl-64 {
      gap: 16rem !important;
    }
    .gap-xl-72 {
      gap: 18rem !important;
    }
    .gap-xl-80 {
      gap: 20rem !important;
    }
    .gap-xl-88 {
      gap: 22rem !important;
    }
    .gap-xl-96 {
      gap: 24rem !important;
    }
    .gap-xl-px {
      gap: 1px !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .justify-content-xl-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-evenly {
      align-content: space-evenly !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      align-self: stretch !important;
    }
    .order-xl-first {
      order: -1 !important;
    }
    .order-xl-0 {
      order: 0 !important;
    }
    .order-xl-1 {
      order: 1 !important;
    }
    .order-xl-2 {
      order: 2 !important;
    }
    .order-xl-3 {
      order: 3 !important;
    }
    .order-xl-4 {
      order: 4 !important;
    }
    .order-xl-5 {
      order: 5 !important;
    }
    .order-xl-last {
      order: 6 !important;
    }
    .m-xl-0 {
      margin: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 0.75rem !important;
    }
    .m-xl-4 {
      margin: 1rem !important;
    }
    .m-xl-5 {
      margin: 1.25rem !important;
    }
    .m-xl-6 {
      margin: 1.5rem !important;
    }
    .m-xl-7 {
      margin: 1.75rem !important;
    }
    .m-xl-8 {
      margin: 2rem !important;
    }
    .m-xl-10 {
      margin: 2.5rem !important;
    }
    .m-xl-12 {
      margin: 3rem !important;
    }
    .m-xl-14 {
      margin: 3.5rem !important;
    }
    .m-xl-16 {
      margin: 4rem !important;
    }
    .m-xl-18 {
      margin: 4.5rem !important;
    }
    .m-xl-20 {
      margin: 5rem !important;
    }
    .m-xl-24 {
      margin: 6rem !important;
    }
    .m-xl-32 {
      margin: 8rem !important;
    }
    .m-xl-40 {
      margin: 10rem !important;
    }
    .m-xl-48 {
      margin: 12rem !important;
    }
    .m-xl-56 {
      margin: 14rem !important;
    }
    .m-xl-64 {
      margin: 16rem !important;
    }
    .m-xl-72 {
      margin: 18rem !important;
    }
    .m-xl-80 {
      margin: 20rem !important;
    }
    .m-xl-88 {
      margin: 22rem !important;
    }
    .m-xl-96 {
      margin: 24rem !important;
    }
    .m-xl-px {
      margin: 1px !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-xl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-xl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-xl-3 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-xl-4 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-xl-5 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-xl-6 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-xl-7 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-xl-8 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-xl-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-xl-12 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-xl-14 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-xl-16 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-xl-18 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-xl-20 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-xl-24 {
      margin-right: 6rem !important;
      margin-left: 6rem !important;
    }
    .mx-xl-32 {
      margin-right: 8rem !important;
      margin-left: 8rem !important;
    }
    .mx-xl-40 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-xl-48 {
      margin-right: 12rem !important;
      margin-left: 12rem !important;
    }
    .mx-xl-56 {
      margin-right: 14rem !important;
      margin-left: 14rem !important;
    }
    .mx-xl-64 {
      margin-right: 16rem !important;
      margin-left: 16rem !important;
    }
    .mx-xl-72 {
      margin-right: 18rem !important;
      margin-left: 18rem !important;
    }
    .mx-xl-80 {
      margin-right: 20rem !important;
      margin-left: 20rem !important;
    }
    .mx-xl-88 {
      margin-right: 22rem !important;
      margin-left: 22rem !important;
    }
    .mx-xl-96 {
      margin-right: 24rem !important;
      margin-left: 24rem !important;
    }
    .mx-xl-px {
      margin-right: 1px !important;
      margin-left: 1px !important;
    }
    .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-xl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-xl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-xl-3 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-xl-4 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-xl-5 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-xl-6 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-xl-7 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-xl-8 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-xl-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-xl-12 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-xl-14 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-xl-16 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-xl-18 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-xl-20 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-xl-24 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important;
    }
    .my-xl-32 {
      margin-top: 8rem !important;
      margin-bottom: 8rem !important;
    }
    .my-xl-40 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-xl-48 {
      margin-top: 12rem !important;
      margin-bottom: 12rem !important;
    }
    .my-xl-56 {
      margin-top: 14rem !important;
      margin-bottom: 14rem !important;
    }
    .my-xl-64 {
      margin-top: 16rem !important;
      margin-bottom: 16rem !important;
    }
    .my-xl-72 {
      margin-top: 18rem !important;
      margin-bottom: 18rem !important;
    }
    .my-xl-80 {
      margin-top: 20rem !important;
      margin-bottom: 20rem !important;
    }
    .my-xl-88 {
      margin-top: 22rem !important;
      margin-bottom: 22rem !important;
    }
    .my-xl-96 {
      margin-top: 24rem !important;
      margin-bottom: 24rem !important;
    }
    .my-xl-px {
      margin-top: 1px !important;
      margin-bottom: 1px !important;
    }
    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xl-0 {
      margin-top: 0 !important;
    }
    .mt-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mt-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mt-xl-3 {
      margin-top: 0.75rem !important;
    }
    .mt-xl-4 {
      margin-top: 1rem !important;
    }
    .mt-xl-5 {
      margin-top: 1.25rem !important;
    }
    .mt-xl-6 {
      margin-top: 1.5rem !important;
    }
    .mt-xl-7 {
      margin-top: 1.75rem !important;
    }
    .mt-xl-8 {
      margin-top: 2rem !important;
    }
    .mt-xl-10 {
      margin-top: 2.5rem !important;
    }
    .mt-xl-12 {
      margin-top: 3rem !important;
    }
    .mt-xl-14 {
      margin-top: 3.5rem !important;
    }
    .mt-xl-16 {
      margin-top: 4rem !important;
    }
    .mt-xl-18 {
      margin-top: 4.5rem !important;
    }
    .mt-xl-20 {
      margin-top: 5rem !important;
    }
    .mt-xl-24 {
      margin-top: 6rem !important;
    }
    .mt-xl-32 {
      margin-top: 8rem !important;
    }
    .mt-xl-40 {
      margin-top: 10rem !important;
    }
    .mt-xl-48 {
      margin-top: 12rem !important;
    }
    .mt-xl-56 {
      margin-top: 14rem !important;
    }
    .mt-xl-64 {
      margin-top: 16rem !important;
    }
    .mt-xl-72 {
      margin-top: 18rem !important;
    }
    .mt-xl-80 {
      margin-top: 20rem !important;
    }
    .mt-xl-88 {
      margin-top: 22rem !important;
    }
    .mt-xl-96 {
      margin-top: 24rem !important;
    }
    .mt-xl-px {
      margin-top: 1px !important;
    }
    .mt-xl-auto {
      margin-top: auto !important;
    }
    .me-xl-0 {
      margin-right: 0 !important;
    }
    .me-xl-1 {
      margin-right: 0.25rem !important;
    }
    .me-xl-2 {
      margin-right: 0.5rem !important;
    }
    .me-xl-3 {
      margin-right: 0.75rem !important;
    }
    .me-xl-4 {
      margin-right: 1rem !important;
    }
    .me-xl-5 {
      margin-right: 1.25rem !important;
    }
    .me-xl-6 {
      margin-right: 1.5rem !important;
    }
    .me-xl-7 {
      margin-right: 1.75rem !important;
    }
    .me-xl-8 {
      margin-right: 2rem !important;
    }
    .me-xl-10 {
      margin-right: 2.5rem !important;
    }
    .me-xl-12 {
      margin-right: 3rem !important;
    }
    .me-xl-14 {
      margin-right: 3.5rem !important;
    }
    .me-xl-16 {
      margin-right: 4rem !important;
    }
    .me-xl-18 {
      margin-right: 4.5rem !important;
    }
    .me-xl-20 {
      margin-right: 5rem !important;
    }
    .me-xl-24 {
      margin-right: 6rem !important;
    }
    .me-xl-32 {
      margin-right: 8rem !important;
    }
    .me-xl-40 {
      margin-right: 10rem !important;
    }
    .me-xl-48 {
      margin-right: 12rem !important;
    }
    .me-xl-56 {
      margin-right: 14rem !important;
    }
    .me-xl-64 {
      margin-right: 16rem !important;
    }
    .me-xl-72 {
      margin-right: 18rem !important;
    }
    .me-xl-80 {
      margin-right: 20rem !important;
    }
    .me-xl-88 {
      margin-right: 22rem !important;
    }
    .me-xl-96 {
      margin-right: 24rem !important;
    }
    .me-xl-px {
      margin-right: 1px !important;
    }
    .me-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-0 {
      margin-bottom: 0 !important;
    }
    .mb-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-xl-3 {
      margin-bottom: 0.75rem !important;
    }
    .mb-xl-4 {
      margin-bottom: 1rem !important;
    }
    .mb-xl-5 {
      margin-bottom: 1.25rem !important;
    }
    .mb-xl-6 {
      margin-bottom: 1.5rem !important;
    }
    .mb-xl-7 {
      margin-bottom: 1.75rem !important;
    }
    .mb-xl-8 {
      margin-bottom: 2rem !important;
    }
    .mb-xl-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-xl-12 {
      margin-bottom: 3rem !important;
    }
    .mb-xl-14 {
      margin-bottom: 3.5rem !important;
    }
    .mb-xl-16 {
      margin-bottom: 4rem !important;
    }
    .mb-xl-18 {
      margin-bottom: 4.5rem !important;
    }
    .mb-xl-20 {
      margin-bottom: 5rem !important;
    }
    .mb-xl-24 {
      margin-bottom: 6rem !important;
    }
    .mb-xl-32 {
      margin-bottom: 8rem !important;
    }
    .mb-xl-40 {
      margin-bottom: 10rem !important;
    }
    .mb-xl-48 {
      margin-bottom: 12rem !important;
    }
    .mb-xl-56 {
      margin-bottom: 14rem !important;
    }
    .mb-xl-64 {
      margin-bottom: 16rem !important;
    }
    .mb-xl-72 {
      margin-bottom: 18rem !important;
    }
    .mb-xl-80 {
      margin-bottom: 20rem !important;
    }
    .mb-xl-88 {
      margin-bottom: 22rem !important;
    }
    .mb-xl-96 {
      margin-bottom: 24rem !important;
    }
    .mb-xl-px {
      margin-bottom: 1px !important;
    }
    .mb-xl-auto {
      margin-bottom: auto !important;
    }
    .ms-xl-0 {
      margin-left: 0 !important;
    }
    .ms-xl-1 {
      margin-left: 0.25rem !important;
    }
    .ms-xl-2 {
      margin-left: 0.5rem !important;
    }
    .ms-xl-3 {
      margin-left: 0.75rem !important;
    }
    .ms-xl-4 {
      margin-left: 1rem !important;
    }
    .ms-xl-5 {
      margin-left: 1.25rem !important;
    }
    .ms-xl-6 {
      margin-left: 1.5rem !important;
    }
    .ms-xl-7 {
      margin-left: 1.75rem !important;
    }
    .ms-xl-8 {
      margin-left: 2rem !important;
    }
    .ms-xl-10 {
      margin-left: 2.5rem !important;
    }
    .ms-xl-12 {
      margin-left: 3rem !important;
    }
    .ms-xl-14 {
      margin-left: 3.5rem !important;
    }
    .ms-xl-16 {
      margin-left: 4rem !important;
    }
    .ms-xl-18 {
      margin-left: 4.5rem !important;
    }
    .ms-xl-20 {
      margin-left: 5rem !important;
    }
    .ms-xl-24 {
      margin-left: 6rem !important;
    }
    .ms-xl-32 {
      margin-left: 8rem !important;
    }
    .ms-xl-40 {
      margin-left: 10rem !important;
    }
    .ms-xl-48 {
      margin-left: 12rem !important;
    }
    .ms-xl-56 {
      margin-left: 14rem !important;
    }
    .ms-xl-64 {
      margin-left: 16rem !important;
    }
    .ms-xl-72 {
      margin-left: 18rem !important;
    }
    .ms-xl-80 {
      margin-left: 20rem !important;
    }
    .ms-xl-88 {
      margin-left: 22rem !important;
    }
    .ms-xl-96 {
      margin-left: 24rem !important;
    }
    .ms-xl-px {
      margin-left: 1px !important;
    }
    .ms-xl-auto {
      margin-left: auto !important;
    }
    .m-xl-n1 {
      margin: -0.25rem !important;
    }
    .m-xl-n2 {
      margin: -0.5rem !important;
    }
    .m-xl-n3 {
      margin: -0.75rem !important;
    }
    .m-xl-n4 {
      margin: -1rem !important;
    }
    .m-xl-n5 {
      margin: -1.25rem !important;
    }
    .m-xl-n6 {
      margin: -1.5rem !important;
    }
    .m-xl-n7 {
      margin: -1.75rem !important;
    }
    .m-xl-n8 {
      margin: -2rem !important;
    }
    .m-xl-n10 {
      margin: -2.5rem !important;
    }
    .m-xl-n12 {
      margin: -3rem !important;
    }
    .m-xl-n14 {
      margin: -3.5rem !important;
    }
    .m-xl-n16 {
      margin: -4rem !important;
    }
    .m-xl-n18 {
      margin: -4.5rem !important;
    }
    .m-xl-n20 {
      margin: -5rem !important;
    }
    .m-xl-n24 {
      margin: -6rem !important;
    }
    .m-xl-n32 {
      margin: -8rem !important;
    }
    .m-xl-n40 {
      margin: -10rem !important;
    }
    .m-xl-n48 {
      margin: -12rem !important;
    }
    .m-xl-n56 {
      margin: -14rem !important;
    }
    .m-xl-n64 {
      margin: -16rem !important;
    }
    .m-xl-n72 {
      margin: -18rem !important;
    }
    .m-xl-n80 {
      margin: -20rem !important;
    }
    .m-xl-n88 {
      margin: -22rem !important;
    }
    .m-xl-n96 {
      margin: -24rem !important;
    }
    .m-xl-npx {
      margin: -1px !important;
    }
    .mx-xl-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-xl-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-xl-n3 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-xl-n4 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-xl-n5 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-xl-n6 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-xl-n7 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-xl-n8 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-xl-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-xl-n12 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-xl-n14 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-xl-n16 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-xl-n18 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-xl-n20 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-xl-n24 {
      margin-right: -6rem !important;
      margin-left: -6rem !important;
    }
    .mx-xl-n32 {
      margin-right: -8rem !important;
      margin-left: -8rem !important;
    }
    .mx-xl-n40 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-xl-n48 {
      margin-right: -12rem !important;
      margin-left: -12rem !important;
    }
    .mx-xl-n56 {
      margin-right: -14rem !important;
      margin-left: -14rem !important;
    }
    .mx-xl-n64 {
      margin-right: -16rem !important;
      margin-left: -16rem !important;
    }
    .mx-xl-n72 {
      margin-right: -18rem !important;
      margin-left: -18rem !important;
    }
    .mx-xl-n80 {
      margin-right: -20rem !important;
      margin-left: -20rem !important;
    }
    .mx-xl-n88 {
      margin-right: -22rem !important;
      margin-left: -22rem !important;
    }
    .mx-xl-n96 {
      margin-right: -24rem !important;
      margin-left: -24rem !important;
    }
    .mx-xl-npx {
      margin-right: -1px !important;
      margin-left: -1px !important;
    }
    .my-xl-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-xl-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-xl-n3 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-xl-n4 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-xl-n5 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-xl-n6 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-xl-n7 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-xl-n8 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-xl-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-xl-n12 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-xl-n14 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-xl-n16 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-xl-n18 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-xl-n20 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-xl-n24 {
      margin-top: -6rem !important;
      margin-bottom: -6rem !important;
    }
    .my-xl-n32 {
      margin-top: -8rem !important;
      margin-bottom: -8rem !important;
    }
    .my-xl-n40 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-xl-n48 {
      margin-top: -12rem !important;
      margin-bottom: -12rem !important;
    }
    .my-xl-n56 {
      margin-top: -14rem !important;
      margin-bottom: -14rem !important;
    }
    .my-xl-n64 {
      margin-top: -16rem !important;
      margin-bottom: -16rem !important;
    }
    .my-xl-n72 {
      margin-top: -18rem !important;
      margin-bottom: -18rem !important;
    }
    .my-xl-n80 {
      margin-top: -20rem !important;
      margin-bottom: -20rem !important;
    }
    .my-xl-n88 {
      margin-top: -22rem !important;
      margin-bottom: -22rem !important;
    }
    .my-xl-n96 {
      margin-top: -24rem !important;
      margin-bottom: -24rem !important;
    }
    .my-xl-npx {
      margin-top: -1px !important;
      margin-bottom: -1px !important;
    }
    .mt-xl-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-xl-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-xl-n3 {
      margin-top: -0.75rem !important;
    }
    .mt-xl-n4 {
      margin-top: -1rem !important;
    }
    .mt-xl-n5 {
      margin-top: -1.25rem !important;
    }
    .mt-xl-n6 {
      margin-top: -1.5rem !important;
    }
    .mt-xl-n7 {
      margin-top: -1.75rem !important;
    }
    .mt-xl-n8 {
      margin-top: -2rem !important;
    }
    .mt-xl-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-xl-n12 {
      margin-top: -3rem !important;
    }
    .mt-xl-n14 {
      margin-top: -3.5rem !important;
    }
    .mt-xl-n16 {
      margin-top: -4rem !important;
    }
    .mt-xl-n18 {
      margin-top: -4.5rem !important;
    }
    .mt-xl-n20 {
      margin-top: -5rem !important;
    }
    .mt-xl-n24 {
      margin-top: -6rem !important;
    }
    .mt-xl-n32 {
      margin-top: -8rem !important;
    }
    .mt-xl-n40 {
      margin-top: -10rem !important;
    }
    .mt-xl-n48 {
      margin-top: -12rem !important;
    }
    .mt-xl-n56 {
      margin-top: -14rem !important;
    }
    .mt-xl-n64 {
      margin-top: -16rem !important;
    }
    .mt-xl-n72 {
      margin-top: -18rem !important;
    }
    .mt-xl-n80 {
      margin-top: -20rem !important;
    }
    .mt-xl-n88 {
      margin-top: -22rem !important;
    }
    .mt-xl-n96 {
      margin-top: -24rem !important;
    }
    .mt-xl-npx {
      margin-top: -1px !important;
    }
    .me-xl-n1 {
      margin-right: -0.25rem !important;
    }
    .me-xl-n2 {
      margin-right: -0.5rem !important;
    }
    .me-xl-n3 {
      margin-right: -0.75rem !important;
    }
    .me-xl-n4 {
      margin-right: -1rem !important;
    }
    .me-xl-n5 {
      margin-right: -1.25rem !important;
    }
    .me-xl-n6 {
      margin-right: -1.5rem !important;
    }
    .me-xl-n7 {
      margin-right: -1.75rem !important;
    }
    .me-xl-n8 {
      margin-right: -2rem !important;
    }
    .me-xl-n10 {
      margin-right: -2.5rem !important;
    }
    .me-xl-n12 {
      margin-right: -3rem !important;
    }
    .me-xl-n14 {
      margin-right: -3.5rem !important;
    }
    .me-xl-n16 {
      margin-right: -4rem !important;
    }
    .me-xl-n18 {
      margin-right: -4.5rem !important;
    }
    .me-xl-n20 {
      margin-right: -5rem !important;
    }
    .me-xl-n24 {
      margin-right: -6rem !important;
    }
    .me-xl-n32 {
      margin-right: -8rem !important;
    }
    .me-xl-n40 {
      margin-right: -10rem !important;
    }
    .me-xl-n48 {
      margin-right: -12rem !important;
    }
    .me-xl-n56 {
      margin-right: -14rem !important;
    }
    .me-xl-n64 {
      margin-right: -16rem !important;
    }
    .me-xl-n72 {
      margin-right: -18rem !important;
    }
    .me-xl-n80 {
      margin-right: -20rem !important;
    }
    .me-xl-n88 {
      margin-right: -22rem !important;
    }
    .me-xl-n96 {
      margin-right: -24rem !important;
    }
    .me-xl-npx {
      margin-right: -1px !important;
    }
    .mb-xl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-xl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-xl-n3 {
      margin-bottom: -0.75rem !important;
    }
    .mb-xl-n4 {
      margin-bottom: -1rem !important;
    }
    .mb-xl-n5 {
      margin-bottom: -1.25rem !important;
    }
    .mb-xl-n6 {
      margin-bottom: -1.5rem !important;
    }
    .mb-xl-n7 {
      margin-bottom: -1.75rem !important;
    }
    .mb-xl-n8 {
      margin-bottom: -2rem !important;
    }
    .mb-xl-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-xl-n12 {
      margin-bottom: -3rem !important;
    }
    .mb-xl-n14 {
      margin-bottom: -3.5rem !important;
    }
    .mb-xl-n16 {
      margin-bottom: -4rem !important;
    }
    .mb-xl-n18 {
      margin-bottom: -4.5rem !important;
    }
    .mb-xl-n20 {
      margin-bottom: -5rem !important;
    }
    .mb-xl-n24 {
      margin-bottom: -6rem !important;
    }
    .mb-xl-n32 {
      margin-bottom: -8rem !important;
    }
    .mb-xl-n40 {
      margin-bottom: -10rem !important;
    }
    .mb-xl-n48 {
      margin-bottom: -12rem !important;
    }
    .mb-xl-n56 {
      margin-bottom: -14rem !important;
    }
    .mb-xl-n64 {
      margin-bottom: -16rem !important;
    }
    .mb-xl-n72 {
      margin-bottom: -18rem !important;
    }
    .mb-xl-n80 {
      margin-bottom: -20rem !important;
    }
    .mb-xl-n88 {
      margin-bottom: -22rem !important;
    }
    .mb-xl-n96 {
      margin-bottom: -24rem !important;
    }
    .mb-xl-npx {
      margin-bottom: -1px !important;
    }
    .ms-xl-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-xl-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-xl-n3 {
      margin-left: -0.75rem !important;
    }
    .ms-xl-n4 {
      margin-left: -1rem !important;
    }
    .ms-xl-n5 {
      margin-left: -1.25rem !important;
    }
    .ms-xl-n6 {
      margin-left: -1.5rem !important;
    }
    .ms-xl-n7 {
      margin-left: -1.75rem !important;
    }
    .ms-xl-n8 {
      margin-left: -2rem !important;
    }
    .ms-xl-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-xl-n12 {
      margin-left: -3rem !important;
    }
    .ms-xl-n14 {
      margin-left: -3.5rem !important;
    }
    .ms-xl-n16 {
      margin-left: -4rem !important;
    }
    .ms-xl-n18 {
      margin-left: -4.5rem !important;
    }
    .ms-xl-n20 {
      margin-left: -5rem !important;
    }
    .ms-xl-n24 {
      margin-left: -6rem !important;
    }
    .ms-xl-n32 {
      margin-left: -8rem !important;
    }
    .ms-xl-n40 {
      margin-left: -10rem !important;
    }
    .ms-xl-n48 {
      margin-left: -12rem !important;
    }
    .ms-xl-n56 {
      margin-left: -14rem !important;
    }
    .ms-xl-n64 {
      margin-left: -16rem !important;
    }
    .ms-xl-n72 {
      margin-left: -18rem !important;
    }
    .ms-xl-n80 {
      margin-left: -20rem !important;
    }
    .ms-xl-n88 {
      margin-left: -22rem !important;
    }
    .ms-xl-n96 {
      margin-left: -24rem !important;
    }
    .ms-xl-npx {
      margin-left: -1px !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 0.75rem !important;
    }
    .p-xl-4 {
      padding: 1rem !important;
    }
    .p-xl-5 {
      padding: 1.25rem !important;
    }
    .p-xl-6 {
      padding: 1.5rem !important;
    }
    .p-xl-7 {
      padding: 1.75rem !important;
    }
    .p-xl-8 {
      padding: 2rem !important;
    }
    .p-xl-10 {
      padding: 2.5rem !important;
    }
    .p-xl-12 {
      padding: 3rem !important;
    }
    .p-xl-14 {
      padding: 3.5rem !important;
    }
    .p-xl-16 {
      padding: 4rem !important;
    }
    .p-xl-18 {
      padding: 4.5rem !important;
    }
    .p-xl-20 {
      padding: 5rem !important;
    }
    .p-xl-24 {
      padding: 6rem !important;
    }
    .p-xl-32 {
      padding: 8rem !important;
    }
    .p-xl-40 {
      padding: 10rem !important;
    }
    .p-xl-48 {
      padding: 12rem !important;
    }
    .p-xl-56 {
      padding: 14rem !important;
    }
    .p-xl-64 {
      padding: 16rem !important;
    }
    .p-xl-72 {
      padding: 18rem !important;
    }
    .p-xl-80 {
      padding: 20rem !important;
    }
    .p-xl-88 {
      padding: 22rem !important;
    }
    .p-xl-96 {
      padding: 24rem !important;
    }
    .p-xl-px {
      padding: 1px !important;
    }
    .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-xl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-xl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-xl-3 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-xl-4 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-xl-5 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-xl-6 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-xl-7 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-xl-8 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-xl-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-xl-12 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-xl-14 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-xl-16 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-xl-18 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-xl-20 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-xl-24 {
      padding-right: 6rem !important;
      padding-left: 6rem !important;
    }
    .px-xl-32 {
      padding-right: 8rem !important;
      padding-left: 8rem !important;
    }
    .px-xl-40 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-xl-48 {
      padding-right: 12rem !important;
      padding-left: 12rem !important;
    }
    .px-xl-56 {
      padding-right: 14rem !important;
      padding-left: 14rem !important;
    }
    .px-xl-64 {
      padding-right: 16rem !important;
      padding-left: 16rem !important;
    }
    .px-xl-72 {
      padding-right: 18rem !important;
      padding-left: 18rem !important;
    }
    .px-xl-80 {
      padding-right: 20rem !important;
      padding-left: 20rem !important;
    }
    .px-xl-88 {
      padding-right: 22rem !important;
      padding-left: 22rem !important;
    }
    .px-xl-96 {
      padding-right: 24rem !important;
      padding-left: 24rem !important;
    }
    .px-xl-px {
      padding-right: 1px !important;
      padding-left: 1px !important;
    }
    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-xl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-xl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-xl-3 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-xl-4 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-xl-5 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-xl-6 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-xl-7 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-xl-8 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-xl-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-xl-12 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-xl-14 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-xl-16 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-xl-18 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-xl-20 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-xl-24 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important;
    }
    .py-xl-32 {
      padding-top: 8rem !important;
      padding-bottom: 8rem !important;
    }
    .py-xl-40 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-xl-48 {
      padding-top: 12rem !important;
      padding-bottom: 12rem !important;
    }
    .py-xl-56 {
      padding-top: 14rem !important;
      padding-bottom: 14rem !important;
    }
    .py-xl-64 {
      padding-top: 16rem !important;
      padding-bottom: 16rem !important;
    }
    .py-xl-72 {
      padding-top: 18rem !important;
      padding-bottom: 18rem !important;
    }
    .py-xl-80 {
      padding-top: 20rem !important;
      padding-bottom: 20rem !important;
    }
    .py-xl-88 {
      padding-top: 22rem !important;
      padding-bottom: 22rem !important;
    }
    .py-xl-96 {
      padding-top: 24rem !important;
      padding-bottom: 24rem !important;
    }
    .py-xl-px {
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }
    .pt-xl-0 {
      padding-top: 0 !important;
    }
    .pt-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pt-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pt-xl-3 {
      padding-top: 0.75rem !important;
    }
    .pt-xl-4 {
      padding-top: 1rem !important;
    }
    .pt-xl-5 {
      padding-top: 1.25rem !important;
    }
    .pt-xl-6 {
      padding-top: 1.5rem !important;
    }
    .pt-xl-7 {
      padding-top: 1.75rem !important;
    }
    .pt-xl-8 {
      padding-top: 2rem !important;
    }
    .pt-xl-10 {
      padding-top: 2.5rem !important;
    }
    .pt-xl-12 {
      padding-top: 3rem !important;
    }
    .pt-xl-14 {
      padding-top: 3.5rem !important;
    }
    .pt-xl-16 {
      padding-top: 4rem !important;
    }
    .pt-xl-18 {
      padding-top: 4.5rem !important;
    }
    .pt-xl-20 {
      padding-top: 5rem !important;
    }
    .pt-xl-24 {
      padding-top: 6rem !important;
    }
    .pt-xl-32 {
      padding-top: 8rem !important;
    }
    .pt-xl-40 {
      padding-top: 10rem !important;
    }
    .pt-xl-48 {
      padding-top: 12rem !important;
    }
    .pt-xl-56 {
      padding-top: 14rem !important;
    }
    .pt-xl-64 {
      padding-top: 16rem !important;
    }
    .pt-xl-72 {
      padding-top: 18rem !important;
    }
    .pt-xl-80 {
      padding-top: 20rem !important;
    }
    .pt-xl-88 {
      padding-top: 22rem !important;
    }
    .pt-xl-96 {
      padding-top: 24rem !important;
    }
    .pt-xl-px {
      padding-top: 1px !important;
    }
    .pe-xl-0 {
      padding-right: 0 !important;
    }
    .pe-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pe-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pe-xl-3 {
      padding-right: 0.75rem !important;
    }
    .pe-xl-4 {
      padding-right: 1rem !important;
    }
    .pe-xl-5 {
      padding-right: 1.25rem !important;
    }
    .pe-xl-6 {
      padding-right: 1.5rem !important;
    }
    .pe-xl-7 {
      padding-right: 1.75rem !important;
    }
    .pe-xl-8 {
      padding-right: 2rem !important;
    }
    .pe-xl-10 {
      padding-right: 2.5rem !important;
    }
    .pe-xl-12 {
      padding-right: 3rem !important;
    }
    .pe-xl-14 {
      padding-right: 3.5rem !important;
    }
    .pe-xl-16 {
      padding-right: 4rem !important;
    }
    .pe-xl-18 {
      padding-right: 4.5rem !important;
    }
    .pe-xl-20 {
      padding-right: 5rem !important;
    }
    .pe-xl-24 {
      padding-right: 6rem !important;
    }
    .pe-xl-32 {
      padding-right: 8rem !important;
    }
    .pe-xl-40 {
      padding-right: 10rem !important;
    }
    .pe-xl-48 {
      padding-right: 12rem !important;
    }
    .pe-xl-56 {
      padding-right: 14rem !important;
    }
    .pe-xl-64 {
      padding-right: 16rem !important;
    }
    .pe-xl-72 {
      padding-right: 18rem !important;
    }
    .pe-xl-80 {
      padding-right: 20rem !important;
    }
    .pe-xl-88 {
      padding-right: 22rem !important;
    }
    .pe-xl-96 {
      padding-right: 24rem !important;
    }
    .pe-xl-px {
      padding-right: 1px !important;
    }
    .pb-xl-0 {
      padding-bottom: 0 !important;
    }
    .pb-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-xl-3 {
      padding-bottom: 0.75rem !important;
    }
    .pb-xl-4 {
      padding-bottom: 1rem !important;
    }
    .pb-xl-5 {
      padding-bottom: 1.25rem !important;
    }
    .pb-xl-6 {
      padding-bottom: 1.5rem !important;
    }
    .pb-xl-7 {
      padding-bottom: 1.75rem !important;
    }
    .pb-xl-8 {
      padding-bottom: 2rem !important;
    }
    .pb-xl-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-xl-12 {
      padding-bottom: 3rem !important;
    }
    .pb-xl-14 {
      padding-bottom: 3.5rem !important;
    }
    .pb-xl-16 {
      padding-bottom: 4rem !important;
    }
    .pb-xl-18 {
      padding-bottom: 4.5rem !important;
    }
    .pb-xl-20 {
      padding-bottom: 5rem !important;
    }
    .pb-xl-24 {
      padding-bottom: 6rem !important;
    }
    .pb-xl-32 {
      padding-bottom: 8rem !important;
    }
    .pb-xl-40 {
      padding-bottom: 10rem !important;
    }
    .pb-xl-48 {
      padding-bottom: 12rem !important;
    }
    .pb-xl-56 {
      padding-bottom: 14rem !important;
    }
    .pb-xl-64 {
      padding-bottom: 16rem !important;
    }
    .pb-xl-72 {
      padding-bottom: 18rem !important;
    }
    .pb-xl-80 {
      padding-bottom: 20rem !important;
    }
    .pb-xl-88 {
      padding-bottom: 22rem !important;
    }
    .pb-xl-96 {
      padding-bottom: 24rem !important;
    }
    .pb-xl-px {
      padding-bottom: 1px !important;
    }
    .ps-xl-0 {
      padding-left: 0 !important;
    }
    .ps-xl-1 {
      padding-left: 0.25rem !important;
    }
    .ps-xl-2 {
      padding-left: 0.5rem !important;
    }
    .ps-xl-3 {
      padding-left: 0.75rem !important;
    }
    .ps-xl-4 {
      padding-left: 1rem !important;
    }
    .ps-xl-5 {
      padding-left: 1.25rem !important;
    }
    .ps-xl-6 {
      padding-left: 1.5rem !important;
    }
    .ps-xl-7 {
      padding-left: 1.75rem !important;
    }
    .ps-xl-8 {
      padding-left: 2rem !important;
    }
    .ps-xl-10 {
      padding-left: 2.5rem !important;
    }
    .ps-xl-12 {
      padding-left: 3rem !important;
    }
    .ps-xl-14 {
      padding-left: 3.5rem !important;
    }
    .ps-xl-16 {
      padding-left: 4rem !important;
    }
    .ps-xl-18 {
      padding-left: 4.5rem !important;
    }
    .ps-xl-20 {
      padding-left: 5rem !important;
    }
    .ps-xl-24 {
      padding-left: 6rem !important;
    }
    .ps-xl-32 {
      padding-left: 8rem !important;
    }
    .ps-xl-40 {
      padding-left: 10rem !important;
    }
    .ps-xl-48 {
      padding-left: 12rem !important;
    }
    .ps-xl-56 {
      padding-left: 14rem !important;
    }
    .ps-xl-64 {
      padding-left: 16rem !important;
    }
    .ps-xl-72 {
      padding-left: 18rem !important;
    }
    .ps-xl-80 {
      padding-left: 20rem !important;
    }
    .ps-xl-88 {
      padding-left: 22rem !important;
    }
    .ps-xl-96 {
      padding-left: 24rem !important;
    }
    .ps-xl-px {
      padding-left: 1px !important;
    }
    .font-xl-italic {
      font-style: italic !important;
    }
    .font-xl-normal {
      font-style: normal !important;
    }
    .font-xl-light {
      font-weight: 300 !important;
    }
    .font-xl-lighter {
      font-weight: lighter !important;
    }
    .font-xl-regular {
      font-weight: 400 !important;
    }
    .font-xl-semibold {
      font-weight: 500 !important;
    }
    .font-xl-bold {
      font-weight: 600 !important;
    }
    .font-xl-bolder {
      font-weight: bolder !important;
    }
    .lh-xl-none {
      line-height: 1 !important;
    }
    .lh-xl-tighter {
      line-height: 1.125 !important;
    }
    .lh-xl-tight {
      line-height: 1.2 !important;
    }
    .lh-xl-snug {
      line-height: 1.375 !important;
    }
    .lh-xl-normal {
      line-height: 1.5 !important;
    }
    .lh-xl-relaxed {
      line-height: 1.625 !important;
    }
    .lh-xl-loose {
      line-height: 2 !important;
    }
    .text-xl-start {
      text-align: left !important;
    }
    .text-xl-end {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
    .text-xl-no-decoration {
      text-decoration: none !important;
    }
    .text-xl-no-decoration-hover:hover {
      text-decoration: none !important;
    }
    .text-xl-underline {
      text-decoration: underline !important;
    }
    .text-xl-underline-hover:hover {
      text-decoration: underline !important;
    }
    .text-xl-line-through {
      text-decoration: line-through !important;
    }
    .text-xl-line-through-hover:hover {
      text-decoration: line-through !important;
    }
    .text-xl-lowercase {
      text-transform: lowercase !important;
    }
    .text-xl-lowercase-hover:hover {
      text-transform: lowercase !important;
    }
    .text-xl-uppercase {
      text-transform: uppercase !important;
    }
    .text-xl-uppercase-hover:hover {
      text-transform: uppercase !important;
    }
    .text-xl-capitalize {
      text-transform: capitalize !important;
    }
    .text-xl-capitalize-hover:hover {
      text-transform: capitalize !important;
    }
    .text-xl-primary {
      --x-text-opacity: 1;
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-primary-hover:hover {
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-secondary {
      --x-text-opacity: 1;
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-secondary-hover:hover {
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-tertiary {
      --x-text-opacity: 1;
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-tertiary-hover:hover {
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-success {
      --x-text-opacity: 1;
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-success-hover:hover {
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-info {
      --x-text-opacity: 1;
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-info-hover:hover {
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-warning {
      --x-text-opacity: 1;
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-warning-hover:hover {
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-danger {
      --x-text-opacity: 1;
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-danger-hover:hover {
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-white {
      --x-text-opacity: 1;
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-white-hover:hover {
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-light {
      --x-text-opacity: 1;
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-light-hover:hover {
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-dark {
      --x-text-opacity: 1;
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-dark-hover:hover {
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-black {
      --x-text-opacity: 1;
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-black-hover:hover {
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-body {
      --x-text-opacity: 1;
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-body-hover:hover {
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-muted {
      --x-text-opacity: 1;
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-muted-hover:hover {
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-heading {
      --x-text-opacity: 1;
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-heading-hover:hover {
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-current {
      --x-text-opacity: 1;
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-current-hover:hover {
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-reset {
      --x-text-opacity: 1;
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .text-xl-reset-hover:hover {
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .bg-xl-primary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-primary-hover:hover {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-primary-focus:focus {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-primary-focus-within:focus-within {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-secondary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-secondary-hover:hover {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-secondary-focus:focus {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-secondary-focus-within:focus-within {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-tertiary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-tertiary-hover:hover {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-tertiary-focus:focus {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-tertiary-focus-within:focus-within {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-success {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-success-hover:hover {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-success-focus:focus {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-success-focus-within:focus-within {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-info {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-info-hover:hover {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-info-focus:focus {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-info-focus-within:focus-within {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-warning {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-warning-hover:hover {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-warning-focus:focus {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-warning-focus-within:focus-within {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-danger {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-danger-hover:hover {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-danger-focus:focus {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-danger-focus-within:focus-within {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-white {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-white-hover:hover {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-white-focus:focus {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-white-focus-within:focus-within {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-light {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-light-hover:hover {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-light-focus:focus {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-light-focus-within:focus-within {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-dark {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-dark-hover:hover {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-dark-focus:focus {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-dark-focus-within:focus-within {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-body {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-body-hover:hover {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-body-focus:focus {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-body-focus-within:focus-within {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-black {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-black-hover:hover {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-black-focus:focus {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-black-focus-within:focus-within {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-card {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-card-hover:hover {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-card-focus:focus {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-card-focus-within:focus-within {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xl-transparent {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-transparent-hover:hover {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-transparent-focus:focus {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-transparent-focus-within:focus-within {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-current {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-current-hover:hover {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-current-focus:focus {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xl-current-focus-within:focus-within {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .rounded-xl {
      border-radius: 0.375rem !important;
    }
    .rounded-xl-0 {
      border-radius: 0 !important;
    }
    .rounded-xl-1 {
      border-radius: 0.25rem !important;
    }
    .rounded-xl-2 {
      border-radius: 0.5rem !important;
    }
    .rounded-xl-3 {
      border-radius: 0.75rem !important;
    }
    .rounded-xl-4 {
      border-radius: 1rem !important;
    }
    .rounded-xl-5 {
      border-radius: 1.5rem !important;
    }
    .rounded-xl-6 {
      border-radius: 3rem !important;
    }
    .rounded-xl-7 {
      border-radius: 4rem !important;
    }
    .rounded-xl-8 {
      border-radius: 5rem !important;
    }
    .rounded-xl-9 {
      border-radius: 6rem !important;
    }
    .rounded-xl-10 {
      border-radius: 7rem !important;
    }
    .rounded-xl-pill {
      border-radius: 50rem !important;
    }
    .rounded-xl-circle {
      border-radius: 50% !important;
    }
    .rounded-xl-card {
      border-radius: 0.75rem !important;
    }
    .rounded-top-xl {
      border-top-left-radius: 0.375rem !important;
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-xl-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-top-xl-1 {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-xl-2 {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-xl-3 {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-xl-4 {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-xl-5 {
      border-top-left-radius: 1.5rem !important;
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-xl-6 {
      border-top-left-radius: 3rem !important;
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-xl-7 {
      border-top-left-radius: 4rem !important;
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-xl-8 {
      border-top-left-radius: 5rem !important;
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-xl-9 {
      border-top-left-radius: 6rem !important;
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-xl-10 {
      border-top-left-radius: 7rem !important;
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-xl-pill {
      border-top-left-radius: 50rem !important;
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-xl-circle {
      border-top-left-radius: 50% !important;
      border-top-right-radius: 50% !important;
    }
    .rounded-top-xl-card {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-end-xl {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-end-xl-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-end-xl-1 {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-end-xl-2 {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-end-xl-3 {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-end-xl-4 {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-end-xl-5 {
      border-top-right-radius: 1.5rem !important;
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-end-xl-6 {
      border-top-right-radius: 3rem !important;
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-end-xl-7 {
      border-top-right-radius: 4rem !important;
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-end-xl-8 {
      border-top-right-radius: 5rem !important;
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-end-xl-9 {
      border-top-right-radius: 6rem !important;
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-end-xl-10 {
      border-top-right-radius: 7rem !important;
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-end-xl-pill {
      border-top-right-radius: 50rem !important;
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-end-xl-circle {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .rounded-end-xl-card {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-xl {
      border-bottom-right-radius: 0.375rem !important;
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-xl-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-xl-1 {
      border-bottom-right-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-xl-2 {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-xl-3 {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-xl-4 {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-xl-5 {
      border-bottom-right-radius: 1.5rem !important;
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-xl-6 {
      border-bottom-right-radius: 3rem !important;
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-xl-7 {
      border-bottom-right-radius: 4rem !important;
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-xl-8 {
      border-bottom-right-radius: 5rem !important;
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-xl-9 {
      border-bottom-right-radius: 6rem !important;
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-xl-10 {
      border-bottom-right-radius: 7rem !important;
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-xl-pill {
      border-bottom-right-radius: 50rem !important;
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-xl-circle {
      border-bottom-right-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-xl-card {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-start-xl {
      border-bottom-left-radius: 0.375rem !important;
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-start-xl-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .rounded-start-xl-1 {
      border-bottom-left-radius: 0.25rem !important;
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-start-xl-2 {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-start-xl-3 {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-start-xl-4 {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-start-xl-5 {
      border-bottom-left-radius: 1.5rem !important;
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-start-xl-6 {
      border-bottom-left-radius: 3rem !important;
      border-top-left-radius: 3rem !important;
    }
    .rounded-start-xl-7 {
      border-bottom-left-radius: 4rem !important;
      border-top-left-radius: 4rem !important;
    }
    .rounded-start-xl-8 {
      border-bottom-left-radius: 5rem !important;
      border-top-left-radius: 5rem !important;
    }
    .rounded-start-xl-9 {
      border-bottom-left-radius: 6rem !important;
      border-top-left-radius: 6rem !important;
    }
    .rounded-start-xl-10 {
      border-bottom-left-radius: 7rem !important;
      border-top-left-radius: 7rem !important;
    }
    .rounded-start-xl-pill {
      border-bottom-left-radius: 50rem !important;
      border-top-left-radius: 50rem !important;
    }
    .rounded-start-xl-circle {
      border-bottom-left-radius: 50% !important;
      border-top-left-radius: 50% !important;
    }
    .rounded-start-xl-card {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-xl {
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-top-start-xl-0 {
      border-top-left-radius: 0 !important;
    }
    .rounded-top-start-xl-1 {
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-top-start-xl-2 {
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-top-start-xl-3 {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-xl-4 {
      border-top-left-radius: 1rem !important;
    }
    .rounded-top-start-xl-5 {
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-top-start-xl-6 {
      border-top-left-radius: 3rem !important;
    }
    .rounded-top-start-xl-7 {
      border-top-left-radius: 4rem !important;
    }
    .rounded-top-start-xl-8 {
      border-top-left-radius: 5rem !important;
    }
    .rounded-top-start-xl-9 {
      border-top-left-radius: 6rem !important;
    }
    .rounded-top-start-xl-10 {
      border-top-left-radius: 7rem !important;
    }
    .rounded-top-start-xl-pill {
      border-top-left-radius: 50rem !important;
    }
    .rounded-top-start-xl-circle {
      border-top-left-radius: 50% !important;
    }
    .rounded-top-start-xl-card {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-end-xl {
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-end-xl-0 {
      border-top-right-radius: 0 !important;
    }
    .rounded-top-end-xl-1 {
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-end-xl-2 {
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-end-xl-3 {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-end-xl-4 {
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-end-xl-5 {
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-end-xl-6 {
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-end-xl-7 {
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-end-xl-8 {
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-end-xl-9 {
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-end-xl-10 {
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-end-xl-pill {
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-end-xl-circle {
      border-top-right-radius: 50% !important;
    }
    .rounded-top-end-xl-card {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-xl {
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-bottom-end-xl-0 {
      border-bottom-right-radius: 0 !important;
    }
    .rounded-bottom-end-xl-1 {
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-bottom-end-xl-2 {
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-bottom-end-xl-3 {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-xl-4 {
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-bottom-end-xl-5 {
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-bottom-end-xl-6 {
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-bottom-end-xl-7 {
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-bottom-end-xl-8 {
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-bottom-end-xl-9 {
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-bottom-end-xl-10 {
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-bottom-end-xl-pill {
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-bottom-end-xl-circle {
      border-bottom-right-radius: 50% !important;
    }
    .rounded-bottom-end-xl-card {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-start-xl {
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-start-xl-0 {
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-start-xl-1 {
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-start-xl-2 {
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-start-xl-3 {
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-start-xl-4 {
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-start-xl-5 {
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-start-xl-6 {
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-start-xl-7 {
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-start-xl-8 {
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-start-xl-9 {
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-start-xl-10 {
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-start-xl-pill {
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-start-xl-circle {
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-start-xl-card {
      border-bottom-left-radius: 0.75rem !important;
    }
    .border-xl-solid {
      border-style: solid !important;
    }
    .border-xl-dashed {
      border-style: dashed !important;
    }
    .border-xl-dotted {
      border-style: dotted !important;
    }
    .border-xl-double {
      border-style: double !important;
    }
    .border-xl-groove {
      border-style: groove !important;
    }
    .border-xl-none {
      border-style: none !important;
    }
    .cursor-xl-auto {
      cursor: auto !important;
    }
    .cursor-xl-auto-hover:hover {
      cursor: auto !important;
    }
    .cursor-xl-pointer {
      cursor: pointer !important;
    }
    .cursor-xl-pointer-hover:hover {
      cursor: pointer !important;
    }
    .cursor-xl-wait {
      cursor: wait !important;
    }
    .cursor-xl-wait-hover:hover {
      cursor: wait !important;
    }
    .cursor-xl-text {
      cursor: text !important;
    }
    .cursor-xl-text-hover:hover {
      cursor: text !important;
    }
    .ls-xl-tighter {
      letter-spacing: -0.05em !important;
    }
    .ls-xl-tight {
      letter-spacing: -0.025em !important;
    }
    .ls-xl-normal {
      letter-spacing: 0 !important;
    }
    .ls-xl-wide {
      letter-spacing: 0.025em !important;
    }
    .ls-xl-wider {
      letter-spacing: 0.05em !important;
    }
    .ls-xl-widest {
      letter-spacing: 0.1em !important;
    }
    .bg-xl-auto {
      background-size: auto !important;
    }
    .bg-xl-cover {
      background-size: cover !important;
    }
    .bg-xl-contain {
      background-size: contain !important;
    }
    .min-w-xl-0 {
      min-width: 0 !important;
    }
    .min-w-xl-full {
      min-width: 100% !important;
    }
    .min-w-xl-min {
      min-width: -webkit-min-content !important;
      min-width: -moz-min-content !important;
      min-width: min-content !important;
    }
    .min-w-xl-max {
      min-width: -webkit-max-content !important;
      min-width: -moz-max-content !important;
      min-width: max-content !important;
    }
    .min-h-xl-0 {
      min-height: 0 !important;
    }
    .min-h-xl-full {
      min-height: 100% !important;
    }
    .min-h-xl-screen {
      min-height: 100vh !important;
    }
    .inset-xl-0 {
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
    }
    .inset-xl-1 {
      top: 0.25rem !important;
      right: 0.25rem !important;
      bottom: 0.25rem !important;
      left: 0.25rem !important;
    }
    .inset-xl-2 {
      top: 0.5rem !important;
      right: 0.5rem !important;
      bottom: 0.5rem !important;
      left: 0.5rem !important;
    }
    .inset-xl-3 {
      top: 0.75rem !important;
      right: 0.75rem !important;
      bottom: 0.75rem !important;
      left: 0.75rem !important;
    }
    .inset-xl-4 {
      top: 1rem !important;
      right: 1rem !important;
      bottom: 1rem !important;
      left: 1rem !important;
    }
    .inset-xl-5 {
      top: 1.25rem !important;
      right: 1.25rem !important;
      bottom: 1.25rem !important;
      left: 1.25rem !important;
    }
    .inset-xl-6 {
      top: 1.5rem !important;
      right: 1.5rem !important;
      bottom: 1.5rem !important;
      left: 1.5rem !important;
    }
    .inset-xl-7 {
      top: 1.75rem !important;
      right: 1.75rem !important;
      bottom: 1.75rem !important;
      left: 1.75rem !important;
    }
    .inset-xl-8 {
      top: 2rem !important;
      right: 2rem !important;
      bottom: 2rem !important;
      left: 2rem !important;
    }
    .inset-xl-10 {
      top: 2.5rem !important;
      right: 2.5rem !important;
      bottom: 2.5rem !important;
      left: 2.5rem !important;
    }
    .inset-xl-12 {
      top: 3rem !important;
      right: 3rem !important;
      bottom: 3rem !important;
      left: 3rem !important;
    }
    .inset-xl-14 {
      top: 3.5rem !important;
      right: 3.5rem !important;
      bottom: 3.5rem !important;
      left: 3.5rem !important;
    }
    .inset-xl-16 {
      top: 4rem !important;
      right: 4rem !important;
      bottom: 4rem !important;
      left: 4rem !important;
    }
    .inset-xl-18 {
      top: 4.5rem !important;
      right: 4.5rem !important;
      bottom: 4.5rem !important;
      left: 4.5rem !important;
    }
    .inset-xl-20 {
      top: 5rem !important;
      right: 5rem !important;
      bottom: 5rem !important;
      left: 5rem !important;
    }
    .inset-xl-24 {
      top: 6rem !important;
      right: 6rem !important;
      bottom: 6rem !important;
      left: 6rem !important;
    }
    .inset-xl-32 {
      top: 8rem !important;
      right: 8rem !important;
      bottom: 8rem !important;
      left: 8rem !important;
    }
    .inset-xl-40 {
      top: 10rem !important;
      right: 10rem !important;
      bottom: 10rem !important;
      left: 10rem !important;
    }
    .inset-xl-48 {
      top: 12rem !important;
      right: 12rem !important;
      bottom: 12rem !important;
      left: 12rem !important;
    }
    .inset-xl-56 {
      top: 14rem !important;
      right: 14rem !important;
      bottom: 14rem !important;
      left: 14rem !important;
    }
    .inset-xl-64 {
      top: 16rem !important;
      right: 16rem !important;
      bottom: 16rem !important;
      left: 16rem !important;
    }
    .inset-xl-72 {
      top: 18rem !important;
      right: 18rem !important;
      bottom: 18rem !important;
      left: 18rem !important;
    }
    .inset-xl-80 {
      top: 20rem !important;
      right: 20rem !important;
      bottom: 20rem !important;
      left: 20rem !important;
    }
    .inset-xl-88 {
      top: 22rem !important;
      right: 22rem !important;
      bottom: 22rem !important;
      left: 22rem !important;
    }
    .inset-xl-96 {
      top: 24rem !important;
      right: 24rem !important;
      bottom: 24rem !important;
      left: 24rem !important;
    }
    .inset-xl-px {
      top: 1px !important;
      right: 1px !important;
      bottom: 1px !important;
      left: 1px !important;
    }
    .inset-xl-1\/2 {
      top: 50% !important;
      right: 50% !important;
      bottom: 50% !important;
      left: 50% !important;
    }
    .inset-xl-full {
      top: 100% !important;
      right: 100% !important;
      bottom: 100% !important;
      left: 100% !important;
    }
    .inset-xl-auto {
      top: auto !important;
      right: auto !important;
      bottom: auto !important;
      left: auto !important;
    }
    .inset-x-xl-0 {
      left: 0 !important;
      right: 0 !important;
    }
    .inset-x-xl-1 {
      left: 0.25rem !important;
      right: 0.25rem !important;
    }
    .inset-x-xl-2 {
      left: 0.5rem !important;
      right: 0.5rem !important;
    }
    .inset-x-xl-3 {
      left: 0.75rem !important;
      right: 0.75rem !important;
    }
    .inset-x-xl-4 {
      left: 1rem !important;
      right: 1rem !important;
    }
    .inset-x-xl-5 {
      left: 1.25rem !important;
      right: 1.25rem !important;
    }
    .inset-x-xl-6 {
      left: 1.5rem !important;
      right: 1.5rem !important;
    }
    .inset-x-xl-7 {
      left: 1.75rem !important;
      right: 1.75rem !important;
    }
    .inset-x-xl-8 {
      left: 2rem !important;
      right: 2rem !important;
    }
    .inset-x-xl-10 {
      left: 2.5rem !important;
      right: 2.5rem !important;
    }
    .inset-x-xl-12 {
      left: 3rem !important;
      right: 3rem !important;
    }
    .inset-x-xl-14 {
      left: 3.5rem !important;
      right: 3.5rem !important;
    }
    .inset-x-xl-16 {
      left: 4rem !important;
      right: 4rem !important;
    }
    .inset-x-xl-18 {
      left: 4.5rem !important;
      right: 4.5rem !important;
    }
    .inset-x-xl-20 {
      left: 5rem !important;
      right: 5rem !important;
    }
    .inset-x-xl-24 {
      left: 6rem !important;
      right: 6rem !important;
    }
    .inset-x-xl-32 {
      left: 8rem !important;
      right: 8rem !important;
    }
    .inset-x-xl-40 {
      left: 10rem !important;
      right: 10rem !important;
    }
    .inset-x-xl-48 {
      left: 12rem !important;
      right: 12rem !important;
    }
    .inset-x-xl-56 {
      left: 14rem !important;
      right: 14rem !important;
    }
    .inset-x-xl-64 {
      left: 16rem !important;
      right: 16rem !important;
    }
    .inset-x-xl-72 {
      left: 18rem !important;
      right: 18rem !important;
    }
    .inset-x-xl-80 {
      left: 20rem !important;
      right: 20rem !important;
    }
    .inset-x-xl-88 {
      left: 22rem !important;
      right: 22rem !important;
    }
    .inset-x-xl-96 {
      left: 24rem !important;
      right: 24rem !important;
    }
    .inset-x-xl-px {
      left: 1px !important;
      right: 1px !important;
    }
    .inset-x-xl-1\/2 {
      left: 50% !important;
      right: 50% !important;
    }
    .inset-x-xl-full {
      left: 100% !important;
      right: 100% !important;
    }
    .inset-x-xl-auto {
      left: auto !important;
      right: auto !important;
    }
    .inset-y-xl-0 {
      top: 0 !important;
      bottom: 0 !important;
    }
    .inset-y-xl-1 {
      top: 0.25rem !important;
      bottom: 0.25rem !important;
    }
    .inset-y-xl-2 {
      top: 0.5rem !important;
      bottom: 0.5rem !important;
    }
    .inset-y-xl-3 {
      top: 0.75rem !important;
      bottom: 0.75rem !important;
    }
    .inset-y-xl-4 {
      top: 1rem !important;
      bottom: 1rem !important;
    }
    .inset-y-xl-5 {
      top: 1.25rem !important;
      bottom: 1.25rem !important;
    }
    .inset-y-xl-6 {
      top: 1.5rem !important;
      bottom: 1.5rem !important;
    }
    .inset-y-xl-7 {
      top: 1.75rem !important;
      bottom: 1.75rem !important;
    }
    .inset-y-xl-8 {
      top: 2rem !important;
      bottom: 2rem !important;
    }
    .inset-y-xl-10 {
      top: 2.5rem !important;
      bottom: 2.5rem !important;
    }
    .inset-y-xl-12 {
      top: 3rem !important;
      bottom: 3rem !important;
    }
    .inset-y-xl-14 {
      top: 3.5rem !important;
      bottom: 3.5rem !important;
    }
    .inset-y-xl-16 {
      top: 4rem !important;
      bottom: 4rem !important;
    }
    .inset-y-xl-18 {
      top: 4.5rem !important;
      bottom: 4.5rem !important;
    }
    .inset-y-xl-20 {
      top: 5rem !important;
      bottom: 5rem !important;
    }
    .inset-y-xl-24 {
      top: 6rem !important;
      bottom: 6rem !important;
    }
    .inset-y-xl-32 {
      top: 8rem !important;
      bottom: 8rem !important;
    }
    .inset-y-xl-40 {
      top: 10rem !important;
      bottom: 10rem !important;
    }
    .inset-y-xl-48 {
      top: 12rem !important;
      bottom: 12rem !important;
    }
    .inset-y-xl-56 {
      top: 14rem !important;
      bottom: 14rem !important;
    }
    .inset-y-xl-64 {
      top: 16rem !important;
      bottom: 16rem !important;
    }
    .inset-y-xl-72 {
      top: 18rem !important;
      bottom: 18rem !important;
    }
    .inset-y-xl-80 {
      top: 20rem !important;
      bottom: 20rem !important;
    }
    .inset-y-xl-88 {
      top: 22rem !important;
      bottom: 22rem !important;
    }
    .inset-y-xl-96 {
      top: 24rem !important;
      bottom: 24rem !important;
    }
    .inset-y-xl-px {
      top: 1px !important;
      bottom: 1px !important;
    }
    .inset-y-xl-1\/2 {
      top: 50% !important;
      bottom: 50% !important;
    }
    .inset-y-xl-full {
      top: 100% !important;
      bottom: 100% !important;
    }
    .inset-y-xl-auto {
      top: auto !important;
      bottom: auto !important;
    }
    .place-content-xl-start {
      place-content: flex-start !important;
    }
    .place-content-xl-end {
      place-content: flex-end !important;
    }
    .place-content-xl-center {
      place-content: center !important;
    }
    .place-content-xl-between {
      place-content: space-between !important;
    }
    .place-content-xl-around {
      place-content: space-around !important;
    }
    .place-content-xl-evenly {
      place-content: space-evenly !important;
    }
    .place-content-xl-stretch {
      place-content: stretch !important;
    }
    .overflow-x-xl-auto {
      overflow-x: auto !important;
    }
    .overflow-x-xl-hidden {
      overflow-x: hidden !important;
    }
    .overflow-x-xl-visible {
      overflow-x: visible !important;
    }
    .overflow-x-xl-scroll {
      overflow-x: scroll !important;
    }
    .overflow-y-xl-auto {
      overflow-y: auto !important;
    }
    .overflow-y-xl-hidden {
      overflow-y: hidden !important;
    }
    .overflow-y-xl-visible {
      overflow-y: visible !important;
    }
    .overflow-y-xl-scroll {
      overflow-y: scroll !important;
    }
    .transition-xl {
      transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform !important;
    }
    .transition-xl-none {
      transition-property: none !important;
    }
    .transition-xl-all {
      transition-property: all !important;
    }
    .transition-xl-colors {
      transition-property: background-color, border-color, color, fill, stroke !important;
    }
    .transition-xl-opacity {
      transition-property: opacity !important;
    }
    .transition-xl-shadow {
      transition-property: box-shadow !important;
    }
    .transition-xl-transform {
      transition-property: transform !important;
    }
    .ease-xl-base {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .ease-xl-linear {
      transition-timing-function: linear !important;
    }
    .ease-xl-in {
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
    }
    .ease-xl-out {
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
    }
    .ease-xl-in-out {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .duration-xl-base {
      transition-duration: 150ms !important;
    }
    .duration-xl-75 {
      transition-duration: 75ms !important;
    }
    .duration-xl-100 {
      transition-duration: 100ms !important;
    }
    .duration-xl-150 {
      transition-duration: 150ms !important;
    }
    .duration-xl-200 {
      transition-duration: 200ms !important;
    }
    .duration-xl-300 {
      transition-duration: 300ms !important;
    }
    .duration-xl-500 {
      transition-duration: 500ms !important;
    }
    .duration-xl-700 {
      transition-duration: 700ms !important;
    }
    .duration-xl-1000 {
      transition-duration: 1000ms !important;
    }
    .delay-xl-75 {
      transition-delay: 75ms !important;
    }
    .delay-xl-100 {
      transition-delay: 100ms !important;
    }
    .delay-xl-150 {
      transition-delay: 150ms !important;
    }
    .delay-xl-200 {
      transition-delay: 200ms !important;
    }
    .delay-xl-300 {
      transition-delay: 300ms !important;
    }
    .delay-xl-500 {
      transition-delay: 500ms !important;
    }
    .delay-xl-700 {
      transition-delay: 700ms !important;
    }
    .delay-xl-1000 {
      transition-delay: 1000ms !important;
    }
    .animation-xl-none {
      -webkit-animation: none !important;
      animation: none !important;
    }
    .animation-xl-spin {
      -webkit-animation: spin 1s linear infinite !important;
      animation: spin 1s linear infinite !important;
    }
    .animation-xl-ping {
      -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
      animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
    }
    .animation-xl-pulse {
      -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
    }
    .animation-xl-bounce {
      -webkit-animation: bounce 1s infinite !important;
      animation: bounce 1s infinite !important;
    }
    .animation-xl-slide-left {
      -webkit-animation: slideLeft 3s infinite !important;
      animation: slideLeft 3s infinite !important;
    }
    .animation-xl-slide-right {
      -webkit-animation: slideRight 3s infinite !important;
      animation: slideRight 3s infinite !important;
    }
    .animation-xl-move-slow-left {
      -webkit-animation: slideLeft 15s linear infinite !important;
      animation: slideLeft 15s linear infinite !important;
    }
    .animation-xl-move-slow-right {
      -webkit-animation: slideRight 15s linear infinite !important;
      animation: slideRight 15s linear infinite !important;
    }
    .blur-xl-5 {
      --x-blur: 5px !important;
    }
    .blur-xl-10 {
      --x-blur: 10px !important;
    }
    .blur-xl-25 {
      --x-blur: 25px !important;
    }
    .blur-xl-50 {
      --x-blur: 50px !important;
    }
    .blur-xl-75 {
      --x-blur: 75px !important;
    }
    .blur-xl-100 {
      --x-blur: 100px !important;
    }
    .blur-xl-125 {
      --x-blur: 125px !important;
    }
    .blur-xl-150 {
      --x-blur: 150px !important;
    }
    .blur-xl-200 {
      --x-blur: 200px !important;
    }
    .transform-xl-none {
      transform: none !important;
    }
    .origin-xl-center {
      transform-origin: center !important;
    }
    .origin-xl-center-hover:hover {
      transform-origin: center !important;
    }
    .origin-xl-top {
      transform-origin: top !important;
    }
    .origin-xl-top-hover:hover {
      transform-origin: top !important;
    }
    .origin-xl-top-right {
      transform-origin: top right !important;
    }
    .origin-xl-top-right-hover:hover {
      transform-origin: top right !important;
    }
    .origin-xl-right {
      transform-origin: right !important;
    }
    .origin-xl-right-hover:hover {
      transform-origin: right !important;
    }
    .origin-xl-bottom-right {
      transform-origin: bottom right !important;
    }
    .origin-xl-bottom-right-hover:hover {
      transform-origin: bottom right !important;
    }
    .origin-xl-bottom {
      transform-origin: bottom !important;
    }
    .origin-xl-bottom-hover:hover {
      transform-origin: bottom !important;
    }
    .origin-xl-bottom-left {
      transform-origin: bottom left !important;
    }
    .origin-xl-bottom-left-hover:hover {
      transform-origin: bottom left !important;
    }
    .origin-xl-left {
      transform-origin: left !important;
    }
    .origin-xl-left-hover:hover {
      transform-origin: left !important;
    }
    .origin-xl-top-left {
      transform-origin: top left !important;
    }
    .origin-xl-top-left-hover:hover {
      transform-origin: top left !important;
    }
    .scale-xl-0 {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-xl-0-hover:hover {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-xl-25 {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-xl-25-hover:hover {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-xl-50 {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-xl-50-hover:hover {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-xl-75 {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-xl-75-hover:hover {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-xl-90 {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-xl-90-hover:hover {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-xl-95 {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-xl-95-hover:hover {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-xl-100 {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-xl-100-hover:hover {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-xl-105 {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-xl-105-hover:hover {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-xl-110 {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-xl-110-hover:hover {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-xl-125 {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-xl-125-hover:hover {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-xl-150 {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-xl-150-hover:hover {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-xl-200 {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-xl-200-hover:hover {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-y-xl-0 {
      --x--scale-y: 0 !important;
    }
    .scale-y-xl-0-hover:hover {
      --x--scale-y: 0 !important;
    }
    .scale-y-xl-25 {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-xl-25-hover:hover {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-xl-50 {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-xl-50-hover:hover {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-xl-75 {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-xl-75-hover:hover {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-xl-90 {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-xl-90-hover:hover {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-xl-95 {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-xl-95-hover:hover {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-xl-100 {
      --x--scale-y: 1 !important;
    }
    .scale-y-xl-100-hover:hover {
      --x--scale-y: 1 !important;
    }
    .scale-y-xl-105 {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-xl-105-hover:hover {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-xl-110 {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-xl-110-hover:hover {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-xl-125 {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-xl-125-hover:hover {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-xl-150 {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-xl-150-hover:hover {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-xl-200 {
      --x--scale-y: 2 !important;
    }
    .scale-y-xl-200-hover:hover {
      --x--scale-y: 2 !important;
    }
    .scale-x-xl-0 {
      --x--scale-x: 0 !important;
    }
    .scale-x-xl-0-hover:hover {
      --x--scale-x: 0 !important;
    }
    .scale-x-xl-25 {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-xl-25-hover:hover {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-xl-50 {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-xl-50-hover:hover {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-xl-75 {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-xl-75-hover:hover {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-xl-90 {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-xl-90-hover:hover {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-xl-95 {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-xl-95-hover:hover {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-xl-100 {
      --x--scale-x: 1 !important;
    }
    .scale-x-xl-100-hover:hover {
      --x--scale-x: 1 !important;
    }
    .scale-x-xl-105 {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-xl-105-hover:hover {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-xl-110 {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-xl-110-hover:hover {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-xl-125 {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-xl-125-hover:hover {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-xl-150 {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-xl-150-hover:hover {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-xl-200 {
      --x--scale-x: 2 !important;
    }
    .scale-x-xl-200-hover:hover {
      --x--scale-x: 2 !important;
    }
    .rotate-xl-0 {
      --x-rotate: 0deg !important;
    }
    .rotate-xl-0-hover:hover {
      --x-rotate: 0deg !important;
    }
    .rotate-xl-1 {
      --x-rotate: 1deg !important;
    }
    .rotate-xl-1-hover:hover {
      --x-rotate: 1deg !important;
    }
    .rotate-xl-2 {
      --x-rotate: 2deg !important;
    }
    .rotate-xl-2-hover:hover {
      --x-rotate: 2deg !important;
    }
    .rotate-xl-3 {
      --x-rotate: 3deg !important;
    }
    .rotate-xl-3-hover:hover {
      --x-rotate: 3deg !important;
    }
    .rotate-xl-6 {
      --x-rotate: 6deg !important;
    }
    .rotate-xl-6-hover:hover {
      --x-rotate: 6deg !important;
    }
    .rotate-xl-12 {
      --x-rotate: 12deg !important;
    }
    .rotate-xl-12-hover:hover {
      --x-rotate: 12deg !important;
    }
    .rotate-xl-30 {
      --x-rotate: 30deg !important;
    }
    .rotate-xl-30-hover:hover {
      --x-rotate: 30deg !important;
    }
    .rotate-xl-45 {
      --x-rotate: 45deg !important;
    }
    .rotate-xl-45-hover:hover {
      --x-rotate: 45deg !important;
    }
    .rotate-xl-90 {
      --x-rotate: 90deg !important;
    }
    .rotate-xl-90-hover:hover {
      --x-rotate: 90deg !important;
    }
    .rotate-xl-180 {
      --x-rotate: 180deg !important;
    }
    .rotate-xl-180-hover:hover {
      --x-rotate: 180deg !important;
    }
    .rotate-xl-n1 {
      --x-rotate: -1deg !important;
    }
    .rotate-xl-n1-hover:hover {
      --x-rotate: -1deg !important;
    }
    .rotate-xl-n2 {
      --x-rotate: -2deg !important;
    }
    .rotate-xl-n2-hover:hover {
      --x-rotate: -2deg !important;
    }
    .rotate-xl-n3 {
      --x-rotate: -3deg !important;
    }
    .rotate-xl-n3-hover:hover {
      --x-rotate: -3deg !important;
    }
    .rotate-xl-n6 {
      --x-rotate: -6deg !important;
    }
    .rotate-xl-n6-hover:hover {
      --x-rotate: -6deg !important;
    }
    .rotate-xl-n12 {
      --x-rotate: -12deg !important;
    }
    .rotate-xl-n12-hover:hover {
      --x-rotate: -12deg !important;
    }
    .rotate-xl-n30 {
      --x-rotate: -30deg !important;
    }
    .rotate-xl-n30-hover:hover {
      --x-rotate: -30deg !important;
    }
    .rotate-xl-n45 {
      --x-rotate: -45deg !important;
    }
    .rotate-xl-n45-hover:hover {
      --x-rotate: -45deg !important;
    }
    .rotate-xl-n90 {
      --x-rotate: -90deg !important;
    }
    .rotate-xl-n90-hover:hover {
      --x-rotate: -90deg !important;
    }
    .rotate-xl-n180 {
      --x-rotate: -180deg !important;
    }
    .rotate-xl-n180-hover:hover {
      --x-rotate: -180deg !important;
    }
    .rotate-y-xl-0 {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-xl-0-hover:hover {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-xl-1 {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-xl-1-hover:hover {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-xl-2 {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-xl-2-hover:hover {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-xl-3 {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-xl-3-hover:hover {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-xl-6 {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-xl-6-hover:hover {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-xl-12 {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-xl-12-hover:hover {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-xl-30 {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-xl-30-hover:hover {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-xl-45 {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-xl-45-hover:hover {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-xl-90 {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-xl-90-hover:hover {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-xl-180 {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-xl-180-hover:hover {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-xl-n1 {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-xl-n1-hover:hover {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-xl-n2 {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-xl-n2-hover:hover {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-xl-n3 {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-xl-n3-hover:hover {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-xl-n6 {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-xl-n6-hover:hover {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-xl-n12 {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-xl-n12-hover:hover {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-xl-n30 {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-xl-n30-hover:hover {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-xl-n45 {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-xl-n45-hover:hover {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-xl-n90 {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-xl-n90-hover:hover {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-xl-n180 {
      --x-rotate-y: -180deg !important;
    }
    .rotate-y-xl-n180-hover:hover {
      --x-rotate-y: -180deg !important;
    }
    .rotate-x-xl-0 {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-xl-0-hover:hover {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-xl-1 {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-xl-1-hover:hover {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-xl-2 {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-xl-2-hover:hover {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-xl-3 {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-xl-3-hover:hover {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-xl-6 {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-xl-6-hover:hover {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-xl-12 {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-xl-12-hover:hover {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-xl-30 {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-xl-30-hover:hover {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-xl-45 {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-xl-45-hover:hover {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-xl-90 {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-xl-90-hover:hover {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-xl-180 {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-xl-180-hover:hover {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-xl-n1 {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-xl-n1-hover:hover {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-xl-n2 {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-xl-n2-hover:hover {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-xl-n3 {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-xl-n3-hover:hover {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-xl-n6 {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-xl-n6-hover:hover {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-xl-n12 {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-xl-n12-hover:hover {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-xl-n30 {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-xl-n30-hover:hover {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-xl-n45 {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-xl-n45-hover:hover {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-xl-n90 {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-xl-n90-hover:hover {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-xl-n180 {
      --x-rotate-x: -180deg !important;
    }
    .rotate-x-xl-n180-hover:hover {
      --x-rotate-x: -180deg !important;
    }
    .perspective-xl-100 {
      --x-perspective: 100px !important;
    }
    .perspective-xl-100-hover:hover {
      --x-perspective: 100px !important;
    }
    .perspective-xl-200 {
      --x-perspective: 200px !important;
    }
    .perspective-xl-200-hover:hover {
      --x-perspective: 200px !important;
    }
    .perspective-xl-300 {
      --x-perspective: 300px !important;
    }
    .perspective-xl-300-hover:hover {
      --x-perspective: 300px !important;
    }
    .perspective-xl-400 {
      --x-perspective: 400px !important;
    }
    .perspective-xl-400-hover:hover {
      --x-perspective: 400px !important;
    }
    .perspective-xl-500 {
      --x-perspective: 500px !important;
    }
    .perspective-xl-500-hover:hover {
      --x-perspective: 500px !important;
    }
    .perspective-xl-600 {
      --x-perspective: 600px !important;
    }
    .perspective-xl-600-hover:hover {
      --x-perspective: 600px !important;
    }
    .perspective-xl-700 {
      --x-perspective: 700px !important;
    }
    .perspective-xl-700-hover:hover {
      --x-perspective: 700px !important;
    }
    .perspective-xl-800 {
      --x-perspective: 800px !important;
    }
    .perspective-xl-800-hover:hover {
      --x-perspective: 800px !important;
    }
    .perspective-xl-1000 {
      --x-perspective: 1000px !important;
    }
    .perspective-xl-1000-hover:hover {
      --x-perspective: 1000px !important;
    }
    .perspective-xl-1250 {
      --x-perspective: 1250px !important;
    }
    .perspective-xl-1250-hover:hover {
      --x-perspective: 1250px !important;
    }
    .perspective-xl-1500 {
      --x-perspective: 1500px !important;
    }
    .perspective-xl-1500-hover:hover {
      --x-perspective: 1500px !important;
    }
    .translate-y-xl-0 {
      --x-translate-y: 0 !important;
    }
    .translate-y-xl-0-hover:hover {
      --x-translate-y: 0 !important;
    }
    .translate-y-xl-1 {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-xl-1-hover:hover {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-xl-2 {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-xl-2-hover:hover {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-xl-3 {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-xl-3-hover:hover {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-xl-4 {
      --x-translate-y: 1rem !important;
    }
    .translate-y-xl-4-hover:hover {
      --x-translate-y: 1rem !important;
    }
    .translate-y-xl-5 {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-xl-5-hover:hover {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-xl-6 {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-xl-6-hover:hover {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-xl-7 {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-xl-7-hover:hover {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-xl-8 {
      --x-translate-y: 2rem !important;
    }
    .translate-y-xl-8-hover:hover {
      --x-translate-y: 2rem !important;
    }
    .translate-y-xl-10 {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-xl-10-hover:hover {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-xl-12 {
      --x-translate-y: 3rem !important;
    }
    .translate-y-xl-12-hover:hover {
      --x-translate-y: 3rem !important;
    }
    .translate-y-xl-14 {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-xl-14-hover:hover {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-xl-16 {
      --x-translate-y: 4rem !important;
    }
    .translate-y-xl-16-hover:hover {
      --x-translate-y: 4rem !important;
    }
    .translate-y-xl-18 {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-xl-18-hover:hover {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-xl-20 {
      --x-translate-y: 5rem !important;
    }
    .translate-y-xl-20-hover:hover {
      --x-translate-y: 5rem !important;
    }
    .translate-y-xl-24 {
      --x-translate-y: 6rem !important;
    }
    .translate-y-xl-24-hover:hover {
      --x-translate-y: 6rem !important;
    }
    .translate-y-xl-32 {
      --x-translate-y: 8rem !important;
    }
    .translate-y-xl-32-hover:hover {
      --x-translate-y: 8rem !important;
    }
    .translate-y-xl-40 {
      --x-translate-y: 10rem !important;
    }
    .translate-y-xl-40-hover:hover {
      --x-translate-y: 10rem !important;
    }
    .translate-y-xl-48 {
      --x-translate-y: 12rem !important;
    }
    .translate-y-xl-48-hover:hover {
      --x-translate-y: 12rem !important;
    }
    .translate-y-xl-56 {
      --x-translate-y: 14rem !important;
    }
    .translate-y-xl-56-hover:hover {
      --x-translate-y: 14rem !important;
    }
    .translate-y-xl-64 {
      --x-translate-y: 16rem !important;
    }
    .translate-y-xl-64-hover:hover {
      --x-translate-y: 16rem !important;
    }
    .translate-y-xl-72 {
      --x-translate-y: 18rem !important;
    }
    .translate-y-xl-72-hover:hover {
      --x-translate-y: 18rem !important;
    }
    .translate-y-xl-80 {
      --x-translate-y: 20rem !important;
    }
    .translate-y-xl-80-hover:hover {
      --x-translate-y: 20rem !important;
    }
    .translate-y-xl-88 {
      --x-translate-y: 22rem !important;
    }
    .translate-y-xl-88-hover:hover {
      --x-translate-y: 22rem !important;
    }
    .translate-y-xl-96 {
      --x-translate-y: 24rem !important;
    }
    .translate-y-xl-96-hover:hover {
      --x-translate-y: 24rem !important;
    }
    .translate-y-xl-px {
      --x-translate-y: 1px !important;
    }
    .translate-y-xl-px-hover:hover {
      --x-translate-y: 1px !important;
    }
    .translate-y-xl-1\/2 {
      --x-translate-y: 50% !important;
    }
    .translate-y-xl-1\/2-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-xl-1\/3 {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-xl-1\/3-hover:hover {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-xl-1\/4 {
      --x-translate-y: 25% !important;
    }
    .translate-y-xl-1\/4-hover:hover {
      --x-translate-y: 25% !important;
    }
    .translate-y-xl-1\/5 {
      --x-translate-y: 20% !important;
    }
    .translate-y-xl-1\/5-hover:hover {
      --x-translate-y: 20% !important;
    }
    .translate-y-xl-2\/3 {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-xl-2\/3-hover:hover {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-xl-2\/5 {
      --x-translate-y: 50% !important;
    }
    .translate-y-xl-2\/5-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-xl-3\/4 {
      --x-translate-y: 75% !important;
    }
    .translate-y-xl-3\/4-hover:hover {
      --x-translate-y: 75% !important;
    }
    .translate-y-xl-full {
      --x-translate-y: 100% !important;
    }
    .translate-y-xl-full-hover:hover {
      --x-translate-y: 100% !important;
    }
    .translate-y-xl-n1 {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-xl-n1-hover:hover {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-xl-n2 {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-xl-n2-hover:hover {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-xl-n3 {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-xl-n3-hover:hover {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-xl-n4 {
      --x-translate-y: -1rem !important;
    }
    .translate-y-xl-n4-hover:hover {
      --x-translate-y: -1rem !important;
    }
    .translate-y-xl-n5 {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-xl-n5-hover:hover {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-xl-n6 {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-xl-n6-hover:hover {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-xl-n7 {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-xl-n7-hover:hover {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-xl-n8 {
      --x-translate-y: -2rem !important;
    }
    .translate-y-xl-n8-hover:hover {
      --x-translate-y: -2rem !important;
    }
    .translate-y-xl-n10 {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-xl-n10-hover:hover {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-xl-n12 {
      --x-translate-y: -3rem !important;
    }
    .translate-y-xl-n12-hover:hover {
      --x-translate-y: -3rem !important;
    }
    .translate-y-xl-n14 {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-xl-n14-hover:hover {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-xl-n16 {
      --x-translate-y: -4rem !important;
    }
    .translate-y-xl-n16-hover:hover {
      --x-translate-y: -4rem !important;
    }
    .translate-y-xl-n18 {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-xl-n18-hover:hover {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-xl-n20 {
      --x-translate-y: -5rem !important;
    }
    .translate-y-xl-n20-hover:hover {
      --x-translate-y: -5rem !important;
    }
    .translate-y-xl-n24 {
      --x-translate-y: -6rem !important;
    }
    .translate-y-xl-n24-hover:hover {
      --x-translate-y: -6rem !important;
    }
    .translate-y-xl-n32 {
      --x-translate-y: -8rem !important;
    }
    .translate-y-xl-n32-hover:hover {
      --x-translate-y: -8rem !important;
    }
    .translate-y-xl-n40 {
      --x-translate-y: -10rem !important;
    }
    .translate-y-xl-n40-hover:hover {
      --x-translate-y: -10rem !important;
    }
    .translate-y-xl-n48 {
      --x-translate-y: -12rem !important;
    }
    .translate-y-xl-n48-hover:hover {
      --x-translate-y: -12rem !important;
    }
    .translate-y-xl-n56 {
      --x-translate-y: -14rem !important;
    }
    .translate-y-xl-n56-hover:hover {
      --x-translate-y: -14rem !important;
    }
    .translate-y-xl-n64 {
      --x-translate-y: -16rem !important;
    }
    .translate-y-xl-n64-hover:hover {
      --x-translate-y: -16rem !important;
    }
    .translate-y-xl-n72 {
      --x-translate-y: -18rem !important;
    }
    .translate-y-xl-n72-hover:hover {
      --x-translate-y: -18rem !important;
    }
    .translate-y-xl-n80 {
      --x-translate-y: -20rem !important;
    }
    .translate-y-xl-n80-hover:hover {
      --x-translate-y: -20rem !important;
    }
    .translate-y-xl-n88 {
      --x-translate-y: -22rem !important;
    }
    .translate-y-xl-n88-hover:hover {
      --x-translate-y: -22rem !important;
    }
    .translate-y-xl-n96 {
      --x-translate-y: -24rem !important;
    }
    .translate-y-xl-n96-hover:hover {
      --x-translate-y: -24rem !important;
    }
    .translate-y-xl-npx {
      --x-translate-y: -1px !important;
    }
    .translate-y-xl-npx-hover:hover {
      --x-translate-y: -1px !important;
    }
    .translate-y-xl-n1\/2 {
      --x-translate-y: -50% !important;
    }
    .translate-y-xl-n1\/2-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-xl-n1\/3 {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-xl-n1\/3-hover:hover {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-xl-n1\/4 {
      --x-translate-y: -25% !important;
    }
    .translate-y-xl-n1\/4-hover:hover {
      --x-translate-y: -25% !important;
    }
    .translate-y-xl-n1\/5 {
      --x-translate-y: -20% !important;
    }
    .translate-y-xl-n1\/5-hover:hover {
      --x-translate-y: -20% !important;
    }
    .translate-y-xl-n2\/3 {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-xl-n2\/3-hover:hover {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-xl-n2\/5 {
      --x-translate-y: -50% !important;
    }
    .translate-y-xl-n2\/5-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-xl-n3\/4 {
      --x-translate-y: -75% !important;
    }
    .translate-y-xl-n3\/4-hover:hover {
      --x-translate-y: -75% !important;
    }
    .translate-y-xl-nfull {
      --x-translate-y: -100% !important;
    }
    .translate-y-xl-nfull-hover:hover {
      --x-translate-y: -100% !important;
    }
    .translate-x-xl-0 {
      --x-translate-x: 0 !important;
    }
    .translate-x-xl-0-hover:hover {
      --x-translate-x: 0 !important;
    }
    .translate-x-xl-1 {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-xl-1-hover:hover {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-xl-2 {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-xl-2-hover:hover {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-xl-3 {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-xl-3-hover:hover {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-xl-4 {
      --x-translate-x: 1rem !important;
    }
    .translate-x-xl-4-hover:hover {
      --x-translate-x: 1rem !important;
    }
    .translate-x-xl-5 {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-xl-5-hover:hover {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-xl-6 {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-xl-6-hover:hover {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-xl-7 {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-xl-7-hover:hover {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-xl-8 {
      --x-translate-x: 2rem !important;
    }
    .translate-x-xl-8-hover:hover {
      --x-translate-x: 2rem !important;
    }
    .translate-x-xl-10 {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-xl-10-hover:hover {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-xl-12 {
      --x-translate-x: 3rem !important;
    }
    .translate-x-xl-12-hover:hover {
      --x-translate-x: 3rem !important;
    }
    .translate-x-xl-14 {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-xl-14-hover:hover {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-xl-16 {
      --x-translate-x: 4rem !important;
    }
    .translate-x-xl-16-hover:hover {
      --x-translate-x: 4rem !important;
    }
    .translate-x-xl-18 {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-xl-18-hover:hover {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-xl-20 {
      --x-translate-x: 5rem !important;
    }
    .translate-x-xl-20-hover:hover {
      --x-translate-x: 5rem !important;
    }
    .translate-x-xl-24 {
      --x-translate-x: 6rem !important;
    }
    .translate-x-xl-24-hover:hover {
      --x-translate-x: 6rem !important;
    }
    .translate-x-xl-32 {
      --x-translate-x: 8rem !important;
    }
    .translate-x-xl-32-hover:hover {
      --x-translate-x: 8rem !important;
    }
    .translate-x-xl-40 {
      --x-translate-x: 10rem !important;
    }
    .translate-x-xl-40-hover:hover {
      --x-translate-x: 10rem !important;
    }
    .translate-x-xl-48 {
      --x-translate-x: 12rem !important;
    }
    .translate-x-xl-48-hover:hover {
      --x-translate-x: 12rem !important;
    }
    .translate-x-xl-56 {
      --x-translate-x: 14rem !important;
    }
    .translate-x-xl-56-hover:hover {
      --x-translate-x: 14rem !important;
    }
    .translate-x-xl-64 {
      --x-translate-x: 16rem !important;
    }
    .translate-x-xl-64-hover:hover {
      --x-translate-x: 16rem !important;
    }
    .translate-x-xl-72 {
      --x-translate-x: 18rem !important;
    }
    .translate-x-xl-72-hover:hover {
      --x-translate-x: 18rem !important;
    }
    .translate-x-xl-80 {
      --x-translate-x: 20rem !important;
    }
    .translate-x-xl-80-hover:hover {
      --x-translate-x: 20rem !important;
    }
    .translate-x-xl-88 {
      --x-translate-x: 22rem !important;
    }
    .translate-x-xl-88-hover:hover {
      --x-translate-x: 22rem !important;
    }
    .translate-x-xl-96 {
      --x-translate-x: 24rem !important;
    }
    .translate-x-xl-96-hover:hover {
      --x-translate-x: 24rem !important;
    }
    .translate-x-xl-px {
      --x-translate-x: 1px !important;
    }
    .translate-x-xl-px-hover:hover {
      --x-translate-x: 1px !important;
    }
    .translate-x-xl-1\/2 {
      --x-translate-x: 50% !important;
    }
    .translate-x-xl-1\/2-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-xl-1\/3 {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-xl-1\/3-hover:hover {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-xl-1\/4 {
      --x-translate-x: 25% !important;
    }
    .translate-x-xl-1\/4-hover:hover {
      --x-translate-x: 25% !important;
    }
    .translate-x-xl-1\/5 {
      --x-translate-x: 20% !important;
    }
    .translate-x-xl-1\/5-hover:hover {
      --x-translate-x: 20% !important;
    }
    .translate-x-xl-2\/3 {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-xl-2\/3-hover:hover {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-xl-2\/5 {
      --x-translate-x: 50% !important;
    }
    .translate-x-xl-2\/5-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-xl-3\/4 {
      --x-translate-x: 75% !important;
    }
    .translate-x-xl-3\/4-hover:hover {
      --x-translate-x: 75% !important;
    }
    .translate-x-xl-full {
      --x-translate-x: 100% !important;
    }
    .translate-x-xl-full-hover:hover {
      --x-translate-x: 100% !important;
    }
    .translate-x-xl-n1 {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-xl-n1-hover:hover {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-xl-n2 {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-xl-n2-hover:hover {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-xl-n3 {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-xl-n3-hover:hover {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-xl-n4 {
      --x-translate-x: -1rem !important;
    }
    .translate-x-xl-n4-hover:hover {
      --x-translate-x: -1rem !important;
    }
    .translate-x-xl-n5 {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-xl-n5-hover:hover {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-xl-n6 {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-xl-n6-hover:hover {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-xl-n7 {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-xl-n7-hover:hover {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-xl-n8 {
      --x-translate-x: -2rem !important;
    }
    .translate-x-xl-n8-hover:hover {
      --x-translate-x: -2rem !important;
    }
    .translate-x-xl-n10 {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-xl-n10-hover:hover {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-xl-n12 {
      --x-translate-x: -3rem !important;
    }
    .translate-x-xl-n12-hover:hover {
      --x-translate-x: -3rem !important;
    }
    .translate-x-xl-n14 {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-xl-n14-hover:hover {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-xl-n16 {
      --x-translate-x: -4rem !important;
    }
    .translate-x-xl-n16-hover:hover {
      --x-translate-x: -4rem !important;
    }
    .translate-x-xl-n18 {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-xl-n18-hover:hover {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-xl-n20 {
      --x-translate-x: -5rem !important;
    }
    .translate-x-xl-n20-hover:hover {
      --x-translate-x: -5rem !important;
    }
    .translate-x-xl-n24 {
      --x-translate-x: -6rem !important;
    }
    .translate-x-xl-n24-hover:hover {
      --x-translate-x: -6rem !important;
    }
    .translate-x-xl-n32 {
      --x-translate-x: -8rem !important;
    }
    .translate-x-xl-n32-hover:hover {
      --x-translate-x: -8rem !important;
    }
    .translate-x-xl-n40 {
      --x-translate-x: -10rem !important;
    }
    .translate-x-xl-n40-hover:hover {
      --x-translate-x: -10rem !important;
    }
    .translate-x-xl-n48 {
      --x-translate-x: -12rem !important;
    }
    .translate-x-xl-n48-hover:hover {
      --x-translate-x: -12rem !important;
    }
    .translate-x-xl-n56 {
      --x-translate-x: -14rem !important;
    }
    .translate-x-xl-n56-hover:hover {
      --x-translate-x: -14rem !important;
    }
    .translate-x-xl-n64 {
      --x-translate-x: -16rem !important;
    }
    .translate-x-xl-n64-hover:hover {
      --x-translate-x: -16rem !important;
    }
    .translate-x-xl-n72 {
      --x-translate-x: -18rem !important;
    }
    .translate-x-xl-n72-hover:hover {
      --x-translate-x: -18rem !important;
    }
    .translate-x-xl-n80 {
      --x-translate-x: -20rem !important;
    }
    .translate-x-xl-n80-hover:hover {
      --x-translate-x: -20rem !important;
    }
    .translate-x-xl-n88 {
      --x-translate-x: -22rem !important;
    }
    .translate-x-xl-n88-hover:hover {
      --x-translate-x: -22rem !important;
    }
    .translate-x-xl-n96 {
      --x-translate-x: -24rem !important;
    }
    .translate-x-xl-n96-hover:hover {
      --x-translate-x: -24rem !important;
    }
    .translate-x-xl-npx {
      --x-translate-x: -1px !important;
    }
    .translate-x-xl-npx-hover:hover {
      --x-translate-x: -1px !important;
    }
    .translate-x-xl-n1\/2 {
      --x-translate-x: -50% !important;
    }
    .translate-x-xl-n1\/2-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-xl-n1\/3 {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-xl-n1\/3-hover:hover {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-xl-n1\/4 {
      --x-translate-x: -25% !important;
    }
    .translate-x-xl-n1\/4-hover:hover {
      --x-translate-x: -25% !important;
    }
    .translate-x-xl-n1\/5 {
      --x-translate-x: -20% !important;
    }
    .translate-x-xl-n1\/5-hover:hover {
      --x-translate-x: -20% !important;
    }
    .translate-x-xl-n2\/3 {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-xl-n2\/3-hover:hover {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-xl-n2\/5 {
      --x-translate-x: -50% !important;
    }
    .translate-x-xl-n2\/5-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-xl-n3\/4 {
      --x-translate-x: -75% !important;
    }
    .translate-x-xl-n3\/4-hover:hover {
      --x-translate-x: -75% !important;
    }
    .translate-x-xl-nfull {
      --x-translate-x: -100% !important;
    }
    .translate-x-xl-nfull-hover:hover {
      --x-translate-x: -100% !important;
    }
    .skew-y-xl-0 {
      --x-skew-y: 0deg !important;
    }
    .skew-y-xl-0-hover:hover {
      --x-skew-y: 0deg !important;
    }
    .skew-y-xl-1 {
      --x-skew-y: 1deg !important;
    }
    .skew-y-xl-1-hover:hover {
      --x-skew-y: 1deg !important;
    }
    .skew-y-xl-2 {
      --x-skew-y: 2deg !important;
    }
    .skew-y-xl-2-hover:hover {
      --x-skew-y: 2deg !important;
    }
    .skew-y-xl-3 {
      --x-skew-y: 3deg !important;
    }
    .skew-y-xl-3-hover:hover {
      --x-skew-y: 3deg !important;
    }
    .skew-y-xl-6 {
      --x-skew-y: 6deg !important;
    }
    .skew-y-xl-6-hover:hover {
      --x-skew-y: 6deg !important;
    }
    .skew-y-xl-12 {
      --x-skew-y: 12deg !important;
    }
    .skew-y-xl-12-hover:hover {
      --x-skew-y: 12deg !important;
    }
    .skew-y-xl-n1 {
      --x-skew-y: -1deg !important;
    }
    .skew-y-xl-n1-hover:hover {
      --x-skew-y: -1deg !important;
    }
    .skew-y-xl-n2 {
      --x-skew-y: -2deg !important;
    }
    .skew-y-xl-n2-hover:hover {
      --x-skew-y: -2deg !important;
    }
    .skew-y-xl-n3 {
      --x-skew-y: -3deg !important;
    }
    .skew-y-xl-n3-hover:hover {
      --x-skew-y: -3deg !important;
    }
    .skew-y-xl-n6 {
      --x-skew-y: -6deg !important;
    }
    .skew-y-xl-n6-hover:hover {
      --x-skew-y: -6deg !important;
    }
    .skew-y-xl-n12 {
      --x-skew-y: -12deg !important;
    }
    .skew-y-xl-n12-hover:hover {
      --x-skew-y: -12deg !important;
    }
    .skew-x-xl-0 {
      --x-skew-x: 0deg !important;
    }
    .skew-x-xl-0-hover:hover {
      --x-skew-x: 0deg !important;
    }
    .skew-x-xl-1 {
      --x-skew-x: 1deg !important;
    }
    .skew-x-xl-1-hover:hover {
      --x-skew-x: 1deg !important;
    }
    .skew-x-xl-2 {
      --x-skew-x: 2deg !important;
    }
    .skew-x-xl-2-hover:hover {
      --x-skew-x: 2deg !important;
    }
    .skew-x-xl-3 {
      --x-skew-x: 3deg !important;
    }
    .skew-x-xl-3-hover:hover {
      --x-skew-x: 3deg !important;
    }
    .skew-x-xl-6 {
      --x-skew-x: 6deg !important;
    }
    .skew-x-xl-6-hover:hover {
      --x-skew-x: 6deg !important;
    }
    .skew-x-xl-12 {
      --x-skew-x: 12deg !important;
    }
    .skew-x-xl-12-hover:hover {
      --x-skew-x: 12deg !important;
    }
    .skew-x-xl-n1 {
      --x-skew-x: -1deg !important;
    }
    .skew-x-xl-n1-hover:hover {
      --x-skew-x: -1deg !important;
    }
    .skew-x-xl-n2 {
      --x-skew-x: -2deg !important;
    }
    .skew-x-xl-n2-hover:hover {
      --x-skew-x: -2deg !important;
    }
    .skew-x-xl-n3 {
      --x-skew-x: -3deg !important;
    }
    .skew-x-xl-n3-hover:hover {
      --x-skew-x: -3deg !important;
    }
    .skew-x-xl-n6 {
      --x-skew-x: -6deg !important;
    }
    .skew-x-xl-n6-hover:hover {
      --x-skew-x: -6deg !important;
    }
    .skew-x-xl-n12 {
      --x-skew-x: -12deg !important;
    }
    .skew-x-xl-n12-hover:hover {
      --x-skew-x: -12deg !important;
    }
  }
  @media (min-width: 1400px) {
    .float-xxl-start {
      float: left !important;
    }
    .float-xxl-end {
      float: right !important;
    }
    .float-xxl-none {
      float: none !important;
    }
    .opacity-xxl-0 {
      opacity: 0 !important;
    }
    .opacity-xxl-0-hover:hover {
      opacity: 0 !important;
    }
    .opacity-xxl-10 {
      opacity: 0.1 !important;
    }
    .opacity-xxl-10-hover:hover {
      opacity: 0.1 !important;
    }
    .opacity-xxl-20 {
      opacity: 0.2 !important;
    }
    .opacity-xxl-20-hover:hover {
      opacity: 0.2 !important;
    }
    .opacity-xxl-30 {
      opacity: 0.3 !important;
    }
    .opacity-xxl-30-hover:hover {
      opacity: 0.3 !important;
    }
    .opacity-xxl-40 {
      opacity: 0.4 !important;
    }
    .opacity-xxl-40-hover:hover {
      opacity: 0.4 !important;
    }
    .opacity-xxl-50 {
      opacity: 0.5 !important;
    }
    .opacity-xxl-50-hover:hover {
      opacity: 0.5 !important;
    }
    .opacity-xxl-60 {
      opacity: 0.6 !important;
    }
    .opacity-xxl-60-hover:hover {
      opacity: 0.6 !important;
    }
    .opacity-xxl-70 {
      opacity: 0.7 !important;
    }
    .opacity-xxl-70-hover:hover {
      opacity: 0.7 !important;
    }
    .opacity-xxl-80 {
      opacity: 0.8 !important;
    }
    .opacity-xxl-80-hover:hover {
      opacity: 0.8 !important;
    }
    .opacity-xxl-90 {
      opacity: 0.9 !important;
    }
    .opacity-xxl-90-hover:hover {
      opacity: 0.9 !important;
    }
    .opacity-xxl-100 {
      opacity: 1 !important;
    }
    .opacity-xxl-100-hover:hover {
      opacity: 1 !important;
    }
    .overflow-xxl-auto {
      overflow: auto !important;
    }
    .overflow-xxl-hidden {
      overflow: hidden !important;
    }
    .overflow-xxl-visible {
      overflow: visible !important;
    }
    .overflow-xxl-scroll {
      overflow: scroll !important;
    }
    .d-xxl-inline {
      display: inline !important;
    }
    .d-xxl-inline-block {
      display: inline-block !important;
    }
    .d-xxl-block {
      display: block !important;
    }
    .d-xxl-grid {
      display: grid !important;
    }
    .d-xxl-table {
      display: table !important;
    }
    .d-xxl-table-row {
      display: table-row !important;
    }
    .d-xxl-table-cell {
      display: table-cell !important;
    }
    .d-xxl-flex {
      display: flex !important;
    }
    .d-xxl-inline-flex {
      display: inline-flex !important;
    }
    .d-xxl-none {
      display: none !important;
    }
    .shadow-xxl {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-hover:hover {
      box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-1 {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-1-hover:hover {
      box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-2 {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-2-hover:hover {
      box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-3 {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-3-hover:hover {
      box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-4 {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-4-hover:hover {
      box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-5 {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-5-hover:hover {
      box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.11),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-6 {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-6-hover:hover {
      box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1),
        0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
    }
    .shadow-xxl-inset {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-xxl-inset-hover:hover {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
    }
    .shadow-xxl-outline {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-xxl-outline-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-xxl-focus {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-xxl-focus-hover:hover {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
    }
    .shadow-xxl-none {
      box-shadow: none !important;
    }
    .shadow-xxl-none-hover:hover {
      box-shadow: none !important;
    }
    .position-xxl-static {
      position: static !important;
    }
    .position-xxl-relative {
      position: relative !important;
    }
    .position-xxl-absolute {
      position: absolute !important;
    }
    .position-xxl-fixed {
      position: fixed !important;
    }
    .position-xxl-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .top-xxl-0 {
      top: 0 !important;
    }
    .top-xxl-1 {
      top: 0.25rem !important;
    }
    .top-xxl-2 {
      top: 0.5rem !important;
    }
    .top-xxl-3 {
      top: 0.75rem !important;
    }
    .top-xxl-4 {
      top: 1rem !important;
    }
    .top-xxl-5 {
      top: 1.25rem !important;
    }
    .top-xxl-6 {
      top: 1.5rem !important;
    }
    .top-xxl-7 {
      top: 1.75rem !important;
    }
    .top-xxl-8 {
      top: 2rem !important;
    }
    .top-xxl-10 {
      top: 2.5rem !important;
    }
    .top-xxl-12 {
      top: 3rem !important;
    }
    .top-xxl-14 {
      top: 3.5rem !important;
    }
    .top-xxl-16 {
      top: 4rem !important;
    }
    .top-xxl-18 {
      top: 4.5rem !important;
    }
    .top-xxl-20 {
      top: 5rem !important;
    }
    .top-xxl-24 {
      top: 6rem !important;
    }
    .top-xxl-32 {
      top: 8rem !important;
    }
    .top-xxl-40 {
      top: 10rem !important;
    }
    .top-xxl-48 {
      top: 12rem !important;
    }
    .top-xxl-56 {
      top: 14rem !important;
    }
    .top-xxl-64 {
      top: 16rem !important;
    }
    .top-xxl-72 {
      top: 18rem !important;
    }
    .top-xxl-80 {
      top: 20rem !important;
    }
    .top-xxl-88 {
      top: 22rem !important;
    }
    .top-xxl-96 {
      top: 24rem !important;
    }
    .top-xxl-px {
      top: 1px !important;
    }
    .top-xxl-1\/2 {
      top: 50% !important;
    }
    .top-xxl-full {
      top: 100% !important;
    }
    .top-xxl-auto {
      top: auto !important;
    }
    .bottom-xxl-0 {
      bottom: 0 !important;
    }
    .bottom-xxl-1 {
      bottom: 0.25rem !important;
    }
    .bottom-xxl-2 {
      bottom: 0.5rem !important;
    }
    .bottom-xxl-3 {
      bottom: 0.75rem !important;
    }
    .bottom-xxl-4 {
      bottom: 1rem !important;
    }
    .bottom-xxl-5 {
      bottom: 1.25rem !important;
    }
    .bottom-xxl-6 {
      bottom: 1.5rem !important;
    }
    .bottom-xxl-7 {
      bottom: 1.75rem !important;
    }
    .bottom-xxl-8 {
      bottom: 2rem !important;
    }
    .bottom-xxl-10 {
      bottom: 2.5rem !important;
    }
    .bottom-xxl-12 {
      bottom: 3rem !important;
    }
    .bottom-xxl-14 {
      bottom: 3.5rem !important;
    }
    .bottom-xxl-16 {
      bottom: 4rem !important;
    }
    .bottom-xxl-18 {
      bottom: 4.5rem !important;
    }
    .bottom-xxl-20 {
      bottom: 5rem !important;
    }
    .bottom-xxl-24 {
      bottom: 6rem !important;
    }
    .bottom-xxl-32 {
      bottom: 8rem !important;
    }
    .bottom-xxl-40 {
      bottom: 10rem !important;
    }
    .bottom-xxl-48 {
      bottom: 12rem !important;
    }
    .bottom-xxl-56 {
      bottom: 14rem !important;
    }
    .bottom-xxl-64 {
      bottom: 16rem !important;
    }
    .bottom-xxl-72 {
      bottom: 18rem !important;
    }
    .bottom-xxl-80 {
      bottom: 20rem !important;
    }
    .bottom-xxl-88 {
      bottom: 22rem !important;
    }
    .bottom-xxl-96 {
      bottom: 24rem !important;
    }
    .bottom-xxl-px {
      bottom: 1px !important;
    }
    .bottom-xxl-1\/2 {
      bottom: 50% !important;
    }
    .bottom-xxl-full {
      bottom: 100% !important;
    }
    .bottom-xxl-auto {
      bottom: auto !important;
    }
    .start-xxl-0 {
      left: 0 !important;
    }
    .start-xxl-1 {
      left: 0.25rem !important;
    }
    .start-xxl-2 {
      left: 0.5rem !important;
    }
    .start-xxl-3 {
      left: 0.75rem !important;
    }
    .start-xxl-4 {
      left: 1rem !important;
    }
    .start-xxl-5 {
      left: 1.25rem !important;
    }
    .start-xxl-6 {
      left: 1.5rem !important;
    }
    .start-xxl-7 {
      left: 1.75rem !important;
    }
    .start-xxl-8 {
      left: 2rem !important;
    }
    .start-xxl-10 {
      left: 2.5rem !important;
    }
    .start-xxl-12 {
      left: 3rem !important;
    }
    .start-xxl-14 {
      left: 3.5rem !important;
    }
    .start-xxl-16 {
      left: 4rem !important;
    }
    .start-xxl-18 {
      left: 4.5rem !important;
    }
    .start-xxl-20 {
      left: 5rem !important;
    }
    .start-xxl-24 {
      left: 6rem !important;
    }
    .start-xxl-32 {
      left: 8rem !important;
    }
    .start-xxl-40 {
      left: 10rem !important;
    }
    .start-xxl-48 {
      left: 12rem !important;
    }
    .start-xxl-56 {
      left: 14rem !important;
    }
    .start-xxl-64 {
      left: 16rem !important;
    }
    .start-xxl-72 {
      left: 18rem !important;
    }
    .start-xxl-80 {
      left: 20rem !important;
    }
    .start-xxl-88 {
      left: 22rem !important;
    }
    .start-xxl-96 {
      left: 24rem !important;
    }
    .start-xxl-px {
      left: 1px !important;
    }
    .start-xxl-1\/2 {
      left: 50% !important;
    }
    .start-xxl-full {
      left: 100% !important;
    }
    .start-xxl-auto {
      left: auto !important;
    }
    .end-xxl-0 {
      right: 0 !important;
    }
    .end-xxl-1 {
      right: 0.25rem !important;
    }
    .end-xxl-2 {
      right: 0.5rem !important;
    }
    .end-xxl-3 {
      right: 0.75rem !important;
    }
    .end-xxl-4 {
      right: 1rem !important;
    }
    .end-xxl-5 {
      right: 1.25rem !important;
    }
    .end-xxl-6 {
      right: 1.5rem !important;
    }
    .end-xxl-7 {
      right: 1.75rem !important;
    }
    .end-xxl-8 {
      right: 2rem !important;
    }
    .end-xxl-10 {
      right: 2.5rem !important;
    }
    .end-xxl-12 {
      right: 3rem !important;
    }
    .end-xxl-14 {
      right: 3.5rem !important;
    }
    .end-xxl-16 {
      right: 4rem !important;
    }
    .end-xxl-18 {
      right: 4.5rem !important;
    }
    .end-xxl-20 {
      right: 5rem !important;
    }
    .end-xxl-24 {
      right: 6rem !important;
    }
    .end-xxl-32 {
      right: 8rem !important;
    }
    .end-xxl-40 {
      right: 10rem !important;
    }
    .end-xxl-48 {
      right: 12rem !important;
    }
    .end-xxl-56 {
      right: 14rem !important;
    }
    .end-xxl-64 {
      right: 16rem !important;
    }
    .end-xxl-72 {
      right: 18rem !important;
    }
    .end-xxl-80 {
      right: 20rem !important;
    }
    .end-xxl-88 {
      right: 22rem !important;
    }
    .end-xxl-96 {
      right: 24rem !important;
    }
    .end-xxl-px {
      right: 1px !important;
    }
    .end-xxl-1\/2 {
      right: 50% !important;
    }
    .end-xxl-full {
      right: 100% !important;
    }
    .end-xxl-auto {
      right: auto !important;
    }
    .border-top-xxl-0 {
      border-top-width: 0 !important;
    }
    .border-top-xxl-0-hover:hover {
      border-top-width: 0 !important;
    }
    .border-top-xxl-0-focus:focus {
      border-top-width: 0 !important;
    }
    .border-top-xxl {
      border-top-width: 1px !important;
    }
    .border-top-xxl-hover:hover {
      border-top-width: 1px !important;
    }
    .border-top-xxl-focus:focus {
      border-top-width: 1px !important;
    }
    .border-top-xxl-2 {
      border-top-width: 2px !important;
    }
    .border-top-xxl-2-hover:hover {
      border-top-width: 2px !important;
    }
    .border-top-xxl-2-focus:focus {
      border-top-width: 2px !important;
    }
    .border-top-xxl-3 {
      border-top-width: 3px !important;
    }
    .border-top-xxl-3-hover:hover {
      border-top-width: 3px !important;
    }
    .border-top-xxl-3-focus:focus {
      border-top-width: 3px !important;
    }
    .border-top-xxl-4 {
      border-top-width: 4px !important;
    }
    .border-top-xxl-4-hover:hover {
      border-top-width: 4px !important;
    }
    .border-top-xxl-4-focus:focus {
      border-top-width: 4px !important;
    }
    .border-top-xxl-5 {
      border-top-width: 5px !important;
    }
    .border-top-xxl-5-hover:hover {
      border-top-width: 5px !important;
    }
    .border-top-xxl-5-focus:focus {
      border-top-width: 5px !important;
    }
    .border-top-xxl-6 {
      border-top-width: 6px !important;
    }
    .border-top-xxl-6-hover:hover {
      border-top-width: 6px !important;
    }
    .border-top-xxl-6-focus:focus {
      border-top-width: 6px !important;
    }
    .border-top-xxl-7 {
      border-top-width: 7px !important;
    }
    .border-top-xxl-7-hover:hover {
      border-top-width: 7px !important;
    }
    .border-top-xxl-7-focus:focus {
      border-top-width: 7px !important;
    }
    .border-top-xxl-8 {
      border-top-width: 8px !important;
    }
    .border-top-xxl-8-hover:hover {
      border-top-width: 8px !important;
    }
    .border-top-xxl-8-focus:focus {
      border-top-width: 8px !important;
    }
    .border-end-xxl-0 {
      border-right-width: 0 !important;
    }
    .border-end-xxl-0-hover:hover {
      border-right-width: 0 !important;
    }
    .border-end-xxl-0-focus:focus {
      border-right-width: 0 !important;
    }
    .border-end-xxl {
      border-right-width: 1px !important;
    }
    .border-end-xxl-hover:hover {
      border-right-width: 1px !important;
    }
    .border-end-xxl-focus:focus {
      border-right-width: 1px !important;
    }
    .border-end-xxl-2 {
      border-right-width: 2px !important;
    }
    .border-end-xxl-2-hover:hover {
      border-right-width: 2px !important;
    }
    .border-end-xxl-2-focus:focus {
      border-right-width: 2px !important;
    }
    .border-end-xxl-3 {
      border-right-width: 3px !important;
    }
    .border-end-xxl-3-hover:hover {
      border-right-width: 3px !important;
    }
    .border-end-xxl-3-focus:focus {
      border-right-width: 3px !important;
    }
    .border-end-xxl-4 {
      border-right-width: 4px !important;
    }
    .border-end-xxl-4-hover:hover {
      border-right-width: 4px !important;
    }
    .border-end-xxl-4-focus:focus {
      border-right-width: 4px !important;
    }
    .border-end-xxl-5 {
      border-right-width: 5px !important;
    }
    .border-end-xxl-5-hover:hover {
      border-right-width: 5px !important;
    }
    .border-end-xxl-5-focus:focus {
      border-right-width: 5px !important;
    }
    .border-end-xxl-6 {
      border-right-width: 6px !important;
    }
    .border-end-xxl-6-hover:hover {
      border-right-width: 6px !important;
    }
    .border-end-xxl-6-focus:focus {
      border-right-width: 6px !important;
    }
    .border-end-xxl-7 {
      border-right-width: 7px !important;
    }
    .border-end-xxl-7-hover:hover {
      border-right-width: 7px !important;
    }
    .border-end-xxl-7-focus:focus {
      border-right-width: 7px !important;
    }
    .border-end-xxl-8 {
      border-right-width: 8px !important;
    }
    .border-end-xxl-8-hover:hover {
      border-right-width: 8px !important;
    }
    .border-end-xxl-8-focus:focus {
      border-right-width: 8px !important;
    }
    .border-bottom-xxl-0 {
      border-bottom-width: 0 !important;
    }
    .border-bottom-xxl-0-hover:hover {
      border-bottom-width: 0 !important;
    }
    .border-bottom-xxl-0-focus:focus {
      border-bottom-width: 0 !important;
    }
    .border-bottom-xxl {
      border-bottom-width: 1px !important;
    }
    .border-bottom-xxl-hover:hover {
      border-bottom-width: 1px !important;
    }
    .border-bottom-xxl-focus:focus {
      border-bottom-width: 1px !important;
    }
    .border-bottom-xxl-2 {
      border-bottom-width: 2px !important;
    }
    .border-bottom-xxl-2-hover:hover {
      border-bottom-width: 2px !important;
    }
    .border-bottom-xxl-2-focus:focus {
      border-bottom-width: 2px !important;
    }
    .border-bottom-xxl-3 {
      border-bottom-width: 3px !important;
    }
    .border-bottom-xxl-3-hover:hover {
      border-bottom-width: 3px !important;
    }
    .border-bottom-xxl-3-focus:focus {
      border-bottom-width: 3px !important;
    }
    .border-bottom-xxl-4 {
      border-bottom-width: 4px !important;
    }
    .border-bottom-xxl-4-hover:hover {
      border-bottom-width: 4px !important;
    }
    .border-bottom-xxl-4-focus:focus {
      border-bottom-width: 4px !important;
    }
    .border-bottom-xxl-5 {
      border-bottom-width: 5px !important;
    }
    .border-bottom-xxl-5-hover:hover {
      border-bottom-width: 5px !important;
    }
    .border-bottom-xxl-5-focus:focus {
      border-bottom-width: 5px !important;
    }
    .border-bottom-xxl-6 {
      border-bottom-width: 6px !important;
    }
    .border-bottom-xxl-6-hover:hover {
      border-bottom-width: 6px !important;
    }
    .border-bottom-xxl-6-focus:focus {
      border-bottom-width: 6px !important;
    }
    .border-bottom-xxl-7 {
      border-bottom-width: 7px !important;
    }
    .border-bottom-xxl-7-hover:hover {
      border-bottom-width: 7px !important;
    }
    .border-bottom-xxl-7-focus:focus {
      border-bottom-width: 7px !important;
    }
    .border-bottom-xxl-8 {
      border-bottom-width: 8px !important;
    }
    .border-bottom-xxl-8-hover:hover {
      border-bottom-width: 8px !important;
    }
    .border-bottom-xxl-8-focus:focus {
      border-bottom-width: 8px !important;
    }
    .border-start-xxl-0 {
      border-left-width: 0 !important;
    }
    .border-start-xxl-0-hover:hover {
      border-left-width: 0 !important;
    }
    .border-start-xxl-0-focus:focus {
      border-left-width: 0 !important;
    }
    .border-start-xxl {
      border-left-width: 1px !important;
    }
    .border-start-xxl-hover:hover {
      border-left-width: 1px !important;
    }
    .border-start-xxl-focus:focus {
      border-left-width: 1px !important;
    }
    .border-start-xxl-2 {
      border-left-width: 2px !important;
    }
    .border-start-xxl-2-hover:hover {
      border-left-width: 2px !important;
    }
    .border-start-xxl-2-focus:focus {
      border-left-width: 2px !important;
    }
    .border-start-xxl-3 {
      border-left-width: 3px !important;
    }
    .border-start-xxl-3-hover:hover {
      border-left-width: 3px !important;
    }
    .border-start-xxl-3-focus:focus {
      border-left-width: 3px !important;
    }
    .border-start-xxl-4 {
      border-left-width: 4px !important;
    }
    .border-start-xxl-4-hover:hover {
      border-left-width: 4px !important;
    }
    .border-start-xxl-4-focus:focus {
      border-left-width: 4px !important;
    }
    .border-start-xxl-5 {
      border-left-width: 5px !important;
    }
    .border-start-xxl-5-hover:hover {
      border-left-width: 5px !important;
    }
    .border-start-xxl-5-focus:focus {
      border-left-width: 5px !important;
    }
    .border-start-xxl-6 {
      border-left-width: 6px !important;
    }
    .border-start-xxl-6-hover:hover {
      border-left-width: 6px !important;
    }
    .border-start-xxl-6-focus:focus {
      border-left-width: 6px !important;
    }
    .border-start-xxl-7 {
      border-left-width: 7px !important;
    }
    .border-start-xxl-7-hover:hover {
      border-left-width: 7px !important;
    }
    .border-start-xxl-7-focus:focus {
      border-left-width: 7px !important;
    }
    .border-start-xxl-8 {
      border-left-width: 8px !important;
    }
    .border-start-xxl-8-hover:hover {
      border-left-width: 8px !important;
    }
    .border-start-xxl-8-focus:focus {
      border-left-width: 8px !important;
    }
    .border-xxl-primary {
      border-color: #4066D5 !important;
    }
    .border-xxl-primary-hover:hover {
      border-color: #4066D5 !important;
    }
    .border-xxl-primary-focus:focus {
      border-color: #4066D5 !important;
    }
    .border-xxl-primary-focus-within:focus-within {
      border-color: #4066D5 !important;
    }
    .border-xxl-secondary {
      border-color: #cfd6df !important;
    }
    .border-xxl-secondary-hover:hover {
      border-color: #cfd6df !important;
    }
    .border-xxl-secondary-focus:focus {
      border-color: #cfd6df !important;
    }
    .border-xxl-secondary-focus-within:focus-within {
      border-color: #cfd6df !important;
    }
    .border-xxl-tertiary {
      border-color: #ff579a !important;
    }
    .border-xxl-tertiary-hover:hover {
      border-color: #ff579a !important;
    }
    .border-xxl-tertiary-focus:focus {
      border-color: #ff579a !important;
    }
    .border-xxl-tertiary-focus-within:focus-within {
      border-color: #ff579a !important;
    }
    .border-xxl-success {
      border-color: #0c8 !important;
    }
    .border-xxl-success-hover:hover {
      border-color: #0c8 !important;
    }
    .border-xxl-success-focus:focus {
      border-color: #0c8 !important;
    }
    .border-xxl-success-focus-within:focus-within {
      border-color: #0c8 !important;
    }
    .border-xxl-info {
      border-color: #00d4ff !important;
    }
    .border-xxl-info-hover:hover {
      border-color: #00d4ff !important;
    }
    .border-xxl-info-focus:focus {
      border-color: #00d4ff !important;
    }
    .border-xxl-info-focus-within:focus-within {
      border-color: #00d4ff !important;
    }
    .border-xxl-warning {
      border-color: #ff8c00 !important;
    }
    .border-xxl-warning-hover:hover {
      border-color: #ff8c00 !important;
    }
    .border-xxl-warning-focus:focus {
      border-color: #ff8c00 !important;
    }
    .border-xxl-warning-focus-within:focus-within {
      border-color: #ff8c00 !important;
    }
    .border-xxl-danger {
      border-color: #f36 !important;
    }
    .border-xxl-danger-hover:hover {
      border-color: #f36 !important;
    }
    .border-xxl-danger-focus:focus {
      border-color: #f36 !important;
    }
    .border-xxl-danger-focus-within:focus-within {
      border-color: #f36 !important;
    }
    .border-xxl-white {
      border-color: #fff !important;
    }
    .border-xxl-white-hover:hover {
      border-color: #fff !important;
    }
    .border-xxl-white-focus:focus {
      border-color: #fff !important;
    }
    .border-xxl-white-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-xxl-light {
      border-color: #e7eaf0 !important;
    }
    .border-xxl-light-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-xxl-light-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-xxl-light-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-xxl-dark {
      border-color: #16192c !important;
    }
    .border-xxl-dark-hover:hover {
      border-color: #16192c !important;
    }
    .border-xxl-dark-focus:focus {
      border-color: #16192c !important;
    }
    .border-xxl-dark-focus-within:focus-within {
      border-color: #16192c !important;
    }
    .border-xxl-transparent {
      border-color: transparent !important;
    }
    .border-xxl-transparent-hover:hover {
      border-color: transparent !important;
    }
    .border-xxl-transparent-focus:focus {
      border-color: transparent !important;
    }
    .border-xxl-transparent-focus-within:focus-within {
      border-color: transparent !important;
    }
    .border-xxl-current {
      border-color: currentColor !important;
    }
    .border-xxl-current-hover:hover {
      border-color: currentColor !important;
    }
    .border-xxl-current-focus:focus {
      border-color: currentColor !important;
    }
    .border-xxl-current-focus-within:focus-within {
      border-color: currentColor !important;
    }
    .border-xxl-base {
      border-color: #e7eaf0 !important;
    }
    .border-xxl-base-hover:hover {
      border-color: #e7eaf0 !important;
    }
    .border-xxl-base-focus:focus {
      border-color: #e7eaf0 !important;
    }
    .border-xxl-base-focus-within:focus-within {
      border-color: #e7eaf0 !important;
    }
    .border-xxl-body {
      border-color: #fff !important;
    }
    .border-xxl-body-hover:hover {
      border-color: #fff !important;
    }
    .border-xxl-body-focus:focus {
      border-color: #fff !important;
    }
    .border-xxl-body-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-xxl-card {
      border-color: #fff !important;
    }
    .border-xxl-card-hover:hover {
      border-color: #fff !important;
    }
    .border-xxl-card-focus:focus {
      border-color: #fff !important;
    }
    .border-xxl-card-focus-within:focus-within {
      border-color: #fff !important;
    }
    .border-xxl-black {
      border-color: #000 !important;
    }
    .border-xxl-black-hover:hover {
      border-color: #000 !important;
    }
    .border-xxl-black-focus:focus {
      border-color: #000 !important;
    }
    .border-xxl-black-focus-within:focus-within {
      border-color: #000 !important;
    }
    .border-xxl-0 {
      border-width: 0 !important;
    }
    .border-xxl-0-hover:hover {
      border-width: 0 !important;
    }
    .border-xxl-0-focus:focus {
      border-width: 0 !important;
    }
    .border-xxl {
      border-width: 1px !important;
    }
    .border-xxl-hover:hover {
      border-width: 1px !important;
    }
    .border-xxl-focus:focus {
      border-width: 1px !important;
    }
    .border-xxl-2 {
      border-width: 2px !important;
    }
    .border-xxl-2-hover:hover {
      border-width: 2px !important;
    }
    .border-xxl-2-focus:focus {
      border-width: 2px !important;
    }
    .border-xxl-3 {
      border-width: 3px !important;
    }
    .border-xxl-3-hover:hover {
      border-width: 3px !important;
    }
    .border-xxl-3-focus:focus {
      border-width: 3px !important;
    }
    .border-xxl-4 {
      border-width: 4px !important;
    }
    .border-xxl-4-hover:hover {
      border-width: 4px !important;
    }
    .border-xxl-4-focus:focus {
      border-width: 4px !important;
    }
    .border-xxl-5 {
      border-width: 5px !important;
    }
    .border-xxl-5-hover:hover {
      border-width: 5px !important;
    }
    .border-xxl-5-focus:focus {
      border-width: 5px !important;
    }
    .border-xxl-6 {
      border-width: 6px !important;
    }
    .border-xxl-6-hover:hover {
      border-width: 6px !important;
    }
    .border-xxl-6-focus:focus {
      border-width: 6px !important;
    }
    .border-xxl-7 {
      border-width: 7px !important;
    }
    .border-xxl-7-hover:hover {
      border-width: 7px !important;
    }
    .border-xxl-7-focus:focus {
      border-width: 7px !important;
    }
    .border-xxl-8 {
      border-width: 8px !important;
    }
    .border-xxl-8-hover:hover {
      border-width: 8px !important;
    }
    .border-xxl-8-focus:focus {
      border-width: 8px !important;
    }
    .w-xxl-0 {
      width: 0 !important;
    }
    .w-xxl-1 {
      width: 0.25rem !important;
    }
    .w-xxl-2 {
      width: 0.5rem !important;
    }
    .w-xxl-3 {
      width: 0.75rem !important;
    }
    .w-xxl-4 {
      width: 1rem !important;
    }
    .w-xxl-5 {
      width: 1.25rem !important;
    }
    .w-xxl-6 {
      width: 1.5rem !important;
    }
    .w-xxl-7 {
      width: 1.75rem !important;
    }
    .w-xxl-8 {
      width: 2rem !important;
    }
    .w-xxl-10 {
      width: 2.5rem !important;
    }
    .w-xxl-12 {
      width: 3rem !important;
    }
    .w-xxl-14 {
      width: 3.5rem !important;
    }
    .w-xxl-16 {
      width: 4rem !important;
    }
    .w-xxl-18 {
      width: 4.5rem !important;
    }
    .w-xxl-20 {
      width: 5rem !important;
    }
    .w-xxl-24 {
      width: 6rem !important;
    }
    .w-xxl-32 {
      width: 8rem !important;
    }
    .w-xxl-40 {
      width: 10rem !important;
    }
    .w-xxl-48 {
      width: 12rem !important;
    }
    .w-xxl-56 {
      width: 14rem !important;
    }
    .w-xxl-64 {
      width: 16rem !important;
    }
    .w-xxl-72 {
      width: 18rem !important;
    }
    .w-xxl-80 {
      width: 20rem !important;
    }
    .w-xxl-88 {
      width: 22rem !important;
    }
    .w-xxl-96 {
      width: 24rem !important;
    }
    .w-xxl-px {
      width: 1px !important;
    }
    .w-xxl-1\/2 {
      width: 50% !important;
    }
    .w-xxl-1\/3 {
      width: 33.3333333333% !important;
    }
    .w-xxl-2\/3 {
      width: 66.6666666667% !important;
    }
    .w-xxl-1\/4 {
      width: 25% !important;
    }
    .w-xxl-2\/4 {
      width: 50% !important;
    }
    .w-xxl-3\/4 {
      width: 75% !important;
    }
    .w-xxl-1\/5 {
      width: 20% !important;
    }
    .w-xxl-2\/5 {
      width: 40% !important;
    }
    .w-xxl-3\/5 {
      width: 60% !important;
    }
    .w-xxl-4\/5 {
      width: 80% !important;
    }
    .w-xxl-1\/6 {
      width: 16.6666666667% !important;
    }
    .w-xxl-2\/6 {
      width: 33.3333333333% !important;
    }
    .w-xxl-3\/6 {
      width: 50% !important;
    }
    .w-xxl-4\/6 {
      width: 66.6666666667% !important;
    }
    .w-xxl-5\/6 {
      width: 83.3333333333% !important;
    }
    .w-xxl-11\/10 {
      width: 110% !important;
    }
    .w-xxl-12\/10 {
      width: 120% !important;
    }
    .w-xxl-13\/10 {
      width: 130% !important;
    }
    .w-xxl-14\/10 {
      width: 140% !important;
    }
    .w-xxl-15\/10 {
      width: 150% !important;
    }
    .w-xxl-auto {
      width: auto !important;
    }
    .w-xxl-full {
      width: 100% !important;
    }
    .w-xxl-screen {
      width: 100vw !important;
    }
    .w-xxl-min {
      width: -webkit-min-content !important;
      width: -moz-min-content !important;
      width: min-content !important;
    }
    .w-xxl-max {
      width: -webkit-max-content !important;
      width: -moz-max-content !important;
      width: max-content !important;
    }
    .max-w-xxl-screen-sm {
      max-width: 640px !important;
    }
    .max-w-xxl-screen-md {
      max-width: 768px !important;
    }
    .max-w-xxl-screen-lg {
      max-width: 1024px !important;
    }
    .max-w-xxl-screen-xl {
      max-width: 1280px !important;
    }
    .max-w-xxl-screen-xxl {
      max-width: 1536px !important;
    }
    .max-w-xxl-0 {
      max-width: 0 !important;
    }
    .max-w-xxl-full {
      max-width: 100% !important;
    }
    .max-w-xxl-read {
      max-width: 65ch !important;
    }
    .max-w-xxl-min {
      max-width: -webkit-min-content !important;
      max-width: -moz-min-content !important;
      max-width: min-content !important;
    }
    .max-w-xxl-max {
      max-width: -webkit-max-content !important;
      max-width: -moz-max-content !important;
      max-width: max-content !important;
    }
    .h-xxl-0 {
      height: 0 !important;
    }
    .h-xxl-1 {
      height: 0.25rem !important;
    }
    .h-xxl-2 {
      height: 0.5rem !important;
    }
    .h-xxl-3 {
      height: 0.75rem !important;
    }
    .h-xxl-4 {
      height: 1rem !important;
    }
    .h-xxl-5 {
      height: 1.25rem !important;
    }
    .h-xxl-6 {
      height: 1.5rem !important;
    }
    .h-xxl-7 {
      height: 1.75rem !important;
    }
    .h-xxl-8 {
      height: 2rem !important;
    }
    .h-xxl-10 {
      height: 2.5rem !important;
    }
    .h-xxl-12 {
      height: 3rem !important;
    }
    .h-xxl-14 {
      height: 3.5rem !important;
    }
    .h-xxl-16 {
      height: 4rem !important;
    }
    .h-xxl-18 {
      height: 4.5rem !important;
    }
    .h-xxl-20 {
      height: 5rem !important;
    }
    .h-xxl-24 {
      height: 6rem !important;
    }
    .h-xxl-32 {
      height: 8rem !important;
    }
    .h-xxl-40 {
      height: 10rem !important;
    }
    .h-xxl-48 {
      height: 12rem !important;
    }
    .h-xxl-56 {
      height: 14rem !important;
    }
    .h-xxl-64 {
      height: 16rem !important;
    }
    .h-xxl-72 {
      height: 18rem !important;
    }
    .h-xxl-80 {
      height: 20rem !important;
    }
    .h-xxl-88 {
      height: 22rem !important;
    }
    .h-xxl-96 {
      height: 24rem !important;
    }
    .h-xxl-px {
      height: 1px !important;
    }
    .h-xxl-1\/2 {
      height: 50% !important;
    }
    .h-xxl-1\/3 {
      height: 33.3333333333% !important;
    }
    .h-xxl-2\/3 {
      height: 66.6666666667% !important;
    }
    .h-xxl-1\/4 {
      height: 25% !important;
    }
    .h-xxl-2\/4 {
      height: 50% !important;
    }
    .h-xxl-3\/4 {
      height: 75% !important;
    }
    .h-xxl-1\/5 {
      height: 20% !important;
    }
    .h-xxl-2\/5 {
      height: 40% !important;
    }
    .h-xxl-3\/5 {
      height: 60% !important;
    }
    .h-xxl-4\/5 {
      height: 80% !important;
    }
    .h-xxl-1\/6 {
      height: 16.6666666667% !important;
    }
    .h-xxl-2\/6 {
      height: 33.3333333333% !important;
    }
    .h-xxl-3\/6 {
      height: 50% !important;
    }
    .h-xxl-4\/6 {
      height: 66.6666666667% !important;
    }
    .h-xxl-5\/6 {
      height: 83.3333333333% !important;
    }
    .h-xxl-11\/10 {
      height: 110% !important;
    }
    .h-xxl-12\/10 {
      height: 120% !important;
    }
    .h-xxl-13\/10 {
      height: 130% !important;
    }
    .h-xxl-14\/10 {
      height: 140% !important;
    }
    .h-xxl-15\/10 {
      height: 150% !important;
    }
    .h-xxl-auto {
      height: auto !important;
    }
    .h-xxl-full {
      height: 100% !important;
    }
    .h-xxl-screen {
      height: 100vh !important;
    }
    .max-h-xxl-0 {
      max-height: 0 !important;
    }
    .max-h-xxl-1 {
      max-height: 0.25rem !important;
    }
    .max-h-xxl-2 {
      max-height: 0.5rem !important;
    }
    .max-h-xxl-3 {
      max-height: 0.75rem !important;
    }
    .max-h-xxl-4 {
      max-height: 1rem !important;
    }
    .max-h-xxl-5 {
      max-height: 1.25rem !important;
    }
    .max-h-xxl-6 {
      max-height: 1.5rem !important;
    }
    .max-h-xxl-7 {
      max-height: 1.75rem !important;
    }
    .max-h-xxl-8 {
      max-height: 2rem !important;
    }
    .max-h-xxl-10 {
      max-height: 2.5rem !important;
    }
    .max-h-xxl-12 {
      max-height: 3rem !important;
    }
    .max-h-xxl-14 {
      max-height: 3.5rem !important;
    }
    .max-h-xxl-16 {
      max-height: 4rem !important;
    }
    .max-h-xxl-18 {
      max-height: 4.5rem !important;
    }
    .max-h-xxl-20 {
      max-height: 5rem !important;
    }
    .max-h-xxl-24 {
      max-height: 6rem !important;
    }
    .max-h-xxl-32 {
      max-height: 8rem !important;
    }
    .max-h-xxl-40 {
      max-height: 10rem !important;
    }
    .max-h-xxl-48 {
      max-height: 12rem !important;
    }
    .max-h-xxl-56 {
      max-height: 14rem !important;
    }
    .max-h-xxl-64 {
      max-height: 16rem !important;
    }
    .max-h-xxl-72 {
      max-height: 18rem !important;
    }
    .max-h-xxl-80 {
      max-height: 20rem !important;
    }
    .max-h-xxl-88 {
      max-height: 22rem !important;
    }
    .max-h-xxl-96 {
      max-height: 24rem !important;
    }
    .max-h-xxl-px {
      max-height: 1px !important;
    }
    .max-h-xxl-full {
      max-height: 100% !important;
    }
    .max-h-xxl-screen {
      max-height: 100vh !important;
    }
    .flex-xxl-1 {
      flex: 1 1 0% !important;
    }
    .flex-xxl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xxl-initial {
      flex: 0 1 auto !important;
    }
    .flex-xxl-none {
      flex: none !important;
    }
    .flex-xxl-row {
      flex-direction: row !important;
    }
    .flex-xxl-column {
      flex-direction: column !important;
    }
    .flex-xxl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xxl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xxl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-xxl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .gap-xxl-0 {
      gap: 0 !important;
    }
    .gap-xxl-1 {
      gap: 0.25rem !important;
    }
    .gap-xxl-2 {
      gap: 0.5rem !important;
    }
    .gap-xxl-3 {
      gap: 0.75rem !important;
    }
    .gap-xxl-4 {
      gap: 1rem !important;
    }
    .gap-xxl-5 {
      gap: 1.25rem !important;
    }
    .gap-xxl-6 {
      gap: 1.5rem !important;
    }
    .gap-xxl-7 {
      gap: 1.75rem !important;
    }
    .gap-xxl-8 {
      gap: 2rem !important;
    }
    .gap-xxl-10 {
      gap: 2.5rem !important;
    }
    .gap-xxl-12 {
      gap: 3rem !important;
    }
    .gap-xxl-14 {
      gap: 3.5rem !important;
    }
    .gap-xxl-16 {
      gap: 4rem !important;
    }
    .gap-xxl-18 {
      gap: 4.5rem !important;
    }
    .gap-xxl-20 {
      gap: 5rem !important;
    }
    .gap-xxl-24 {
      gap: 6rem !important;
    }
    .gap-xxl-32 {
      gap: 8rem !important;
    }
    .gap-xxl-40 {
      gap: 10rem !important;
    }
    .gap-xxl-48 {
      gap: 12rem !important;
    }
    .gap-xxl-56 {
      gap: 14rem !important;
    }
    .gap-xxl-64 {
      gap: 16rem !important;
    }
    .gap-xxl-72 {
      gap: 18rem !important;
    }
    .gap-xxl-80 {
      gap: 20rem !important;
    }
    .gap-xxl-88 {
      gap: 22rem !important;
    }
    .gap-xxl-96 {
      gap: 24rem !important;
    }
    .gap-xxl-px {
      gap: 1px !important;
    }
    .justify-content-xxl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xxl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xxl-center {
      justify-content: center !important;
    }
    .justify-content-xxl-between {
      justify-content: space-between !important;
    }
    .justify-content-xxl-around {
      justify-content: space-around !important;
    }
    .justify-content-xxl-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-xxl-start {
      align-items: flex-start !important;
    }
    .align-items-xxl-end {
      align-items: flex-end !important;
    }
    .align-items-xxl-center {
      align-items: center !important;
    }
    .align-items-xxl-baseline {
      align-items: baseline !important;
    }
    .align-items-xxl-stretch {
      align-items: stretch !important;
    }
    .align-content-xxl-start {
      align-content: flex-start !important;
    }
    .align-content-xxl-end {
      align-content: flex-end !important;
    }
    .align-content-xxl-center {
      align-content: center !important;
    }
    .align-content-xxl-between {
      align-content: space-between !important;
    }
    .align-content-xxl-around {
      align-content: space-around !important;
    }
    .align-content-xxl-evenly {
      align-content: space-evenly !important;
    }
    .align-content-xxl-stretch {
      align-content: stretch !important;
    }
    .align-self-xxl-auto {
      align-self: auto !important;
    }
    .align-self-xxl-start {
      align-self: flex-start !important;
    }
    .align-self-xxl-end {
      align-self: flex-end !important;
    }
    .align-self-xxl-center {
      align-self: center !important;
    }
    .align-self-xxl-baseline {
      align-self: baseline !important;
    }
    .align-self-xxl-stretch {
      align-self: stretch !important;
    }
    .order-xxl-first {
      order: -1 !important;
    }
    .order-xxl-0 {
      order: 0 !important;
    }
    .order-xxl-1 {
      order: 1 !important;
    }
    .order-xxl-2 {
      order: 2 !important;
    }
    .order-xxl-3 {
      order: 3 !important;
    }
    .order-xxl-4 {
      order: 4 !important;
    }
    .order-xxl-5 {
      order: 5 !important;
    }
    .order-xxl-last {
      order: 6 !important;
    }
    .m-xxl-0 {
      margin: 0 !important;
    }
    .m-xxl-1 {
      margin: 0.25rem !important;
    }
    .m-xxl-2 {
      margin: 0.5rem !important;
    }
    .m-xxl-3 {
      margin: 0.75rem !important;
    }
    .m-xxl-4 {
      margin: 1rem !important;
    }
    .m-xxl-5 {
      margin: 1.25rem !important;
    }
    .m-xxl-6 {
      margin: 1.5rem !important;
    }
    .m-xxl-7 {
      margin: 1.75rem !important;
    }
    .m-xxl-8 {
      margin: 2rem !important;
    }
    .m-xxl-10 {
      margin: 2.5rem !important;
    }
    .m-xxl-12 {
      margin: 3rem !important;
    }
    .m-xxl-14 {
      margin: 3.5rem !important;
    }
    .m-xxl-16 {
      margin: 4rem !important;
    }
    .m-xxl-18 {
      margin: 4.5rem !important;
    }
    .m-xxl-20 {
      margin: 5rem !important;
    }
    .m-xxl-24 {
      margin: 6rem !important;
    }
    .m-xxl-32 {
      margin: 8rem !important;
    }
    .m-xxl-40 {
      margin: 10rem !important;
    }
    .m-xxl-48 {
      margin: 12rem !important;
    }
    .m-xxl-56 {
      margin: 14rem !important;
    }
    .m-xxl-64 {
      margin: 16rem !important;
    }
    .m-xxl-72 {
      margin: 18rem !important;
    }
    .m-xxl-80 {
      margin: 20rem !important;
    }
    .m-xxl-88 {
      margin: 22rem !important;
    }
    .m-xxl-96 {
      margin: 24rem !important;
    }
    .m-xxl-px {
      margin: 1px !important;
    }
    .m-xxl-auto {
      margin: auto !important;
    }
    .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-xxl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-xxl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-xxl-3 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-xxl-4 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-xxl-5 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-xxl-6 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-xxl-7 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-xxl-8 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-xxl-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-xxl-12 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-xxl-14 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-xxl-16 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-xxl-18 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-xxl-20 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-xxl-24 {
      margin-right: 6rem !important;
      margin-left: 6rem !important;
    }
    .mx-xxl-32 {
      margin-right: 8rem !important;
      margin-left: 8rem !important;
    }
    .mx-xxl-40 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-xxl-48 {
      margin-right: 12rem !important;
      margin-left: 12rem !important;
    }
    .mx-xxl-56 {
      margin-right: 14rem !important;
      margin-left: 14rem !important;
    }
    .mx-xxl-64 {
      margin-right: 16rem !important;
      margin-left: 16rem !important;
    }
    .mx-xxl-72 {
      margin-right: 18rem !important;
      margin-left: 18rem !important;
    }
    .mx-xxl-80 {
      margin-right: 20rem !important;
      margin-left: 20rem !important;
    }
    .mx-xxl-88 {
      margin-right: 22rem !important;
      margin-left: 22rem !important;
    }
    .mx-xxl-96 {
      margin-right: 24rem !important;
      margin-left: 24rem !important;
    }
    .mx-xxl-px {
      margin-right: 1px !important;
      margin-left: 1px !important;
    }
    .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-xxl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-xxl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-xxl-3 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-xxl-4 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-xxl-5 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-xxl-6 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-xxl-7 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-xxl-8 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-xxl-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-xxl-12 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-xxl-14 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-xxl-16 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-xxl-18 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-xxl-20 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-xxl-24 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important;
    }
    .my-xxl-32 {
      margin-top: 8rem !important;
      margin-bottom: 8rem !important;
    }
    .my-xxl-40 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-xxl-48 {
      margin-top: 12rem !important;
      margin-bottom: 12rem !important;
    }
    .my-xxl-56 {
      margin-top: 14rem !important;
      margin-bottom: 14rem !important;
    }
    .my-xxl-64 {
      margin-top: 16rem !important;
      margin-bottom: 16rem !important;
    }
    .my-xxl-72 {
      margin-top: 18rem !important;
      margin-bottom: 18rem !important;
    }
    .my-xxl-80 {
      margin-top: 20rem !important;
      margin-bottom: 20rem !important;
    }
    .my-xxl-88 {
      margin-top: 22rem !important;
      margin-bottom: 22rem !important;
    }
    .my-xxl-96 {
      margin-top: 24rem !important;
      margin-bottom: 24rem !important;
    }
    .my-xxl-px {
      margin-top: 1px !important;
      margin-bottom: 1px !important;
    }
    .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xxl-0 {
      margin-top: 0 !important;
    }
    .mt-xxl-1 {
      margin-top: 0.25rem !important;
    }
    .mt-xxl-2 {
      margin-top: 0.5rem !important;
    }
    .mt-xxl-3 {
      margin-top: 0.75rem !important;
    }
    .mt-xxl-4 {
      margin-top: 1rem !important;
    }
    .mt-xxl-5 {
      margin-top: 1.25rem !important;
    }
    .mt-xxl-6 {
      margin-top: 1.5rem !important;
    }
    .mt-xxl-7 {
      margin-top: 1.75rem !important;
    }
    .mt-xxl-8 {
      margin-top: 2rem !important;
    }
    .mt-xxl-10 {
      margin-top: 2.5rem !important;
    }
    .mt-xxl-12 {
      margin-top: 3rem !important;
    }
    .mt-xxl-14 {
      margin-top: 3.5rem !important;
    }
    .mt-xxl-16 {
      margin-top: 4rem !important;
    }
    .mt-xxl-18 {
      margin-top: 4.5rem !important;
    }
    .mt-xxl-20 {
      margin-top: 5rem !important;
    }
    .mt-xxl-24 {
      margin-top: 6rem !important;
    }
    .mt-xxl-32 {
      margin-top: 8rem !important;
    }
    .mt-xxl-40 {
      margin-top: 10rem !important;
    }
    .mt-xxl-48 {
      margin-top: 12rem !important;
    }
    .mt-xxl-56 {
      margin-top: 14rem !important;
    }
    .mt-xxl-64 {
      margin-top: 16rem !important;
    }
    .mt-xxl-72 {
      margin-top: 18rem !important;
    }
    .mt-xxl-80 {
      margin-top: 20rem !important;
    }
    .mt-xxl-88 {
      margin-top: 22rem !important;
    }
    .mt-xxl-96 {
      margin-top: 24rem !important;
    }
    .mt-xxl-px {
      margin-top: 1px !important;
    }
    .mt-xxl-auto {
      margin-top: auto !important;
    }
    .me-xxl-0 {
      margin-right: 0 !important;
    }
    .me-xxl-1 {
      margin-right: 0.25rem !important;
    }
    .me-xxl-2 {
      margin-right: 0.5rem !important;
    }
    .me-xxl-3 {
      margin-right: 0.75rem !important;
    }
    .me-xxl-4 {
      margin-right: 1rem !important;
    }
    .me-xxl-5 {
      margin-right: 1.25rem !important;
    }
    .me-xxl-6 {
      margin-right: 1.5rem !important;
    }
    .me-xxl-7 {
      margin-right: 1.75rem !important;
    }
    .me-xxl-8 {
      margin-right: 2rem !important;
    }
    .me-xxl-10 {
      margin-right: 2.5rem !important;
    }
    .me-xxl-12 {
      margin-right: 3rem !important;
    }
    .me-xxl-14 {
      margin-right: 3.5rem !important;
    }
    .me-xxl-16 {
      margin-right: 4rem !important;
    }
    .me-xxl-18 {
      margin-right: 4.5rem !important;
    }
    .me-xxl-20 {
      margin-right: 5rem !important;
    }
    .me-xxl-24 {
      margin-right: 6rem !important;
    }
    .me-xxl-32 {
      margin-right: 8rem !important;
    }
    .me-xxl-40 {
      margin-right: 10rem !important;
    }
    .me-xxl-48 {
      margin-right: 12rem !important;
    }
    .me-xxl-56 {
      margin-right: 14rem !important;
    }
    .me-xxl-64 {
      margin-right: 16rem !important;
    }
    .me-xxl-72 {
      margin-right: 18rem !important;
    }
    .me-xxl-80 {
      margin-right: 20rem !important;
    }
    .me-xxl-88 {
      margin-right: 22rem !important;
    }
    .me-xxl-96 {
      margin-right: 24rem !important;
    }
    .me-xxl-px {
      margin-right: 1px !important;
    }
    .me-xxl-auto {
      margin-right: auto !important;
    }
    .mb-xxl-0 {
      margin-bottom: 0 !important;
    }
    .mb-xxl-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-xxl-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-xxl-3 {
      margin-bottom: 0.75rem !important;
    }
    .mb-xxl-4 {
      margin-bottom: 1rem !important;
    }
    .mb-xxl-5 {
      margin-bottom: 1.25rem !important;
    }
    .mb-xxl-6 {
      margin-bottom: 1.5rem !important;
    }
    .mb-xxl-7 {
      margin-bottom: 1.75rem !important;
    }
    .mb-xxl-8 {
      margin-bottom: 2rem !important;
    }
    .mb-xxl-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-xxl-12 {
      margin-bottom: 3rem !important;
    }
    .mb-xxl-14 {
      margin-bottom: 3.5rem !important;
    }
    .mb-xxl-16 {
      margin-bottom: 4rem !important;
    }
    .mb-xxl-18 {
      margin-bottom: 4.5rem !important;
    }
    .mb-xxl-20 {
      margin-bottom: 5rem !important;
    }
    .mb-xxl-24 {
      margin-bottom: 6rem !important;
    }
    .mb-xxl-32 {
      margin-bottom: 8rem !important;
    }
    .mb-xxl-40 {
      margin-bottom: 10rem !important;
    }
    .mb-xxl-48 {
      margin-bottom: 12rem !important;
    }
    .mb-xxl-56 {
      margin-bottom: 14rem !important;
    }
    .mb-xxl-64 {
      margin-bottom: 16rem !important;
    }
    .mb-xxl-72 {
      margin-bottom: 18rem !important;
    }
    .mb-xxl-80 {
      margin-bottom: 20rem !important;
    }
    .mb-xxl-88 {
      margin-bottom: 22rem !important;
    }
    .mb-xxl-96 {
      margin-bottom: 24rem !important;
    }
    .mb-xxl-px {
      margin-bottom: 1px !important;
    }
    .mb-xxl-auto {
      margin-bottom: auto !important;
    }
    .ms-xxl-0 {
      margin-left: 0 !important;
    }
    .ms-xxl-1 {
      margin-left: 0.25rem !important;
    }
    .ms-xxl-2 {
      margin-left: 0.5rem !important;
    }
    .ms-xxl-3 {
      margin-left: 0.75rem !important;
    }
    .ms-xxl-4 {
      margin-left: 1rem !important;
    }
    .ms-xxl-5 {
      margin-left: 1.25rem !important;
    }
    .ms-xxl-6 {
      margin-left: 1.5rem !important;
    }
    .ms-xxl-7 {
      margin-left: 1.75rem !important;
    }
    .ms-xxl-8 {
      margin-left: 2rem !important;
    }
    .ms-xxl-10 {
      margin-left: 2.5rem !important;
    }
    .ms-xxl-12 {
      margin-left: 3rem !important;
    }
    .ms-xxl-14 {
      margin-left: 3.5rem !important;
    }
    .ms-xxl-16 {
      margin-left: 4rem !important;
    }
    .ms-xxl-18 {
      margin-left: 4.5rem !important;
    }
    .ms-xxl-20 {
      margin-left: 5rem !important;
    }
    .ms-xxl-24 {
      margin-left: 6rem !important;
    }
    .ms-xxl-32 {
      margin-left: 8rem !important;
    }
    .ms-xxl-40 {
      margin-left: 10rem !important;
    }
    .ms-xxl-48 {
      margin-left: 12rem !important;
    }
    .ms-xxl-56 {
      margin-left: 14rem !important;
    }
    .ms-xxl-64 {
      margin-left: 16rem !important;
    }
    .ms-xxl-72 {
      margin-left: 18rem !important;
    }
    .ms-xxl-80 {
      margin-left: 20rem !important;
    }
    .ms-xxl-88 {
      margin-left: 22rem !important;
    }
    .ms-xxl-96 {
      margin-left: 24rem !important;
    }
    .ms-xxl-px {
      margin-left: 1px !important;
    }
    .ms-xxl-auto {
      margin-left: auto !important;
    }
    .m-xxl-n1 {
      margin: -0.25rem !important;
    }
    .m-xxl-n2 {
      margin: -0.5rem !important;
    }
    .m-xxl-n3 {
      margin: -0.75rem !important;
    }
    .m-xxl-n4 {
      margin: -1rem !important;
    }
    .m-xxl-n5 {
      margin: -1.25rem !important;
    }
    .m-xxl-n6 {
      margin: -1.5rem !important;
    }
    .m-xxl-n7 {
      margin: -1.75rem !important;
    }
    .m-xxl-n8 {
      margin: -2rem !important;
    }
    .m-xxl-n10 {
      margin: -2.5rem !important;
    }
    .m-xxl-n12 {
      margin: -3rem !important;
    }
    .m-xxl-n14 {
      margin: -3.5rem !important;
    }
    .m-xxl-n16 {
      margin: -4rem !important;
    }
    .m-xxl-n18 {
      margin: -4.5rem !important;
    }
    .m-xxl-n20 {
      margin: -5rem !important;
    }
    .m-xxl-n24 {
      margin: -6rem !important;
    }
    .m-xxl-n32 {
      margin: -8rem !important;
    }
    .m-xxl-n40 {
      margin: -10rem !important;
    }
    .m-xxl-n48 {
      margin: -12rem !important;
    }
    .m-xxl-n56 {
      margin: -14rem !important;
    }
    .m-xxl-n64 {
      margin: -16rem !important;
    }
    .m-xxl-n72 {
      margin: -18rem !important;
    }
    .m-xxl-n80 {
      margin: -20rem !important;
    }
    .m-xxl-n88 {
      margin: -22rem !important;
    }
    .m-xxl-n96 {
      margin: -24rem !important;
    }
    .m-xxl-npx {
      margin: -1px !important;
    }
    .mx-xxl-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-xxl-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-xxl-n3 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-xxl-n4 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-xxl-n5 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-xxl-n6 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-xxl-n7 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-xxl-n8 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-xxl-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-xxl-n12 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-xxl-n14 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-xxl-n16 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-xxl-n18 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-xxl-n20 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-xxl-n24 {
      margin-right: -6rem !important;
      margin-left: -6rem !important;
    }
    .mx-xxl-n32 {
      margin-right: -8rem !important;
      margin-left: -8rem !important;
    }
    .mx-xxl-n40 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-xxl-n48 {
      margin-right: -12rem !important;
      margin-left: -12rem !important;
    }
    .mx-xxl-n56 {
      margin-right: -14rem !important;
      margin-left: -14rem !important;
    }
    .mx-xxl-n64 {
      margin-right: -16rem !important;
      margin-left: -16rem !important;
    }
    .mx-xxl-n72 {
      margin-right: -18rem !important;
      margin-left: -18rem !important;
    }
    .mx-xxl-n80 {
      margin-right: -20rem !important;
      margin-left: -20rem !important;
    }
    .mx-xxl-n88 {
      margin-right: -22rem !important;
      margin-left: -22rem !important;
    }
    .mx-xxl-n96 {
      margin-right: -24rem !important;
      margin-left: -24rem !important;
    }
    .mx-xxl-npx {
      margin-right: -1px !important;
      margin-left: -1px !important;
    }
    .my-xxl-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-xxl-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-xxl-n3 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-xxl-n4 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-xxl-n5 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-xxl-n6 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-xxl-n7 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-xxl-n8 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-xxl-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-xxl-n12 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-xxl-n14 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-xxl-n16 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-xxl-n18 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-xxl-n20 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-xxl-n24 {
      margin-top: -6rem !important;
      margin-bottom: -6rem !important;
    }
    .my-xxl-n32 {
      margin-top: -8rem !important;
      margin-bottom: -8rem !important;
    }
    .my-xxl-n40 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-xxl-n48 {
      margin-top: -12rem !important;
      margin-bottom: -12rem !important;
    }
    .my-xxl-n56 {
      margin-top: -14rem !important;
      margin-bottom: -14rem !important;
    }
    .my-xxl-n64 {
      margin-top: -16rem !important;
      margin-bottom: -16rem !important;
    }
    .my-xxl-n72 {
      margin-top: -18rem !important;
      margin-bottom: -18rem !important;
    }
    .my-xxl-n80 {
      margin-top: -20rem !important;
      margin-bottom: -20rem !important;
    }
    .my-xxl-n88 {
      margin-top: -22rem !important;
      margin-bottom: -22rem !important;
    }
    .my-xxl-n96 {
      margin-top: -24rem !important;
      margin-bottom: -24rem !important;
    }
    .my-xxl-npx {
      margin-top: -1px !important;
      margin-bottom: -1px !important;
    }
    .mt-xxl-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-xxl-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-xxl-n3 {
      margin-top: -0.75rem !important;
    }
    .mt-xxl-n4 {
      margin-top: -1rem !important;
    }
    .mt-xxl-n5 {
      margin-top: -1.25rem !important;
    }
    .mt-xxl-n6 {
      margin-top: -1.5rem !important;
    }
    .mt-xxl-n7 {
      margin-top: -1.75rem !important;
    }
    .mt-xxl-n8 {
      margin-top: -2rem !important;
    }
    .mt-xxl-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-xxl-n12 {
      margin-top: -3rem !important;
    }
    .mt-xxl-n14 {
      margin-top: -3.5rem !important;
    }
    .mt-xxl-n16 {
      margin-top: -4rem !important;
    }
    .mt-xxl-n18 {
      margin-top: -4.5rem !important;
    }
    .mt-xxl-n20 {
      margin-top: -5rem !important;
    }
    .mt-xxl-n24 {
      margin-top: -6rem !important;
    }
    .mt-xxl-n32 {
      margin-top: -8rem !important;
    }
    .mt-xxl-n40 {
      margin-top: -10rem !important;
    }
    .mt-xxl-n48 {
      margin-top: -12rem !important;
    }
    .mt-xxl-n56 {
      margin-top: -14rem !important;
    }
    .mt-xxl-n64 {
      margin-top: -16rem !important;
    }
    .mt-xxl-n72 {
      margin-top: -18rem !important;
    }
    .mt-xxl-n80 {
      margin-top: -20rem !important;
    }
    .mt-xxl-n88 {
      margin-top: -22rem !important;
    }
    .mt-xxl-n96 {
      margin-top: -24rem !important;
    }
    .mt-xxl-npx {
      margin-top: -1px !important;
    }
    .me-xxl-n1 {
      margin-right: -0.25rem !important;
    }
    .me-xxl-n2 {
      margin-right: -0.5rem !important;
    }
    .me-xxl-n3 {
      margin-right: -0.75rem !important;
    }
    .me-xxl-n4 {
      margin-right: -1rem !important;
    }
    .me-xxl-n5 {
      margin-right: -1.25rem !important;
    }
    .me-xxl-n6 {
      margin-right: -1.5rem !important;
    }
    .me-xxl-n7 {
      margin-right: -1.75rem !important;
    }
    .me-xxl-n8 {
      margin-right: -2rem !important;
    }
    .me-xxl-n10 {
      margin-right: -2.5rem !important;
    }
    .me-xxl-n12 {
      margin-right: -3rem !important;
    }
    .me-xxl-n14 {
      margin-right: -3.5rem !important;
    }
    .me-xxl-n16 {
      margin-right: -4rem !important;
    }
    .me-xxl-n18 {
      margin-right: -4.5rem !important;
    }
    .me-xxl-n20 {
      margin-right: -5rem !important;
    }
    .me-xxl-n24 {
      margin-right: -6rem !important;
    }
    .me-xxl-n32 {
      margin-right: -8rem !important;
    }
    .me-xxl-n40 {
      margin-right: -10rem !important;
    }
    .me-xxl-n48 {
      margin-right: -12rem !important;
    }
    .me-xxl-n56 {
      margin-right: -14rem !important;
    }
    .me-xxl-n64 {
      margin-right: -16rem !important;
    }
    .me-xxl-n72 {
      margin-right: -18rem !important;
    }
    .me-xxl-n80 {
      margin-right: -20rem !important;
    }
    .me-xxl-n88 {
      margin-right: -22rem !important;
    }
    .me-xxl-n96 {
      margin-right: -24rem !important;
    }
    .me-xxl-npx {
      margin-right: -1px !important;
    }
    .mb-xxl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-xxl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-xxl-n3 {
      margin-bottom: -0.75rem !important;
    }
    .mb-xxl-n4 {
      margin-bottom: -1rem !important;
    }
    .mb-xxl-n5 {
      margin-bottom: -1.25rem !important;
    }
    .mb-xxl-n6 {
      margin-bottom: -1.5rem !important;
    }
    .mb-xxl-n7 {
      margin-bottom: -1.75rem !important;
    }
    .mb-xxl-n8 {
      margin-bottom: -2rem !important;
    }
    .mb-xxl-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-xxl-n12 {
      margin-bottom: -3rem !important;
    }
    .mb-xxl-n14 {
      margin-bottom: -3.5rem !important;
    }
    .mb-xxl-n16 {
      margin-bottom: -4rem !important;
    }
    .mb-xxl-n18 {
      margin-bottom: -4.5rem !important;
    }
    .mb-xxl-n20 {
      margin-bottom: -5rem !important;
    }
    .mb-xxl-n24 {
      margin-bottom: -6rem !important;
    }
    .mb-xxl-n32 {
      margin-bottom: -8rem !important;
    }
    .mb-xxl-n40 {
      margin-bottom: -10rem !important;
    }
    .mb-xxl-n48 {
      margin-bottom: -12rem !important;
    }
    .mb-xxl-n56 {
      margin-bottom: -14rem !important;
    }
    .mb-xxl-n64 {
      margin-bottom: -16rem !important;
    }
    .mb-xxl-n72 {
      margin-bottom: -18rem !important;
    }
    .mb-xxl-n80 {
      margin-bottom: -20rem !important;
    }
    .mb-xxl-n88 {
      margin-bottom: -22rem !important;
    }
    .mb-xxl-n96 {
      margin-bottom: -24rem !important;
    }
    .mb-xxl-npx {
      margin-bottom: -1px !important;
    }
    .ms-xxl-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-xxl-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-xxl-n3 {
      margin-left: -0.75rem !important;
    }
    .ms-xxl-n4 {
      margin-left: -1rem !important;
    }
    .ms-xxl-n5 {
      margin-left: -1.25rem !important;
    }
    .ms-xxl-n6 {
      margin-left: -1.5rem !important;
    }
    .ms-xxl-n7 {
      margin-left: -1.75rem !important;
    }
    .ms-xxl-n8 {
      margin-left: -2rem !important;
    }
    .ms-xxl-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-xxl-n12 {
      margin-left: -3rem !important;
    }
    .ms-xxl-n14 {
      margin-left: -3.5rem !important;
    }
    .ms-xxl-n16 {
      margin-left: -4rem !important;
    }
    .ms-xxl-n18 {
      margin-left: -4.5rem !important;
    }
    .ms-xxl-n20 {
      margin-left: -5rem !important;
    }
    .ms-xxl-n24 {
      margin-left: -6rem !important;
    }
    .ms-xxl-n32 {
      margin-left: -8rem !important;
    }
    .ms-xxl-n40 {
      margin-left: -10rem !important;
    }
    .ms-xxl-n48 {
      margin-left: -12rem !important;
    }
    .ms-xxl-n56 {
      margin-left: -14rem !important;
    }
    .ms-xxl-n64 {
      margin-left: -16rem !important;
    }
    .ms-xxl-n72 {
      margin-left: -18rem !important;
    }
    .ms-xxl-n80 {
      margin-left: -20rem !important;
    }
    .ms-xxl-n88 {
      margin-left: -22rem !important;
    }
    .ms-xxl-n96 {
      margin-left: -24rem !important;
    }
    .ms-xxl-npx {
      margin-left: -1px !important;
    }
    .p-xxl-0 {
      padding: 0 !important;
    }
    .p-xxl-1 {
      padding: 0.25rem !important;
    }
    .p-xxl-2 {
      padding: 0.5rem !important;
    }
    .p-xxl-3 {
      padding: 0.75rem !important;
    }
    .p-xxl-4 {
      padding: 1rem !important;
    }
    .p-xxl-5 {
      padding: 1.25rem !important;
    }
    .p-xxl-6 {
      padding: 1.5rem !important;
    }
    .p-xxl-7 {
      padding: 1.75rem !important;
    }
    .p-xxl-8 {
      padding: 2rem !important;
    }
    .p-xxl-10 {
      padding: 2.5rem !important;
    }
    .p-xxl-12 {
      padding: 3rem !important;
    }
    .p-xxl-14 {
      padding: 3.5rem !important;
    }
    .p-xxl-16 {
      padding: 4rem !important;
    }
    .p-xxl-18 {
      padding: 4.5rem !important;
    }
    .p-xxl-20 {
      padding: 5rem !important;
    }
    .p-xxl-24 {
      padding: 6rem !important;
    }
    .p-xxl-32 {
      padding: 8rem !important;
    }
    .p-xxl-40 {
      padding: 10rem !important;
    }
    .p-xxl-48 {
      padding: 12rem !important;
    }
    .p-xxl-56 {
      padding: 14rem !important;
    }
    .p-xxl-64 {
      padding: 16rem !important;
    }
    .p-xxl-72 {
      padding: 18rem !important;
    }
    .p-xxl-80 {
      padding: 20rem !important;
    }
    .p-xxl-88 {
      padding: 22rem !important;
    }
    .p-xxl-96 {
      padding: 24rem !important;
    }
    .p-xxl-px {
      padding: 1px !important;
    }
    .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-xxl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-xxl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-xxl-3 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-xxl-4 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-xxl-5 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-xxl-6 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-xxl-7 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-xxl-8 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-xxl-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-xxl-12 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-xxl-14 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-xxl-16 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-xxl-18 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-xxl-20 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-xxl-24 {
      padding-right: 6rem !important;
      padding-left: 6rem !important;
    }
    .px-xxl-32 {
      padding-right: 8rem !important;
      padding-left: 8rem !important;
    }
    .px-xxl-40 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-xxl-48 {
      padding-right: 12rem !important;
      padding-left: 12rem !important;
    }
    .px-xxl-56 {
      padding-right: 14rem !important;
      padding-left: 14rem !important;
    }
    .px-xxl-64 {
      padding-right: 16rem !important;
      padding-left: 16rem !important;
    }
    .px-xxl-72 {
      padding-right: 18rem !important;
      padding-left: 18rem !important;
    }
    .px-xxl-80 {
      padding-right: 20rem !important;
      padding-left: 20rem !important;
    }
    .px-xxl-88 {
      padding-right: 22rem !important;
      padding-left: 22rem !important;
    }
    .px-xxl-96 {
      padding-right: 24rem !important;
      padding-left: 24rem !important;
    }
    .px-xxl-px {
      padding-right: 1px !important;
      padding-left: 1px !important;
    }
    .py-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-xxl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-xxl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-xxl-3 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-xxl-4 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-xxl-5 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-xxl-6 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-xxl-7 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-xxl-8 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-xxl-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-xxl-12 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-xxl-14 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-xxl-16 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-xxl-18 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-xxl-20 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-xxl-24 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important;
    }
    .py-xxl-32 {
      padding-top: 8rem !important;
      padding-bottom: 8rem !important;
    }
    .py-xxl-40 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-xxl-48 {
      padding-top: 12rem !important;
      padding-bottom: 12rem !important;
    }
    .py-xxl-56 {
      padding-top: 14rem !important;
      padding-bottom: 14rem !important;
    }
    .py-xxl-64 {
      padding-top: 16rem !important;
      padding-bottom: 16rem !important;
    }
    .py-xxl-72 {
      padding-top: 18rem !important;
      padding-bottom: 18rem !important;
    }
    .py-xxl-80 {
      padding-top: 20rem !important;
      padding-bottom: 20rem !important;
    }
    .py-xxl-88 {
      padding-top: 22rem !important;
      padding-bottom: 22rem !important;
    }
    .py-xxl-96 {
      padding-top: 24rem !important;
      padding-bottom: 24rem !important;
    }
    .py-xxl-px {
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }
    .pt-xxl-0 {
      padding-top: 0 !important;
    }
    .pt-xxl-1 {
      padding-top: 0.25rem !important;
    }
    .pt-xxl-2 {
      padding-top: 0.5rem !important;
    }
    .pt-xxl-3 {
      padding-top: 0.75rem !important;
    }
    .pt-xxl-4 {
      padding-top: 1rem !important;
    }
    .pt-xxl-5 {
      padding-top: 1.25rem !important;
    }
    .pt-xxl-6 {
      padding-top: 1.5rem !important;
    }
    .pt-xxl-7 {
      padding-top: 1.75rem !important;
    }
    .pt-xxl-8 {
      padding-top: 2rem !important;
    }
    .pt-xxl-10 {
      padding-top: 2.5rem !important;
    }
    .pt-xxl-12 {
      padding-top: 3rem !important;
    }
    .pt-xxl-14 {
      padding-top: 3.5rem !important;
    }
    .pt-xxl-16 {
      padding-top: 4rem !important;
    }
    .pt-xxl-18 {
      padding-top: 4.5rem !important;
    }
    .pt-xxl-20 {
      padding-top: 5rem !important;
    }
    .pt-xxl-24 {
      padding-top: 6rem !important;
    }
    .pt-xxl-32 {
      padding-top: 8rem !important;
    }
    .pt-xxl-40 {
      padding-top: 10rem !important;
    }
    .pt-xxl-48 {
      padding-top: 12rem !important;
    }
    .pt-xxl-56 {
      padding-top: 14rem !important;
    }
    .pt-xxl-64 {
      padding-top: 16rem !important;
    }
    .pt-xxl-72 {
      padding-top: 18rem !important;
    }
    .pt-xxl-80 {
      padding-top: 20rem !important;
    }
    .pt-xxl-88 {
      padding-top: 22rem !important;
    }
    .pt-xxl-96 {
      padding-top: 24rem !important;
    }
    .pt-xxl-px {
      padding-top: 1px !important;
    }
    .pe-xxl-0 {
      padding-right: 0 !important;
    }
    .pe-xxl-1 {
      padding-right: 0.25rem !important;
    }
    .pe-xxl-2 {
      padding-right: 0.5rem !important;
    }
    .pe-xxl-3 {
      padding-right: 0.75rem !important;
    }
    .pe-xxl-4 {
      padding-right: 1rem !important;
    }
    .pe-xxl-5 {
      padding-right: 1.25rem !important;
    }
    .pe-xxl-6 {
      padding-right: 1.5rem !important;
    }
    .pe-xxl-7 {
      padding-right: 1.75rem !important;
    }
    .pe-xxl-8 {
      padding-right: 2rem !important;
    }
    .pe-xxl-10 {
      padding-right: 2.5rem !important;
    }
    .pe-xxl-12 {
      padding-right: 3rem !important;
    }
    .pe-xxl-14 {
      padding-right: 3.5rem !important;
    }
    .pe-xxl-16 {
      padding-right: 4rem !important;
    }
    .pe-xxl-18 {
      padding-right: 4.5rem !important;
    }
    .pe-xxl-20 {
      padding-right: 5rem !important;
    }
    .pe-xxl-24 {
      padding-right: 6rem !important;
    }
    .pe-xxl-32 {
      padding-right: 8rem !important;
    }
    .pe-xxl-40 {
      padding-right: 10rem !important;
    }
    .pe-xxl-48 {
      padding-right: 12rem !important;
    }
    .pe-xxl-56 {
      padding-right: 14rem !important;
    }
    .pe-xxl-64 {
      padding-right: 16rem !important;
    }
    .pe-xxl-72 {
      padding-right: 18rem !important;
    }
    .pe-xxl-80 {
      padding-right: 20rem !important;
    }
    .pe-xxl-88 {
      padding-right: 22rem !important;
    }
    .pe-xxl-96 {
      padding-right: 24rem !important;
    }
    .pe-xxl-px {
      padding-right: 1px !important;
    }
    .pb-xxl-0 {
      padding-bottom: 0 !important;
    }
    .pb-xxl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-xxl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-xxl-3 {
      padding-bottom: 0.75rem !important;
    }
    .pb-xxl-4 {
      padding-bottom: 1rem !important;
    }
    .pb-xxl-5 {
      padding-bottom: 1.25rem !important;
    }
    .pb-xxl-6 {
      padding-bottom: 1.5rem !important;
    }
    .pb-xxl-7 {
      padding-bottom: 1.75rem !important;
    }
    .pb-xxl-8 {
      padding-bottom: 2rem !important;
    }
    .pb-xxl-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-xxl-12 {
      padding-bottom: 3rem !important;
    }
    .pb-xxl-14 {
      padding-bottom: 3.5rem !important;
    }
    .pb-xxl-16 {
      padding-bottom: 4rem !important;
    }
    .pb-xxl-18 {
      padding-bottom: 4.5rem !important;
    }
    .pb-xxl-20 {
      padding-bottom: 5rem !important;
    }
    .pb-xxl-24 {
      padding-bottom: 6rem !important;
    }
    .pb-xxl-32 {
      padding-bottom: 8rem !important;
    }
    .pb-xxl-40 {
      padding-bottom: 10rem !important;
    }
    .pb-xxl-48 {
      padding-bottom: 12rem !important;
    }
    .pb-xxl-56 {
      padding-bottom: 14rem !important;
    }
    .pb-xxl-64 {
      padding-bottom: 16rem !important;
    }
    .pb-xxl-72 {
      padding-bottom: 18rem !important;
    }
    .pb-xxl-80 {
      padding-bottom: 20rem !important;
    }
    .pb-xxl-88 {
      padding-bottom: 22rem !important;
    }
    .pb-xxl-96 {
      padding-bottom: 24rem !important;
    }
    .pb-xxl-px {
      padding-bottom: 1px !important;
    }
    .ps-xxl-0 {
      padding-left: 0 !important;
    }
    .ps-xxl-1 {
      padding-left: 0.25rem !important;
    }
    .ps-xxl-2 {
      padding-left: 0.5rem !important;
    }
    .ps-xxl-3 {
      padding-left: 0.75rem !important;
    }
    .ps-xxl-4 {
      padding-left: 1rem !important;
    }
    .ps-xxl-5 {
      padding-left: 1.25rem !important;
    }
    .ps-xxl-6 {
      padding-left: 1.5rem !important;
    }
    .ps-xxl-7 {
      padding-left: 1.75rem !important;
    }
    .ps-xxl-8 {
      padding-left: 2rem !important;
    }
    .ps-xxl-10 {
      padding-left: 2.5rem !important;
    }
    .ps-xxl-12 {
      padding-left: 3rem !important;
    }
    .ps-xxl-14 {
      padding-left: 3.5rem !important;
    }
    .ps-xxl-16 {
      padding-left: 4rem !important;
    }
    .ps-xxl-18 {
      padding-left: 4.5rem !important;
    }
    .ps-xxl-20 {
      padding-left: 5rem !important;
    }
    .ps-xxl-24 {
      padding-left: 6rem !important;
    }
    .ps-xxl-32 {
      padding-left: 8rem !important;
    }
    .ps-xxl-40 {
      padding-left: 10rem !important;
    }
    .ps-xxl-48 {
      padding-left: 12rem !important;
    }
    .ps-xxl-56 {
      padding-left: 14rem !important;
    }
    .ps-xxl-64 {
      padding-left: 16rem !important;
    }
    .ps-xxl-72 {
      padding-left: 18rem !important;
    }
    .ps-xxl-80 {
      padding-left: 20rem !important;
    }
    .ps-xxl-88 {
      padding-left: 22rem !important;
    }
    .ps-xxl-96 {
      padding-left: 24rem !important;
    }
    .ps-xxl-px {
      padding-left: 1px !important;
    }
    .font-xxl-italic {
      font-style: italic !important;
    }
    .font-xxl-normal {
      font-style: normal !important;
    }
    .font-xxl-light {
      font-weight: 300 !important;
    }
    .font-xxl-lighter {
      font-weight: lighter !important;
    }
    .font-xxl-regular {
      font-weight: 400 !important;
    }
    .font-xxl-semibold {
      font-weight: 500 !important;
    }
    .font-xxl-bold {
      font-weight: 600 !important;
    }
    .font-xxl-bolder {
      font-weight: bolder !important;
    }
    .lh-xxl-none {
      line-height: 1 !important;
    }
    .lh-xxl-tighter {
      line-height: 1.125 !important;
    }
    .lh-xxl-tight {
      line-height: 1.2 !important;
    }
    .lh-xxl-snug {
      line-height: 1.375 !important;
    }
    .lh-xxl-normal {
      line-height: 1.5 !important;
    }
    .lh-xxl-relaxed {
      line-height: 1.625 !important;
    }
    .lh-xxl-loose {
      line-height: 2 !important;
    }
    .text-xxl-start {
      text-align: left !important;
    }
    .text-xxl-end {
      text-align: right !important;
    }
    .text-xxl-center {
      text-align: center !important;
    }
    .text-xxl-no-decoration {
      text-decoration: none !important;
    }
    .text-xxl-no-decoration-hover:hover {
      text-decoration: none !important;
    }
    .text-xxl-underline {
      text-decoration: underline !important;
    }
    .text-xxl-underline-hover:hover {
      text-decoration: underline !important;
    }
    .text-xxl-line-through {
      text-decoration: line-through !important;
    }
    .text-xxl-line-through-hover:hover {
      text-decoration: line-through !important;
    }
    .text-xxl-lowercase {
      text-transform: lowercase !important;
    }
    .text-xxl-lowercase-hover:hover {
      text-transform: lowercase !important;
    }
    .text-xxl-uppercase {
      text-transform: uppercase !important;
    }
    .text-xxl-uppercase-hover:hover {
      text-transform: uppercase !important;
    }
    .text-xxl-capitalize {
      text-transform: capitalize !important;
    }
    .text-xxl-capitalize-hover:hover {
      text-transform: capitalize !important;
    }
    .text-xxl-primary {
      --x-text-opacity: 1;
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-primary-hover:hover {
      color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-secondary {
      --x-text-opacity: 1;
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-secondary-hover:hover {
      color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-tertiary {
      --x-text-opacity: 1;
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-tertiary-hover:hover {
      color: rgba(var(--x-tertiary-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-success {
      --x-text-opacity: 1;
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-success-hover:hover {
      color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-info {
      --x-text-opacity: 1;
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-info-hover:hover {
      color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-warning {
      --x-text-opacity: 1;
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-warning-hover:hover {
      color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-danger {
      --x-text-opacity: 1;
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-danger-hover:hover {
      color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-white {
      --x-text-opacity: 1;
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-white-hover:hover {
      color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-light {
      --x-text-opacity: 1;
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-light-hover:hover {
      color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-dark {
      --x-text-opacity: 1;
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-dark-hover:hover {
      color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-black {
      --x-text-opacity: 1;
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-black-hover:hover {
      color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-body {
      --x-text-opacity: 1;
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-body-hover:hover {
      color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-muted {
      --x-text-opacity: 1;
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-muted-hover:hover {
      color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-heading {
      --x-text-opacity: 1;
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-heading-hover:hover {
      color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-current {
      --x-text-opacity: 1;
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-current-hover:hover {
      color: rgba(var(--x-current-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-reset {
      --x-text-opacity: 1;
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .text-xxl-reset-hover:hover {
      color: rgba(var(--x-reset-rgb), var(--x-text-opacity)) !important;
    }
    .bg-xxl-primary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-primary-hover:hover {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-primary-focus:focus {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-primary-focus-within:focus-within {
      background-color: rgba(
        var(--x-primary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-secondary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-secondary-hover:hover {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-secondary-focus:focus {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-secondary-focus-within:focus-within {
      background-color: rgba(
        var(--x-secondary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-tertiary {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-tertiary-hover:hover {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-tertiary-focus:focus {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-tertiary-focus-within:focus-within {
      background-color: rgba(
        var(--x-tertiary-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-success {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-success-hover:hover {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-success-focus:focus {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-success-focus-within:focus-within {
      background-color: rgba(
        var(--x-success-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-info {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-info-hover:hover {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-info-focus:focus {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-info-focus-within:focus-within {
      background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-warning {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-warning-hover:hover {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-warning-focus:focus {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-warning-focus-within:focus-within {
      background-color: rgba(
        var(--x-warning-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-danger {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-danger-hover:hover {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-danger-focus:focus {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-danger-focus-within:focus-within {
      background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-white {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-white-hover:hover {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-white-focus:focus {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-white-focus-within:focus-within {
      background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-light {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-light-hover:hover {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-light-focus:focus {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-light-focus-within:focus-within {
      background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-dark {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-dark-hover:hover {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-dark-focus:focus {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-dark-focus-within:focus-within {
      background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-body {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-body-hover:hover {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-body-focus:focus {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-body-focus-within:focus-within {
      background-color: rgba(
        var(--x-body-bg-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-black {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-black-hover:hover {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-black-focus:focus {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-black-focus-within:focus-within {
      background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-card {
      --x-bg-opacity: 1;
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-card-hover:hover {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-card-focus:focus {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-card-focus-within:focus-within {
      background-color: rgba(var(--x-card-rgb), var(--x-bg-opacity)) !important;
    }
    .bg-xxl-transparent {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-transparent-hover:hover {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-transparent-focus:focus {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-transparent-focus-within:focus-within {
      background-color: rgba(
        var(--x-transparent-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-current {
      --x-bg-opacity: 1;
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-current-hover:hover {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-current-focus:focus {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .bg-xxl-current-focus-within:focus-within {
      background-color: rgba(
        var(--x-current-rgb),
        var(--x-bg-opacity)
      ) !important;
    }
    .rounded-xxl {
      border-radius: 0.375rem !important;
    }
    .rounded-xxl-0 {
      border-radius: 0 !important;
    }
    .rounded-xxl-1 {
      border-radius: 0.25rem !important;
    }
    .rounded-xxl-2 {
      border-radius: 0.5rem !important;
    }
    .rounded-xxl-3 {
      border-radius: 0.75rem !important;
    }
    .rounded-xxl-4 {
      border-radius: 1rem !important;
    }
    .rounded-xxl-5 {
      border-radius: 1.5rem !important;
    }
    .rounded-xxl-6 {
      border-radius: 3rem !important;
    }
    .rounded-xxl-7 {
      border-radius: 4rem !important;
    }
    .rounded-xxl-8 {
      border-radius: 5rem !important;
    }
    .rounded-xxl-9 {
      border-radius: 6rem !important;
    }
    .rounded-xxl-10 {
      border-radius: 7rem !important;
    }
    .rounded-xxl-pill {
      border-radius: 50rem !important;
    }
    .rounded-xxl-circle {
      border-radius: 50% !important;
    }
    .rounded-xxl-card {
      border-radius: 0.75rem !important;
    }
    .rounded-top-xxl {
      border-top-left-radius: 0.375rem !important;
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-xxl-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-top-xxl-1 {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-xxl-2 {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-xxl-3 {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-xxl-4 {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-xxl-5 {
      border-top-left-radius: 1.5rem !important;
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-xxl-6 {
      border-top-left-radius: 3rem !important;
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-xxl-7 {
      border-top-left-radius: 4rem !important;
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-xxl-8 {
      border-top-left-radius: 5rem !important;
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-xxl-9 {
      border-top-left-radius: 6rem !important;
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-xxl-10 {
      border-top-left-radius: 7rem !important;
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-xxl-pill {
      border-top-left-radius: 50rem !important;
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-xxl-circle {
      border-top-left-radius: 50% !important;
      border-top-right-radius: 50% !important;
    }
    .rounded-top-xxl-card {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-end-xxl {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-end-xxl-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-end-xxl-1 {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-end-xxl-2 {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-end-xxl-3 {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-end-xxl-4 {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-end-xxl-5 {
      border-top-right-radius: 1.5rem !important;
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-end-xxl-6 {
      border-top-right-radius: 3rem !important;
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-end-xxl-7 {
      border-top-right-radius: 4rem !important;
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-end-xxl-8 {
      border-top-right-radius: 5rem !important;
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-end-xxl-9 {
      border-top-right-radius: 6rem !important;
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-end-xxl-10 {
      border-top-right-radius: 7rem !important;
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-end-xxl-pill {
      border-top-right-radius: 50rem !important;
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-end-xxl-circle {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .rounded-end-xxl-card {
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-xxl {
      border-bottom-right-radius: 0.375rem !important;
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-xxl-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-xxl-1 {
      border-bottom-right-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-xxl-2 {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-xxl-3 {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-xxl-4 {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-xxl-5 {
      border-bottom-right-radius: 1.5rem !important;
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-xxl-6 {
      border-bottom-right-radius: 3rem !important;
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-xxl-7 {
      border-bottom-right-radius: 4rem !important;
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-xxl-8 {
      border-bottom-right-radius: 5rem !important;
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-xxl-9 {
      border-bottom-right-radius: 6rem !important;
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-xxl-10 {
      border-bottom-right-radius: 7rem !important;
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-xxl-pill {
      border-bottom-right-radius: 50rem !important;
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-xxl-circle {
      border-bottom-right-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-xxl-card {
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-start-xxl {
      border-bottom-left-radius: 0.375rem !important;
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-start-xxl-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .rounded-start-xxl-1 {
      border-bottom-left-radius: 0.25rem !important;
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-start-xxl-2 {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-start-xxl-3 {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-start-xxl-4 {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-start-xxl-5 {
      border-bottom-left-radius: 1.5rem !important;
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-start-xxl-6 {
      border-bottom-left-radius: 3rem !important;
      border-top-left-radius: 3rem !important;
    }
    .rounded-start-xxl-7 {
      border-bottom-left-radius: 4rem !important;
      border-top-left-radius: 4rem !important;
    }
    .rounded-start-xxl-8 {
      border-bottom-left-radius: 5rem !important;
      border-top-left-radius: 5rem !important;
    }
    .rounded-start-xxl-9 {
      border-bottom-left-radius: 6rem !important;
      border-top-left-radius: 6rem !important;
    }
    .rounded-start-xxl-10 {
      border-bottom-left-radius: 7rem !important;
      border-top-left-radius: 7rem !important;
    }
    .rounded-start-xxl-pill {
      border-bottom-left-radius: 50rem !important;
      border-top-left-radius: 50rem !important;
    }
    .rounded-start-xxl-circle {
      border-bottom-left-radius: 50% !important;
      border-top-left-radius: 50% !important;
    }
    .rounded-start-xxl-card {
      border-bottom-left-radius: 0.75rem !important;
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-xxl {
      border-top-left-radius: 0.375rem !important;
    }
    .rounded-top-start-xxl-0 {
      border-top-left-radius: 0 !important;
    }
    .rounded-top-start-xxl-1 {
      border-top-left-radius: 0.25rem !important;
    }
    .rounded-top-start-xxl-2 {
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-top-start-xxl-3 {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-start-xxl-4 {
      border-top-left-radius: 1rem !important;
    }
    .rounded-top-start-xxl-5 {
      border-top-left-radius: 1.5rem !important;
    }
    .rounded-top-start-xxl-6 {
      border-top-left-radius: 3rem !important;
    }
    .rounded-top-start-xxl-7 {
      border-top-left-radius: 4rem !important;
    }
    .rounded-top-start-xxl-8 {
      border-top-left-radius: 5rem !important;
    }
    .rounded-top-start-xxl-9 {
      border-top-left-radius: 6rem !important;
    }
    .rounded-top-start-xxl-10 {
      border-top-left-radius: 7rem !important;
    }
    .rounded-top-start-xxl-pill {
      border-top-left-radius: 50rem !important;
    }
    .rounded-top-start-xxl-circle {
      border-top-left-radius: 50% !important;
    }
    .rounded-top-start-xxl-card {
      border-top-left-radius: 0.75rem !important;
    }
    .rounded-top-end-xxl {
      border-top-right-radius: 0.375rem !important;
    }
    .rounded-top-end-xxl-0 {
      border-top-right-radius: 0 !important;
    }
    .rounded-top-end-xxl-1 {
      border-top-right-radius: 0.25rem !important;
    }
    .rounded-top-end-xxl-2 {
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-top-end-xxl-3 {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-top-end-xxl-4 {
      border-top-right-radius: 1rem !important;
    }
    .rounded-top-end-xxl-5 {
      border-top-right-radius: 1.5rem !important;
    }
    .rounded-top-end-xxl-6 {
      border-top-right-radius: 3rem !important;
    }
    .rounded-top-end-xxl-7 {
      border-top-right-radius: 4rem !important;
    }
    .rounded-top-end-xxl-8 {
      border-top-right-radius: 5rem !important;
    }
    .rounded-top-end-xxl-9 {
      border-top-right-radius: 6rem !important;
    }
    .rounded-top-end-xxl-10 {
      border-top-right-radius: 7rem !important;
    }
    .rounded-top-end-xxl-pill {
      border-top-right-radius: 50rem !important;
    }
    .rounded-top-end-xxl-circle {
      border-top-right-radius: 50% !important;
    }
    .rounded-top-end-xxl-card {
      border-top-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-xxl {
      border-bottom-right-radius: 0.375rem !important;
    }
    .rounded-bottom-end-xxl-0 {
      border-bottom-right-radius: 0 !important;
    }
    .rounded-bottom-end-xxl-1 {
      border-bottom-right-radius: 0.25rem !important;
    }
    .rounded-bottom-end-xxl-2 {
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-bottom-end-xxl-3 {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-end-xxl-4 {
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-bottom-end-xxl-5 {
      border-bottom-right-radius: 1.5rem !important;
    }
    .rounded-bottom-end-xxl-6 {
      border-bottom-right-radius: 3rem !important;
    }
    .rounded-bottom-end-xxl-7 {
      border-bottom-right-radius: 4rem !important;
    }
    .rounded-bottom-end-xxl-8 {
      border-bottom-right-radius: 5rem !important;
    }
    .rounded-bottom-end-xxl-9 {
      border-bottom-right-radius: 6rem !important;
    }
    .rounded-bottom-end-xxl-10 {
      border-bottom-right-radius: 7rem !important;
    }
    .rounded-bottom-end-xxl-pill {
      border-bottom-right-radius: 50rem !important;
    }
    .rounded-bottom-end-xxl-circle {
      border-bottom-right-radius: 50% !important;
    }
    .rounded-bottom-end-xxl-card {
      border-bottom-right-radius: 0.75rem !important;
    }
    .rounded-bottom-start-xxl {
      border-bottom-left-radius: 0.375rem !important;
    }
    .rounded-bottom-start-xxl-0 {
      border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-start-xxl-1 {
      border-bottom-left-radius: 0.25rem !important;
    }
    .rounded-bottom-start-xxl-2 {
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-bottom-start-xxl-3 {
      border-bottom-left-radius: 0.75rem !important;
    }
    .rounded-bottom-start-xxl-4 {
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-bottom-start-xxl-5 {
      border-bottom-left-radius: 1.5rem !important;
    }
    .rounded-bottom-start-xxl-6 {
      border-bottom-left-radius: 3rem !important;
    }
    .rounded-bottom-start-xxl-7 {
      border-bottom-left-radius: 4rem !important;
    }
    .rounded-bottom-start-xxl-8 {
      border-bottom-left-radius: 5rem !important;
    }
    .rounded-bottom-start-xxl-9 {
      border-bottom-left-radius: 6rem !important;
    }
    .rounded-bottom-start-xxl-10 {
      border-bottom-left-radius: 7rem !important;
    }
    .rounded-bottom-start-xxl-pill {
      border-bottom-left-radius: 50rem !important;
    }
    .rounded-bottom-start-xxl-circle {
      border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-start-xxl-card {
      border-bottom-left-radius: 0.75rem !important;
    }
    .border-xxl-solid {
      border-style: solid !important;
    }
    .border-xxl-dashed {
      border-style: dashed !important;
    }
    .border-xxl-dotted {
      border-style: dotted !important;
    }
    .border-xxl-double {
      border-style: double !important;
    }
    .border-xxl-groove {
      border-style: groove !important;
    }
    .border-xxl-none {
      border-style: none !important;
    }
    .cursor-xxl-auto {
      cursor: auto !important;
    }
    .cursor-xxl-auto-hover:hover {
      cursor: auto !important;
    }
    .cursor-xxl-pointer {
      cursor: pointer !important;
    }
    .cursor-xxl-pointer-hover:hover {
      cursor: pointer !important;
    }
    .cursor-xxl-wait {
      cursor: wait !important;
    }
    .cursor-xxl-wait-hover:hover {
      cursor: wait !important;
    }
    .cursor-xxl-text {
      cursor: text !important;
    }
    .cursor-xxl-text-hover:hover {
      cursor: text !important;
    }
    .ls-xxl-tighter {
      letter-spacing: -0.05em !important;
    }
    .ls-xxl-tight {
      letter-spacing: -0.025em !important;
    }
    .ls-xxl-normal {
      letter-spacing: 0 !important;
    }
    .ls-xxl-wide {
      letter-spacing: 0.025em !important;
    }
    .ls-xxl-wider {
      letter-spacing: 0.05em !important;
    }
    .ls-xxl-widest {
      letter-spacing: 0.1em !important;
    }
    .bg-xxl-auto {
      background-size: auto !important;
    }
    .bg-xxl-cover {
      background-size: cover !important;
    }
    .bg-xxl-contain {
      background-size: contain !important;
    }
    .min-w-xxl-0 {
      min-width: 0 !important;
    }
    .min-w-xxl-full {
      min-width: 100% !important;
    }
    .min-w-xxl-min {
      min-width: -webkit-min-content !important;
      min-width: -moz-min-content !important;
      min-width: min-content !important;
    }
    .min-w-xxl-max {
      min-width: -webkit-max-content !important;
      min-width: -moz-max-content !important;
      min-width: max-content !important;
    }
    .min-h-xxl-0 {
      min-height: 0 !important;
    }
    .min-h-xxl-full {
      min-height: 100% !important;
    }
    .min-h-xxl-screen {
      min-height: 100vh !important;
    }
    .inset-xxl-0 {
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
    }
    .inset-xxl-1 {
      top: 0.25rem !important;
      right: 0.25rem !important;
      bottom: 0.25rem !important;
      left: 0.25rem !important;
    }
    .inset-xxl-2 {
      top: 0.5rem !important;
      right: 0.5rem !important;
      bottom: 0.5rem !important;
      left: 0.5rem !important;
    }
    .inset-xxl-3 {
      top: 0.75rem !important;
      right: 0.75rem !important;
      bottom: 0.75rem !important;
      left: 0.75rem !important;
    }
    .inset-xxl-4 {
      top: 1rem !important;
      right: 1rem !important;
      bottom: 1rem !important;
      left: 1rem !important;
    }
    .inset-xxl-5 {
      top: 1.25rem !important;
      right: 1.25rem !important;
      bottom: 1.25rem !important;
      left: 1.25rem !important;
    }
    .inset-xxl-6 {
      top: 1.5rem !important;
      right: 1.5rem !important;
      bottom: 1.5rem !important;
      left: 1.5rem !important;
    }
    .inset-xxl-7 {
      top: 1.75rem !important;
      right: 1.75rem !important;
      bottom: 1.75rem !important;
      left: 1.75rem !important;
    }
    .inset-xxl-8 {
      top: 2rem !important;
      right: 2rem !important;
      bottom: 2rem !important;
      left: 2rem !important;
    }
    .inset-xxl-10 {
      top: 2.5rem !important;
      right: 2.5rem !important;
      bottom: 2.5rem !important;
      left: 2.5rem !important;
    }
    .inset-xxl-12 {
      top: 3rem !important;
      right: 3rem !important;
      bottom: 3rem !important;
      left: 3rem !important;
    }
    .inset-xxl-14 {
      top: 3.5rem !important;
      right: 3.5rem !important;
      bottom: 3.5rem !important;
      left: 3.5rem !important;
    }
    .inset-xxl-16 {
      top: 4rem !important;
      right: 4rem !important;
      bottom: 4rem !important;
      left: 4rem !important;
    }
    .inset-xxl-18 {
      top: 4.5rem !important;
      right: 4.5rem !important;
      bottom: 4.5rem !important;
      left: 4.5rem !important;
    }
    .inset-xxl-20 {
      top: 5rem !important;
      right: 5rem !important;
      bottom: 5rem !important;
      left: 5rem !important;
    }
    .inset-xxl-24 {
      top: 6rem !important;
      right: 6rem !important;
      bottom: 6rem !important;
      left: 6rem !important;
    }
    .inset-xxl-32 {
      top: 8rem !important;
      right: 8rem !important;
      bottom: 8rem !important;
      left: 8rem !important;
    }
    .inset-xxl-40 {
      top: 10rem !important;
      right: 10rem !important;
      bottom: 10rem !important;
      left: 10rem !important;
    }
    .inset-xxl-48 {
      top: 12rem !important;
      right: 12rem !important;
      bottom: 12rem !important;
      left: 12rem !important;
    }
    .inset-xxl-56 {
      top: 14rem !important;
      right: 14rem !important;
      bottom: 14rem !important;
      left: 14rem !important;
    }
    .inset-xxl-64 {
      top: 16rem !important;
      right: 16rem !important;
      bottom: 16rem !important;
      left: 16rem !important;
    }
    .inset-xxl-72 {
      top: 18rem !important;
      right: 18rem !important;
      bottom: 18rem !important;
      left: 18rem !important;
    }
    .inset-xxl-80 {
      top: 20rem !important;
      right: 20rem !important;
      bottom: 20rem !important;
      left: 20rem !important;
    }
    .inset-xxl-88 {
      top: 22rem !important;
      right: 22rem !important;
      bottom: 22rem !important;
      left: 22rem !important;
    }
    .inset-xxl-96 {
      top: 24rem !important;
      right: 24rem !important;
      bottom: 24rem !important;
      left: 24rem !important;
    }
    .inset-xxl-px {
      top: 1px !important;
      right: 1px !important;
      bottom: 1px !important;
      left: 1px !important;
    }
    .inset-xxl-1\/2 {
      top: 50% !important;
      right: 50% !important;
      bottom: 50% !important;
      left: 50% !important;
    }
    .inset-xxl-full {
      top: 100% !important;
      right: 100% !important;
      bottom: 100% !important;
      left: 100% !important;
    }
    .inset-xxl-auto {
      top: auto !important;
      right: auto !important;
      bottom: auto !important;
      left: auto !important;
    }
    .inset-x-xxl-0 {
      left: 0 !important;
      right: 0 !important;
    }
    .inset-x-xxl-1 {
      left: 0.25rem !important;
      right: 0.25rem !important;
    }
    .inset-x-xxl-2 {
      left: 0.5rem !important;
      right: 0.5rem !important;
    }
    .inset-x-xxl-3 {
      left: 0.75rem !important;
      right: 0.75rem !important;
    }
    .inset-x-xxl-4 {
      left: 1rem !important;
      right: 1rem !important;
    }
    .inset-x-xxl-5 {
      left: 1.25rem !important;
      right: 1.25rem !important;
    }
    .inset-x-xxl-6 {
      left: 1.5rem !important;
      right: 1.5rem !important;
    }
    .inset-x-xxl-7 {
      left: 1.75rem !important;
      right: 1.75rem !important;
    }
    .inset-x-xxl-8 {
      left: 2rem !important;
      right: 2rem !important;
    }
    .inset-x-xxl-10 {
      left: 2.5rem !important;
      right: 2.5rem !important;
    }
    .inset-x-xxl-12 {
      left: 3rem !important;
      right: 3rem !important;
    }
    .inset-x-xxl-14 {
      left: 3.5rem !important;
      right: 3.5rem !important;
    }
    .inset-x-xxl-16 {
      left: 4rem !important;
      right: 4rem !important;
    }
    .inset-x-xxl-18 {
      left: 4.5rem !important;
      right: 4.5rem !important;
    }
    .inset-x-xxl-20 {
      left: 5rem !important;
      right: 5rem !important;
    }
    .inset-x-xxl-24 {
      left: 6rem !important;
      right: 6rem !important;
    }
    .inset-x-xxl-32 {
      left: 8rem !important;
      right: 8rem !important;
    }
    .inset-x-xxl-40 {
      left: 10rem !important;
      right: 10rem !important;
    }
    .inset-x-xxl-48 {
      left: 12rem !important;
      right: 12rem !important;
    }
    .inset-x-xxl-56 {
      left: 14rem !important;
      right: 14rem !important;
    }
    .inset-x-xxl-64 {
      left: 16rem !important;
      right: 16rem !important;
    }
    .inset-x-xxl-72 {
      left: 18rem !important;
      right: 18rem !important;
    }
    .inset-x-xxl-80 {
      left: 20rem !important;
      right: 20rem !important;
    }
    .inset-x-xxl-88 {
      left: 22rem !important;
      right: 22rem !important;
    }
    .inset-x-xxl-96 {
      left: 24rem !important;
      right: 24rem !important;
    }
    .inset-x-xxl-px {
      left: 1px !important;
      right: 1px !important;
    }
    .inset-x-xxl-1\/2 {
      left: 50% !important;
      right: 50% !important;
    }
    .inset-x-xxl-full {
      left: 100% !important;
      right: 100% !important;
    }
    .inset-x-xxl-auto {
      left: auto !important;
      right: auto !important;
    }
    .inset-y-xxl-0 {
      top: 0 !important;
      bottom: 0 !important;
    }
    .inset-y-xxl-1 {
      top: 0.25rem !important;
      bottom: 0.25rem !important;
    }
    .inset-y-xxl-2 {
      top: 0.5rem !important;
      bottom: 0.5rem !important;
    }
    .inset-y-xxl-3 {
      top: 0.75rem !important;
      bottom: 0.75rem !important;
    }
    .inset-y-xxl-4 {
      top: 1rem !important;
      bottom: 1rem !important;
    }
    .inset-y-xxl-5 {
      top: 1.25rem !important;
      bottom: 1.25rem !important;
    }
    .inset-y-xxl-6 {
      top: 1.5rem !important;
      bottom: 1.5rem !important;
    }
    .inset-y-xxl-7 {
      top: 1.75rem !important;
      bottom: 1.75rem !important;
    }
    .inset-y-xxl-8 {
      top: 2rem !important;
      bottom: 2rem !important;
    }
    .inset-y-xxl-10 {
      top: 2.5rem !important;
      bottom: 2.5rem !important;
    }
    .inset-y-xxl-12 {
      top: 3rem !important;
      bottom: 3rem !important;
    }
    .inset-y-xxl-14 {
      top: 3.5rem !important;
      bottom: 3.5rem !important;
    }
    .inset-y-xxl-16 {
      top: 4rem !important;
      bottom: 4rem !important;
    }
    .inset-y-xxl-18 {
      top: 4.5rem !important;
      bottom: 4.5rem !important;
    }
    .inset-y-xxl-20 {
      top: 5rem !important;
      bottom: 5rem !important;
    }
    .inset-y-xxl-24 {
      top: 6rem !important;
      bottom: 6rem !important;
    }
    .inset-y-xxl-32 {
      top: 8rem !important;
      bottom: 8rem !important;
    }
    .inset-y-xxl-40 {
      top: 10rem !important;
      bottom: 10rem !important;
    }
    .inset-y-xxl-48 {
      top: 12rem !important;
      bottom: 12rem !important;
    }
    .inset-y-xxl-56 {
      top: 14rem !important;
      bottom: 14rem !important;
    }
    .inset-y-xxl-64 {
      top: 16rem !important;
      bottom: 16rem !important;
    }
    .inset-y-xxl-72 {
      top: 18rem !important;
      bottom: 18rem !important;
    }
    .inset-y-xxl-80 {
      top: 20rem !important;
      bottom: 20rem !important;
    }
    .inset-y-xxl-88 {
      top: 22rem !important;
      bottom: 22rem !important;
    }
    .inset-y-xxl-96 {
      top: 24rem !important;
      bottom: 24rem !important;
    }
    .inset-y-xxl-px {
      top: 1px !important;
      bottom: 1px !important;
    }
    .inset-y-xxl-1\/2 {
      top: 50% !important;
      bottom: 50% !important;
    }
    .inset-y-xxl-full {
      top: 100% !important;
      bottom: 100% !important;
    }
    .inset-y-xxl-auto {
      top: auto !important;
      bottom: auto !important;
    }
    .place-content-xxl-start {
      place-content: flex-start !important;
    }
    .place-content-xxl-end {
      place-content: flex-end !important;
    }
    .place-content-xxl-center {
      place-content: center !important;
    }
    .place-content-xxl-between {
      place-content: space-between !important;
    }
    .place-content-xxl-around {
      place-content: space-around !important;
    }
    .place-content-xxl-evenly {
      place-content: space-evenly !important;
    }
    .place-content-xxl-stretch {
      place-content: stretch !important;
    }
    .overflow-x-xxl-auto {
      overflow-x: auto !important;
    }
    .overflow-x-xxl-hidden {
      overflow-x: hidden !important;
    }
    .overflow-x-xxl-visible {
      overflow-x: visible !important;
    }
    .overflow-x-xxl-scroll {
      overflow-x: scroll !important;
    }
    .overflow-y-xxl-auto {
      overflow-y: auto !important;
    }
    .overflow-y-xxl-hidden {
      overflow-y: hidden !important;
    }
    .overflow-y-xxl-visible {
      overflow-y: visible !important;
    }
    .overflow-y-xxl-scroll {
      overflow-y: scroll !important;
    }
    .transition-xxl {
      transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform !important;
    }
    .transition-xxl-none {
      transition-property: none !important;
    }
    .transition-xxl-all {
      transition-property: all !important;
    }
    .transition-xxl-colors {
      transition-property: background-color, border-color, color, fill, stroke !important;
    }
    .transition-xxl-opacity {
      transition-property: opacity !important;
    }
    .transition-xxl-shadow {
      transition-property: box-shadow !important;
    }
    .transition-xxl-transform {
      transition-property: transform !important;
    }
    .ease-xxl-base {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .ease-xxl-linear {
      transition-timing-function: linear !important;
    }
    .ease-xxl-in {
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
    }
    .ease-xxl-out {
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
    }
    .ease-xxl-in-out {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
    .duration-xxl-base {
      transition-duration: 150ms !important;
    }
    .duration-xxl-75 {
      transition-duration: 75ms !important;
    }
    .duration-xxl-100 {
      transition-duration: 100ms !important;
    }
    .duration-xxl-150 {
      transition-duration: 150ms !important;
    }
    .duration-xxl-200 {
      transition-duration: 200ms !important;
    }
    .duration-xxl-300 {
      transition-duration: 300ms !important;
    }
    .duration-xxl-500 {
      transition-duration: 500ms !important;
    }
    .duration-xxl-700 {
      transition-duration: 700ms !important;
    }
    .duration-xxl-1000 {
      transition-duration: 1000ms !important;
    }
    .delay-xxl-75 {
      transition-delay: 75ms !important;
    }
    .delay-xxl-100 {
      transition-delay: 100ms !important;
    }
    .delay-xxl-150 {
      transition-delay: 150ms !important;
    }
    .delay-xxl-200 {
      transition-delay: 200ms !important;
    }
    .delay-xxl-300 {
      transition-delay: 300ms !important;
    }
    .delay-xxl-500 {
      transition-delay: 500ms !important;
    }
    .delay-xxl-700 {
      transition-delay: 700ms !important;
    }
    .delay-xxl-1000 {
      transition-delay: 1000ms !important;
    }
    .animation-xxl-none {
      -webkit-animation: none !important;
      animation: none !important;
    }
    .animation-xxl-spin {
      -webkit-animation: spin 1s linear infinite !important;
      animation: spin 1s linear infinite !important;
    }
    .animation-xxl-ping {
      -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
      animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
    }
    .animation-xxl-pulse {
      -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
    }
    .animation-xxl-bounce {
      -webkit-animation: bounce 1s infinite !important;
      animation: bounce 1s infinite !important;
    }
    .animation-xxl-slide-left {
      -webkit-animation: slideLeft 3s infinite !important;
      animation: slideLeft 3s infinite !important;
    }
    .animation-xxl-slide-right {
      -webkit-animation: slideRight 3s infinite !important;
      animation: slideRight 3s infinite !important;
    }
    .animation-xxl-move-slow-left {
      -webkit-animation: slideLeft 15s linear infinite !important;
      animation: slideLeft 15s linear infinite !important;
    }
    .animation-xxl-move-slow-right {
      -webkit-animation: slideRight 15s linear infinite !important;
      animation: slideRight 15s linear infinite !important;
    }
    .blur-xxl-5 {
      --x-blur: 5px !important;
    }
    .blur-xxl-10 {
      --x-blur: 10px !important;
    }
    .blur-xxl-25 {
      --x-blur: 25px !important;
    }
    .blur-xxl-50 {
      --x-blur: 50px !important;
    }
    .blur-xxl-75 {
      --x-blur: 75px !important;
    }
    .blur-xxl-100 {
      --x-blur: 100px !important;
    }
    .blur-xxl-125 {
      --x-blur: 125px !important;
    }
    .blur-xxl-150 {
      --x-blur: 150px !important;
    }
    .blur-xxl-200 {
      --x-blur: 200px !important;
    }
    .transform-xxl-none {
      transform: none !important;
    }
    .origin-xxl-center {
      transform-origin: center !important;
    }
    .origin-xxl-center-hover:hover {
      transform-origin: center !important;
    }
    .origin-xxl-top {
      transform-origin: top !important;
    }
    .origin-xxl-top-hover:hover {
      transform-origin: top !important;
    }
    .origin-xxl-top-right {
      transform-origin: top right !important;
    }
    .origin-xxl-top-right-hover:hover {
      transform-origin: top right !important;
    }
    .origin-xxl-right {
      transform-origin: right !important;
    }
    .origin-xxl-right-hover:hover {
      transform-origin: right !important;
    }
    .origin-xxl-bottom-right {
      transform-origin: bottom right !important;
    }
    .origin-xxl-bottom-right-hover:hover {
      transform-origin: bottom right !important;
    }
    .origin-xxl-bottom {
      transform-origin: bottom !important;
    }
    .origin-xxl-bottom-hover:hover {
      transform-origin: bottom !important;
    }
    .origin-xxl-bottom-left {
      transform-origin: bottom left !important;
    }
    .origin-xxl-bottom-left-hover:hover {
      transform-origin: bottom left !important;
    }
    .origin-xxl-left {
      transform-origin: left !important;
    }
    .origin-xxl-left-hover:hover {
      transform-origin: left !important;
    }
    .origin-xxl-top-left {
      transform-origin: top left !important;
    }
    .origin-xxl-top-left-hover:hover {
      transform-origin: top left !important;
    }
    .scale-xxl-0 {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-xxl-0-hover:hover {
      --x-scale-x: 0 !important;
      --x-scale-y: 0 !important;
    }
    .scale-xxl-25 {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-xxl-25-hover:hover {
      --x-scale-x: 0.25 !important;
      --x-scale-y: 0.25 !important;
    }
    .scale-xxl-50 {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-xxl-50-hover:hover {
      --x-scale-x: 0.5 !important;
      --x-scale-y: 0.5 !important;
    }
    .scale-xxl-75 {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-xxl-75-hover:hover {
      --x-scale-x: 0.75 !important;
      --x-scale-y: 0.75 !important;
    }
    .scale-xxl-90 {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-xxl-90-hover:hover {
      --x-scale-x: 0.9 !important;
      --x-scale-y: 0.9 !important;
    }
    .scale-xxl-95 {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-xxl-95-hover:hover {
      --x-scale-x: 0.95 !important;
      --x-scale-y: 0.95 !important;
    }
    .scale-xxl-100 {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-xxl-100-hover:hover {
      --x-scale-x: 1 !important;
      --x-scale-y: 1 !important;
    }
    .scale-xxl-105 {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-xxl-105-hover:hover {
      --x-scale-x: 1.05 !important;
      --x-scale-y: 1.05 !important;
    }
    .scale-xxl-110 {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-xxl-110-hover:hover {
      --x-scale-x: 1.1 !important;
      --x-scale-y: 1.1 !important;
    }
    .scale-xxl-125 {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-xxl-125-hover:hover {
      --x-scale-x: 1.25 !important;
      --x-scale-y: 1.25 !important;
    }
    .scale-xxl-150 {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-xxl-150-hover:hover {
      --x-scale-x: 1.5 !important;
      --x-scale-y: 1.5 !important;
    }
    .scale-xxl-200 {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-xxl-200-hover:hover {
      --x-scale-x: 2 !important;
      --x-scale-y: 2 !important;
    }
    .scale-y-xxl-0 {
      --x--scale-y: 0 !important;
    }
    .scale-y-xxl-0-hover:hover {
      --x--scale-y: 0 !important;
    }
    .scale-y-xxl-25 {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-xxl-25-hover:hover {
      --x--scale-y: 0.25 !important;
    }
    .scale-y-xxl-50 {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-xxl-50-hover:hover {
      --x--scale-y: 0.5 !important;
    }
    .scale-y-xxl-75 {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-xxl-75-hover:hover {
      --x--scale-y: 0.75 !important;
    }
    .scale-y-xxl-90 {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-xxl-90-hover:hover {
      --x--scale-y: 0.9 !important;
    }
    .scale-y-xxl-95 {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-xxl-95-hover:hover {
      --x--scale-y: 0.95 !important;
    }
    .scale-y-xxl-100 {
      --x--scale-y: 1 !important;
    }
    .scale-y-xxl-100-hover:hover {
      --x--scale-y: 1 !important;
    }
    .scale-y-xxl-105 {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-xxl-105-hover:hover {
      --x--scale-y: 1.05 !important;
    }
    .scale-y-xxl-110 {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-xxl-110-hover:hover {
      --x--scale-y: 1.1 !important;
    }
    .scale-y-xxl-125 {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-xxl-125-hover:hover {
      --x--scale-y: 1.25 !important;
    }
    .scale-y-xxl-150 {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-xxl-150-hover:hover {
      --x--scale-y: 1.5 !important;
    }
    .scale-y-xxl-200 {
      --x--scale-y: 2 !important;
    }
    .scale-y-xxl-200-hover:hover {
      --x--scale-y: 2 !important;
    }
    .scale-x-xxl-0 {
      --x--scale-x: 0 !important;
    }
    .scale-x-xxl-0-hover:hover {
      --x--scale-x: 0 !important;
    }
    .scale-x-xxl-25 {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-xxl-25-hover:hover {
      --x--scale-x: 0.25 !important;
    }
    .scale-x-xxl-50 {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-xxl-50-hover:hover {
      --x--scale-x: 0.5 !important;
    }
    .scale-x-xxl-75 {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-xxl-75-hover:hover {
      --x--scale-x: 0.75 !important;
    }
    .scale-x-xxl-90 {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-xxl-90-hover:hover {
      --x--scale-x: 0.9 !important;
    }
    .scale-x-xxl-95 {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-xxl-95-hover:hover {
      --x--scale-x: 0.95 !important;
    }
    .scale-x-xxl-100 {
      --x--scale-x: 1 !important;
    }
    .scale-x-xxl-100-hover:hover {
      --x--scale-x: 1 !important;
    }
    .scale-x-xxl-105 {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-xxl-105-hover:hover {
      --x--scale-x: 1.05 !important;
    }
    .scale-x-xxl-110 {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-xxl-110-hover:hover {
      --x--scale-x: 1.1 !important;
    }
    .scale-x-xxl-125 {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-xxl-125-hover:hover {
      --x--scale-x: 1.25 !important;
    }
    .scale-x-xxl-150 {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-xxl-150-hover:hover {
      --x--scale-x: 1.5 !important;
    }
    .scale-x-xxl-200 {
      --x--scale-x: 2 !important;
    }
    .scale-x-xxl-200-hover:hover {
      --x--scale-x: 2 !important;
    }
    .rotate-xxl-0 {
      --x-rotate: 0deg !important;
    }
    .rotate-xxl-0-hover:hover {
      --x-rotate: 0deg !important;
    }
    .rotate-xxl-1 {
      --x-rotate: 1deg !important;
    }
    .rotate-xxl-1-hover:hover {
      --x-rotate: 1deg !important;
    }
    .rotate-xxl-2 {
      --x-rotate: 2deg !important;
    }
    .rotate-xxl-2-hover:hover {
      --x-rotate: 2deg !important;
    }
    .rotate-xxl-3 {
      --x-rotate: 3deg !important;
    }
    .rotate-xxl-3-hover:hover {
      --x-rotate: 3deg !important;
    }
    .rotate-xxl-6 {
      --x-rotate: 6deg !important;
    }
    .rotate-xxl-6-hover:hover {
      --x-rotate: 6deg !important;
    }
    .rotate-xxl-12 {
      --x-rotate: 12deg !important;
    }
    .rotate-xxl-12-hover:hover {
      --x-rotate: 12deg !important;
    }
    .rotate-xxl-30 {
      --x-rotate: 30deg !important;
    }
    .rotate-xxl-30-hover:hover {
      --x-rotate: 30deg !important;
    }
    .rotate-xxl-45 {
      --x-rotate: 45deg !important;
    }
    .rotate-xxl-45-hover:hover {
      --x-rotate: 45deg !important;
    }
    .rotate-xxl-90 {
      --x-rotate: 90deg !important;
    }
    .rotate-xxl-90-hover:hover {
      --x-rotate: 90deg !important;
    }
    .rotate-xxl-180 {
      --x-rotate: 180deg !important;
    }
    .rotate-xxl-180-hover:hover {
      --x-rotate: 180deg !important;
    }
    .rotate-xxl-n1 {
      --x-rotate: -1deg !important;
    }
    .rotate-xxl-n1-hover:hover {
      --x-rotate: -1deg !important;
    }
    .rotate-xxl-n2 {
      --x-rotate: -2deg !important;
    }
    .rotate-xxl-n2-hover:hover {
      --x-rotate: -2deg !important;
    }
    .rotate-xxl-n3 {
      --x-rotate: -3deg !important;
    }
    .rotate-xxl-n3-hover:hover {
      --x-rotate: -3deg !important;
    }
    .rotate-xxl-n6 {
      --x-rotate: -6deg !important;
    }
    .rotate-xxl-n6-hover:hover {
      --x-rotate: -6deg !important;
    }
    .rotate-xxl-n12 {
      --x-rotate: -12deg !important;
    }
    .rotate-xxl-n12-hover:hover {
      --x-rotate: -12deg !important;
    }
    .rotate-xxl-n30 {
      --x-rotate: -30deg !important;
    }
    .rotate-xxl-n30-hover:hover {
      --x-rotate: -30deg !important;
    }
    .rotate-xxl-n45 {
      --x-rotate: -45deg !important;
    }
    .rotate-xxl-n45-hover:hover {
      --x-rotate: -45deg !important;
    }
    .rotate-xxl-n90 {
      --x-rotate: -90deg !important;
    }
    .rotate-xxl-n90-hover:hover {
      --x-rotate: -90deg !important;
    }
    .rotate-xxl-n180 {
      --x-rotate: -180deg !important;
    }
    .rotate-xxl-n180-hover:hover {
      --x-rotate: -180deg !important;
    }
    .rotate-y-xxl-0 {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-xxl-0-hover:hover {
      --x-rotate-y: 0deg !important;
    }
    .rotate-y-xxl-1 {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-xxl-1-hover:hover {
      --x-rotate-y: 1deg !important;
    }
    .rotate-y-xxl-2 {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-xxl-2-hover:hover {
      --x-rotate-y: 2deg !important;
    }
    .rotate-y-xxl-3 {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-xxl-3-hover:hover {
      --x-rotate-y: 3deg !important;
    }
    .rotate-y-xxl-6 {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-xxl-6-hover:hover {
      --x-rotate-y: 6deg !important;
    }
    .rotate-y-xxl-12 {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-xxl-12-hover:hover {
      --x-rotate-y: 12deg !important;
    }
    .rotate-y-xxl-30 {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-xxl-30-hover:hover {
      --x-rotate-y: 30deg !important;
    }
    .rotate-y-xxl-45 {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-xxl-45-hover:hover {
      --x-rotate-y: 45deg !important;
    }
    .rotate-y-xxl-90 {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-xxl-90-hover:hover {
      --x-rotate-y: 90deg !important;
    }
    .rotate-y-xxl-180 {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-xxl-180-hover:hover {
      --x-rotate-y: 180deg !important;
    }
    .rotate-y-xxl-n1 {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-xxl-n1-hover:hover {
      --x-rotate-y: -1deg !important;
    }
    .rotate-y-xxl-n2 {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-xxl-n2-hover:hover {
      --x-rotate-y: -2deg !important;
    }
    .rotate-y-xxl-n3 {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-xxl-n3-hover:hover {
      --x-rotate-y: -3deg !important;
    }
    .rotate-y-xxl-n6 {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-xxl-n6-hover:hover {
      --x-rotate-y: -6deg !important;
    }
    .rotate-y-xxl-n12 {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-xxl-n12-hover:hover {
      --x-rotate-y: -12deg !important;
    }
    .rotate-y-xxl-n30 {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-xxl-n30-hover:hover {
      --x-rotate-y: -30deg !important;
    }
    .rotate-y-xxl-n45 {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-xxl-n45-hover:hover {
      --x-rotate-y: -45deg !important;
    }
    .rotate-y-xxl-n90 {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-xxl-n90-hover:hover {
      --x-rotate-y: -90deg !important;
    }
    .rotate-y-xxl-n180 {
      --x-rotate-y: -180deg !important;
    }
    .rotate-y-xxl-n180-hover:hover {
      --x-rotate-y: -180deg !important;
    }
    .rotate-x-xxl-0 {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-xxl-0-hover:hover {
      --x-rotate-x: 0deg !important;
    }
    .rotate-x-xxl-1 {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-xxl-1-hover:hover {
      --x-rotate-x: 1deg !important;
    }
    .rotate-x-xxl-2 {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-xxl-2-hover:hover {
      --x-rotate-x: 2deg !important;
    }
    .rotate-x-xxl-3 {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-xxl-3-hover:hover {
      --x-rotate-x: 3deg !important;
    }
    .rotate-x-xxl-6 {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-xxl-6-hover:hover {
      --x-rotate-x: 6deg !important;
    }
    .rotate-x-xxl-12 {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-xxl-12-hover:hover {
      --x-rotate-x: 12deg !important;
    }
    .rotate-x-xxl-30 {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-xxl-30-hover:hover {
      --x-rotate-x: 30deg !important;
    }
    .rotate-x-xxl-45 {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-xxl-45-hover:hover {
      --x-rotate-x: 45deg !important;
    }
    .rotate-x-xxl-90 {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-xxl-90-hover:hover {
      --x-rotate-x: 90deg !important;
    }
    .rotate-x-xxl-180 {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-xxl-180-hover:hover {
      --x-rotate-x: 180deg !important;
    }
    .rotate-x-xxl-n1 {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-xxl-n1-hover:hover {
      --x-rotate-x: -1deg !important;
    }
    .rotate-x-xxl-n2 {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-xxl-n2-hover:hover {
      --x-rotate-x: -2deg !important;
    }
    .rotate-x-xxl-n3 {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-xxl-n3-hover:hover {
      --x-rotate-x: -3deg !important;
    }
    .rotate-x-xxl-n6 {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-xxl-n6-hover:hover {
      --x-rotate-x: -6deg !important;
    }
    .rotate-x-xxl-n12 {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-xxl-n12-hover:hover {
      --x-rotate-x: -12deg !important;
    }
    .rotate-x-xxl-n30 {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-xxl-n30-hover:hover {
      --x-rotate-x: -30deg !important;
    }
    .rotate-x-xxl-n45 {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-xxl-n45-hover:hover {
      --x-rotate-x: -45deg !important;
    }
    .rotate-x-xxl-n90 {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-xxl-n90-hover:hover {
      --x-rotate-x: -90deg !important;
    }
    .rotate-x-xxl-n180 {
      --x-rotate-x: -180deg !important;
    }
    .rotate-x-xxl-n180-hover:hover {
      --x-rotate-x: -180deg !important;
    }
    .perspective-xxl-100 {
      --x-perspective: 100px !important;
    }
    .perspective-xxl-100-hover:hover {
      --x-perspective: 100px !important;
    }
    .perspective-xxl-200 {
      --x-perspective: 200px !important;
    }
    .perspective-xxl-200-hover:hover {
      --x-perspective: 200px !important;
    }
    .perspective-xxl-300 {
      --x-perspective: 300px !important;
    }
    .perspective-xxl-300-hover:hover {
      --x-perspective: 300px !important;
    }
    .perspective-xxl-400 {
      --x-perspective: 400px !important;
    }
    .perspective-xxl-400-hover:hover {
      --x-perspective: 400px !important;
    }
    .perspective-xxl-500 {
      --x-perspective: 500px !important;
    }
    .perspective-xxl-500-hover:hover {
      --x-perspective: 500px !important;
    }
    .perspective-xxl-600 {
      --x-perspective: 600px !important;
    }
    .perspective-xxl-600-hover:hover {
      --x-perspective: 600px !important;
    }
    .perspective-xxl-700 {
      --x-perspective: 700px !important;
    }
    .perspective-xxl-700-hover:hover {
      --x-perspective: 700px !important;
    }
    .perspective-xxl-800 {
      --x-perspective: 800px !important;
    }
    .perspective-xxl-800-hover:hover {
      --x-perspective: 800px !important;
    }
    .perspective-xxl-1000 {
      --x-perspective: 1000px !important;
    }
    .perspective-xxl-1000-hover:hover {
      --x-perspective: 1000px !important;
    }
    .perspective-xxl-1250 {
      --x-perspective: 1250px !important;
    }
    .perspective-xxl-1250-hover:hover {
      --x-perspective: 1250px !important;
    }
    .perspective-xxl-1500 {
      --x-perspective: 1500px !important;
    }
    .perspective-xxl-1500-hover:hover {
      --x-perspective: 1500px !important;
    }
    .translate-y-xxl-0 {
      --x-translate-y: 0 !important;
    }
    .translate-y-xxl-0-hover:hover {
      --x-translate-y: 0 !important;
    }
    .translate-y-xxl-1 {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-xxl-1-hover:hover {
      --x-translate-y: 0.25rem !important;
    }
    .translate-y-xxl-2 {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-xxl-2-hover:hover {
      --x-translate-y: 0.5rem !important;
    }
    .translate-y-xxl-3 {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-xxl-3-hover:hover {
      --x-translate-y: 0.75rem !important;
    }
    .translate-y-xxl-4 {
      --x-translate-y: 1rem !important;
    }
    .translate-y-xxl-4-hover:hover {
      --x-translate-y: 1rem !important;
    }
    .translate-y-xxl-5 {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-xxl-5-hover:hover {
      --x-translate-y: 1.25rem !important;
    }
    .translate-y-xxl-6 {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-xxl-6-hover:hover {
      --x-translate-y: 1.5rem !important;
    }
    .translate-y-xxl-7 {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-xxl-7-hover:hover {
      --x-translate-y: 1.75rem !important;
    }
    .translate-y-xxl-8 {
      --x-translate-y: 2rem !important;
    }
    .translate-y-xxl-8-hover:hover {
      --x-translate-y: 2rem !important;
    }
    .translate-y-xxl-10 {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-xxl-10-hover:hover {
      --x-translate-y: 2.5rem !important;
    }
    .translate-y-xxl-12 {
      --x-translate-y: 3rem !important;
    }
    .translate-y-xxl-12-hover:hover {
      --x-translate-y: 3rem !important;
    }
    .translate-y-xxl-14 {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-xxl-14-hover:hover {
      --x-translate-y: 3.5rem !important;
    }
    .translate-y-xxl-16 {
      --x-translate-y: 4rem !important;
    }
    .translate-y-xxl-16-hover:hover {
      --x-translate-y: 4rem !important;
    }
    .translate-y-xxl-18 {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-xxl-18-hover:hover {
      --x-translate-y: 4.5rem !important;
    }
    .translate-y-xxl-20 {
      --x-translate-y: 5rem !important;
    }
    .translate-y-xxl-20-hover:hover {
      --x-translate-y: 5rem !important;
    }
    .translate-y-xxl-24 {
      --x-translate-y: 6rem !important;
    }
    .translate-y-xxl-24-hover:hover {
      --x-translate-y: 6rem !important;
    }
    .translate-y-xxl-32 {
      --x-translate-y: 8rem !important;
    }
    .translate-y-xxl-32-hover:hover {
      --x-translate-y: 8rem !important;
    }
    .translate-y-xxl-40 {
      --x-translate-y: 10rem !important;
    }
    .translate-y-xxl-40-hover:hover {
      --x-translate-y: 10rem !important;
    }
    .translate-y-xxl-48 {
      --x-translate-y: 12rem !important;
    }
    .translate-y-xxl-48-hover:hover {
      --x-translate-y: 12rem !important;
    }
    .translate-y-xxl-56 {
      --x-translate-y: 14rem !important;
    }
    .translate-y-xxl-56-hover:hover {
      --x-translate-y: 14rem !important;
    }
    .translate-y-xxl-64 {
      --x-translate-y: 16rem !important;
    }
    .translate-y-xxl-64-hover:hover {
      --x-translate-y: 16rem !important;
    }
    .translate-y-xxl-72 {
      --x-translate-y: 18rem !important;
    }
    .translate-y-xxl-72-hover:hover {
      --x-translate-y: 18rem !important;
    }
    .translate-y-xxl-80 {
      --x-translate-y: 20rem !important;
    }
    .translate-y-xxl-80-hover:hover {
      --x-translate-y: 20rem !important;
    }
    .translate-y-xxl-88 {
      --x-translate-y: 22rem !important;
    }
    .translate-y-xxl-88-hover:hover {
      --x-translate-y: 22rem !important;
    }
    .translate-y-xxl-96 {
      --x-translate-y: 24rem !important;
    }
    .translate-y-xxl-96-hover:hover {
      --x-translate-y: 24rem !important;
    }
    .translate-y-xxl-px {
      --x-translate-y: 1px !important;
    }
    .translate-y-xxl-px-hover:hover {
      --x-translate-y: 1px !important;
    }
    .translate-y-xxl-1\/2 {
      --x-translate-y: 50% !important;
    }
    .translate-y-xxl-1\/2-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-xxl-1\/3 {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-xxl-1\/3-hover:hover {
      --x-translate-y: 33.333333% !important;
    }
    .translate-y-xxl-1\/4 {
      --x-translate-y: 25% !important;
    }
    .translate-y-xxl-1\/4-hover:hover {
      --x-translate-y: 25% !important;
    }
    .translate-y-xxl-1\/5 {
      --x-translate-y: 20% !important;
    }
    .translate-y-xxl-1\/5-hover:hover {
      --x-translate-y: 20% !important;
    }
    .translate-y-xxl-2\/3 {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-xxl-2\/3-hover:hover {
      --x-translate-y: 66.666667% !important;
    }
    .translate-y-xxl-2\/5 {
      --x-translate-y: 50% !important;
    }
    .translate-y-xxl-2\/5-hover:hover {
      --x-translate-y: 50% !important;
    }
    .translate-y-xxl-3\/4 {
      --x-translate-y: 75% !important;
    }
    .translate-y-xxl-3\/4-hover:hover {
      --x-translate-y: 75% !important;
    }
    .translate-y-xxl-full {
      --x-translate-y: 100% !important;
    }
    .translate-y-xxl-full-hover:hover {
      --x-translate-y: 100% !important;
    }
    .translate-y-xxl-n1 {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-xxl-n1-hover:hover {
      --x-translate-y: -0.25rem !important;
    }
    .translate-y-xxl-n2 {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-xxl-n2-hover:hover {
      --x-translate-y: -0.5rem !important;
    }
    .translate-y-xxl-n3 {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-xxl-n3-hover:hover {
      --x-translate-y: -0.75rem !important;
    }
    .translate-y-xxl-n4 {
      --x-translate-y: -1rem !important;
    }
    .translate-y-xxl-n4-hover:hover {
      --x-translate-y: -1rem !important;
    }
    .translate-y-xxl-n5 {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-xxl-n5-hover:hover {
      --x-translate-y: -1.25rem !important;
    }
    .translate-y-xxl-n6 {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-xxl-n6-hover:hover {
      --x-translate-y: -1.5rem !important;
    }
    .translate-y-xxl-n7 {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-xxl-n7-hover:hover {
      --x-translate-y: -1.75rem !important;
    }
    .translate-y-xxl-n8 {
      --x-translate-y: -2rem !important;
    }
    .translate-y-xxl-n8-hover:hover {
      --x-translate-y: -2rem !important;
    }
    .translate-y-xxl-n10 {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-xxl-n10-hover:hover {
      --x-translate-y: -2.5rem !important;
    }
    .translate-y-xxl-n12 {
      --x-translate-y: -3rem !important;
    }
    .translate-y-xxl-n12-hover:hover {
      --x-translate-y: -3rem !important;
    }
    .translate-y-xxl-n14 {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-xxl-n14-hover:hover {
      --x-translate-y: -3.5rem !important;
    }
    .translate-y-xxl-n16 {
      --x-translate-y: -4rem !important;
    }
    .translate-y-xxl-n16-hover:hover {
      --x-translate-y: -4rem !important;
    }
    .translate-y-xxl-n18 {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-xxl-n18-hover:hover {
      --x-translate-y: -4.5rem !important;
    }
    .translate-y-xxl-n20 {
      --x-translate-y: -5rem !important;
    }
    .translate-y-xxl-n20-hover:hover {
      --x-translate-y: -5rem !important;
    }
    .translate-y-xxl-n24 {
      --x-translate-y: -6rem !important;
    }
    .translate-y-xxl-n24-hover:hover {
      --x-translate-y: -6rem !important;
    }
    .translate-y-xxl-n32 {
      --x-translate-y: -8rem !important;
    }
    .translate-y-xxl-n32-hover:hover {
      --x-translate-y: -8rem !important;
    }
    .translate-y-xxl-n40 {
      --x-translate-y: -10rem !important;
    }
    .translate-y-xxl-n40-hover:hover {
      --x-translate-y: -10rem !important;
    }
    .translate-y-xxl-n48 {
      --x-translate-y: -12rem !important;
    }
    .translate-y-xxl-n48-hover:hover {
      --x-translate-y: -12rem !important;
    }
    .translate-y-xxl-n56 {
      --x-translate-y: -14rem !important;
    }
    .translate-y-xxl-n56-hover:hover {
      --x-translate-y: -14rem !important;
    }
    .translate-y-xxl-n64 {
      --x-translate-y: -16rem !important;
    }
    .translate-y-xxl-n64-hover:hover {
      --x-translate-y: -16rem !important;
    }
    .translate-y-xxl-n72 {
      --x-translate-y: -18rem !important;
    }
    .translate-y-xxl-n72-hover:hover {
      --x-translate-y: -18rem !important;
    }
    .translate-y-xxl-n80 {
      --x-translate-y: -20rem !important;
    }
    .translate-y-xxl-n80-hover:hover {
      --x-translate-y: -20rem !important;
    }
    .translate-y-xxl-n88 {
      --x-translate-y: -22rem !important;
    }
    .translate-y-xxl-n88-hover:hover {
      --x-translate-y: -22rem !important;
    }
    .translate-y-xxl-n96 {
      --x-translate-y: -24rem !important;
    }
    .translate-y-xxl-n96-hover:hover {
      --x-translate-y: -24rem !important;
    }
    .translate-y-xxl-npx {
      --x-translate-y: -1px !important;
    }
    .translate-y-xxl-npx-hover:hover {
      --x-translate-y: -1px !important;
    }
    .translate-y-xxl-n1\/2 {
      --x-translate-y: -50% !important;
    }
    .translate-y-xxl-n1\/2-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-xxl-n1\/3 {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-xxl-n1\/3-hover:hover {
      --x-translate-y: -33.333333% !important;
    }
    .translate-y-xxl-n1\/4 {
      --x-translate-y: -25% !important;
    }
    .translate-y-xxl-n1\/4-hover:hover {
      --x-translate-y: -25% !important;
    }
    .translate-y-xxl-n1\/5 {
      --x-translate-y: -20% !important;
    }
    .translate-y-xxl-n1\/5-hover:hover {
      --x-translate-y: -20% !important;
    }
    .translate-y-xxl-n2\/3 {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-xxl-n2\/3-hover:hover {
      --x-translate-y: -66.666667% !important;
    }
    .translate-y-xxl-n2\/5 {
      --x-translate-y: -50% !important;
    }
    .translate-y-xxl-n2\/5-hover:hover {
      --x-translate-y: -50% !important;
    }
    .translate-y-xxl-n3\/4 {
      --x-translate-y: -75% !important;
    }
    .translate-y-xxl-n3\/4-hover:hover {
      --x-translate-y: -75% !important;
    }
    .translate-y-xxl-nfull {
      --x-translate-y: -100% !important;
    }
    .translate-y-xxl-nfull-hover:hover {
      --x-translate-y: -100% !important;
    }
    .translate-x-xxl-0 {
      --x-translate-x: 0 !important;
    }
    .translate-x-xxl-0-hover:hover {
      --x-translate-x: 0 !important;
    }
    .translate-x-xxl-1 {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-xxl-1-hover:hover {
      --x-translate-x: 0.25rem !important;
    }
    .translate-x-xxl-2 {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-xxl-2-hover:hover {
      --x-translate-x: 0.5rem !important;
    }
    .translate-x-xxl-3 {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-xxl-3-hover:hover {
      --x-translate-x: 0.75rem !important;
    }
    .translate-x-xxl-4 {
      --x-translate-x: 1rem !important;
    }
    .translate-x-xxl-4-hover:hover {
      --x-translate-x: 1rem !important;
    }
    .translate-x-xxl-5 {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-xxl-5-hover:hover {
      --x-translate-x: 1.25rem !important;
    }
    .translate-x-xxl-6 {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-xxl-6-hover:hover {
      --x-translate-x: 1.5rem !important;
    }
    .translate-x-xxl-7 {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-xxl-7-hover:hover {
      --x-translate-x: 1.75rem !important;
    }
    .translate-x-xxl-8 {
      --x-translate-x: 2rem !important;
    }
    .translate-x-xxl-8-hover:hover {
      --x-translate-x: 2rem !important;
    }
    .translate-x-xxl-10 {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-xxl-10-hover:hover {
      --x-translate-x: 2.5rem !important;
    }
    .translate-x-xxl-12 {
      --x-translate-x: 3rem !important;
    }
    .translate-x-xxl-12-hover:hover {
      --x-translate-x: 3rem !important;
    }
    .translate-x-xxl-14 {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-xxl-14-hover:hover {
      --x-translate-x: 3.5rem !important;
    }
    .translate-x-xxl-16 {
      --x-translate-x: 4rem !important;
    }
    .translate-x-xxl-16-hover:hover {
      --x-translate-x: 4rem !important;
    }
    .translate-x-xxl-18 {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-xxl-18-hover:hover {
      --x-translate-x: 4.5rem !important;
    }
    .translate-x-xxl-20 {
      --x-translate-x: 5rem !important;
    }
    .translate-x-xxl-20-hover:hover {
      --x-translate-x: 5rem !important;
    }
    .translate-x-xxl-24 {
      --x-translate-x: 6rem !important;
    }
    .translate-x-xxl-24-hover:hover {
      --x-translate-x: 6rem !important;
    }
    .translate-x-xxl-32 {
      --x-translate-x: 8rem !important;
    }
    .translate-x-xxl-32-hover:hover {
      --x-translate-x: 8rem !important;
    }
    .translate-x-xxl-40 {
      --x-translate-x: 10rem !important;
    }
    .translate-x-xxl-40-hover:hover {
      --x-translate-x: 10rem !important;
    }
    .translate-x-xxl-48 {
      --x-translate-x: 12rem !important;
    }
    .translate-x-xxl-48-hover:hover {
      --x-translate-x: 12rem !important;
    }
    .translate-x-xxl-56 {
      --x-translate-x: 14rem !important;
    }
    .translate-x-xxl-56-hover:hover {
      --x-translate-x: 14rem !important;
    }
    .translate-x-xxl-64 {
      --x-translate-x: 16rem !important;
    }
    .translate-x-xxl-64-hover:hover {
      --x-translate-x: 16rem !important;
    }
    .translate-x-xxl-72 {
      --x-translate-x: 18rem !important;
    }
    .translate-x-xxl-72-hover:hover {
      --x-translate-x: 18rem !important;
    }
    .translate-x-xxl-80 {
      --x-translate-x: 20rem !important;
    }
    .translate-x-xxl-80-hover:hover {
      --x-translate-x: 20rem !important;
    }
    .translate-x-xxl-88 {
      --x-translate-x: 22rem !important;
    }
    .translate-x-xxl-88-hover:hover {
      --x-translate-x: 22rem !important;
    }
    .translate-x-xxl-96 {
      --x-translate-x: 24rem !important;
    }
    .translate-x-xxl-96-hover:hover {
      --x-translate-x: 24rem !important;
    }
    .translate-x-xxl-px {
      --x-translate-x: 1px !important;
    }
    .translate-x-xxl-px-hover:hover {
      --x-translate-x: 1px !important;
    }
    .translate-x-xxl-1\/2 {
      --x-translate-x: 50% !important;
    }
    .translate-x-xxl-1\/2-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-xxl-1\/3 {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-xxl-1\/3-hover:hover {
      --x-translate-x: 33.333333% !important;
    }
    .translate-x-xxl-1\/4 {
      --x-translate-x: 25% !important;
    }
    .translate-x-xxl-1\/4-hover:hover {
      --x-translate-x: 25% !important;
    }
    .translate-x-xxl-1\/5 {
      --x-translate-x: 20% !important;
    }
    .translate-x-xxl-1\/5-hover:hover {
      --x-translate-x: 20% !important;
    }
    .translate-x-xxl-2\/3 {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-xxl-2\/3-hover:hover {
      --x-translate-x: 66.666667% !important;
    }
    .translate-x-xxl-2\/5 {
      --x-translate-x: 50% !important;
    }
    .translate-x-xxl-2\/5-hover:hover {
      --x-translate-x: 50% !important;
    }
    .translate-x-xxl-3\/4 {
      --x-translate-x: 75% !important;
    }
    .translate-x-xxl-3\/4-hover:hover {
      --x-translate-x: 75% !important;
    }
    .translate-x-xxl-full {
      --x-translate-x: 100% !important;
    }
    .translate-x-xxl-full-hover:hover {
      --x-translate-x: 100% !important;
    }
    .translate-x-xxl-n1 {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-xxl-n1-hover:hover {
      --x-translate-x: -0.25rem !important;
    }
    .translate-x-xxl-n2 {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-xxl-n2-hover:hover {
      --x-translate-x: -0.5rem !important;
    }
    .translate-x-xxl-n3 {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-xxl-n3-hover:hover {
      --x-translate-x: -0.75rem !important;
    }
    .translate-x-xxl-n4 {
      --x-translate-x: -1rem !important;
    }
    .translate-x-xxl-n4-hover:hover {
      --x-translate-x: -1rem !important;
    }
    .translate-x-xxl-n5 {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-xxl-n5-hover:hover {
      --x-translate-x: -1.25rem !important;
    }
    .translate-x-xxl-n6 {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-xxl-n6-hover:hover {
      --x-translate-x: -1.5rem !important;
    }
    .translate-x-xxl-n7 {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-xxl-n7-hover:hover {
      --x-translate-x: -1.75rem !important;
    }
    .translate-x-xxl-n8 {
      --x-translate-x: -2rem !important;
    }
    .translate-x-xxl-n8-hover:hover {
      --x-translate-x: -2rem !important;
    }
    .translate-x-xxl-n10 {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-xxl-n10-hover:hover {
      --x-translate-x: -2.5rem !important;
    }
    .translate-x-xxl-n12 {
      --x-translate-x: -3rem !important;
    }
    .translate-x-xxl-n12-hover:hover {
      --x-translate-x: -3rem !important;
    }
    .translate-x-xxl-n14 {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-xxl-n14-hover:hover {
      --x-translate-x: -3.5rem !important;
    }
    .translate-x-xxl-n16 {
      --x-translate-x: -4rem !important;
    }
    .translate-x-xxl-n16-hover:hover {
      --x-translate-x: -4rem !important;
    }
    .translate-x-xxl-n18 {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-xxl-n18-hover:hover {
      --x-translate-x: -4.5rem !important;
    }
    .translate-x-xxl-n20 {
      --x-translate-x: -5rem !important;
    }
    .translate-x-xxl-n20-hover:hover {
      --x-translate-x: -5rem !important;
    }
    .translate-x-xxl-n24 {
      --x-translate-x: -6rem !important;
    }
    .translate-x-xxl-n24-hover:hover {
      --x-translate-x: -6rem !important;
    }
    .translate-x-xxl-n32 {
      --x-translate-x: -8rem !important;
    }
    .translate-x-xxl-n32-hover:hover {
      --x-translate-x: -8rem !important;
    }
    .translate-x-xxl-n40 {
      --x-translate-x: -10rem !important;
    }
    .translate-x-xxl-n40-hover:hover {
      --x-translate-x: -10rem !important;
    }
    .translate-x-xxl-n48 {
      --x-translate-x: -12rem !important;
    }
    .translate-x-xxl-n48-hover:hover {
      --x-translate-x: -12rem !important;
    }
    .translate-x-xxl-n56 {
      --x-translate-x: -14rem !important;
    }
    .translate-x-xxl-n56-hover:hover {
      --x-translate-x: -14rem !important;
    }
    .translate-x-xxl-n64 {
      --x-translate-x: -16rem !important;
    }
    .translate-x-xxl-n64-hover:hover {
      --x-translate-x: -16rem !important;
    }
    .translate-x-xxl-n72 {
      --x-translate-x: -18rem !important;
    }
    .translate-x-xxl-n72-hover:hover {
      --x-translate-x: -18rem !important;
    }
    .translate-x-xxl-n80 {
      --x-translate-x: -20rem !important;
    }
    .translate-x-xxl-n80-hover:hover {
      --x-translate-x: -20rem !important;
    }
    .translate-x-xxl-n88 {
      --x-translate-x: -22rem !important;
    }
    .translate-x-xxl-n88-hover:hover {
      --x-translate-x: -22rem !important;
    }
    .translate-x-xxl-n96 {
      --x-translate-x: -24rem !important;
    }
    .translate-x-xxl-n96-hover:hover {
      --x-translate-x: -24rem !important;
    }
    .translate-x-xxl-npx {
      --x-translate-x: -1px !important;
    }
    .translate-x-xxl-npx-hover:hover {
      --x-translate-x: -1px !important;
    }
    .translate-x-xxl-n1\/2 {
      --x-translate-x: -50% !important;
    }
    .translate-x-xxl-n1\/2-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-xxl-n1\/3 {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-xxl-n1\/3-hover:hover {
      --x-translate-x: -33.333333% !important;
    }
    .translate-x-xxl-n1\/4 {
      --x-translate-x: -25% !important;
    }
    .translate-x-xxl-n1\/4-hover:hover {
      --x-translate-x: -25% !important;
    }
    .translate-x-xxl-n1\/5 {
      --x-translate-x: -20% !important;
    }
    .translate-x-xxl-n1\/5-hover:hover {
      --x-translate-x: -20% !important;
    }
    .translate-x-xxl-n2\/3 {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-xxl-n2\/3-hover:hover {
      --x-translate-x: -66.666667% !important;
    }
    .translate-x-xxl-n2\/5 {
      --x-translate-x: -50% !important;
    }
    .translate-x-xxl-n2\/5-hover:hover {
      --x-translate-x: -50% !important;
    }
    .translate-x-xxl-n3\/4 {
      --x-translate-x: -75% !important;
    }
    .translate-x-xxl-n3\/4-hover:hover {
      --x-translate-x: -75% !important;
    }
    .translate-x-xxl-nfull {
      --x-translate-x: -100% !important;
    }
    .translate-x-xxl-nfull-hover:hover {
      --x-translate-x: -100% !important;
    }
    .skew-y-xxl-0 {
      --x-skew-y: 0deg !important;
    }
    .skew-y-xxl-0-hover:hover {
      --x-skew-y: 0deg !important;
    }
    .skew-y-xxl-1 {
      --x-skew-y: 1deg !important;
    }
    .skew-y-xxl-1-hover:hover {
      --x-skew-y: 1deg !important;
    }
    .skew-y-xxl-2 {
      --x-skew-y: 2deg !important;
    }
    .skew-y-xxl-2-hover:hover {
      --x-skew-y: 2deg !important;
    }
    .skew-y-xxl-3 {
      --x-skew-y: 3deg !important;
    }
    .skew-y-xxl-3-hover:hover {
      --x-skew-y: 3deg !important;
    }
    .skew-y-xxl-6 {
      --x-skew-y: 6deg !important;
    }
    .skew-y-xxl-6-hover:hover {
      --x-skew-y: 6deg !important;
    }
    .skew-y-xxl-12 {
      --x-skew-y: 12deg !important;
    }
    .skew-y-xxl-12-hover:hover {
      --x-skew-y: 12deg !important;
    }
    .skew-y-xxl-n1 {
      --x-skew-y: -1deg !important;
    }
    .skew-y-xxl-n1-hover:hover {
      --x-skew-y: -1deg !important;
    }
    .skew-y-xxl-n2 {
      --x-skew-y: -2deg !important;
    }
    .skew-y-xxl-n2-hover:hover {
      --x-skew-y: -2deg !important;
    }
    .skew-y-xxl-n3 {
      --x-skew-y: -3deg !important;
    }
    .skew-y-xxl-n3-hover:hover {
      --x-skew-y: -3deg !important;
    }
    .skew-y-xxl-n6 {
      --x-skew-y: -6deg !important;
    }
    .skew-y-xxl-n6-hover:hover {
      --x-skew-y: -6deg !important;
    }
    .skew-y-xxl-n12 {
      --x-skew-y: -12deg !important;
    }
    .skew-y-xxl-n12-hover:hover {
      --x-skew-y: -12deg !important;
    }
    .skew-x-xxl-0 {
      --x-skew-x: 0deg !important;
    }
    .skew-x-xxl-0-hover:hover {
      --x-skew-x: 0deg !important;
    }
    .skew-x-xxl-1 {
      --x-skew-x: 1deg !important;
    }
    .skew-x-xxl-1-hover:hover {
      --x-skew-x: 1deg !important;
    }
    .skew-x-xxl-2 {
      --x-skew-x: 2deg !important;
    }
    .skew-x-xxl-2-hover:hover {
      --x-skew-x: 2deg !important;
    }
    .skew-x-xxl-3 {
      --x-skew-x: 3deg !important;
    }
    .skew-x-xxl-3-hover:hover {
      --x-skew-x: 3deg !important;
    }
    .skew-x-xxl-6 {
      --x-skew-x: 6deg !important;
    }
    .skew-x-xxl-6-hover:hover {
      --x-skew-x: 6deg !important;
    }
    .skew-x-xxl-12 {
      --x-skew-x: 12deg !important;
    }
    .skew-x-xxl-12-hover:hover {
      --x-skew-x: 12deg !important;
    }
    .skew-x-xxl-n1 {
      --x-skew-x: -1deg !important;
    }
    .skew-x-xxl-n1-hover:hover {
      --x-skew-x: -1deg !important;
    }
    .skew-x-xxl-n2 {
      --x-skew-x: -2deg !important;
    }
    .skew-x-xxl-n2-hover:hover {
      --x-skew-x: -2deg !important;
    }
    .skew-x-xxl-n3 {
      --x-skew-x: -3deg !important;
    }
    .skew-x-xxl-n3-hover:hover {
      --x-skew-x: -3deg !important;
    }
    .skew-x-xxl-n6 {
      --x-skew-x: -6deg !important;
    }
    .skew-x-xxl-n6-hover:hover {
      --x-skew-x: -6deg !important;
    }
    .skew-x-xxl-n12 {
      --x-skew-x: -12deg !important;
    }
    .skew-x-xxl-n12-hover:hover {
      --x-skew-x: -12deg !important;
    }
  }
  @media (min-width: 1200px) {
    .text-xl {
      font-size: 1.5rem !important;
    }
    .text-2xl {
      font-size: 2rem !important;
    }
  }
  @media print {
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-grid {
      display: grid !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: flex !important;
    }
    .d-print-inline-flex {
      display: inline-flex !important;
    }
    .d-print-none {
      display: none !important;
    }
  }
  
  